export const ItemEnum = {
    _100_AURUM_TOKEN  : 32792,
    _1000_AURUM_TOKEN  : 2833,
    _10000MN_AFTERBURNER_I  : 41236,
    _10000MN_AFTERBURNER_I_BLUEPRINT  : 41242,
    _10000MN_AFTERBURNER_II  : 41239,
    _10000MN_MONOPROPELLANT_ENDURING_AFTERBURNER  : 41238,
    _10000MN_MONOPROPELLANT_ENDURING_AFTERBURNER_BLUEPRINT  : 41244,
    _10000MN_Y_S8_COMPACT_AFTERBURNER  : 41237,
    _10000MN_Y_S8_COMPACT_AFTERBURNER_BLUEPRINT  : 41243,
    _100K_BOUNTY_SCC_ENCRYPTED_BOND  : 55930,
    _100MM_CRYSTALLINE_CARBONIDE_RESTRAINED_PLATES  : 11343,
    _100MM_ROLLED_TUNGSTEN_COMPACT_PLATES  : 11341,
    _100MM_STEEL_PLATES_I  : 11293,
    _100MM_STEEL_PLATES_I_BLUEPRINT  : 11294,
    _100MM_STEEL_PLATES_II  : 20345,
    _100MN_AFTERBURNER_I  : 12066,
    _100MN_AFTERBURNER_I_BLUEPRINT  : 12067,
    _100MN_AFTERBURNER_II  : 12068,
    _100MN_ANALOG_BOOSTER_AFTERBURNER  : 21474,
    _100MN_MONOPROPELLANT_ENDURING_AFTERBURNER  : 5955,
    _100MN_Y_S8_COMPACT_AFTERBURNER  : 35657,
    _10K_BOUNTY_SCC_ENCRYPTED_BOND  : 55931,
    _10M_BOUNTY_SCC_ENCRYPTED_BOND  : 55932,
    _10MN_AFTERBURNER_I  : 12056,
    _10MN_AFTERBURNER_I_BLUEPRINT  : 12057,
    _10MN_AFTERBURNER_II  : 12058,
    _10MN_ANALOG_BOOSTER_AFTERBURNER  : 21472,
    _10MN_MONOPROPELLANT_ENDURING_AFTERBURNER  : 6005,
    _10MN_Y_S8_COMPACT_AFTERBURNER  : 35656,
    _10TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19422,
    _11TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19409,
    _1200MM_ARTILLERY_CANNON_I  : 497,
    _1200MM_ARTILLERY_CANNON_I_BLUEPRINT  : 832,
    _1200MM_ARTILLERY_CANNON_II  : 2865,
    _1200MM_HEAVY_CARBINE_HOWITZER_I  : 9369,
    _1200MM_HEAVY_GALLIUM_CANNON  : 9371,
    _1200MM_HEAVY_JOLT_ARTILLERY_I  : 21559,
    _1200MM_HEAVY_PROTOTYPE_SIEGE_CANNON  : 9377,
    _1200MM_HEAVY_SCOUT_ARTILLERY_I  : 9367,
    _125MM_CARBIDE_RAILGUN_I  : 10690,
    _125MM_COMPRESSED_COIL_GUN_I  : 10692,
    _125MM_GATLING_AUTOCANNON_I  : 484,
    _125MM_GATLING_AUTOCANNON_I_BLUEPRINT  : 819,
    _125MM_GATLING_AUTOCANNON_II  : 2873,
    _125MM_LIGHT_CARBINE_REPEATING_CANNON_I  : 8785,
    _125MM_LIGHT_GALLIUM_MACHINE_GUN  : 8787,
    _125MM_LIGHT_PROTOTYPE_AUTOMATIC_CANNON  : 8789,
    _125MM_LIGHT_SCOUT_AUTOCANNON_I  : 8759,
    _125MM_PROTOTYPE_GAUSS_GUN  : 10694,
    _125MM_RAILGUN_I  : 10678,
    _125MM_RAILGUN_I_BLUEPRINT  : 10679,
    _125MM_RAILGUN_II  : 10680,
    _125MM_SCOUT_ACCELERATOR_CANNON  : 10688,
    _12TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19410,
    _13TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19411,
    _1400MM_CARBINE_HOWITZER_I  : 9493,
    _1400MM_GALLIUM_CANNON  : 9495,
    _1400MM_HOWITZER_ARTILLERY_I  : 498,
    _1400MM_HOWITZER_ARTILLERY_I_BLUEPRINT  : 833,
    _1400MM_HOWITZER_ARTILLERY_II  : 2961,
    _1400MM_JOLT_ARTILLERY_I  : 21561,
    _1400MM_PROTOTYPE_SIEGE_CANNON  : 9497,
    _1400MM_SCOUT_ARTILLERY_I  : 9491,
    _14TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19412,
    _150MM_CARBIDE_RAILGUN_I  : 7291,
    _150MM_COMPRESSED_COIL_GUN_I  : 7293,
    _150MM_LIGHT_AUTOCANNON_I  : 485,
    _150MM_LIGHT_AUTOCANNON_I_BLUEPRINT  : 820,
    _150MM_LIGHT_AUTOCANNON_II  : 2881,
    _150MM_LIGHT_CARBINE_REPEATING_CANNON_I  : 8817,
    _150MM_LIGHT_GALLIUM_MACHINE_GUN  : 8819,
    _150MM_LIGHT_PROTOTYPE_AUTOMATIC_CANNON  : 8821,
    _150MM_LIGHT_SCOUT_AUTOCANNON_I  : 8815,
    _150MM_MUSKET_RAILGUN  : 20587,
    _150MM_PROTOTYPE_GAUSS_GUN  : 7287,
    _150MM_RAILGUN_I  : 565,
    _150MM_RAILGUN_I_BLUEPRINT  : 1116,
    _150MM_RAILGUN_II  : 3074,
    _150MM_SCOUT_ACCELERATOR_CANNON  : 7289,
    _15TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19413,
    _1600MM_CRYSTALLINE_CARBONIDE_RESTRAINED_PLATES  : 11327,
    _1600MM_ROLLED_TUNGSTEN_COMPACT_PLATES  : 11325,
    _1600MM_STEEL_PLATES_I  : 11279,
    _1600MM_STEEL_PLATES_I_BLUEPRINT  : 11280,
    _1600MM_STEEL_PLATES_II  : 20353,
    _16TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19414,
    _17TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19415,
    _18TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19416,
    _19TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19417,
    _1M_BOUNTY_SCC_ENCRYPTED_BOND  : 55933,
    _1MN_AFTERBURNER_I  : 439,
    _1MN_AFTERBURNER_I_BLUEPRINT  : 1072,
    _1MN_AFTERBURNER_II  : 438,
    _1MN_ANALOG_BOOSTER_AFTERBURNER  : 21470,
    _1MN_CIVILIAN_AFTERBURNER  : 21857,
    _1MN_MONOPROPELLANT_ENDURING_AFTERBURNER  : 6003,
    _1MN_Y_S8_COMPACT_AFTERBURNER  : 6001,
    _1ST_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19400,
    _200MM_AUTOCANNON_I  : 486,
    _200MM_AUTOCANNON_I_BLUEPRINT  : 821,
    _200MM_AUTOCANNON_II  : 2889,
    _200MM_CARBIDE_RAILGUN_I  : 14272,
    _200MM_COMPRESSED_COIL_GUN_I  : 14276,
    _200MM_CRYSTALLINE_CARBONIDE_RESTRAINED_PLATES  : 11351,
    _200MM_LIGHT_CARBINE_REPEATING_CANNON_I  : 8865,
    _200MM_LIGHT_GALLIUM_MACHINE_GUN  : 8867,
    _200MM_LIGHT_JOLT_AUTOCANNON_I  : 21545,
    _200MM_LIGHT_PROTOTYPE_AUTOMATIC_CANNON  : 8869,
    _200MM_LIGHT_SCOUT_AUTOCANNON_I  : 8863,
    _200MM_PROTOTYPE_GAUSS_GUN  : 14278,
    _200MM_RAILGUN_I  : 12344,
    _200MM_RAILGUN_I_BLUEPRINT  : 12345,
    _200MM_RAILGUN_II  : 12346,
    _200MM_ROLLED_TUNGSTEN_COMPACT_PLATES  : 11349,
    _200MM_SCOUT_ACCELERATOR_CANNON  : 14274,
    _200MM_STEEL_PLATES_I  : 11295,
    _200MM_STEEL_PLATES_I_BLUEPRINT  : 11296,
    _200MM_STEEL_PLATES_II  : 20347,
    _20TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19418,
    _21ST_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19419,
    _220MM_MEDIUM_CARBINE_REPEATING_CANNON_I  : 9129,
    _220MM_MEDIUM_GALLIUM_MACHINE_GUN  : 9131,
    _220MM_MEDIUM_PROTOTYPE_AUTOMATIC_CANNON  : 9133,
    _220MM_MEDIUM_SCOUT_AUTOCANNON_I  : 9127,
    _220MM_VULCAN_AUTOCANNON_I  : 490,
    _220MM_VULCAN_AUTOCANNON_I_BLUEPRINT  : 825,
    _220MM_VULCAN_AUTOCANNON_II  : 2897,
    _22ND_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19420,
    _23RD_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19421,
    _25000MM_CRYSTALLINE_CARBONIDE_RESTRAINED_PLATES  : 40349,
    _25000MM_CRYSTALLINE_CARBONIDE_RESTRAINED_PLATES_BLUEPRINT  : 41609,
    _25000MM_ROLLED_TUNGSTEN_COMPACT_PLATES  : 40350,
    _25000MM_ROLLED_TUNGSTEN_COMPACT_PLATES_BLUEPRINT  : 41608,
    _25000MM_STEEL_PLATES_I  : 40348,
    _25000MM_STEEL_PLATES_I_BLUEPRINT  : 41607,
    _25000MM_STEEL_PLATES_II  : 40351,
    _250MM_CARBIDE_RAILGUN_I  : 7371,
    _250MM_COMPRESSED_COIL_GUN_I  : 7373,
    _250MM_FLINTLOCK_RAILGUN  : 20589,
    _250MM_LIGHT_ARTILLERY_CANNON_I  : 487,
    _250MM_LIGHT_ARTILLERY_CANNON_I_BLUEPRINT  : 822,
    _250MM_LIGHT_ARTILLERY_CANNON_II  : 2905,
    _250MM_LIGHT_CARBINE_HOWITZER_I  : 8905,
    _250MM_LIGHT_GALLIUM_CANNON  : 8907,
    _250MM_LIGHT_JOLT_ARTILLERY_I  : 21547,
    _250MM_LIGHT_PROTOTYPE_SIEGE_CANNON  : 8909,
    _250MM_LIGHT_SCOUT_ARTILLERY_I  : 8903,
    _250MM_PROTOTYPE_GAUSS_GUN  : 7367,
    _250MM_RAILGUN_I  : 570,
    _250MM_RAILGUN_I_BLUEPRINT  : 1121,
    _250MM_RAILGUN_II  : 3082,
    _250MM_SCOUT_ACCELERATOR_CANNON  : 7369,
    _280MM_CARBINE_HOWITZER_I  : 9413,
    _280MM_GALLIUM_CANNON  : 9415,
    _280MM_HOWITZER_ARTILLERY_I  : 488,
    _280MM_HOWITZER_ARTILLERY_I_BLUEPRINT  : 823,
    _280MM_HOWITZER_ARTILLERY_II  : 2977,
    _280MM_JOLT_ARTILLERY_I  : 21549,
    _280MM_PROTOTYPE_SIEGE_CANNON  : 9417,
    _280MM_SCOUT_ARTILLERY_I  : 9411,
    _2ND_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19401,
    _300_AURUM_TOKEN  : 44204,
    _300_BILLION_ISK_CONTRACT_COPY  : 47056,
    _350MM_CARBIDE_RAILGUN_I  : 14280,
    _350MM_COMPRESSED_COIL_GUN_I  : 14284,
    _350MM_PROTOTYPE_GAUSS_GUN  : 14286,
    _350MM_RAILGUN_I  : 12354,
    _350MM_RAILGUN_I_BLUEPRINT  : 12355,
    _350MM_RAILGUN_II  : 12356,
    _350MM_SCOUT_ACCELERATOR_CANNON  : 14282,
    _3RD_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19402,
    _400MM_CRYSTALLINE_CARBONIDE_RESTRAINED_PLATES  : 11311,
    _400MM_ROLLED_TUNGSTEN_COMPACT_PLATES  : 11309,
    _400MM_STEEL_PLATES_I  : 11297,
    _400MM_STEEL_PLATES_I_BLUEPRINT  : 11298,
    _400MM_STEEL_PLATES_II  : 20349,
    _41F_VEILED_TARGETING_UNIT  : 5871,
    _425MM_AUTOCANNON_I  : 491,
    _425MM_AUTOCANNON_I_BLUEPRINT  : 826,
    _425MM_AUTOCANNON_II  : 2913,
    _425MM_CARBIDE_RAILGUN_I  : 7451,
    _425MM_COMPRESSED_COIL_GUN_I  : 7453,
    _425MM_MEDIUM_CARBINE_REPEATING_CANNON_I  : 9137,
    _425MM_MEDIUM_GALLIUM_MACHINE_GUN  : 9139,
    _425MM_MEDIUM_JOLT_AUTOCANNON_I  : 21551,
    _425MM_MEDIUM_PROTOTYPE_AUTOMATIC_CANNON  : 9141,
    _425MM_MEDIUM_SCOUT_AUTOCANNON_I  : 9135,
    _425MM_POPPER_RAILGUN  : 20591,
    _425MM_PROTOTYPE_GAUSS_GUN  : 7447,
    _425MM_RAILGUN_I  : 574,
    _425MM_RAILGUN_I_BLUEPRINT  : 1125,
    _425MM_RAILGUN_II  : 3090,
    _425MM_SCOUT_ACCELERATOR_CANNON  : 7449,
    _4TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19403,
    _500_AURUM_TOKEN  : 32793,
    _50000MN_COLD_GAS_ENDURING_MICROWARPDRIVE  : 41252,
    _50000MN_COLD_GAS_ENDURING_MICROWARPDRIVE_BLUEPRINT  : 41313,
    _50000MN_MICROWARPDRIVE_I  : 41249,
    _50000MN_MICROWARPDRIVE_I_BLUEPRINT  : 41256,
    _50000MN_MICROWARPDRIVE_II  : 41253,
    _50000MN_QUAD_LIF_RESTRAINED_MICROWARPDRIVE  : 41251,
    _50000MN_QUAD_LIF_RESTRAINED_MICROWARPDRIVE_BLUEPRINT  : 41258,
    _50000MN_Y_T8_COMPACT_MICROWARPDRIVE  : 41250,
    _50000MN_Y_T8_COMPACT_MICROWARPDRIVE_BLUEPRINT  : 41257,
    _500MN_COLD_GAS_ENDURING_MICROWARPDRIVE  : 5945,
    _500MN_DIGITAL_BOOSTER_MICROWARPDRIVE  : 21480,
    _500MN_MICROWARPDRIVE_I  : 12054,
    _500MN_MICROWARPDRIVE_I_BLUEPRINT  : 12055,
    _500MN_MICROWARPDRIVE_II  : 12084,
    _500MN_QUAD_LIF_RESTRAINED_MICROWARPDRIVE  : 35662,
    _500MN_Y_T8_COMPACT_MICROWARPDRIVE  : 35661,
    _50MN_COLD_GAS_ENDURING_MICROWARPDRIVE  : 5975,
    _50MN_DIGITAL_BOOSTER_MICROWARPDRIVE  : 21478,
    _50MN_MICROWARPDRIVE_I  : 12052,
    _50MN_MICROWARPDRIVE_I_BLUEPRINT  : 12053,
    _50MN_MICROWARPDRIVE_II  : 12076,
    _50MN_QUAD_LIF_RESTRAINED_MICROWARPDRIVE  : 35660,
    _50MN_Y_T8_COMPACT_MICROWARPDRIVE  : 35659,
    _5MN_COLD_GAS_ENDURING_MICROWARPDRIVE  : 5971,
    _5MN_DIGITAL_BOOSTER_MICROWARPDRIVE  : 21476,
    _5MN_MICROWARPDRIVE_I  : 434,
    _5MN_MICROWARPDRIVE_I_BLUEPRINT  : 1067,
    _5MN_MICROWARPDRIVE_II  : 440,
    _5MN_QUAD_LIF_RESTRAINED_MICROWARPDRIVE  : 35658,
    _5MN_Y_T8_COMPACT_MICROWARPDRIVE  : 5973,
    _5TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19404,
    _650MM_ARTILLERY_CANNON_I  : 492,
    _650MM_ARTILLERY_CANNON_I_BLUEPRINT  : 827,
    _650MM_ARTILLERY_CANNON_II  : 2921,
    _650MM_MEDIUM_CARBINE_HOWITZER_I  : 9209,
    _650MM_MEDIUM_GALLIUM_CANNON  : 9211,
    _650MM_MEDIUM_JOLT_ARTILLERY_I  : 21553,
    _650MM_MEDIUM_PROTOTYPE_SIEGE_CANNON  : 9213,
    _650MM_MEDIUM_SCOUT_ARTILLERY_I  : 9207,
    _6TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19405,
    _720MM_CARBINE_HOWITZER_I  : 9453,
    _720MM_GALLIUM_CANNON  : 9455,
    _720MM_HOWITZER_ARTILLERY_I  : 493,
    _720MM_HOWITZER_ARTILLERY_I_BLUEPRINT  : 828,
    _720MM_HOWITZER_ARTILLERY_II  : 2969,
    _720MM_JOLT_ARTILLERY_I  : 21555,
    _720MM_PROTOTYPE_SIEGE_CANNON  : 9457,
    _720MM_SCOUT_ARTILLERY_I  : 9451,
    _75MM_CARBIDE_RAILGUN_I  : 7251,
    _75MM_COMPRESSED_COIL_GUN_I  : 7253,
    _75MM_GATLING_RAIL_I  : 561,
    _75MM_GATLING_RAIL_I_BLUEPRINT  : 1112,
    _75MM_GATLING_RAIL_II  : 3098,
    _75MM_PROTOTYPE_GAUSS_GUN  : 7247,
    _75MM_SCOUT_ACCELERATOR_CANNON  : 7249,
    _7TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19406,
    _800MM_CRYSTALLINE_CARBONIDE_RESTRAINED_PLATES  : 11319,
    _800MM_HEAVY_CARBINE_REPEATING_CANNON_I  : 9329,
    _800MM_HEAVY_GALLIUM_REPEATING_CANNON  : 9331,
    _800MM_HEAVY_JOLT_REPEATING_CANNON_I  : 21557,
    _800MM_HEAVY_PROTOTYPE_AUTOMATIC_CANNON  : 9333,
    _800MM_HEAVY_SCOUT_REPEATING_CANNON_I  : 9327,
    _800MM_REPEATING_CANNON_I  : 496,
    _800MM_REPEATING_CANNON_I_BLUEPRINT  : 831,
    _800MM_REPEATING_CANNON_II  : 2929,
    _800MM_ROLLED_TUNGSTEN_COMPACT_PLATES  : 11317,
    _800MM_STEEL_PLATES_I  : 11299,
    _800MM_STEEL_PLATES_I_BLUEPRINT  : 11300,
    _800MM_STEEL_PLATES_II  : 20351,
    _8TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19407,
    _95TH_INTERSTELLAR_MINDCLASH_CHAMPIONSHIP_TICKETS  : 37883,
    _9TH_TIER_OVERSEERS_PERSONAL_EFFECTS  : 19408,
    A_211_ENDURING_GUIDANCE_DISRUPTOR  : 37545,
    A_BIG_RED_BUTTON  : 33020,
    A_HANDFUL_OF_TINY_STARS  : 33016,
    A_LETTER_OF_COMPLAINT  : 44206,
    A_PIECE_OF_STEVE  : 33213,
    A_PILE_OF_LOGS  : 48749,
    A_STOLEN_VOTING_PEBBLE  : 47045,
    A_TANK_OF_HONOR  : 33023,
    ABADDON  : 24692,
    ABADDON_AURORA_UNIVERSALIS_SKIN  : 57016,
    ABADDON_BIOSECURITY_RESPONDERS_SKIN  : 55310,
    ABADDON_BLOOD_RAIDERS_SKIN  : 36913,
    ABADDON_BLUEPRINT  : 24693,
    ABADDON_CAPSULEER_DAY_XVII_SKIN  : 54761,
    ABADDON_CAPSULEER_ELITE_XIX_SKIN  : 63684,
    ABADDON_COLD_IRON_SKIN  : 42784,
    ABADDON_CROWN_AND_SWORDS_SKIN  : 56195,
    ABADDON_CRYPTIC_MIMESIS_SKIN  : 48893,
    ABADDON_EXOPLANETS_HUNTER_SKIN  : 45793,
    ABADDON_GLACIAL_DRIFT_SKIN  : 46802,
    ABADDON_HEADHUNTER_SKIN  : 46710,
    ABADDON_IMPERIAL_ARMAMENTS_SKIN  : 41588,
    ABADDON_IMPERIAL_JUBILEE_SKIN  : 46535,
    ABADDON_IMPERIAL_NAVY_SKIN  : 58902,
    ABADDON_INTERSTELLAR_CONVERGENCE_SKIN  : 62221,
    ABADDON_IRONBLOOD_SKIN  : 43515,
    ABADDON_KADOR_SKIN  : 34623,
    ABADDON_OASIS_OF_FAITH_SKIN  : 53635,
    ABADDON_PURITY_OF_THE_THRONE_SKIN  : 42584,
    ABADDON_RAATA_SUNSET_SKIN  : 37515,
    ABADDON_SCOPE_SYNDICATION_YC122_SKIN  : 56888,
    ABADDON_STAR_CAPTAIN_SKIN  : 45665,
    ABADDON_STARLINE_MAJESTIC_SKIN  : 55720,
    ABADDON_TASH_MURKON_SKIN  : 34624,
    ABADDON_VALKYRIE_SKIN  : 46378,
    ABATIS_100MM_STEEL_PLATES  : 23783,
    ABSOLUTION  : 22448,
    ABSOLUTION_BIOSECURITY_RESPONDERS_SKIN  : 55349,
    ABSOLUTION_BLUEPRINT  : 22449,
    ABSOLUTION_COLD_IRON_SKIN  : 42781,
    ABSOLUTION_EOM_SKIN  : 36312,
    ABSOLUTION_EXOPLANETS_HUNTER_SKIN  : 45790,
    ABSOLUTION_GLACIAL_DRIFT_SKIN  : 46799,
    ABSOLUTION_IMPERIAL_JUBILEE_SKIN  : 48852,
    ABSOLUTION_OASIS_OF_FAITH_SKIN  : 53632,
    ABSOLUTION_PURITY_OF_THE_THRONE_SKIN  : 42581,
    ABSOLUTION_RAATA_SUNSET_SKIN  : 37513,
    ABSOLUTION_STAR_CAPTAIN_SKIN  : 48243,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_A_I  : 60303,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 60349,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_A_II  : 60306,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_B_I  : 60304,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 60371,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_B_II  : 60307,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_C_I  : 60305,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 60372,
    ABYSSAL_ASTEROID_MINING_CRYSTAL_TYPE_C_II  : 60308,
    ABYSSAL_BEZDNACINE  : 56627,
    ABYSSAL_ORE_PROCESSING  : 60381,
    ABYSSAL_RAKOVENE  : 56629,
    ABYSSAL_TALASSONITE  : 56625,
    ACCELERANT_DECRYPTOR  : 34201,
    ACCELERATION_CONTROL  : 3452,
    ACCORD_CORE_COMPENSATION  : 16297,
    ACCOUNTING  : 16622,
    ACE_OF_PODHUNTERS_FIREWORK  : 50138,
    ACOLYTE_EV_300  : 23659,
    ACOLYTE_EV_300_BLUEPRINT  : 23660,
    ACOLYTE_I  : 2203,
    ACOLYTE_I_BLUEPRINT  : 2204,
    ACOLYTE_II  : 2205,
    ACOLYTE_TD_300  : 23727,
    ACOLYTE_TD_300_BLUEPRINT  : 23728,
    ACTIVE_SHIELDING_CHARGE  : 42694,
    ACTIVE_SHIELDING_CHARGE_BLUEPRINT  : 42883,
    ACTIVISTS  : 17754,
    ADMIXTI_MUTANITE  : 77524,
    ADRESTIA  : 2836,
    ADVANCED_BROKER_RELATIONS  : 16597,
    ADVANCED_CAPITAL_SHIP_CONSTRUCTION  : 77725,
    ADVANCED_CONTRACTING  : 73912,
    ADVANCED_DRONE_AVIONICS  : 23566,
    ADVANCED_INDUSTRIAL_SHIP_CONSTRUCTION  : 3396,
    ADVANCED_INDUSTRY  : 3388,
    ADVANCED_INFOMORPH_PSYCHOLOGY  : 33407,
    ADVANCED_LABORATORY_OPERATION  : 24624,
    ADVANCED_LARGE_SHIP_ASSEMBLY_ARRAY  : 24657,
    ADVANCED_LARGE_SHIP_CONSTRUCTION  : 3398,
    ADVANCED_LIMOS_HEAVY_MISSILE_BAY_I  : 8103,
    ADVANCED_LOGISTICS_NETWORK  : 32422,
    ADVANCED_LOGISTICS_NETWORK_BLUEPRINT  : 34865,
    ADVANCED_MASS_PRODUCTION  : 24625,
    ADVANCED_MASS_REACTIONS  : 45749,
    ADVANCED_MEDIUM_SHIP_ASSEMBLY_ARRAY  : 24655,
    ADVANCED_MEDIUM_SHIP_CONSTRUCTION  : 3397,
    ADVANCED_PLANETOLOGY  : 2403,
    ADVANCED_SMALL_SHIP_ASSEMBLY_ARRAY  : 24653,
    ADVANCED_SMALL_SHIP_CONSTRUCTION  : 3395,
    ADVANCED_SPACESHIP_COMMAND  : 20342,
    ADVANCED_TARGET_MANAGEMENT  : 3430,
    ADVANCED_WEAPON_UPGRADES  : 11207,
    AE_K_COMPACT_DRONE_DAMAGE_AMPLIFIER  : 41034,
    AEGIS_COVERT_OPERATIONS_REPORTS  : 60753,
    AEGIS_FORTIFICATION_SCHEMATICS  : 60752,
    AEGIS_PERSONNEL_FILE_BACKUPS  : 60751,
    AEGIS_SECURITY_PATROL_REPORTS  : 60750,
    AEON  : 23919,
    AEON_BIOSECURITY_RESPONDERS_SKIN  : 55315,
    AEON_BLUEPRINT  : 23920,
    AEON_COLD_IRON_SKIN  : 42793,
    AEON_CROWN_AND_SWORDS_SKIN  : 56199,
    AEON_EOM_SKIN  : 36316,
    AEON_EROS_BLOSSOM_SKIN  : 47289,
    AEON_EXOPLANETS_HUNTER_SKIN  : 45802,
    AEON_GLACIAL_DRIFT_SKIN  : 46811,
    AEON_HEADHUNTER_SKIN  : 49422,
    AEON_INTERSTELLAR_CONVERGENCE_SKIN  : 62223,
    AEON_IRONBLOOD_SKIN  : 43522,
    AEON_KHANID_SKIN  : 34649,
    AEON_LAVACORE_IMPERIAL_SKIN  : 49823,
    AEON_OASIS_OF_FAITH_SKIN  : 53644,
    AEON_PURITY_OF_THE_THRONE_SKIN  : 42593,
    AEON_RAATA_SUNSET_SKIN  : 37522,
    AEON_SARUM_SKIN  : 34650,
    AEON_STAR_CAPTAIN_SKIN  : 48366,
    AEON_ULTRAVIOLENCE_VORTEX_SKIN  : 58798,
    AERGIA_COMPACT_MAGNETOMETRIC_ECM  : 19925,
    AFFIRMATIVE_MODIFIED_ENTOSIS_LINK  : 40309,
    AFOCAL_PRECISE_DUAL_GIGA_BEAM_LASER  : 41117,
    AFOCAL_PRECISE_DUAL_GIGA_BEAM_LASER_BLUEPRINT  : 41123,
    AFOCAL_PRECISE_DUAL_GIGA_PULSE_LASER  : 41102,
    AFOCAL_PRECISE_DUAL_GIGA_PULSE_LASER_BLUEPRINT  : 41108,
    AFOCAL_PRECISE_QUAD_MEGA_PULSE_LASER  : 41065,
    AFOCAL_PRECISE_QUAD_MEGA_PULSE_LASER_BLUEPRINT  : 41072,
    AFTERBURNER  : 3450,
    AG_COMPOSITE_MOLECULAR_CONDENSER  : 57446,
    AGENCY_HARDSHELL_TB3_DOSE_I  : 46001,
    AGENCY_HARDSHELL_TB5_DOSE_II  : 46002,
    AGENCY_HARDSHELL_TB7_DOSE_III  : 46003,
    AGENCY_HARDSHELL_TB9_DOSE_IV  : 48113,
    AGENCY_OVERCLOCKER_SB3_DOSE_I  : 46004,
    AGENCY_OVERCLOCKER_SB5_DOSE_II  : 46005,
    AGENCY_OVERCLOCKER_SB7_DOSE_III  : 46006,
    AGENCY_OVERCLOCKER_SB9_DOSE_IV  : 48114,
    AGENCY_PYROLANCEA_DB3_DOSE_I  : 45998,
    AGENCY_PYROLANCEA_DB5_DOSE_II  : 45999,
    AGENCY_PYROLANCEA_DB7_DOSE_III  : 46000,
    AGENCY_PYROLANCEA_DB9_DOSE_IV  : 48115,
    AGENCY_SUPPORT_DROP  : 46381,
    AGITATED_DARK_FILAMENT  : 47892,
    AGITATED_ELECTRICAL_FILAMENT  : 47904,
    AGITATED_EXOTIC_FILAMENT  : 47888,
    AGITATED_FIRESTORM_FILAMENT  : 47896,
    AGITATED_GAMMA_FILAMENT  : 47900,
    AHREMEN_ARKAHS_TAG  : 17300,
    AHREMENS_MODIFIED_CAP_RECHARGER  : 21818,
    AHREMENS_MODIFIED_CAPACITOR_POWER_RELAY  : 15090,
    AHREMENS_MODIFIED_DUAL_HEAVY_PULSE_LASER  : 14427,
    AHREMENS_MODIFIED_EM_ARMOR_HARDENER  : 15026,
    AHREMENS_MODIFIED_EM_COATING  : 14914,
    AHREMENS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14978,
    AHREMENS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15074,
    AHREMENS_MODIFIED_EXPLOSIVE_COATING  : 14898,
    AHREMENS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14994,
    AHREMENS_MODIFIED_HEAT_SINK  : 14810,
    AHREMENS_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15138,
    AHREMENS_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14842,
    AHREMENS_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14826,
    AHREMENS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15058,
    AHREMENS_MODIFIED_KINETIC_COATING  : 14882,
    AHREMENS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15010,
    AHREMENS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14853,
    AHREMENS_MODIFIED_LARGE_EMP_SMARTBOMB  : 14794,
    AHREMENS_MODIFIED_MEGA_PULSE_LASER  : 14443,
    AHREMENS_MODIFIED_MULTISPECTRUM_COATING  : 14866,
    AHREMENS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14946,
    AHREMENS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15106,
    AHREMENS_MODIFIED_REACTOR_CONTROL_UNIT  : 15122,
    AHREMENS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15042,
    AHREMENS_MODIFIED_THERMAL_COATING  : 14930,
    AHREMENS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14962,
    AIRLOCK_SLAMMER_DISPLAY  : 56977,
    ALFVEN_ENDURING_REMOTE_TRACKING_COMPUTER  : 5342,
    ALGOS  : 32872,
    ALGOS_ABYSSAL_FIRESTORM_SKIN  : 53140,
    ALGOS_BIOSECURITY_RESPONDERS_SKIN  : 55576,
    ALGOS_BLUEPRINT  : 32873,
    ALGOS_DEATHGLOW_REMNANT_SKIN  : 73371,
    ALGOS_EXOPLANETS_HUNTER_SKIN  : 45887,
    ALGOS_FEDERATION_NAVY_SKIN  : 58887,
    ALGOS_GLACIAL_DRIFT_SKIN  : 44181,
    ALGOS_GLITTERING_DREAM_SKIN  : 47526,
    ALGOS_INNER_ZONE_VANGUARD_SKIN  : 52722,
    ALGOS_INTAKI_SYNDICATE_SKIN  : 34706,
    ALGOS_INTERBUS_SKIN  : 34707,
    ALGOS_KOPIS_EDGE_SKIN  : 46399,
    ALGOS_LUMINAIRE_ZENITH_SKIN  : 48227,
    ALGOS_RUBY_SUNGRAZER_SKIN  : 45562,
    ALGOS_SAPPHIRE_SUNGRAZER_SKIN  : 46548,
    ALGOS_SERPENTIS_SKIN  : 42161,
    ALGOS_SPIRIT_SKIN  : 44905,
    ALGOS_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59425,
    ALGOS_TRIGLAVIAN_TWILIGHT_SKIN  : 52398,
    ALGOS_VALIMOR_LEGACY_SKIN  : 37560,
    ALGOS_YOIUL_STAR_SKIN  : 61126,
    ALICE_SAKIS_GOOD_POSTING_GUIDE  : 34434,
    ALLIANCE_TOURNAMENT_I_BAND_OF_BROTHERS  : 33223,
    ALLIANCE_TOURNAMENT_I_KAOS_EMPIRE  : 33385,
    ALLIANCE_TOURNAMENT_II_BAND_OF_BROTHERS  : 33224,
    ALLIANCE_TOURNAMENT_III_BAND_OF_BROTHERS  : 33225,
    ALLIANCE_TOURNAMENT_III_CULT_OF_WAR  : 33226,
    ALLIANCE_TOURNAMENT_III_INTERSTELLAR_ALCOHOL_CONGLOMERATE  : 33386,
    ALLIANCE_TOURNAMENT_IV_HUN_RELOADED  : 33227,
    ALLIANCE_TOURNAMENT_IV_PANDEMIC_LEGION  : 33387,
    ALLIANCE_TOURNAMENT_IV_STAR_FRACTION  : 33228,
    ALLIANCE_TOURNAMENT_IX_DARKSIDE  : 33391,
    ALLIANCE_TOURNAMENT_IX_HYDRA_RELOADED_AND_0UTBREAK  : 33373,
    ALLIANCE_TOURNAMENT_V_EV0KE  : 33229,
    ALLIANCE_TOURNAMENT_V_TRIUMVIRATE  : 33388,
    ALLIANCE_TOURNAMENT_VI_PANDEMIC_LEGION  : 33230,
    ALLIANCE_TOURNAMENT_VI_RUR  : 33390,
    ALLIANCE_TOURNAMENT_VII_PANDEMIC_LEGION  : 33231,
    ALLIANCE_TOURNAMENT_VIII_HYDRA_RELOADED  : 33389,
    ALLIANCE_TOURNAMENT_VIII_PANDEMIC_LEGION  : 33232,
    ALLIANCE_TOURNAMENT_X_HUN_RELOADED  : 33392,
    ALLIANCE_TOURNAMENT_X_VERGE_OF_COLLAPSE  : 33374,
    ALLIANCE_TOURNAMENT_XV_META  : 47057,
    ALLOTEK_INDUSTRIES_STRONG_BOX  : 43690,
    ALLOYED_TRITANIUM_BAR  : 25595,
    ALUMEL_WIRED_ENDURING_SENSOR_BOOSTER  : 6159,
    AMARR_BATTLECRUISER  : 33095,
    AMARR_BATTLESHIP  : 3339,
    AMARR_CARRIER  : 24311,
    AMARR_CONTROL_TOWER  : 12235,
    AMARR_CONTROL_TOWER_BLUEPRINT  : 2774,
    AMARR_CONTROL_TOWER_MEDIUM  : 20059,
    AMARR_CONTROL_TOWER_MEDIUM_BLUEPRINT  : 2775,
    AMARR_CONTROL_TOWER_SMALL  : 20060,
    AMARR_CONTROL_TOWER_SMALL_BLUEPRINT  : 2776,
    AMARR_CORE_SYSTEMS  : 30539,
    AMARR_CRUISER  : 3335,
    AMARR_DECODER_PACKAGE  : 73397,
    AMARR_DEFENSIVE_SYSTEMS  : 30532,
    AMARR_DESTROYER  : 33091,
    AMARR_DREADNOUGHT  : 20525,
    AMARR_DRONE_SPECIALIZATION  : 12484,
    AMARR_EMPIRE_SLAVE_TRADER_INSIGNIA  : 15980,
    AMARR_EMPIRE_STARBASE_CHARTER  : 24592,
    AMARR_ENCODER_SPLICE  : 73401,
    AMARR_ENCRYPTION_METHODS  : 23087,
    AMARR_FREIGHTER  : 20524,
    AMARR_FRIGATE  : 3331,
    AMARR_HAULER  : 3343,
    AMARR_INCEPTION_PACKAGE  : 43930,
    AMARR_KIU_1_NEXUS_CHIP  : 17794,
    AMARR_LISTENING_OUTPOST  : 73270,
    AMARR_LOYALTY_TOKEN  : 73250,
    AMARR_MIY_1_NEXUS_CHIP  : 17795,
    AMARR_OFFENSIVE_SYSTEMS  : 30537,
    AMARR_PHENOMENA_GENERATOR  : 43658,
    AMARR_PHENOMENA_GENERATOR_BLUEPRINT  : 43659,
    AMARR_PROPAGANDA_BROADCAST_STRUCTURE  : 73225,
    AMARR_PROPULSION_SYSTEMS  : 30538,
    AMARR_RELIGIOUS_MASK  : 49641,
    AMARR_SHUTTLE  : 11134,
    AMARR_SHUTTLE_BIOSECURITY_RESPONDERS_SKIN  : 55331,
    AMARR_SHUTTLE_BLUEPRINT  : 11135,
    AMARR_SHUTTLE_EXOPLANETS_HUNTER_SKIN  : 45751,
    AMARR_SHUTTLE_GLACIAL_DRIFT_SKIN  : 46773,
    AMARR_STARSHIP_ENGINEERING  : 11444,
    AMARR_STRATEGIC_CRUISER  : 30650,
    AMARR_TACTICAL_DESTROYER  : 34390,
    AMARR_TIL_1_NEXUS_CHIP  : 17793,
    AMARR_TITAN  : 3347,
    AMARRIAN_WHEAT  : 17424,
    AMBER_CYTOSEROCIN  : 25268,
    AMBER_MYKOSEROCIN  : 28694,
    AMETAT_I  : 40362,
    AMETAT_I_BLUEPRINT  : 41355,
    AMETAT_II  : 40560,
    AML_COMPACT_OMNIDIRECTIONAL_TRACKING_LINK  : 41031,
    AMMATAR_MANDATE_STARBASE_CHARTER  : 24597,
    AMMATAR_NAVY_CAP_RECHARGER  : 15788,
    AMMATAR_NAVY_CAPACITOR_POWER_RELAY  : 17510,
    AMMATAR_NAVY_CAPTAIN_INSIGNIA_I  : 15622,
    AMMATAR_NAVY_COLONEL_INSIGNIA_I  : 17999,
    AMMATAR_NAVY_COLONEL_INSIGNIA_II  : 15671,
    AMMATAR_NAVY_DUAL_HEAVY_BEAM_LASER  : 15859,
    AMMATAR_NAVY_DUAL_HEAVY_PULSE_LASER  : 15858,
    AMMATAR_NAVY_DUAL_LIGHT_BEAM_LASER  : 15857,
    AMMATAR_NAVY_DUAL_LIGHT_PULSE_LASER  : 15856,
    AMMATAR_NAVY_EM_ARMOR_HARDENER  : 17502,
    AMMATAR_NAVY_EM_COATING  : 17518,
    AMMATAR_NAVY_EM_ENERGIZED_MEMBRANE  : 17542,
    AMMATAR_NAVY_EXPLOSIVE_ARMOR_HARDENER  : 17504,
    AMMATAR_NAVY_EXPLOSIVE_COATING  : 17516,
    AMMATAR_NAVY_EXPLOSIVE_ENERGIZED_MEMBRANE  : 17540,
    AMMATAR_NAVY_FLEET_COMMANDER_INSIGNIA  : 15643,
    AMMATAR_NAVY_FOCUSED_MEDIUM_BEAM_LASER  : 15855,
    AMMATAR_NAVY_FOCUSED_MEDIUM_PULSE_LASER  : 15854,
    AMMATAR_NAVY_GATLING_PULSE_LASER  : 15853,
    AMMATAR_NAVY_HEAT_SINK  : 15808,
    AMMATAR_NAVY_HEAVY_BEAM_LASER  : 15852,
    AMMATAR_NAVY_HEAVY_CAPACITOR_BOOSTER  : 15778,
    AMMATAR_NAVY_HEAVY_ENERGY_NEUTRALIZER  : 15798,
    AMMATAR_NAVY_HEAVY_ENERGY_NOSFERATU  : 15879,
    AMMATAR_NAVY_HEAVY_PULSE_LASER  : 15851,
    AMMATAR_NAVY_KINETIC_ARMOR_HARDENER  : 17506,
    AMMATAR_NAVY_KINETIC_COATING  : 17512,
    AMMATAR_NAVY_KINETIC_ENERGIZED_MEMBRANE  : 17538,
    AMMATAR_NAVY_LARGE_ARMOR_REPAIRER  : 15743,
    AMMATAR_NAVY_LARGE_EMP_SMARTBOMB  : 15947,
    AMMATAR_NAVY_MAJOR_INSIGNIA_I  : 15623,
    AMMATAR_NAVY_MAJOR_INSIGNIA_II  : 15672,
    AMMATAR_NAVY_MEDIUM_ARMOR_REPAIRER  : 15742,
    AMMATAR_NAVY_MEDIUM_CAPACITOR_BOOSTER  : 15776,
    AMMATAR_NAVY_MEDIUM_EMP_SMARTBOMB  : 15945,
    AMMATAR_NAVY_MEDIUM_ENERGY_NEUTRALIZER  : 15796,
    AMMATAR_NAVY_MEDIUM_ENERGY_NOSFERATU  : 15877,
    AMMATAR_NAVY_MEGA_BEAM_LASER  : 15848,
    AMMATAR_NAVY_MEGA_PULSE_LASER  : 15847,
    AMMATAR_NAVY_MICRO_EMP_SMARTBOMB  : 15943,
    AMMATAR_NAVY_MIDSHIPMAN_INSIGNIA_I  : 15617,
    AMMATAR_NAVY_MIDSHIPMAN_INSIGNIA_II  : 15618,
    AMMATAR_NAVY_MIDSHIPMAN_INSIGNIA_III  : 15619,
    AMMATAR_NAVY_MULTISPECTRUM_COATING  : 17514,
    AMMATAR_NAVY_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 17536,
    AMMATAR_NAVY_POWER_DIAGNOSTIC_SYSTEM  : 17524,
    AMMATAR_NAVY_QUAD_BEAM_LASER  : 15846,
    AMMATAR_NAVY_RAID_LEADER_INSIGNIA  : 15641,
    AMMATAR_NAVY_REACTOR_CONTROL_UNIT  : 17522,
    AMMATAR_NAVY_SERGEANT_ELITE_INSIGNIA  : 15642,
    AMMATAR_NAVY_SERGEANT_INSIGNIA_I  : 15620,
    AMMATAR_NAVY_SERGEANT_INSIGNIA_II  : 15993,
    AMMATAR_NAVY_SERGEANT_INSIGNIA_III  : 16001,
    AMMATAR_NAVY_SERGEANT_MAJOR_INSIGNIA_I  : 15621,
    AMMATAR_NAVY_SMALL_ARMOR_REPAIRER  : 15741,
    AMMATAR_NAVY_SMALL_CAPACITOR_BOOSTER  : 15772,
    AMMATAR_NAVY_SMALL_EMP_SMARTBOMB  : 15941,
    AMMATAR_NAVY_SMALL_ENERGY_NEUTRALIZER  : 15794,
    AMMATAR_NAVY_SMALL_ENERGY_NOSFERATU  : 15875,
    AMMATAR_NAVY_SMALL_FOCUSED_BEAM_LASER  : 15850,
    AMMATAR_NAVY_SMALL_FOCUSED_PULSE_LASER  : 15849,
    AMMATAR_NAVY_SQUAD_LEADER_INSIGNIA  : 15640,
    AMMATAR_NAVY_TACHYON_BEAM_LASER  : 15845,
    AMMATAR_NAVY_THERMAL_ARMOR_HARDENER  : 17508,
    AMMATAR_NAVY_THERMAL_COATING  : 32809,
    AMMATAR_NAVY_THERMAL_ENERGIZED_MEMBRANE  : 17544,
    AMMATAR_SLAVE_TRADER_INSIGNIA  : 15979,
    AMMUNITION_ASSEMBLY_ARRAY  : 24658,
    AMPERUM_MUTANITE  : 77118,
    AMPLE_COMPOUNDS  : 41264,
    AMPLE_CONDUCTORS  : 41265,
    AMPLE_ELECTRONICS  : 41263,
    AMPLE_GALLIUM_QUAD_800MM_REPEATING_CANNON  : 41080,
    AMPLE_GALLIUM_QUAD_800MM_REPEATING_CANNON_BLUEPRINT  : 41094,
    ANALOG_PANEL  : 23162,
    ANATHEMA  : 11188,
    ANATHEMA_BIOSECURITY_RESPONDERS_SKIN  : 55342,
    ANATHEMA_BLUEPRINT  : 11189,
    ANATHEMA_COLD_IRON_SKIN  : 42754,
    ANATHEMA_CROWN_AND_SWORDS_SKIN  : 56191,
    ANATHEMA_EOM_SKIN  : 36289,
    ANATHEMA_EXOPLANETS_HUNTER_SKIN  : 45761,
    ANATHEMA_GALNET_STREAMCAST_UNIT_SKIN  : 57735,
    ANATHEMA_GLACIAL_DRIFT_SKIN  : 46776,
    ANATHEMA_IMPERIAL_JUBILEE_SKIN  : 48844,
    ANATHEMA_LUMINAIRE_RISING_SKIN  : 64406,
    ANATHEMA_OASIS_OF_FAITH_SKIN  : 53603,
    ANATHEMA_PURITY_OF_THE_THRONE_SKIN  : 42552,
    ANATHEMA_RAATA_SUNSET_SKIN  : 37492,
    ANATHEMA_STAR_CAPTAIN_SKIN  : 52573,
    ANATHEMA_VERSUS_REDFORCE_SKIN  : 62012,
    ANCHORING  : 11584,
    ANCIENT_COORDINATES_DATABASE  : 30746,
    ANCIENT_RADAR_DECORRELATOR  : 45662,
    ANGEL_ADVANCED_TRIGGER_MECHANISM  : 21729,
    ANGEL_BRASS_TAG  : 12529,
    ANGEL_BRONZE_TAG  : 17190,
    ANGEL_CONTROL_TOWER  : 27539,
    ANGEL_CONTROL_TOWER_MEDIUM  : 27607,
    ANGEL_CONTROL_TOWER_SMALL  : 27610,
    ANGEL_COPPER_TAG  : 12304,
    ANGEL_CRYSTAL_TAG  : 12531,
    ANGEL_DIAMOND_TAG  : 17192,
    ANGEL_DYNAMIC_CALIBRATOR  : 21733,
    ANGEL_ELECTRUM_TAG  : 17199,
    ANGEL_GOLD_TAG  : 17194,
    ANGEL_LARGE_ARTILLERY_BATTERY  : 27557,
    ANGEL_LARGE_AUTOCANNON_BATTERY  : 27554,
    ANGEL_MEDIUM_ARTILLERY_BATTERY  : 27644,
    ANGEL_MEDIUM_AUTOCANNON_BATTERY  : 27647,
    ANGEL_PALLADIUM_TAG  : 12530,
    ANGEL_PLATINUM_TAG  : 17196,
    ANGEL_SILVER_TAG  : 12528,
    ANGEL_SIMPLE_TRIGGER_MECHANISM  : 21731,
    ANGEL_SMALL_ARTILLERY_BATTERY  : 27650,
    ANGEL_SMALL_AUTOCANNON_BATTERY  : 27653,
    ANGEL_SPATIAL_ANALYZER  : 21732,
    ANGEL_STANDARD_TRIGGER_MECHANISM  : 21730,
    ANGEL_STASIS_WEBIFICATION_BATTERY  : 27570,
    ANGELS_ARISEN_FIREWORK  : 44261,
    ANGUIS_ICE_HARVESTER_UPGRADE  : 22621,
    ANIMAL_MEDICAL_EXPERT  : 33024,
    ANODE_ELECTRON_PARTICLE_CANNON_I  : 7625,
    ANODE_ION_PARTICLE_CANNON_I  : 7669,
    ANODE_LIGHT_ELECTRON_PARTICLE_CANNON_I  : 7493,
    ANODE_LIGHT_ION_PARTICLE_CANNON_I  : 7541,
    ANODE_LIGHT_NEUTRON_PARTICLE_CANNON_I  : 7585,
    ANODE_MEGA_ELECTRON_PARTICLE_CANNON_I  : 7749,
    ANODE_MEGA_ION_PARTICLE_CANNON_I  : 7833,
    ANODE_MEGA_NEUTRON_PARTICLE_CANNON_I  : 7789,
    ANODE_NEUTRON_PARTICLE_CANNON_I  : 7709,
    ANODE_SCOPED_DUAL_GIGA_BEAM_LASER  : 41116,
    ANODE_SCOPED_DUAL_GIGA_BEAM_LASER_BLUEPRINT  : 41122,
    ANODE_SCOPED_DUAL_GIGA_PULSE_LASER  : 41101,
    ANODE_SCOPED_DUAL_GIGA_PULSE_LASER_BLUEPRINT  : 41107,
    ANODE_SCOPED_ION_SIEGE_BLASTER  : 41128,
    ANODE_SCOPED_ION_SIEGE_BLASTER_BLUEPRINT  : 41134,
    ANODE_SCOPED_QUAD_MEGA_PULSE_LASER  : 41064,
    ANODE_SCOPED_QUAD_MEGA_PULSE_LASER_BLUEPRINT  : 41071,
    ANODE_SCOPED_TRIPLE_NEUTRON_BLASTER_CANNON  : 41077,
    ANODE_SCOPED_TRIPLE_NEUTRON_BLASTER_CANNON_BLUEPRINT  : 41088,
    ANSHAR  : 28848,
    ANSHAR_BIOSECURITY_RESPONDERS_SKIN  : 55625,
    ANSHAR_EXOPLANETS_HUNTER_SKIN  : 45930,
    ANSHAR_GLACIAL_DRIFT_SKIN  : 46975,
    ANSHAR_INNER_ZONE_VANGUARD_SKIN  : 52765,
    ANSHAR_INTAKI_SYNDICATE_SKIN  : 36405,
    ANSHAR_KOPIS_EDGE_SKIN  : 46442,
    ANSHAR_SERPENTIS_SKIN  : 42199,
    ANSHAR_SPIRIT_SKIN  : 44948,
    ANSHAR_VALIMOR_LEGACY_SKIN  : 37595,
    ANSIBLEX_JUMP_GATE  : 35841,
    ANSIBLEX_JUMP_GATE_BLUEPRINT  : 36961,
    ANTAEUS_I  : 40364,
    ANTAEUS_I_BLUEPRINT  : 41363,
    ANTAEUS_II  : 40562,
    ANTIBIOTICS  : 43,
    ANTIKYTHERA_ELEMENT  : 34575,
    ANTIMATTER_CHARGE_L  : 238,
    ANTIMATTER_CHARGE_L_BLUEPRINT  : 1153,
    ANTIMATTER_CHARGE_M  : 230,
    ANTIMATTER_CHARGE_M_BLUEPRINT  : 1145,
    ANTIMATTER_CHARGE_S  : 222,
    ANTIMATTER_CHARGE_S_BLUEPRINT  : 1137,
    ANTIMATTER_CHARGE_XL  : 17648,
    ANTIMATTER_CHARGE_XL_BLUEPRINT  : 17649,
    ANTIMATTER_REACTOR_UNIT  : 11549,
    ANTIMATTER_REACTOR_UNIT_BLUEPRINT  : 17338,
    ANTIPHARMAKON_AEOLIS  : 36912,
    ANTIPHARMAKON_IOKIRA  : 36910,
    ANTIPHARMAKON_KOSYBO  : 36909,
    ANTIPHARMAKON_THUREO  : 36908,
    ANTIPHARMAKON_TOXOT  : 36911,
    AOEDE_MINING_LASER_UPGRADE  : 22615,
    APOCALYPSE  : 642,
    APOCALYPSE_ABYSSAL_FIRESTORM_SKIN  : 53129,
    APOCALYPSE_ARDISHAPUR_SKIN  : 34627,
    APOCALYPSE_AURORA_UNIVERSALIS_SKIN  : 53365,
    APOCALYPSE_BIOSECURITY_RESPONDERS_SKIN  : 55311,
    APOCALYPSE_BLOOD_RAIDERS_SKIN  : 34792,
    APOCALYPSE_BLUEPRINT  : 996,
    APOCALYPSE_CAPSULEER_DAY_XV_SKIN  : 47625,
    APOCALYPSE_COLD_IRON_SKIN  : 42785,
    APOCALYPSE_CROWN_AND_SWORDS_SKIN  : 52775,
    APOCALYPSE_EXOPLANETS_HUNTER_SKIN  : 45794,
    APOCALYPSE_GLACIAL_DRIFT_SKIN  : 46803,
    APOCALYPSE_IGC_VICTORY_SKIN  : 44280,
    APOCALYPSE_IMPERIAL_ISSUE  : 11936,
    APOCALYPSE_IMPERIAL_JUBILEE_SKIN  : 48854,
    APOCALYPSE_IRONBLOOD_SKIN  : 43516,
    APOCALYPSE_KADOR_SKIN  : 34599,
    APOCALYPSE_KHANID_SKIN  : 34628,
    APOCALYPSE_LAVACORE_IMPERIAL_SKIN  : 49817,
    APOCALYPSE_NAVY_ISSUE  : 17726,
    APOCALYPSE_NAVY_ISSUE_ARDISHAPUR_SKIN  : 60676,
    APOCALYPSE_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55336,
    APOCALYPSE_NAVY_ISSUE_CAPSULEER_ELITE_XX_SKIN  : 77167,
    APOCALYPSE_NAVY_ISSUE_COLD_IRON_SKIN  : 42789,
    APOCALYPSE_NAVY_ISSUE_CROWN_AND_SWORDS_SKIN  : 52776,
    APOCALYPSE_NAVY_ISSUE_EOM_SKIN  : 36306,
    APOCALYPSE_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45798,
    APOCALYPSE_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46807,
    APOCALYPSE_NAVY_ISSUE_IMPERIAL_JUBILEE_SKIN  : 48855,
    APOCALYPSE_NAVY_ISSUE_LAVACORE_IMPERIAL_SKIN  : 49820,
    APOCALYPSE_NAVY_ISSUE_OASIS_OF_FAITH_SKIN  : 53640,
    APOCALYPSE_NAVY_ISSUE_PURITY_OF_THE_THRONE_SKIN  : 42589,
    APOCALYPSE_NAVY_ISSUE_SANGUINARY_SAVANT_SKIN  : 73452,
    APOCALYPSE_NAVY_ISSUE_SARUM_SKIN  : 56790,
    APOCALYPSE_NAVY_ISSUE_STAR_CAPTAIN_SKIN  : 52583,
    APOCALYPSE_OASIS_OF_FAITH_SKIN  : 53636,
    APOCALYPSE_PURITY_OF_THE_THRONE_SKIN  : 42585,
    APOCALYPSE_RAATA_SUNSET_SKIN  : 37516,
    APOCALYPSE_SANGUINARY_SAVANT_SKIN  : 73451,
    APOCALYPSE_STAR_CAPTAIN_SKIN  : 45666,
    APOCALYPSE_TASH_MURKON_SKIN  : 34600,
    APOCALYPSE_TRIGLAVIAN_TWILIGHT_SKIN  : 52392,
    APOCALYPSE_WAR_RESERVES_LIEUTENANT_SKIN  : 59216,
    APOCALYPSE_ZAKURA_SHUMYU_SKIN  : 57059,
    APOSTLE  : 37604,
    APOSTLE_BIOSECURITY_RESPONDERS_SKIN  : 55320,
    APOSTLE_BLUEPRINT  : 41582,
    APOSTLE_CAPSULEER_ELITE_XV_SKIN  : 47640,
    APOSTLE_COLD_IRON_SKIN  : 42908,
    APOSTLE_CROWN_AND_SWORDS_SKIN  : 56196,
    APOSTLE_EXOPLANETS_HUNTER_SKIN  : 46146,
    APOSTLE_FRONTIER_SAFEGUARDER_SKIN  : 54434,
    APOSTLE_GLACIAL_DRIFT_SKIN  : 47049,
    APOSTLE_HEADHUNTER_SKIN  : 49421,
    APOSTLE_IMPERIAL_JUBILEE_SKIN  : 48858,
    APOSTLE_LAVACORE_IMPERIAL_SKIN  : 49825,
    APOSTLE_OASIS_OF_FAITH_SKIN  : 53653,
    APOSTLE_PURITY_OF_THE_THRONE_SKIN  : 42653,
    APOSTLE_RAATA_SUNSET_SKIN  : 41680,
    APOSTLE_STAR_CAPTAIN_SKIN  : 52588,
    APOTHEOSIS  : 29266,
    APOTHEOSIS_CAPSULEER_DAY_XVI_SKIN  : 52281,
    APOTHEOSIS_CAPSULEER_ELITE_XV_SKIN  : 47647,
    APOTHEOSIS_CAPSULEER_NETWORKER_XV_SKIN  : 47652,
    APOTHEOSIS_GLACIAL_DRIFT_SKIN  : 47011,
    APOTHEOSIS_ICECLOUD_INVESTIGATORS_SKIN  : 49794,
    APOTHEOSIS_IRONBLOOD_SKIN  : 43532,
    APOTHEOSIS_MIDNIGHT_SKIN  : 44258,
    APOTHEOSIS_SILVER_RACER_SKIN  : 47550,
    APOTHEOSIS_VERSUS_BLUEFORCE_SKIN  : 47547,
    APOTHEOSIS_VERSUS_REDFORCE_SKIN  : 47544,
    AQUEOUS_LIQUIDS  : 2268,
    ARALEZ  : 60766,
    ARALEZ_BLUEPRINT  : 60769,
    ARAZU  : 11969,
    ARAZU_BIOSECURITY_RESPONDERS_SKIN  : 55619,
    ARAZU_BLUEPRINT  : 11970,
    ARAZU_EXOPLANETS_HUNTER_SKIN  : 45900,
    ARAZU_GLACIAL_DRIFT_SKIN  : 46946,
    ARAZU_INNER_ZONE_VANGUARD_SKIN  : 52735,
    ARAZU_INTAKI_SYNDICATE_SKIN  : 36382,
    ARAZU_KOPIS_EDGE_SKIN  : 46412,
    ARAZU_SERPENTIS_SKIN  : 42174,
    ARAZU_SPIRIT_SKIN  : 44918,
    ARAZU_VALIMOR_LEGACY_SKIN  : 37571,
    ARBALEST_COMPACT_LIGHT_MISSILE_LAUNCHER  : 8089,
    ARBALEST_COMPACT_RAPID_TORPEDO_LAUNCHER  : 41223,
    ARBALEST_COMPACT_RAPID_TORPEDO_LAUNCHER_BLUEPRINT  : 41226,
    ARBALEST_COMPACT_XL_CRUISE_MISSILE_LAUNCHER  : 41175,
    ARBALEST_COMPACT_XL_CRUISE_MISSILE_LAUNCHER_BLUEPRINT  : 41177,
    ARBALEST_COMPACT_XL_TORPEDO_LAUNCHER  : 41180,
    ARBALEST_COMPACT_XL_TORPEDO_LAUNCHER_BLUEPRINT  : 41183,
    ARBALEST_CRUISE_LAUNCHER_I  : 16519,
    ARBALEST_HEAVY_MISSILE_LAUNCHER  : 8105,
    ARBALEST_RAPID_HEAVY_MISSILE_LAUNCHER_I  : 33440,
    ARBALEST_ROCKET_LAUNCHER_I  : 16527,
    ARBITRATOR  : 628,
    ARBITRATOR_ABYSSAL_FIRESTORM_SKIN  : 53130,
    ARBITRATOR_ARDISHAPUR_SKIN  : 34676,
    ARBITRATOR_BIOSECURITY_RESPONDERS_SKIN  : 55326,
    ARBITRATOR_BLOOD_RAIDERS_SKIN  : 56797,
    ARBITRATOR_BLUEPRINT  : 973,
    ARBITRATOR_COLD_IRON_SKIN  : 42766,
    ARBITRATOR_EXOPLANETS_HUNTER_SKIN  : 45774,
    ARBITRATOR_GLACIAL_DRIFT_SKIN  : 44142,
    ARBITRATOR_IMPERIAL_NAVY_SKIN  : 58899,
    ARBITRATOR_IRONBLOOD_SKIN  : 43500,
    ARBITRATOR_KADOR_SKIN  : 34677,
    ARBITRATOR_LUMINAIRE_ZENITH_SKIN  : 48220,
    ARBITRATOR_OASIS_OF_FAITH_SKIN  : 53616,
    ARBITRATOR_PURITY_OF_THE_THRONE_SKIN  : 42565,
    ARBITRATOR_RAATA_SUNSET_SKIN  : 37500,
    ARBITRATOR_TRIGLAVIAN_TWILIGHT_SKIN  : 52407,
    ARCH_ANGEL_CARBONIZED_LEAD_L  : 20721,
    ARCH_ANGEL_CARBONIZED_LEAD_M  : 19994,
    ARCH_ANGEL_CARBONIZED_LEAD_S  : 19970,
    ARCH_ANGEL_CARBONIZED_LEAD_XL  : 20737,
    ARCH_ANGEL_DEPLETED_URANIUM_L  : 20727,
    ARCH_ANGEL_DEPLETED_URANIUM_M  : 20000,
    ARCH_ANGEL_DEPLETED_URANIUM_S  : 19976,
    ARCH_ANGEL_DEPLETED_URANIUM_XL  : 20739,
    ARCH_ANGEL_EMP_L  : 20735,
    ARCH_ANGEL_EMP_M  : 20008,
    ARCH_ANGEL_EMP_S  : 19992,
    ARCH_ANGEL_EMP_XL  : 20741,
    ARCH_ANGEL_FUSION_L  : 20731,
    ARCH_ANGEL_FUSION_M  : 20004,
    ARCH_ANGEL_FUSION_S  : 19988,
    ARCH_ANGEL_FUSION_XL  : 20743,
    ARCH_ANGEL_NUCLEAR_L  : 20723,
    ARCH_ANGEL_NUCLEAR_M  : 19996,
    ARCH_ANGEL_NUCLEAR_S  : 19972,
    ARCH_ANGEL_NUCLEAR_XL  : 20745,
    ARCH_ANGEL_PHASED_PLASMA_L  : 20733,
    ARCH_ANGEL_PHASED_PLASMA_M  : 20006,
    ARCH_ANGEL_PHASED_PLASMA_S  : 19990,
    ARCH_ANGEL_PHASED_PLASMA_XL  : 20747,
    ARCH_ANGEL_PROTON_L  : 20725,
    ARCH_ANGEL_PROTON_M  : 19998,
    ARCH_ANGEL_PROTON_S  : 19974,
    ARCH_ANGEL_PROTON_XL  : 20749,
    ARCH_ANGEL_TITANIUM_SABOT_L  : 20729,
    ARCH_ANGEL_TITANIUM_SABOT_M  : 20002,
    ARCH_ANGEL_TITANIUM_SABOT_S  : 19986,
    ARCH_ANGEL_TITANIUM_SABOT_XL  : 20751,
    ARCHAEOLOGY  : 13278,
    ARCHON  : 23757,
    ARCHON_BIOSECURITY_RESPONDERS_SKIN  : 55316,
    ARCHON_BLUEPRINT  : 23758,
    ARCHON_COLD_IRON_SKIN  : 42792,
    ARCHON_CROWN_AND_SWORDS_SKIN  : 56198,
    ARCHON_EOM_SKIN  : 36315,
    ARCHON_EXOPLANETS_HUNTER_SKIN  : 45801,
    ARCHON_GLACIAL_DRIFT_SKIN  : 46810,
    ARCHON_HUNTERS_QUIVER_SKIN  : 47496,
    ARCHON_IMPERIAL_JUBILEE_SKIN  : 46539,
    ARCHON_IRONBLOOD_SKIN  : 43521,
    ARCHON_KADOR_SKIN  : 34647,
    ARCHON_OASIS_OF_FAITH_SKIN  : 53643,
    ARCHON_PURITY_OF_THE_THRONE_SKIN  : 42592,
    ARCHON_RAATA_SUNSET_SKIN  : 37521,
    ARCHON_SPIRIT_SKIN  : 47309,
    ARCHON_STAR_CAPTAIN_SKIN  : 52589,
    ARCHON_TASH_MURKON_SKIN  : 34648,
    ARES  : 11202,
    ARES_BIOSECURITY_RESPONDERS_SKIN  : 55631,
    ARES_BLUEPRINT  : 11203,
    ARES_EXOPLANETS_HUNTER_SKIN  : 45882,
    ARES_GLACIAL_DRIFT_SKIN  : 46934,
    ARES_HUNTERS_QUIVER_SKIN  : 57536,
    ARES_INNER_ZONE_VANGUARD_SKIN  : 52717,
    ARES_INTAKI_SYNDICATE_SKIN  : 36380,
    ARES_KOPIS_EDGE_SKIN  : 46394,
    ARES_LUMINAIRE_RISING_SKIN  : 64412,
    ARES_LUMINAIRE_ZENITH_SKIN  : 64397,
    ARES_SERPENTIS_SKIN  : 42158,
    ARES_SPIRIT_SKIN  : 44900,
    ARES_VALIMOR_LEGACY_SKIN  : 37558,
    ARK  : 28850,
    ARK_BIOSECURITY_RESPONDERS_SKIN  : 55352,
    ARK_COLD_IRON_SKIN  : 42800,
    ARK_CROWN_AND_SWORDS_SKIN  : 56197,
    ARK_EOM_SKIN  : 36320,
    ARK_EXOPLANETS_HUNTER_SKIN  : 45809,
    ARK_GLACIAL_DRIFT_SKIN  : 46818,
    ARK_IMPERIAL_JUBILEE_SKIN  : 48861,
    ARK_OASIS_OF_FAITH_SKIN  : 53651,
    ARK_PURITY_OF_THE_THRONE_SKIN  : 42600,
    ARK_RAATA_SUNSET_SKIN  : 37529,
    ARK_STAR_CAPTAIN_SKIN  : 48368,
    ARK_YOIUL_FESTIVAL_YC121_SKIN  : 49948,
    ARK_YOIUL_STAR_SKIN  : 53423,
    ARKONOR  : 22,
    ARMAGEDDON  : 643,
    ARMAGEDDON_BIOSECURITY_RESPONDERS_SKIN  : 55317,
    ARMAGEDDON_BLAZE_SQUADRON_SKIN  : 47102,
    ARMAGEDDON_BLUEPRINT  : 997,
    ARMAGEDDON_COLD_IRON_SKIN  : 42786,
    ARMAGEDDON_CROWN_AND_SWORDS_SKIN  : 52777,
    ARMAGEDDON_DEATHGLOW_REMNANT_SKIN  : 60664,
    ARMAGEDDON_EXOPLANETS_HUNTER_SKIN  : 45795,
    ARMAGEDDON_GLACIAL_DRIFT_SKIN  : 46804,
    ARMAGEDDON_HUNTERS_QUIVER_SKIN  : 47493,
    ARMAGEDDON_IMPERIAL_ISSUE  : 11938,
    ARMAGEDDON_IMPERIAL_JUBILEE_SKIN  : 46534,
    ARMAGEDDON_IRONBLOOD_SKIN  : 43517,
    ARMAGEDDON_KADOR_SKIN  : 34625,
    ARMAGEDDON_LAVACORE_IMPERIAL_SKIN  : 49818,
    ARMAGEDDON_MALAGRIN_MARSHLIGHT_SKIN  : 56592,
    ARMAGEDDON_NAVY_ISSUE  : 32305,
    ARMAGEDDON_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55335,
    ARMAGEDDON_NAVY_ISSUE_COLD_IRON_SKIN  : 42790,
    ARMAGEDDON_NAVY_ISSUE_CROWN_AND_SWORDS_SKIN  : 52778,
    ARMAGEDDON_NAVY_ISSUE_EOM_SKIN  : 36323,
    ARMAGEDDON_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45799,
    ARMAGEDDON_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46808,
    ARMAGEDDON_NAVY_ISSUE_HUNTERS_QUIVER_SKIN  : 57776,
    ARMAGEDDON_NAVY_ISSUE_IMPERIAL_JUBILEE_SKIN  : 48856,
    ARMAGEDDON_NAVY_ISSUE_LAVACORE_IMPERIAL_SKIN  : 49821,
    ARMAGEDDON_NAVY_ISSUE_OASIS_OF_FAITH_SKIN  : 53641,
    ARMAGEDDON_NAVY_ISSUE_PURITY_OF_THE_THRONE_SKIN  : 42590,
    ARMAGEDDON_NAVY_ISSUE_SARUM_SKIN  : 56791,
    ARMAGEDDON_NAVY_ISSUE_SPIRIT_SKIN  : 57387,
    ARMAGEDDON_NAVY_ISSUE_STAR_CAPTAIN_SKIN  : 52584,
    ARMAGEDDON_OASIS_OF_FAITH_SKIN  : 53637,
    ARMAGEDDON_PURITY_OF_THE_THRONE_SKIN  : 42586,
    ARMAGEDDON_RAATA_SUNSET_SKIN  : 37517,
    ARMAGEDDON_SPIRIT_SKIN  : 47307,
    ARMAGEDDON_STAR_CAPTAIN_SKIN  : 45667,
    ARMAGEDDON_TASH_MURKON_SKIN  : 34626,
    ARMAGEDDON_WAR_RESERVES_LIEUTENANT_SKIN  : 59212,
    ARMOR_BLOCKS  : 21590,
    ARMOR_COMMAND_BURST_I  : 42526,
    ARMOR_COMMAND_BURST_I_BLUEPRINT  : 43547,
    ARMOR_COMMAND_BURST_II  : 43552,
    ARMOR_EM_RESISTANCE_SCRIPT  : 41517,
    ARMOR_EM_RESISTANCE_SCRIPT_BLUEPRINT  : 41590,
    ARMOR_ENERGIZING_CHARGE  : 42832,
    ARMOR_ENERGIZING_CHARGE_BLUEPRINT  : 42874,
    ARMOR_EXPLOSIVE_RESISTANCE_SCRIPT  : 41520,
    ARMOR_EXPLOSIVE_RESISTANCE_SCRIPT_BLUEPRINT  : 41593,
    ARMOR_KINETIC_RESISTANCE_SCRIPT  : 41519,
    ARMOR_KINETIC_RESISTANCE_SCRIPT_BLUEPRINT  : 41592,
    ARMOR_LAYERING  : 33078,
    ARMOR_PLATES  : 25605,
    ARMOR_PLATING_FROM_TES_SERAPH  : 44216,
    ARMOR_REINFORCEMENT_CHARGE  : 42834,
    ARMOR_REINFORCEMENT_CHARGE_BLUEPRINT  : 42875,
    ARMOR_RIGGING  : 26253,
    ARMOR_THERMAL_RESISTANCE_SCRIPT  : 41518,
    ARMOR_THERMAL_RESISTANCE_SCRIPT_BLUEPRINT  : 41591,
    ARMORED_COMMAND  : 20494,
    ARMORED_COMMAND_MINDLINK  : 13209,
    ARMORED_COMMAND_SPECIALIST  : 11569,
    ARMS_RACE_BATTLECRUISER_ASSEMBLER  : 46771,
    ARMS_RACE_BATTLESHIP_ASSEMBLER  : 46772,
    ARQUEBUS_HEAVY_BEAM_LASER_I  : 23844,
    ARTIFICIAL_NEURAL_NETWORK  : 25616,
    ASHIMMU  : 17922,
    ASHIMMU_AURORA_UNIVERSALIS_SKIN  : 60909,
    ASHIMMU_GLACIAL_DRIFT_SKIN  : 46820,
    ASHIMMU_IRONBLOOD_SKIN  : 60666,
    ASHIMMU_LUMINAE_POLARIS_SKIN  : 75271,
    ASHIMMU_MALAGRIN_MARSHLIGHT_SKIN  : 53020,
    ASHIMMU_VAMPIRES_HEX_SKIN  : 57817,
    ASSASSINATION_CONTRACT_MIRIAL  : 33218,
    ASSAULT_DAMAGE_CONTROL_I  : 47254,
    ASSAULT_DAMAGE_CONTROL_I_BLUEPRINT  : 47259,
    ASSAULT_DAMAGE_CONTROL_II  : 47257,
    ASSAULT_FRIGATES  : 12095,
    ASTARTE  : 22466,
    ASTARTE_BIOSECURITY_RESPONDERS_SKIN  : 55612,
    ASTARTE_BLUEPRINT  : 22467,
    ASTARTE_EXOPLANETS_HUNTER_SKIN  : 45908,
    ASTARTE_GLACIAL_DRIFT_SKIN  : 46954,
    ASTARTE_INNER_ZONE_VANGUARD_SKIN  : 52743,
    ASTARTE_INTAKI_SYNDICATE_SKIN  : 36400,
    ASTARTE_KOPIS_EDGE_SKIN  : 46420,
    ASTARTE_SERPENTIS_SKIN  : 42180,
    ASTARTE_SPIRIT_SKIN  : 44926,
    ASTARTE_VALIMOR_LEGACY_SKIN  : 37576,
    ASTERO  : 33468,
    ASTERO_AIR_LABORATORIES_SKIN  : 60156,
    ASTERO_ASPIRANT_EXPLORER_SKIN  : 60227,
    ASTERO_CRYSTAL_BLAST_SKIN  : 47249,
    ASTERO_DEATHGLOW_HUNTERS_SKIN  : 49435,
    ASTERO_FRIENDSHIP_LEAGUE_SKIN  : 60097,
    ASTERO_GLACIAL_DRIFT_SKIN  : 47013,
    ASTERO_ONE_EDEN_SKIN  : 47375,
    ASTERO_SANCTUARY_SKIN  : 35690,
    ASTERO_SCOPE_SYNDICATION_YC122_SKIN  : 56880,
    ASTERO_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60225,
    ASTERO_SOE_BLUE_CELL  : 45018,
    ASTERO_SOE_FIRE_CELL  : 44219,
    ASTERO_SOE_GOLD_CELL  : 45483,
    ASTERO_SPECTRAL_SHIFT_SKIN  : 46520,
    ASTERO_YOIUL_FESTIVAL_YC121_SKIN  : 49961,
    ASTERO_YOIUL_STAR_SKIN  : 53436,
    ASTRAHUS  : 35832,
    ASTRAHUS_BLUEPRINT  : 36966,
    ASTRAHUS_UPWELL_QUANTUM_CORE  : 56201,
    ASTRAL_MINING_INC_STRONG_BOX  : 43688,
    ASTRO_INERTIAL_COMPACT_MISSILE_GUIDANCE_COMPUTER  : 35789,
    ASTROGEOLOGY  : 3410,
    ASTROMETRIC_ACQUISITION  : 25811,
    ASTROMETRIC_PINPOINTING  : 25810,
    ASTROMETRIC_RANGEFINDING  : 25739,
    ASTROMETRICS  : 3412,
    ASTRONAUTIC_ENGINEERING  : 11487,
    ASTRONAUTICS_RIGGING  : 26254,
    ATGEIR_EXPLOSIVE_DISRUPTIVE_LANCE  : 77398,
    ATGEIR_EXPLOSIVE_DISRUPTIVE_LANCE_BLUEPRINT  : 77402,
    ATHANOR  : 35835,
    ATHANOR_BLUEPRINT  : 36977,
    ATHANOR_UPWELL_QUANTUM_CORE  : 56202,
    ATMOSPHERIC_GASES  : 16634,
    ATRON  : 608,
    ATRON_AURORA_UNIVERSALIS_SKIN  : 53375,
    ATRON_BIOSECURITY_RESPONDERS_SKIN  : 55596,
    ATRON_BLUEPRINT  : 955,
    ATRON_EXOPLANETS_HUNTER_SKIN  : 45871,
    ATRON_GLACIAL_DRIFT_SKIN  : 44174,
    ATRON_HUNTERS_QUIVER_SKIN  : 47503,
    ATRON_INNER_ZONE_VANGUARD_SKIN  : 52706,
    ATRON_INTAKI_SYNDICATE_SKIN  : 34736,
    ATRON_INTERBUS_SKIN  : 34737,
    ATRON_KOPIS_EDGE_SKIN  : 46383,
    ATRON_LUMINAIRE_ZENITH_SKIN  : 48226,
    ATRON_SERPENTIS_SKIN  : 42147,
    ATRON_SPIRIT_SKIN  : 44889,
    ATRON_VALIMOR_LEGACY_SKIN  : 37547,
    ATRON_VERSUS_BLUEFORCE_SKIN  : 63144,
    ATRON_ZAKURA_HANSEI_SKIN  : 57181,
    ATTAINMENT_DECRYPTOR  : 34202,
    AUGMENTATION_DECRYPTOR  : 34203,
    AUGMENTED_ACOLYTE  : 28264,
    AUGMENTED_BERSERKER  : 28268,
    AUGMENTED_EIFYRIUM  : 74532,
    AUGMENTED_HAMMERHEAD  : 28272,
    AUGMENTED_HOBGOBLIN  : 28276,
    AUGMENTED_HORNET  : 28280,
    AUGMENTED_ICE_HARVESTING_DRONE  : 43701,
    AUGMENTED_INFILTRATOR  : 28284,
    AUGMENTED_MINING_DRONE  : 43694,
    AUGMENTED_OGRE  : 28288,
    AUGMENTED_PRAETOR  : 28292,
    AUGMENTED_VALKYRIE  : 28296,
    AUGMENTED_VESPA  : 28300,
    AUGMENTED_WARRIOR  : 28304,
    AUGMENTED_WASP  : 28308,
    AUGOROR  : 625,
    AUGOROR_ARDISHAPUR_SKIN  : 34678,
    AUGOROR_AURORA_UNIVERSALIS_SKIN  : 60908,
    AUGOROR_BIOSECURITY_RESPONDERS_SKIN  : 55329,
    AUGOROR_BLUEPRINT  : 970,
    AUGOROR_COLD_IRON_SKIN  : 42767,
    AUGOROR_COMBAT_MEDIC_SECTION_SKIN  : 48719,
    AUGOROR_EXOPLANETS_HUNTER_SKIN  : 45775,
    AUGOROR_GLACIAL_DRIFT_SKIN  : 44143,
    AUGOROR_IRONBLOOD_SKIN  : 43501,
    AUGOROR_KHANID_SKIN  : 34679,
    AUGOROR_NAVY_ISSUE  : 29337,
    AUGOROR_NAVY_ISSUE_ARDISHAPUR_SKIN  : 60675,
    AUGOROR_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55333,
    AUGOROR_NAVY_ISSUE_COLD_IRON_SKIN  : 42776,
    AUGOROR_NAVY_ISSUE_EOM_SKIN  : 36322,
    AUGOROR_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45785,
    AUGOROR_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46794,
    AUGOROR_NAVY_ISSUE_OASIS_OF_FAITH_SKIN  : 53627,
    AUGOROR_NAVY_ISSUE_PURITY_OF_THE_THRONE_SKIN  : 42576,
    AUGOROR_NAVY_ISSUE_SARUM_SKIN  : 56792,
    AUGOROR_NAVY_ISSUE_SERENE_ROU_SKIN  : 49155,
    AUGOROR_NAVY_ISSUE_SPIRIT_SKIN  : 57385,
    AUGOROR_NAVY_ISSUE_STAR_CAPTAIN_SKIN  : 52576,
    AUGOROR_OASIS_OF_FAITH_SKIN  : 53617,
    AUGOROR_PURITY_OF_THE_THRONE_SKIN  : 42566,
    AUGOROR_RAATA_SUNSET_SKIN  : 37501,
    AUGOROR_SPIRIT_SKIN  : 47305,
    AURA_WARP_CORE_STABILIZER_I  : 22875,
    AURORA_L  : 12824,
    AURORA_M  : 12822,
    AURORA_OMINAE_THERMAL_DOOMSDAY  : 24554,
    AURORA_OMINAE_THERMAL_DOOMSDAY_BLUEPRINT  : 24555,
    AURORA_S  : 12559,
    AURORA_XL  : 41336,
    AUTO_INTEGRITY_PRESERVATION_SEAL  : 57478,
    AUTO_INTEGRITY_PRESERVATION_SEAL_BLUEPRINT  : 57515,
    AUTO_TARGETING_MISSILES  : 3322,
    AUTO_TARGETING_SYSTEM_I  : 1182,
    AUTO_TARGETING_SYSTEM_I_BLUEPRINT  : 1208,
    AUTO_TARGETING_SYSTEM_II  : 1436,
    AUTOMATED_TARGETING_UNIT_I  : 6047,
    AUTOTROPHS  : 2305,
    AUTRECH_VOUCHER  : 19463,
    AUXILIARY_PARTS  : 23159,
    AV_COMPOSITE_MOLECULAR_CONDENSER  : 57448,
    AVATAR  : 11567,
    AVATAR_BIOSECURITY_RESPONDERS_SKIN  : 55313,
    AVATAR_BLUEPRINT  : 11568,
    AVATAR_CAPSULEER_ELITE_XV_SKIN  : 47639,
    AVATAR_CAPSULEER_ELITE_XX_SKIN  : 77168,
    AVATAR_COLD_IRON_SKIN  : 42794,
    AVATAR_CROWN_AND_SWORDS_SKIN  : 52781,
    AVATAR_DEATHGLOW_HUNTERS_SKIN  : 49432,
    AVATAR_EOM_SKIN  : 36293,
    AVATAR_EROS_BLOSSOM_SKIN  : 50071,
    AVATAR_EXOPLANETS_HUNTER_SKIN  : 45803,
    AVATAR_GLACIAL_DRIFT_SKIN  : 46812,
    AVATAR_HEADHUNTER_SKIN  : 46711,
    AVATAR_IMPERIAL_JUBILEE_SKIN  : 46540,
    AVATAR_INTERSTELLAR_CONVERGENCE_SKIN  : 62222,
    AVATAR_IRONBLOOD_SKIN  : 43523,
    AVATAR_KADOR_SKIN  : 34672,
    AVATAR_KHANID_SKIN  : 34673,
    AVATAR_LAVACORE_IMPERIAL_SKIN  : 49824,
    AVATAR_MALAGRIN_MARSHLIGHT_SKIN  : 53009,
    AVATAR_OASIS_OF_FAITH_SKIN  : 53645,
    AVATAR_PURITY_OF_THE_THRONE_SKIN  : 42594,
    AVATAR_RAATA_SUNSET_SKIN  : 37523,
    AVATAR_STAR_CAPTAIN_SKIN  : 48367,
    AVATAR_STARLINE_MAJESTIC_SKIN  : 55722,
    AVATAR_YOIUL_STAR_SKIN  : 53422,
    AXOSOMATIC_NEUROLINK_ENHANCER  : 57460,
    AXOSOMATIC_NEUROLINK_ENHANCER_REACTION_FORMULA  : 57497,
    AZBEL  : 35826,
    AZBEL_BLUEPRINT  : 36972,
    AZBEL_UPWELL_QUANTUM_CORE  : 56206,
    AZMARU_ELECTROMAGNETIC_DISRUPTIVE_LANCE  : 77399,
    AZMARU_ELECTROMAGNETIC_DISRUPTIVE_LANCE_BLUEPRINT  : 77403,
    AZURE_CYTOSEROCIN  : 25279,
    AZURE_MYKOSEROCIN  : 28695,
    AZURE_PLAGIOCLASE  : 17455,
    BACTERIA  : 2393,
    BADGER  : 648,
    BADGER_BIOSECURITY_RESPONDERS_SKIN  : 55515,
    BADGER_BLUE_TIGER_SKIN  : 36745,
    BADGER_BLUEPRINT  : 983,
    BADGER_EXOPLANETS_HUNTER_SKIN  : 45863,
    BADGER_GHOSTBIRD_SKIN  : 48206,
    BADGER_GLACIAL_DRIFT_SKIN  : 46867,
    BADGER_MATIGU_SEABEAST_SKIN  : 40621,
    BADGER_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 70836,
    BADGER_RAATA_SUNSET_SKIN  : 36673,
    BADGER_STEEL_CARDINAL_SKIN  : 46140,
    BADGER_SUKUUVESTAA_SKIN  : 56090,
    BADGER_WIYRKOMI_SKIN  : 60106,
    BADGER_WIYRKOMI_SKIN2  : 36333,
    BADGER_ZENTO_ISIDEKO_COMBINE_SKIN  : 53200,
    BADLY_CORRUPTED_HOLOREEL  : 47054,
    BAG_OF_GELATINIZED_CONFECTIONARY  : 62048,
    BAILEY_1600MM_STEEL_PLATES  : 23785,
    BAKER_NUNN_ENDURING_TRACKING_DISRUPTOR_I  : 5319,
    BALEFIRE_ROCKET_LAUNCHER  : 20593,
    BALLISTA_TACHYON_BEAM_LASER_I  : 23850,
    BALLISTIC_CONTROL_SYSTEM_I  : 12274,
    BALLISTIC_CONTROL_SYSTEM_I_BLUEPRINT  : 12275,
    BALLISTIC_CONTROL_SYSTEM_II  : 22291,
    BALLISTIC_DEFLECTION_ARRAY  : 17184,
    BALLISTIC_DEFLECTION_ARRAY_BLUEPRINT  : 2755,
    BALMER_SERIES_COMPACT_TRACKING_DISRUPTOR_I  : 5320,
    BAND_OF_BROTHERS_DIRECTOR_ACCESS_KEY  : 33214,
    BANE  : 77283,
    BANTAM  : 582,
    BANTAM_BIOSECURITY_RESPONDERS_SKIN  : 55532,
    BANTAM_BLUE_TIGER_SKIN  : 36705,
    BANTAM_BLUEPRINT  : 683,
    BANTAM_COMBAT_MEDIC_SECTION_SKIN  : 60198,
    BANTAM_DEATHGLOW_REMNANT_SKIN  : 56785,
    BANTAM_EXOPLANETS_HUNTER_SKIN  : 45812,
    BANTAM_GHOSTBIRD_SKIN  : 48155,
    BANTAM_GLACIAL_DRIFT_SKIN  : 44148,
    BANTAM_GURISTAS_SKIN  : 40770,
    BANTAM_MATIGU_SEABEAST_SKIN  : 40579,
    BANTAM_RAATA_SUNSET_SKIN  : 36633,
    BANTAM_STEEL_CARDINAL_SKIN  : 46089,
    BANTAM_ZENTO_ISIDEKO_COMBINE_SKIN  : 53149,
    BARBICAN_800MM_STEEL_PLATES  : 23793,
    BARBICAN_ELEMENT  : 34557,
    BARBICAN_INDEX  : 34552,
    BARBICAN_SEQUENCE_0  : 34542,
    BARBICAN_SEQUENCE_1  : 34543,
    BARGHEST  : 33820,
    BARGHEST_BLAZE_SQUADRON_SKIN  : 47106,
    BARGHEST_CONVERGENCE___NOVA_SKIN  : 44255,
    BARGHEST_GHOSTS_HEX_SKIN  : 57812,
    BARGHEST_GLACIAL_DRIFT_SKIN  : 47005,
    BARGHEST_IGC_SKIN  : 60756,
    BARGHEST_LUMINAE_POLARIS_SKIN  : 75268,
    BARGHEST_RED_STARGAZER_SKIN  : 75908,
    BARGHEST_REDCLAW_SABLE_SKIN  : 57787,
    BARGHEST_TRIUMPHANT_ELITE_SKIN  : 62375,
    BARGHEST_WASCHI_UPRISING_COMBAT_SKIN  : 37869,
    BARGHEST_WASCHI_UPRISING_PARADE_SKIN  : 37967,
    BARIUM_FIREWORK  : 33572,
    BARIUM_FIREWORK_CXIV  : 32994,
    BARRAGE_L  : 12775,
    BARRAGE_M  : 12773,
    BARRAGE_S  : 12625,
    BARRAGE_TORPEDO_LAUNCHER  : 20603,
    BARRAGE_XL  : 41316,
    BARREN_COMMAND_CENTER  : 2524,
    BARYON_EXOTIC_PLASMA_L  : 47932,
    BARYON_EXOTIC_PLASMA_M  : 47928,
    BARYON_EXOTIC_PLASMA_S  : 47924,
    BARYON_EXOTIC_PLASMA_XL  : 52916,
    BASE_METALS  : 2267,
    BASIC_ARDOUR_CEREBRAL_ACCELERATOR_BLUEPRINT_CRATE  : 50029,
    BASIC_CAPACITOR_FLUX_COIL  : 509,
    BASIC_CAPACITOR_POWER_RELAY  : 506,
    BASIC_CAPACITOR_RECHARGER  : 421,
    BASIC_CO_PROCESSOR  : 3469,
    BASIC_DAMAGE_CONTROL  : 521,
    BASIC_EM_COATING  : 1193,
    BASIC_EM_ENERGIZED_MEMBRANE  : 11215,
    BASIC_EM_SHIELD_AMPLIFIER  : 1798,
    BASIC_EXPANDED_CARGOHOLD  : 1315,
    BASIC_EXPLOSIVE_COATING  : 1262,
    BASIC_EXPLOSIVE_ENERGIZED_MEMBRANE  : 11225,
    BASIC_EXPLOSIVE_SHIELD_AMPLIFIER  : 1804,
    BASIC_GYROSTABILIZER  : 518,
    BASIC_HEAT_SINK  : 1893,
    BASIC_INERTIAL_STABILIZERS  : 1401,
    BASIC_KINETIC_COATING  : 1282,
    BASIC_KINETIC_ENERGIZED_MEMBRANE  : 11245,
    BASIC_KINETIC_SHIELD_AMPLIFIER  : 1802,
    BASIC_LAYERED_COATING  : 1272,
    BASIC_LAYERED_ENERGIZED_MEMBRANE  : 11235,
    BASIC_MAGNETIC_FIELD_STABILIZER  : 10188,
    BASIC_MULTISPECTRUM_COATING  : 1302,
    BASIC_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 11265,
    BASIC_NANOFIBER_INTERNAL_STRUCTURE  : 1242,
    BASIC_OVERDRIVE_INJECTOR_SYSTEM  : 1192,
    BASIC_POWER_DIAGNOSTIC_SYSTEM  : 1537,
    BASIC_REACTOR_CONTROL_UNIT  : 1351,
    BASIC_REINFORCED_BULKHEADS  : 1240,
    BASIC_SHIELD_FLUX_COIL  : 508,
    BASIC_SHIELD_POWER_RELAY  : 1419,
    BASIC_SIGNAL_AMPLIFIER  : 1949,
    BASIC_THERMAL_COATING  : 1292,
    BASIC_THERMAL_ENERGIZED_MEMBRANE  : 11255,
    BASIC_THERMAL_SHIELD_AMPLIFIER  : 1800,
    BASIC_TRACKING_ENHANCER  : 1951,
    BASILISK  : 11985,
    BASILISK_BIOSECURITY_RESPONDERS_SKIN  : 55556,
    BASILISK_BLUE_TIGER_SKIN  : 36728,
    BASILISK_BLUEPRINT  : 11986,
    BASILISK_EDENS_HUNTERS_SKIN  : 54908,
    BASILISK_EMERGENCY_RESPONSE_SQUAD_SKIN  : 48724,
    BASILISK_EROS_BLOSSOM_SKIN  : 50075,
    BASILISK_EXOPLANETS_HUNTER_SKIN  : 45840,
    BASILISK_FRONTIER_SAFEGUARDER_SKIN  : 54439,
    BASILISK_GHOSTBIRD_SKIN  : 48183,
    BASILISK_GLACIAL_DRIFT_SKIN  : 46844,
    BASILISK_KYBERNAUT_CLADE_SKIN  : 54917,
    BASILISK_MATIGU_SEABEAST_SKIN  : 40604,
    BASILISK_RAATA_SUNSET_SKIN  : 36656,
    BASILISK_STEEL_CARDINAL_SKIN  : 46117,
    BASILISK_VITALSHIFT_EGO_SKIN  : 52557,
    BASILISK_WIYRKOMI_SKIN  : 36343,
    BASILISK_ZENTO_ISIDEKO_COMBINE_SKIN  : 53177,
    BASTION_400MM_STEEL_PLATES  : 23789,
    BASTION_MODULE_I  : 33400,
    BASTION_MODULE_I_BLUEPRINT  : 33401,
    BATCH_COMPRESSED_ARKONOR  : 28367,
    BATCH_COMPRESSED_AZURE_PLAGIOCLASE  : 28421,
    BATCH_COMPRESSED_BISTOT  : 28388,
    BATCH_COMPRESSED_BRIGHT_SPODUMAIN  : 28418,
    BATCH_COMPRESSED_BRILLIANT_GNEISS  : 46695,
    BATCH_COMPRESSED_CONCENTRATED_VELDSPAR  : 28430,
    BATCH_COMPRESSED_CONDENSED_SCORDITE  : 28427,
    BATCH_COMPRESSED_CRIMSON_ARKONOR  : 28385,
    BATCH_COMPRESSED_CROKITE  : 28391,
    BATCH_COMPRESSED_CRYSTALLINE_CROKITE  : 28392,
    BATCH_COMPRESSED_CUBIC_BISTOT  : 46692,
    BATCH_COMPRESSED_DARK_OCHRE  : 28394,
    BATCH_COMPRESSED_DAZZLING_SPODUMAIN  : 46704,
    BATCH_COMPRESSED_DENSE_VELDSPAR  : 28431,
    BATCH_COMPRESSED_FIERY_KERNITE  : 28409,
    BATCH_COMPRESSED_FLAWLESS_ARKONOR  : 46691,
    BATCH_COMPRESSED_GLAZED_HEDBERGITE  : 28400,
    BATCH_COMPRESSED_GLEAMING_SPODUMAIN  : 28419,
    BATCH_COMPRESSED_GLOSSY_SCORDITE  : 46703,
    BATCH_COMPRESSED_GNEISS  : 28397,
    BATCH_COMPRESSED_GOLDEN_OMBER  : 28415,
    BATCH_COMPRESSED_HEDBERGITE  : 28401,
    BATCH_COMPRESSED_HEMORPHITE  : 28403,
    BATCH_COMPRESSED_IMMACULATE_JASPET  : 46698,
    BATCH_COMPRESSED_IRIDESCENT_GNEISS  : 28398,
    BATCH_COMPRESSED_JASPET  : 28406,
    BATCH_COMPRESSED_JET_OCHRE  : 46694,
    BATCH_COMPRESSED_KERNITE  : 28410,
    BATCH_COMPRESSED_LUMINOUS_KERNITE  : 28411,
    BATCH_COMPRESSED_LUSTROUS_HEDBERGITE  : 46696,
    BATCH_COMPRESSED_MAGMA_MERCOXIT  : 28412,
    BATCH_COMPRESSED_MASSIVE_SCORDITE  : 28428,
    BATCH_COMPRESSED_MERCOXIT  : 28413,
    BATCH_COMPRESSED_MONOCLINIC_BISTOT  : 28389,
    BATCH_COMPRESSED_OBSIDIAN_OCHRE  : 28395,
    BATCH_COMPRESSED_OMBER  : 28416,
    BATCH_COMPRESSED_ONYX_OCHRE  : 28396,
    BATCH_COMPRESSED_OPULENT_PYROXERES  : 46702,
    BATCH_COMPRESSED_PELLUCID_CROKITE  : 46693,
    BATCH_COMPRESSED_PLAGIOCLASE  : 28422,
    BATCH_COMPRESSED_PLATINOID_OMBER  : 46700,
    BATCH_COMPRESSED_PRIME_ARKONOR  : 28387,
    BATCH_COMPRESSED_PRISMATIC_GNEISS  : 28399,
    BATCH_COMPRESSED_PRISTINE_JASPET  : 28407,
    BATCH_COMPRESSED_PURE_JASPET  : 28408,
    BATCH_COMPRESSED_PYROXERES  : 28424,
    BATCH_COMPRESSED_RADIANT_HEMORPHITE  : 28404,
    BATCH_COMPRESSED_RESPLENDANT_KERNITE  : 46699,
    BATCH_COMPRESSED_RICH_PLAGIOCLASE  : 28423,
    BATCH_COMPRESSED_SCINTILLATING_HEMORPHITE  : 46697,
    BATCH_COMPRESSED_SCORDITE  : 28429,
    BATCH_COMPRESSED_SHARP_CROKITE  : 28393,
    BATCH_COMPRESSED_SILVERY_OMBER  : 28417,
    BATCH_COMPRESSED_SOLID_PYROXERES  : 28425,
    BATCH_COMPRESSED_SPARKLING_PLAGIOCLASE  : 46701,
    BATCH_COMPRESSED_SPODUMAIN  : 28420,
    BATCH_COMPRESSED_STABLE_VELDSPAR  : 46705,
    BATCH_COMPRESSED_TRICLINIC_BISTOT  : 28390,
    BATCH_COMPRESSED_VELDSPAR  : 28432,
    BATCH_COMPRESSED_VISCOUS_PYROXERES  : 28426,
    BATCH_COMPRESSED_VITREOUS_MERCOXIT  : 28414,
    BATCH_COMPRESSED_VITRIC_HEDBERGITE  : 28402,
    BATCH_COMPRESSED_VIVID_HEMORPHITE  : 28405,
    BEATNIK_SMALL_REMOTE_ARMOR_REPAIRER  : 22947,
    BELLICOSE  : 630,
    BELLICOSE_BIOSECURITY_RESPONDERS_SKIN  : 55637,
    BELLICOSE_BLUE_TIGER_SKIN  : 36814,
    BELLICOSE_BLUEPRINT  : 975,
    BELLICOSE_DAWN_OF_LIBERATION_SKIN  : 48501,
    BELLICOSE_EXOPLANETS_HUNTER_SKIN  : 45954,
    BELLICOSE_GLACIAL_DRIFT_SKIN  : 44170,
    BELLICOSE_HAZARD_CONTROL_SKIN  : 56922,
    BELLICOSE_KRUSUAL_SKIN  : 34697,
    BELLICOSE_REPUBLIC_FLEET_SKIN  : 58869,
    BELLICOSE_SEMIOTIQUE_SPORTS_SKIN  : 60262,
    BELLICOSE_SNOWLINE_BLADERACER_SKIN  : 52865,
    BELLICOSE_TRONHADAR_INK_SKIN  : 40484,
    BELLICOSE_VALKLEAR_GLORY_SKIN  : 36767,
    BERSERKER_I  : 2476,
    BERSERKER_I_BLUEPRINT  : 2477,
    BERSERKER_II  : 2478,
    BERSERKER_SW_900  : 23536,
    BERSERKER_SW_900_BLUEPRINT  : 23537,
    BERSERKER_TP_900  : 23512,
    BERSERKER_TP_900_BLUEPRINT  : 23513,
    BESTLA  : 74316,
    BESTOWER  : 1944,
    BESTOWER_BIOSECURITY_RESPONDERS_SKIN  : 55312,
    BESTOWER_BLUEPRINT  : 1945,
    BESTOWER_COLD_IRON_SKIN  : 42795,
    BESTOWER_EXOPLANETS_HUNTER_SKIN  : 45804,
    BESTOWER_GLACIAL_DRIFT_SKIN  : 46813,
    BESTOWER_LODESTRIKE_SKIN  : 46744,
    BESTOWER_OASIS_OF_FAITH_SKIN  : 53646,
    BESTOWER_PURITY_OF_THE_THRONE_SKIN  : 42595,
    BESTOWER_RAATA_SUNSET_SKIN  : 37524,
    BESTOWER_TASH_MURKON_SKIN  : 34752,
    BESTOWER_WAR_RESERVES_LIEUTENANT_SKIN  : 70799,
    BEZDNACINE  : 52316,
    BEZDNACINE_PROCESSING  : 56631,
    BHAALGORN  : 17920,
    BHAALGORN_BLOOD_RAIDER_VICTORY_SKIN  : 43535,
    BHAALGORN_GLACIAL_DRIFT_SKIN  : 46821,
    BHAALGORN_IRONBLOOD_SKIN  : 60667,
    BHAALGORN_LUMINAE_POLARIS_SKIN  : 75272,
    BHAALGORN_MALAGRIN_MARSHLIGHT_SKIN  : 53021,
    BHAALGORN_RED_STARGAZER_SKIN  : 75899,
    BHAALGORN_REDCLAW_SABLE_SKIN  : 56954,
    BHAALGORN_VAMPIRES_HEX_SKIN  : 57818,
    BIFROST  : 37480,
    BIFROST_BIOSECURITY_RESPONDERS_SKIN  : 55693,
    BIFROST_EDENS_HUNTERS_SKIN  : 54910,
    BIFROST_EXOPLANETS_HUNTER_SKIN  : 45951,
    BIFROST_GLACIAL_DRIFT_SKIN  : 46890,
    BIFROST_HAZARD_CONTROL_SKIN  : 56900,
    BIFROST_KYBERNAUT_CLADE_SKIN  : 54919,
    BIFROST_LIBERATION_GAMES_SKIN  : 59470,
    BIFROST_SNOWLINE_BLADERACER_SKIN  : 52862,
    BIFROST_TRONHADAR_INK_SKIN  : 40517,
    BIFROST_VALKLEAR_GLORY_SKIN  : 40326,
    BIOCELLS  : 2329,
    BIOCHEMICAL_REACTOR_ARRAY  : 24684,
    BIOCHEMICAL_SILO  : 25270,
    BIOFUELS  : 2396,
    BIOLOGY  : 3405,
    BIOMASS  : 3779,
    BIOMECHANICAL_ARMOR_FRAGMENT  : 62053,
    BIOTECH_RESEARCH_REPORTS  : 2358,
    BISTOT  : 1223,
    BISTOT_PROCESSING  : 12181,
    BITUMENS  : 45492,
    BITWAVE_GLACIER_AUGMENTATION_CRATE  : 49804,
    BLACK_DAGGER  : 49644,
    BLACK_EAGLE_DRONE_LINK_AUGMENTOR  : 32262,
    BLACK_MARKET_VALDIMAR_DRONE_DURABILITY_DD_1001  : 54543,
    BLACK_MARKET_VALDIMAR_DRONE_DURABILITY_DD_1003  : 54544,
    BLACK_MARKET_VALDIMAR_DRONE_DURABILITY_DD_1005  : 54545,
    BLACK_MARKET_VALDIMAR_DRONE_NAVIGATION_DN_701  : 54534,
    BLACK_MARKET_VALDIMAR_DRONE_NAVIGATION_DN_703  : 54535,
    BLACK_MARKET_VALDIMAR_DRONE_NAVIGATION_DN_705  : 54536,
    BLACK_MARKET_VALDIMAR_DRONE_SHARPSHOOTING_DS_801  : 54537,
    BLACK_MARKET_VALDIMAR_DRONE_SHARPSHOOTING_DS_803  : 54538,
    BLACK_MARKET_VALDIMAR_DRONE_SHARPSHOOTING_DS_805  : 54539,
    BLACK_MARKET_VALDIMAR_REPAIR_DRONE_OPERATION_DR_901  : 54540,
    BLACK_MARKET_VALDIMAR_REPAIR_DRONE_OPERATION_DR_903  : 54541,
    BLACK_MARKET_VALDIMAR_REPAIR_DRONE_OPERATION_DR_905  : 54542,
    BLACK_OPS  : 28656,
    BLACKBIRD  : 632,
    BLACKBIRD_BIOSECURITY_RESPONDERS_SKIN  : 55514,
    BLACKBIRD_BLUE_TIGER_SKIN  : 36721,
    BLACKBIRD_BLUEPRINT  : 977,
    BLACKBIRD_CALDARI_NAVY_SKIN  : 58879,
    BLACKBIRD_EXOPLANETS_HUNTER_SKIN  : 45833,
    BLACKBIRD_GHOSTBIRD_SKIN  : 48176,
    BLACKBIRD_GLACIAL_DRIFT_SKIN  : 44157,
    BLACKBIRD_GURISTAS_SKIN  : 54559,
    BLACKBIRD_LUMINAIRE_ZENITH_SKIN  : 48224,
    BLACKBIRD_MATIGU_SEABEAST_SKIN  : 40597,
    BLACKBIRD_RAATA_SUNSET_SKIN  : 36649,
    BLACKBIRD_STEEL_CARDINAL_SKIN  : 46110,
    BLACKBIRD_ZENTO_ISIDEKO_COMBINE_SKIN  : 53170,
    BLAQUE_VOUCHER  : 19461,
    BLASTSHOT_CONDENSER_PACK_L  : 54779,
    BLASTSHOT_CONDENSER_PACK_M  : 54775,
    BLASTSHOT_CONDENSER_PACK_S  : 54771,
    BLOOD_BRASS_TAG  : 17204,
    BLOOD_BRONZE_TAG  : 12532,
    BLOOD_COATED_STATUETTE  : 49646,
    BLOOD_CONTROL_TOWER  : 27530,
    BLOOD_CONTROL_TOWER_MEDIUM  : 27589,
    BLOOD_CONTROL_TOWER_SMALL  : 27592,
    BLOOD_COPPER_TAG  : 17200,
    BLOOD_CRYSTAL_TAG  : 12536,
    BLOOD_DAGGER_FIREWORK  : 44262,
    BLOOD_DIAMOND_TAG  : 17201,
    BLOOD_ELECTRUM_TAG  : 17203,
    BLOOD_ENERGY_NEUTRALIZING_BATTERY  : 27857,
    BLOOD_GAMMA_L  : 21236,
    BLOOD_GAMMA_M  : 21220,
    BLOOD_GAMMA_S  : 21206,
    BLOOD_GAMMA_XL  : 21252,
    BLOOD_GOLD_TAG  : 25239,
    BLOOD_INFRARED_L  : 21228,
    BLOOD_INFRARED_M  : 21212,
    BLOOD_INFRARED_S  : 21198,
    BLOOD_INFRARED_XL  : 21244,
    BLOOD_LARGE_BEAM_LASER_BATTERY  : 27551,
    BLOOD_LARGE_PULSE_LASER_BATTERY  : 27548,
    BLOOD_MEDIUM_BEAM_LASER_BATTERY  : 27625,
    BLOOD_MEDIUM_PULSE_LASER_BATTERY  : 27628,
    BLOOD_MICROWAVE_L  : 21226,
    BLOOD_MICROWAVE_M  : 21210,
    BLOOD_MICROWAVE_S  : 21196,
    BLOOD_MICROWAVE_XL  : 21242,
    BLOOD_MULTIFREQUENCY_L  : 21238,
    BLOOD_MULTIFREQUENCY_M  : 21222,
    BLOOD_MULTIFREQUENCY_S  : 21208,
    BLOOD_MULTIFREQUENCY_XL  : 21254,
    BLOOD_PALLADIUM_TAG  : 17202,
    BLOOD_PLATINUM_TAG  : 12535,
    BLOOD_RADIO_L  : 21224,
    BLOOD_RADIO_M  : 21450,
    BLOOD_RADIO_S  : 21194,
    BLOOD_RADIO_XL  : 21240,
    BLOOD_RAIDER_CHEMOSH_BLUEPRINT_ARMORED_CACHE  : 45678,
    BLOOD_RAIDER_DAGON_BLUEPRINT_ARMORED_CACHE  : 45679,
    BLOOD_RAIDER_EXTREME_BALLISTIC_CONTROL  : 23150,
    BLOOD_RAIDER_LIMITED_BALLISTIC_CONTROL  : 23148,
    BLOOD_RAIDER_MOLOK_BLUEPRINT_ARMORED_CACHE  : 45677,
    BLOOD_RAIDER_POWER_REDISTRIBUTOR  : 23152,
    BLOOD_RAIDER_REGULAR_BALLISTIC_CONTROL  : 23149,
    BLOOD_RAIDER_STANDUP_MODULE_BLUEPRINT_ARMORED_CACHE  : 46579,
    BLOOD_RAIDER_STRONG_BOX  : 43696,
    BLOOD_RAIDER_VICTORY_SKIN  : 43935,
    BLOOD_RAIDER_WEAPON_INTEGRATION_UNIT  : 23151,
    BLOOD_SILVER_TAG  : 12533,
    BLOOD_SMALL_BEAM_LASER_BATTERY  : 27631,
    BLOOD_SMALL_PULSE_LASER_BATTERY  : 27634,
    BLOOD_STANDARD_L  : 21230,
    BLOOD_STANDARD_M  : 21214,
    BLOOD_STANDARD_S  : 21200,
    BLOOD_STANDARD_XL  : 21246,
    BLOOD_ULTRAVIOLET_L  : 21232,
    BLOOD_ULTRAVIOLET_M  : 21216,
    BLOOD_ULTRAVIOLET_S  : 21202,
    BLOOD_ULTRAVIOLET_XL  : 21248,
    BLOOD_UPPER_TIER_TAG  : 12534,
    BLOOD_XRAY_L  : 21234,
    BLOOD_XRAY_M  : 21218,
    BLOOD_XRAY_S  : 21204,
    BLOOD_XRAY_XL  : 21250,
    BLTZN_05L_LOWSEC_ICE_STORM_FILAMENT  : 74432,
    BLUE_ICE  : 16264,
    BLUE_PILL  : 3707,
    BOMB_DEPLOYMENT  : 28073,
    BOMB_LAUNCHER_I  : 27914,
    BOMB_LAUNCHER_I_BLUEPRINT  : 27915,
    BOMB_LAUNCHER_II  : 4256,
    BOOBOOK  : 64034,
    BOOBOOK_VERSUS_BLUEFORCE_SKIN  : 64190,
    BOOBOOK_VERSUS_REDFORCE_SKIN  : 64189,
    BOOSTED_EIFYRIUM  : 74531,
    BOOTLEG_REMOTE_SENSOR_BOOSTER  : 22925,
    BOOTLEG_YTIRIUM  : 74526,
    BOOTSINI  : 33064,
    BORDER_15_POCHVEN_FILAMENT  : 56077,
    BORDER_5_POCHVEN_FILAMENT  : 56076,
    BOSONIC_FIELD_GENERATOR  : 40633,
    BOSONIC_FIELD_GENERATOR_BLUEPRINT  : 41532,
    BOUNCER_I  : 23563,
    BOUNCER_I_BLUEPRINT  : 23564,
    BOUNCER_II  : 28215,
    BOUNTIFUL_LOPARITE  : 46316,
    BOUNTIFUL_MONAZITE  : 46314,
    BOUNTIFUL_XENOTIME  : 46312,
    BOUNTIFUL_YTTERBITE  : 46318,
    BOUQUET_OF_FLOWERS  : 54162,
    BOWHEAD  : 34328,
    BOWHEAD_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43758,
    BOWHEAD_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43811,
    BOWHEAD_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43824,
    BOWHEAD_BLUEPRINT  : 34329,
    BOWHEAD_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43771,
    BOWHEAD_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43785,
    BOWHEAD_GLACIAL_DRIFT_SKIN  : 46990,
    BOWHEAD_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43837,
    BOWHEAD_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43798,
    BOWHEAD_MORPHITE_SHINE_SKIN  : 42623,
    BOWHEAD_PAYDIRT_PROSPECTOR_SKIN  : 42680,
    BOWHEAD_RADIOACTIVES_RECLAMATION_SKIN  : 60958,
    BOWHEAD_ROCKBREAKER_PIONEERS_SKIN  : 56614,
    BOWHEAD_ROSADA_DAWN_SKIN  : 43672,
    BOWHEAD_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43850,
    BOX_OF_CHOCOLATES  : 54161,
    BREACHER  : 598,
    BREACHER_BIOSECURITY_RESPONDERS_SKIN  : 55639,
    BREACHER_BLUE_TIGER_SKIN  : 36798,
    BREACHER_BLUEPRINT  : 945,
    BREACHER_BRUTOR_SKIN  : 59869,
    BREACHER_DAWN_OF_LIBERATION_SKIN  : 48485,
    BREACHER_EXOPLANETS_HUNTER_SKIN  : 45933,
    BREACHER_GLACIAL_DRIFT_SKIN  : 44164,
    BREACHER_HAZARD_CONTROL_SKIN  : 56917,
    BREACHER_HUNTERS_QUIVER_SKIN  : 47507,
    BREACHER_KRUSUAL_SKIN  : 59829,
    BREACHER_NEFANTAR_SKIN  : 59821,
    BREACHER_REPUBLIC_FLEET_SKIN  : 58865,
    BREACHER_SEBIESTOR_SKIN  : 59836,
    BREACHER_SNOWLINE_BLADERACER_SKIN  : 52844,
    BREACHER_STARKMANIR_SKIN  : 59861,
    BREACHER_THUKKER_TRIBE_SKIN  : 59845,
    BREACHER_TRONHADAR_INK_SKIN  : 40467,
    BREACHER_VALKLEAR_GLORY_SKIN  : 36751,
    BREACHER_VHEROKIOR_SKIN  : 59853,
    BREACHER_ZAKURA_HANSEI_SKIN  : 57182,
    BRIBE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 55862,
    BRIGHT_SPODUMAIN  : 17466,
    BRILLIANT_GNEISS  : 46679,
    BRIMFUL_BITUMENS  : 46284,
    BRIMFUL_COESITE  : 46286,
    BRIMFUL_SYLVITE  : 46282,
    BRIMFUL_ZEOLITES  : 46280,
    BROADCAST_NODE  : 2867,
    BROADSWORD  : 12013,
    BROADSWORD_BIOSECURITY_RESPONDERS_SKIN  : 55690,
    BROADSWORD_BLOODY_HANDS_SKIN  : 47163,
    BROADSWORD_BLUE_TIGER_SKIN  : 36820,
    BROADSWORD_DAWN_OF_LIBERATION_SKIN  : 48507,
    BROADSWORD_EXOPLANETS_HUNTER_SKIN  : 45960,
    BROADSWORD_FIREWALL_BREACH_SKIN  : 46018,
    BROADSWORD_GLACIAL_DRIFT_SKIN  : 46897,
    BROADSWORD_HAZARD_CONTROL_SKIN  : 56896,
    BROADSWORD_HEARTSURGE_SKIN  : 76282,
    BROADSWORD_JUSTICE_SKIN  : 36434,
    BROADSWORD_SNOWLINE_BLADERACER_SKIN  : 52871,
    BROADSWORD_TRONHADAR_INK_SKIN  : 40490,
    BROADSWORD_VALKLEAR_GLORY_SKIN  : 36773,
    BROKARA_RYVERS_TAG  : 17290,
    BROKARAS_MODIFIED_CAP_RECHARGER  : 16599,
    BROKARAS_MODIFIED_CAPACITOR_POWER_RELAY  : 15080,
    BROKARAS_MODIFIED_DUAL_HEAVY_PULSE_LASER  : 14429,
    BROKARAS_MODIFIED_EM_ARMOR_HARDENER  : 15016,
    BROKARAS_MODIFIED_EM_COATING  : 14904,
    BROKARAS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14968,
    BROKARAS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15064,
    BROKARAS_MODIFIED_EXPLOSIVE_COATING  : 14888,
    BROKARAS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14984,
    BROKARAS_MODIFIED_HEAT_SINK  : 14800,
    BROKARAS_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15128,
    BROKARAS_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14832,
    BROKARAS_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14816,
    BROKARAS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15048,
    BROKARAS_MODIFIED_KINETIC_COATING  : 14872,
    BROKARAS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15000,
    BROKARAS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14848,
    BROKARAS_MODIFIED_LARGE_EMP_SMARTBOMB  : 14784,
    BROKARAS_MODIFIED_MEGA_PULSE_LASER  : 14445,
    BROKARAS_MODIFIED_MULTISPECTRUM_COATING  : 14856,
    BROKARAS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14936,
    BROKARAS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15096,
    BROKARAS_MODIFIED_REACTOR_CONTROL_UNIT  : 15112,
    BROKARAS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15032,
    BROKARAS_MODIFIED_THERMAL_COATING  : 14920,
    BROKARAS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14952,
    BROKEN_BROADCAST_NODE  : 2375,
    BROKEN_DRONE_TRANSCEIVER  : 25596,
    BROKEN_INTEGRITY_RESPONSE_DRONES  : 2376,
    BROKEN_NANO_FACTORY  : 2371,
    BROKEN_ORGANIC_MORTAR_APPLICATORS  : 2368,
    BROKEN_RECURSIVE_COMPUTING_MODULE  : 2374,
    BROKEN_SELF_HARMONIZING_POWER_CORE  : 2373,
    BROKEN_SKILL_CANISTER  : 54968,
    BROKEN_STERILE_CONDUITS  : 2369,
    BROKEN_WETWARE_MAINFRAME  : 2377,
    BROKER_RELATIONS  : 3446,
    BRUTIX  : 16229,
    BRUTIX_ABYSSAL_FIRESTORM_SKIN  : 53244,
    BRUTIX_AIR_LABORATORIES_SKIN  : 60267,
    BRUTIX_BIOSECURITY_RESPONDERS_SKIN  : 55581,
    BRUTIX_BLUEPRINT  : 16230,
    BRUTIX_CAPSULEER_DAY_XVII_SKIN  : 54788,
    BRUTIX_CORDITE_BLOSSOM_SKIN  : 48836,
    BRUTIX_DEATHGLOW_REMNANT_SKIN  : 73367,
    BRUTIX_DRAGON_PHOENIX_SKIN  : 54430,
    BRUTIX_EXOPLANETS_HUNTER_SKIN  : 45905,
    BRUTIX_FEDERAL_POLICE_SKIN  : 52436,
    BRUTIX_GLACIAL_DRIFT_SKIN  : 46951,
    BRUTIX_IGC_SKIN  : 48558,
    BRUTIX_INNER_ZONE_VANGUARD_SKIN  : 52740,
    BRUTIX_INTAKI_SYNDICATE_SKIN  : 34612,
    BRUTIX_KOPIS_EDGE_SKIN  : 46417,
    BRUTIX_LUMINAIRE_RISING_SKIN  : 64570,
    BRUTIX_MALAGRIN_MARSHLIGHT_SKIN  : 53013,
    BRUTIX_NAVY_ISSUE  : 33151,
    BRUTIX_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55607,
    BRUTIX_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45910,
    BRUTIX_NAVY_ISSUE_FEDERAL_POLICE_SKIN  : 52437,
    BRUTIX_NAVY_ISSUE_GALNET_STREAMCAST_UNIT_SKIN  : 57731,
    BRUTIX_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46956,
    BRUTIX_NAVY_ISSUE_IGC_SKIN  : 48559,
    BRUTIX_NAVY_ISSUE_INNER_ZONE_VANGUARD_SKIN  : 52745,
    BRUTIX_NAVY_ISSUE_INTAKI_SYNDICATE_SKIN  : 36408,
    BRUTIX_NAVY_ISSUE_KOPIS_EDGE_SKIN  : 46422,
    BRUTIX_NAVY_ISSUE_LUMINAIRE_RISING_SKIN  : 77874,
    BRUTIX_NAVY_ISSUE_SAPPHIRE_SUNGRAZER_SKIN  : 45025,
    BRUTIX_NAVY_ISSUE_SPIRIT_SKIN  : 44928,
    BRUTIX_NAVY_ISSUE_WARP_CONVERGENCE_SKIN  : 61874,
    BRUTIX_RODEN_SKIN  : 34611,
    BRUTIX_SERPENTIS_SKIN  : 39584,
    BRUTIX_SERPENTIS_SKIN_30_DAYS  : 35006,
    BRUTIX_SERPENTIS_SKIN2  : 42177,
    BRUTIX_SPIRIT_SKIN  : 44923,
    BRUTIX_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59239,
    BRUTIX_TRIGLAVIAN_TWILIGHT_SKIN  : 52397,
    BRUTIX_VALIMOR_LEGACY_SKIN  : 37573,
    BRUTIX_WARP_CONVERGENCE_SKIN  : 61873,
    BRUTIX_YOIUL_STAR_SKIN  : 57161,
    BRYNN_JERDOLAS_TAG  : 17294,
    BRYNNS_MODIFIED_100MN_AFTERBURNER  : 14500,
    BRYNNS_MODIFIED_350MM_RAILGUN  : 14387,
    BRYNNS_MODIFIED_425MM_RAILGUN  : 14397,
    BRYNNS_MODIFIED_500MN_MICROWARPDRIVE  : 14508,
    BRYNNS_MODIFIED_CO_PROCESSOR  : 15308,
    BRYNNS_MODIFIED_DAMAGE_CONTROL  : 41203,
    BRYNNS_MODIFIED_DUAL_250MM_RAILGUN  : 14407,
    BRYNNS_MODIFIED_EM_ARMOR_HARDENER  : 15244,
    BRYNNS_MODIFIED_EM_COATING  : 15180,
    BRYNNS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 15220,
    BRYNNS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15268,
    BRYNNS_MODIFIED_EXPLOSIVE_COATING  : 15188,
    BRYNNS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 15228,
    BRYNNS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15260,
    BRYNNS_MODIFIED_KINETIC_COATING  : 15196,
    BRYNNS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15236,
    BRYNNS_MODIFIED_LARGE_ARMOR_REPAIRER  : 15160,
    BRYNNS_MODIFIED_LARGE_PLASMA_SMARTBOMB  : 15152,
    BRYNNS_MODIFIED_MAGNETIC_FIELD_STABILIZER  : 15144,
    BRYNNS_MODIFIED_MULTISPECTRUM_COATING  : 15164,
    BRYNNS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 15204,
    BRYNNS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15292,
    BRYNNS_MODIFIED_REACTOR_CONTROL_UNIT  : 15300,
    BRYNNS_MODIFIED_SENSOR_BOOSTER  : 15276,
    BRYNNS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15252,
    BRYNNS_MODIFIED_THERMAL_COATING  : 15172,
    BRYNNS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 15212,
    BRYNNS_MODIFIED_TRACKING_COMPUTER  : 15284,
    BURNED_LOGIC_CIRCUIT  : 25600,
    BURNED_TRANSNEURAL_SCANNER  : 54226,
    BURST  : 599,
    BURST_BIOSECURITY_RESPONDERS_SKIN  : 55640,
    BURST_BLUE_TIGER_SKIN  : 36799,
    BURST_BLUEPRINT  : 946,
    BURST_COMBAT_MEDIC_SECTION_SKIN  : 60200,
    BURST_DAWN_OF_LIBERATION_SKIN  : 48486,
    BURST_EXOPLANETS_HUNTER_SKIN  : 45934,
    BURST_GLACIAL_DRIFT_SKIN  : 44161,
    BURST_HAZARD_CONTROL_SKIN  : 56918,
    BURST_JAMMER_I  : 580,
    BURST_JAMMER_I_BLUEPRINT  : 1210,
    BURST_JAMMER_II  : 2117,
    BURST_PROJECTOR_OPERATION  : 27911,
    BURST_SNOWLINE_BLADERACER_SKIN  : 52845,
    BURST_TRONHADAR_INK_SKIN  : 40468,
    BURST_VALKLEAR_GLORY_SKIN  : 36752,
    BUSTARD  : 12731,
    BUSTARD_BIOSECURITY_RESPONDERS_SKIN  : 55551,
    BUSTARD_BLUE_TIGER_SKIN  : 36748,
    BUSTARD_BLUEPRINT  : 12732,
    BUSTARD_EXOPLANETS_HUNTER_SKIN  : 45866,
    BUSTARD_GHOSTBIRD_SKIN  : 48209,
    BUSTARD_GLACIAL_DRIFT_SKIN  : 46870,
    BUSTARD_KIMOTORO_ESKEITAN_SKIN  : 52640,
    BUSTARD_LODESTRIKE_SKIN  : 46755,
    BUSTARD_MATIGU_SEABEAST_SKIN  : 40624,
    BUSTARD_RAATA_SUNSET_SKIN  : 36676,
    BUSTARD_STEEL_CARDINAL_SKIN  : 46143,
    BUSTARD_UNION_DAY_EXECUTIVE_SKIN  : 71728,
    BUSTARD_VITALSHIFT_EGO_SKIN  : 52561,
    BUSTARD_WIYRKOMI_SKIN  : 36349,
    BUSTARD_YOIUL_STAR_SKIN  : 61123,
    BUSTARD_ZENTO_ISIDEKO_COMBINE_SKIN  : 53203,
    BUZZARD  : 11192,
    BUZZARD_BIOSECURITY_RESPONDERS_SKIN  : 55570,
    BUZZARD_BLUE_TIGER_SKIN  : 36713,
    BUZZARD_BLUEPRINT  : 11193,
    BUZZARD_CALDARI_NAVY_SKIN  : 58876,
    BUZZARD_EXOPLANETS_HUNTER_SKIN  : 45820,
    BUZZARD_GHOSTBIRD_SKIN  : 48163,
    BUZZARD_GLACIAL_DRIFT_SKIN  : 46830,
    BUZZARD_LUMINAIRE_RISING_SKIN  : 64408,
    BUZZARD_MATIGU_SEABEAST_SKIN  : 40587,
    BUZZARD_RAATA_SUNSET_SKIN  : 36641,
    BUZZARD_SCOPE_SYNDICATION_YC122_SKIN  : 61187,
    BUZZARD_STEEL_CARDINAL_SKIN  : 46097,
    BUZZARD_VERSUS_BLUEFORCE_SKIN  : 63863,
    BUZZARD_VERSUS_REDFORCE_SKIN  : 62015,
    BUZZARD_WIYRKOMI_SKIN  : 36337,
    BUZZARD_YOIUL_STAR_SKIN  : 57158,
    BUZZARD_ZENTO_ISIDEKO_COMBINE_SKIN  : 53157,
    BZ_5_SCOPED_GRAVIMETRIC_ECM  : 19946,
    C_IR_COMPACT_GUIDANCE_DISRUPTOR  : 5321,
    C3_A_HIVAA_SAITSUO_BALLISTIC_CONTROL_SYSTEM  : 47447,
    C3_FTM_ACID  : 30311,
    C3_FTM_ACID_REACTION_FORMULA  : 46165,
    C3_X_HIVAA_SAITSUO_BALLISTIC_CONTROL_SYSTEM  : 47448,
    CACTUS_MODIFIED_KINETIC_SHIELD_AMPLIFIER  : 20609,
    CADMIUM  : 16643,
    CAEDES  : 42246,
    CAESARIUM_CADMIDE  : 16663,
    CAESARIUM_CADMIDE_REACTION_FORMULA  : 46166,
    CAESIUM  : 16647,
    CAIMAN  : 45647,
    CAIMAN_GLACIAL_DRIFT_SKIN  : 46876,
    CALDARI_AZ_1_NEXUS_CHIP  : 17643,
    CALDARI_BATTLECRUISER  : 33096,
    CALDARI_BATTLESHIP  : 3338,
    CALDARI_BY_1_NEXUS_CHIP  : 17647,
    CALDARI_CARRIER  : 24312,
    CALDARI_CONTROL_TOWER  : 16213,
    CALDARI_CONTROL_TOWER_BLUEPRINT  : 2777,
    CALDARI_CONTROL_TOWER_MEDIUM  : 20061,
    CALDARI_CONTROL_TOWER_MEDIUM_BLUEPRINT  : 2778,
    CALDARI_CONTROL_TOWER_SMALL  : 20062,
    CALDARI_CONTROL_TOWER_SMALL_BLUEPRINT  : 2779,
    CALDARI_CORE_SYSTEMS  : 30548,
    CALDARI_CRUISER  : 3334,
    CALDARI_CU_1_NEXUS_CHIP  : 17646,
    CALDARI_DECODER_PACKAGE  : 73399,
    CALDARI_DEFENSIVE_SYSTEMS  : 30544,
    CALDARI_DESTROYER  : 33092,
    CALDARI_DREADNOUGHT  : 20530,
    CALDARI_DRONE_SPECIALIZATION  : 12487,
    CALDARI_ENCODER_SPLICE  : 73403,
    CALDARI_ENCRYPTION_METHODS  : 21790,
    CALDARI_FORCE_AUXILIARY  : 40536,
    CALDARI_FREIGHTER  : 20526,
    CALDARI_FRIGATE  : 3330,
    CALDARI_HAULER  : 3342,
    CALDARI_INCEPTION_PACKAGE  : 43931,
    CALDARI_LISTENING_OUTPOST  : 73272,
    CALDARI_LOYALTY_TOKEN  : 73248,
    CALDARI_NAVY_125MM_RAILGUN  : 15824,
    CALDARI_NAVY_150MM_RAILGUN  : 15823,
    CALDARI_NAVY_200MM_RAILGUN  : 15821,
    CALDARI_NAVY_250MM_RAILGUN  : 15820,
    CALDARI_NAVY_350MM_RAILGUN  : 15818,
    CALDARI_NAVY_425MM_RAILGUN  : 15817,
    CALDARI_NAVY_75MM_RAILGUN  : 15816,
    CALDARI_NAVY_ADMIRAL_INSIGNIA_I  : 15604,
    CALDARI_NAVY_ANTIMATTER_CHARGE_L  : 21740,
    CALDARI_NAVY_ANTIMATTER_CHARGE_M  : 23025,
    CALDARI_NAVY_ANTIMATTER_CHARGE_S  : 23009,
    CALDARI_NAVY_BALLISTIC_CONTROL_SYSTEM  : 15681,
    CALDARI_NAVY_CAPTAIN_ELITE_INSIGNIA  : 15653,
    CALDARI_NAVY_CAPTAIN_INSIGNIA_I  : 15599,
    CALDARI_NAVY_CAPTAIN_INSIGNIA_II  : 15600,
    CALDARI_NAVY_CAPTAIN_INSIGNIA_III  : 15601,
    CALDARI_NAVY_CAPTAIN_INSIGNIA_IV  : 15996,
    CALDARI_NAVY_CAPTAIN_INSIGNIA_V  : 15999,
    CALDARI_NAVY_CLOAKING_DEVICE  : 15790,
    CALDARI_NAVY_CO_PROCESSOR  : 15675,
    CALDARI_NAVY_COMMAND_MINDLINK  : 33406,
    CALDARI_NAVY_COMMODORE_INSIGNIA_I  : 15602,
    CALDARI_NAVY_COMMODORE_INSIGNIA_II  : 15656,
    CALDARI_NAVY_CRUISE_MISSILE_LAUNCHER  : 16062,
    CALDARI_NAVY_DUAL_150MM_RAILGUN  : 15815,
    CALDARI_NAVY_DUAL_250MM_RAILGUN  : 15814,
    CALDARI_NAVY_EM_SHIELD_AMPLIFIER  : 15909,
    CALDARI_NAVY_EM_SHIELD_HARDENER  : 17499,
    CALDARI_NAVY_EXPLOSIVE_SHIELD_AMPLIFIER  : 15915,
    CALDARI_NAVY_EXPLOSIVE_SHIELD_HARDENER  : 17496,
    CALDARI_NAVY_FLEET_COMMANDER_INSIGNIA  : 15657,
    CALDARI_NAVY_FLEET_REAR_ADMIRAL_INSIGNIA  : 28237,
    CALDARI_NAVY_HEAVY_ASSAULT_MISSILE_LAUNCHER  : 28377,
    CALDARI_NAVY_HEAVY_MISSILE_LAUNCHER  : 16064,
    CALDARI_NAVY_HOOKBILL  : 17619,
    CALDARI_NAVY_HOOKBILL_BIOSECURITY_RESPONDERS_SKIN  : 55547,
    CALDARI_NAVY_HOOKBILL_EXOPLANETS_HUNTER_SKIN  : 45826,
    CALDARI_NAVY_HOOKBILL_GHOSTBIRD_SKIN  : 48169,
    CALDARI_NAVY_HOOKBILL_GLACIAL_DRIFT_SKIN  : 46836,
    CALDARI_NAVY_HOOKBILL_STEEL_CARDINAL_SKIN  : 46103,
    CALDARI_NAVY_HOOKBILL_VERSUS_BLUEFORCE_SKIN  : 73873,
    CALDARI_NAVY_HOOKBILL_WIYRKOMI_SKIN  : 36352,
    CALDARI_NAVY_HOOKBILL_ZENTO_ISIDEKO_COMBINE_SKIN  : 53163,
    CALDARI_NAVY_HORNET  : 31872,
    CALDARI_NAVY_INFERNO_CRUISE_MISSILE  : 27423,
    CALDARI_NAVY_INFERNO_HEAVY_ASSAULT_MISSILE  : 27405,
    CALDARI_NAVY_INFERNO_HEAVY_MISSILE  : 27447,
    CALDARI_NAVY_INFERNO_LIGHT_MISSILE  : 27371,
    CALDARI_NAVY_INFERNO_ROCKET  : 27315,
    CALDARI_NAVY_INFERNO_TORPEDO  : 27351,
    CALDARI_NAVY_IRIDIUM_CHARGE_L  : 23049,
    CALDARI_NAVY_IRIDIUM_CHARGE_M  : 23035,
    CALDARI_NAVY_IRIDIUM_CHARGE_S  : 23019,
    CALDARI_NAVY_IRON_CHARGE_L  : 23053,
    CALDARI_NAVY_IRON_CHARGE_M  : 23039,
    CALDARI_NAVY_IRON_CHARGE_S  : 23023,
    CALDARI_NAVY_KINETIC_SHIELD_AMPLIFIER  : 15911,
    CALDARI_NAVY_KINETIC_SHIELD_HARDENER  : 17495,
    CALDARI_NAVY_LARGE_GRAVITON_SMARTBOMB  : 15931,
    CALDARI_NAVY_LARGE_SHIELD_BOOSTER  : 15900,
    CALDARI_NAVY_LARGE_SHIELD_EXTENDER  : 31930,
    CALDARI_NAVY_LEAD_CHARGE_L  : 23047,
    CALDARI_NAVY_LEAD_CHARGE_M  : 23033,
    CALDARI_NAVY_LEAD_CHARGE_S  : 23017,
    CALDARI_NAVY_LIGHT_MISSILE_LAUNCHER  : 16068,
    CALDARI_NAVY_MEDIUM_GRAVITON_SMARTBOMB  : 15929,
    CALDARI_NAVY_MEDIUM_SHIELD_BOOSTER  : 15899,
    CALDARI_NAVY_MEDIUM_SHIELD_EXTENDER  : 31926,
    CALDARI_NAVY_MICRO_GRAVITON_SMARTBOMB  : 15927,
    CALDARI_NAVY_MIDSHIPMAN_INSIGNIA_I  : 15596,
    CALDARI_NAVY_MIDSHIPMAN_INSIGNIA_II  : 15597,
    CALDARI_NAVY_MIDSHIPMAN_INSIGNIA_III  : 15598,
    CALDARI_NAVY_MJOLNIR_CRUISE_MISSILE  : 27377,
    CALDARI_NAVY_MJOLNIR_HEAVY_ASSAULT_MISSILE  : 27419,
    CALDARI_NAVY_MJOLNIR_HEAVY_MISSILE  : 27435,
    CALDARI_NAVY_MJOLNIR_LIGHT_MISSILE  : 27387,
    CALDARI_NAVY_MJOLNIR_ROCKET  : 27321,
    CALDARI_NAVY_MJOLNIR_TORPEDO  : 27339,
    CALDARI_NAVY_MULTISPECTRUM_SHIELD_HARDENER  : 17498,
    CALDARI_NAVY_NOVA_CRUISE_MISSILE  : 27429,
    CALDARI_NAVY_NOVA_HEAVY_ASSAULT_MISSILE  : 27401,
    CALDARI_NAVY_NOVA_HEAVY_MISSILE  : 27453,
    CALDARI_NAVY_NOVA_LIGHT_MISSILE  : 27381,
    CALDARI_NAVY_NOVA_ROCKET  : 27327,
    CALDARI_NAVY_NOVA_TORPEDO  : 27359,
    CALDARI_NAVY_PLUTONIUM_CHARGE_L  : 23041,
    CALDARI_NAVY_PLUTONIUM_CHARGE_M  : 23027,
    CALDARI_NAVY_PLUTONIUM_CHARGE_S  : 23011,
    CALDARI_NAVY_POWER_DIAGNOSTIC_SYSTEM  : 31952,
    CALDARI_NAVY_RAID_LEADER_INSIGNIA  : 15655,
    CALDARI_NAVY_RAPID_HEAVY_MISSILE_LAUNCHER  : 33446,
    CALDARI_NAVY_RAPID_LIGHT_MISSILE_LAUNCHER  : 16061,
    CALDARI_NAVY_ROCKET_LAUNCHER  : 16065,
    CALDARI_NAVY_SCOURGE_AUTO_TARGETING_CRUISE_MISSILE_I  : 27465,
    CALDARI_NAVY_SCOURGE_AUTO_TARGETING_HEAVY_MISSILE_I  : 27489,
    CALDARI_NAVY_SCOURGE_AUTO_TARGETING_LIGHT_MISSILE_I  : 27513,
    CALDARI_NAVY_SCOURGE_CRUISE_MISSILE  : 27395,
    CALDARI_NAVY_SCOURGE_HEAVY_ASSAULT_MISSILE  : 27413,
    CALDARI_NAVY_SCOURGE_HEAVY_MISSILE  : 27441,
    CALDARI_NAVY_SCOURGE_LIGHT_MISSILE  : 27361,
    CALDARI_NAVY_SCOURGE_ROCKET  : 27333,
    CALDARI_NAVY_SCOURGE_TORPEDO  : 27345,
    CALDARI_NAVY_SHIELD_BOOST_AMPLIFIER  : 15905,
    CALDARI_NAVY_SHIELD_POWER_RELAY  : 37820,
    CALDARI_NAVY_SMALL_GRAVITON_SMARTBOMB  : 15925,
    CALDARI_NAVY_SMALL_SHIELD_BOOSTER  : 15898,
    CALDARI_NAVY_SMALL_SHIELD_EXTENDER  : 31922,
    CALDARI_NAVY_SQUAD_LEADER_INSIGNIA  : 15654,
    CALDARI_NAVY_STASIS_WEBIFIER  : 17500,
    CALDARI_NAVY_THERMAL_SHIELD_AMPLIFIER  : 15913,
    CALDARI_NAVY_THERMAL_SHIELD_HARDENER  : 17497,
    CALDARI_NAVY_THORIUM_CHARGE_L  : 23045,
    CALDARI_NAVY_THORIUM_CHARGE_M  : 23031,
    CALDARI_NAVY_THORIUM_CHARGE_S  : 23015,
    CALDARI_NAVY_TORPEDO_LAUNCHER  : 16067,
    CALDARI_NAVY_TUNGSTEN_CHARGE_L  : 23051,
    CALDARI_NAVY_TUNGSTEN_CHARGE_M  : 23037,
    CALDARI_NAVY_TUNGSTEN_CHARGE_S  : 23021,
    CALDARI_NAVY_URANIUM_CHARGE_L  : 23043,
    CALDARI_NAVY_URANIUM_CHARGE_M  : 23029,
    CALDARI_NAVY_URANIUM_CHARGE_S  : 23013,
    CALDARI_NAVY_VESPA  : 31874,
    CALDARI_NAVY_VICE_ADMIRAL_INSIGNIA_I  : 15605,
    CALDARI_NAVY_WARDEN  : 31878,
    CALDARI_NAVY_WARP_DISRUPTOR  : 15889,
    CALDARI_NAVY_WARP_SCRAMBLER  : 15887,
    CALDARI_NAVY_WASP  : 31876,
    CALDARI_NAVY_X_LARGE_SHIELD_BOOSTER  : 15897,
    CALDARI_OFFENSIVE_SYSTEMS  : 30549,
    CALDARI_PHENOMENA_GENERATOR  : 43660,
    CALDARI_PHENOMENA_GENERATOR_BLUEPRINT  : 43661,
    CALDARI_PROPAGANDA_BROADCAST_STRUCTURE  : 73227,
    CALDARI_PROPULSION_SYSTEMS  : 30552,
    CALDARI_SHUTTLE  : 672,
    CALDARI_SHUTTLE_BIOSECURITY_RESPONDERS_SKIN  : 55534,
    CALDARI_SHUTTLE_BLUEPRINT  : 967,
    CALDARI_SHUTTLE_EXOPLANETS_HUNTER_SKIN  : 45810,
    CALDARI_SHUTTLE_GLACIAL_DRIFT_SKIN  : 46827,
    CALDARI_SHUTTLE_SUKUUVESTAA_SKIN  : 56089,
    CALDARI_SHUTTLE_WIYRKOMI_SKIN  : 60105,
    CALDARI_SPACESHIP_SKILL_ACCELERATOR  : 55804,
    CALDARI_STARSHIP_ENGINEERING  : 11454,
    CALDARI_STATE_STARBASE_CHARTER  : 24593,
    CALDARI_STRATEGIC_CRUISER  : 30651,
    CALDARI_TACTICAL_DESTROYER  : 35680,
    CALDARI_TITAN  : 3346,
    CALM_ABYSSAL_FILAMENTS_CRATE  : 49708,
    CALM_DARK_FILAMENT  : 47762,
    CALM_ELECTRICAL_FILAMENT  : 47765,
    CALM_EXOTIC_FILAMENT  : 47761,
    CALM_FIRESTORM_FILAMENT  : 47763,
    CALM_GAMMA_FILAMENT  : 47764,
    CAMBION  : 32788,
    CAMERA_DRONES  : 2345,
    CAP_BOOSTER_100  : 3554,
    CAP_BOOSTER_100_BLUEPRINT  : 3555,
    CAP_BOOSTER_150  : 11283,
    CAP_BOOSTER_150_BLUEPRINT  : 11284,
    CAP_BOOSTER_200  : 11285,
    CAP_BOOSTER_200_BLUEPRINT  : 11286,
    CAP_BOOSTER_25  : 263,
    CAP_BOOSTER_25_BLUEPRINT  : 1178,
    CAP_BOOSTER_3200  : 41489,
    CAP_BOOSTER_3200_BLUEPRINT  : 41598,
    CAP_BOOSTER_400  : 11287,
    CAP_BOOSTER_400_BLUEPRINT  : 11288,
    CAP_BOOSTER_50  : 264,
    CAP_BOOSTER_50_BLUEPRINT  : 1179,
    CAP_BOOSTER_75  : 3552,
    CAP_BOOSTER_75_BLUEPRINT  : 3553,
    CAP_BOOSTER_800  : 11289,
    CAP_BOOSTER_800_BLUEPRINT  : 11290,
    CAP_RECHARGER_I  : 1195,
    CAP_RECHARGER_I_BLUEPRINT  : 1196,
    CAP_RECHARGER_II  : 2032,
    CAPACITOR_CONSOLE  : 25622,
    CAPACITOR_EMISSION_SYSTEMS  : 3423,
    CAPACITOR_FLUX_COIL_I  : 1246,
    CAPACITOR_FLUX_COIL_I_BLUEPRINT  : 1247,
    CAPACITOR_FLUX_COIL_II  : 1248,
    CAPACITOR_MANAGEMENT  : 3418,
    CAPACITOR_POWER_RELAY_I  : 1445,
    CAPACITOR_POWER_RELAY_I_BLUEPRINT  : 1446,
    CAPACITOR_POWER_RELAY_II  : 1447,
    CAPACITOR_SYSTEMS_OPERATION  : 3417,
    CAPITAL_ABSORPTION_THRUSTER_ARRAY  : 53037,
    CAPITAL_ACM_COMPACT_ARMOR_REPAIRER  : 41499,
    CAPITAL_ACM_COMPACT_ARMOR_REPAIRER_BLUEPRINT  : 41615,
    CAPITAL_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I  : 31422,
    CAPITAL_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 31423,
    CAPITAL_ALGID_ENERGY_ADMINISTRATIONS_UNIT_II  : 31428,
    CAPITAL_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I  : 31506,
    CAPITAL_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 31507,
    CAPITAL_ALGID_HYBRID_ADMINISTRATIONS_UNIT_II  : 31512,
    CAPITAL_ANCILLARY_ARMOR_REPAIRER  : 41503,
    CAPITAL_ANCILLARY_CURRENT_ROUTER_I  : 31362,
    CAPITAL_ANCILLARY_CURRENT_ROUTER_I_BLUEPRINT  : 31363,
    CAPITAL_ANCILLARY_CURRENT_ROUTER_II  : 31368,
    CAPITAL_ANCILLARY_REMOTE_ARMOR_REPAIRER  : 41479,
    CAPITAL_ANCILLARY_REMOTE_SHIELD_BOOSTER  : 41483,
    CAPITAL_ANCILLARY_SHIELD_BOOSTER  : 41504,
    CAPITAL_ANTIMATTER_REACTOR_UNIT  : 29039,
    CAPITAL_ANTIMATTER_REACTOR_UNIT_BLUEPRINT  : 29040,
    CAPITAL_ARMOR_PLATES  : 21017,
    CAPITAL_ARMOR_PLATES_BLUEPRINT  : 21018,
    CAPITAL_ARMOR_REPAIRER_I  : 20701,
    CAPITAL_ARMOR_REPAIRER_I_BLUEPRINT  : 20702,
    CAPITAL_ARMOR_REPAIRER_II  : 41500,
    CAPITAL_ARTILLERY_SPECIALIZATION  : 41404,
    CAPITAL_ASTEROID_ORE_COMPRESSOR_I  : 62632,
    CAPITAL_ASTEROID_ORE_COMPRESSOR_I_BLUEPRINT  : 62670,
    CAPITAL_ASYMMETRIC_ENDURING_REMOTE_SHIELD_BOOSTER  : 41469,
    CAPITAL_ASYMMETRIC_ENDURING_REMOTE_SHIELD_BOOSTER_BLUEPRINT  : 41666,
    CAPITAL_AUTOCANNON_SPECIALIZATION  : 41403,
    CAPITAL_AUXILIARY_NANO_PUMP_I  : 27064,
    CAPITAL_AUXILIARY_NANO_PUMP_I_BLUEPRINT  : 27065,
    CAPITAL_AUXILIARY_NANO_PUMP_II  : 31049,
    CAPITAL_AUXILIARY_THRUSTERS_I  : 31109,
    CAPITAL_AUXILIARY_THRUSTERS_I_BLUEPRINT  : 31110,
    CAPITAL_AUXILIARY_THRUSTERS_II  : 31115,
    CAPITAL_AZEOTROPIC_RESTRAINED_SHIELD_EXTENDER  : 40355,
    CAPITAL_AZEOTROPIC_RESTRAINED_SHIELD_EXTENDER_BLUEPRINT  : 41601,
    CAPITAL_BAY_LOADING_ACCELERATOR_I  : 31590,
    CAPITAL_BAY_LOADING_ACCELERATOR_I_BLUEPRINT  : 31591,
    CAPITAL_BAY_LOADING_ACCELERATOR_II  : 31596,
    CAPITAL_BEAM_LASER_SPECIALIZATION  : 41408,
    CAPITAL_BLASTER_SPECIALIZATION  : 41405,
    CAPITAL_C_5L_COMPACT_SHIELD_BOOSTER  : 41505,
    CAPITAL_C_5L_COMPACT_SHIELD_BOOSTER_BLUEPRINT  : 41632,
    CAPITAL_CAP_BATTERY_I  : 41484,
    CAPITAL_CAP_BATTERY_I_BLUEPRINT  : 41639,
    CAPITAL_CAP_BATTERY_II  : 41486,
    CAPITAL_CAPACITOR_BATTERY  : 21019,
    CAPITAL_CAPACITOR_BATTERY_BLUEPRINT  : 21020,
    CAPITAL_CAPACITOR_BOOSTER_I  : 41491,
    CAPITAL_CAPACITOR_BOOSTER_I_BLUEPRINT  : 41644,
    CAPITAL_CAPACITOR_BOOSTER_II  : 41493,
    CAPITAL_CAPACITOR_CONTROL_CIRCUIT_I  : 31374,
    CAPITAL_CAPACITOR_CONTROL_CIRCUIT_I_BLUEPRINT  : 31375,
    CAPITAL_CAPACITOR_CONTROL_CIRCUIT_II  : 31380,
    CAPITAL_CAPACITOR_EMISSION_SYSTEMS  : 24572,
    CAPITAL_CARGO_BAY  : 21027,
    CAPITAL_CARGO_BAY_BLUEPRINT  : 21028,
    CAPITAL_CARGOHOLD_OPTIMIZATION_I  : 31121,
    CAPITAL_CARGOHOLD_OPTIMIZATION_I_BLUEPRINT  : 31122,
    CAPITAL_CARGOHOLD_OPTIMIZATION_II  : 31127,
    CAPITAL_CLARITY_WARD_ENDURING_SHIELD_BOOSTER  : 41506,
    CAPITAL_CLARITY_WARD_ENDURING_SHIELD_BOOSTER_BLUEPRINT  : 41633,
    CAPITAL_CLONE_VAT_BAY  : 24547,
    CAPITAL_CLONE_VAT_BAY_BLUEPRINT  : 24548,
    CAPITAL_COAXIAL_COMPACT_REMOTE_ARMOR_REPAIRER  : 41464,
    CAPITAL_COAXIAL_COMPACT_REMOTE_ARMOR_REPAIRER_BLUEPRINT  : 41622,
    CAPITAL_COMMAND_PROCESSOR_I  : 43900,
    CAPITAL_COMMAND_PROCESSOR_I_BLUEPRINT  : 43908,
    CAPITAL_COMPACT_PB_ACID_CAP_BATTERY  : 41485,
    CAPITAL_COMPACT_PB_ACID_CAP_BATTERY_BLUEPRINT  : 41640,
    CAPITAL_COMPUTER_SYSTEM  : 21035,
    CAPITAL_COMPUTER_SYSTEM_BLUEPRINT  : 21036,
    CAPITAL_CONSTRUCTION_PARTS  : 21037,
    CAPITAL_CONSTRUCTION_PARTS_BLUEPRINT  : 21038,
    CAPITAL_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I  : 31768,
    CAPITAL_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I_BLUEPRINT  : 31769,
    CAPITAL_CORE_DEFENSE_CAPACITOR_SAFEGUARD_II  : 31774,
    CAPITAL_CORE_DEFENSE_CHARGE_ECONOMIZER_I  : 31780,
    CAPITAL_CORE_DEFENSE_CHARGE_ECONOMIZER_I_BLUEPRINT  : 31781,
    CAPITAL_CORE_DEFENSE_CHARGE_ECONOMIZER_II  : 31786,
    CAPITAL_CORE_DEFENSE_FIELD_EXTENDER_I  : 31792,
    CAPITAL_CORE_DEFENSE_FIELD_EXTENDER_I_BLUEPRINT  : 31793,
    CAPITAL_CORE_DEFENSE_FIELD_EXTENDER_II  : 31798,
    CAPITAL_CORE_DEFENSE_FIELD_PURGER_I  : 31804,
    CAPITAL_CORE_DEFENSE_FIELD_PURGER_I_BLUEPRINT  : 31805,
    CAPITAL_CORE_DEFENSE_FIELD_PURGER_II  : 31814,
    CAPITAL_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I  : 31820,
    CAPITAL_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I_BLUEPRINT  : 31821,
    CAPITAL_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_II  : 31826,
    CAPITAL_CORE_TEMPERATURE_REGULATOR  : 57487,
    CAPITAL_CORE_TEMPERATURE_REGULATOR_BLUEPRINT  : 57524,
    CAPITAL_CORPORATE_HANGAR_BAY  : 24560,
    CAPITAL_CORPORATE_HANGAR_BAY_BLUEPRINT  : 24561,
    CAPITAL_CRYSTALLINE_CARBONIDE_ARMOR_PLATE  : 29041,
    CAPITAL_CRYSTALLINE_CARBONIDE_ARMOR_PLATE_BLUEPRINT  : 29042,
    CAPITAL_DEFLECTION_SHIELD_EMITTER  : 29043,
    CAPITAL_DEFLECTION_SHIELD_EMITTER_BLUEPRINT  : 29044,
    CAPITAL_DOOMSDAY_WEAPON_MOUNT  : 24556,
    CAPITAL_DOOMSDAY_WEAPON_MOUNT_BLUEPRINT  : 24557,
    CAPITAL_DRONE_BAY  : 21029,
    CAPITAL_DRONE_BAY_BLUEPRINT  : 21030,
    CAPITAL_DRONE_CONTROL_RANGE_AUGMENTOR_I  : 33277,
    CAPITAL_DRONE_CONTROL_RANGE_AUGMENTOR_I_BLUEPRINT  : 33278,
    CAPITAL_DRONE_CONTROL_RANGE_AUGMENTOR_II  : 33279,
    CAPITAL_DRONE_DURABILITY_ENHANCER_I  : 33281,
    CAPITAL_DRONE_DURABILITY_ENHANCER_I_BLUEPRINT  : 33282,
    CAPITAL_DRONE_DURABILITY_ENHANCER_II  : 33283,
    CAPITAL_DRONE_MINING_AUGMENTOR_I  : 33285,
    CAPITAL_DRONE_MINING_AUGMENTOR_I_BLUEPRINT  : 33286,
    CAPITAL_DRONE_MINING_AUGMENTOR_II  : 33287,
    CAPITAL_DRONE_REPAIR_AUGMENTOR_I  : 33289,
    CAPITAL_DRONE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 33290,
    CAPITAL_DRONE_REPAIR_AUGMENTOR_II  : 33291,
    CAPITAL_DRONE_SCOPE_CHIP_I  : 33293,
    CAPITAL_DRONE_SCOPE_CHIP_I_BLUEPRINT  : 33294,
    CAPITAL_DRONE_SCOPE_CHIP_II  : 33295,
    CAPITAL_DRONE_SPEED_AUGMENTOR_I  : 33297,
    CAPITAL_DRONE_SPEED_AUGMENTOR_I_BLUEPRINT  : 33299,
    CAPITAL_DRONE_SPEED_AUGMENTOR_II  : 33298,
    CAPITAL_DYNAMIC_FUEL_VALVE_I  : 31133,
    CAPITAL_DYNAMIC_FUEL_VALVE_I_BLUEPRINT  : 31134,
    CAPITAL_DYNAMIC_FUEL_VALVE_II  : 31139,
    CAPITAL_EGRESS_PORT_MAXIMIZER_I  : 31386,
    CAPITAL_EGRESS_PORT_MAXIMIZER_I_BLUEPRINT  : 31387,
    CAPITAL_EGRESS_PORT_MAXIMIZER_II  : 31392,
    CAPITAL_ELECTROLYTIC_CAPACITOR_UNIT  : 29045,
    CAPITAL_ELECTROLYTIC_CAPACITOR_UNIT_BLUEPRINT  : 29046,
    CAPITAL_EM_ARMOR_REINFORCER_I  : 31001,
    CAPITAL_EM_ARMOR_REINFORCER_I_BLUEPRINT  : 31002,
    CAPITAL_EM_ARMOR_REINFORCER_II  : 31007,
    CAPITAL_EM_PULSE_GENERATOR  : 29047,
    CAPITAL_EM_PULSE_GENERATOR_BLUEPRINT  : 29048,
    CAPITAL_EM_SHIELD_REINFORCER_I  : 31720,
    CAPITAL_EM_SHIELD_REINFORCER_I_BLUEPRINT  : 31721,
    CAPITAL_EM_SHIELD_REINFORCER_II  : 31726,
    CAPITAL_EMERGENCY_HULL_ENERGIZER_I  : 40714,
    CAPITAL_EMERGENCY_HULL_ENERGIZER_I_BLUEPRINT  : 40719,
    CAPITAL_EMERGENCY_HULL_ENERGIZER_II  : 40717,
    CAPITAL_EMISSION_SCOPE_SHARPENER_I  : 31205,
    CAPITAL_EMISSION_SCOPE_SHARPENER_I_BLUEPRINT  : 31206,
    CAPITAL_EMISSION_SCOPE_SHARPENER_II  : 31211,
    CAPITAL_ENERGY_AMBIT_EXTENSION_I  : 31434,
    CAPITAL_ENERGY_AMBIT_EXTENSION_I_BLUEPRINT  : 31435,
    CAPITAL_ENERGY_AMBIT_EXTENSION_II  : 31440,
    CAPITAL_ENERGY_BURST_AERATOR_I  : 31446,
    CAPITAL_ENERGY_BURST_AERATOR_I_BLUEPRINT  : 31447,
    CAPITAL_ENERGY_BURST_AERATOR_II  : 31452,
    CAPITAL_ENERGY_COLLISION_ACCELERATOR_I  : 31458,
    CAPITAL_ENERGY_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31459,
    CAPITAL_ENERGY_COLLISION_ACCELERATOR_II  : 31464,
    CAPITAL_ENERGY_DISCHARGE_ELUTRIATION_I  : 31470,
    CAPITAL_ENERGY_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 31471,
    CAPITAL_ENERGY_DISCHARGE_ELUTRIATION_II  : 31476,
    CAPITAL_ENERGY_LOCUS_COORDINATOR_I  : 31482,
    CAPITAL_ENERGY_LOCUS_COORDINATOR_I_BLUEPRINT  : 31483,
    CAPITAL_ENERGY_LOCUS_COORDINATOR_II  : 31488,
    CAPITAL_ENERGY_METASTASIS_ADJUSTER_I  : 31494,
    CAPITAL_ENERGY_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31495,
    CAPITAL_ENERGY_METASTASIS_ADJUSTER_II  : 31500,
    CAPITAL_ENERGY_NEUTRALIZER_I  : 40659,
    CAPITAL_ENERGY_NEUTRALIZER_I_BLUEPRINT  : 40672,
    CAPITAL_ENERGY_NEUTRALIZER_II  : 40662,
    CAPITAL_ENERGY_NOSFERATU_I  : 40665,
    CAPITAL_ENERGY_NOSFERATU_I_BLUEPRINT  : 40684,
    CAPITAL_ENERGY_NOSFERATU_II  : 40668,
    CAPITAL_ENERGY_TURRET  : 20327,
    CAPITAL_ENGINE_THERMAL_SHIELDING_I  : 31145,
    CAPITAL_ENGINE_THERMAL_SHIELDING_I_BLUEPRINT  : 31146,
    CAPITAL_ENGINE_THERMAL_SHIELDING_II  : 31151,
    CAPITAL_EXPLOSIVE_ARMOR_REINFORCER_I  : 31013,
    CAPITAL_EXPLOSIVE_ARMOR_REINFORCER_I_BLUEPRINT  : 31014,
    CAPITAL_EXPLOSIVE_ARMOR_REINFORCER_II  : 31019,
    CAPITAL_EXPLOSIVE_SHIELD_REINFORCER_I  : 31732,
    CAPITAL_EXPLOSIVE_SHIELD_REINFORCER_I_BLUEPRINT  : 31733,
    CAPITAL_EXPLOSIVE_SHIELD_REINFORCER_II  : 31738,
    CAPITAL_F_RX_COMPACT_CAPACITOR_BOOSTER  : 41492,
    CAPITAL_F_RX_COMPACT_CAPACITOR_BOOSTER_BLUEPRINT  : 41645,
    CAPITAL_F_S9_REGOLITH_COMPACT_SHIELD_EXTENDER  : 40356,
    CAPITAL_F_S9_REGOLITH_COMPACT_SHIELD_EXTENDER_BLUEPRINT  : 41600,
    CAPITAL_FERNITE_CARBIDE_COMPOSITE_ARMOR_PLATE  : 29049,
    CAPITAL_FERNITE_CARBIDE_COMPOSITE_ARMOR_PLATE_BLUEPRINT  : 29050,
    CAPITAL_FLEX_ARMOR_HARDENER_I  : 41515,
    CAPITAL_FLEX_ARMOR_HARDENER_I_BLUEPRINT  : 41657,
    CAPITAL_FLEX_ARMOR_HARDENER_II  : 41525,
    CAPITAL_FLEX_SHIELD_HARDENER_I  : 41516,
    CAPITAL_FLEX_SHIELD_HARDENER_I_BLUEPRINT  : 41662,
    CAPITAL_FLEX_SHIELD_HARDENER_II  : 41528,
    CAPITAL_FUSION_REACTOR_UNIT  : 29051,
    CAPITAL_FUSION_REACTOR_UNIT_BLUEPRINT  : 29052,
    CAPITAL_FUSION_THRUSTER  : 29053,
    CAPITAL_FUSION_THRUSTER_BLUEPRINT  : 29054,
    CAPITAL_GAS_COMPRESSOR_I  : 62634,
    CAPITAL_GAS_COMPRESSOR_I_BLUEPRINT  : 62675,
    CAPITAL_GHOUL_COMPACT_ENERGY_NOSFERATU  : 40666,
    CAPITAL_GHOUL_COMPACT_ENERGY_NOSFERATU_BLUEPRINT  : 40685,
    CAPITAL_GRAVIMETRIC_SENSOR_CLUSTER  : 29055,
    CAPITAL_GRAVIMETRIC_SENSOR_CLUSTER_BLUEPRINT  : 29056,
    CAPITAL_GRAVITON_PULSE_GENERATOR  : 29057,
    CAPITAL_GRAVITON_PULSE_GENERATOR_BLUEPRINT  : 29058,
    CAPITAL_GRAVITON_REACTOR_UNIT  : 29059,
    CAPITAL_GRAVITON_REACTOR_UNIT_BLUEPRINT  : 29060,
    CAPITAL_GRAVITY_CAPACITOR_UPGRADE_I  : 31217,
    CAPITAL_GRAVITY_CAPACITOR_UPGRADE_I_BLUEPRINT  : 31218,
    CAPITAL_GRAVITY_CAPACITOR_UPGRADE_II  : 31224,
    CAPITAL_GREMLIN_COMPACT_ENERGY_NEUTRALIZER  : 40660,
    CAPITAL_GREMLIN_COMPACT_ENERGY_NEUTRALIZER_BLUEPRINT  : 40673,
    CAPITAL_HIGGS_ANCHOR_I  : 34308,
    CAPITAL_HIGGS_ANCHOR_I_BLUEPRINT  : 34309,
    CAPITAL_HULL_REPAIRER_I  : 41511,
    CAPITAL_HULL_REPAIRER_I_BLUEPRINT  : 41627,
    CAPITAL_HULL_REPAIRER_II  : 41514,
    CAPITAL_HYBRID_AMBIT_EXTENSION_I  : 31518,
    CAPITAL_HYBRID_AMBIT_EXTENSION_I_BLUEPRINT  : 31519,
    CAPITAL_HYBRID_AMBIT_EXTENSION_II  : 31524,
    CAPITAL_HYBRID_BURST_AERATOR_I  : 31530,
    CAPITAL_HYBRID_BURST_AERATOR_I_BLUEPRINT  : 31531,
    CAPITAL_HYBRID_BURST_AERATOR_II  : 31536,
    CAPITAL_HYBRID_COLLISION_ACCELERATOR_I  : 31542,
    CAPITAL_HYBRID_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31543,
    CAPITAL_HYBRID_COLLISION_ACCELERATOR_II  : 31548,
    CAPITAL_HYBRID_DISCHARGE_ELUTRIATION_I  : 31554,
    CAPITAL_HYBRID_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 31555,
    CAPITAL_HYBRID_DISCHARGE_ELUTRIATION_II  : 31560,
    CAPITAL_HYBRID_LOCUS_COORDINATOR_I  : 31566,
    CAPITAL_HYBRID_LOCUS_COORDINATOR_I_BLUEPRINT  : 31567,
    CAPITAL_HYBRID_LOCUS_COORDINATOR_II  : 31572,
    CAPITAL_HYBRID_METASTASIS_ADJUSTER_I  : 31578,
    CAPITAL_HYBRID_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31579,
    CAPITAL_HYBRID_METASTASIS_ADJUSTER_II  : 31584,
    CAPITAL_HYBRID_TURRET  : 21666,
    CAPITAL_HYDRAULIC_BAY_THRUSTERS_I  : 31602,
    CAPITAL_HYDRAULIC_BAY_THRUSTERS_I_BLUEPRINT  : 31603,
    CAPITAL_HYDRAULIC_BAY_THRUSTERS_II  : 33301,
    CAPITAL_HYPERSPATIAL_VELOCITY_OPTIMIZER_I  : 31163,
    CAPITAL_HYPERSPATIAL_VELOCITY_OPTIMIZER_I_BLUEPRINT  : 31164,
    CAPITAL_HYPERSPATIAL_VELOCITY_OPTIMIZER_II  : 31169,
    CAPITAL_I_A_ENDURING_ARMOR_REPAIRER  : 41498,
    CAPITAL_I_A_ENDURING_ARMOR_REPAIRER_BLUEPRINT  : 41614,
    CAPITAL_I_AX_ENDURING_REMOTE_ARMOR_REPAIRER  : 41463,
    CAPITAL_I_AX_ENDURING_REMOTE_ARMOR_REPAIRER_BLUEPRINT  : 41621,
    CAPITAL_I_B_ENDURING_HULL_REPAIRER  : 41512,
    CAPITAL_I_B_ENDURING_HULL_REPAIRER_BLUEPRINT  : 41628,
    CAPITAL_ICE_COMPRESSOR_I  : 62633,
    CAPITAL_ICE_COMPRESSOR_I_BLUEPRINT  : 62672,
    CAPITAL_IEF_COMPACT_HULL_REPAIRER  : 41513,
    CAPITAL_IEF_COMPACT_HULL_REPAIRER_BLUEPRINT  : 41629,
    CAPITAL_IMPLACABLE_COMPACT_EMERGENCY_HULL_ENERGIZER  : 40715,
    CAPITAL_IMPLACABLE_COMPACT_EMERGENCY_HULL_ENERGIZER_BLUEPRINT  : 40720,
    CAPITAL_INDEFATIGABLE_ENDURING_EMERGENCY_HULL_ENERGIZER  : 40716,
    CAPITAL_INDEFATIGABLE_ENDURING_EMERGENCY_HULL_ENERGIZER_BLUEPRINT  : 40721,
    CAPITAL_INDUCTIVE_COMPACT_REMOTE_CAPACITOR_TRANSMITTER  : 41566,
    CAPITAL_INDUCTIVE_COMPACT_REMOTE_CAPACITOR_TRANSMITTER_BLUEPRINT  : 41698,
    CAPITAL_INDUSTRIAL_CORE_I  : 28583,
    CAPITAL_INDUSTRIAL_CORE_I_BLUEPRINT  : 28584,
    CAPITAL_INDUSTRIAL_CORE_II  : 42890,
    CAPITAL_INDUSTRIAL_RECONFIGURATION  : 28585,
    CAPITAL_INDUSTRIAL_SHIPS  : 28374,
    CAPITAL_INFECTIOUS_SCOPED_ENERGY_NEUTRALIZER  : 40661,
    CAPITAL_INFECTIOUS_SCOPED_ENERGY_NEUTRALIZER_BLUEPRINT  : 40674,
    CAPITAL_INVERTED_SIGNAL_FIELD_PROJECTOR_I  : 31266,
    CAPITAL_INVERTED_SIGNAL_FIELD_PROJECTOR_I_BLUEPRINT  : 31267,
    CAPITAL_INVERTED_SIGNAL_FIELD_PROJECTOR_II  : 31272,
    CAPITAL_ION_THRUSTER  : 29061,
    CAPITAL_ION_THRUSTER_BLUEPRINT  : 29062,
    CAPITAL_IONIC_FIELD_PROJECTOR_I  : 31278,
    CAPITAL_IONIC_FIELD_PROJECTOR_I_BLUEPRINT  : 31279,
    CAPITAL_IONIC_FIELD_PROJECTOR_II  : 31284,
    CAPITAL_JUMP_BRIDGE_ARRAY  : 24545,
    CAPITAL_JUMP_BRIDGE_ARRAY_BLUEPRINT  : 24546,
    CAPITAL_JUMP_DRIVE  : 21025,
    CAPITAL_JUMP_DRIVE_BLUEPRINT  : 21026,
    CAPITAL_KINETIC_ARMOR_REINFORCER_I  : 31025,
    CAPITAL_KINETIC_ARMOR_REINFORCER_I_BLUEPRINT  : 31026,
    CAPITAL_KINETIC_ARMOR_REINFORCER_II  : 31031,
    CAPITAL_KINETIC_SHIELD_REINFORCER_I  : 31744,
    CAPITAL_KINETIC_SHIELD_REINFORCER_I_BLUEPRINT  : 31745,
    CAPITAL_KINETIC_SHIELD_REINFORCER_II  : 31750,
    CAPITAL_KNAVE_SCOPED_ENERGY_NOSFERATU  : 40667,
    CAPITAL_KNAVE_SCOPED_ENERGY_NOSFERATU_BLUEPRINT  : 40686,
    CAPITAL_LADAR_SENSOR_CLUSTER  : 29065,
    CAPITAL_LADAR_SENSOR_CLUSTER_BLUEPRINT  : 29066,
    CAPITAL_LASER_FOCUSING_CRYSTALS  : 29063,
    CAPITAL_LASER_FOCUSING_CRYSTALS_BLUEPRINT  : 29064,
    CAPITAL_LAUNCHER_HARDPOINT  : 21041,
    CAPITAL_LAUNCHER_HARDPOINT_BLUEPRINT  : 21042,
    CAPITAL_LINEAR_SHIELD_EMITTER  : 29067,
    CAPITAL_LINEAR_SHIELD_EMITTER_BLUEPRINT  : 29068,
    CAPITAL_LIQUID_COOLED_ELECTRONICS_I  : 31230,
    CAPITAL_LIQUID_COOLED_ELECTRONICS_I_BLUEPRINT  : 31231,
    CAPITAL_LIQUID_COOLED_ELECTRONICS_II  : 31236,
    CAPITAL_LOW_FRICTION_NOZZLE_JOINTS_I  : 31157,
    CAPITAL_LOW_FRICTION_NOZZLE_JOINTS_I_BLUEPRINT  : 31158,
    CAPITAL_LOW_FRICTION_NOZZLE_JOINTS_II  : 31175,
    CAPITAL_MAGNETOMETRIC_SENSOR_CLUSTER  : 29069,
    CAPITAL_MAGNETOMETRIC_SENSOR_CLUSTER_BLUEPRINT  : 29070,
    CAPITAL_MAGPULSE_THRUSTER  : 29071,
    CAPITAL_MAGPULSE_THRUSTER_BLUEPRINT  : 29072,
    CAPITAL_MEMETIC_ALGORITHM_BANK_I  : 31242,
    CAPITAL_MEMETIC_ALGORITHM_BANK_I_BLUEPRINT  : 31243,
    CAPITAL_MEMETIC_ALGORITHM_BANK_II  : 31248,
    CAPITAL_MERCOXIT_COMPRESSOR_I  : 62635,
    CAPITAL_MERCOXIT_COMPRESSOR_I_BLUEPRINT  : 62677,
    CAPITAL_MOON_ORE_COMPRESSOR_I  : 62636,
    CAPITAL_MOON_ORE_COMPRESSOR_I_BLUEPRINT  : 62679,
    CAPITAL_MURKY_COMPACT_REMOTE_SHIELD_BOOSTER  : 41470,
    CAPITAL_MURKY_COMPACT_REMOTE_SHIELD_BOOSTER_BLUEPRINT  : 41667,
    CAPITAL_NANOBOT_ACCELERATOR_I  : 31067,
    CAPITAL_NANOBOT_ACCELERATOR_I_BLUEPRINT  : 31068,
    CAPITAL_NANOBOT_ACCELERATOR_II  : 27066,
    CAPITAL_NANOELECTRICAL_MICROPROCESSOR  : 29073,
    CAPITAL_NANOELECTRICAL_MICROPROCESSOR_BLUEPRINT  : 29074,
    CAPITAL_NANOMECHANICAL_MICROPROCESSOR  : 29075,
    CAPITAL_NANOMECHANICAL_MICROPROCESSOR_BLUEPRINT  : 29076,
    CAPITAL_NUCLEAR_PULSE_GENERATOR  : 29077,
    CAPITAL_NUCLEAR_PULSE_GENERATOR_BLUEPRINT  : 29078,
    CAPITAL_NUCLEAR_REACTOR_UNIT  : 29079,
    CAPITAL_NUCLEAR_REACTOR_UNIT_BLUEPRINT  : 29080,
    CAPITAL_OSCILLATOR_CAPACITOR_UNIT  : 29081,
    CAPITAL_OSCILLATOR_CAPACITOR_UNIT_BLUEPRINT  : 29082,
    CAPITAL_PARKING_TICKET  : 37886,
    CAPITAL_PARTICLE_ACCELERATOR_UNIT  : 29083,
    CAPITAL_PARTICLE_ACCELERATOR_UNIT_BLUEPRINT  : 29084,
    CAPITAL_PARTICLE_DISPERSION_AUGMENTOR_I  : 31290,
    CAPITAL_PARTICLE_DISPERSION_AUGMENTOR_I_BLUEPRINT  : 31291,
    CAPITAL_PARTICLE_DISPERSION_AUGMENTOR_II  : 31296,
    CAPITAL_PARTICLE_DISPERSION_PROJECTOR_I  : 31302,
    CAPITAL_PARTICLE_DISPERSION_PROJECTOR_I_BLUEPRINT  : 31303,
    CAPITAL_PARTICLE_DISPERSION_PROJECTOR_II  : 31308,
    CAPITAL_PHOTON_MICROPROCESSOR  : 29085,
    CAPITAL_PHOTON_MICROPROCESSOR_BLUEPRINT  : 29086,
    CAPITAL_PLASMA_PULSE_GENERATOR  : 29087,
    CAPITAL_PLASMA_PULSE_GENERATOR_BLUEPRINT  : 29088,
    CAPITAL_PLASMA_THRUSTER  : 29089,
    CAPITAL_PLASMA_THRUSTER_BLUEPRINT  : 29090,
    CAPITAL_POLYCARBON_ENGINE_HOUSING_I  : 31181,
    CAPITAL_POLYCARBON_ENGINE_HOUSING_I_BLUEPRINT  : 31182,
    CAPITAL_POLYCARBON_ENGINE_HOUSING_II  : 31187,
    CAPITAL_POWER_GENERATOR  : 21021,
    CAPITAL_POWER_GENERATOR_BLUEPRINT  : 21022,
    CAPITAL_POWERGRID_SUBROUTINE_MAXIMIZER_I  : 31398,
    CAPITAL_POWERGRID_SUBROUTINE_MAXIMIZER_I_BLUEPRINT  : 31399,
    CAPITAL_POWERGRID_SUBROUTINE_MAXIMIZER_II  : 31404,
    CAPITAL_PRECURSOR_WEAPON  : 52998,
    CAPITAL_PROCESSOR_OVERCLOCKING_UNIT_I  : 33303,
    CAPITAL_PROCESSOR_OVERCLOCKING_UNIT_I_BLUEPRINT  : 33304,
    CAPITAL_PROCESSOR_OVERCLOCKING_UNIT_II  : 33305,
    CAPITAL_PROJECTILE_AMBIT_EXTENSION_I  : 31660,
    CAPITAL_PROJECTILE_AMBIT_EXTENSION_I_BLUEPRINT  : 31661,
    CAPITAL_PROJECTILE_AMBIT_EXTENSION_II  : 31666,
    CAPITAL_PROJECTILE_BURST_AERATOR_I  : 31672,
    CAPITAL_PROJECTILE_BURST_AERATOR_I_BLUEPRINT  : 31673,
    CAPITAL_PROJECTILE_BURST_AERATOR_II  : 31678,
    CAPITAL_PROJECTILE_COLLISION_ACCELERATOR_I  : 31684,
    CAPITAL_PROJECTILE_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31685,
    CAPITAL_PROJECTILE_COLLISION_ACCELERATOR_II  : 31690,
    CAPITAL_PROJECTILE_LOCUS_COORDINATOR_I  : 31696,
    CAPITAL_PROJECTILE_LOCUS_COORDINATOR_I_BLUEPRINT  : 31697,
    CAPITAL_PROJECTILE_LOCUS_COORDINATOR_II  : 31702,
    CAPITAL_PROJECTILE_METASTASIS_ADJUSTER_I  : 31708,
    CAPITAL_PROJECTILE_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31709,
    CAPITAL_PROJECTILE_METASTASIS_ADJUSTER_II  : 31714,
    CAPITAL_PROJECTILE_TURRET  : 21667,
    CAPITAL_PROPULSION_ENGINE  : 21009,
    CAPITAL_PROPULSION_ENGINE_BLUEPRINT  : 21010,
    CAPITAL_PULSE_LASER_SPECIALIZATION  : 41407,
    CAPITAL_PULSE_SHIELD_EMITTER  : 29091,
    CAPITAL_PULSE_SHIELD_EMITTER_BLUEPRINT  : 29092,
    CAPITAL_QUANTUM_MICROPROCESSOR  : 29093,
    CAPITAL_QUANTUM_MICROPROCESSOR_BLUEPRINT  : 29094,
    CAPITAL_RADAR_SENSOR_CLUSTER  : 29095,
    CAPITAL_RADAR_SENSOR_CLUSTER_BLUEPRINT  : 29096,
    CAPITAL_RADIATION_CONVERSION_UNIT  : 53036,
    CAPITAL_RADIATIVE_SCOPED_REMOTE_CAPACITOR_TRANSMITTER  : 41565,
    CAPITAL_RADIATIVE_SCOPED_REMOTE_CAPACITOR_TRANSMITTER_BLUEPRINT  : 41697,
    CAPITAL_RAILGUN_SPECIALIZATION  : 41406,
    CAPITAL_REMOTE_ARMOR_REPAIR_SYSTEMS  : 24568,
    CAPITAL_REMOTE_ARMOR_REPAIRER_I  : 24569,
    CAPITAL_REMOTE_ARMOR_REPAIRER_I_BLUEPRINT  : 24570,
    CAPITAL_REMOTE_ARMOR_REPAIRER_II  : 41466,
    CAPITAL_REMOTE_CAPACITOR_TRANSMITTER_I  : 12219,
    CAPITAL_REMOTE_CAPACITOR_TRANSMITTER_I_BLUEPRINT  : 12220,
    CAPITAL_REMOTE_CAPACITOR_TRANSMITTER_II  : 12223,
    CAPITAL_REMOTE_HULL_REPAIR_SYSTEMS  : 27936,
    CAPITAL_REMOTE_HULL_REPAIRER_I  : 27934,
    CAPITAL_REMOTE_HULL_REPAIRER_I_BLUEPRINT  : 27935,
    CAPITAL_REMOTE_HULL_REPAIRER_II  : 41475,
    CAPITAL_REMOTE_REPAIR_AUGMENTOR_I  : 31075,
    CAPITAL_REMOTE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 31076,
    CAPITAL_REMOTE_REPAIR_AUGMENTOR_II  : 31081,
    CAPITAL_REMOTE_SHIELD_BOOSTER_I  : 3616,
    CAPITAL_REMOTE_SHIELD_BOOSTER_I_BLUEPRINT  : 3617,
    CAPITAL_REMOTE_SHIELD_BOOSTER_II  : 3618,
    CAPITAL_REPAIR_SYSTEMS  : 21803,
    CAPITAL_ROCKET_FUEL_CACHE_PARTITION_I  : 31612,
    CAPITAL_ROCKET_FUEL_CACHE_PARTITION_I_BLUEPRINT  : 31613,
    CAPITAL_ROCKET_FUEL_CACHE_PARTITION_II  : 31618,
    CAPITAL_S95A_SCOPED_REMOTE_SHIELD_BOOSTER  : 41471,
    CAPITAL_S95A_SCOPED_REMOTE_SHIELD_BOOSTER_BLUEPRINT  : 41668,
    CAPITAL_SALVAGE_TACKLE_I  : 31087,
    CAPITAL_SALVAGE_TACKLE_I_BLUEPRINT  : 31088,
    CAPITAL_SALVAGE_TACKLE_II  : 31093,
    CAPITAL_SCALAR_CAPACITOR_UNIT  : 29097,
    CAPITAL_SCALAR_CAPACITOR_UNIT_BLUEPRINT  : 29098,
    CAPITAL_SEMICONDUCTOR_MEMORY_CELL_I  : 31410,
    CAPITAL_SEMICONDUCTOR_MEMORY_CELL_I_BLUEPRINT  : 31411,
    CAPITAL_SEMICONDUCTOR_MEMORY_CELL_II  : 31416,
    CAPITAL_SENSOR_CLUSTER  : 21013,
    CAPITAL_SENSOR_CLUSTER_BLUEPRINT  : 21014,
    CAPITAL_SENTRY_DAMAGE_AUGMENTOR_I  : 33307,
    CAPITAL_SENTRY_DAMAGE_AUGMENTOR_I_BLUEPRINT  : 33308,
    CAPITAL_SENTRY_DAMAGE_AUGMENTOR_II  : 33309,
    CAPITAL_SHIELD_BOOSTER_I  : 20703,
    CAPITAL_SHIELD_BOOSTER_I_BLUEPRINT  : 20704,
    CAPITAL_SHIELD_BOOSTER_II  : 41507,
    CAPITAL_SHIELD_EMISSION_SYSTEMS  : 24571,
    CAPITAL_SHIELD_EMITTER  : 21023,
    CAPITAL_SHIELD_EMITTER_BLUEPRINT  : 21024,
    CAPITAL_SHIELD_EXTENDER_I  : 40354,
    CAPITAL_SHIELD_EXTENDER_I_BLUEPRINT  : 41599,
    CAPITAL_SHIELD_EXTENDER_II  : 40357,
    CAPITAL_SHIELD_OPERATION  : 21802,
    CAPITAL_SHIP_ASSEMBLY_ARRAY  : 24656,
    CAPITAL_SHIP_CONSTRUCTION  : 22242,
    CAPITAL_SHIP_MAINTENANCE_BAY  : 24558,
    CAPITAL_SHIP_MAINTENANCE_BAY_BLUEPRINT  : 24559,
    CAPITAL_SHIPBOARD_COMPRESSION_TECHNOLOGY  : 62451,
    CAPITAL_SHIPS  : 20533,
    CAPITAL_SIEGE_ARRAY  : 21039,
    CAPITAL_SIEGE_ARRAY_BLUEPRINT  : 21040,
    CAPITAL_SIGNAL_DISRUPTION_AMPLIFIER_I  : 31254,
    CAPITAL_SIGNAL_DISRUPTION_AMPLIFIER_I_BLUEPRINT  : 31255,
    CAPITAL_SIGNAL_DISRUPTION_AMPLIFIER_II  : 31260,
    CAPITAL_SIGNAL_FOCUSING_KIT_I  : 31314,
    CAPITAL_SIGNAL_FOCUSING_KIT_I_BLUEPRINT  : 31315,
    CAPITAL_SIGNAL_FOCUSING_KIT_II  : 31320,
    CAPITAL_SINGULARITY_RADIATION_CONVERTOR  : 53288,
    CAPITAL_SOLACE_SCOPED_REMOTE_ARMOR_REPAIRER  : 41465,
    CAPITAL_SOLACE_SCOPED_REMOTE_ARMOR_REPAIRER_BLUEPRINT  : 41624,
    CAPITAL_STASIS_DRONE_AUGMENTOR_I  : 33311,
    CAPITAL_STASIS_DRONE_AUGMENTOR_I_BLUEPRINT  : 33312,
    CAPITAL_STASIS_DRONE_AUGMENTOR_II  : 33313,
    CAPITAL_SUPERCONDUCTOR_RAILS  : 29099,
    CAPITAL_SUPERCONDUCTOR_RAILS_BLUEPRINT  : 29100,
    CAPITAL_SUSTAINED_SHIELD_EMITTER  : 29101,
    CAPITAL_SUSTAINED_SHIELD_EMITTER_BLUEPRINT  : 29102,
    CAPITAL_TARGETING_SYSTEM_SUBCONTROLLER_I  : 31326,
    CAPITAL_TARGETING_SYSTEM_SUBCONTROLLER_I_BLUEPRINT  : 31327,
    CAPITAL_TARGETING_SYSTEM_SUBCONTROLLER_II  : 31332,
    CAPITAL_TARGETING_SYSTEMS_STABILIZER_I  : 31338,
    CAPITAL_TARGETING_SYSTEMS_STABILIZER_I_BLUEPRINT  : 31339,
    CAPITAL_TARGETING_SYSTEMS_STABILIZER_II  : 31344,
    CAPITAL_TESSERACT_CAPACITOR_UNIT  : 29103,
    CAPITAL_TESSERACT_CAPACITOR_UNIT_BLUEPRINT  : 29104,
    CAPITAL_THERMAL_ARMOR_REINFORCER_I  : 31037,
    CAPITAL_THERMAL_ARMOR_REINFORCER_I_BLUEPRINT  : 31038,
    CAPITAL_THERMAL_ARMOR_REINFORCER_II  : 31043,
    CAPITAL_THERMAL_SHIELD_REINFORCER_I  : 31756,
    CAPITAL_THERMAL_SHIELD_REINFORCER_I_BLUEPRINT  : 31757,
    CAPITAL_THERMAL_SHIELD_REINFORCER_II  : 31762,
    CAPITAL_THERMONUCLEAR_TRIGGER_UNIT  : 29105,
    CAPITAL_THERMONUCLEAR_TRIGGER_UNIT_BLUEPRINT  : 29106,
    CAPITAL_TITANIUM_DIBORITE_ARMOR_PLATE  : 29107,
    CAPITAL_TITANIUM_DIBORITE_ARMOR_PLATE_BLUEPRINT  : 29108,
    CAPITAL_TRACKING_DIAGNOSTIC_SUBROUTINES_I  : 31350,
    CAPITAL_TRACKING_DIAGNOSTIC_SUBROUTINES_I_BLUEPRINT  : 31351,
    CAPITAL_TRACKING_DIAGNOSTIC_SUBROUTINES_II  : 31356,
    CAPITAL_TRACTOR_BEAM_I  : 24644,
    CAPITAL_TRACTOR_BEAM_I_BLUEPRINT  : 24645,
    CAPITAL_TRACTOR_BEAM_II  : 4252,
    CAPITAL_TRANSVERSE_BULKHEAD_I  : 33902,
    CAPITAL_TRANSVERSE_BULKHEAD_I_BLUEPRINT  : 33903,
    CAPITAL_TRANSVERSE_BULKHEAD_II  : 33904,
    CAPITAL_TRIMARK_ARMOR_PUMP_I  : 30993,
    CAPITAL_TRIMARK_ARMOR_PUMP_I_BLUEPRINT  : 30994,
    CAPITAL_TRIMARK_ARMOR_PUMP_II  : 31061,
    CAPITAL_TRINARY_STATE_PROCESSOR  : 53290,
    CAPITAL_TUNGSTEN_CARBIDE_ARMOR_PLATE  : 29109,
    CAPITAL_TUNGSTEN_CARBIDE_ARMOR_PLATE_BLUEPRINT  : 29110,
    CAPITAL_TURRET_HARDPOINT  : 21011,
    CAPITAL_TURRET_HARDPOINT_BLUEPRINT  : 21012,
    CAPITAL_ULTRATIDAL_ENTROPIC_MOUNTING  : 53035,
    CAPITAL_WARHEAD_CALEFACTION_CATALYST_I  : 31624,
    CAPITAL_WARHEAD_CALEFACTION_CATALYST_I_BLUEPRINT  : 31625,
    CAPITAL_WARHEAD_CALEFACTION_CATALYST_II  : 31630,
    CAPITAL_WARHEAD_FLARE_CATALYST_I  : 31636,
    CAPITAL_WARHEAD_FLARE_CATALYST_I_BLUEPRINT  : 31637,
    CAPITAL_WARHEAD_FLARE_CATALYST_II  : 31642,
    CAPITAL_WARHEAD_RIGOR_CATALYST_I  : 31648,
    CAPITAL_WARHEAD_RIGOR_CATALYST_I_BLUEPRINT  : 31649,
    CAPITAL_WARHEAD_RIGOR_CATALYST_II  : 31654,
    CAPITAL_WARP_CORE_OPTIMIZER_I  : 31193,
    CAPITAL_WARP_CORE_OPTIMIZER_I_BLUEPRINT  : 31194,
    CAPITAL_WARP_CORE_OPTIMIZER_II  : 31199,
    CAPITAL_ZERO_POINT_FIELD_MANIPULATOR  : 53289,
    CAPSULE_AURORA_UNIVERSALIS_SKIN  : 57004,
    CAPSULE_CAPSULEER_ELITE_XV_SKIN  : 47651,
    CAPSULE_CAPSULEER_ELITE_XX_SKIN  : 77172,
    CAPSULE_CASCADE_FIREWORK  : 54564,
    CAPSULE_PALATINE_SKIN  : 41580,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_001  : 63704,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_002  : 63712,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_003  : 63713,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_004  : 63705,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_005  : 63706,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_006  : 63707,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_007  : 63708,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_008  : 63709,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_009  : 63710,
    CAPSULE_SUBSYSTEM_HOLOSTORAGE_EH_CN_J44_010  : 63711,
    CAPSULE_UPWELL_SKIN  : 41579,
    CAPSULE_YC119_CAPSULEER_DAY_SKIN  : 45482,
    CAPSULEER_DAWN_FIREWORK_LARGE  : 47300,
    CAPSULEER_DAY_GLAMOUREX_CRATE  : 77055,
    CAPSULEER_DAY_XVI_APOTHEOSIS__SKIN_CRATE  : 52296,
    CAPSULEER_DAY_XVI_CAP_CRATE  : 52294,
    CAPSULEER_DAY_XVI_GNOSIS__SKIN_CRATE  : 52291,
    CAPSULEER_DAY_XVI_PRAXIS__SKIN_CRATE  : 52298,
    CAPSULEER_DAY_XVI_SUNESIS__SKIN_CRATE  : 52295,
    CAPSULEER_DAY_XVI_T_SHIRT_CRATE  : 52290,
    CAPSULEER_DAY_XX_FIREWORKS_PACKAGE  : 76964,
    CAPSULEER_STARBURST_FIREWORK  : 63165,
    CARACAL  : 621,
    CARACAL_ABYSSAL_FIRESTORM_SKIN  : 53133,
    CARACAL_ASPIRANT_ENFORCER_SKIN  : 60236,
    CARACAL_AURORA_UNIVERSALIS_SKIN  : 57017,
    CARACAL_BIOSECURITY_RESPONDERS_SKIN  : 55512,
    CARACAL_BLUE_TIGER_SKIN  : 36722,
    CARACAL_BLUEPRINT  : 687,
    CARACAL_DEATHGLOW_REMNANT_SKIN  : 60661,
    CARACAL_EXOPLANETS_HUNTER_SKIN  : 45834,
    CARACAL_GALNET_STREAMCAST_UNIT_SKIN  : 76484,
    CARACAL_GHOSTBIRD_SKIN  : 48177,
    CARACAL_GLACIAL_DRIFT_SKIN  : 44158,
    CARACAL_GURISTAS_SKIN  : 54558,
    CARACAL_HEADHUNTER_SKIN  : 46713,
    CARACAL_HUNTERS_QUIVER_SKIN  : 47499,
    CARACAL_IGC_SKIN  : 45014,
    CARACAL_KIMOTORO_ESKEITAN_SKIN  : 52636,
    CARACAL_MATIGU_SEABEAST_SKIN  : 40598,
    CARACAL_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59222,
    CARACAL_NAVY_ISSUE  : 17634,
    CARACAL_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55542,
    CARACAL_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45844,
    CARACAL_NAVY_ISSUE_GHOSTBIRD_SKIN  : 48187,
    CARACAL_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46848,
    CARACAL_NAVY_ISSUE_HUNTERS_QUIVER_SKIN  : 57779,
    CARACAL_NAVY_ISSUE_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47613,
    CARACAL_NAVY_ISSUE_SPIRIT_SKIN  : 57389,
    CARACAL_NAVY_ISSUE_STEEL_CARDINAL_SKIN  : 46121,
    CARACAL_NAVY_ISSUE_WIYRKOMI_SKIN  : 36353,
    CARACAL_NAVY_ISSUE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53181,
    CARACAL_NUGOEIHUVI_SKIN  : 34685,
    CARACAL_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47612,
    CARACAL_OCTOPUS_RED_FLIGHT_SKIN  : 46729,
    CARACAL_RAATA_SUNSET_SKIN  : 36650,
    CARACAL_SCOPE_SYNDICATION_YC122_SKIN  : 56881,
    CARACAL_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60218,
    CARACAL_SPIRIT_SKIN  : 47311,
    CARACAL_STEEL_CARDINAL_SKIN  : 46111,
    CARACAL_TRIGLAVIAN_TWILIGHT_SKIN  : 52399,
    CARACAL_UNION_DAY_EXECUTIVE_SKIN  : 71724,
    CARACAL_VERSUS_BLUEFORCE_SKIN  : 63244,
    CARACAL_WIYRKOMI_SKIN  : 34686,
    CARACAL_ZAKURA_SHUMYU_SKIN  : 57054,
    CARACAL_ZENTO_ISIDEKO_COMBINE_SKIN  : 53171,
    CARBIDE_COMPACT_DUAL_1000MM_RAILGUN  : 41138,
    CARBIDE_COMPACT_DUAL_1000MM_RAILGUN_BLUEPRINT  : 41143,
    CARBIDE_COMPACT_QUAD_3500MM_SIEGE_ARTILLERY  : 41156,
    CARBIDE_COMPACT_QUAD_3500MM_SIEGE_ARTILLERY_BLUEPRINT  : 41168,
    CARBINE_COMPACT_HEXA_2500MM_REPEATING_CANNON  : 41150,
    CARBINE_COMPACT_HEXA_2500MM_REPEATING_CANNON_BLUEPRINT  : 41161,
    CARBON  : 9826,
    CARBON_86_EPOXY_RESIN  : 30310,
    CARBON_86_EPOXY_RESIN_REACTION_FORMULA  : 46164,
    CARBON_COMPOUNDS  : 2288,
    CARBON_FIBER  : 57453,
    CARBON_FIBER_REACTION_FORMULA  : 57490,
    CARBON_POLYMERS  : 16659,
    CARBON_POLYMERS_REACTION_FORMULA  : 46167,
    CARBONIZED_LEAD_L  : 194,
    CARBONIZED_LEAD_L_BLUEPRINT  : 895,
    CARBONIZED_LEAD_M  : 186,
    CARBONIZED_LEAD_M_BLUEPRINT  : 887,
    CARBONIZED_LEAD_S  : 178,
    CARBONIZED_LEAD_S_BLUEPRINT  : 879,
    CARBONIZED_LEAD_XL  : 17664,
    CARBONIZED_LEAD_XL_BLUEPRINT  : 17665,
    CARGO_SCANNER_I  : 442,
    CARGO_SCANNER_I_BLUEPRINT  : 1074,
    CARGO_SCANNER_II  : 2038,
    CARNOTITE  : 45502,
    CARPO_MINING_LASER_UPGRADE  : 22613,
    CARTEL_POWER_DIAGNOSTIC_SYSTEM_I  : 22953,
    CARTESIAN_TEMPORAL_COORDINATOR  : 30024,
    CATACLYSMIC_DARK_FILAMENT  : 56140,
    CATACLYSMIC_ELECTRICAL_FILAMENT  : 56139,
    CATACLYSMIC_EXOTIC_FILAMENT  : 56141,
    CATACLYSMIC_FIRESTORM_FILAMENT  : 56142,
    CATACLYSMIC_GAMMA_FILAMENT  : 56143,
    CATALYST  : 16240,
    CATALYST_ACE_OF_PODHUNTERS_SKIN  : 63207,
    CATALYST_ALIASTRA_SKIN  : 34708,
    CATALYST_AURORA_UNIVERSALIS_SKIN  : 53377,
    CATALYST_BIOSECURITY_RESPONDERS_SKIN  : 55575,
    CATALYST_BLUEPRINT  : 16241,
    CATALYST_EXOPLANETS_HUNTER_SKIN  : 45888,
    CATALYST_GLACIAL_DRIFT_SKIN  : 44180,
    CATALYST_HARMONIOUS_ASCENSION_SKIN  : 53309,
    CATALYST_INNER_ZONE_SHIPPING_SKIN  : 34709,
    CATALYST_INNER_ZONE_VANGUARD_SKIN  : 52723,
    CATALYST_INTAKI_SYNDICATE_SKIN  : 34710,
    CATALYST_INTERBUS_SKIN  : 34711,
    CATALYST_KOPIS_EDGE_SKIN  : 46400,
    CATALYST_LUMINAIRE_RISING_SKIN  : 77867,
    CATALYST_METALBEE_GALLENTE_SKIN_PERMANENT  : 53536,
    CATALYST_NAVY_ISSUE  : 73796,
    CATALYST_NAVY_ISSUE_HEARTSURGE_SKIN  : 76289,
    CATALYST_NAVY_ISSUE_LUMINAIRE_RISING_SKIN  : 77886,
    CATALYST_QUAFE_SKIN  : 34712,
    CATALYST_RUBY_SUNGRAZER_SKIN  : 45563,
    CATALYST_SAPPHIRE_SUNGRAZER_SKIN  : 46549,
    CATALYST_SCOPE_SYNDICATION_YC122_SKIN  : 61191,
    CATALYST_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60220,
    CATALYST_SERPENTIS_SKIN  : 39585,
    CATALYST_SERPENTIS_SKIN_30_DAYS  : 35038,
    CATALYST_SERPENTIS_SKIN2  : 42162,
    CATALYST_SILO  : 25271,
    CATALYST_SPIRIT_SKIN  : 44906,
    CATALYST_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59234,
    CATALYST_VALIMOR_LEGACY_SKIN  : 37561,
    CATALYST_ZAKURA_SHUMYU_SKIN  : 57061,
    CATAPULT_MEGA_BEAM_LASER_I  : 23848,
    CDIA_TRAINING_EXERCISE_SUPPLY_CRATE  : 47482,
    CELADON_CYTOSEROCIN  : 25275,
    CELADON_MYKOSEROCIN  : 28696,
    CELESTIS  : 633,
    CELESTIS_BIOSECURITY_RESPONDERS_SKIN  : 55577,
    CELESTIS_BLUEPRINT  : 978,
    CELESTIS_EXOPLANETS_HUNTER_SKIN  : 45892,
    CELESTIS_FEDERATION_NAVY_SKIN  : 58889,
    CELESTIS_GLACIAL_DRIFT_SKIN  : 44183,
    CELESTIS_INNER_ZONE_VANGUARD_SKIN  : 52727,
    CELESTIS_INTAKI_SYNDICATE_SKIN  : 34689,
    CELESTIS_INTERBUS_SKIN  : 34690,
    CELESTIS_KOPIS_EDGE_SKIN  : 46404,
    CELESTIS_SEMIOTIQUE_SPORTS_SKIN  : 60260,
    CELESTIS_SERPENTIS_SKIN  : 42166,
    CELESTIS_SPIRIT_SKIN  : 44910,
    CELESTIS_VALIMOR_LEGACY_SKIN  : 37563,
    CENOBITE_I  : 37599,
    CENOBITE_I_BLUEPRINT  : 41367,
    CENOBITE_II  : 40568,
    CENSER_MEDIUM_CAP_BATTERY  : 23803,
    CENTII_A_TYPE_EM_COATING  : 18762,
    CENTII_A_TYPE_EXPLOSIVE_COATING  : 18758,
    CENTII_A_TYPE_KINETIC_COATING  : 18754,
    CENTII_A_TYPE_MULTISPECTRUM_COATING  : 18710,
    CENTII_A_TYPE_SMALL_ARMOR_REPAIRER  : 19009,
    CENTII_A_TYPE_SMALL_REMOTE_ARMOR_REPAIRER  : 19051,
    CENTII_A_TYPE_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 19075,
    CENTII_A_TYPE_THERMAL_COATING  : 18766,
    CENTII_B_TYPE_EM_COATING  : 18750,
    CENTII_B_TYPE_EXPLOSIVE_COATING  : 18746,
    CENTII_B_TYPE_KINETIC_COATING  : 18742,
    CENTII_B_TYPE_MULTISPECTRUM_COATING  : 18706,
    CENTII_B_TYPE_SMALL_ARMOR_REPAIRER  : 19007,
    CENTII_B_TYPE_SMALL_REMOTE_ARMOR_REPAIRER  : 19049,
    CENTII_B_TYPE_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 19073,
    CENTII_B_TYPE_THERMAL_COATING  : 18730,
    CENTII_C_TYPE_EM_COATING  : 18722,
    CENTII_C_TYPE_EXPLOSIVE_COATING  : 18718,
    CENTII_C_TYPE_KINETIC_COATING  : 18714,
    CENTII_C_TYPE_MULTISPECTRUM_COATING  : 18702,
    CENTII_C_TYPE_SMALL_ARMOR_REPAIRER  : 19005,
    CENTII_C_TYPE_SMALL_REMOTE_ARMOR_REPAIRER  : 19047,
    CENTII_C_TYPE_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 19071,
    CENTII_C_TYPE_THERMAL_COATING  : 18726,
    CENTRAL_SYSTEM_CONTROLLER  : 30270,
    CENTUM_A_TYPE_EM_ENERGIZED_MEMBRANE  : 18871,
    CENTUM_A_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18875,
    CENTUM_A_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18879,
    CENTUM_A_TYPE_MEDIUM_ARMOR_REPAIRER  : 19027,
    CENTUM_A_TYPE_MEDIUM_REMOTE_ARMOR_REPAIRER  : 19057,
    CENTUM_A_TYPE_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 19087,
    CENTUM_A_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18883,
    CENTUM_A_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18867,
    CENTUM_B_TYPE_EM_ENERGIZED_MEMBRANE  : 19363,
    CENTUM_B_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18859,
    CENTUM_B_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18855,
    CENTUM_B_TYPE_MEDIUM_ARMOR_REPAIRER  : 19025,
    CENTUM_B_TYPE_MEDIUM_REMOTE_ARMOR_REPAIRER  : 19055,
    CENTUM_B_TYPE_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 19085,
    CENTUM_B_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18851,
    CENTUM_B_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18863,
    CENTUM_C_TYPE_EM_ENERGIZED_MEMBRANE  : 18843,
    CENTUM_C_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18839,
    CENTUM_C_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18835,
    CENTUM_C_TYPE_MEDIUM_ARMOR_REPAIRER  : 19023,
    CENTUM_C_TYPE_MEDIUM_REMOTE_ARMOR_REPAIRER  : 19053,
    CENTUM_C_TYPE_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 19083,
    CENTUM_C_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18831,
    CENTUM_C_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18847,
    CENTUS_A_TYPE_EM_ARMOR_HARDENER  : 18931,
    CENTUS_A_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18927,
    CENTUS_A_TYPE_KINETIC_ARMOR_HARDENER  : 18923,
    CENTUS_A_TYPE_LARGE_ARMOR_REPAIRER  : 19044,
    CENTUS_A_TYPE_THERMAL_ARMOR_HARDENER  : 18919,
    CENTUS_B_TYPE_EM_ARMOR_HARDENER  : 18903,
    CENTUS_B_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18907,
    CENTUS_B_TYPE_KINETIC_ARMOR_HARDENER  : 18911,
    CENTUS_B_TYPE_LARGE_ARMOR_REPAIRER  : 19042,
    CENTUS_B_TYPE_THERMAL_ARMOR_HARDENER  : 18915,
    CENTUS_C_TYPE_EM_ARMOR_HARDENER  : 18887,
    CENTUS_C_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18891,
    CENTUS_C_TYPE_KINETIC_ARMOR_HARDENER  : 18895,
    CENTUS_C_TYPE_LARGE_ARMOR_REPAIRER  : 19040,
    CENTUS_C_TYPE_THERMAL_ARMOR_HARDENER  : 18899,
    CENTUS_X_TYPE_EM_ARMOR_HARDENER  : 18935,
    CENTUS_X_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18939,
    CENTUS_X_TYPE_KINETIC_ARMOR_HARDENER  : 18943,
    CENTUS_X_TYPE_LARGE_ARMOR_REPAIRER  : 19046,
    CENTUS_X_TYPE_THERMAL_ARMOR_HARDENER  : 18947,
    CERAMIC_POWDER  : 16660,
    CERAMIC_POWDER_REACTION_FORMULA  : 46168,
    CERBERUS  : 11993,
    CERBERUS_BIOSECURITY_RESPONDERS_SKIN  : 55549,
    CERBERUS_BLAZE_SQUADRON_SKIN  : 47105,
    CERBERUS_BLUE_TIGER_SKIN  : 36725,
    CERBERUS_BLUEPRINT  : 11994,
    CERBERUS_CAPSULEER_DAY_XV_SKIN  : 47628,
    CERBERUS_DRAGON_PHOENIX_SKIN  : 54428,
    CERBERUS_EXOPLANETS_HUNTER_SKIN  : 45837,
    CERBERUS_GHOSTBIRD_SKIN  : 48180,
    CERBERUS_GLACIAL_DRIFT_SKIN  : 46841,
    CERBERUS_MATIGU_SEABEAST_SKIN  : 40601,
    CERBERUS_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47614,
    CERBERUS_OCTOPUS_RED_FLIGHT_SKIN  : 46730,
    CERBERUS_RAATA_SUNSET_SKIN  : 36653,
    CERBERUS_STEEL_CARDINAL_SKIN  : 46114,
    CERBERUS_UNION_DAY_EXECUTIVE_SKIN  : 71725,
    CERBERUS_VITALSHIFT_EGO_SKIN  : 52555,
    CERBERUS_WIYRKOMI_SKIN  : 36344,
    CERBERUS_YOIUL_FESTIVAL_YC121_SKIN  : 49953,
    CERBERUS_YOIUL_STAR_SKIN  : 53425,
    CERBERUS_ZENTO_ISIDEKO_COMBINE_SKIN  : 53174,
    CEREBRUM_IN_A_CONTAINER  : 37877,
    CETUS_SCOPED_BURST_JAMMER  : 5365,
    CHAINMAIL_200MM_STEEL_PLATES  : 23787,
    CHAMELEON  : 33675,
    CHAOTIC_DARK_FILAMENT  : 47895,
    CHAOTIC_ELECTRICAL_FILAMENT  : 47907,
    CHAOTIC_EXOTIC_FILAMENT  : 47891,
    CHAOTIC_FIRESTORM_FILAMENT  : 47899,
    CHAOTIC_GAMMA_FILAMENT  : 47903,
    CHARLIE_PLACEHOLDER  : 45671,
    CHARLIEBLUEPRINT_PLACEHOLDER  : 45672,
    CHARON  : 20185,
    CHARON_BIOSECURITY_RESPONDERS_SKIN  : 55527,
    CHARON_BLUE_TIGER_SKIN  : 36749,
    CHARON_BLUEPRINT  : 20186,
    CHARON_COPPER_LIGHTNING_SKIN  : 60761,
    CHARON_EXOPLANETS_HUNTER_SKIN  : 45867,
    CHARON_GHOSTBIRD_SKIN  : 48210,
    CHARON_GLACIAL_DRIFT_SKIN  : 46871,
    CHARON_MALAGRIN_MARSHLIGHT_SKIN  : 56594,
    CHARON_MATIGU_SEABEAST_SKIN  : 40625,
    CHARON_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 62856,
    CHARON_RAATA_SUNSET_SKIN  : 36677,
    CHARON_STEEL_CARDINAL_SKIN  : 46144,
    CHARON_VITALSHIFT_EGO_SKIN  : 52562,
    CHARON_WIYRKOMI_SKIN  : 34668,
    CHARON_ZENTO_ISIDEKO_COMBINE_SKIN  : 53204,
    CHARRED_MICRO_CIRCUIT  : 25599,
    CHEETAH  : 11182,
    CHEETAH_BIOSECURITY_RESPONDERS_SKIN  : 55677,
    CHEETAH_BLUE_TIGER_SKIN  : 36806,
    CHEETAH_BLUEPRINT  : 11183,
    CHEETAH_DAWN_OF_LIBERATION_SKIN  : 48493,
    CHEETAH_EXOPLANETS_HUNTER_SKIN  : 45941,
    CHEETAH_GLACIAL_DRIFT_SKIN  : 46882,
    CHEETAH_HAZARD_CONTROL_SKIN  : 56901,
    CHEETAH_JUSTICE_SKIN  : 36424,
    CHEETAH_LUMINAIRE_RISING_SKIN  : 64411,
    CHEETAH_SNOWLINE_BLADERACER_SKIN  : 52852,
    CHEETAH_TRONHADAR_INK_SKIN  : 40475,
    CHEETAH_VALKLEAR_GLORY_SKIN  : 36759,
    CHEETAH_VERSUS_BLUEFORCE_SKIN  : 63867,
    CHEETAH_VERSUS_REDFORCE_SKIN  : 62021,
    CHELM_SORANS_TAG  : 17287,
    CHELMS_MODIFIED_CAP_RECHARGER  : 16605,
    CHELMS_MODIFIED_CAPACITOR_POWER_RELAY  : 15092,
    CHELMS_MODIFIED_DUAL_HEAVY_BEAM_LASER  : 14419,
    CHELMS_MODIFIED_EM_ARMOR_HARDENER  : 15028,
    CHELMS_MODIFIED_EM_COATING  : 14916,
    CHELMS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14980,
    CHELMS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15076,
    CHELMS_MODIFIED_EXPLOSIVE_COATING  : 14900,
    CHELMS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14996,
    CHELMS_MODIFIED_HEAT_SINK  : 14812,
    CHELMS_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15140,
    CHELMS_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14844,
    CHELMS_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14828,
    CHELMS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15060,
    CHELMS_MODIFIED_KINETIC_COATING  : 14884,
    CHELMS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15012,
    CHELMS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14854,
    CHELMS_MODIFIED_LARGE_EMP_SMARTBOMB  : 14796,
    CHELMS_MODIFIED_MEGA_BEAM_LASER  : 14435,
    CHELMS_MODIFIED_MULTISPECTRUM_COATING  : 14868,
    CHELMS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14948,
    CHELMS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15108,
    CHELMS_MODIFIED_REACTOR_CONTROL_UNIT  : 15124,
    CHELMS_MODIFIED_TACHYON_BEAM_LASER  : 14451,
    CHELMS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15044,
    CHELMS_MODIFIED_THERMAL_COATING  : 14932,
    CHELMS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14964,
    CHEMOSH  : 42243,
    CHEMOSH_GLACIAL_DRIFT_SKIN  : 46822,
    CHERUBIC_HEARTBURST_FIREWORK  : 49992,
    CHIMERA  : 23915,
    CHIMERA_BIOSECURITY_RESPONDERS_SKIN  : 55518,
    CHIMERA_BLUE_TIGER_SKIN  : 36742,
    CHIMERA_BLUEPRINT  : 23916,
    CHIMERA_EXOPLANETS_HUNTER_SKIN  : 45860,
    CHIMERA_GHOSTBIRD_SKIN  : 48203,
    CHIMERA_GLACIAL_DRIFT_SKIN  : 46864,
    CHIMERA_HUNTERS_QUIVER_SKIN  : 47497,
    CHIMERA_KAIRIOLAS_SACRIFICE_SKIN  : 46277,
    CHIMERA_LAI_DAI_SKIN  : 34651,
    CHIMERA_MATIGU_SEABEAST_SKIN  : 40618,
    CHIMERA_RAATA_SUNSET_SKIN  : 36670,
    CHIMERA_SPIRIT_SKIN  : 47315,
    CHIMERA_STEEL_CARDINAL_SKIN  : 46137,
    CHIMERA_WIYRKOMI_SKIN  : 36359,
    CHIMERA_YOIUL_STAR_SKIN  : 53426,
    CHIMERA_ZENTO_ISIDEKO_COMBINE_SKIN  : 53197,
    CHIRAL_STRUCTURES  : 2401,
    CHIVALRY_LARGE_REMOTE_CAPACITOR_TRANSMITTER  : 23856,
    CHREMOAS  : 33397,
    CHRIBBAS_MODIFIED_STRIP_MINER  : 34421,
    CHROMITE  : 45501,
    CHROMIUM  : 16641,
    CHROMODYNAMIC_TRICARBOXYLS  : 48927,
    CHRONOLOGICALLY_CURIOUS_BLUE_DIARY  : 62049,
    CINNABAR  : 45506,
    CITADELLA_100MM_STEEL_PLATES  : 23791,
    CITIZEN_VENTURE  : 48648,
    CIVILIAN_ARMOR_REPAIRER  : 21853,
    CIVILIAN_ARMOR_REPAIRER_BLUEPRINT  : 21854,
    CIVILIAN_DAMAGE_CONTROL  : 30839,
    CIVILIAN_DATA_ANALYZER_BLUEPRINT  : 27015,
    CIVILIAN_EM_SHIELD_HARDENER  : 30420,
    CIVILIAN_EXPANDED_CARGOHOLD  : 21855,
    CIVILIAN_EXPLOSIVE_SHIELD_HARDENER  : 30422,
    CIVILIAN_KINETIC_SHIELD_HARDENER  : 30424,
    CIVILIAN_MINER  : 3651,
    CIVILIAN_MINING_DRONE  : 1202,
    CIVILIAN_MINING_DRONE_BLUEPRINT  : 1218,
    CIVILIAN_SHIELD_BOOSTER  : 10039,
    CIVILIAN_SHIELD_BOOSTER_BLUEPRINT  : 10040,
    CIVILIAN_STASIS_WEBIFIER  : 30328,
    CIVILIAN_THERMAL_SHIELD_HARDENER  : 30342,
    CIVILIAN_WARP_DISRUPTOR  : 32459,
    CLADISTIC_15_KRAI_PERUN_FILAMENT  : 56071,
    CLADISTIC_15_KRAI_SVAROG_FILAMENT  : 56075,
    CLADISTIC_15_KRAI_VELES_FILAMENT  : 56073,
    CLADISTIC_5_KRAI_PERUN_FILAMENT  : 56070,
    CLADISTIC_5_KRAI_SVAROG_FILAMENT  : 56074,
    CLADISTIC_5_KRAI_VELES_FILAMENT  : 56072,
    CLASSIFIED_CALDARI_DATABANK  : 63699,
    CLAW  : 11196,
    CLAW_AURORA_UNIVERSALIS_SKIN  : 57009,
    CLAW_BIOSECURITY_RESPONDERS_SKIN  : 55685,
    CLAW_BLUE_TIGER_SKIN  : 36809,
    CLAW_BLUEPRINT  : 11197,
    CLAW_CAPSULEER_DAY_XV_SKIN  : 47635,
    CLAW_DAWN_OF_LIBERATION_SKIN  : 48496,
    CLAW_EXOPLANETS_HUNTER_SKIN  : 45944,
    CLAW_GLACIAL_DRIFT_SKIN  : 46885,
    CLAW_HAZARD_CONTROL_SKIN  : 56908,
    CLAW_JUSTICE_SKIN  : 36425,
    CLAW_LIBERATION_GAMES_SKIN  : 59469,
    CLAW_LUMINAIRE_RISING_SKIN  : 64410,
    CLAW_SNOWLINE_BLADERACER_SKIN  : 52855,
    CLAW_TRONHADAR_INK_SKIN  : 40478,
    CLAW_VALKLEAR_GLORY_SKIN  : 36762,
    CLAYMORE  : 22468,
    CLAYMORE_BIOSECURITY_RESPONDERS_SKIN  : 55698,
    CLAYMORE_BLUE_TIGER_SKIN  : 36827,
    CLAYMORE_BLUEPRINT  : 22469,
    CLAYMORE_EXOPLANETS_HUNTER_SKIN  : 45970,
    CLAYMORE_FREEDOMS_REVOLUTION_SKIN  : 55712,
    CLAYMORE_GLACIAL_DRIFT_SKIN  : 46907,
    CLAYMORE_HAZARD_CONTROL_SKIN  : 56899,
    CLAYMORE_JUSTICE_SKIN  : 36448,
    CLAYMORE_LIBERATION_GAMES_SKIN  : 59473,
    CLAYMORE_SNOWLINE_BLADERACER_SKIN  : 52881,
    CLAYMORE_TRONHADAR_INK_SKIN  : 40498,
    CLAYMORE_VALKLEAR_GLORY_SKIN  : 36780,
    CLEAR_ICICLE  : 16262,
    CLOAKING  : 11579,
    CLONE_SOLDIER_NEGOTIATOR_TAG  : 33141,
    CLONE_SOLDIER_RECRUITER_TAG  : 33139,
    CLONE_SOLDIER_TRAINER_TAG  : 33138,
    CLONE_SOLDIER_TRANSPORTER_TAG  : 33140,
    CLONE_VAT_BAY_I  : 23735,
    CLONE_VAT_BAY_I_BLUEPRINT  : 23736,
    CLONING_FACILITY_OPERATION  : 24606,
    CLUTCH_RESTRAINED_WARP_DISRUPTION_FIELD_GENERATOR  : 37610,
    CMT_05N_NULLSEC_ICE_STORM_FILAMENT  : 74433,
    CO_PROCESSOR_I  : 3887,
    CO_PROCESSOR_I_BLUEPRINT  : 11619,
    CO_PROCESSOR_II  : 3888,
    COADJUNCT_SCOPED_REMOTE_SENSOR_BOOSTER  : 5281,
    COALESCED_ELEMENT  : 36902,
    COALESCED_ELEMENT_BLUEPRINT  : 36949,
    COBALT  : 16640,
    COBALTITE  : 45494,
    COERCER  : 16236,
    COERCER_ABYSSAL_FIRESTORM_SKIN  : 53132,
    COERCER_ACE_OF_PODHUNTERS_SKIN  : 63201,
    COERCER_ARDISHAPUR_SKIN  : 34700,
    COERCER_AURORA_UNIVERSALIS_SKIN  : 53367,
    COERCER_BIOSECURITY_RESPONDERS_SKIN  : 55323,
    COERCER_BLOOD_RAIDERS_SKIN_30_DAYS  : 35062,
    COERCER_BLUEPRINT  : 16237,
    COERCER_COLD_IRON_SKIN  : 42762,
    COERCER_EXOPLANETS_HUNTER_SKIN  : 45769,
    COERCER_GLACIAL_DRIFT_SKIN  : 44140,
    COERCER_HARMONIOUS_ASCENSION_SKIN  : 53307,
    COERCER_IMPERIAL_JUBILEE_SKIN  : 48845,
    COERCER_IMPERIAL_NAVY_SKIN  : 58897,
    COERCER_IRONBLOOD_SKIN  : 43496,
    COERCER_LUMINAIRE_ZENITH_SKIN  : 48219,
    COERCER_METALBEE_AMARR_SKIN_PERMANENT  : 53537,
    COERCER_NAVY_ISSUE  : 73789,
    COERCER_NAVY_ISSUE_HEARTSURGE_SKIN  : 76293,
    COERCER_OASIS_OF_FAITH_SKIN  : 53611,
    COERCER_ORDER_OF_TETRIMON_SKIN  : 73461,
    COERCER_PURITY_OF_THE_THRONE_SKIN  : 42560,
    COERCER_RAATA_SUNSET_SKIN  : 37497,
    COERCER_SARUM_SKIN  : 34702,
    COERCER_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60214,
    COERCER_TRIGLAVIAN_TWILIGHT_SKIN  : 52394,
    COERCER_WAR_RESERVES_LIEUTENANT_SKIN  : 59210,
    COERCER_ZAKURA_SHUMYU_SKIN  : 57057,
    COESITE  : 45493,
    COGNI_EMOTIVE_NEUROLINK_ENHANCER  : 57461,
    COGNI_EMOTIVE_NEUROLINK_ENHANCER_REACTION_FORMULA  : 57498,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_A_I  : 60285,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 60343,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_A_II  : 60288,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_B_I  : 60286,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 60365,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_B_II  : 60289,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_C_I  : 60287,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 60366,
    COHERENT_ASTEROID_MINING_CRYSTAL_TYPE_C_II  : 60290,
    COHERENT_ORE_PROCESSING  : 60378,
    COLD_WIND_KINETIC_REAPER  : 41442,
    COLD_WIND_KINETIC_REAPER_BLUEPRINT  : 41449,
    COLONIZE_BOARD_GAME  : 49653,
    COMBAT_SCANNER_PROBE_I  : 30028,
    COMBAT_SCANNER_PROBE_I_BLUEPRINT  : 30029,
    COMMAND_BURST_SPECIALIST  : 3354,
    COMMAND_CENTER_UPGRADES  : 2505,
    COMMAND_DESTROYERS  : 37615,
    COMMAND_SHIPS  : 23950,
    COMMON_MOON_MINING_CRYSTAL_TYPE_A_I  : 46365,
    COMMON_MOON_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 46352,
    COMMON_MOON_MINING_CRYSTAL_TYPE_A_II  : 46366,
    COMMON_MOON_MINING_CRYSTAL_TYPE_B_I  : 61201,
    COMMON_MOON_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 61221,
    COMMON_MOON_MINING_CRYSTAL_TYPE_B_II  : 61203,
    COMMON_MOON_MINING_CRYSTAL_TYPE_C_I  : 61202,
    COMMON_MOON_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 61222,
    COMMON_MOON_MINING_CRYSTAL_TYPE_C_II  : 61204,
    COMMON_MOON_ORE_PROCESSING  : 46153,
    COMPACT_CARBINE_QUAD_800MM_REPEATING_CANNON  : 37305,
    COMPACT_CARBINE_QUAD_800MM_REPEATING_CANNON_BLUEPRINT  : 41093,
    COMPACT_COMPOUNDS  : 41267,
    COMPACT_CONDUCTORS  : 41268,
    COMPACT_ELECTRONICS  : 41266,
    COMPACT_EM_ENERGIZED_MEMBRANE  : 16415,
    COMPACT_EM_SHIELD_AMPLIFIER  : 9580,
    COMPACT_EM_SHIELD_HARDENER  : 9622,
    COMPACT_ENTROPIC_RADIATION_SINK  : 47909,
    COMPACT_EXPLOSIVE_ENERGIZED_MEMBRANE  : 16407,
    COMPACT_EXPLOSIVE_SHIELD_AMPLIFIER  : 9556,
    COMPACT_EXPLOSIVE_SHIELD_HARDENER  : 9646,
    COMPACT_INTERDICTION_NULLIFIER  : 58966,
    COMPACT_INTERDICTION_NULLIFIER_BLUEPRINT  : 58973,
    COMPACT_KINETIC_ENERGIZED_MEMBRANE  : 16399,
    COMPACT_KINETIC_SHIELD_AMPLIFIER  : 9582,
    COMPACT_KINETIC_SHIELD_HARDENER  : 9608,
    COMPACT_LAYERED_ENERGIZED_MEMBRANE  : 16423,
    COMPACT_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 16391,
    COMPACT_MULTISPECTRUM_SHIELD_HARDENER  : 9632,
    COMPACT_THERMAL_ENERGIZED_MEMBRANE  : 16431,
    COMPACT_THERMAL_SHIELD_AMPLIFIER  : 9568,
    COMPACT_THERMAL_SHIELD_HARDENER  : 9660,
    COMPACT_VORTON_TUNING_SYSTEM_I  : 54974,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_A_I  : 60297,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 60347,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_A_II  : 60300,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_B_I  : 60298,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 60369,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_B_II  : 60301,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_C_I  : 60299,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 60370,
    COMPLEX_ASTEROID_MINING_CRYSTAL_TYPE_C_II  : 60302,
    COMPLEX_ORE_PROCESSING  : 60380,
    COMPLEX_ORGANISMS  : 2287,
    COMPLEX_REACTOR_ARRAY  : 16869,
    COMPONENT_ASSEMBLY_ARRAY  : 24660,
    COMPRESSED_ABYSSAL_BEZDNACINE  : 62577,
    COMPRESSED_ABYSSAL_RAKOVENE  : 62580,
    COMPRESSED_ABYSSAL_TALASSONITE  : 62583,
    COMPRESSED_AMBER_CYTOSEROCIN  : 62396,
    COMPRESSED_AMBER_MYKOSEROCIN  : 62377,
    COMPRESSED_ARKONOR  : 62568,
    COMPRESSED_AUGMENTED_EIFYRIUM  : 75286,
    COMPRESSED_AZURE_CYTOSEROCIN  : 62386,
    COMPRESSED_AZURE_MYKOSEROCIN  : 62379,
    COMPRESSED_AZURE_PLAGIOCLASE  : 62529,
    COMPRESSED_BEZDNACINE  : 62576,
    COMPRESSED_BISTOT  : 62564,
    COMPRESSED_BITUMENS  : 62454,
    COMPRESSED_BLUE_ICE  : 28433,
    COMPRESSED_BOOSTED_EIFYRIUM  : 75285,
    COMPRESSED_BOOTLEG_YTIRIUM  : 75280,
    COMPRESSED_BOUNTIFUL_LOPARITE  : 62505,
    COMPRESSED_BOUNTIFUL_MONAZITE  : 62508,
    COMPRESSED_BOUNTIFUL_XENOTIME  : 62511,
    COMPRESSED_BOUNTIFUL_YTTERBITE  : 62514,
    COMPRESSED_BRIGHT_SPODUMAIN  : 62573,
    COMPRESSED_BRILLIANT_GNEISS  : 62555,
    COMPRESSED_BRIMFUL_BITUMENS  : 62455,
    COMPRESSED_BRIMFUL_COESITE  : 62458,
    COMPRESSED_BRIMFUL_SYLVITE  : 62461,
    COMPRESSED_BRIMFUL_ZEOLITES  : 62464,
    COMPRESSED_CAPSULE_SHELL  : 47450,
    COMPRESSED_CARNOTITE  : 62492,
    COMPRESSED_CELADON_CYTOSEROCIN  : 62387,
    COMPRESSED_CELADON_MYKOSEROCIN  : 62380,
    COMPRESSED_CHROMITE  : 62480,
    COMPRESSED_CINNABAR  : 62495,
    COMPRESSED_CLEAR_ICICLE  : 28434,
    COMPRESSED_COBALTITE  : 62474,
    COMPRESSED_COESITE  : 62457,
    COMPRESSED_CONCENTRATED_VELDSPAR  : 62517,
    COMPRESSED_CONDENSED_SCORDITE  : 62521,
    COMPRESSED_COPIOUS_COBALTITE  : 62475,
    COMPRESSED_COPIOUS_EUXENITE  : 62472,
    COMPRESSED_COPIOUS_SCHEELITE  : 62469,
    COMPRESSED_COPIOUS_TITANITE  : 62478,
    COMPRESSED_CRIMSON_ARKONOR  : 62569,
    COMPRESSED_CROKITE  : 62560,
    COMPRESSED_CRYSTALLINE_CROKITE  : 62562,
    COMPRESSED_CUBIC_BISTOT  : 62567,
    COMPRESSED_DARK_GLITTER  : 28435,
    COMPRESSED_DARK_OCHRE  : 62556,
    COMPRESSED_DAZZLING_SPODUMAIN  : 62575,
    COMPRESSED_DENSE_VELDSPAR  : 62518,
    COMPRESSED_DOPED_EIFYRIUM  : 75284,
    COMPRESSED_DUCINIUM  : 75287,
    COMPRESSED_EIFYRIUM  : 75283,
    COMPRESSED_ENDURING_DUAL_1000MM_RAILGUN  : 41139,
    COMPRESSED_ENDURING_DUAL_1000MM_RAILGUN_BLUEPRINT  : 41144,
    COMPRESSED_ENRICHED_CLEAR_ICICLE  : 28436,
    COMPRESSED_EUXENITE  : 62471,
    COMPRESSED_FIERY_KERNITE  : 62538,
    COMPRESSED_FIREWATER_YTIRIUM  : 75281,
    COMPRESSED_FLAWLESS_ARKONOR  : 62571,
    COMPRESSED_FULLERITE_C28  : 62402,
    COMPRESSED_FULLERITE_C32  : 62404,
    COMPRESSED_FULLERITE_C320  : 62406,
    COMPRESSED_FULLERITE_C50  : 62399,
    COMPRESSED_FULLERITE_C540  : 62405,
    COMPRESSED_FULLERITE_C60  : 62397,
    COMPRESSED_FULLERITE_C70  : 62398,
    COMPRESSED_FULLERITE_C72  : 62403,
    COMPRESSED_FULLERITE_C84  : 62400,
    COMPRESSED_GELIDUS  : 28437,
    COMPRESSED_GLACIAL_MASS  : 28438,
    COMPRESSED_GLARE_CRUST  : 28439,
    COMPRESSED_GLAZED_HEDBERGITE  : 62550,
    COMPRESSED_GLEAMING_SPODUMAIN  : 62574,
    COMPRESSED_GLISTENING_BITUMENS  : 62456,
    COMPRESSED_GLISTENING_COESITE  : 62459,
    COMPRESSED_GLISTENING_SYLVITE  : 62466,
    COMPRESSED_GLISTENING_ZEOLITES  : 62467,
    COMPRESSED_GLOSSY_SCORDITE  : 62523,
    COMPRESSED_GLOWING_CARNOTITE  : 62494,
    COMPRESSED_GLOWING_CINNABAR  : 62497,
    COMPRESSED_GLOWING_POLLUCITE  : 62500,
    COMPRESSED_GLOWING_ZIRCON  : 62503,
    COMPRESSED_GNEISS  : 62552,
    COMPRESSED_GOLDEN_CYTOSEROCIN  : 62390,
    COMPRESSED_GOLDEN_MYKOSEROCIN  : 62381,
    COMPRESSED_GOLDEN_OMBER  : 62534,
    COMPRESSED_HADAL_BEZDNACINE  : 62578,
    COMPRESSED_HADAL_RAKOVENE  : 62581,
    COMPRESSED_HADAL_TALASSONITE  : 62584,
    COMPRESSED_HEDBERGITE  : 62548,
    COMPRESSED_HEMORPHITE  : 62544,
    COMPRESSED_IMMACULATE_JASPET  : 62543,
    COMPRESSED_IMPERIAL_DUCINIUM  : 75290,
    COMPRESSED_IRIDESCENT_GNEISS  : 62553,
    COMPRESSED_JASPET  : 62540,
    COMPRESSED_JET_OCHRE  : 62559,
    COMPRESSED_KERNITE  : 62536,
    COMPRESSED_KRYSTALLOS  : 28440,
    COMPRESSED_LAVISH_CHROMITE  : 62481,
    COMPRESSED_LAVISH_OTAVITE  : 62484,
    COMPRESSED_LAVISH_SPERRYLITE  : 62487,
    COMPRESSED_LAVISH_VANADINITE  : 62490,
    COMPRESSED_LIME_CYTOSEROCIN  : 62391,
    COMPRESSED_LIME_MYKOSEROCIN  : 62382,
    COMPRESSED_LOPARITE  : 62504,
    COMPRESSED_LUMINOUS_KERNITE  : 62537,
    COMPRESSED_LUSTROUS_HEDBERGITE  : 62551,
    COMPRESSED_MAGMA_MERCOXIT  : 62587,
    COMPRESSED_MALACHITE_CYTOSEROCIN  : 62392,
    COMPRESSED_MALACHITE_MYKOSEROCIN  : 62383,
    COMPRESSED_MASSIVE_SCORDITE  : 62522,
    COMPRESSED_MERCOXIT  : 62586,
    COMPRESSED_MONAZITE  : 62507,
    COMPRESSED_MONOCLINIC_BISTOT  : 62566,
    COMPRESSED_MOONSHINE_YTIRIUM  : 75282,
    COMPRESSED_MORDUNIUM  : 75275,
    COMPRESSED_NOBLE_DUCINIUM  : 75288,
    COMPRESSED_OBSIDIAN_OCHRE  : 62558,
    COMPRESSED_OMBER  : 62532,
    COMPRESSED_ONYX_OCHRE  : 62557,
    COMPRESSED_OPULENT_PYROXERES  : 62527,
    COMPRESSED_OTAVITE  : 62483,
    COMPRESSED_PELLUCID_CROKITE  : 62563,
    COMPRESSED_PLAGIOCLASE  : 62528,
    COMPRESSED_PLATINOID_OMBER  : 62535,
    COMPRESSED_PLUM_MORDUNIUM  : 75276,
    COMPRESSED_PLUNDER_MORDUNIUM  : 75278,
    COMPRESSED_POLLUCITE  : 62498,
    COMPRESSED_PRIME_ARKONOR  : 62570,
    COMPRESSED_PRISMATIC_GNEISS  : 62554,
    COMPRESSED_PRISTINE_JASPET  : 62542,
    COMPRESSED_PRISTINE_WHITE_GLAZE  : 28441,
    COMPRESSED_PRIZE_MORDUNIUM  : 75277,
    COMPRESSED_PURE_JASPET  : 62541,
    COMPRESSED_PYROXERES  : 62524,
    COMPRESSED_RADIANT_HEMORPHITE  : 62546,
    COMPRESSED_RAKOVENE  : 62579,
    COMPRESSED_REPLETE_CARNOTITE  : 62493,
    COMPRESSED_REPLETE_CINNABAR  : 62496,
    COMPRESSED_REPLETE_POLLUCITE  : 62499,
    COMPRESSED_REPLETE_ZIRCON  : 62502,
    COMPRESSED_RESPLENDANT_KERNITE  : 62539,
    COMPRESSED_RICH_PLAGIOCLASE  : 62530,
    COMPRESSED_ROYAL_DUCINIUM  : 75289,
    COMPRESSED_SCHEELITE  : 62468,
    COMPRESSED_SCINTILLATING_HEMORPHITE  : 62547,
    COMPRESSED_SCORDITE  : 62520,
    COMPRESSED_SHARP_CROKITE  : 62561,
    COMPRESSED_SHIMMERING_CHROMITE  : 62482,
    COMPRESSED_SHIMMERING_OTAVITE  : 62485,
    COMPRESSED_SHIMMERING_SPERRYLITE  : 62488,
    COMPRESSED_SHIMMERING_VANADINITE  : 62491,
    COMPRESSED_SHINING_LOPARITE  : 62506,
    COMPRESSED_SHINING_MONAZITE  : 62509,
    COMPRESSED_SHINING_XENOTIME  : 62512,
    COMPRESSED_SHINING_YTTERBITE  : 62515,
    COMPRESSED_SILVERY_OMBER  : 62533,
    COMPRESSED_SMOOTH_GLACIAL_MASS  : 28442,
    COMPRESSED_SOLID_PYROXERES  : 62525,
    COMPRESSED_SPARKLING_PLAGIOCLASE  : 62531,
    COMPRESSED_SPERRYLITE  : 62486,
    COMPRESSED_SPODUMAIN  : 62572,
    COMPRESSED_STABLE_VELDSPAR  : 62519,
    COMPRESSED_SYLVITE  : 62460,
    COMPRESSED_TALASSONITE  : 62582,
    COMPRESSED_THICK_BLUE_ICE  : 28443,
    COMPRESSED_TITANITE  : 62477,
    COMPRESSED_TRICLINIC_BISTOT  : 62565,
    COMPRESSED_TWINKLING_COBALTITE  : 62476,
    COMPRESSED_TWINKLING_EUXENITE  : 62473,
    COMPRESSED_TWINKLING_SCHEELITE  : 62470,
    COMPRESSED_TWINKLING_TITANITE  : 62479,
    COMPRESSED_VANADINITE  : 62489,
    COMPRESSED_VELDSPAR  : 62516,
    COMPRESSED_VERMILLION_CYTOSEROCIN  : 62393,
    COMPRESSED_VERMILLION_MYKOSEROCIN  : 62384,
    COMPRESSED_VIRIDIAN_CYTOSEROCIN  : 62394,
    COMPRESSED_VIRIDIAN_MYKOSEROCIN  : 62385,
    COMPRESSED_VISCOUS_PYROXERES  : 62526,
    COMPRESSED_VITREOUS_MERCOXIT  : 62588,
    COMPRESSED_VITRIC_HEDBERGITE  : 62549,
    COMPRESSED_VIVID_HEMORPHITE  : 62545,
    COMPRESSED_WHITE_GLAZE  : 28444,
    COMPRESSED_XENOTIME  : 62510,
    COMPRESSED_YTIRIUM  : 75279,
    COMPRESSED_YTTERBITE  : 62513,
    COMPRESSED_ZEOLITES  : 62463,
    COMPRESSED_ZIRCON  : 62501,
    COMPRESSION_ARRAY  : 12239,
    COMPULSIVE_SCOPED_MULTISPECTRAL_ECM  : 19931,
    COMPUTER_CHIPS  : 21591,
    CONCENTRATED_VELDSPAR  : 17470,
    CONCORD_25000MM_STEEL_PLATES  : 41456,
    CONCORD_CAPITAL_ARMOR_REPAIRER  : 3534,
    CONCORD_CAPITAL_REMOTE_ARMOR_REPAIRER  : 3536,
    CONCORD_CAPITAL_REMOTE_CAPACITOR_TRANSMITTER  : 3575,
    CONCORD_CAPITAL_REMOTE_SHIELD_BOOSTER  : 3544,
    CONCORD_CAPITAL_SHIELD_BOOSTER  : 3542,
    CONCORD_CAPITAL_SHIELD_EXTENDER  : 41459,
    CONCORD_CAPTAIN_EMBLEM  : 16122,
    CONCORD_COLONEL_EMBLEM  : 16124,
    CONCORD_DUAL_1000MM_RAILGUN  : 3550,
    CONCORD_DUAL_GIGA_BEAM_LASER  : 3561,
    CONCORD_DUAL_GIGA_PULSE_LASER  : 3559,
    CONCORD_GALNET_MONITORING_ARCHIVE  : 47055,
    CONCORD_GENERAL_EMBLEM  : 16125,
    CONCORD_HEXA_2500MM_REPEATING_CANNON  : 3573,
    CONCORD_ION_SIEGE_BLASTER  : 3546,
    CONCORD_MODIFIED_CLOAKING_DEVICE  : 16126,
    CONCORD_MODIFIED_WARP_SCRAMBLER  : 16140,
    CONCORD_OFFICER_EMBLEM  : 16118,
    CONCORD_PANTHER_EMBLEM  : 16121,
    CONCORD_PIRANHA_EMBLEM  : 16120,
    CONCORD_QUAD_3500MM_SIEGE_ARTILLERY  : 3571,
    CONCORD_RAPTOR_EMBLEM  : 16123,
    CONCORD_ROGUE_ANALYSIS_BEACON  : 60244,
    CONCORD_ROGUE_ANALYSIS_BEACON_BLUEPRINT  : 60514,
    CONCORD_SOLDIER_EMBLEM  : 16119,
    CONCORD_SPEEDING_TICKET  : 44214,
    CONCORD_STAR_EMBLEM  : 16617,
    CONCORD_UNIVERSAL_TRANSLATOR  : 48754,
    CONCORD_WARP_DISRUPTION_FIELD_GENERATOR  : 37613,
    CONCORD_XL_CRUISE_MISSILE_LAUNCHER  : 3563,
    CONCORD_XL_TORPEDO_LAUNCHER  : 3565,
    CONCORDOKKEN  : 33058,
    CONCUSSION_BOMB  : 27912,
    CONCUSSION_BOMB_BLUEPRINT  : 27913,
    CONDENSATES  : 2344,
    CONDENSED_ALLOY  : 11739,
    CONDENSED_SCORDITE  : 17463,
    CONDOR  : 583,
    CONDOR_AURORA_UNIVERSALIS_SKIN  : 53370,
    CONDOR_BIOSECURITY_RESPONDERS_SKIN  : 55536,
    CONDOR_BLUE_TIGER_SKIN  : 36706,
    CONDOR_BLUEPRINT  : 684,
    CONDOR_EXOPLANETS_HUNTER_SKIN  : 45813,
    CONDOR_GHOSTBIRD_SKIN  : 48156,
    CONDOR_GLACIAL_DRIFT_SKIN  : 44152,
    CONDOR_GURISTAS_SKIN  : 54552,
    CONDOR_MATIGU_SEABEAST_SKIN  : 40580,
    CONDOR_RAATA_SUNSET_SKIN  : 36634,
    CONDOR_STEEL_CARDINAL_SKIN  : 46090,
    CONDOR_VERSUS_REDFORCE_SKIN  : 63141,
    CONDOR_ZAKURA_HANSEI_SKIN  : 57180,
    CONDOR_ZENTO_ISIDEKO_COMBINE_SKIN  : 53150,
    CONDUCTIVE_POLYMER  : 25604,
    CONDUCTIVE_THERMOPLASTIC  : 25623,
    CONFESSOR  : 34317,
    CONFESSOR_BIOSECURITY_RESPONDERS_SKIN  : 55325,
    CONFESSOR_COLD_IRON_SKIN  : 42907,
    CONFESSOR_CROWN_AND_SWORDS_SKIN  : 52770,
    CONFESSOR_EROS_BLOSSOM_SKIN  : 50069,
    CONFESSOR_EXOPLANETS_HUNTER_SKIN  : 45773,
    CONFESSOR_GLACIAL_DRIFT_SKIN  : 46786,
    CONFESSOR_HEARTSURGE_SKIN  : 53953,
    CONFESSOR_IMPERIAL_JUBILEE_SKIN  : 46527,
    CONFESSOR_LAVACORE_IMPERIAL_SKIN  : 49816,
    CONFESSOR_MALAGRIN_MARSHLIGHT_SKIN  : 56590,
    CONFESSOR_OASIS_OF_FAITH_SKIN  : 53615,
    CONFESSOR_PURITY_OF_THE_THRONE_SKIN  : 42564,
    CONFESSOR_STAR_CAPTAIN_SKIN  : 44101,
    CONFESSOR_YOIUL_STAR_SKIN  : 61121,
    CONFISCATED_VIRAL_AGENT  : 19398,
    CONFLAGRATI_MUTANITE  : 77419,
    CONFLAGRATION_L  : 12816,
    CONFLAGRATION_M  : 12814,
    CONFLAGRATION_S  : 12565,
    CONFLAGRATION_XL  : 41332,
    CONFLUX_ELEMENT  : 34559,
    CONFLUX_INDEX  : 34554,
    CONFLUX_SEQUENCE_0  : 34546,
    CONFLUX_SEQUENCE_1  : 34547,
    CONNECTIONS  : 3359,
    CONSTRUCTION_ALLOY  : 21595,
    CONSTRUCTION_BLOCKS  : 3828,
    CONSUMER_ELECTRONICS  : 9836,
    CONTAMINATED_LORENTZ_FLUID  : 25591,
    CONTAMINATED_NANITE_COMPOUND  : 25590,
    CONTRABAND_AMARRIAN_WALNUTS  : 37875,
    CONTRACTING  : 25235,
    CONTROLLED_BURSTS  : 3316,
    CONVOY_HAWK_6_ESCORT_ROSTER  : 41023,
    COOKING_WITH_VELDSPAR  : 34420,
    COOLANT  : 9832,
    COPASETIC_COMPACT_SHIELD_BOOST_AMPLIFIER  : 16535,
    COPIOUS_COBALTITE  : 46288,
    COPIOUS_EUXENITE  : 46290,
    COPIOUS_SCHEELITE  : 46294,
    COPIOUS_TITANITE  : 46292,
    COPPER_FIREWORK  : 33573,
    COPPER_FIREWORK_CXIV  : 32995,
    COPPER_OUROBOROS  : 42229,
    COPY_OF_THE_GALLENTE_FEDERATION_CONSTITUTION  : 48135,
    CORAX  : 32876,
    CORAX_BIOSECURITY_RESPONDERS_SKIN  : 55540,
    CORAX_BLUE_TIGER_SKIN  : 36718,
    CORAX_BLUEPRINT  : 32877,
    CORAX_CALDARI_UNION_DAY_SKIN  : 48727,
    CORAX_DEATHGLOW_REMNANT_SKIN  : 73370,
    CORAX_EXOPLANETS_HUNTER_SKIN  : 45828,
    CORAX_GHOSTBIRD_SKIN  : 48171,
    CORAX_GLACIAL_DRIFT_SKIN  : 44155,
    CORAX_GURISTAS_SKIN  : 54556,
    CORAX_LUMINAIRE_ZENITH_SKIN  : 48223,
    CORAX_MATIGU_SEABEAST_SKIN  : 40593,
    CORAX_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59428,
    CORAX_RAATA_SUNSET_SKIN  : 36646,
    CORAX_SEMIOTIQUE_SPORTS_SKIN  : 60208,
    CORAX_STEEL_CARDINAL_SKIN  : 46105,
    CORAX_UNION_DAY_EXECUTIVE_SKIN  : 71721,
    CORAX_ZENTO_ISIDEKO_COMBINE_SKIN  : 53165,
    CORE_A_TYPE_100MN_AFTERBURNER  : 18696,
    CORE_A_TYPE_500MN_MICROWARPDRIVE  : 19329,
    CORE_A_TYPE_EM_ARMOR_HARDENER  : 18965,
    CORE_A_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18967,
    CORE_A_TYPE_KINETIC_ARMOR_HARDENER  : 18969,
    CORE_A_TYPE_LARGE_ARMOR_REPAIRER  : 19037,
    CORE_A_TYPE_THERMAL_ARMOR_HARDENER  : 18971,
    CORE_B_TYPE_100MN_AFTERBURNER  : 18690,
    CORE_B_TYPE_500MN_MICROWARPDRIVE  : 19323,
    CORE_B_TYPE_EM_ARMOR_HARDENER  : 18957,
    CORE_B_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18959,
    CORE_B_TYPE_KINETIC_ARMOR_HARDENER  : 18961,
    CORE_B_TYPE_LARGE_ARMOR_REPAIRER  : 19036,
    CORE_B_TYPE_THERMAL_ARMOR_HARDENER  : 18963,
    CORE_C_TYPE_100MN_AFTERBURNER  : 18684,
    CORE_C_TYPE_500MN_MICROWARPDRIVE  : 19317,
    CORE_C_TYPE_EM_ARMOR_HARDENER  : 18949,
    CORE_C_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18951,
    CORE_C_TYPE_KINETIC_ARMOR_HARDENER  : 18953,
    CORE_C_TYPE_LARGE_ARMOR_REPAIRER  : 19035,
    CORE_C_TYPE_THERMAL_ARMOR_HARDENER  : 18955,
    CORE_PROBE_LAUNCHER_I  : 17938,
    CORE_PROBE_LAUNCHER_I_BLUEPRINT  : 17939,
    CORE_PROBE_LAUNCHER_II  : 4258,
    CORE_SCANNER_PROBE_I  : 30013,
    CORE_SCANNER_PROBE_I_BLUEPRINT  : 30014,
    CORE_SUBSYSTEM_TECHNOLOGY  : 30325,
    CORE_TEMPERATURE_REGULATOR  : 57479,
    CORE_TEMPERATURE_REGULATOR_BLUEPRINT  : 57516,
    CORE_X_TYPE_100MN_AFTERBURNER  : 18698,
    CORE_X_TYPE_500MN_MICROWARPDRIVE  : 19335,
    CORE_X_TYPE_EM_ARMOR_HARDENER  : 18973,
    CORE_X_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18975,
    CORE_X_TYPE_KINETIC_ARMOR_HARDENER  : 18977,
    CORE_X_TYPE_LARGE_ARMOR_REPAIRER  : 19038,
    CORE_X_TYPE_THERMAL_ARMOR_HARDENER  : 18979,
    CORELI_A_TYPE_1MN_AFTERBURNER  : 18692,
    CORELI_A_TYPE_5MN_MICROWARPDRIVE  : 19325,
    CORELI_A_TYPE_EM_COATING  : 18795,
    CORELI_A_TYPE_EXPLOSIVE_COATING  : 18793,
    CORELI_A_TYPE_KINETIC_COATING  : 18791,
    CORELI_A_TYPE_MULTISPECTRUM_COATING  : 18789,
    CORELI_A_TYPE_SMALL_ARMOR_REPAIRER  : 19015,
    CORELI_A_TYPE_SMALL_REMOTE_ARMOR_REPAIRER  : 18985,
    CORELI_A_TYPE_THERMAL_COATING  : 18797,
    CORELI_B_TYPE_1MN_AFTERBURNER  : 18686,
    CORELI_B_TYPE_5MN_MICROWARPDRIVE  : 19319,
    CORELI_B_TYPE_EM_COATING  : 18785,
    CORELI_B_TYPE_EXPLOSIVE_COATING  : 18783,
    CORELI_B_TYPE_KINETIC_COATING  : 18781,
    CORELI_B_TYPE_MULTISPECTRUM_COATING  : 18779,
    CORELI_B_TYPE_SMALL_ARMOR_REPAIRER  : 19013,
    CORELI_B_TYPE_SMALL_REMOTE_ARMOR_REPAIRER  : 18983,
    CORELI_B_TYPE_THERMAL_COATING  : 18787,
    CORELI_C_TYPE_1MN_AFTERBURNER  : 18680,
    CORELI_C_TYPE_5MN_MICROWARPDRIVE  : 19313,
    CORELI_C_TYPE_EM_COATING  : 18775,
    CORELI_C_TYPE_EXPLOSIVE_COATING  : 18772,
    CORELI_C_TYPE_KINETIC_COATING  : 18770,
    CORELI_C_TYPE_MULTISPECTRUM_COATING  : 18768,
    CORELI_C_TYPE_SMALL_ARMOR_REPAIRER  : 19011,
    CORELI_C_TYPE_SMALL_REMOTE_ARMOR_REPAIRER  : 18981,
    CORELI_C_TYPE_THERMAL_COATING  : 18777,
    CORELUM_A_TYPE_10MN_AFTERBURNER  : 18694,
    CORELUM_A_TYPE_50MN_MICROWARPDRIVE  : 19327,
    CORELUM_A_TYPE_EM_ENERGIZED_MEMBRANE  : 18825,
    CORELUM_A_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18823,
    CORELUM_A_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18821,
    CORELUM_A_TYPE_MEDIUM_ARMOR_REPAIRER  : 19033,
    CORELUM_A_TYPE_MEDIUM_REMOTE_ARMOR_REPAIRER  : 18991,
    CORELUM_A_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18819,
    CORELUM_A_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18827,
    CORELUM_B_TYPE_10MN_AFTERBURNER  : 18688,
    CORELUM_B_TYPE_50MN_MICROWARPDRIVE  : 19321,
    CORELUM_B_TYPE_EM_ENERGIZED_MEMBRANE  : 18815,
    CORELUM_B_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18813,
    CORELUM_B_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18811,
    CORELUM_B_TYPE_MEDIUM_ARMOR_REPAIRER  : 19031,
    CORELUM_B_TYPE_MEDIUM_REMOTE_ARMOR_REPAIRER  : 18989,
    CORELUM_B_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18809,
    CORELUM_B_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18817,
    CORELUM_C_TYPE_10MN_AFTERBURNER  : 18682,
    CORELUM_C_TYPE_50MN_MICROWARPDRIVE  : 19315,
    CORELUM_C_TYPE_EM_ENERGIZED_MEMBRANE  : 18805,
    CORELUM_C_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18803,
    CORELUM_C_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18801,
    CORELUM_C_TYPE_MEDIUM_ARMOR_REPAIRER  : 19029,
    CORELUM_C_TYPE_MEDIUM_REMOTE_ARMOR_REPAIRER  : 18987,
    CORELUM_C_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18799,
    CORELUM_C_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18807,
    CORMACK_VAAJAS_TAG  : 17291,
    CORMACKS_MODIFIED_100MN_AFTERBURNER  : 14506,
    CORMACKS_MODIFIED_500MN_MICROWARPDRIVE  : 14514,
    CORMACKS_MODIFIED_CO_PROCESSOR  : 15314,
    CORMACKS_MODIFIED_DAMAGE_CONTROL  : 41210,
    CORMACKS_MODIFIED_ELECTRON_BLASTER_CANNON  : 14377,
    CORMACKS_MODIFIED_EM_ARMOR_HARDENER  : 15250,
    CORMACKS_MODIFIED_EM_COATING  : 15186,
    CORMACKS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 15226,
    CORMACKS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15274,
    CORMACKS_MODIFIED_EXPLOSIVE_COATING  : 15194,
    CORMACKS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 15234,
    CORMACKS_MODIFIED_ION_BLASTER_CANNON  : 14379,
    CORMACKS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15266,
    CORMACKS_MODIFIED_KINETIC_COATING  : 15202,
    CORMACKS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15242,
    CORMACKS_MODIFIED_LARGE_ARMOR_REPAIRER  : 15163,
    CORMACKS_MODIFIED_LARGE_PLASMA_SMARTBOMB  : 15158,
    CORMACKS_MODIFIED_MAGNETIC_FIELD_STABILIZER  : 15150,
    CORMACKS_MODIFIED_MULTISPECTRUM_COATING  : 15170,
    CORMACKS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 15210,
    CORMACKS_MODIFIED_NEUTRON_BLASTER_CANNON  : 14385,
    CORMACKS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15298,
    CORMACKS_MODIFIED_REACTOR_CONTROL_UNIT  : 15306,
    CORMACKS_MODIFIED_SENSOR_BOOSTER  : 15282,
    CORMACKS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15258,
    CORMACKS_MODIFIED_THERMAL_COATING  : 15178,
    CORMACKS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 15218,
    CORMACKS_MODIFIED_TRACKING_COMPUTER  : 15290,
    CORMORANT  : 16238,
    CORMORANT_ABYSSAL_FIRESTORM_SKIN  : 53136,
    CORMORANT_ACE_OF_PODHUNTERS_SKIN  : 63203,
    CORMORANT_AIR_LABORATORIES_SKIN  : 60264,
    CORMORANT_AURORA_UNIVERSALIS_SKIN  : 53372,
    CORMORANT_BIOSECURITY_RESPONDERS_SKIN  : 55541,
    CORMORANT_BLUE_TIGER_SKIN  : 36719,
    CORMORANT_BLUEPRINT  : 16239,
    CORMORANT_CALDARI_NAVY_SKIN  : 58877,
    CORMORANT_DEATHGLOW_HUNTERS_SKIN  : 60572,
    CORMORANT_EXOPLANETS_HUNTER_SKIN  : 45829,
    CORMORANT_GALNET_STREAMCAST_UNIT_SKIN  : 57732,
    CORMORANT_GHOSTBIRD_SKIN  : 48172,
    CORMORANT_GLACIAL_DRIFT_SKIN  : 44154,
    CORMORANT_GURISTAS_SKIN  : 40774,
    CORMORANT_GURISTAS_SKIN_30_DAYS  : 35074,
    CORMORANT_HARMONIOUS_ASCENSION_SKIN  : 53308,
    CORMORANT_IGC_SKIN  : 45013,
    CORMORANT_KIMOTORO_ESKEITAN_SKIN  : 52631,
    CORMORANT_MATIGU_SEABEAST_SKIN  : 40594,
    CORMORANT_METALBEE_CALDARI_SKIN_PERMANENT  : 53538,
    CORMORANT_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59217,
    CORMORANT_NAVY_ISSUE  : 73795,
    CORMORANT_NAVY_ISSUE_HEARTSURGE_SKIN  : 76291,
    CORMORANT_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47610,
    CORMORANT_OCTOPUS_RED_FLIGHT_SKIN  : 46727,
    CORMORANT_RAATA_SUNSET_SKIN  : 36647,
    CORMORANT_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60217,
    CORMORANT_STATE_POLICE_SKIN  : 72554,
    CORMORANT_STEEL_CARDINAL_SKIN  : 46106,
    CORMORANT_TRIGLAVIAN_TWILIGHT_SKIN  : 52402,
    CORMORANT_VITALSHIFT_EGO_SKIN  : 52553,
    CORMORANT_ZAKURA_SHUMYU_SKIN  : 57053,
    CORMORANT_ZENTO_ISIDEKO_COMBINE_SKIN  : 53166,
    CORPII_A_TYPE_EM_COATING  : 18760,
    CORPII_A_TYPE_EXPLOSIVE_COATING  : 18756,
    CORPII_A_TYPE_KINETIC_COATING  : 18752,
    CORPII_A_TYPE_MULTISPECTRUM_COATING  : 18708,
    CORPII_A_TYPE_SMALL_ARMOR_REPAIRER  : 19003,
    CORPII_A_TYPE_SMALL_ENERGY_NEUTRALIZER  : 37624,
    CORPII_A_TYPE_SMALL_ENERGY_NOSFERATU  : 19105,
    CORPII_A_TYPE_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 19069,
    CORPII_A_TYPE_THERMAL_COATING  : 18764,
    CORPII_B_TYPE_EM_COATING  : 18748,
    CORPII_B_TYPE_EXPLOSIVE_COATING  : 18744,
    CORPII_B_TYPE_KINETIC_COATING  : 18740,
    CORPII_B_TYPE_MULTISPECTRUM_COATING  : 18704,
    CORPII_B_TYPE_SMALL_ARMOR_REPAIRER  : 19001,
    CORPII_B_TYPE_SMALL_ENERGY_NEUTRALIZER  : 37623,
    CORPII_B_TYPE_SMALL_ENERGY_NOSFERATU  : 19103,
    CORPII_B_TYPE_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 19067,
    CORPII_B_TYPE_THERMAL_COATING  : 18728,
    CORPII_C_TYPE_EM_COATING  : 18720,
    CORPII_C_TYPE_EXPLOSIVE_COATING  : 18716,
    CORPII_C_TYPE_KINETIC_COATING  : 18712,
    CORPII_C_TYPE_MULTISPECTRUM_COATING  : 18700,
    CORPII_C_TYPE_SMALL_ARMOR_REPAIRER  : 18999,
    CORPII_C_TYPE_SMALL_ENERGY_NEUTRALIZER  : 37622,
    CORPII_C_TYPE_SMALL_ENERGY_NOSFERATU  : 19101,
    CORPII_C_TYPE_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 19065,
    CORPII_C_TYPE_THERMAL_COATING  : 18724,
    CORPORATE_HANGAR_ARRAY  : 17621,
    CORPORATE_HANGAR_ARRAY_BLUEPRINT  : 2787,
    CORPORATE_LIGHT_ELECTRON_BLASTER_I  : 22899,
    CORPORATION_CONTRACTING  : 25233,
    CORPORATION_MANAGEMENT  : 3363,
    CORPUM_A_TYPE_EM_ENERGIZED_MEMBRANE  : 18869,
    CORPUM_A_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18873,
    CORPUM_A_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18877,
    CORPUM_A_TYPE_MEDIUM_ARMOR_REPAIRER  : 19021,
    CORPUM_A_TYPE_MEDIUM_ENERGY_NEUTRALIZER  : 37627,
    CORPUM_A_TYPE_MEDIUM_ENERGY_NOSFERATU  : 19111,
    CORPUM_A_TYPE_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 19081,
    CORPUM_A_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18881,
    CORPUM_A_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18865,
    CORPUM_B_TYPE_EM_ENERGIZED_MEMBRANE  : 19361,
    CORPUM_B_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18857,
    CORPUM_B_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18853,
    CORPUM_B_TYPE_MEDIUM_ARMOR_REPAIRER  : 19019,
    CORPUM_B_TYPE_MEDIUM_ENERGY_NEUTRALIZER  : 37626,
    CORPUM_B_TYPE_MEDIUM_ENERGY_NOSFERATU  : 19109,
    CORPUM_B_TYPE_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 19079,
    CORPUM_B_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18849,
    CORPUM_B_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18861,
    CORPUM_C_TYPE_EM_ENERGIZED_MEMBRANE  : 18841,
    CORPUM_C_TYPE_EXPLOSIVE_ENERGIZED_MEMBRANE  : 18837,
    CORPUM_C_TYPE_KINETIC_ENERGIZED_MEMBRANE  : 18833,
    CORPUM_C_TYPE_MEDIUM_ARMOR_REPAIRER  : 19017,
    CORPUM_C_TYPE_MEDIUM_ENERGY_NEUTRALIZER  : 37625,
    CORPUM_C_TYPE_MEDIUM_ENERGY_NOSFERATU  : 19107,
    CORPUM_C_TYPE_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 19077,
    CORPUM_C_TYPE_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 18829,
    CORPUM_C_TYPE_THERMAL_ENERGIZED_MEMBRANE  : 18845,
    CORPUS_A_TYPE_EM_ARMOR_HARDENER  : 18929,
    CORPUS_A_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18925,
    CORPUS_A_TYPE_HEAVY_ENERGY_NEUTRALIZER  : 37630,
    CORPUS_A_TYPE_HEAVY_ENERGY_NOSFERATU  : 19117,
    CORPUS_A_TYPE_KINETIC_ARMOR_HARDENER  : 18921,
    CORPUS_A_TYPE_LARGE_ARMOR_REPAIRER  : 19043,
    CORPUS_A_TYPE_THERMAL_ARMOR_HARDENER  : 18917,
    CORPUS_B_TYPE_EM_ARMOR_HARDENER  : 18901,
    CORPUS_B_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18905,
    CORPUS_B_TYPE_HEAVY_ENERGY_NEUTRALIZER  : 37629,
    CORPUS_B_TYPE_HEAVY_ENERGY_NOSFERATU  : 19115,
    CORPUS_B_TYPE_KINETIC_ARMOR_HARDENER  : 18909,
    CORPUS_B_TYPE_LARGE_ARMOR_REPAIRER  : 19041,
    CORPUS_B_TYPE_THERMAL_ARMOR_HARDENER  : 18913,
    CORPUS_C_TYPE_EM_ARMOR_HARDENER  : 18885,
    CORPUS_C_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18889,
    CORPUS_C_TYPE_HEAVY_ENERGY_NEUTRALIZER  : 37628,
    CORPUS_C_TYPE_HEAVY_ENERGY_NOSFERATU  : 19113,
    CORPUS_C_TYPE_KINETIC_ARMOR_HARDENER  : 18893,
    CORPUS_C_TYPE_LARGE_ARMOR_REPAIRER  : 19039,
    CORPUS_C_TYPE_THERMAL_ARMOR_HARDENER  : 18897,
    CORPUS_X_TYPE_EM_ARMOR_HARDENER  : 18933,
    CORPUS_X_TYPE_EXPLOSIVE_ARMOR_HARDENER  : 18937,
    CORPUS_X_TYPE_HEAVY_ENERGY_NEUTRALIZER  : 37631,
    CORPUS_X_TYPE_HEAVY_ENERGY_NOSFERATU  : 19119,
    CORPUS_X_TYPE_KINETIC_ARMOR_HARDENER  : 18941,
    CORPUS_X_TYPE_LARGE_ARMOR_REPAIRER  : 19045,
    CORPUS_X_TYPE_THERMAL_ARMOR_HARDENER  : 18945,
    CORRUPTED_NEUROSYNAPTIC_PROFILE  : 56709,
    COUNCIL_DIPLOMATIC_SHUTTLE  : 34496,
    COUNCIL_DIPLOMATIC_SHUTTLE_SARO_BLACK_TROOP_SKIN  : 54993,
    COUNTER_SUBVERSION_SENSOR_ARRAY  : 57442,
    COUNTERBALANCED_COMPACT_GYROSTABILIZER  : 5933,
    COUNTERFEIT_DOMINIX_MODEL  : 37887,
    COUPLING_ARRAY  : 17982,
    COVERT_CYNOSURAL_FIELD_GENERATOR_I  : 28646,
    COVERT_CYNOSURAL_FIELD_GENERATOR_I_BLUEPRINT  : 28647,
    COVERT_JUMP_PORTAL_GENERATOR_I  : 28652,
    COVERT_JUMP_PORTAL_GENERATOR_I_BLUEPRINT  : 28653,
    COVERT_MOBILE_CYNOSURAL_BEACON  : 59630,
    COVERT_MOBILE_CYNOSURAL_BEACON_BLUEPRINT  : 59632,
    COVERT_OPS  : 12093,
    COVERT_OPS_CLOAKING_DEVICE_II  : 11578,
    COVERT_RESEARCH_TOOLS  : 33577,
    COVETOR  : 17476,
    COVETOR_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43754,
    COVETOR_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43807,
    COVETOR_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43820,
    COVETOR_BLUEPRINT  : 17477,
    COVETOR_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43767,
    COVETOR_DIGMASTER_BLAZON_SKIN  : 42646,
    COVETOR_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43781,
    COVETOR_GLACIAL_DRIFT_SKIN  : 46986,
    COVETOR_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43833,
    COVETOR_LODESTRIKE_SKIN  : 46737,
    COVETOR_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43794,
    COVETOR_MORPHITE_SHINE_SKIN  : 42619,
    COVETOR_PAYDIRT_PROSPECTOR_SKIN  : 42676,
    COVETOR_RADIOACTIVES_RECLAMATION_SKIN  : 60949,
    COVETOR_ROCKBREAKER_PIONEERS_SKIN  : 56610,
    COVETOR_ROSADA_DAWN_SKIN  : 43668,
    COVETOR_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43846,
    CPD_10N_NULLSEC_ICE_STORM_FILAMENT  : 74434,
    CPU_MANAGEMENT  : 3426,
    CRANE  : 12729,
    CRANE_BIOSECURITY_RESPONDERS_SKIN  : 55561,
    CRANE_BLUE_TIGER_SKIN  : 36747,
    CRANE_BLUEPRINT  : 12730,
    CRANE_EXOPLANETS_HUNTER_SKIN  : 45865,
    CRANE_GHOSTBIRD_SKIN  : 48208,
    CRANE_GLACIAL_DRIFT_SKIN  : 46869,
    CRANE_KIMOTORO_ESKEITAN_SKIN  : 52639,
    CRANE_LODESTRIKE_SKIN  : 46754,
    CRANE_MATIGU_SEABEAST_SKIN  : 40623,
    CRANE_RAATA_SUNSET_SKIN  : 36675,
    CRANE_STARLINE_MAJESTIC_SKIN  : 55724,
    CRANE_STEEL_CARDINAL_SKIN  : 46142,
    CRANE_UNION_DAY_EXECUTIVE_SKIN  : 71732,
    CRANE_VERSUS_BLUEFORCE_SKIN  : 63864,
    CRANE_WIYRKOMI_SKIN  : 36348,
    CRANE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53202,
    CRASH  : 3705,
    CREODRON_BUMBLEBEE_DRONE_TUNER_T10_5D  : 48149,
    CREODRON_YELLOWJACKET_DRONE_TUNER_D5_10T  : 48148,
    CRIMINAL_CONNECTIONS  : 3361,
    CRIMINAL_DNA  : 12250,
    CRIMINAL_DOG_TAG  : 11069,
    CRIMSON_ARKONOR  : 17425,
    CRIMSON_HELIX_FIREWORK_LARGE  : 47301,
    CRIMSON_SCYTHES_FIREWORK  : 44267,
    CROKITE  : 1225,
    CROKITE_PROCESSING  : 12182,
    CROP_GAS_CLOUD_SCOOP  : 25540,
    CROSSBOW_FOCUSED_MEDIUM_BEAM_LASER_I  : 23840,
    CROSSLINK_COMPACT_BALLISTIC_CONTROL_SYSTEM  : 16457,
    CROW  : 11176,
    CROW_BIOSECURITY_RESPONDERS_SKIN  : 55562,
    CROW_BLUE_TIGER_SKIN  : 36716,
    CROW_BLUEPRINT  : 11177,
    CROW_EXOPLANETS_HUNTER_SKIN  : 45823,
    CROW_GHOSTBIRD_SKIN  : 48166,
    CROW_GLACIAL_DRIFT_SKIN  : 46833,
    CROW_HUNTERS_QUIVER_SKIN  : 57535,
    CROW_LUMINAIRE_RISING_SKIN  : 64407,
    CROW_LUMINAIRE_ZENITH_SKIN  : 64396,
    CROW_MATIGU_SEABEAST_SKIN  : 40590,
    CROW_RAATA_SUNSET_SKIN  : 36644,
    CROW_STATE_POLICE_SKIN  : 60027,
    CROW_STEEL_CARDINAL_SKIN  : 46100,
    CROW_WIYRKOMI_SKIN  : 36335,
    CROW_ZENTO_ISIDEKO_COMBINE_SKIN  : 53160,
    CROWN_IMPERIAL_FIREWORK  : 44260,
    CRUCIBLE_SMALL_CAP_BATTERY  : 23801,
    CRUCIFIER  : 2161,
    CRUCIFIER_ARDISHAPUR_SKIN  : 60672,
    CRUCIFIER_BIOSECURITY_RESPONDERS_SKIN  : 55318,
    CRUCIFIER_BLUEPRINT  : 2162,
    CRUCIFIER_COLD_IRON_SKIN  : 42746,
    CRUCIFIER_EXOPLANETS_HUNTER_SKIN  : 45753,
    CRUCIFIER_GLACIAL_DRIFT_SKIN  : 44134,
    CRUCIFIER_IRONBLOOD_SKIN  : 43482,
    CRUCIFIER_KADOR_SKIN  : 34719,
    CRUCIFIER_NAVY_ISSUE  : 37453,
    CRUCIFIER_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55338,
    CRUCIFIER_NAVY_ISSUE_COLD_IRON_SKIN  : 42761,
    CRUCIFIER_NAVY_ISSUE_EOM_SKIN  : 40330,
    CRUCIFIER_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45768,
    CRUCIFIER_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46783,
    CRUCIFIER_NAVY_ISSUE_OASIS_OF_FAITH_SKIN  : 53610,
    CRUCIFIER_NAVY_ISSUE_PURITY_OF_THE_THRONE_SKIN  : 42559,
    CRUCIFIER_NAVY_ISSUE_SARUM_SKIN  : 56793,
    CRUCIFIER_NAVY_ISSUE_SPIRIT_SKIN  : 57384,
    CRUCIFIER_OASIS_OF_FAITH_SKIN  : 53595,
    CRUCIFIER_PURITY_OF_THE_THRONE_SKIN  : 42544,
    CRUCIFIER_RAATA_SUNSET_SKIN  : 37484,
    CRUCIFIER_SARUM_SKIN  : 34720,
    CRUCIFIER_SPIRIT_SKIN  : 47304,
    CRUCIFIER_WAR_RESERVES_LIEUTENANT_SKIN  : 59421,
    CRUISE_MISSILE_BATTERY  : 16696,
    CRUISE_MISSILE_BATTERY_BLUEPRINT  : 2823,
    CRUISE_MISSILE_LAUNCHER_I  : 13320,
    CRUISE_MISSILE_LAUNCHER_I_BLUEPRINT  : 13321,
    CRUISE_MISSILE_LAUNCHER_II  : 19739,
    CRUISE_MISSILE_SPECIALIZATION  : 20212,
    CRUISE_MISSILES  : 3326,
    CRUNCH_FUEL  : 48755,
    CRUOR  : 17926,
    CRUOR_BLOOD_RAIDER_VICTORY_SKIN  : 43533,
    CRUOR_GLACIAL_DRIFT_SKIN  : 46819,
    CRUOR_IRONBLOOD_SKIN  : 60665,
    CRUOR_LUMINAE_POLARIS_SKIN  : 75273,
    CRUOR_MALAGRIN_MARSHLIGHT_SKIN  : 53019,
    CRUOR_VAMPIRES_HEX_SKIN  : 57816,
    CRUSADER  : 11184,
    CRUSADER_AURORA_UNIVERSALIS_SKIN  : 57005,
    CRUSADER_BIOSECURITY_RESPONDERS_SKIN  : 55351,
    CRUSADER_BLUEPRINT  : 11185,
    CRUSADER_COLD_IRON_SKIN  : 42757,
    CRUSADER_EOM_SKIN  : 36287,
    CRUSADER_EXOPLANETS_HUNTER_SKIN  : 45764,
    CRUSADER_GLACIAL_DRIFT_SKIN  : 46779,
    CRUSADER_IMPERIAL_NAVY_SKIN  : 58896,
    CRUSADER_OASIS_OF_FAITH_SKIN  : 53606,
    CRUSADER_PURITY_OF_THE_THRONE_SKIN  : 42555,
    CRUSADER_RAATA_SUNSET_SKIN  : 37495,
    CRUSADER_STAR_CAPTAIN_SKIN  : 52569,
    CRYOPROTECTANT_SOLUTION  : 2367,
    CRYSTAL_COMPOUND  : 11741,
    CRYSTAL_EGG  : 3824,
    CRYSTALLINE_CARBONIDE  : 16670,
    CRYSTALLINE_CARBONIDE_ARMOR_PLATE  : 11545,
    CRYSTALLINE_CARBONIDE_ARMOR_PLATE_BLUEPRINT  : 17323,
    CRYSTALLINE_CARBONIDE_REACTION_FORMULA  : 46205,
    CRYSTALLINE_CROKITE  : 17433,
    CRYSTALLINE_ISOGEN_10  : 47975,
    CRYSTALLITE_ALLOY  : 16655,
    CRYSTALLITE_ALLOY_REACTION_FORMULA  : 46169,
    CSM_1_ELECTEE_ARCHIVE_SCRIPT  : 34769,
    CSM_10_ELECTEE_ARCHIVE_SCRIPT  : 34778,
    CSM_11_ELECTEE_ARCHIVE_SCRIPT  : 40656,
    CSM_2_ELECTEE_ARCHIVE_SCRIPT  : 34770,
    CSM_3_ELECTEE_ARCHIVE_SCRIPT  : 34771,
    CSM_4_ELECTEE_ARCHIVE_SCRIPT  : 34772,
    CSM_5_ELECTEE_ARCHIVE_SCRIPT  : 34773,
    CSM_6_ELECTEE_ARCHIVE_SCRIPT  : 34774,
    CSM_7_ELECTEE_ARCHIVE_SCRIPT  : 34775,
    CSM_8_ELECTEE_ARCHIVE_SCRIPT  : 34776,
    CSM_9_ELECTEE_ARCHIVE_SCRIPT  : 34777,
    CUBIC_BISTOT  : 46676,
    CULTURAL_SEMIOSIS_DATA  : 56715,
    CURATOR_I  : 23525,
    CURATOR_I_BLUEPRINT  : 23526,
    CURATOR_II  : 28213,
    CURRENT_AMPLIFIER  : 23163,
    CURRENT_PUMP  : 25611,
    CURSE  : 20125,
    CURSE_BIOSECURITY_RESPONDERS_SKIN  : 55347,
    CURSE_BLUEPRINT  : 20126,
    CURSE_COLD_IRON_SKIN  : 42774,
    CURSE_CROWN_AND_SWORDS_SKIN  : 52771,
    CURSE_EOM_SKIN  : 36309,
    CURSE_EXOPLANETS_HUNTER_SKIN  : 45782,
    CURSE_GLACIAL_DRIFT_SKIN  : 46791,
    CURSE_IMPERIAL_JUBILEE_SKIN  : 48848,
    CURSE_IRONBLOOD_SKIN  : 43508,
    CURSE_OASIS_OF_FAITH_SKIN  : 53624,
    CURSE_ORDER_OF_TETRIMON_SKIN  : 73464,
    CURSE_PURITY_OF_THE_THRONE_SKIN  : 42573,
    CURSE_RAATA_SUNSET_SKIN  : 37508,
    CURSE_STAR_CAPTAIN_SKIN  : 52578,
    CUSTOMS_CODE_EXPERTISE  : 33467,
    CUSTOMS_OFFICE_GANTRY  : 3962,
    CV_COMPOSITE_MOLECULAR_CONDENSER  : 57447,
    CYBERNETIC_SOURCE_SUBPROCESSOR  : 33807,
    CYBERNETIC_SUBPROCESSOR___BASIC  : 9943,
    CYBERNETIC_SUBPROCESSOR___IMPROVED  : 10222,
    CYBERNETIC_SUBPROCESSOR___STANDARD  : 10221,
    CYBERNETICS  : 3411,
    CYCLONE  : 16231,
    CYCLONE_BIOSECURITY_RESPONDERS_SKIN  : 55660,
    CYCLONE_BLUE_TIGER_SKIN  : 36824,
    CYCLONE_BLUEPRINT  : 16232,
    CYCLONE_CAPSULEER_DAY_XVII_SKIN  : 54799,
    CYCLONE_CHAINBREAKER_KHUMAAK_SKIN  : 77958,
    CYCLONE_EXOPLANETS_HUNTER_SKIN  : 45967,
    CYCLONE_FLEET_ISSUE  : 72811,
    CYCLONE_FLEET_ISSUE_CHAINBREAKER_KHUMAAK_SKIN  : 77957,
    CYCLONE_FLEET_ISSUE_SNOWLINE_BLADERACER_SKIN  : 73869,
    CYCLONE_FLEET_ISSUE_VERSUS_REDFORCE_SKIN  : 73880,
    CYCLONE_FLEET_ORDNANCE_SPIKE_SKIN  : 59432,
    CYCLONE_GLACIAL_DRIFT_SKIN  : 46904,
    CYCLONE_HAZARD_CONTROL_SKIN  : 56924,
    CYCLONE_HUNTERS_QUIVER_SKIN  : 57529,
    CYCLONE_KRUSUAL_SKIN  : 34618,
    CYCLONE_LIBERATION_GAMES_SKIN  : 59467,
    CYCLONE_LUMINAIRE_ZENITH_SKIN  : 48233,
    CYCLONE_REPUBLIC_FLEET_SKIN  : 59814,
    CYCLONE_SNOWLINE_BLADERACER_SKIN  : 52878,
    CYCLONE_THUKKER_TRIBE_SKIN_30_DAYS  : 35090,
    CYCLONE_TRONHADAR_INK_SKIN  : 40495,
    CYCLONE_VALKLEAR_GLORY_SKIN  : 36777,
    CYCLONE_WARP_CONVERGENCE_SKIN  : 61878,
    CYCLOPS_I  : 32325,
    CYCLOPS_I_BLUEPRINT  : 32326,
    CYCLOPS_II  : 40563,
    CYNABAL  : 17720,
    CYNABAL_ANGELS_HEX_SKIN  : 49147,
    CYNABAL_ASPIRANT_SOLDIER_SKIN  : 60243,
    CYNABAL_AURORA_UNIVERSALIS_SKIN  : 60922,
    CYNABAL_COPPER_LIGHTNING_SKIN  : 60760,
    CYNABAL_EROS_BLOSSOM_SKIN  : 47294,
    CYNABAL_GLACIAL_DRIFT_SKIN  : 46998,
    CYNABAL_HEARTSURGE_SKIN  : 53950,
    CYNABAL_MALAGRIN_MARSHLIGHT_SKIN  : 56602,
    CYNABAL_SERENE_TU_SKIN  : 49154,
    CYNABAL_SERPENTIS_SKIN  : 57333,
    CYNABAL_SPIRIT_SKIN  : 44950,
    CYNABAL_VERSUS_BLUEFORCE_SKIN  : 62646,
    CYNABAL_VERSUS_REDFORCE_SKIN  : 62640,
    CYNOSURAL_FIELD_GENERATOR_I  : 21096,
    CYNOSURAL_FIELD_GENERATOR_I_BLUEPRINT  : 21604,
    CYNOSURAL_FIELD_THEORY  : 21603,
    CYNOSURAL_GENERATOR_ARRAY  : 27673,
    CYNOSURAL_NAVIGATION  : 2008,
    CYNOSURAL_NAVIGATION_BLUEPRINT  : 34866,
    CYNOSURAL_SUPPRESSION  : 2001,
    CYNOSURAL_SUPPRESSION_BLUEPRINT  : 34867,
    CYNOSURAL_SYSTEM_JAMMER  : 27674,
    CZ_4_COMPACT_GRAVIMETRIC_ECM  : 19944,
    DAGON  : 42242,
    DAGON_GLACIAL_DRIFT_SKIN  : 46823,
    DAILY_ALPHA_INJECTOR  : 46375,
    DAIRY_PRODUCTS  : 3717,
    DAMAGE_CONTROL_I  : 2046,
    DAMAGE_CONTROL_I_BLUEPRINT  : 2047,
    DAMAGE_CONTROL_II  : 2048,
    DAMAGED_ANCIENT_PEG  : 47053,
    DAMAGED_ARTIFICIAL_NEURAL_NETWORK  : 25597,
    DAMAVIK  : 47269,
    DAMAVIK_BLACKFIRE_STEEL_SKIN  : 49922,
    DAMAVIK_DEADSPACE_DREAMTIME_SKIN  : 52417,
    DAMAVIK_HADEAN_HORROR_SKIN  : 48653,
    DAMAVIK_METAMATERIA_EXOTICA_SKIN  : 50139,
    DAMAVIK_RED_FORESTS_THUNDERBIRD_SKIN  : 52442,
    DAMAVIK_SINGULARITY_STORM_SKIN  : 48649,
    DAMAVIK_TRIGLAVIAN_VICTORY_SKIN  : 48563,
    DAMNATION  : 22474,
    DAMNATION_BIOSECURITY_RESPONDERS_SKIN  : 55341,
    DAMNATION_BLUEPRINT  : 22475,
    DAMNATION_COLD_IRON_SKIN  : 42782,
    DAMNATION_CROWN_AND_SWORDS_SKIN  : 52774,
    DAMNATION_EOM_SKIN  : 36314,
    DAMNATION_EXOPLANETS_HUNTER_SKIN  : 45791,
    DAMNATION_GLACIAL_DRIFT_SKIN  : 46800,
    DAMNATION_IMPERIAL_JUBILEE_SKIN  : 48853,
    DAMNATION_MALAGRIN_MARSHLIGHT_SKIN  : 53007,
    DAMNATION_OASIS_OF_FAITH_SKIN  : 53633,
    DAMNATION_PURITY_OF_THE_THRONE_SKIN  : 42582,
    DAMNATION_RAATA_SUNSET_SKIN  : 37514,
    DAMNATION_STAR_CAPTAIN_SKIN  : 48244,
    DAREDEVIL  : 17928,
    DAREDEVIL_ANGELS_HEX_SKIN  : 49148,
    DAREDEVIL_GLACIAL_DRIFT_SKIN  : 46996,
    DAREDEVIL_HEARTSURGE_SKIN  : 53952,
    DAREDEVIL_IGC_SKIN  : 46051,
    DAREDEVIL_SERPENTIS_VICTORY_SKIN  : 46584,
    DAREDEVIL_SPIRIT_SKIN  : 57310,
    DAREDEVIL_VERSUS_BLUEFORCE_SKIN  : 62645,
    DAREDEVIL_VERSUS_REDFORCE_SKIN  : 62639,
    DARK_BLOOD_25000MM_STEEL_PLATES  : 41457,
    DARK_BLOOD_BRASS_TAG  : 17231,
    DARK_BLOOD_BRONZE_TAG  : 17232,
    DARK_BLOOD_CAP_RECHARGER  : 14142,
    DARK_BLOOD_CAPACITOR_POWER_RELAY  : 14144,
    DARK_BLOOD_CAPITAL_ARMOR_REPAIRER  : 41501,
    DARK_BLOOD_CAPITAL_CAP_BATTERY  : 41488,
    DARK_BLOOD_CAPITAL_CAPACITOR_BOOSTER  : 41494,
    DARK_BLOOD_CAPITAL_ENERGY_NEUTRALIZER  : 40664,
    DARK_BLOOD_CAPITAL_ENERGY_NOSFERATU  : 40670,
    DARK_BLOOD_CAPITAL_FLEX_ARMOR_HARDENER  : 41526,
    DARK_BLOOD_CAPITAL_REMOTE_ARMOR_REPAIRER  : 41467,
    DARK_BLOOD_CONTROL_TOWER  : 27532,
    DARK_BLOOD_CONTROL_TOWER_MEDIUM  : 27591,
    DARK_BLOOD_CONTROL_TOWER_SMALL  : 27594,
    DARK_BLOOD_COPPER_TAG  : 17233,
    DARK_BLOOD_CRYSTAL_TAG  : 17234,
    DARK_BLOOD_DIAMOND_TAG  : 17235,
    DARK_BLOOD_DUAL_GIGA_BEAM_LASER  : 41118,
    DARK_BLOOD_DUAL_GIGA_PULSE_LASER  : 41103,
    DARK_BLOOD_DUAL_HEAVY_BEAM_LASER  : 13793,
    DARK_BLOOD_DUAL_HEAVY_PULSE_LASER  : 13791,
    DARK_BLOOD_DUAL_LIGHT_BEAM_LASER  : 13795,
    DARK_BLOOD_DUAL_LIGHT_PULSE_LASER  : 13797,
    DARK_BLOOD_ELECTRUM_TAG  : 17236,
    DARK_BLOOD_EM_ARMOR_HARDENER  : 13972,
    DARK_BLOOD_EM_COATING  : 14021,
    DARK_BLOOD_EM_ENERGIZED_MEMBRANE  : 14088,
    DARK_BLOOD_ENERGY_NEUTRALIZING_BATTERY  : 27858,
    DARK_BLOOD_EXPLOSIVE_ARMOR_HARDENER  : 13976,
    DARK_BLOOD_EXPLOSIVE_COATING  : 14015,
    DARK_BLOOD_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14082,
    DARK_BLOOD_FOCUSED_MEDIUM_BEAM_LASER  : 13799,
    DARK_BLOOD_FOCUSED_MEDIUM_PULSE_LASER  : 13801,
    DARK_BLOOD_GAMMA_L  : 21300,
    DARK_BLOOD_GAMMA_M  : 21284,
    DARK_BLOOD_GAMMA_S  : 21268,
    DARK_BLOOD_GAMMA_XL  : 21316,
    DARK_BLOOD_GATLING_PULSE_LASER  : 13803,
    DARK_BLOOD_GOLD_TAG  : 17238,
    DARK_BLOOD_HEAT_SINK  : 13941,
    DARK_BLOOD_HEAVY_BEAM_LASER  : 13805,
    DARK_BLOOD_HEAVY_CAPACITOR_BOOSTER  : 14172,
    DARK_BLOOD_HEAVY_ENERGY_NEUTRALIZER  : 14168,
    DARK_BLOOD_HEAVY_ENERGY_NOSFERATU  : 14152,
    DARK_BLOOD_HEAVY_PULSE_LASER  : 13807,
    DARK_BLOOD_INFRARED_L  : 21292,
    DARK_BLOOD_INFRARED_M  : 21276,
    DARK_BLOOD_INFRARED_S  : 21260,
    DARK_BLOOD_INFRARED_XL  : 21308,
    DARK_BLOOD_KINETIC_ARMOR_HARDENER  : 13980,
    DARK_BLOOD_KINETIC_COATING  : 14009,
    DARK_BLOOD_KINETIC_ENERGIZED_MEMBRANE  : 14076,
    DARK_BLOOD_LARGE_ARMOR_REPAIRER  : 13957,
    DARK_BLOOD_LARGE_BEAM_LASER_BATTERY  : 27553,
    DARK_BLOOD_LARGE_EMP_SMARTBOMB  : 14188,
    DARK_BLOOD_LARGE_PULSE_LASER_BATTERY  : 27550,
    DARK_BLOOD_MEDIUM_ARMOR_REPAIRER  : 13960,
    DARK_BLOOD_MEDIUM_BEAM_LASER_BATTERY  : 27627,
    DARK_BLOOD_MEDIUM_CAPACITOR_BOOSTER  : 14176,
    DARK_BLOOD_MEDIUM_EMP_SMARTBOMB  : 14192,
    DARK_BLOOD_MEDIUM_ENERGY_NEUTRALIZER  : 14164,
    DARK_BLOOD_MEDIUM_ENERGY_NOSFERATU  : 14156,
    DARK_BLOOD_MEDIUM_PULSE_LASER_BATTERY  : 27630,
    DARK_BLOOD_MEGA_BEAM_LASER  : 13813,
    DARK_BLOOD_MEGA_PULSE_LASER  : 13815,
    DARK_BLOOD_MICRO_EMP_SMARTBOMB  : 14196,
    DARK_BLOOD_MICROWAVE_L  : 21290,
    DARK_BLOOD_MICROWAVE_M  : 21274,
    DARK_BLOOD_MICROWAVE_S  : 21258,
    DARK_BLOOD_MICROWAVE_XL  : 21306,
    DARK_BLOOD_MULTIFREQUENCY_L  : 21302,
    DARK_BLOOD_MULTIFREQUENCY_M  : 21286,
    DARK_BLOOD_MULTIFREQUENCY_S  : 21270,
    DARK_BLOOD_MULTIFREQUENCY_XL  : 21318,
    DARK_BLOOD_MULTISPECTRUM_COATING  : 14003,
    DARK_BLOOD_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14070,
    DARK_BLOOD_PALLADIUM_TAG  : 17237,
    DARK_BLOOD_PLATINUM_TAG  : 17240,
    DARK_BLOOD_POWER_DIAGNOSTIC_SYSTEM  : 14134,
    DARK_BLOOD_QUAD_BEAM_LASER  : 13819,
    DARK_BLOOD_QUAD_MEGA_PULSE_LASER  : 41066,
    DARK_BLOOD_RADIO_L  : 21288,
    DARK_BLOOD_RADIO_M  : 21272,
    DARK_BLOOD_RADIO_S  : 21256,
    DARK_BLOOD_RADIO_XL  : 21304,
    DARK_BLOOD_REACTOR_CONTROL_UNIT  : 14128,
    DARK_BLOOD_SILVER_TAG  : 17239,
    DARK_BLOOD_SMALL_ARMOR_REPAIRER  : 13964,
    DARK_BLOOD_SMALL_BEAM_LASER_BATTERY  : 27633,
    DARK_BLOOD_SMALL_CAPACITOR_BOOSTER  : 14184,
    DARK_BLOOD_SMALL_EMP_SMARTBOMB  : 14200,
    DARK_BLOOD_SMALL_ENERGY_NEUTRALIZER  : 14160,
    DARK_BLOOD_SMALL_ENERGY_NOSFERATU  : 14148,
    DARK_BLOOD_SMALL_FOCUSED_BEAM_LASER  : 13809,
    DARK_BLOOD_SMALL_FOCUSED_PULSE_LASER  : 13811,
    DARK_BLOOD_SMALL_PULSE_LASER_BATTERY  : 27636,
    DARK_BLOOD_STANDARD_L  : 21294,
    DARK_BLOOD_STANDARD_M  : 21278,
    DARK_BLOOD_STANDARD_S  : 21262,
    DARK_BLOOD_STANDARD_XL  : 21310,
    DARK_BLOOD_STANDUP_HEAVY_ENERGY_NEUTRALIZER  : 46575,
    DARK_BLOOD_STANDUP_HEAVY_ENERGY_NEUTRALIZER_BLUEPRINT  : 46576,
    DARK_BLOOD_STASIS_WEBIFIER  : 14262,
    DARK_BLOOD_TACHYON_BEAM_LASER  : 13817,
    DARK_BLOOD_THERMAL_ARMOR_HARDENER  : 13984,
    DARK_BLOOD_THERMAL_COATING  : 14027,
    DARK_BLOOD_THERMAL_ENERGIZED_MEMBRANE  : 14094,
    DARK_BLOOD_ULTRAVIOLET_L  : 21296,
    DARK_BLOOD_ULTRAVIOLET_M  : 21280,
    DARK_BLOOD_ULTRAVIOLET_S  : 21264,
    DARK_BLOOD_ULTRAVIOLET_XL  : 21312,
    DARK_BLOOD_WARP_DISRUPTOR  : 14242,
    DARK_BLOOD_WARP_SCRAMBLER  : 14252,
    DARK_BLOOD_XRAY_L  : 21298,
    DARK_BLOOD_XRAY_M  : 21282,
    DARK_BLOOD_XRAY_S  : 21266,
    DARK_BLOOD_XRAY_XL  : 21314,
    DARK_COMPOUND  : 11735,
    DARK_GLITTER  : 16267,
    DARK_OCHRE  : 1232,
    DARK_OCHRE_PROCESSING  : 12183,
    DATA_ANALYZER_I  : 22175,
    DATA_ANALYZER_I_BLUEPRINT  : 22176,
    DATA_ANALYZER_II  : 30834,
    DATA_CHIPS  : 17392,
    DATA_PROCESSOR  : 21596,
    DATA_SHEETS  : 3812,
    DATA_SNIFFER_DISPLAY  : 56981,
    DATACORE___AMARRIAN_STARSHIP_ENGINEERING  : 20421,
    DATACORE___CALDARI_STARSHIP_ENGINEERING  : 25887,
    DATACORE___CORE_SUBSYSTEMS_ENGINEERING  : 20115,
    DATACORE___DEFENSIVE_SUBSYSTEMS_ENGINEERING  : 11496,
    DATACORE___ELECTROMAGNETIC_PHYSICS  : 20417,
    DATACORE___ELECTRONIC_ENGINEERING  : 20418,
    DATACORE___GALLENTEAN_STARSHIP_ENGINEERING  : 20410,
    DATACORE___GRAVITON_PHYSICS  : 20419,
    DATACORE___HIGH_ENERGY_PHYSICS  : 20411,
    DATACORE___HYDROMAGNETIC_PHYSICS  : 20171,
    DATACORE___LASER_PHYSICS  : 20413,
    DATACORE___MECHANICAL_ENGINEERING  : 20424,
    DATACORE___MINMATAR_STARSHIP_ENGINEERING  : 20172,
    DATACORE___MOLECULAR_ENGINEERING  : 20415,
    DATACORE___NANITE_ENGINEERING  : 20416,
    DATACORE___NUCLEAR_PHYSICS  : 20423,
    DATACORE___OFFENSIVE_SUBSYSTEMS_ENGINEERING  : 20425,
    DATACORE___PLASMA_PHYSICS  : 20412,
    DATACORE___PROPULSION_SUBSYSTEMS_ENGINEERING  : 20114,
    DATACORE___QUANTUM_PHYSICS  : 20414,
    DATACORE___ROCKET_SCIENCE  : 20420,
    DATACORE___TRIGLAVIAN_QUANTUM_ENGINEERING  : 52309,
    DAWN_OF_LIBERATION_BATTLESHIP_SKINS  : 48533,
    DAWN_OF_LIBERATION_CRUISER_SKINS  : 48532,
    DAWN_OF_LIBERATION_FRIGATE_SKINS  : 48531,
    DAYTRADING  : 16595,
    DAZZLING_SPODUMAIN  : 46688,
    DDO_SCOPED_TRACKING_DISRUPTOR_I  : 5322,
    DEACON  : 37457,
    DEACON_BIOSECURITY_RESPONDERS_SKIN  : 55353,
    DEACON_COLD_IRON_SKIN  : 42759,
    DEACON_EMERGENCY_RESPONSE_SQUAD_SKIN  : 60193,
    DEACON_EXOPLANETS_HUNTER_SKIN  : 45766,
    DEACON_GLACIAL_DRIFT_SKIN  : 46781,
    DEACON_OASIS_OF_FAITH_SKIN  : 53608,
    DEACON_PURITY_OF_THE_THRONE_SKIN  : 42557,
    DEACON_RAATA_SUNSET_SKIN  : 40320,
    DEACTIVATED_STATION_KEY_PASS  : 33017,
    DEACTIVATED_STATION_KEY_PASS_BLUEPRINT  : 33067,
    DEALER_LIGHT_ION_BLASTER_I  : 22901,
    DEATHGLOW_RESIDUE  : 49619,
    DECAYED_10000MN_AFTERBURNER_MUTAPLASMID  : 56275,
    DECAYED_100MN_AFTERBURNER_MUTAPLASMID  : 47754,
    DECAYED_10MN_AFTERBURNER_MUTAPLASMID  : 47750,
    DECAYED_1MN_AFTERBURNER_MUTAPLASMID  : 47746,
    DECAYED_50000MN_MICROWARPDRIVE_MUTAPLASMID  : 56278,
    DECAYED_500MN_MICROWARPDRIVE_MUTAPLASMID  : 47299,
    DECAYED_50MN_MICROWARPDRIVE_MUTAPLASMID  : 47742,
    DECAYED_5MN_MICROWARPDRIVE_MUTAPLASMID  : 47737,
    DECAYED_ASSAULT_DAMAGE_CONTROL_MUTAPLASMID  : 52228,
    DECAYED_BALLISTIC_CONTROL_SYSTEM_MUTAPLASMID  : 49739,
    DECAYED_CAPITAL_ARMOR_REPAIRER_MUTAPLASMID  : 56281,
    DECAYED_CAPITAL_ENERGY_NEUTRALIZER_MUTAPLASMID  : 56292,
    DECAYED_CAPITAL_ENERGY_NOSFERATU_MUTAPLASMID  : 56289,
    DECAYED_CAPITAL_SHIELD_BOOSTER_MUTAPLASMID  : 56285,
    DECAYED_DAMAGE_CONTROL_MUTAPLASMID  : 52224,
    DECAYED_DEATHGLOW  : 49620,
    DECAYED_ENTROPIC_RADIATION_SINK_MUTAPLASMID  : 49735,
    DECAYED_GYROSTABILIZER_MUTAPLASMID  : 49731,
    DECAYED_HEAT_SINK_MUTAPLASMID  : 49727,
    DECAYED_HEAVY_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47829,
    DECAYED_HEAVY_ENERGY_NOSFERATU_MUTAPLASMID  : 48424,
    DECAYED_HEAVY_WARP_DISRUPTOR_MUTAPLASMID  : 56274,
    DECAYED_HEAVY_WARP_SCRAMBLER_MUTAPLASMID  : 56269,
    DECAYED_LARGE_ARMOR_PLATE_MUTAPLASMID  : 47816,
    DECAYED_LARGE_ARMOR_REPAIRER_MUTAPLASMID  : 47774,
    DECAYED_LARGE_CAP_BATTERY_MUTAPLASMID  : 48436,
    DECAYED_LARGE_SHIELD_BOOSTER_MUTAPLASMID  : 47786,
    DECAYED_LARGE_SHIELD_EXTENDER_MUTAPLASMID  : 47805,
    DECAYED_MAGNETIC_FIELD_STABILIZER_MUTAPLASMID  : 49723,
    DECAYED_MEDIUM_ARMOR_PLATE_MUTAPLASMID  : 47813,
    DECAYED_MEDIUM_ARMOR_REPAIRER_MUTAPLASMID  : 47770,
    DECAYED_MEDIUM_CAP_BATTERY_MUTAPLASMID  : 48432,
    DECAYED_MEDIUM_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47825,
    DECAYED_MEDIUM_ENERGY_NOSFERATU_MUTAPLASMID  : 48420,
    DECAYED_MEDIUM_SHIELD_BOOSTER_MUTAPLASMID  : 47782,
    DECAYED_MEDIUM_SHIELD_EXTENDER_MUTAPLASMID  : 47801,
    DECAYED_SIEGE_MODULE_MUTAPLASMID  : 56299,
    DECAYED_SMALL_ARMOR_PLATE_MUTAPLASMID  : 47809,
    DECAYED_SMALL_ARMOR_REPAIRER_MUTAPLASMID  : 47766,
    DECAYED_SMALL_CAP_BATTERY_MUTAPLASMID  : 48428,
    DECAYED_SMALL_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47821,
    DECAYED_SMALL_ENERGY_NOSFERATU_MUTAPLASMID  : 48416,
    DECAYED_SMALL_SHIELD_BOOSTER_MUTAPLASMID  : 47778,
    DECAYED_SMALL_SHIELD_EXTENDER_MUTAPLASMID  : 47797,
    DECAYED_STASIS_WEBIFIER_MUTAPLASMID  : 47699,
    DECAYED_WARP_DISRUPTOR_MUTAPLASMID  : 47733,
    DECAYED_WARP_SCRAMBLER_MUTAPLASMID  : 47729,
    DECAYED_X_LARGE_SHIELD_BOOSTER_MUTAPLASMID  : 47790,
    DECOMMISSIONED_ERIC_PROTOTYPE  : 48758,
    DED_INFORMANTS_FROZEN_CORPSE  : 54228,
    DEEP_CORE_MINING  : 11395,
    DEEP_CORE_MINING_INC_STRONG_BOX  : 43685,
    DEEP_CORE_MINING_LASER_I  : 12108,
    DEEP_CORE_MINING_LASER_I_BLUEPRINT  : 12109,
    DEFECTIVE_CURRENT_PUMP  : 25592,
    DEFENDER_LAUNCHER_I  : 44102,
    DEFENDER_LAUNCHER_I_BLUEPRINT  : 44103,
    DEFENDER_MISSILE_I  : 32782,
    DEFENDER_MISSILE_I_BLUEPRINT  : 32783,
    DEFENDER_MISSILES  : 3323,
    DEFENSIVE_CONTROL_NODE  : 30269,
    DEFENSIVE_SUBSYSTEM_TECHNOLOGY  : 30324,
    DEFLECTION_SHIELD_EMITTER  : 11555,
    DEFLECTION_SHIELD_EMITTER_BLUEPRINT  : 17346,
    DEFUNCT_AMARR_ENCOUNTER_SURVEILLANCE_SYSTEM  : 33585,
    DEFUNCT_CALDARI_ENCOUNTER_SURVEILLANCE_SYSTEM  : 33595,
    DEFUNCT_DRONE_SENSOR_MODULE  : 11588,
    DEFUNCT_GALLENTE_ENCOUNTER_SURVEILLANCE_SYSTEM  : 33608,
    DEFUNCT_MINMATAR_ENCOUNTER_SURVEILLANCE_SYSTEM  : 33610,
    DEFUNCT_NAME_CHANGE_TOKEN  : 48760,
    DEIMOS  : 12023,
    DEIMOS_BIOSECURITY_RESPONDERS_SKIN  : 55618,
    DEIMOS_BLUEPRINT  : 12024,
    DEIMOS_CAILLE_NEON_SKIN  : 50147,
    DEIMOS_EROS_BLOSSOM_SKIN  : 50077,
    DEIMOS_EXOPLANETS_HUNTER_SKIN  : 45896,
    DEIMOS_GLACIAL_DRIFT_SKIN  : 46942,
    DEIMOS_GREEN_DEMONS_SKIN  : 59339,
    DEIMOS_INNER_ZONE_VANGUARD_SKIN  : 52731,
    DEIMOS_INTAKI_SYNDICATE_SKIN  : 36387,
    DEIMOS_KOPIS_EDGE_SKIN  : 46408,
    DEIMOS_SERPENTIS_SKIN  : 42170,
    DEIMOS_SPIRIT_SKIN  : 44914,
    DEIMOS_VALIMOR_LEGACY_SKIN  : 37567,
    DEIMOS_YOIUL_STAR_SKIN  : 57160,
    DELINEATIVE_WARP_SCRAMBLER  : 21512,
    DELUGE_ENDURING_BURST_JAMMER  : 5361,
    DELVE_YOIUL_CALENDAR  : 47043,
    DENNY_ENDURING_OMNIDIRECTIONAL_TRACKING_LINK  : 41032,
    DENSE_VELDSPAR  : 17471,
    DEPLETED_URANIUM_L  : 197,
    DEPLETED_URANIUM_L_BLUEPRINT  : 898,
    DEPLETED_URANIUM_M  : 189,
    DEPLETED_URANIUM_M_BLUEPRINT  : 890,
    DEPLETED_URANIUM_S  : 181,
    DEPLETED_URANIUM_S_BLUEPRINT  : 882,
    DEPLETED_URANIUM_XL  : 17666,
    DEPLETED_URANIUM_XL_BLUEPRINT  : 17667,
    DESERT_HEAT_THERMAL_SHIELD_HARDENER  : 20635,
    DESIGN_LABORATORY  : 28351,
    DEUCE_CO_PROCESSOR_I  : 20569,
    DEVOTER  : 12017,
    DEVOTER_BIOSECURITY_RESPONDERS_SKIN  : 55301,
    DEVOTER_COLD_IRON_SKIN  : 42772,
    DEVOTER_CROWN_AND_SWORDS_SKIN  : 52772,
    DEVOTER_EOM_SKIN  : 36297,
    DEVOTER_EXOPLANETS_HUNTER_SKIN  : 45780,
    DEVOTER_GLACIAL_DRIFT_SKIN  : 46789,
    DEVOTER_IMPERIAL_JUBILEE_SKIN  : 46529,
    DEVOTER_OASIS_OF_FAITH_SKIN  : 53622,
    DEVOTER_PURITY_OF_THE_THRONE_SKIN  : 42571,
    DEVOTER_RAATA_SUNSET_SKIN  : 37506,
    DEVOTER_VERSUS_BLUEFORCE_SKIN  : 63857,
    DIAMOND_RING  : 54160,
    DICE_OF_JADE  : 48587,
    DIPLOMACY  : 3357,
    DIPLOMATIC_RELATIONS  : 3368,
    DISCOVERY_SURVEY_PROBE_I  : 18635,
    DISCOVERY_SURVEY_PROBE_I_BLUEPRINT  : 32854,
    DISPLAY_LAUNCHER  : 44109,
    DISRUPTIVE_LANCE_OPERATION  : 77739,
    DISTRIBUTION_CONNECTIONS  : 3894,
    DISTRIBUTOR_GUIDANCE_DISRUPTOR  : 22931,
    DIVINE_HARVEST_ELECTROMAGNETIC_REAPER  : 40632,
    DIVINE_HARVEST_ELECTROMAGNETIC_REAPER_BLUEPRINT  : 41452,
    DNA_SAMPLE  : 13288,
    DNCR_05H_HIGHSEC_ICE_STORM_FILAMENT  : 74426,
    DNNR_01L_LOWSEC_ICE_STORM_FILAMENT  : 74427,
    DOG_PLACEHOLDER  : 45673,
    DOGBLUEPRINT_PLACEHOLDER  : 45674,
    DOMINATION_10000MN_AFTERBURNER  : 41240,
    DOMINATION_100MN_AFTERBURNER  : 14102,
    DOMINATION_10MN_AFTERBURNER  : 14106,
    DOMINATION_1200MM_ARTILLERY  : 13774,
    DOMINATION_125MM_AUTOCANNON  : 13773,
    DOMINATION_1400MM_HOWITZER_ARTILLERY  : 13775,
    DOMINATION_150MM_AUTOCANNON  : 13776,
    DOMINATION_1MN_AFTERBURNER  : 14110,
    DOMINATION_200MM_AUTOCANNON  : 13777,
    DOMINATION_220MM_AUTOCANNON  : 13778,
    DOMINATION_250MM_ARTILLERY  : 13779,
    DOMINATION_280MM_HOWITZER_ARTILLERY  : 13781,
    DOMINATION_425MM_AUTOCANNON  : 13782,
    DOMINATION_50000MN_MICROWARPDRIVE  : 41254,
    DOMINATION_500MN_MICROWARPDRIVE  : 14114,
    DOMINATION_50MN_MICROWARPDRIVE  : 14118,
    DOMINATION_5MN_MICROWARPDRIVE  : 14122,
    DOMINATION_650MM_ARTILLERY  : 13783,
    DOMINATION_720MM_HOWITZER_ARTILLERY  : 13784,
    DOMINATION_800MM_REPEATING_CANNON  : 13785,
    DOMINATION_BALLISTIC_CONTROL_SYSTEM  : 13935,
    DOMINATION_BRASS_TAG  : 17220,
    DOMINATION_BRONZE_TAG  : 17221,
    DOMINATION_CAPITAL_CAP_BATTERY  : 41487,
    DOMINATION_CAPITAL_FLEX_SHIELD_HARDENER  : 41531,
    DOMINATION_CAPITAL_REMOTE_SHIELD_BOOSTER  : 41474,
    DOMINATION_CAPITAL_SHIELD_BOOSTER  : 41510,
    DOMINATION_CAPITAL_SHIELD_EXTENDER  : 41462,
    DOMINATION_CARBONIZED_LEAD_L  : 20785,
    DOMINATION_CARBONIZED_LEAD_M  : 20769,
    DOMINATION_CARBONIZED_LEAD_S  : 20753,
    DOMINATION_CARBONIZED_LEAD_XL  : 20801,
    DOMINATION_CONTROL_TOWER  : 27540,
    DOMINATION_CONTROL_TOWER_MEDIUM  : 27609,
    DOMINATION_CONTROL_TOWER_SMALL  : 27612,
    DOMINATION_COPPER_TAG  : 17222,
    DOMINATION_CRUISE_MISSILE_LAUNCHER  : 13927,
    DOMINATION_CRYSTAL_TAG  : 17223,
    DOMINATION_DEPLETED_URANIUM_L  : 20791,
    DOMINATION_DEPLETED_URANIUM_M  : 20775,
    DOMINATION_DEPLETED_URANIUM_S  : 20759,
    DOMINATION_DEPLETED_URANIUM_XL  : 20803,
    DOMINATION_DIAMOND_TAG  : 17224,
    DOMINATION_DUAL_180MM_AUTOCANNON  : 13786,
    DOMINATION_DUAL_425MM_AUTOCANNON  : 13787,
    DOMINATION_DUAL_650MM_REPEATING_CANNON  : 13788,
    DOMINATION_ELECTRUM_TAG  : 17225,
    DOMINATION_EM_ARMOR_HARDENER  : 13986,
    DOMINATION_EM_COATING  : 14017,
    DOMINATION_EM_SHIELD_AMPLIFIER  : 14041,
    DOMINATION_EM_SHIELD_HARDENER  : 13994,
    DOMINATION_EMP_L  : 20799,
    DOMINATION_EMP_M  : 20783,
    DOMINATION_EMP_S  : 20767,
    DOMINATION_EMP_XL  : 20805,
    DOMINATION_EXPLOSIVE_ARMOR_HARDENER  : 13988,
    DOMINATION_EXPLOSIVE_COATING  : 14011,
    DOMINATION_EXPLOSIVE_SHIELD_AMPLIFIER  : 14029,
    DOMINATION_EXPLOSIVE_SHIELD_HARDENER  : 13996,
    DOMINATION_FUSION_L  : 20795,
    DOMINATION_FUSION_M  : 20779,
    DOMINATION_FUSION_S  : 20763,
    DOMINATION_FUSION_XL  : 20807,
    DOMINATION_GOLD_TAG  : 17226,
    DOMINATION_GYROSTABILIZER  : 13939,
    DOMINATION_HEAVY_ASSAULT_MISSILE_LAUNCHER  : 28379,
    DOMINATION_HEAVY_MISSILE_LAUNCHER  : 13921,
    DOMINATION_HEAVY_STASIS_GRAPPLER  : 41059,
    DOMINATION_HEAVY_WARP_DISRUPTOR  : 40737,
    DOMINATION_HEAVY_WARP_SCRAMBLER  : 40764,
    DOMINATION_HEXA_2500MM_REPEATING_CANNON  : 41154,
    DOMINATION_INERTIAL_STABILIZERS  : 34481,
    DOMINATION_KINETIC_ARMOR_HARDENER  : 13990,
    DOMINATION_KINETIC_COATING  : 14005,
    DOMINATION_KINETIC_SHIELD_AMPLIFIER  : 14037,
    DOMINATION_KINETIC_SHIELD_HARDENER  : 13997,
    DOMINATION_LARGE_ARMOR_REPAIRER  : 13955,
    DOMINATION_LARGE_ARTILLERY_BATTERY  : 27559,
    DOMINATION_LARGE_AUTOCANNON_BATTERY  : 27556,
    DOMINATION_LARGE_CAP_BATTERY  : 41219,
    DOMINATION_LARGE_PROTON_SMARTBOMB  : 14208,
    DOMINATION_LARGE_SHIELD_BOOSTER  : 13948,
    DOMINATION_LIGHT_MISSILE_LAUNCHER  : 13925,
    DOMINATION_MEDIUM_ARMOR_REPAIRER  : 13958,
    DOMINATION_MEDIUM_ARTILLERY_BATTERY  : 27646,
    DOMINATION_MEDIUM_AUTOCANNON_BATTERY  : 27649,
    DOMINATION_MEDIUM_CAP_BATTERY  : 41216,
    DOMINATION_MEDIUM_PROTON_SMARTBOMB  : 14222,
    DOMINATION_MEDIUM_SHIELD_BOOSTER  : 13950,
    DOMINATION_MICRO_PROTON_SMARTBOMB  : 14224,
    DOMINATION_MULTISPECTRUM_COATING  : 13999,
    DOMINATION_MULTISPECTRUM_SHIELD_HARDENER  : 13998,
    DOMINATION_NANOFIBER_STRUCTURE  : 14127,
    DOMINATION_NUCLEAR_L  : 20787,
    DOMINATION_NUCLEAR_M  : 20771,
    DOMINATION_NUCLEAR_S  : 20755,
    DOMINATION_NUCLEAR_XL  : 20809,
    DOMINATION_OVERDRIVE_INJECTOR  : 14126,
    DOMINATION_PALLADIUM_TAG  : 17227,
    DOMINATION_PHASED_PLASMA_L  : 20797,
    DOMINATION_PHASED_PLASMA_M  : 20781,
    DOMINATION_PHASED_PLASMA_S  : 20765,
    DOMINATION_PHASED_PLASMA_XL  : 20811,
    DOMINATION_PLATINUM_TAG  : 17229,
    DOMINATION_PROTON_L  : 20789,
    DOMINATION_PROTON_M  : 20773,
    DOMINATION_PROTON_S  : 20757,
    DOMINATION_PROTON_XL  : 20813,
    DOMINATION_QUAD_3500MM_SIEGE_ARTILLERY  : 41160,
    DOMINATION_QUAD_800MM_REPEATING_CANNON  : 41083,
    DOMINATION_RAPID_HEAVY_MISSILE_LAUNCHER  : 33452,
    DOMINATION_RAPID_LIGHT_MISSILE_LAUNCHER  : 13919,
    DOMINATION_ROCKET_LAUNCHER  : 13931,
    DOMINATION_SHIELD_BOOST_AMPLIFIER  : 14045,
    DOMINATION_SILVER_TAG  : 17230,
    DOMINATION_SMALL_ARMOR_REPAIRER  : 13962,
    DOMINATION_SMALL_ARTILLERY_BATTERY  : 27652,
    DOMINATION_SMALL_AUTOCANNON_BATTERY  : 27655,
    DOMINATION_SMALL_CAP_BATTERY  : 41213,
    DOMINATION_SMALL_PROTON_SMARTBOMB  : 14226,
    DOMINATION_SMALL_SHIELD_BOOSTER  : 13952,
    DOMINATION_STASIS_WEBIFICATION_BATTERY  : 27573,
    DOMINATION_STASIS_WEBIFIER  : 14264,
    DOMINATION_TARGET_PAINTER  : 32414,
    DOMINATION_THERMAL_ARMOR_HARDENER  : 13992,
    DOMINATION_THERMAL_COATING  : 14023,
    DOMINATION_THERMAL_SHIELD_AMPLIFIER  : 14033,
    DOMINATION_THERMAL_SHIELD_HARDENER  : 13995,
    DOMINATION_TITANIUM_SABOT_L  : 20793,
    DOMINATION_TITANIUM_SABOT_M  : 20777,
    DOMINATION_TITANIUM_SABOT_S  : 20761,
    DOMINATION_TITANIUM_SABOT_XL  : 20815,
    DOMINATION_TORPEDO_LAUNCHER  : 13923,
    DOMINATION_TRACKING_ENHANCER  : 14100,
    DOMINATION_WARP_DISRUPTOR  : 14244,
    DOMINATION_WARP_SCRAMBLER  : 14254,
    DOMINATION_X_LARGE_SHIELD_BOOSTER  : 13954,
    DOMINIX  : 645,
    DOMINIX_BIOSECURITY_RESPONDERS_SKIN  : 55579,
    DOMINIX_BLUEPRINT  : 999,
    DOMINIX_CAPSULEER_DAY_XVII_SKIN  : 54789,
    DOMINIX_CRYPTIC_MIMESIS_SKIN  : 48895,
    DOMINIX_EXOPLANETS_HUNTER_SKIN  : 45911,
    DOMINIX_FEDERAL_POLICE_SKIN  : 54901,
    DOMINIX_GALNET_STREAMCAST_UNIT_SKIN  : 57730,
    DOMINIX_GLACIAL_DRIFT_SKIN  : 46957,
    DOMINIX_GLITTERING_DREAM_SKIN  : 47534,
    DOMINIX_GREEN_DEMONS_SKIN  : 59335,
    DOMINIX_HEADHUNTER_SKIN  : 46720,
    DOMINIX_IGC_VICTORY_SKIN  : 47698,
    DOMINIX_INNER_ZONE_SHIPPING_SKIN  : 34633,
    DOMINIX_INNER_ZONE_VANGUARD_SKIN  : 52746,
    DOMINIX_INTAKI_SYNDICATE_SKIN  : 34634,
    DOMINIX_INTERSTELLAR_CONVERGENCE_SKIN  : 62230,
    DOMINIX_KOPIS_EDGE_SKIN  : 46423,
    DOMINIX_LUMINAIRE_RISING_SKIN  : 64571,
    DOMINIX_MALAGRIN_MARSHLIGHT_SKIN  : 56597,
    DOMINIX_NAVY_ISSUE  : 32307,
    DOMINIX_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55609,
    DOMINIX_NAVY_ISSUE_CRYPTIC_MIMESIS_SKIN  : 60452,
    DOMINIX_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45916,
    DOMINIX_NAVY_ISSUE_FEDERAL_POLICE_SKIN  : 54902,
    DOMINIX_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46962,
    DOMINIX_NAVY_ISSUE_GLITTERING_DREAM_SKIN  : 47535,
    DOMINIX_NAVY_ISSUE_INNER_ZONE_VANGUARD_SKIN  : 52751,
    DOMINIX_NAVY_ISSUE_INTAKI_SYNDICATE_SKIN  : 36407,
    DOMINIX_NAVY_ISSUE_KOPIS_EDGE_SKIN  : 46428,
    DOMINIX_NAVY_ISSUE_SAPPHIRE_SUNGRAZER_SKIN  : 45026,
    DOMINIX_NAVY_ISSUE_SPIRIT_SKIN  : 44934,
    DOMINIX_QUAFE_SKIN  : 34635,
    DOMINIX_RUBY_SUNGRAZER_SKIN  : 45569,
    DOMINIX_SAPPHIRE_SUNGRAZER_SKIN  : 46550,
    DOMINIX_SERPENTIS_SKIN  : 42182,
    DOMINIX_SPIRIT_SKIN  : 44929,
    DOMINIX_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59236,
    DOMINIX_VALIMOR_LEGACY_SKIN  : 37578,
    DOMINIX_VALKYRIE_SKIN  : 46379,
    DOMINIX_YOIUL_FESTIVAL_YC121_SKIN  : 49957,
    DOMINIX_YOIUL_STAR_SKIN  : 53429,
    DOMINO_TURQUOISE_AUGMENTATION_CRATE  : 49705,
    DONUT_HOLDER  : 33065,
    DOOMSDAY_OPERATION  : 24563,
    DOOMSDAY_RAPID_FIRING  : 41537,
    DOPED_EIFYRIUM  : 74530,
    DRACCOUS_FORTIZAR  : 47513,
    DRACLIRA_MERLONNES_TAG  : 17299,
    DRACLIRAS_MODIFIED_CAP_RECHARGER  : 21819,
    DRACLIRAS_MODIFIED_CAPACITOR_POWER_RELAY  : 15094,
    DRACLIRAS_MODIFIED_DUAL_HEAVY_BEAM_LASER  : 14423,
    DRACLIRAS_MODIFIED_EM_ARMOR_HARDENER  : 15030,
    DRACLIRAS_MODIFIED_EM_COATING  : 14918,
    DRACLIRAS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14982,
    DRACLIRAS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15078,
    DRACLIRAS_MODIFIED_EXPLOSIVE_COATING  : 14902,
    DRACLIRAS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14998,
    DRACLIRAS_MODIFIED_HEAT_SINK  : 14814,
    DRACLIRAS_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15142,
    DRACLIRAS_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14846,
    DRACLIRAS_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14830,
    DRACLIRAS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15062,
    DRACLIRAS_MODIFIED_KINETIC_COATING  : 14886,
    DRACLIRAS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15014,
    DRACLIRAS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14855,
    DRACLIRAS_MODIFIED_LARGE_EMP_SMARTBOMB  : 14798,
    DRACLIRAS_MODIFIED_MEGA_BEAM_LASER  : 14439,
    DRACLIRAS_MODIFIED_MULTISPECTRUM_COATING  : 14870,
    DRACLIRAS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14950,
    DRACLIRAS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15110,
    DRACLIRAS_MODIFIED_REACTOR_CONTROL_UNIT  : 15126,
    DRACLIRAS_MODIFIED_TACHYON_BEAM_LASER  : 14455,
    DRACLIRAS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15046,
    DRACLIRAS_MODIFIED_THERMAL_COATING  : 14934,
    DRACLIRAS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14966,
    DRAGONFLY_I  : 23057,
    DRAGONFLY_I_BLUEPRINT  : 23058,
    DRAGONFLY_II  : 40557,
    DRAGOON  : 32874,
    DRAGOON_ARDISHAPUR_SKIN  : 60674,
    DRAGOON_BIOSECURITY_RESPONDERS_SKIN  : 55324,
    DRAGOON_BLUEPRINT  : 32875,
    DRAGOON_COLD_IRON_SKIN  : 42763,
    DRAGOON_DEATHGLOW_REMNANT_SKIN  : 73373,
    DRAGOON_EXOPLANETS_HUNTER_SKIN  : 45770,
    DRAGOON_GLACIAL_DRIFT_SKIN  : 44141,
    DRAGOON_IMPERIAL_JUBILEE_SKIN  : 46526,
    DRAGOON_IRONBLOOD_SKIN  : 43497,
    DRAGOON_KHANID_SKIN  : 34703,
    DRAGOON_OASIS_OF_FAITH_SKIN  : 53612,
    DRAGOON_PURITY_OF_THE_THRONE_SKIN  : 42561,
    DRAGOON_RAATA_SUNSET_SKIN  : 37498,
    DRAGOON_SANGUINARY_SAVANT_SKIN  : 73453,
    DRAGOON_TASH_MURKON_SKIN  : 34704,
    DRAGOON_WAR_RESERVES_LIEUTENANT_SKIN  : 59422,
    DRAKE  : 24698,
    DRAKE__ORACLE_ARGYROS_SKIN_CRATE  : 52297,
    DRAKE__RUPTURE_SKIN_CRATE  : 49805,
    DRAKE_ABYSSAL_FIRESTORM_SKIN  : 53135,
    DRAKE_ARGYROS_ELITE_XVI_SKIN  : 52285,
    DRAKE_AURORA_UNIVERSALIS_SKIN  : 53373,
    DRAKE_BIOSECURITY_RESPONDERS_SKIN  : 55526,
    DRAKE_BLUE_TIGER_SKIN  : 36731,
    DRAKE_BLUEPRINT  : 24699,
    DRAKE_CALDARI_UNION_DAY_SKIN  : 48729,
    DRAKE_CAPSULEER_DAY_XV_SKIN  : 47629,
    DRAKE_CHROMODYNAMIC_CANDY_SKIN  : 49800,
    DRAKE_DEATHGLOW_REMNANT_SKIN  : 73366,
    DRAKE_EXOPLANETS_HUNTER_SKIN  : 45846,
    DRAKE_GHOSTBIRD_SKIN  : 48189,
    DRAKE_GILDED_PREDATOR_SKIN  : 77173,
    DRAKE_GLACIAL_DRIFT_SKIN  : 46850,
    DRAKE_GURISTAS_SKIN  : 54561,
    DRAKE_HEADHUNTER_SKIN  : 46714,
    DRAKE_IGC_SKIN  : 45015,
    DRAKE_MALAGRIN_MARSHLIGHT_SKIN  : 53010,
    DRAKE_MATIGU_SEABEAST_SKIN  : 40607,
    DRAKE_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59224,
    DRAKE_NAVY_ISSUE  : 33153,
    DRAKE_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55545,
    DRAKE_NAVY_ISSUE_CAPSULEER_ELITE_XX_SKIN  : 77166,
    DRAKE_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45851,
    DRAKE_NAVY_ISSUE_GHOSTBIRD_SKIN  : 48194,
    DRAKE_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46855,
    DRAKE_NAVY_ISSUE_IGC_SKIN  : 48551,
    DRAKE_NAVY_ISSUE_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47616,
    DRAKE_NAVY_ISSUE_SPIRIT_SKIN  : 57390,
    DRAKE_NAVY_ISSUE_STEEL_CARDINAL_SKIN  : 46128,
    DRAKE_NAVY_ISSUE_WARP_CONVERGENCE_SKIN  : 61869,
    DRAKE_NAVY_ISSUE_WIYRKOMI_SKIN  : 36366,
    DRAKE_NAVY_ISSUE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53188,
    DRAKE_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47615,
    DRAKE_OCTOPUS_RED_FLIGHT_SKIN  : 46731,
    DRAKE_RAATA_SUNSET_SKIN  : 36659,
    DRAKE_SPIRIT_SKIN  : 47312,
    DRAKE_STARLINE_MAJESTIC_SKIN  : 55723,
    DRAKE_STEEL_CARDINAL_SKIN  : 46123,
    DRAKE_TRIGLAVIAN_TWILIGHT_SKIN  : 52401,
    DRAKE_UNION_DAY_EXECUTIVE_SKIN  : 71730,
    DRAKE_WARP_CONVERGENCE_SKIN  : 61868,
    DRAKE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53183,
    DRAMIEL  : 17932,
    DRAMIEL_ANGELS_HEX_SKIN  : 49146,
    DRAMIEL_EROS_BLOSSOM_SKIN  : 47293,
    DRAMIEL_GLACIAL_DRIFT_SKIN  : 46997,
    DRAMIEL_HEARTSURGE_SKIN  : 53949,
    DRAMIEL_SARIELS_FLAMES_SKIN  : 47541,
    DRAMIEL_SPIRIT_SKIN  : 44951,
    DRAMIEL_VERSUS_BLUEFORCE_SKIN  : 62644,
    DRAMIEL_VERSUS_REDFORCE_SKIN  : 62638,
    DRAUGUR  : 52254,
    DRAUGUR_ALL_STARS_CASINO_CLASH_SKIN  : 53024,
    DRAUGUR_BLACKFIRE_STEEL_SKIN  : 53666,
    DRAUGUR_LIMINAL_CROSSINGS_SKIN  : 53316,
    DRAUGUR_METAMATERIA_EXOTICA_SKIN  : 54220,
    DRAUGUR_NIFLHEL_GILDCLAW_SKIN  : 52698,
    DRAUGUR_SINGULARITY_STORM_SKIN  : 52352,
    DREAD_GURISTAS_125MM_RAILGUN  : 13865,
    DREAD_GURISTAS_150MM_RAILGUN  : 13867,
    DREAD_GURISTAS_200MM_RAILGUN  : 13870,
    DREAD_GURISTAS_250MM_RAILGUN  : 13873,
    DREAD_GURISTAS_350MM_RAILGUN  : 13876,
    DREAD_GURISTAS_425MM_RAILGUN  : 13879,
    DREAD_GURISTAS_75MM_RAILGUN  : 13893,
    DREAD_GURISTAS_ANTIMATTER_CHARGE_L  : 21430,
    DREAD_GURISTAS_ANTIMATTER_CHARGE_M  : 21414,
    DREAD_GURISTAS_ANTIMATTER_CHARGE_S  : 21398,
    DREAD_GURISTAS_ANTIMATTER_CHARGE_XL  : 21432,
    DREAD_GURISTAS_BALLISTIC_CONTROL_SYSTEM  : 13937,
    DREAD_GURISTAS_BRASS_TAG  : 17241,
    DREAD_GURISTAS_BRONZE_TAG  : 17242,
    DREAD_GURISTAS_CAPITAL_FLEX_SHIELD_HARDENER  : 41530,
    DREAD_GURISTAS_CAPITAL_REMOTE_SHIELD_BOOSTER  : 41473,
    DREAD_GURISTAS_CAPITAL_SHIELD_BOOSTER  : 41509,
    DREAD_GURISTAS_CAPITAL_SHIELD_EXTENDER  : 41461,
    DREAD_GURISTAS_CLOAKING_DEVICE  : 14234,
    DREAD_GURISTAS_CO_PROCESSOR  : 14230,
    DREAD_GURISTAS_CONTROL_TOWER  : 27535,
    DREAD_GURISTAS_CONTROL_TOWER_MEDIUM  : 27597,
    DREAD_GURISTAS_CONTROL_TOWER_SMALL  : 27600,
    DREAD_GURISTAS_COPPER_TAG  : 17243,
    DREAD_GURISTAS_CRUISE_MISSILE_BATTERY  : 27640,
    DREAD_GURISTAS_CRUISE_MISSILE_LAUNCHER  : 13929,
    DREAD_GURISTAS_CRYSTAL_TAG  : 17244,
    DREAD_GURISTAS_DIAMOND_TAG  : 17245,
    DREAD_GURISTAS_DRONE_DAMAGE_AMPLIFIER  : 33846,
    DREAD_GURISTAS_DUAL_150MM_RAILGUN  : 13881,
    DREAD_GURISTAS_DUAL_250MM_RAILGUN  : 13883,
    DREAD_GURISTAS_ELECTRUM_TAG  : 17247,
    DREAD_GURISTAS_EM_SHIELD_AMPLIFIER  : 14043,
    DREAD_GURISTAS_EM_SHIELD_HARDENER  : 13965,
    DREAD_GURISTAS_EXPLOSIVE_SHIELD_AMPLIFIER  : 14031,
    DREAD_GURISTAS_EXPLOSIVE_SHIELD_HARDENER  : 13967,
    DREAD_GURISTAS_GOLD_TAG  : 17248,
    DREAD_GURISTAS_HEAVY_ASSAULT_MISSILE_LAUNCHER  : 28381,
    DREAD_GURISTAS_HEAVY_MISSILE_LAUNCHER  : 13922,
    DREAD_GURISTAS_INFERNO_CRUISE_MISSILE  : 27425,
    DREAD_GURISTAS_INFERNO_HEAVY_ASSAULT_MISSILE  : 27407,
    DREAD_GURISTAS_INFERNO_HEAVY_MISSILE  : 27449,
    DREAD_GURISTAS_INFERNO_LIGHT_MISSILE  : 27369,
    DREAD_GURISTAS_INFERNO_ROCKET  : 27317,
    DREAD_GURISTAS_INFERNO_TORPEDO  : 27355,
    DREAD_GURISTAS_ION_FIELD_PROJECTION_BATTERY  : 27576,
    DREAD_GURISTAS_IRIDIUM_CHARGE_L  : 21420,
    DREAD_GURISTAS_IRIDIUM_CHARGE_M  : 21404,
    DREAD_GURISTAS_IRIDIUM_CHARGE_S  : 21388,
    DREAD_GURISTAS_IRIDIUM_CHARGE_XL  : 21434,
    DREAD_GURISTAS_IRON_CHARGE_L  : 21416,
    DREAD_GURISTAS_IRON_CHARGE_M  : 21400,
    DREAD_GURISTAS_IRON_CHARGE_S  : 21384,
    DREAD_GURISTAS_IRON_CHARGE_XL  : 21436,
    DREAD_GURISTAS_KINETIC_SHIELD_AMPLIFIER  : 14039,
    DREAD_GURISTAS_KINETIC_SHIELD_HARDENER  : 13968,
    DREAD_GURISTAS_LARGE_GRAVITON_SMARTBOMB  : 14204,
    DREAD_GURISTAS_LARGE_SHIELD_BOOSTER  : 13947,
    DREAD_GURISTAS_LEAD_CHARGE_L  : 21422,
    DREAD_GURISTAS_LEAD_CHARGE_M  : 21406,
    DREAD_GURISTAS_LEAD_CHARGE_S  : 21390,
    DREAD_GURISTAS_LEAD_CHARGE_XL  : 21438,
    DREAD_GURISTAS_LIGHT_MISSILE_LAUNCHER  : 13926,
    DREAD_GURISTAS_MEDIUM_GRAVITON_SMARTBOMB  : 14210,
    DREAD_GURISTAS_MEDIUM_SHIELD_BOOSTER  : 13949,
    DREAD_GURISTAS_MICRO_GRAVITON_SMARTBOMB  : 14212,
    DREAD_GURISTAS_MJOLNIR_CRUISE_MISSILE  : 27389,
    DREAD_GURISTAS_MJOLNIR_HEAVY_ASSAULT_MISSILE  : 27421,
    DREAD_GURISTAS_MJOLNIR_HEAVY_MISSILE  : 27437,
    DREAD_GURISTAS_MJOLNIR_LIGHT_MISSILE  : 27385,
    DREAD_GURISTAS_MJOLNIR_ROCKET  : 27323,
    DREAD_GURISTAS_MJOLNIR_TORPEDO  : 27341,
    DREAD_GURISTAS_MULTISPECTRAL_ECM  : 20199,
    DREAD_GURISTAS_MULTISPECTRUM_SHIELD_HARDENER  : 13969,
    DREAD_GURISTAS_NOVA_CRUISE_MISSILE  : 27431,
    DREAD_GURISTAS_NOVA_HEAVY_ASSAULT_MISSILE  : 27397,
    DREAD_GURISTAS_NOVA_HEAVY_MISSILE  : 27455,
    DREAD_GURISTAS_NOVA_LIGHT_MISSILE  : 27375,
    DREAD_GURISTAS_NOVA_ROCKET  : 27329,
    DREAD_GURISTAS_NOVA_TORPEDO  : 27363,
    DREAD_GURISTAS_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 33830,
    DREAD_GURISTAS_PALLADIUM_TAG  : 17249,
    DREAD_GURISTAS_PHASE_INVERSION_BATTERY  : 27579,
    DREAD_GURISTAS_PLATINUM_TAG  : 17250,
    DREAD_GURISTAS_PLUTONIUM_CHARGE_L  : 21428,
    DREAD_GURISTAS_PLUTONIUM_CHARGE_M  : 21412,
    DREAD_GURISTAS_PLUTONIUM_CHARGE_S  : 21396,
    DREAD_GURISTAS_PLUTONIUM_CHARGE_XL  : 21440,
    DREAD_GURISTAS_RAPID_HEAVY_MISSILE_LAUNCHER  : 33453,
    DREAD_GURISTAS_RAPID_LIGHT_MISSILE_LAUNCHER  : 13920,
    DREAD_GURISTAS_RAPID_TORPEDO_LAUNCHER  : 37293,
    DREAD_GURISTAS_ROCKET_LAUNCHER  : 13933,
    DREAD_GURISTAS_SCOURGE_CRUISE_MISSILE  : 27399,
    DREAD_GURISTAS_SCOURGE_HEAVY_ASSAULT_MISSILE  : 27415,
    DREAD_GURISTAS_SCOURGE_HEAVY_MISSILE  : 27443,
    DREAD_GURISTAS_SCOURGE_LIGHT_MISSILE  : 27365,
    DREAD_GURISTAS_SCOURGE_ROCKET  : 27335,
    DREAD_GURISTAS_SCOURGE_TORPEDO  : 27347,
    DREAD_GURISTAS_SHIELD_BOOST_AMPLIFIER  : 14047,
    DREAD_GURISTAS_SHIELD_FLUX_COIL  : 37825,
    DREAD_GURISTAS_SILVER_TAG  : 17251,
    DREAD_GURISTAS_SMALL_GRAVITON_SMARTBOMB  : 14214,
    DREAD_GURISTAS_SMALL_SHIELD_BOOSTER  : 13951,
    DREAD_GURISTAS_SPATIAL_DESTABILIZATION_BATTERY  : 27582,
    DREAD_GURISTAS_STANDUP_VARIABLE_SPECTRUM_ECM  : 46577,
    DREAD_GURISTAS_STANDUP_VARIABLE_SPECTRUM_ECM_BLUEPRINT  : 46578,
    DREAD_GURISTAS_STASIS_WEBIFIER  : 14266,
    DREAD_GURISTAS_THERMAL_SHIELD_AMPLIFIER  : 14035,
    DREAD_GURISTAS_THERMAL_SHIELD_HARDENER  : 13966,
    DREAD_GURISTAS_THORIUM_CHARGE_L  : 21424,
    DREAD_GURISTAS_THORIUM_CHARGE_M  : 21408,
    DREAD_GURISTAS_THORIUM_CHARGE_S  : 21392,
    DREAD_GURISTAS_THORIUM_CHARGE_XL  : 21442,
    DREAD_GURISTAS_TORPEDO_BATTERY  : 27643,
    DREAD_GURISTAS_TORPEDO_LAUNCHER  : 13924,
    DREAD_GURISTAS_TUNGSTEN_CHARGE_L  : 21418,
    DREAD_GURISTAS_TUNGSTEN_CHARGE_M  : 21402,
    DREAD_GURISTAS_TUNGSTEN_CHARGE_S  : 21386,
    DREAD_GURISTAS_TUNGSTEN_CHARGE_XL  : 21444,
    DREAD_GURISTAS_URANIUM_CHARGE_L  : 21426,
    DREAD_GURISTAS_URANIUM_CHARGE_M  : 21410,
    DREAD_GURISTAS_URANIUM_CHARGE_S  : 21394,
    DREAD_GURISTAS_URANIUM_CHARGE_XL  : 21446,
    DREAD_GURISTAS_WARP_DISRUPTOR  : 14246,
    DREAD_GURISTAS_WARP_SCRAMBLER  : 14256,
    DREAD_GURISTAS_WHITE_NOISE_GENERATION_BATTERY  : 27585,
    DREAD_GURISTAS_X_LARGE_SHIELD_BOOSTER  : 13953,
    DREAD_GURISTAS_XL_CRUISE_MISSILE_LAUNCHER  : 41174,
    DREAD_GURISTAS_XL_TORPEDO_BATTERY  : 27562,
    DREAD_GURISTAS_XL_TORPEDO_LAUNCHER  : 41182,
    DREADNOUGHT_SYSTEMS_DIAGRAM  : 53291,
    DREKAVAC  : 49711,
    DREKAVAC_BLACKFIRE_STEEL_SKIN  : 49927,
    DREKAVAC_COPPER_LIGHTNING_SKIN  : 60759,
    DREKAVAC_DEADSPACE_DREAMTIME_SKIN  : 52419,
    DREKAVAC_EDENS_HUNTERS_SKIN  : 54906,
    DREKAVAC_HADEAN_HORROR_SKIN  : 49936,
    DREKAVAC_KYBERNAUT_CLADE_SKIN  : 54915,
    DREKAVAC_METAMATERIA_EXOTICA_SKIN  : 50144,
    DREKAVAC_RED_FORESTS_THUNDERBIRD_SKIN  : 52444,
    DREZINS_DNA  : 14483,
    DRIED_MOSS  : 48756,
    DROMI_I  : 40347,
    DROMI_I_BLUEPRINT  : 41373,
    DROMI_II  : 40571,
    DRONE_ASSEMBLY_ARRAY  : 24659,
    DRONE_AVIONICS  : 3437,
    DRONE_CAPILLARY_FLUID  : 28362,
    DRONE_CEREBRAL_FRAGMENT  : 28363,
    DRONE_CORONARY_UNIT  : 28366,
    DRONE_DAMAGE_AMPLIFIER_I  : 4393,
    DRONE_DAMAGE_AMPLIFIER_I_BLUEPRINT  : 4394,
    DRONE_DAMAGE_AMPLIFIER_II  : 4405,
    DRONE_DURABILITY  : 23618,
    DRONE_EPIDERMAL_SHIELDING_CHUNK  : 28365,
    DRONE_INTERFACING  : 3442,
    DRONE_LINK_AUGMENTOR_I  : 23527,
    DRONE_LINK_AUGMENTOR_I_BLUEPRINT  : 23528,
    DRONE_LINK_AUGMENTOR_II  : 24427,
    DRONE_NAVIGATION  : 12305,
    DRONE_NAVIGATION_COMPUTER_I  : 24395,
    DRONE_NAVIGATION_COMPUTER_I_BLUEPRINT  : 24396,
    DRONE_NAVIGATION_COMPUTER_II  : 24417,
    DRONE_REGIONS_YC109  : 52274,
    DRONE_SHARPSHOOTING  : 23606,
    DRONE_SYNAPTIC_RELAY_WIRING  : 28361,
    DRONE_TACTICAL_LIMB  : 28364,
    DRONE_TRANSCEIVER  : 25615,
    DRONES  : 3436,
    DRONES_RIGGING  : 26255,
    DROP  : 3709,
    DROPSUIT_FRAGMENT  : 48757,
    DRUG_LAB  : 25305,
    DRUG_MANUFACTURING  : 26224,
    DSHR_01H_HIGHSEC_ICE_STORM_FILAMENT  : 74428,
    DUAL_1000MM_RAILGUN_I  : 20448,
    DUAL_1000MM_RAILGUN_I_BLUEPRINT  : 20449,
    DUAL_1000MM_RAILGUN_II  : 37303,
    DUAL_150MM_CARBIDE_RAILGUN_I  : 7331,
    DUAL_150MM_COMPRESSED_COIL_GUN_I  : 7333,
    DUAL_150MM_PROTOTYPE_GAUSS_GUN  : 7327,
    DUAL_150MM_RAILGUN_I  : 567,
    DUAL_150MM_RAILGUN_I_BLUEPRINT  : 1118,
    DUAL_150MM_RAILGUN_II  : 3106,
    DUAL_150MM_SCOUT_ACCELERATOR_CANNON  : 7329,
    DUAL_180MM_AUTOCANNON_I  : 489,
    DUAL_180MM_AUTOCANNON_I_BLUEPRINT  : 824,
    DUAL_180MM_AUTOCANNON_II  : 2937,
    DUAL_180MM_CARBINE_REPEATING_CANNON_I  : 9073,
    DUAL_180MM_GALLIUM_MACHINE_GUN  : 9091,
    DUAL_180MM_PROTOTYPE_AUTOMATIC_CANNON  : 9093,
    DUAL_180MM_SCOUT_AUTOCANNON_I  : 9071,
    DUAL_250MM_CARBIDE_RAILGUN_I  : 7411,
    DUAL_250MM_COMPRESSED_COIL_GUN_I  : 7413,
    DUAL_250MM_PROTOTYPE_GAUSS_GUN  : 7407,
    DUAL_250MM_RAILGUN_I  : 572,
    DUAL_250MM_RAILGUN_I_BLUEPRINT  : 1123,
    DUAL_250MM_RAILGUN_II  : 3114,
    DUAL_250MM_SCOUT_ACCELERATOR_CANNON  : 7409,
    DUAL_425MM_AUTOCANNON_I  : 494,
    DUAL_425MM_AUTOCANNON_I_BLUEPRINT  : 829,
    DUAL_425MM_AUTOCANNON_II  : 2945,
    DUAL_425MM_CARBINE_REPEATING_CANNON_I  : 9249,
    DUAL_425MM_GALLIUM_MACHINE_GUN  : 9251,
    DUAL_425MM_PROTOTYPE_AUTOMATIC_CANNON  : 9253,
    DUAL_425MM_SCOUT_AUTOCANNON_I  : 9247,
    DUAL_650MM_CARBINE_REPEATING_CANNON_I  : 9289,
    DUAL_650MM_GALLIUM_REPEATING_CANNON  : 9291,
    DUAL_650MM_PROTOTYPE_AUTOMATIC_CANNON  : 9293,
    DUAL_650MM_REPEATING_CANNON_I  : 495,
    DUAL_650MM_REPEATING_CANNON_I_BLUEPRINT  : 830,
    DUAL_650MM_REPEATING_CANNON_II  : 2953,
    DUAL_650MM_SCOUT_REPEATING_CANNON_I  : 9287,
    DUAL_AFOCAL_HEAVY_MASER_I  : 7045,
    DUAL_AFOCAL_LIGHT_MASER_I  : 6633,
    DUAL_AFOCAL_PULSE_MASER_I  : 5217,
    DUAL_ANODE_HEAVY_PARTICLE_STREAM_I  : 7049,
    DUAL_ANODE_LIGHT_PARTICLE_STREAM_I  : 6637,
    DUAL_ANODE_PULSE_PARTICLE_STREAM_I  : 5221,
    DUAL_GIGA_BEAM_LASER_I  : 20446,
    DUAL_GIGA_BEAM_LASER_I_BLUEPRINT  : 20447,
    DUAL_GIGA_BEAM_LASER_II  : 37299,
    DUAL_GIGA_PULSE_LASER_I  : 20444,
    DUAL_GIGA_PULSE_LASER_I_BLUEPRINT  : 20445,
    DUAL_GIGA_PULSE_LASER_II  : 37298,
    DUAL_HEAVY_AFOCAL_PULSE_MASER_I  : 7001,
    DUAL_HEAVY_ANODE_PULSE_PARTICLE_STREAM_I  : 7005,
    DUAL_HEAVY_BEAM_LASER_I  : 461,
    DUAL_HEAVY_BEAM_LASER_I_BLUEPRINT  : 845,
    DUAL_HEAVY_BEAM_LASER_II  : 2985,
    DUAL_HEAVY_MODAL_PULSE_LASER_I  : 6999,
    DUAL_HEAVY_MODULATED_PULSE_ENERGY_BEAM_I  : 7003,
    DUAL_HEAVY_PULSE_LASER_I  : 460,
    DUAL_HEAVY_PULSE_LASER_I_BLUEPRINT  : 844,
    DUAL_HEAVY_PULSE_LASER_II  : 4147,
    DUAL_LIGHT_BEAM_LASER_I  : 452,
    DUAL_LIGHT_BEAM_LASER_I_BLUEPRINT  : 836,
    DUAL_LIGHT_BEAM_LASER_II  : 2993,
    DUAL_LIGHT_PULSE_LASER_I  : 451,
    DUAL_LIGHT_PULSE_LASER_I_BLUEPRINT  : 835,
    DUAL_LIGHT_PULSE_LASER_II  : 3001,
    DUAL_MODAL_HEAVY_LASER_I  : 7043,
    DUAL_MODAL_LIGHT_LASER_I  : 6631,
    DUAL_MODAL_PULSE_LASER_I  : 5215,
    DUAL_MODULATED_HEAVY_ENERGY_BEAM_I  : 7047,
    DUAL_MODULATED_LIGHT_ENERGY_BEAM_I  : 6635,
    DUAL_MODULATED_PULSE_ENERGY_BEAM_I  : 5219,
    DUCIA_FOUNDRY_STRONG_BOX  : 43682,
    DUCINIUM  : 74533,
    DUNK_SALVAGE_DRONE  : 55761,
    DUNK_SALVAGE_DRONE_BLUEPRINT  : 55763,
    DYAD_CO_PROCESSOR_I  : 20567,
    DYSPORITE  : 16668,
    DYSPORITE_REACTION_FORMULA  : 46170,
    DYSPROSIUM  : 16650,
    EAGLE  : 12011,
    EAGLE_ACE_OF_PODHUNTERS_SKIN  : 63204,
    EAGLE_BIOSECURITY_RESPONDERS_SKIN  : 55566,
    EAGLE_BLUE_TIGER_SKIN  : 36726,
    EAGLE_BLUEPRINT  : 12012,
    EAGLE_CORDITE_BLOSSOM_SKIN  : 48833,
    EAGLE_EROS_BLOSSOM_SKIN  : 50074,
    EAGLE_EXOPLANETS_HUNTER_SKIN  : 45838,
    EAGLE_GHOSTBIRD_SKIN  : 48181,
    EAGLE_GILDED_PREDATOR_SKIN  : 77178,
    EAGLE_GLACIAL_DRIFT_SKIN  : 46842,
    EAGLE_HEARTSURGE_SKIN  : 53956,
    EAGLE_KIMOTORO_ESKEITAN_SKIN  : 52634,
    EAGLE_MATIGU_SEABEAST_SKIN  : 40602,
    EAGLE_RAATA_SUNSET_SKIN  : 36654,
    EAGLE_STATE_POLICE_SKIN  : 60030,
    EAGLE_STEEL_CARDINAL_SKIN  : 46115,
    EAGLE_VITALSHIFT_EGO_SKIN  : 52558,
    EAGLE_WIYRKOMI_SKIN  : 36346,
    EAGLE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53175,
    EAGLE_ZYDRINE_BURN_SKIN  : 76955,
    EASY_PLACEHOLDER  : 45675,
    EASYBLUEPRINT_PLACEHOLDER  : 45676,
    ECCM_SCRIPT  : 41155,
    ECCM_SCRIPT_BLUEPRINT  : 41230,
    ECHELON  : 3532,
    ECHO  : 617,
    ECM_JAMMER_BURST_PROJECTOR  : 27678,
    ECM_JAMMER_BURST_PROJECTOR_BLUEPRINT  : 27679,
    ECTOPLASM  : 3771,
    ECTOPLASM_SKIMMER_DISPLAY  : 56978,
    EDENCOM_BATTLESHIP  : 54794,
    EDENCOM_CRUISER  : 55032,
    EDENCOM_CRUISER_OLD  : 54793,
    EDENCOM_FRIGATE  : 55031,
    EDENCOM_FRIGATE_OLD  : 54790,
    EFFA_COMPACT_ASSAULT_DAMAGE_CONTROL  : 47255,
    EGDLI_2_ABYSSAL_TIER_1_OMEGA  : 55839,
    EGDLI_2_ABYSSAL_TIER_2_OMEGA  : 55840,
    EGDLI_2_ABYSSAL_TIER_4_ALPHA  : 55911,
    EGDLI_2_BOOSTERS_TIER_1_ALPHA  : 55902,
    EGDLI_2_BOOSTERS_TIER_1_OMEGA  : 55836,
    EGDLI_2_BOOSTERS_TIER_2_ALPHA  : 55903,
    EGDLI_2_BOOSTERS_TIER_2_OMEGA  : 55837,
    EGDLI_2_BOOSTERS_TIER_3_ALPHA  : 55904,
    EGDLI_2_BOOSTERS_TIER_3_OMEGA  : 55838,
    EGDLI_2_BPC_TIER_1_ALPHA  : 55905,
    EGDLI_2_BPC_TIER_1_OMEGA  : 55841,
    EGDLI_2_BPC_TIER_2_ALPHA  : 55906,
    EGDLI_2_BPC_TIER_2_OMEGA  : 55842,
    EGDLI_2_BPC_TIER_3_ALPHA  : 55907,
    EGDLI_2_BPC_TIER_3_OMEGA  : 55843,
    EGDLI_2_SKIN_TIER_1_ALPHA  : 55908,
    EGDLI_2_SKIN_TIER_1_OMEGA  : 55844,
    EGDLI_2_SKIN_TIER_2_ALPHA  : 55909,
    EGDLI_2_SKIN_TIER_2_OMEGA  : 55845,
    EGDLI_2_SKIN_TIER_3_ALPHA  : 55910,
    EGDLI_2_SKIN_TIER_3_OMEGA  : 55846,
    EICO_REDARC_REDWIRE_XVI_AUGMENTATIONS_CRATE  : 52299,
    EIFYR_AND_CO_ALCHEMIST_BIOLOGY_BY_805  : 27147,
    EIFYR_AND_CO_ALCHEMIST_BIOLOGY_BY_810  : 27148,
    EIFYR_AND_CO_ALCHEMIST_GAS_HARVESTING_GH_801  : 27240,
    EIFYR_AND_CO_ALCHEMIST_GAS_HARVESTING_GH_803  : 27238,
    EIFYR_AND_CO_ALCHEMIST_GAS_HARVESTING_GH_805  : 27239,
    EIFYR_AND_CO_ALCHEMIST_NEUROTOXIN_CONTROL_NC_903  : 25545,
    EIFYR_AND_CO_ALCHEMIST_NEUROTOXIN_CONTROL_NC_905  : 25546,
    EIFYR_AND_CO_ALCHEMIST_NEUROTOXIN_RECOVERY_NR_1003  : 25547,
    EIFYR_AND_CO_ALCHEMIST_NEUROTOXIN_RECOVERY_NR_1005  : 25548,
    EIFYR_AND_CO_GUNSLINGER_LARGE_PROJECTILE_TURRET_LP_1001  : 27090,
    EIFYR_AND_CO_GUNSLINGER_LARGE_PROJECTILE_TURRET_LP_1002  : 3196,
    EIFYR_AND_CO_GUNSLINGER_LARGE_PROJECTILE_TURRET_LP_1003  : 13219,
    EIFYR_AND_CO_GUNSLINGER_LARGE_PROJECTILE_TURRET_LP_1004  : 3197,
    EIFYR_AND_CO_GUNSLINGER_LARGE_PROJECTILE_TURRET_LP_1005  : 19688,
    EIFYR_AND_CO_GUNSLINGER_LARGE_PROJECTILE_TURRET_LP_1006  : 3198,
    EIFYR_AND_CO_GUNSLINGER_MEDIUM_PROJECTILE_TURRET_MP_801  : 27087,
    EIFYR_AND_CO_GUNSLINGER_MEDIUM_PROJECTILE_TURRET_MP_802  : 3185,
    EIFYR_AND_CO_GUNSLINGER_MEDIUM_PROJECTILE_TURRET_MP_803  : 13222,
    EIFYR_AND_CO_GUNSLINGER_MEDIUM_PROJECTILE_TURRET_MP_804  : 3188,
    EIFYR_AND_CO_GUNSLINGER_MEDIUM_PROJECTILE_TURRET_MP_805  : 19689,
    EIFYR_AND_CO_GUNSLINGER_MEDIUM_PROJECTILE_TURRET_MP_806  : 3189,
    EIFYR_AND_CO_GUNSLINGER_MOTION_PREDICTION_MR_701  : 27075,
    EIFYR_AND_CO_GUNSLINGER_MOTION_PREDICTION_MR_702  : 3190,
    EIFYR_AND_CO_GUNSLINGER_MOTION_PREDICTION_MR_703  : 9957,
    EIFYR_AND_CO_GUNSLINGER_MOTION_PREDICTION_MR_704  : 3191,
    EIFYR_AND_CO_GUNSLINGER_MOTION_PREDICTION_MR_705  : 19686,
    EIFYR_AND_CO_GUNSLINGER_MOTION_PREDICTION_MR_706  : 3192,
    EIFYR_AND_CO_GUNSLINGER_SMALL_PROJECTILE_TURRET_SP_601  : 27084,
    EIFYR_AND_CO_GUNSLINGER_SMALL_PROJECTILE_TURRET_SP_602  : 3199,
    EIFYR_AND_CO_GUNSLINGER_SMALL_PROJECTILE_TURRET_SP_603  : 13225,
    EIFYR_AND_CO_GUNSLINGER_SMALL_PROJECTILE_TURRET_SP_604  : 3200,
    EIFYR_AND_CO_GUNSLINGER_SMALL_PROJECTILE_TURRET_SP_605  : 19690,
    EIFYR_AND_CO_GUNSLINGER_SMALL_PROJECTILE_TURRET_SP_606  : 3201,
    EIFYR_AND_CO_GUNSLINGER_SURGICAL_STRIKE_SS_901  : 27081,
    EIFYR_AND_CO_GUNSLINGER_SURGICAL_STRIKE_SS_902  : 3193,
    EIFYR_AND_CO_GUNSLINGER_SURGICAL_STRIKE_SS_903  : 13244,
    EIFYR_AND_CO_GUNSLINGER_SURGICAL_STRIKE_SS_904  : 3194,
    EIFYR_AND_CO_GUNSLINGER_SURGICAL_STRIKE_SS_905  : 19687,
    EIFYR_AND_CO_GUNSLINGER_SURGICAL_STRIKE_SS_906  : 3195,
    EIFYR_AND_CO_ROGUE_ACCELERATION_CONTROL_AC_601  : 27101,
    EIFYR_AND_CO_ROGUE_ACCELERATION_CONTROL_AC_602  : 3120,
    EIFYR_AND_CO_ROGUE_ACCELERATION_CONTROL_AC_603  : 16008,
    EIFYR_AND_CO_ROGUE_ACCELERATION_CONTROL_AC_604  : 3121,
    EIFYR_AND_CO_ROGUE_ACCELERATION_CONTROL_AC_605  : 16009,
    EIFYR_AND_CO_ROGUE_ACCELERATION_CONTROL_AC_606  : 3124,
    EIFYR_AND_CO_ROGUE_AFTERBURNER_AB_602  : 27111,
    EIFYR_AND_CO_ROGUE_AFTERBURNER_AB_604  : 3104,
    EIFYR_AND_CO_ROGUE_AFTERBURNER_AB_606  : 13239,
    EIFYR_AND_CO_ROGUE_AFTERBURNER_AB_608  : 3105,
    EIFYR_AND_CO_ROGUE_AFTERBURNER_AB_610  : 27110,
    EIFYR_AND_CO_ROGUE_AFTERBURNER_AB_612  : 3108,
    EIFYR_AND_CO_ROGUE_EVASIVE_MANEUVERING_EM_701  : 27099,
    EIFYR_AND_CO_ROGUE_EVASIVE_MANEUVERING_EM_702  : 3093,
    EIFYR_AND_CO_ROGUE_EVASIVE_MANEUVERING_EM_703  : 13240,
    EIFYR_AND_CO_ROGUE_EVASIVE_MANEUVERING_EM_704  : 3094,
    EIFYR_AND_CO_ROGUE_EVASIVE_MANEUVERING_EM_705  : 16004,
    EIFYR_AND_CO_ROGUE_EVASIVE_MANEUVERING_EM_706  : 3095,
    EIFYR_AND_CO_ROGUE_FUEL_CONSERVATION_FC_801  : 27098,
    EIFYR_AND_CO_ROGUE_FUEL_CONSERVATION_FC_802  : 3101,
    EIFYR_AND_CO_ROGUE_FUEL_CONSERVATION_FC_803  : 13238,
    EIFYR_AND_CO_ROGUE_FUEL_CONSERVATION_FC_804  : 3102,
    EIFYR_AND_CO_ROGUE_FUEL_CONSERVATION_FC_805  : 16005,
    EIFYR_AND_CO_ROGUE_FUEL_CONSERVATION_FC_806  : 3103,
    EIFYR_AND_CO_ROGUE_HIGH_SPEED_MANEUVERING_HS_901  : 27100,
    EIFYR_AND_CO_ROGUE_HIGH_SPEED_MANEUVERING_HS_902  : 3112,
    EIFYR_AND_CO_ROGUE_HIGH_SPEED_MANEUVERING_HS_903  : 13243,
    EIFYR_AND_CO_ROGUE_HIGH_SPEED_MANEUVERING_HS_904  : 3113,
    EIFYR_AND_CO_ROGUE_HIGH_SPEED_MANEUVERING_HS_905  : 16006,
    EIFYR_AND_CO_ROGUE_HIGH_SPEED_MANEUVERING_HS_906  : 3116,
    EIFYR_AND_CO_ROGUE_NAVIGATION_NN_601  : 27097,
    EIFYR_AND_CO_ROGUE_NAVIGATION_NN_602  : 3096,
    EIFYR_AND_CO_ROGUE_NAVIGATION_NN_603  : 13237,
    EIFYR_AND_CO_ROGUE_NAVIGATION_NN_604  : 3097,
    EIFYR_AND_CO_ROGUE_NAVIGATION_NN_605  : 16003,
    EIFYR_AND_CO_ROGUE_NAVIGATION_NN_606  : 3100,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_OPERATION_WD_602  : 27113,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_OPERATION_WD_604  : 3109,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_OPERATION_WD_606  : 13241,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_OPERATION_WD_608  : 3110,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_OPERATION_WD_610  : 27112,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_OPERATION_WD_612  : 3111,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_SPEED_WS_605  : 27115,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_SPEED_WS_608  : 3117,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_SPEED_WS_610  : 13242,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_SPEED_WS_613  : 3118,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_SPEED_WS_615  : 27114,
    EIFYR_AND_CO_ROGUE_WARP_DRIVE_SPEED_WS_618  : 3119,
    EIFYRIUM  : 74529,
    EINHERJI_I  : 23061,
    EINHERJI_I_BLUEPRINT  : 23062,
    EINHERJI_II  : 40559,
    ELARA_RESTRAINED_MINING_LASER_UPGRADE  : 22611,
    ELECTRIC_CONDUIT  : 21592,
    ELECTRO_NEURAL_SIGNALLER  : 57450,
    ELECTROLYTES  : 2390,
    ELECTROLYTIC_CAPACITOR_UNIT  : 11551,
    ELECTROLYTIC_CAPACITOR_UNIT_BLUEPRINT  : 17347,
    ELECTROMAGNETIC_METAPRISMATIC_SHEETING  : 54970,
    ELECTROMAGNETIC_PHYSICS  : 11448,
    ELECTROMECHANICAL_HULL_SHEETING  : 30254,
    ELECTROMECHANICAL_INTERFACE_NEXUS  : 30466,
    ELECTROMECHANICAL_INTERFACE_NEXUS_BLUEPRINT  : 30467,
    ELECTRON_BLASTER_CANNON_I  : 571,
    ELECTRON_BLASTER_CANNON_I_BLUEPRINT  : 1122,
    ELECTRON_BLASTER_CANNON_II  : 3122,
    ELECTRON_BOMB  : 27920,
    ELECTRON_BOMB_BLUEPRINT  : 27921,
    ELECTRONIC_ATTACK_SHIPS  : 28615,
    ELECTRONIC_ENGINEERING  : 11453,
    ELECTRONIC_HARDENING_CHARGE  : 42837,
    ELECTRONIC_HARDENING_CHARGE_BLUEPRINT  : 42877,
    ELECTRONIC_LINK  : 21587,
    ELECTRONIC_PARTS  : 3687,
    ELECTRONIC_SUPERIORITY_CHARGE  : 42836,
    ELECTRONIC_SUPERIORITY_CHARGE_BLUEPRINT  : 42878,
    ELECTRONIC_SUPERIORITY_RIGGING  : 26256,
    ELECTRONIC_WARFARE  : 3427,
    ELECTRONICS_UPGRADES  : 3432,
    ELECTROPUNCH_ULTRA_L  : 54783,
    ELECTROPUNCH_ULTRA_M  : 54786,
    ELECTROPUNCH_ULTRA_S  : 54784,
    ELEMENTAL_CRUX  : 23161,
    ELENA_GAZKYS_DNA  : 17624,
    ELITE_DRONE_AI  : 21815,
    ELITE_INFOMORPH_PSYCHOLOGY  : 73910,
    ELITE_SLAVES  : 17796,
    EM_ARMOR_COMPENSATION  : 22806,
    EM_ARMOR_HARDENER_I  : 11301,
    EM_ARMOR_HARDENER_I_BLUEPRINT  : 11302,
    EM_ARMOR_HARDENER_II  : 11642,
    EM_COATING_I  : 1197,
    EM_COATING_I_BLUEPRINT  : 1204,
    EM_COATING_II  : 1198,
    EM_ENERGIZED_MEMBRANE_I  : 11217,
    EM_ENERGIZED_MEMBRANE_I_BLUEPRINT  : 11218,
    EM_ENERGIZED_MEMBRANE_II  : 11219,
    EM_PULSE_GENERATOR  : 11694,
    EM_PULSE_GENERATOR_BLUEPRINT  : 17335,
    EM_SHIELD_AMPLIFIER_I  : 1808,
    EM_SHIELD_AMPLIFIER_I_BLUEPRINT  : 1809,
    EM_SHIELD_AMPLIFIER_II  : 2553,
    EM_SHIELD_COMPENSATION  : 12365,
    EM_SHIELD_HARDENER_I  : 2293,
    EM_SHIELD_HARDENER_I_BLUEPRINT  : 2294,
    EM_SHIELD_HARDENER_II  : 2301,
    EMERGENT_COMBAT_ANALYZER  : 30248,
    EMERGENT_COMBAT_INTELLIGENCE  : 30271,
    EMERGENT_NEUROVISUAL_INTERFACE  : 29996,
    EMERGENT_NEUROVISUAL_INTERFACE_BLUEPRINT  : 29997,
    EMP_L  : 201,
    EMP_L_BLUEPRINT  : 902,
    EMP_M  : 193,
    EMP_M_BLUEPRINT  : 894,
    EMP_S  : 185,
    EMP_S_BLUEPRINT  : 886,
    EMP_XL  : 17668,
    EMP_XL_BLUEPRINT  : 17669,
    EMPIRE_CIVILIAN_CONTAINER  : 43640,
    EMPIRE_CONTROL  : 3732,
    ENDURANCE  : 37135,
    ENDURANCE_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43750,
    ENDURANCE_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43803,
    ENDURANCE_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43816,
    ENDURANCE_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43763,
    ENDURANCE_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43777,
    ENDURANCE_GLACIAL_DRIFT_SKIN  : 46982,
    ENDURANCE_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43829,
    ENDURANCE_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43790,
    ENDURANCE_MORPHITE_SHINE_SKIN  : 42615,
    ENDURANCE_PAYDIRT_PROSPECTOR_SKIN  : 42672,
    ENDURANCE_RADIOACTIVES_RECLAMATION_SKIN  : 60945,
    ENDURANCE_ROCKBREAKER_PIONEERS_SKIN  : 56607,
    ENDURANCE_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43842,
    ENDURING_COMPOUNDS  : 41302,
    ENDURING_CONDUCTORS  : 41303,
    ENDURING_ELECTRONICS  : 41269,
    ENDURING_EM_SHIELD_HARDENER  : 54292,
    ENDURING_EXPLOSIVE_SHIELD_HARDENER  : 54293,
    ENDURING_INTERDICTION_NULLIFIER  : 58971,
    ENDURING_INTERDICTION_NULLIFIER_BLUEPRINT  : 58974,
    ENDURING_KINETIC_SHIELD_HARDENER  : 54294,
    ENDURING_MULTISPECTRUM_SHIELD_HARDENER  : 54291,
    ENDURING_THERMAL_SHIELD_HARDENER  : 54295,
    ENERGY_CELLS  : 21594,
    ENERGY_GRID_UPGRADES  : 3424,
    ENERGY_NEUTRALIZATION_BURST_PROJECTOR  : 40636,
    ENERGY_NEUTRALIZATION_BURST_PROJECTOR_BLUEPRINT  : 41390,
    ENERGY_NEUTRALIZING_BATTERY  : 27672,
    ENERGY_NEUTRALIZING_BATTERY_BLUEPRINT  : 2799,
    ENERGY_PULSE_WEAPONS  : 3421,
    ENERGY_WEAPON_RIGGING  : 26258,
    ENFEEBLING_SCOPED_LADAR_ECM  : 19939,
    ENFORCER  : 44995,
    ENFORCER___CALM_ABYSSAL_FILAMENTS_CRATE  : 63787,
    ENFORCER___DEFENSE_SYSTEM_SKILL_CRATE  : 63781,
    ENFORCER___DESTROYER_SKILL_CRATE  : 63778,
    ENFORCER___FRIGATE_CRATE  : 63780,
    ENFORCER___FRIGATE_CRATE2  : 63782,
    ENFORCER___FRIGATE_SKILL_CRATE  : 63779,
    ENFORCER___TRANQUIL_ABYSSAL_FILAMENTS_CRATE  : 63786,
    ENFORCER_BIOSECURITY_RESPONDERS_SKIN  : 55296,
    ENFORCER_BLACKICE_DERAILERS_SKIN  : 57784,
    ENFORCER_CONCORD_AEROSPACE_PROTOTYPE_SKIN  : 45480,
    ENFORCER_DED_ALPHA_PLUS_TASKFORCE_SKIN  : 48580,
    ENFORCER_DED_ARMY_RESERVE_SKIN  : 54217,
    ENFORCER_DED_MASTER_AT_ARMS_RECRUITER_SKIN  : 48577,
    ENFORCER_EXOPLANETS_HUNTER_SKIN  : 45992,
    ENFORCER_FIREWATCH_SKIN  : 46020,
    ENFORCER_GLACIAL_DRIFT_SKIN  : 47000,
    ENFORCER_SARO_BLACK_TROOP_SKIN  : 47681,
    ENHANCED_ELECTRO_NEURAL_SIGNALLER  : 57451,
    ENHANCED_NEUROLINK_PROTECTION_CELL  : 57489,
    ENHANCED_NEUROLINK_PROTECTION_CELL_BLUEPRINT  : 57526,
    ENHANCED_WARD_CONSOLE  : 25625,
    ENORMOUS_FREIGHT_CONTAINER  : 33003,
    ENORMOUS_FREIGHT_CONTAINER_BLUEPRINT  : 33004,
    ENRICHED_CLEAR_ICICLE  : 17978,
    ENRICHED_URANIUM  : 44,
    ENTERPRISE_REMOTE_TRACKING_COMPUTER  : 22937,
    ENTOSIS_LINK_I  : 34593,
    ENTOSIS_LINK_I_BLUEPRINT  : 34594,
    ENTOSIS_LINK_II  : 34595,
    ENTRAPMENT_ARRAY_1  : 2031,
    ENTRAPMENT_ARRAY_1_BLUEPRINT  : 34869,
    ENTRAPMENT_ARRAY_2  : 2034,
    ENTRAPMENT_ARRAY_2_BLUEPRINT  : 34841,
    ENTRAPMENT_ARRAY_3  : 2035,
    ENTRAPMENT_ARRAY_3_BLUEPRINT  : 34842,
    ENTRAPMENT_ARRAY_4  : 2036,
    ENTRAPMENT_ARRAY_4_BLUEPRINT  : 34843,
    ENTRAPMENT_ARRAY_5  : 2037,
    ENTRAPMENT_ARRAY_5_BLUEPRINT  : 34844,
    ENTROPIC_RADIATION_SINK_I  : 47908,
    ENTROPIC_RADIATION_SINK_I_BLUEPRINT  : 48095,
    ENTROPIC_RADIATION_SINK_II  : 47911,
    ENYO  : 12044,
    ENYO_BIOSECURITY_RESPONDERS_SKIN  : 55630,
    ENYO_BLUEPRINT  : 12045,
    ENYO_EXOPLANETS_HUNTER_SKIN  : 45877,
    ENYO_GLACIAL_DRIFT_SKIN  : 46929,
    ENYO_GLITTERING_DREAM_SKIN  : 47524,
    ENYO_INNER_ZONE_VANGUARD_SKIN  : 52712,
    ENYO_INTAKI_SYNDICATE_SKIN  : 36389,
    ENYO_KOPIS_EDGE_SKIN  : 46389,
    ENYO_SERPENTIS_SKIN  : 42153,
    ENYO_SPIRIT_SKIN  : 44895,
    ENYO_VALIMOR_LEGACY_SKIN  : 37553,
    EOS  : 22442,
    EOS_BIOSECURITY_RESPONDERS_SKIN  : 55622,
    EOS_BLUEPRINT  : 22443,
    EOS_EXOPLANETS_HUNTER_SKIN  : 45909,
    EOS_GLACIAL_DRIFT_SKIN  : 46955,
    EOS_INNER_ZONE_VANGUARD_SKIN  : 52744,
    EOS_INTAKI_SYNDICATE_SKIN  : 36398,
    EOS_KOPIS_EDGE_SKIN  : 46421,
    EOS_SERPENTIS_SKIN  : 42181,
    EOS_SPIRIT_SKIN  : 44927,
    EOS_VALIMOR_LEGACY_SKIN  : 37577,
    EOS_ZYDRINE_BURN_SKIN  : 76953,
    EP_S_GAUSSIAN_SCOPED_MINING_LASER  : 5239,
    EPITHAL  : 655,
    EPITHAL_BIOSECURITY_RESPONDERS_SKIN  : 55589,
    EPITHAL_BLUEPRINT  : 990,
    EPITHAL_EXOPLANETS_HUNTER_SKIN  : 45922,
    EPITHAL_GLACIAL_DRIFT_SKIN  : 46968,
    EPITHAL_INNER_ZONE_VANGUARD_SKIN  : 52757,
    EPITHAL_KOPIS_EDGE_SKIN  : 46434,
    EPITHAL_PLANETARY_EXTRACTION  : 48656,
    EPITHAL_RUBY_SUNGRAZER_SKIN  : 45575,
    EPITHAL_SERPENTIS_SKIN  : 42191,
    EPITHAL_SPIRIT_SKIN  : 44940,
    EPITHAL_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 64392,
    EPITHAL_VALIMOR_LEGACY_SKIN  : 37587,
    EQUIPMENT_ASSEMBLY_ARRAY  : 13780,
    EQUITE_I  : 40358,
    EQUITE_I_BLUEPRINT  : 41376,
    EQUITE_II  : 40552,
    EREBUS  : 671,
    EREBUS_BIOSECURITY_RESPONDERS_SKIN  : 55572,
    EREBUS_BLUEPRINT  : 1002,
    EREBUS_CAILLE_NEON_SKIN  : 50152,
    EREBUS_CAPSULEER_ELITE_XV_SKIN  : 47643,
    EREBUS_DUVOLLE_SKIN  : 34674,
    EREBUS_EROS_BLOSSOM_SKIN  : 50079,
    EREBUS_EXOPLANETS_HUNTER_SKIN  : 45921,
    EREBUS_FEDERAL_POLICE_SKIN  : 52440,
    EREBUS_GLACIAL_DRIFT_SKIN  : 46967,
    EREBUS_GLITTERING_DREAM_SKIN  : 47540,
    EREBUS_HEADHUNTER_SKIN  : 49428,
    EREBUS_INNER_ZONE_VANGUARD_SKIN  : 52756,
    EREBUS_INTAKI_SYNDICATE_SKIN  : 36375,
    EREBUS_INTERBUS_SKIN  : 34675,
    EREBUS_INTERSTELLAR_CONVERGENCE_SKIN  : 62231,
    EREBUS_KOPIS_EDGE_SKIN  : 46433,
    EREBUS_LUMINAIRE_RISING_SKIN  : 64574,
    EREBUS_RUBY_SUNGRAZER_SKIN  : 45582,
    EREBUS_SAPPHIRE_SUNGRAZER_SKIN  : 46551,
    EREBUS_SERPENTIS_SKIN  : 42190,
    EREBUS_SPIRIT_SKIN  : 44939,
    EREBUS_VALIMOR_LEGACY_SKIN  : 37586,
    EREBUS_YOIUL_STAR_SKIN  : 53431,
    ERIS  : 22460,
    ERIS_AURORA_UNIVERSALIS_SKIN  : 57011,
    ERIS_BIOSECURITY_RESPONDERS_SKIN  : 55628,
    ERIS_BLUEPRINT  : 22461,
    ERIS_EXOPLANETS_HUNTER_SKIN  : 45890,
    ERIS_GLACIAL_DRIFT_SKIN  : 46940,
    ERIS_INNER_ZONE_VANGUARD_SKIN  : 52725,
    ERIS_INTAKI_SYNDICATE_SKIN  : 36399,
    ERIS_KOPIS_EDGE_SKIN  : 46402,
    ERIS_SERPENTIS_SKIN  : 42164,
    ERIS_SPIRIT_SKIN  : 44908,
    ERIS_VALIMOR_LEGACY_SKIN  : 37562,
    EROS_BLOSSOM_FIREWORK  : 47264,
    ESTAMEL_THARCHONS_TAG  : 17295,
    ESTAMELS_MODIFIED_350MM_RAILGUN  : 14395,
    ESTAMELS_MODIFIED_425MM_RAILGUN  : 14405,
    ESTAMELS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14690,
    ESTAMELS_MODIFIED_CLOAKING_DEVICE  : 14782,
    ESTAMELS_MODIFIED_CO_PROCESSOR  : 14774,
    ESTAMELS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14678,
    ESTAMELS_MODIFIED_DUAL_250MM_RAILGUN  : 14415,
    ESTAMELS_MODIFIED_EM_SHIELD_AMPLIFIER  : 14746,
    ESTAMELS_MODIFIED_EM_SHIELD_HARDENER  : 14755,
    ESTAMELS_MODIFIED_EXPLOSIVE_SHIELD_AMPLIFIER  : 14722,
    ESTAMELS_MODIFIED_EXPLOSIVE_SHIELD_HARDENER  : 14759,
    ESTAMELS_MODIFIED_KINETIC_SHIELD_AMPLIFIER  : 14738,
    ESTAMELS_MODIFIED_KINETIC_SHIELD_HARDENER  : 14751,
    ESTAMELS_MODIFIED_LARGE_GRAVITON_SMARTBOMB  : 14698,
    ESTAMELS_MODIFIED_LARGE_SHIELD_BOOSTER  : 14703,
    ESTAMELS_MODIFIED_MULTISPECTRAL_ECM  : 20207,
    ESTAMELS_MODIFIED_MULTISPECTRUM_SHIELD_HARDENER  : 14767,
    ESTAMELS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33454,
    ESTAMELS_MODIFIED_SHIELD_BOOST_AMPLIFIER  : 14714,
    ESTAMELS_MODIFIED_THERMAL_SHIELD_AMPLIFIER  : 14730,
    ESTAMELS_MODIFIED_THERMAL_SHIELD_HARDENER  : 14763,
    ESTAMELS_MODIFIED_TORPEDO_LAUNCHER  : 14683,
    ESTAMELS_MODIFIED_X_LARGE_SHIELD_BOOSTER  : 14707,
    ETANA  : 32790,
    EUTECTIC_COMPACT_CAP_RECHARGER  : 4435,
    EUXENITE  : 45495,
    EVAPORITE_DEPOSITS  : 16635,
    EVASIVE_MANEUVERING  : 3453,
    EVASIVE_MANEUVERS_CHARGE  : 42838,
    EVASIVE_MANEUVERS_CHARGE_BLUEPRINT  : 42886,
    EXCAVATOR_ICE_HARVESTING_DRONE  : 43681,
    EXCAVATOR_MINING_DRONE  : 41030,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_A_I  : 46371,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 46361,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_A_II  : 46372,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_B_I  : 61213,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 61233,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_B_II  : 61215,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_C_I  : 61214,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 61234,
    EXCEPTIONAL_MOON_MINING_CRYSTAL_TYPE_C_II  : 61216,
    EXCEPTIONAL_MOON_ORE_PROCESSING  : 46156,
    EXECUTIONER  : 589,
    EXECUTIONER_ARDISHAPUR_SKIN  : 34721,
    EXECUTIONER_AURORA_UNIVERSALIS_SKIN  : 53369,
    EXECUTIONER_BIOSECURITY_RESPONDERS_SKIN  : 55319,
    EXECUTIONER_BLOOD_RAIDERS_SKIN  : 36914,
    EXECUTIONER_BLUEPRINT  : 936,
    EXECUTIONER_CAPSULEER_DAY_XV_SKIN  : 47626,
    EXECUTIONER_COLD_IRON_SKIN  : 42747,
    EXECUTIONER_EXOPLANETS_HUNTER_SKIN  : 45754,
    EXECUTIONER_GLACIAL_DRIFT_SKIN  : 44135,
    EXECUTIONER_IRONBLOOD_SKIN  : 43483,
    EXECUTIONER_KADOR_SKIN  : 34722,
    EXECUTIONER_OASIS_OF_FAITH_SKIN  : 53596,
    EXECUTIONER_PURITY_OF_THE_THRONE_SKIN  : 42545,
    EXECUTIONER_RAATA_SUNSET_SKIN  : 37485,
    EXECUTIONER_VERSUS_BLUEFORCE_SKIN  : 63143,
    EXECUTIONER_ZAKURA_HANSEI_SKIN  : 57183,
    EXECUTIVE_REMOTE_SENSOR_DAMPENER  : 22945,
    EXEQUROR  : 634,
    EXEQUROR_AURORA_UNIVERSALIS_SKIN  : 60916,
    EXEQUROR_BIOSECURITY_RESPONDERS_SKIN  : 55574,
    EXEQUROR_BLUEPRINT  : 979,
    EXEQUROR_COMBAT_MEDIC_SECTION_SKIN  : 48721,
    EXEQUROR_EXOPLANETS_HUNTER_SKIN  : 45893,
    EXEQUROR_GALNET_STREAMCAST_UNIT_SKIN  : 76491,
    EXEQUROR_GLACIAL_DRIFT_SKIN  : 44182,
    EXEQUROR_HUNTERS_QUIVER_SKIN  : 47502,
    EXEQUROR_INNER_ZONE_VANGUARD_SKIN  : 52728,
    EXEQUROR_INTAKI_SYNDICATE_SKIN  : 34691,
    EXEQUROR_KOPIS_EDGE_SKIN  : 46405,
    EXEQUROR_LUMINAIRE_ZENITH_SKIN  : 64400,
    EXEQUROR_NAVY_ISSUE  : 29344,
    EXEQUROR_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55610,
    EXEQUROR_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45903,
    EXEQUROR_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46949,
    EXEQUROR_NAVY_ISSUE_HUNTERS_QUIVER_SKIN  : 57778,
    EXEQUROR_NAVY_ISSUE_INNER_ZONE_VANGUARD_SKIN  : 52738,
    EXEQUROR_NAVY_ISSUE_INTAKI_SYNDICATE_SKIN  : 36406,
    EXEQUROR_NAVY_ISSUE_KOPIS_EDGE_SKIN  : 46415,
    EXEQUROR_NAVY_ISSUE_SAPPHIRE_SUNGRAZER_SKIN  : 45023,
    EXEQUROR_NAVY_ISSUE_SPIRIT_SKIN  : 44921,
    EXEQUROR_SERPENTIS_SKIN  : 42167,
    EXEQUROR_SPIRIT_SKIN  : 44911,
    EXEQUROR_VALIMOR_LEGACY_SKIN  : 37564,
    EXHUMERS  : 22551,
    EXIGENT_HEAVY_DRONE_DURABILITY_MUTAPLASMID  : 60465,
    EXIGENT_HEAVY_DRONE_FIREPOWER_MUTAPLASMID  : 60464,
    EXIGENT_HEAVY_DRONE_NAVIGATION_MUTAPLASMID  : 60463,
    EXIGENT_HEAVY_DRONE_PROJECTION_MUTAPLASMID  : 60466,
    EXIGENT_LIGHT_DRONE_DURABILITY_MUTAPLASMID  : 60462,
    EXIGENT_LIGHT_DRONE_FIREPOWER_MUTAPLASMID  : 60461,
    EXIGENT_LIGHT_DRONE_NAVIGATION_MUTAPLASMID  : 60460,
    EXIGENT_LIGHT_DRONE_PROJECTION_MUTAPLASMID  : 60471,
    EXIGENT_MEDIUM_DRONE_DURABILITY_MUTAPLASMID  : 60474,
    EXIGENT_MEDIUM_DRONE_FIREPOWER_MUTAPLASMID  : 60473,
    EXIGENT_MEDIUM_DRONE_NAVIGATION_MUTAPLASMID  : 60472,
    EXIGENT_MEDIUM_DRONE_PROJECTION_MUTAPLASMID  : 60475,
    EXIGENT_SENTRY_DRONE_DURABILITY_MUTAPLASMID  : 60469,
    EXIGENT_SENTRY_DRONE_FIREPOWER_MUTAPLASMID  : 60468,
    EXIGENT_SENTRY_DRONE_PRECISION_MUTAPLASMID  : 60467,
    EXIGENT_SENTRY_DRONE_PROJECTION_MUTAPLASMID  : 60470,
    EXILE  : 3818,
    EXOPLANETS_HUNTER_REWARD_CRATE  : 45996,
    EXOTIC_DANCERS_FEMALE  : 17765,
    EXOTIC_DANCERS_MALE  : 4358,
    EXPANDED_CARGOHOLD_I  : 1317,
    EXPANDED_CARGOHOLD_I_BLUEPRINT  : 1318,
    EXPANDED_CARGOHOLD_II  : 1319,
    EXPANDED_PROBE_LAUNCHER_I  : 18639,
    EXPANDED_PROBE_LAUNCHER_I_BLUEPRINT  : 18640,
    EXPANDED_PROBE_LAUNCHER_II  : 4260,
    EXPEDITION_FRIGATES  : 33856,
    EXPERIMENTAL_ENDURING_EM_ARMOR_HARDENER_I  : 16357,
    EXPERIMENTAL_ENDURING_EXPLOSIVE_ARMOR_HARDENER_I  : 16365,
    EXPERIMENTAL_ENDURING_KINETIC_ARMOR_HARDENER_I  : 16373,
    EXPERIMENTAL_ENDURING_THERMAL_ARMOR_HARDENER_I  : 16381,
    EXPERIMENTAL_HYPERSPATIAL_ACCELERATOR  : 33983,
    EXPERIMENTAL_JUMP_DRIVE_ECONOMIZER  : 34124,
    EXPERIMENTAL_LABORATORY  : 24567,
    EXPERIMENTAL_MUTAPLASMID_REPOSITORY  : 48642,
    EXPERIMENTAL_SV_2000_RAPID_LIGHT_MISSILE_LAUNCHER  : 8007,
    EXPERIMENTAL_XT_2800_HEAVY_ASSAULT_MISSILE_LAUNCHER_I  : 25713,
    EXPERIMENTAL_ZW_4100_TORPEDO_LAUNCHER  : 8001,
    EXPERT_CEREBRAL_ACCELERATOR  : 55826,
    EXPIRED_BASIC_GURI_KIKHIVAA_CEREBRAL_ACCELERATOR  : 63633,
    EXPIRED_CURIOUS_WARP_MATRIX_FILAMENT  : 60633,
    EXPIRED_DANGEROUS_WARP_MATRIX_FILAMENT  : 62056,
    EXPIRED_ENIGMATIC_WARP_MATRIX_FILAMENT  : 61656,
    EXPIRED_EXTENDED_GURI_KIKHIVAA_CEREBRAL_ACCELERATOR  : 63635,
    EXPIRED_HAZARDOUS_WARP_MATRIX_FILAMENT  : 61655,
    EXPIRED_HUNT_DRONE_DAMAGE_BOOSTER_I  : 63669,
    EXPIRED_HUNT_DRONE_DAMAGE_BOOSTER_II  : 63670,
    EXPIRED_HUNT_DRONE_DAMAGE_BOOSTER_III  : 63671,
    EXPIRED_HUNT_DRONE_DAMAGE_BOOSTER_IV  : 63672,
    EXPIRED_HUNT_DRONE_PRECISION_BOOSTER_I  : 63661,
    EXPIRED_HUNT_DRONE_PRECISION_BOOSTER_II  : 63662,
    EXPIRED_HUNT_DRONE_PRECISION_BOOSTER_III  : 63663,
    EXPIRED_HUNT_DRONE_PRECISION_BOOSTER_IV  : 63664,
    EXPIRED_HUNT_MISSILE_DAMAGE_BOOSTER_I  : 63673,
    EXPIRED_HUNT_MISSILE_DAMAGE_BOOSTER_II  : 63674,
    EXPIRED_HUNT_MISSILE_DAMAGE_BOOSTER_III  : 63675,
    EXPIRED_HUNT_MISSILE_DAMAGE_BOOSTER_IV  : 63676,
    EXPIRED_HUNT_MISSILE_VELOCITY_BOOSTER_I  : 63665,
    EXPIRED_HUNT_MISSILE_VELOCITY_BOOSTER_II  : 63666,
    EXPIRED_HUNT_MISSILE_VELOCITY_BOOSTER_III  : 63667,
    EXPIRED_HUNT_MISSILE_VELOCITY_BOOSTER_IV  : 63668,
    EXPIRED_HUNT_PROBING_BOOSTER_I  : 63645,
    EXPIRED_HUNT_PROBING_BOOSTER_II  : 63646,
    EXPIRED_HUNT_PROBING_BOOSTER_III  : 63647,
    EXPIRED_HUNT_PROBING_BOOSTER_IV  : 63648,
    EXPIRED_HUNT_PURSUIT_BOOSTER_I  : 63649,
    EXPIRED_HUNT_PURSUIT_BOOSTER_II  : 63650,
    EXPIRED_HUNT_PURSUIT_BOOSTER_III  : 63651,
    EXPIRED_HUNT_PURSUIT_BOOSTER_IV  : 63652,
    EXPIRED_HUNT_RAPID_REPAIR_BOOSTER_I  : 63653,
    EXPIRED_HUNT_RAPID_REPAIR_BOOSTER_II  : 63654,
    EXPIRED_HUNT_RAPID_REPAIR_BOOSTER_III  : 63655,
    EXPIRED_HUNT_RAPID_REPAIR_BOOSTER_IV  : 63656,
    EXPIRED_HUNT_RESISTANCE_BOOSTER_I  : 63657,
    EXPIRED_HUNT_RESISTANCE_BOOSTER_II  : 63658,
    EXPIRED_HUNT_RESISTANCE_BOOSTER_III  : 63659,
    EXPIRED_HUNT_RESISTANCE_BOOSTER_IV  : 63660,
    EXPIRED_MYSTERIOUS_WARP_MATRIX_FILAMENT  : 62055,
    EXPIRED_PERILOUS_WARP_MATRIX_FILAMENT  : 62057,
    EXPIRED_POTENT_GURI_KIKHIVAA_CEREBRAL_ACCELERATOR  : 63634,
    EXPIRED_PRECARIOUS_WARP_MATRIX_FILAMENT  : 61654,
    EXPLORER___FRIGATE_CRATE  : 63771,
    EXPLORER___FRIGATE_SKILL_CRATE  : 63770,
    EXPLOSION_DAMPENING_ARRAY  : 17185,
    EXPLOSION_DAMPENING_ARRAY_BLUEPRINT  : 2757,
    EXPLOSIVE_ARMOR_COMPENSATION  : 22807,
    EXPLOSIVE_ARMOR_HARDENER_I  : 11303,
    EXPLOSIVE_ARMOR_HARDENER_I_BLUEPRINT  : 11304,
    EXPLOSIVE_ARMOR_HARDENER_II  : 11646,
    EXPLOSIVE_COATING_I  : 1264,
    EXPLOSIVE_COATING_I_BLUEPRINT  : 1265,
    EXPLOSIVE_COATING_II  : 1266,
    EXPLOSIVE_ENERGIZED_MEMBRANE_I  : 11227,
    EXPLOSIVE_ENERGIZED_MEMBRANE_I_BLUEPRINT  : 11228,
    EXPLOSIVE_ENERGIZED_MEMBRANE_II  : 11229,
    EXPLOSIVE_SHIELD_AMPLIFIER_I  : 2529,
    EXPLOSIVE_SHIELD_AMPLIFIER_I_BLUEPRINT  : 2530,
    EXPLOSIVE_SHIELD_AMPLIFIER_II  : 2531,
    EXPLOSIVE_SHIELD_COMPENSATION  : 12367,
    EXPLOSIVE_SHIELD_HARDENER_I  : 2289,
    EXPLOSIVE_SHIELD_HARDENER_I_BLUEPRINT  : 2290,
    EXPLOSIVE_SHIELD_HARDENER_II  : 2297,
    EXTENDABLE_PLUNGER_TOOL  : 62052,
    EXTRUDED_COMPACT_HEAT_SINK  : 5849,
    F_12_ENDURING_TRACKING_COMPUTER  : 6176,
    F_23_COMPACT_REMOTE_SENSOR_BOOSTER  : 5279,
    F_293_SCOPED_REMOTE_TRACKING_COMPUTER  : 5339,
    F_89_COMPACT_SIGNAL_AMPLIFIER  : 6296,
    F_90_COMPACT_SENSOR_BOOSTER  : 6160,
    FADED_ASTROMETRIC_CHART  : 53341,
    FAINT_EPSILON_SCOPED_WARP_SCRAMBLER  : 5443,
    FAINT_SCOPED_WARP_DISRUPTOR  : 5403,
    FALCON  : 11957,
    FALCON_BIOSECURITY_RESPONDERS_SKIN  : 55571,
    FALCON_BLUE_TIGER_SKIN  : 36729,
    FALCON_BLUEPRINT  : 11958,
    FALCON_CALDARI_NAVY_SKIN  : 58880,
    FALCON_EXOPLANETS_HUNTER_SKIN  : 45841,
    FALCON_GHOSTBIRD_SKIN  : 48184,
    FALCON_GLACIAL_DRIFT_SKIN  : 46845,
    FALCON_MATIGU_SEABEAST_SKIN  : 40605,
    FALCON_RAATA_SUNSET_SKIN  : 36657,
    FALCON_STEEL_CARDINAL_SKIN  : 46118,
    FALCON_VERSUS_REDFORCE_SKIN  : 63865,
    FALCON_WIYRKOMI_SKIN  : 36341,
    FALCON_ZENTO_ISIDEKO_COMBINE_SKIN  : 53178,
    FALL_OF_FORT_KNOCKS_YC120  : 52277,
    FANGS_OF_THE_SERPENT_FIREWORK  : 44272,
    FARADAY_FORCE_MAGNETOMETER  : 45661,
    FARAMONS_TAG  : 17852,
    FAST_TALK  : 3358,
    FEAWENS_APPROVAL_DOCUMENTATION  : 37884,
    FEDERAL_ENCRYPTION_KEY  : 77947,
    FEDERATION_CIVILIAN_CONTAINER  : 43678,
    FEDERATION_GRAND_PRIX_BRONZE_PACKAGE  : 48375,
    FEDERATION_GRAND_PRIX_CREODRON_PACKAGE  : 48376,
    FEDERATION_GRAND_PRIX_GOLD_PACKAGE  : 48379,
    FEDERATION_GRAND_PRIX_IMPETUS_PACKAGE  : 48374,
    FEDERATION_GRAND_PRIX_QUAFE_PACKAGE  : 48378,
    FEDERATION_GRAND_PRIX_SILVER_PACKAGE  : 48377,
    FEDERATION_NAVY_100MM_STEEL_PLATES  : 31898,
    FEDERATION_NAVY_100MN_AFTERBURNER  : 15770,
    FEDERATION_NAVY_10MN_AFTERBURNER  : 15766,
    FEDERATION_NAVY_125MM_RAILGUN  : 15844,
    FEDERATION_NAVY_150MM_RAILGUN  : 15843,
    FEDERATION_NAVY_1600MM_STEEL_PLATES  : 31902,
    FEDERATION_NAVY_1MN_AFTERBURNER  : 15761,
    FEDERATION_NAVY_200MM_RAILGUN  : 15841,
    FEDERATION_NAVY_200MM_STEEL_PLATES  : 31906,
    FEDERATION_NAVY_250MM_RAILGUN  : 15840,
    FEDERATION_NAVY_350MM_RAILGUN  : 15838,
    FEDERATION_NAVY_400MM_STEEL_PLATES  : 31910,
    FEDERATION_NAVY_425MM_RAILGUN  : 15837,
    FEDERATION_NAVY_500MN_MICROWARPDRIVE  : 15768,
    FEDERATION_NAVY_50MN_MICROWARPDRIVE  : 15764,
    FEDERATION_NAVY_5MN_MICROWARPDRIVE  : 15759,
    FEDERATION_NAVY_75MM_RAILGUN  : 15836,
    FEDERATION_NAVY_800MM_STEEL_PLATES  : 31918,
    FEDERATION_NAVY_ANTIMATTER_CHARGE_L  : 22993,
    FEDERATION_NAVY_ANTIMATTER_CHARGE_M  : 22977,
    FEDERATION_NAVY_ANTIMATTER_CHARGE_S  : 22961,
    FEDERATION_NAVY_CO_PROCESSOR  : 15677,
    FEDERATION_NAVY_COMET  : 17841,
    FEDERATION_NAVY_COMET_BIOSECURITY_RESPONDERS_SKIN  : 55606,
    FEDERATION_NAVY_COMET_EXOPLANETS_HUNTER_SKIN  : 45885,
    FEDERATION_NAVY_COMET_GLACIAL_DRIFT_SKIN  : 46937,
    FEDERATION_NAVY_COMET_INNER_ZONE_VANGUARD_SKIN  : 52720,
    FEDERATION_NAVY_COMET_INTAKI_SYNDICATE_SKIN  : 36393,
    FEDERATION_NAVY_COMET_KOPIS_EDGE_SKIN  : 46397,
    FEDERATION_NAVY_COMET_LUMINAIRE_ZENITH_SKIN  : 64399,
    FEDERATION_NAVY_COMET_POLICE_SKIN  : 34718,
    FEDERATION_NAVY_COMET_SAPPHIRE_SUNGRAZER_SKIN  : 45021,
    FEDERATION_NAVY_COMET_SPIRIT_SKIN  : 44903,
    FEDERATION_NAVY_COMET_VERSUS_REDFORCE_SKIN  : 73877,
    FEDERATION_NAVY_COMMAND_MINDLINK  : 33404,
    FEDERATION_NAVY_COMMAND_SERGEANT_MAJOR_INSIGNIA_I  : 15646,
    FEDERATION_NAVY_DRONE_DAMAGE_AMPLIFIER  : 33842,
    FEDERATION_NAVY_DRONE_NAVIGATION_COMPUTER  : 33850,
    FEDERATION_NAVY_DUAL_150MM_RAILGUN  : 15835,
    FEDERATION_NAVY_DUAL_250MM_RAILGUN  : 15834,
    FEDERATION_NAVY_ELECTRON_BLASTER_CANNON  : 15833,
    FEDERATION_NAVY_EM_ARMOR_HARDENER  : 17832,
    FEDERATION_NAVY_EM_COATING  : 17555,
    FEDERATION_NAVY_EM_ENERGIZED_MEMBRANE  : 15733,
    FEDERATION_NAVY_EXPLOSIVE_ARMOR_HARDENER  : 17834,
    FEDERATION_NAVY_EXPLOSIVE_COATING  : 17553,
    FEDERATION_NAVY_EXPLOSIVE_ENERGIZED_MEMBRANE  : 15735,
    FEDERATION_NAVY_FLEET_CAPTAIN_INSIGNIA_I  : 15592,
    FEDERATION_NAVY_FLEET_COLONEL_INSIGNIA_I  : 15594,
    FEDERATION_NAVY_FLEET_COLONEL_INSIGNIA_II  : 15673,
    FEDERATION_NAVY_FLEET_COMMANDER_INSIGNIA  : 15650,
    FEDERATION_NAVY_FLEET_MAJOR_INSIGNIA_I  : 15593,
    FEDERATION_NAVY_FLEET_REAR_ADMIRAL_INSIGNIA  : 28236,
    FEDERATION_NAVY_GARDE  : 31886,
    FEDERATION_NAVY_HAMMERHEAD  : 31882,
    FEDERATION_NAVY_HEAVY_ELECTRON_BLASTER  : 15832,
    FEDERATION_NAVY_HEAVY_ION_BLASTER  : 15831,
    FEDERATION_NAVY_HEAVY_NEUTRON_BLASTER  : 15830,
    FEDERATION_NAVY_HOBGOBLIN  : 31880,
    FEDERATION_NAVY_INFERNO_AUTO_TARGETING_CRUISE_MISSILE_I  : 27471,
    FEDERATION_NAVY_INFERNO_AUTO_TARGETING_HEAVY_MISSILE_I  : 27495,
    FEDERATION_NAVY_INFERNO_AUTO_TARGETING_LIGHT_MISSILE_I  : 27519,
    FEDERATION_NAVY_ION_BLASTER_CANNON  : 15829,
    FEDERATION_NAVY_IRIDIUM_CHARGE_L  : 23003,
    FEDERATION_NAVY_IRIDIUM_CHARGE_M  : 22987,
    FEDERATION_NAVY_IRIDIUM_CHARGE_S  : 22971,
    FEDERATION_NAVY_IRON_CHARGE_L  : 23007,
    FEDERATION_NAVY_IRON_CHARGE_M  : 22991,
    FEDERATION_NAVY_IRON_CHARGE_S  : 22975,
    FEDERATION_NAVY_KINETIC_ARMOR_HARDENER  : 17836,
    FEDERATION_NAVY_KINETIC_COATING  : 17551,
    FEDERATION_NAVY_KINETIC_ENERGIZED_MEMBRANE  : 15737,
    FEDERATION_NAVY_LARGE_ARMOR_REPAIRER  : 15746,
    FEDERATION_NAVY_LARGE_PLASMA_SMARTBOMB  : 15955,
    FEDERATION_NAVY_LEAD_CHARGE_L  : 23001,
    FEDERATION_NAVY_LEAD_CHARGE_M  : 22985,
    FEDERATION_NAVY_LEAD_CHARGE_S  : 22969,
    FEDERATION_NAVY_LIGHT_ELECTRON_BLASTER  : 15828,
    FEDERATION_NAVY_LIGHT_ION_BLASTER  : 15827,
    FEDERATION_NAVY_LIGHT_NEUTRON_BLASTER  : 15826,
    FEDERATION_NAVY_MAGNETIC_FIELD_STABILIZER  : 15895,
    FEDERATION_NAVY_MEDIUM_ARMOR_REPAIRER  : 15745,
    FEDERATION_NAVY_MEDIUM_PLASMA_SMARTBOMB  : 15953,
    FEDERATION_NAVY_MICRO_PLASMA_SMARTBOMB  : 15951,
    FEDERATION_NAVY_MIDSHIPMAN_INSIGNIA_I  : 15587,
    FEDERATION_NAVY_MIDSHIPMAN_INSIGNIA_II  : 15588,
    FEDERATION_NAVY_MIDSHIPMAN_INSIGNIA_III  : 15589,
    FEDERATION_NAVY_MULTISPECTRUM_COATING  : 17549,
    FEDERATION_NAVY_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 15739,
    FEDERATION_NAVY_NEUTRON_BLASTER_CANNON  : 15825,
    FEDERATION_NAVY_OGRE  : 31884,
    FEDERATION_NAVY_OMNIDIRECTIONAL_TRACKING_LINK  : 31942,
    FEDERATION_NAVY_PLUTONIUM_CHARGE_L  : 22995,
    FEDERATION_NAVY_PLUTONIUM_CHARGE_M  : 22979,
    FEDERATION_NAVY_PLUTONIUM_CHARGE_S  : 22963,
    FEDERATION_NAVY_RAID_LEADER_INSIGNIA  : 15648,
    FEDERATION_NAVY_REMOTE_TRACKING_COMPUTER  : 15967,
    FEDERATION_NAVY_SENSOR_BOOSTER  : 17520,
    FEDERATION_NAVY_SERGEANT_ELITE_INSIGNIA  : 15649,
    FEDERATION_NAVY_SERGEANT_INSIGNIA_I  : 15590,
    FEDERATION_NAVY_SERGEANT_INSIGNIA_II  : 15994,
    FEDERATION_NAVY_SERGEANT_INSIGNIA_III  : 16002,
    FEDERATION_NAVY_SERGEANT_MAJOR_INSIGNIA_I  : 15591,
    FEDERATION_NAVY_SMALL_ARMOR_REPAIRER  : 15744,
    FEDERATION_NAVY_SMALL_PLASMA_SMARTBOMB  : 15949,
    FEDERATION_NAVY_SQUAD_LEADER_INSIGNIA  : 15647,
    FEDERATION_NAVY_STASIS_WEBIFIER  : 17559,
    FEDERATION_NAVY_THERMAL_ARMOR_HARDENER  : 17838,
    FEDERATION_NAVY_THERMAL_COATING  : 17557,
    FEDERATION_NAVY_THERMAL_ENERGIZED_MEMBRANE  : 15731,
    FEDERATION_NAVY_THORIUM_CHARGE_L  : 22999,
    FEDERATION_NAVY_THORIUM_CHARGE_M  : 22983,
    FEDERATION_NAVY_THORIUM_CHARGE_S  : 22967,
    FEDERATION_NAVY_TRACKING_COMPUTER  : 15792,
    FEDERATION_NAVY_TUNGSTEN_CHARGE_L  : 23005,
    FEDERATION_NAVY_TUNGSTEN_CHARGE_M  : 22989,
    FEDERATION_NAVY_TUNGSTEN_CHARGE_S  : 22973,
    FEDERATION_NAVY_URANIUM_CHARGE_L  : 22997,
    FEDERATION_NAVY_URANIUM_CHARGE_M  : 22981,
    FEDERATION_NAVY_URANIUM_CHARGE_S  : 22965,
    FEDERATION_NAVY_WARP_DISRUPTOR  : 41062,
    FEDERATION_NAVY_WARP_SCRAMBLER  : 41061,
    FEDO  : 26902,
    FELON_ION_BLASTER_CANNON_I  : 22913,
    FELSIC_MAGMA  : 2307,
    FENRIR  : 20189,
    FENRIR_BIOSECURITY_RESPONDERS_SKIN  : 55656,
    FENRIR_BLUE_TIGER_SKIN  : 36843,
    FENRIR_BLUEPRINT  : 20190,
    FENRIR_EXOPLANETS_HUNTER_SKIN  : 45989,
    FENRIR_FLEET_ORDNANCE_SPIKE_SKIN  : 62858,
    FENRIR_GLACIAL_DRIFT_SKIN  : 46926,
    FENRIR_HAZARD_CONTROL_SKIN  : 56936,
    FENRIR_JUSTICE_SKIN  : 36444,
    FENRIR_KRUSUAL_SKIN  : 34671,
    FENRIR_SNOWLINE_BLADERACER_SKIN  : 52900,
    FENRIR_TRONHADAR_INK_SKIN  : 40514,
    FENRIR_VALKLEAR_GLORY_SKIN  : 36796,
    FERMIONIC_CONDENSATES  : 17317,
    FERMIONIC_CONDENSATES_REACTION_FORMULA  : 46214,
    FERNITE_ALLOY  : 16656,
    FERNITE_ALLOY_REACTION_FORMULA  : 46171,
    FERNITE_CARBIDE  : 16673,
    FERNITE_CARBIDE_COMPOSITE_ARMOR_PLATE  : 11542,
    FERNITE_CARBIDE_COMPOSITE_ARMOR_PLATE_BLUEPRINT  : 17355,
    FERNITE_CARBIDE_REACTION_FORMULA  : 46206,
    FEROX  : 16227,
    FEROX_BIOSECURITY_RESPONDERS_SKIN  : 55525,
    FEROX_BLUE_TIGER_SKIN  : 36732,
    FEROX_BLUEPRINT  : 16228,
    FEROX_CAPSULEER_DAY_XVII_SKIN  : 54766,
    FEROX_CORDITE_BLOSSOM_SKIN  : 48834,
    FEROX_EXOPLANETS_HUNTER_SKIN  : 45847,
    FEROX_GHOSTBIRD_SKIN  : 48190,
    FEROX_GLACIAL_DRIFT_SKIN  : 46851,
    FEROX_GURISTAS_SKIN_30_DAYS  : 35150,
    FEROX_HUNTERS_QUIVER_SKIN  : 57531,
    FEROX_IGC_SKIN  : 48550,
    FEROX_LAI_DAI_SKIN  : 34609,
    FEROX_LUMINAIRE_RISING_SKIN  : 48217,
    FEROX_MALAGRIN_MARSHLIGHT_SKIN  : 56593,
    FEROX_MATIGU_SEABEAST_SKIN  : 40608,
    FEROX_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59429,
    FEROX_NAVY_ISSUE  : 72812,
    FEROX_NAVY_ISSUE_STEEL_CARDINAL_SKIN  : 73870,
    FEROX_NAVY_ISSUE_VERSUS_BLUEFORCE_SKIN  : 73874,
    FEROX_RAATA_SUNSET_SKIN  : 36660,
    FEROX_SCOPE_SYNDICATION_YC122_SKIN  : 61189,
    FEROX_STATE_POLICE_SKIN  : 60031,
    FEROX_STEEL_CARDINAL_SKIN  : 46124,
    FEROX_VITALSHIFT_EGO_SKIN  : 52559,
    FEROX_ZENTO_ISIDEKO_COMBINE_SKIN  : 53184,
    FERROFLUID  : 16669,
    FERROFLUID_REACTION_FORMULA  : 46172,
    FERROGEL  : 16683,
    FERROGEL_REACTION_FORMULA  : 46213,
    FERTILIZER  : 3693,
    FESTIVAL_BATTLECRUISER_SKINS_AND_SNOWBALLS_CRATE  : 53509,
    FESTIVAL_BATTLESHIP_SKINS_HYPERCORE_AND_SNOWBALLS_CRATE  : 53514,
    FESTIVAL_CAP_AND_SNOWBALLS_CRATE  : 53498,
    FESTIVAL_DESTROYER_SKINS_AND_SNOWBALLS_CRATE  : 53491,
    FESTIVAL_EXPLORATION_SUIT_AND_SNOWBALLS_CRATE  : 53496,
    FESTIVAL_FACIAL_AUGMENTATION_AND_SNOWBALLS_CRATE  : 53493,
    FESTIVAL_FACIAL_AUGMENTATION_AND_SNOWBALLS_CRATE2  : 53513,
    FESTIVAL_FIREWORKS_CRATE  : 53494,
    FESTIVAL_FRIGATE_SKINS_AND_SNOWBALLS_CRATE  : 53486,
    FESTIVAL_HYPERCORE_AND_SNOWBALLS_CRATE  : 53512,
    FESTIVAL_HYPERCORE_BODY_AUGMENTATION_AND_SNOWBALLS_CRATE  : 53508,
    FESTIVAL_LAUNCHER  : 19660,
    FESTIVAL_SNOWBALLS_CRATE  : 53488,
    FESTIVAL_TIER_1_FILAMENT_CRATE  : 53490,
    FESTIVE_FIRECRACKERS  : 37879,
    FFR_ENDURING_ASSAULT_DAMAGE_CONTROL  : 47256,
    FIEND  : 35781,
    FIERCE_ABYSSAL_FILAMENTS_CRATE  : 49709,
    FIERCE_DARK_FILAMENT  : 47893,
    FIERCE_ELECTRICAL_FILAMENT  : 47905,
    FIERCE_EXOTIC_FILAMENT  : 47889,
    FIERCE_FIRESTORM_FILAMENT  : 47897,
    FIERCE_GAMMA_FILAMENT  : 47901,
    FIERY_KERNITE  : 17453,
    FIGHTER_HANGAR_MANAGEMENT  : 24613,
    FIGHTER_SUPPORT_UNIT_I  : 24283,
    FIGHTER_SUPPORT_UNIT_I_BLUEPRINT  : 24284,
    FIGHTER_SUPPORT_UNIT_II  : 41415,
    FIGHTERS  : 23069,
    FIRBOLG_I  : 23059,
    FIRBOLG_I_BLUEPRINT  : 23060,
    FIRBOLG_II  : 40558,
    FIREWALL_SIGNAL_AMPLIFIER  : 21527,
    FIREWATER_YTIRIUM  : 74527,
    FLAG_CRUISERS  : 47445,
    FLAMES_OF_THE_REBELLION_FIREWORK  : 44270,
    FLAWLESS_ARKONOR  : 46678,
    FLEET_COMMAND  : 24764,
    FLEET_COMPRESSION_LOGISTICS  : 62453,
    FLEET_COORDINATION  : 57318,
    FLEET_FORMATIONS  : 57317,
    FLEETING_COMPACT_STASIS_WEBIFIER  : 4027,
    FLOW_CYTOMETRY_REWARDS_CRATE  : 55358,
    FLUCTUATING_ENTROPIC_REGULATOR  : 77708,
    FLUXED_CONDENSATES  : 17769,
    FLUXED_CONDENSATES_REACTION_FORMULA  : 46173,
    FLYCATCHER  : 22464,
    FLYCATCHER_AURORA_UNIVERSALIS_SKIN  : 57012,
    FLYCATCHER_BIOSECURITY_RESPONDERS_SKIN  : 55560,
    FLYCATCHER_BLUE_TIGER_SKIN  : 36720,
    FLYCATCHER_BLUEPRINT  : 22465,
    FLYCATCHER_EXOPLANETS_HUNTER_SKIN  : 45831,
    FLYCATCHER_GHOSTBIRD_SKIN  : 48174,
    FLYCATCHER_GLACIAL_DRIFT_SKIN  : 46839,
    FLYCATCHER_KIMOTORO_ESKEITAN_SKIN  : 52632,
    FLYCATCHER_MALAGRIN_MARSHLIGHT_SKIN  : 60565,
    FLYCATCHER_MATIGU_SEABEAST_SKIN  : 40596,
    FLYCATCHER_RAATA_SUNSET_SKIN  : 36648,
    FLYCATCHER_STATE_POLICE_SKIN  : 72553,
    FLYCATCHER_STEEL_CARDINAL_SKIN  : 46108,
    FLYCATCHER_WIYRKOMI_SKIN  : 36357,
    FLYCATCHER_ZENTO_ISIDEKO_COMBINE_SKIN  : 53168,
    FOCUSED_AFOCAL_MEDIUM_MASER_I  : 6861,
    FOCUSED_AFOCAL_PULSE_MASER_I  : 6807,
    FOCUSED_ANODE_MEDIUM_PARTICLE_STREAM_I  : 6865,
    FOCUSED_ANODE_PULSE_PARTICLE_STREAM_I  : 6811,
    FOCUSED_MEDIUM_BEAM_LASER_I  : 457,
    FOCUSED_MEDIUM_BEAM_LASER_I_BLUEPRINT  : 841,
    FOCUSED_MEDIUM_BEAM_LASER_II  : 3009,
    FOCUSED_MEDIUM_PULSE_LASER_I  : 456,
    FOCUSED_MEDIUM_PULSE_LASER_I_BLUEPRINT  : 840,
    FOCUSED_MEDIUM_PULSE_LASER_II  : 3512,
    FOCUSED_MODAL_MEDIUM_LASER_I  : 6859,
    FOCUSED_MODAL_PULSE_LASER_I  : 6805,
    FOCUSED_MODULATED_MEDIUM_ENERGY_BEAM_I  : 6863,
    FOCUSED_MODULATED_PULSE_ENERGY_BEAM_I  : 6809,
    FOCUSED_VOID_BOMB  : 34264,
    FOCUSED_WARP_DISRUPTION_SCRIPT  : 29003,
    FOCUSED_WARP_DISRUPTION_SCRIPT_BLUEPRINT  : 29004,
    FOCUSED_WARP_SCRAMBLING_SCRIPT  : 45010,
    FOCUSED_WARP_SCRAMBLING_SCRIPT_BLUEPRINT  : 45011,
    FOIRITAN_VOUCHER  : 19462,
    FORCE_CABLE  : 23160,
    FOREVER_4_4_FIREWORK  : 56745,
    FORGED_FRIGATE_BLUEPRINTS  : 44208,
    FORTIZAR  : 35833,
    FORTIZAR_BLUEPRINT  : 36967,
    FORTIZAR_UPWELL_QUANTUM_CORE  : 56204,
    FOUR_FREEDOMS_FIREWORK  : 44268,
    FOURIER_COMPACT_TRACKING_ENHANCER  : 6325,
    FRAGMENT_INSCRIBED_WITH_ODD_CIRCULAR_TEXT  : 62050,
    FREED_SLAVES  : 18029,
    FREEDOM_FIGHTERS  : 17791,
    FREEZER_THIEF_DISPLAY  : 56982,
    FREKI  : 32207,
    FRENTIX  : 3822,
    FREQUENCY_MODULATION  : 19760,
    FRIED_INTERFACE_CIRCUIT  : 25601,
    FRIGORIS_RESTRAINED_ICE_HARVESTER_UPGRADE  : 22619,
    FROSTLINE_CLAVICULA_INTEGRATED_ANALYZER  : 49791,
    FROSTLINE_CLAVICULA_INTEGRATED_ANALYZER_BLUEPRINT_CRATE  : 49975,
    FROSTLINE_OMNIVORE_HARVESTER_UPGRADE  : 49792,
    FROSTLINE_OMNIVORE_HARVESTER_UPGRADE_BLUEPRINT  : 49973,
    FROSTLINE_OMNIVORE_HARVESTER_UPGRADE_BLUEPRINT_CRATE  : 49981,
    FROZEN_DROPLET_OF_BLOOD  : 47048,
    FROZEN_FOOD  : 3715,
    FROZEN_PLANT_SEEDS  : 45,
    FUEL_CONSERVATION  : 3451,
    FUEL_STEALER_DISPLAY  : 56972,
    FULL_DUPLEX_BALLISTIC_CONTROL_SYSTEM  : 21484,
    FULLERENE_INTERCALATED_GRAPHITE  : 30305,
    FULLERENE_INTERCALATED_GRAPHITE_REACTION_FORMULA  : 46160,
    FULLERENE_INTERCALATED_SHEETS  : 30002,
    FULLERENE_INTERCALATED_SHEETS_BLUEPRINT  : 30003,
    FULLERIDE_REACTION_FORMULA  : 46209,
    FULLERIDES  : 16679,
    FULLERITE_C28  : 30375,
    FULLERITE_C32  : 30376,
    FULLERITE_C320  : 30377,
    FULLERITE_C50  : 30370,
    FULLERITE_C540  : 30378,
    FULLERITE_C60  : 30371,
    FULLERITE_C70  : 30372,
    FULLERITE_C72  : 30373,
    FULLERITE_C84  : 30374,
    FULLEROFERROCENE  : 30303,
    FULLEROFERROCENE_POWER_CONDUITS  : 30476,
    FULLEROFERROCENE_POWER_CONDUITS_BLUEPRINT  : 30477,
    FULLEROFERROCENE_REACTION_FORMULA  : 46158,
    FUSED_NANOMECHANICAL_ENGINES  : 30018,
    FUSION_L  : 199,
    FUSION_L_BLUEPRINT  : 900,
    FUSION_M  : 191,
    FUSION_M_BLUEPRINT  : 892,
    FUSION_REACTOR_UNIT  : 11547,
    FUSION_REACTOR_UNIT_BLUEPRINT  : 17331,
    FUSION_S  : 183,
    FUSION_S_BLUEPRINT  : 884,
    FUSION_THRUSTER  : 11532,
    FUSION_THRUSTER_BLUEPRINT  : 17349,
    FUSION_XL  : 17670,
    FUSION_XL_BLUEPRINT  : 17671,
    FZ_3A_ENDURING_GRAVIMETRIC_ECM  : 19942,
    G_O_TRIGGER_NEUROLINK_CONDUIT  : 57473,
    G_O_TRIGGER_NEUROLINK_CONDUIT_BLUEPRINT  : 57510,
    GALAXY_PACK___CRYSTAL  : 47253,
    GALAXY_PACK___SPECTRAL  : 47154,
    GALEPTOS_MEDICINE  : 15316,
    GALLENTE_106_ELECTION_HOLOREEL  : 17423,
    GALLENTE_ALPHA_NEXUS_CHIP  : 17850,
    GALLENTE_BATTLECRUISER  : 33097,
    GALLENTE_BATTLESHIP  : 3336,
    GALLENTE_BETA_NEXUS_CHIP  : 17849,
    GALLENTE_CARRIER  : 24313,
    GALLENTE_CONTROL_TOWER  : 12236,
    GALLENTE_CONTROL_TOWER_BLUEPRINT  : 2780,
    GALLENTE_CONTROL_TOWER_MEDIUM  : 20063,
    GALLENTE_CONTROL_TOWER_MEDIUM_BLUEPRINT  : 2781,
    GALLENTE_CONTROL_TOWER_SMALL  : 20064,
    GALLENTE_CONTROL_TOWER_SMALL_BLUEPRINT  : 2782,
    GALLENTE_CORE_SYSTEMS  : 30546,
    GALLENTE_CRUISER  : 3332,
    GALLENTE_DECODER_PACKAGE  : 73400,
    GALLENTE_DEFENSIVE_SYSTEMS  : 30540,
    GALLENTE_DESTROYER  : 33093,
    GALLENTE_DREADNOUGHT  : 20531,
    GALLENTE_DRONE_SPECIALIZATION  : 12486,
    GALLENTE_ENCODER_SPLICE  : 73404,
    GALLENTE_ENCRYPTION_METHODS  : 23121,
    GALLENTE_FEDERATION_STARBASE_CHARTER  : 24594,
    GALLENTE_FORCE_AUXILIARY  : 40537,
    GALLENTE_FREIGHTER  : 20527,
    GALLENTE_FRIGATE  : 3328,
    GALLENTE_GAMMA_NEXUS_CHIP  : 17848,
    GALLENTE_HAULER  : 3340,
    GALLENTE_INCEPTION_PACKAGE  : 43932,
    GALLENTE_LISTENING_OUTPOST  : 73273,
    GALLENTE_LOYALTY_TOKEN  : 73249,
    GALLENTE_MINING_LASER  : 21841,
    GALLENTE_OFFENSIVE_SYSTEMS  : 30550,
    GALLENTE_PHENOMENA_GENERATOR  : 43662,
    GALLENTE_PHENOMENA_GENERATOR_BLUEPRINT  : 43663,
    GALLENTE_PROPAGANDA_BROADCAST_STRUCTURE  : 73228,
    GALLENTE_PROPULSION_SYSTEMS  : 30553,
    GALLENTE_SHUTTLE  : 11129,
    GALLENTE_SHUTTLE_BIOSECURITY_RESPONDERS_SKIN  : 55594,
    GALLENTE_SHUTTLE_BLUEPRINT  : 11130,
    GALLENTE_SHUTTLE_EXOPLANETS_HUNTER_SKIN  : 45869,
    GALLENTE_SHUTTLE_GLACIAL_DRIFT_SKIN  : 46928,
    GALLENTE_SHUTTLE_SPIRIT_SKIN  : 44887,
    GALLENTE_SPACESHIP_SKILL_ACCELERATOR  : 55805,
    GALLENTE_STARSHIP_ENGINEERING  : 11450,
    GALLENTE_STRATEGIC_CRUISER  : 30652,
    GALLENTE_TACTICAL_DESTROYER  : 35685,
    GALLENTE_TITAN  : 3344,
    GALLENTEAN_PLANETARY_VEHICLES  : 17143,
    GALLIUM_AMPLE_HEXA_2500MM_REPEATING_CANNON  : 41151,
    GALLIUM_AMPLE_HEXA_2500MM_REPEATING_CANNON_BLUEPRINT  : 41162,
    GALLIUM_AMPLE_QUAD_3500MM_SIEGE_ARTILLERY  : 41157,
    GALLIUM_AMPLE_QUAD_3500MM_SIEGE_ARTILLERY_BLUEPRINT  : 41169,
    GALLOWS_LIGHT_MISSILE_LAUNCHER  : 20595,
    GALVASURGE_CONDENSER_PACK_L  : 54777,
    GALVASURGE_CONDENSER_PACK_M  : 54773,
    GALVASURGE_CONDENSER_PACK_S  : 54769,
    GAMBLER_LADAR_ECM  : 20575,
    GAMEALFUR_ALE  : 54163,
    GAMMA_L  : 261,
    GAMMA_L_BLUEPRINT  : 1176,
    GAMMA_M  : 253,
    GAMMA_M_BLUEPRINT  : 1168,
    GAMMA_S  : 245,
    GAMMA_S_BLUEPRINT  : 1160,
    GAMMA_XL  : 17680,
    GAMMA_XL_BLUEPRINT  : 17681,
    GARBAGE  : 41,
    GARDE_I  : 23561,
    GARDE_I_BLUEPRINT  : 23562,
    GARDE_II  : 28211,
    GARMUR  : 33816,
    GARMUR_CONVERGENCE___NOVA_SKIN  : 44256,
    GARMUR_GHOSTS_HEX_SKIN  : 57810,
    GARMUR_GLACIAL_DRIFT_SKIN  : 47003,
    GARMUR_IGC_SKIN  : 60754,
    GARMUR_LUMINAE_POLARIS_SKIN  : 75270,
    GARMUR_REDCLAW_SABLE_SKIN  : 56952,
    GARMUR_TRIUMPHANT_ELITE_SKIN  : 62372,
    GARMUR_WASCHI_UPRISING_COMBAT_SKIN  : 37867,
    GARMUR_WASCHI_UPRISING_PARADE_SKIN  : 37965,
    GAS_CLOUD_HARVESTER_I  : 60313,
    GAS_CLOUD_HARVESTER_I_BLUEPRINT  : 60338,
    GAS_CLOUD_HARVESTER_II  : 60314,
    GAS_CLOUD_HARVESTING  : 25544,
    GAS_CLOUD_SCOOP_I  : 25266,
    GAS_CLOUD_SCOOP_I_BLUEPRINT  : 25267,
    GAS_CLOUD_SCOOP_II  : 25812,
    GAS_COMMAND_CENTER  : 2534,
    GAS_DECOMPRESSION_EFFICIENCY  : 62452,
    GATLING_AFOCAL_MASER_I  : 5177,
    GATLING_ANODE_PARTICLE_STREAM_I  : 5181,
    GATLING_MODAL_LASER_I  : 5175,
    GATLING_MODULATED_ENERGY_BEAM_I  : 5179,
    GATLING_PULSE_LASER_I  : 450,
    GATLING_PULSE_LASER_I_BLUEPRINT  : 834,
    GATLING_PULSE_LASER_II  : 3017,
    GAUNTLET_SMALL_FOCUSED_BEAM_LASER_I  : 23838,
    GAZE_SURVEY_PROBE_I  : 18637,
    GAZE_SURVEY_PROBE_I_BLUEPRINT  : 32855,
    GDN_9_NIGHTSTALKER_COMBAT_GOGGLES  : 3958,
    GECKO  : 33681,
    GEIRAVOR_EXPLOSIVE_LANCE  : 41441,
    GEIRAVOR_EXPLOSIVE_LANCE_BLUEPRINT  : 41448,
    GEL_MATRIX_BIOPASTE  : 2348,
    GELIDUS  : 16268,
    GENERAL_STORAGE  : 25821,
    GENETIC_LOCK_PRESERVER  : 57481,
    GENETIC_LOCK_PRESERVER_BLUEPRINT  : 57518,
    GENETIC_MUTATION_INHIBITOR  : 57485,
    GENETIC_MUTATION_INHIBITOR_BLUEPRINT  : 57522,
    GENETIC_SAFEGUARD_FILTER  : 57482,
    GENETIC_SAFEGUARD_FILTER_BLUEPRINT  : 57519,
    GENETIC_STRUCTURE_REPAIRER  : 57484,
    GENETIC_STRUCTURE_REPAIRER_BLUEPRINT  : 57521,
    GENETICALLY_ENHANCED_LIVESTOCK  : 15317,
    GENOLUTION_AURORAL_AU_79  : 33329,
    GENOLUTION_CA_X  : 47961,
    GENOLUTION_CORE_AUGMENTATION_CA_1  : 2082,
    GENOLUTION_CORE_AUGMENTATION_CA_2  : 2589,
    GENOLUTION_CORE_AUGMENTATION_CA_3  : 33393,
    GENOLUTION_CORE_AUGMENTATION_CA_4  : 33394,
    GENOM_TARAS_INSIGNIA  : 17817,
    GERI  : 74141,
    GHOST_BURST_JAMMER  : 20581,
    GIANT_FREIGHT_CONTAINER  : 24445,
    GIANT_FREIGHT_CONTAINER_BLUEPRINT  : 32862,
    GIANT_SECURE_CONTAINER  : 11489,
    GIANT_SECURE_CONTAINER_BLUEPRINT  : 32863,
    GILA  : 17715,
    GILA_ABYSSAL_AFTERGLOW_SKIN  : 49750,
    GILA_ABYSSAL_DARKFIELD_SKIN  : 53963,
    GILA_ABYSSAL_EXOSTORM_SKIN  : 53964,
    GILA_ABYSSAL_FIRESTORM_SKIN  : 49751,
    GILA_ABYSSAL_THUNDERSTORM_SKIN  : 49752,
    GILA_ACE_OF_PODHUNTERS_SKIN  : 47510,
    GILA_AETHERIC_RAIDERS_SKIN  : 52704,
    GILA_AURORA_UNIVERSALIS_SKIN  : 60913,
    GILA_DEATHGLOW_HUNTERS_SKIN  : 49433,
    GILA_DRAGON_PHOENIX_SKIN  : 54431,
    GILA_EMPEROR_BEAR_SKIN  : 52302,
    GILA_GILDED_PREDATOR_SKIN  : 77176,
    GILA_GLACIAL_DRIFT_SKIN  : 46874,
    GILA_HELMATTS_HARRIERS_SKIN  : 54230,
    GILA_HOSTILE_TAKEOVER_SKIN  : 45508,
    GILA_IGC_SKIN  : 48553,
    GILA_SCOPE_SYNDICATION_YC122_SKIN  : 56891,
    GILA_STORM_BEAR_SKIN  : 48817,
    GILA_VAMPIRES_HEX_SKIN  : 57814,
    GILA_VERSUS_BLUEFORCE_SKIN  : 63642,
    GILA_WARP_CONVERGENCE_SKIN  : 61867,
    GIST_A_TYPE_100MN_AFTERBURNER  : 18674,
    GIST_A_TYPE_500MN_MICROWARPDRIVE  : 19353,
    GIST_A_TYPE_EM_SHIELD_HARDENER  : 19279,
    GIST_A_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19275,
    GIST_A_TYPE_KINETIC_SHIELD_HARDENER  : 19273,
    GIST_A_TYPE_LARGE_SHIELD_BOOSTER  : 19199,
    GIST_A_TYPE_SHIELD_BOOST_AMPLIFIER  : 19293,
    GIST_A_TYPE_THERMAL_SHIELD_HARDENER  : 19277,
    GIST_A_TYPE_X_LARGE_SHIELD_BOOSTER  : 19197,
    GIST_B_TYPE_100MN_AFTERBURNER  : 18668,
    GIST_B_TYPE_500MN_MICROWARPDRIVE  : 19347,
    GIST_B_TYPE_EM_SHIELD_HARDENER  : 19265,
    GIST_B_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19269,
    GIST_B_TYPE_KINETIC_SHIELD_HARDENER  : 19271,
    GIST_B_TYPE_LARGE_SHIELD_BOOSTER  : 19194,
    GIST_B_TYPE_SHIELD_BOOST_AMPLIFIER  : 19299,
    GIST_B_TYPE_THERMAL_SHIELD_HARDENER  : 19267,
    GIST_B_TYPE_X_LARGE_SHIELD_BOOSTER  : 19196,
    GIST_C_TYPE_100MN_AFTERBURNER  : 18662,
    GIST_C_TYPE_500MN_MICROWARPDRIVE  : 19341,
    GIST_C_TYPE_EM_SHIELD_HARDENER  : 19263,
    GIST_C_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19259,
    GIST_C_TYPE_KINETIC_SHIELD_HARDENER  : 19257,
    GIST_C_TYPE_LARGE_SHIELD_BOOSTER  : 19193,
    GIST_C_TYPE_SHIELD_BOOST_AMPLIFIER  : 19297,
    GIST_C_TYPE_THERMAL_SHIELD_HARDENER  : 19261,
    GIST_C_TYPE_X_LARGE_SHIELD_BOOSTER  : 19195,
    GIST_X_TYPE_100MN_AFTERBURNER  : 18676,
    GIST_X_TYPE_500MN_MICROWARPDRIVE  : 19359,
    GIST_X_TYPE_EM_SHIELD_HARDENER  : 19281,
    GIST_X_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19285,
    GIST_X_TYPE_KINETIC_SHIELD_HARDENER  : 19287,
    GIST_X_TYPE_LARGE_SHIELD_BOOSTER  : 19200,
    GIST_X_TYPE_SHIELD_BOOST_AMPLIFIER  : 19301,
    GIST_X_TYPE_THERMAL_SHIELD_HARDENER  : 19283,
    GIST_X_TYPE_X_LARGE_SHIELD_BOOSTER  : 19198,
    GISTII_A_TYPE_1MN_AFTERBURNER  : 18670,
    GISTII_A_TYPE_5MN_MICROWARPDRIVE  : 19349,
    GISTII_A_TYPE_SMALL_REMOTE_SHIELD_BOOSTER  : 19133,
    GISTII_A_TYPE_SMALL_SHIELD_BOOSTER  : 19173,
    GISTII_B_TYPE_1MN_AFTERBURNER  : 18664,
    GISTII_B_TYPE_5MN_MICROWARPDRIVE  : 19343,
    GISTII_B_TYPE_SMALL_REMOTE_SHIELD_BOOSTER  : 19131,
    GISTII_B_TYPE_SMALL_SHIELD_BOOSTER  : 19171,
    GISTII_C_TYPE_1MN_AFTERBURNER  : 18658,
    GISTII_C_TYPE_5MN_MICROWARPDRIVE  : 19337,
    GISTII_C_TYPE_SMALL_REMOTE_SHIELD_BOOSTER  : 19129,
    GISTII_C_TYPE_SMALL_SHIELD_BOOSTER  : 19169,
    GISTUM_A_TYPE_10MN_AFTERBURNER  : 18672,
    GISTUM_A_TYPE_50MN_MICROWARPDRIVE  : 19351,
    GISTUM_A_TYPE_EM_SHIELD_AMPLIFIER  : 19255,
    GISTUM_A_TYPE_EXPLOSIVE_SHIELD_AMPLIFIER  : 19249,
    GISTUM_A_TYPE_KINETIC_SHIELD_AMPLIFIER  : 19253,
    GISTUM_A_TYPE_MEDIUM_REMOTE_SHIELD_BOOSTER  : 19145,
    GISTUM_A_TYPE_MEDIUM_SHIELD_BOOSTER  : 19185,
    GISTUM_A_TYPE_MULTISPECTRUM_SHIELD_HARDENER  : 4346,
    GISTUM_A_TYPE_THERMAL_SHIELD_AMPLIFIER  : 19251,
    GISTUM_B_TYPE_10MN_AFTERBURNER  : 18666,
    GISTUM_B_TYPE_50MN_MICROWARPDRIVE  : 19345,
    GISTUM_B_TYPE_EM_SHIELD_AMPLIFIER  : 19247,
    GISTUM_B_TYPE_EXPLOSIVE_SHIELD_AMPLIFIER  : 19235,
    GISTUM_B_TYPE_KINETIC_SHIELD_AMPLIFIER  : 19243,
    GISTUM_B_TYPE_MEDIUM_REMOTE_SHIELD_BOOSTER  : 19143,
    GISTUM_B_TYPE_MEDIUM_SHIELD_BOOSTER  : 19183,
    GISTUM_B_TYPE_MULTISPECTRUM_SHIELD_HARDENER  : 4345,
    GISTUM_B_TYPE_THERMAL_SHIELD_AMPLIFIER  : 19239,
    GISTUM_C_TYPE_10MN_AFTERBURNER  : 18660,
    GISTUM_C_TYPE_50MN_MICROWARPDRIVE  : 19339,
    GISTUM_C_TYPE_EM_SHIELD_AMPLIFIER  : 19245,
    GISTUM_C_TYPE_EXPLOSIVE_SHIELD_AMPLIFIER  : 19233,
    GISTUM_C_TYPE_KINETIC_SHIELD_AMPLIFIER  : 19241,
    GISTUM_C_TYPE_MEDIUM_REMOTE_SHIELD_BOOSTER  : 19141,
    GISTUM_C_TYPE_MEDIUM_SHIELD_BOOSTER  : 19181,
    GISTUM_C_TYPE_MULTISPECTRUM_SHIELD_HARDENER  : 2050,
    GISTUM_C_TYPE_THERMAL_SHIELD_AMPLIFIER  : 19237,
    GJALLARHORN_EXPLOSIVE_DOOMSDAY  : 23674,
    GJALLARHORN_EXPLOSIVE_DOOMSDAY_BLUEPRINT  : 24549,
    GLACIAL_DRIFT_FESTIVAL_BOX  : 46766,
    GLACIAL_MASS  : 16263,
    GLARE_CRUST  : 16266,
    GLAZED_HEDBERGITE  : 17441,
    GLEAM_L  : 12828,
    GLEAM_M  : 12826,
    GLEAM_S  : 12557,
    GLEAM_XL  : 41334,
    GLEAMING_ALLOY  : 11740,
    GLEAMING_SPODUMAIN  : 17467,
    GLISTENING_BITUMENS  : 46285,
    GLISTENING_COESITE  : 46287,
    GLISTENING_SYLVITE  : 46283,
    GLISTENING_ZEOLITES  : 46281,
    GLOOM_ENDURING_RADAR_ECM  : 19948,
    GLORIFICATION_1_DEVANA_FILAMENT  : 56065,
    GLORIFICATION_15_DEVANA_FILAMENT  : 56067,
    GLORIFICATION_5_DEVANA_FILAMENT  : 56066,
    GLOSSY_COMPOUND  : 11724,
    GLOSSY_SCORDITE  : 46687,
    GLOWING_CARNOTITE  : 46305,
    GLOWING_CINNABAR  : 46311,
    GLOWING_POLLUCITE  : 46309,
    GLOWING_ZIRCON  : 46307,
    GLYCERINE_SHIELD_BOOST_AMPLIFIER  : 20613,
    GNEISS  : 1229,
    GNEISS_PROCESSING  : 12184,
    GNOSIS  : 3756,
    GNOSIS__PUNISHER_SKIN_CRATE  : 49802,
    GNOSIS_CAPSULEER_DAY_XVI_SKIN  : 52280,
    GNOSIS_CAPSULEER_ELITE_XV_SKIN  : 47649,
    GNOSIS_CAPSULEER_NETWORKER_XV_SKIN  : 47654,
    GNOSIS_GALNET_STREAMCAST_UNIT_SKIN  : 76494,
    GNOSIS_GLACIAL_DRIFT_SKIN  : 47010,
    GNOSIS_ICECLOUD_INVESTIGATORS_SKIN  : 49795,
    GNOSIS_IRONBLOOD_SKIN  : 43530,
    GNOSIS_MIDNIGHT_SKIN  : 44257,
    GNOSIS_SILVER_RACER_SKIN  : 47552,
    GNOSIS_TERCIO_ROJO_SKIN  : 74117,
    GNOSIS_VERSUS_BLUEFORCE_SKIN  : 47549,
    GNOSIS_VERSUS_REDFORCE_SKIN  : 47546,
    GNOSIS_ZAKURA_SHUMYU_SKIN  : 57068,
    GOAL_ORIENTING_NEUROLINK_STABILIZER  : 57469,
    GOAL_ORIENTING_NEUROLINK_STABILIZER_REACTION_FORMULA  : 57506,
    GOLD_MAGNATE  : 11940,
    GOLD_OUROBOROS  : 42231,
    GOLDEN_CYTOSEROCIN  : 25273,
    GOLDEN_MYKOSEROCIN  : 28697,
    GOLDEN_OMBER  : 17868,
    GOLDEN_SABIK_VIAL  : 49647,
    GOLEM  : 28710,
    GOLEM_BIOSECURITY_RESPONDERS_SKIN  : 55550,
    GOLEM_BLUE_TIGER_SKIN  : 36740,
    GOLEM_CALDARI_UNION_DAY_SKIN  : 48733,
    GOLEM_EXOPLANETS_HUNTER_SKIN  : 45856,
    GOLEM_GALNET_STREAMCAST_UNIT_SKIN  : 57733,
    GOLEM_GHOSTBIRD_SKIN  : 48199,
    GOLEM_GLACIAL_DRIFT_SKIN  : 46860,
    GOLEM_HEADHUNTER_SKIN  : 49423,
    GOLEM_KAALAKIOTA_SKIN  : 34814,
    GOLEM_KIMOTORO_ESKEITAN_COMMANDER_SKIN  : 64098,
    GOLEM_MALAGRIN_MARSHLIGHT_SKIN  : 53011,
    GOLEM_MATIGU_SEABEAST_SKIN  : 40616,
    GOLEM_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47619,
    GOLEM_OCTOPUS_RED_FLIGHT_SKIN  : 46733,
    GOLEM_RAATA_SUNSET_SKIN  : 36668,
    GOLEM_STATE_POLICE_SKIN  : 60032,
    GOLEM_STEEL_CARDINAL_SKIN  : 46133,
    GOLEM_VITALSHIFT_EGO_SKIN  : 52560,
    GOLEM_WIYRKOMI_SKIN  : 36361,
    GOLEM_ZAKURA_SHUMYU_SKIN  : 57070,
    GOLEM_ZENTO_ISIDEKO_COMBINE_SKIN  : 53193,
    GOLEM_ZYDRINE_BURN_SKIN  : 76956,
    GORGET_SMALL_ARMOR_REPAIRER_I  : 23795,
    GORUS_SHUTTLE  : 21097,
    GOTAN_KREISSS_TAG  : 17304,
    GOTANS_MODIFIED_100MN_AFTERBURNER  : 14488,
    GOTANS_MODIFIED_500MN_MICROWARPDRIVE  : 14496,
    GOTANS_MODIFIED_800MM_REPEATING_CANNON  : 14459,
    GOTANS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14532,
    GOTANS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14520,
    GOTANS_MODIFIED_DUAL_425MM_AUTOCANNON  : 14471,
    GOTANS_MODIFIED_DUAL_650MM_REPEATING_CANNON  : 14475,
    GOTANS_MODIFIED_EM_COATING  : 14584,
    GOTANS_MODIFIED_EXPLOSIVE_COATING  : 14576,
    GOTANS_MODIFIED_GYROSTABILIZER  : 14540,
    GOTANS_MODIFIED_HEAVY_WARP_DISRUPTOR  : 14660,
    GOTANS_MODIFIED_HEAVY_WARP_SCRAMBLER  : 14668,
    GOTANS_MODIFIED_KINETIC_COATING  : 14568,
    GOTANS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14554,
    GOTANS_MODIFIED_LARGE_PROTON_SMARTBOMB  : 14548,
    GOTANS_MODIFIED_MULTISPECTRUM_COATING  : 14560,
    GOTANS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33455,
    GOTANS_MODIFIED_STASIS_WEBIFIER  : 14652,
    GOTANS_MODIFIED_THERMAL_COATING  : 14592,
    GOTANS_MODIFIED_TORPEDO_LAUNCHER  : 14526,
    GOTANS_MODIFIED_TRACKING_ENHANCER  : 14644,
    GRAM_I  : 40361,
    GRAM_I_BLUEPRINT  : 41385,
    GRAM_II  : 40553,
    GRAND_TIEGJON_CASINO_CHIP  : 37881,
    GRAPHENE_NANORIBBONS  : 30309,
    GRAPHENE_NANORIBBONS_REACTION_FORMULA  : 46163,
    GRAVID_10000MN_AFTERBURNER_MUTAPLASMID  : 56277,
    GRAVID_100MN_AFTERBURNER_MUTAPLASMID  : 47756,
    GRAVID_10MN_AFTERBURNER_MUTAPLASMID  : 47752,
    GRAVID_1MN_AFTERBURNER_MUTAPLASMID  : 47748,
    GRAVID_50000MN_MICROWARPDRIVE_MUTAPLASMID  : 56280,
    GRAVID_500MN_MICROWARPDRIVE_MUTAPLASMID  : 47744,
    GRAVID_50MN_MICROWARPDRIVE_MUTAPLASMID  : 47741,
    GRAVID_5MN_MICROWARPDRIVE_MUTAPLASMID  : 47739,
    GRAVID_ASSAULT_DAMAGE_CONTROL_MUTAPLASMID  : 52229,
    GRAVID_BALLISTIC_CONTROL_SYSTEM_MUTAPLASMID  : 49740,
    GRAVID_CAPITAL_ARMOR_REPAIRER_MUTAPLASMID  : 56283,
    GRAVID_CAPITAL_ENERGY_NEUTRALIZER_MUTAPLASMID  : 56294,
    GRAVID_CAPITAL_ENERGY_NOSFERATU_MUTAPLASMID  : 56291,
    GRAVID_CAPITAL_SHIELD_BOOSTER_MUTAPLASMID  : 56287,
    GRAVID_DAMAGE_CONTROL_MUTAPLASMID  : 52225,
    GRAVID_ENTROPIC_RADIATION_SINK_MUTAPLASMID  : 49736,
    GRAVID_GYROSTABILIZER_MUTAPLASMID  : 49732,
    GRAVID_HEAT_SINK_MUTAPLASMID  : 49728,
    GRAVID_HEAVY_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47831,
    GRAVID_HEAVY_ENERGY_NOSFERATU_MUTAPLASMID  : 48425,
    GRAVID_HEAVY_WARP_DISRUPTOR_MUTAPLASMID  : 56273,
    GRAVID_HEAVY_WARP_SCRAMBLER_MUTAPLASMID  : 56271,
    GRAVID_LARGE_ARMOR_PLATE_MUTAPLASMID  : 47819,
    GRAVID_LARGE_ARMOR_REPAIRER_MUTAPLASMID  : 47776,
    GRAVID_LARGE_CAP_BATTERY_MUTAPLASMID  : 48437,
    GRAVID_LARGE_SHIELD_BOOSTER_MUTAPLASMID  : 47788,
    GRAVID_LARGE_SHIELD_EXTENDER_MUTAPLASMID  : 47807,
    GRAVID_MAGNETIC_FIELD_STABILIZER_MUTAPLASMID  : 49724,
    GRAVID_MEDIUM_ARMOR_PLATE_MUTAPLASMID  : 47815,
    GRAVID_MEDIUM_ARMOR_REPAIRER_MUTAPLASMID  : 47772,
    GRAVID_MEDIUM_CAP_BATTERY_MUTAPLASMID  : 48433,
    GRAVID_MEDIUM_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47827,
    GRAVID_MEDIUM_ENERGY_NOSFERATU_MUTAPLASMID  : 48421,
    GRAVID_MEDIUM_SHIELD_BOOSTER_MUTAPLASMID  : 47784,
    GRAVID_MEDIUM_SHIELD_EXTENDER_MUTAPLASMID  : 47803,
    GRAVID_SIEGE_MODULE_MUTAPLASMID  : 56301,
    GRAVID_SMALL_ARMOR_PLATE_MUTAPLASMID  : 47811,
    GRAVID_SMALL_ARMOR_REPAIRER_MUTAPLASMID  : 47768,
    GRAVID_SMALL_CAP_BATTERY_MUTAPLASMID  : 48429,
    GRAVID_SMALL_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47823,
    GRAVID_SMALL_ENERGY_NOSFERATU_MUTAPLASMID  : 48417,
    GRAVID_SMALL_SHIELD_BOOSTER_MUTAPLASMID  : 47780,
    GRAVID_SMALL_SHIELD_EXTENDER_MUTAPLASMID  : 47799,
    GRAVID_STASIS_WEBIFIER_MUTAPLASMID  : 47701,
    GRAVID_WARP_DISRUPTOR_MUTAPLASMID  : 47735,
    GRAVID_WARP_SCRAMBLER_MUTAPLASMID  : 47731,
    GRAVID_X_LARGE_SHIELD_BOOSTER_MUTAPLASMID  : 47792,
    GRAVIMETRIC_BACKUP_ARRAY_I_BLUEPRINT  : 11820,
    GRAVIMETRIC_ECM_I  : 1955,
    GRAVIMETRIC_ECM_I_BLUEPRINT  : 11631,
    GRAVIMETRIC_ECM_II  : 2571,
    GRAVIMETRIC_FTL_INTERLINK_COMMUNICATOR  : 57475,
    GRAVIMETRIC_FTL_INTERLINK_COMMUNICATOR_BLUEPRINT  : 57512,
    GRAVIMETRIC_SENSOR_CLUSTER  : 11534,
    GRAVIMETRIC_SENSOR_CLUSTER_BLUEPRINT  : 17340,
    GRAVIMETRIC_SENSOR_COMPENSATION  : 33000,
    GRAVITATIONAL_TRANSPORTATION_FIELD_OSCILLATOR  : 40634,
    GRAVITATIONAL_TRANSPORTATION_FIELD_OSCILLATOR_BLUEPRINT  : 41438,
    GRAVITON_PHYSICS  : 11446,
    GRAVITON_PULSE_GENERATOR  : 11693,
    GRAVITON_PULSE_GENERATOR_BLUEPRINT  : 17332,
    GRAVITON_REACTOR_UNIT  : 11550,
    GRAVITON_REACTOR_UNIT_BLUEPRINT  : 17353,
    GREAVES_MEDIUM_ARMOR_REPAIRER_I  : 23797,
    GRIFFIN  : 584,
    GRIFFIN_BIOSECURITY_RESPONDERS_SKIN  : 55535,
    GRIFFIN_BLUE_TIGER_SKIN  : 36707,
    GRIFFIN_BLUEPRINT  : 685,
    GRIFFIN_EXOPLANETS_HUNTER_SKIN  : 45814,
    GRIFFIN_FORWARD_4_4_SKIN  : 56861,
    GRIFFIN_GHOSTBIRD_SKIN  : 48157,
    GRIFFIN_GLACIAL_DRIFT_SKIN  : 44151,
    GRIFFIN_GURISTAS_SKIN  : 54553,
    GRIFFIN_LUMINAIRE_ZENITH_SKIN  : 48222,
    GRIFFIN_MATIGU_SEABEAST_SKIN  : 40581,
    GRIFFIN_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59427,
    GRIFFIN_NAVY_ISSUE  : 37455,
    GRIFFIN_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55548,
    GRIFFIN_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45827,
    GRIFFIN_NAVY_ISSUE_FORWARD_4_4_SKIN  : 58861,
    GRIFFIN_NAVY_ISSUE_GHOSTBIRD_SKIN  : 48170,
    GRIFFIN_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46837,
    GRIFFIN_NAVY_ISSUE_SPIRIT_SKIN  : 57388,
    GRIFFIN_NAVY_ISSUE_STEEL_CARDINAL_SKIN  : 46104,
    GRIFFIN_NAVY_ISSUE_WIYRKOMI_SKIN  : 40331,
    GRIFFIN_NAVY_ISSUE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53164,
    GRIFFIN_RAATA_SUNSET_SKIN  : 36635,
    GRIFFIN_SPIRIT_SKIN  : 47310,
    GRIFFIN_STEEL_CARDINAL_SKIN  : 46091,
    GRIFFIN_ZENTO_ISIDEKO_COMBINE_SKIN  : 53151,
    GUARDIAN  : 11987,
    GUARDIAN_ANTIMATTER_CHARGE_L  : 20991,
    GUARDIAN_ANTIMATTER_CHARGE_M  : 20975,
    GUARDIAN_ANTIMATTER_CHARGE_S  : 20959,
    GUARDIAN_ANTIMATTER_CHARGE_XL  : 20993,
    GUARDIAN_BIOSECURITY_RESPONDERS_SKIN  : 55355,
    GUARDIAN_BLUEPRINT  : 11988,
    GUARDIAN_COLD_IRON_SKIN  : 42773,
    GUARDIAN_EDENS_HUNTERS_SKIN  : 54909,
    GUARDIAN_EMERGENCY_RESPONSE_SQUAD_SKIN  : 48723,
    GUARDIAN_EOM_SKIN  : 36295,
    GUARDIAN_EXOPLANETS_HUNTER_SKIN  : 45781,
    GUARDIAN_FRONTIER_SAFEGUARDER_SKIN  : 54433,
    GUARDIAN_GLACIAL_DRIFT_SKIN  : 46790,
    GUARDIAN_IGC_SKIN  : 48546,
    GUARDIAN_IMPERIAL_JUBILEE_SKIN  : 46530,
    GUARDIAN_IMPERIAL_NAVY_SKIN  : 58900,
    GUARDIAN_IRIDIUM_CHARGE_L  : 20981,
    GUARDIAN_IRIDIUM_CHARGE_M  : 20965,
    GUARDIAN_IRIDIUM_CHARGE_S  : 20949,
    GUARDIAN_IRIDIUM_CHARGE_XL  : 20995,
    GUARDIAN_IRON_CHARGE_L  : 20977,
    GUARDIAN_IRON_CHARGE_M  : 20961,
    GUARDIAN_IRON_CHARGE_S  : 20945,
    GUARDIAN_IRON_CHARGE_XL  : 20997,
    GUARDIAN_KYBERNAUT_CLADE_SKIN  : 54918,
    GUARDIAN_LEAD_CHARGE_L  : 20983,
    GUARDIAN_LEAD_CHARGE_M  : 20967,
    GUARDIAN_LEAD_CHARGE_S  : 20951,
    GUARDIAN_LEAD_CHARGE_XL  : 20999,
    GUARDIAN_OASIS_OF_FAITH_SKIN  : 53623,
    GUARDIAN_PLUTONIUM_CHARGE_L  : 20989,
    GUARDIAN_PLUTONIUM_CHARGE_M  : 20973,
    GUARDIAN_PLUTONIUM_CHARGE_S  : 20957,
    GUARDIAN_PLUTONIUM_CHARGE_XL  : 21001,
    GUARDIAN_PURITY_OF_THE_THRONE_SKIN  : 42572,
    GUARDIAN_RAATA_SUNSET_SKIN  : 37507,
    GUARDIAN_STAR_CAPTAIN_SKIN  : 48242,
    GUARDIAN_STARLINE_MAJESTIC_SKIN  : 55719,
    GUARDIAN_THORIUM_CHARGE_L  : 20985,
    GUARDIAN_THORIUM_CHARGE_M  : 20969,
    GUARDIAN_THORIUM_CHARGE_S  : 20953,
    GUARDIAN_THORIUM_CHARGE_XL  : 21003,
    GUARDIAN_TUNGSTEN_CHARGE_L  : 20979,
    GUARDIAN_TUNGSTEN_CHARGE_M  : 20963,
    GUARDIAN_TUNGSTEN_CHARGE_S  : 20947,
    GUARDIAN_TUNGSTEN_CHARGE_XL  : 21005,
    GUARDIAN_URANIUM_CHARGE_L  : 20987,
    GUARDIAN_URANIUM_CHARGE_M  : 20971,
    GUARDIAN_URANIUM_CHARGE_S  : 20955,
    GUARDIAN_URANIUM_CHARGE_XL  : 21007,
    GUARDIAN_VEXOR  : 11011,
    GUARDIANS_GALA_SURPRISE_GIFT  : 50045,
    GUARDIANS_GALA_SURPRISE_GIFT_DAMAGED  : 50047,
    GUARDIANS_GALA_VIP_SURPRISE_GIFT  : 50046,
    GUARDIANS_GALA_VIP_SURPRISE_GIFT_DAMAGED  : 50048,
    GUIDANCE_DISRUPTOR_I  : 37543,
    GUIDANCE_DISRUPTOR_I_BLUEPRINT  : 40338,
    GUIDANCE_DISRUPTOR_II  : 37546,
    GUIDANCE_SYSTEMS  : 9834,
    GUIDED_MISSILE_PRECISION  : 20312,
    GUILLOME_RENARDS_SLEEPER_LOOT_STASH  : 34431,
    GUILLOTINE_THERMAL_REAPER  : 41443,
    GUILLOTINE_THERMAL_REAPER_BLUEPRINT  : 41450,
    GUNGNIR_I  : 40365,
    GUNGNIR_I_BLUEPRINT  : 41365,
    GUNGNIR_II  : 40564,
    GUNNERY  : 3300,
    GURISTAS_ANTIMATTER_CHARGE_L  : 21366,
    GURISTAS_ANTIMATTER_CHARGE_M  : 21350,
    GURISTAS_ANTIMATTER_CHARGE_S  : 21334,
    GURISTAS_ANTIMATTER_CHARGE_XL  : 21368,
    GURISTAS_BRASS_TAG  : 17207,
    GURISTAS_BRONZE_TAG  : 12542,
    GURISTAS_CAIMAN_BLUEPRINT_ARMORED_CACHE  : 46602,
    GURISTAS_CONTROL_TOWER  : 27533,
    GURISTAS_CONTROL_TOWER_MEDIUM  : 27595,
    GURISTAS_CONTROL_TOWER_SMALL  : 27598,
    GURISTAS_COPPER_TAG  : 17205,
    GURISTAS_CRUISE_MISSILE_BATTERY  : 27638,
    GURISTAS_CRYSTAL_TAG  : 12546,
    GURISTAS_DATA_SEQUENCE  : 33988,
    GURISTAS_DIAMOND_TAG  : 17206,
    GURISTAS_ELECTRUM_TAG  : 17209,
    GURISTAS_GOLD_TAG  : 12544,
    GURISTAS_GRAVITON_HARDENING  : 21728,
    GURISTAS_GRAVITY_FOCUSER  : 21727,
    GURISTAS_HEAVY_WEAPON_CONSOLE  : 21724,
    GURISTAS_INFERNO_CRUISE_MISSILE  : 27409,
    GURISTAS_INFERNO_HEAVY_ASSAULT_MISSILE  : 27403,
    GURISTAS_INFERNO_HEAVY_MISSILE  : 27445,
    GURISTAS_INFERNO_LIGHT_MISSILE  : 27367,
    GURISTAS_INFERNO_ROCKET  : 27313,
    GURISTAS_INFERNO_TORPEDO  : 27349,
    GURISTAS_INFERNO_XL_CRUISE_MISSILE  : 2182,
    GURISTAS_INFERNO_XL_TORPEDO  : 29618,
    GURISTAS_INFILTRATOR_CLONE_CORPSE  : 63698,
    GURISTAS_ION_FIELD_PROJECTION_BATTERY  : 27574,
    GURISTAS_IRIDIUM_CHARGE_L  : 21356,
    GURISTAS_IRIDIUM_CHARGE_M  : 21340,
    GURISTAS_IRIDIUM_CHARGE_S  : 21324,
    GURISTAS_IRIDIUM_CHARGE_XL  : 21370,
    GURISTAS_IRON_CHARGE_L  : 21352,
    GURISTAS_IRON_CHARGE_M  : 21336,
    GURISTAS_IRON_CHARGE_S  : 21320,
    GURISTAS_IRON_CHARGE_XL  : 21372,
    GURISTAS_KOMODO_BLUEPRINT_ARMORED_CACHE  : 46603,
    GURISTAS_LEAD_CHARGE_L  : 21358,
    GURISTAS_LEAD_CHARGE_M  : 21342,
    GURISTAS_LEAD_CHARGE_S  : 21326,
    GURISTAS_LEAD_CHARGE_XL  : 21374,
    GURISTAS_LIGHT_WEAPON_CONSOLE  : 21726,
    GURISTAS_LOGGERHEAD_BLUEPRINT_ARMORED_CACHE  : 46601,
    GURISTAS_MEDIUM_WEAPON_CONSOLE  : 21725,
    GURISTAS_MJOLNIR_CRUISE_MISSILE  : 27373,
    GURISTAS_MJOLNIR_HEAVY_ASSAULT_MISSILE  : 27417,
    GURISTAS_MJOLNIR_HEAVY_MISSILE  : 27433,
    GURISTAS_MJOLNIR_LIGHT_MISSILE  : 27383,
    GURISTAS_MJOLNIR_ROCKET  : 27319,
    GURISTAS_MJOLNIR_TORPEDO  : 27337,
    GURISTAS_MJOLNIR_XL_CRUISE_MISSILE  : 2188,
    GURISTAS_MJOLNIR_XL_TORPEDO  : 29622,
    GURISTAS_NOVA_CRUISE_MISSILE  : 27427,
    GURISTAS_NOVA_HEAVY_ASSAULT_MISSILE  : 27393,
    GURISTAS_NOVA_HEAVY_MISSILE  : 27451,
    GURISTAS_NOVA_LIGHT_MISSILE  : 27379,
    GURISTAS_NOVA_ROCKET  : 27325,
    GURISTAS_NOVA_TORPEDO  : 27357,
    GURISTAS_NOVA_XL_CRUISE_MISSILE  : 2178,
    GURISTAS_NOVA_XL_TORPEDO  : 29616,
    GURISTAS_PALLADIUM_TAG  : 17208,
    GURISTAS_PHASE_INVERSION_BATTERY  : 27577,
    GURISTAS_PLATINUM_TAG  : 12545,
    GURISTAS_PLUTONIUM_CHARGE_L  : 21364,
    GURISTAS_PLUTONIUM_CHARGE_M  : 21348,
    GURISTAS_PLUTONIUM_CHARGE_S  : 21332,
    GURISTAS_PLUTONIUM_CHARGE_XL  : 21376,
    GURISTAS_PRODUCTION_STRONG_BOX  : 43697,
    GURISTAS_SCOURGE_CRUISE_MISSILE  : 27391,
    GURISTAS_SCOURGE_HEAVY_ASSAULT_MISSILE  : 27411,
    GURISTAS_SCOURGE_HEAVY_MISSILE  : 27439,
    GURISTAS_SCOURGE_LIGHT_MISSILE  : 27353,
    GURISTAS_SCOURGE_ROCKET  : 27331,
    GURISTAS_SCOURGE_TORPEDO  : 27343,
    GURISTAS_SCOURGE_XL_CRUISE_MISSILE  : 2180,
    GURISTAS_SCOURGE_XL_TORPEDO  : 29620,
    GURISTAS_SHUTTLE  : 21628,
    GURISTAS_SILVER_TAG  : 12543,
    GURISTAS_SPATIAL_DESTABILIZATION_BATTERY  : 27580,
    GURISTAS_STANDUP_MODULE_BLUEPRINT_ARMORED_CACHE  : 46580,
    GURISTAS_THORIUM_CHARGE_L  : 21360,
    GURISTAS_THORIUM_CHARGE_M  : 21344,
    GURISTAS_THORIUM_CHARGE_S  : 21328,
    GURISTAS_THORIUM_CHARGE_XL  : 21378,
    GURISTAS_TORPEDO_BATTERY  : 27641,
    GURISTAS_TUNGSTEN_CHARGE_L  : 21354,
    GURISTAS_TUNGSTEN_CHARGE_M  : 21338,
    GURISTAS_TUNGSTEN_CHARGE_S  : 21322,
    GURISTAS_TUNGSTEN_CHARGE_XL  : 21380,
    GURISTAS_URANIUM_CHARGE_L  : 21362,
    GURISTAS_URANIUM_CHARGE_M  : 21346,
    GURISTAS_URANIUM_CHARGE_S  : 21330,
    GURISTAS_URANIUM_CHARGE_XL  : 21382,
    GURISTAS_VICTORY_SKIN  : 45552,
    GURISTAS_WHITE_NOISE_GENERATION_BATTERY  : 27583,
    GURISTAS_XL_TORPEDO_BATTERY  : 27560,
    GYROSTABILIZER_I  : 520,
    GYROSTABILIZER_I_BLUEPRINT  : 1096,
    GYROSTABILIZER_II  : 519,
    HACKING  : 21718,
    HADAL_BEZDNACINE  : 56628,
    HADAL_RAKOVENE  : 56630,
    HADAL_TALASSONITE  : 56626,
    HAFNIUM  : 16648,
    HAIL_L  : 12779,
    HAIL_M  : 12777,
    HAIL_S  : 12608,
    HAIL_XL  : 41314,
    HAIR_ACCESSORY_SHIPMENT_DAMAGED  : 49707,
    HAKIM_STORMARES_TAG  : 17305,
    HAKIMS_MODIFIED_100MN_AFTERBURNER  : 14486,
    HAKIMS_MODIFIED_1200MM_ARTILLERY_CANNON  : 14461,
    HAKIMS_MODIFIED_1400MM_HOWITZER_ARTILLERY  : 14465,
    HAKIMS_MODIFIED_500MN_MICROWARPDRIVE  : 14494,
    HAKIMS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14528,
    HAKIMS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14518,
    HAKIMS_MODIFIED_EM_SHIELD_AMPLIFIER  : 14630,
    HAKIMS_MODIFIED_EXPLOSIVE_SHIELD_AMPLIFIER  : 14606,
    HAKIMS_MODIFIED_GYROSTABILIZER  : 14538,
    HAKIMS_MODIFIED_HEAVY_WARP_DISRUPTOR  : 14658,
    HAKIMS_MODIFIED_HEAVY_WARP_SCRAMBLER  : 14666,
    HAKIMS_MODIFIED_KINETIC_SHIELD_AMPLIFIER  : 14622,
    HAKIMS_MODIFIED_LARGE_PROTON_SMARTBOMB  : 14546,
    HAKIMS_MODIFIED_LARGE_SHIELD_BOOSTER  : 14597,
    HAKIMS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33456,
    HAKIMS_MODIFIED_SHIELD_BOOST_AMPLIFIER  : 14636,
    HAKIMS_MODIFIED_STASIS_WEBIFIER  : 14650,
    HAKIMS_MODIFIED_THERMAL_SHIELD_AMPLIFIER  : 14614,
    HAKIMS_MODIFIED_TORPEDO_LAUNCHER  : 14525,
    HAKIMS_MODIFIED_TRACKING_ENHANCER  : 14642,
    HAKIMS_MODIFIED_X_LARGE_SHIELD_BOOSTER  : 14601,
    HALBERD_MEGA_PULSE_LASER_I  : 23846,
    HALCYON_CORE_EQUALIZER_I  : 16303,
    HALLOWEEN_HORRORS_ASTERO_SKIN  : 53055,
    HALLOWEEN_HORRORS_AVATAR_SKIN_AND_FIREWORKS_CRATE  : 53059,
    HALLOWEEN_HORRORS_FIREWORK  : 52219,
    HALLOWEEN_HORRORS_FIREWORKS_AND_LAUNCHER_CRATE  : 53053,
    HALLOWEEN_HORRORS_FIREWORKS_CRATE  : 53056,
    HALLOWEEN_HORRORS_GILA_SKIN  : 53057,
    HALLOWEEN_HORRORS_HEADHUNTER_CRUISER_SKINS  : 53060,
    HALLOWEEN_HORRORS_MACHARIEL_SKIN  : 53058,
    HALTING_COMPACT_LADAR_ECM  : 19937,
    HAMMERHEAD_I  : 2183,
    HAMMERHEAD_I_BLUEPRINT  : 2184,
    HAMMERHEAD_II  : 2185,
    HAMMERHEAD_SD_600  : 23713,
    HAMMERHEAD_SD_600_BLUEPRINT  : 23714,
    HARBINGER  : 24696,
    HARBINGER_AURORA_UNIVERSALIS_SKIN  : 53366,
    HARBINGER_BIOSECURITY_RESPONDERS_SKIN  : 55307,
    HARBINGER_BLOOD_RAIDERS_SKIN  : 36915,
    HARBINGER_BLUEPRINT  : 24697,
    HARBINGER_COLD_IRON_SKIN  : 42778,
    HARBINGER_EXOPLANETS_HUNTER_SKIN  : 45787,
    HARBINGER_GALNET_STREAMCAST_UNIT_SKIN  : 57737,
    HARBINGER_GLACIAL_DRIFT_SKIN  : 46796,
    HARBINGER_IGC_SKIN  : 48547,
    HARBINGER_IMPERIAL_JUBILEE_SKIN  : 46532,
    HARBINGER_IRONBLOOD_SKIN  : 43510,
    HARBINGER_KADOR_SKIN  : 34601,
    HARBINGER_KHANID_SKIN  : 34602,
    HARBINGER_NAVY_ISSUE  : 33155,
    HARBINGER_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55334,
    HARBINGER_NAVY_ISSUE_COLD_IRON_SKIN  : 42783,
    HARBINGER_NAVY_ISSUE_EOM_SKIN  : 36325,
    HARBINGER_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45792,
    HARBINGER_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46801,
    HARBINGER_NAVY_ISSUE_IGC_SKIN  : 48548,
    HARBINGER_NAVY_ISSUE_IMPERIAL_JUBILEE_SKIN  : 48851,
    HARBINGER_NAVY_ISSUE_OASIS_OF_FAITH_SKIN  : 53634,
    HARBINGER_NAVY_ISSUE_PURITY_OF_THE_THRONE_SKIN  : 42583,
    HARBINGER_NAVY_ISSUE_SARUM_SKIN  : 56794,
    HARBINGER_NAVY_ISSUE_SPIRIT_SKIN  : 57386,
    HARBINGER_OASIS_OF_FAITH_SKIN  : 53629,
    HARBINGER_PURITY_OF_THE_THRONE_SKIN  : 42578,
    HARBINGER_RAATA_SUNSET_SKIN  : 37510,
    HARBINGER_SPIRIT_SKIN  : 47306,
    HARBINGER_WAR_RESERVES_LIEUTENANT_SKIN  : 59215,
    HARBINGER_YOIUL_STAR_SKIN  : 61122,
    HARDWIRING___ZAINOU_SHARPSHOOTER_ZMX11  : 3149,
    HARDWIRING___ZAINOU_SHARPSHOOTER_ZMX110  : 3150,
    HARDWIRING___ZAINOU_SHARPSHOOTER_ZMX1100  : 3151,
    HARMONY_SMALL_ARMOR_REPAIRER_I  : 22887,
    HARNESS_MEDIUM_CAPACITOR_BOOSTER_I  : 23809,
    HARPY  : 11381,
    HARPY_BIOSECURITY_RESPONDERS_SKIN  : 55569,
    HARPY_BLUE_TIGER_SKIN  : 36711,
    HARPY_BLUEPRINT  : 11382,
    HARPY_EXOPLANETS_HUNTER_SKIN  : 45818,
    HARPY_GALNET_STREAMCAST_UNIT_SKIN  : 57734,
    HARPY_GHOSTBIRD_SKIN  : 48161,
    HARPY_GLACIAL_DRIFT_SKIN  : 46828,
    HARPY_KIMOTORO_ESKEITAN_SKIN  : 52629,
    HARPY_MATIGU_SEABEAST_SKIN  : 40585,
    HARPY_RAATA_SUNSET_SKIN  : 36639,
    HARPY_STATE_POLICE_SKIN  : 72556,
    HARPY_STEEL_CARDINAL_SKIN  : 46095,
    HARPY_WIYRKOMI_SKIN  : 36340,
    HARPY_ZENTO_ISIDEKO_COMBINE_SKIN  : 53155,
    HARVESTER_MINING_DRONE  : 3218,
    HAUBERK_LARGE_ARMOR_REPAIRER_I  : 23799,
    HAWK  : 11379,
    HAWK_BIOSECURITY_RESPONDERS_SKIN  : 55552,
    HAWK_BLUE_TIGER_SKIN  : 36712,
    HAWK_BLUEPRINT  : 11380,
    HAWK_EXOPLANETS_HUNTER_SKIN  : 45819,
    HAWK_GHOSTBIRD_SKIN  : 48162,
    HAWK_GLACIAL_DRIFT_SKIN  : 46829,
    HAWK_KIMOTORO_ESKEITAN_SKIN  : 52630,
    HAWK_MATIGU_SEABEAST_SKIN  : 40586,
    HAWK_RAATA_SUNSET_SKIN  : 36640,
    HAWK_STATE_POLICE_SKIN  : 60028,
    HAWK_STEEL_CARDINAL_SKIN  : 46096,
    HAWK_WIYRKOMI_SKIN  : 36339,
    HAWK_ZENTO_ISIDEKO_COMBINE_SKIN  : 53156,
    HAWK_ZYDRINE_BURN_SKIN  : 76957,
    HAZARDOUS_CHEMICAL_SILO  : 25280,
    HAZMAT_DETECTION_SYSTEMS  : 2366,
    HEADHUNTER_CRATE  : 46756,
    HEAT_DEPRESSOR  : 23165,
    HEAT_DISSIPATION_ARRAY  : 17186,
    HEAT_DISSIPATION_ARRAY_BLUEPRINT  : 2759,
    HEAT_SINK_I  : 2363,
    HEAT_SINK_I_BLUEPRINT  : 11612,
    HEAT_SINK_II  : 2364,
    HEAVY_AFOCAL_MASER_I  : 6961,
    HEAVY_AFOCAL_PULSE_MASER_I  : 6921,
    HEAVY_ANODE_PARTICLE_STREAM_I  : 6965,
    HEAVY_ANODE_PULSE_PARTICLE_STREAM_I  : 6925,
    HEAVY_ARMOR_MAINTENANCE_BOT_I  : 23523,
    HEAVY_ARMOR_MAINTENANCE_BOT_I_BLUEPRINT  : 23524,
    HEAVY_ARMOR_MAINTENANCE_BOT_II  : 28197,
    HEAVY_ASSAULT_CRUISERS  : 16591,
    HEAVY_ASSAULT_MISSILE_LAUNCHER_I  : 20138,
    HEAVY_ASSAULT_MISSILE_LAUNCHER_I_BLUEPRINT  : 26759,
    HEAVY_ASSAULT_MISSILE_LAUNCHER_II  : 25715,
    HEAVY_ASSAULT_MISSILE_SPECIALIZATION  : 25718,
    HEAVY_ASSAULT_MISSILES  : 25719,
    HEAVY_BEAM_LASER_I  : 459,
    HEAVY_BEAM_LASER_I_BLUEPRINT  : 843,
    HEAVY_BEAM_LASER_II  : 3025,
    HEAVY_BRAVE_CAPACITOR_BOOSTER  : 20559,
    HEAVY_CAPACITOR_BOOSTER_I  : 3576,
    HEAVY_CAPACITOR_BOOSTER_I_BLUEPRINT  : 3577,
    HEAVY_CAPACITOR_BOOSTER_II  : 3578,
    HEAVY_COMPACT_ENTROPIC_DISINTEGRATOR  : 47916,
    HEAVY_COMPACT_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 48471,
    HEAVY_DRONE_OPERATION  : 3441,
    HEAVY_ELECTRON_BLASTER_I  : 566,
    HEAVY_ELECTRON_BLASTER_I_BLUEPRINT  : 1117,
    HEAVY_ELECTRON_BLASTER_II  : 3130,
    HEAVY_ENERGY_NEUTRALIZER_I  : 12269,
    HEAVY_ENERGY_NEUTRALIZER_I_BLUEPRINT  : 12270,
    HEAVY_ENERGY_NEUTRALIZER_II  : 12271,
    HEAVY_ENERGY_NOSFERATU_I  : 12261,
    HEAVY_ENERGY_NOSFERATU_I_BLUEPRINT  : 12262,
    HEAVY_ENERGY_NOSFERATU_II  : 12263,
    HEAVY_ENTROPIC_DISINTEGRATOR_I  : 47273,
    HEAVY_ENTROPIC_DISINTEGRATOR_I_BLUEPRINT  : 47971,
    HEAVY_ENTROPIC_DISINTEGRATOR_II  : 47918,
    HEAVY_F_RX_COMPACT_CAPACITOR_BOOSTER  : 5051,
    HEAVY_FAINT_EPSILON_SCOPED_WARP_SCRAMBLER  : 40756,
    HEAVY_FAINT_EPSILON_SCOPED_WARP_SCRAMBLER_BLUEPRINT  : 40757,
    HEAVY_FAINT_SCOPED_WARP_DISRUPTOR  : 40733,
    HEAVY_FAINT_SCOPED_WARP_DISRUPTOR_BLUEPRINT  : 40743,
    HEAVY_FIGHTERS  : 32339,
    HEAVY_FLEETING_COMPACT_WARP_DISRUPTOR  : 40731,
    HEAVY_FLEETING_COMPACT_WARP_DISRUPTOR_BLUEPRINT  : 40741,
    HEAVY_GHOUL_COMPACT_ENERGY_NOSFERATU  : 16497,
    HEAVY_GREMLIN_COMPACT_ENERGY_NEUTRALIZER  : 16475,
    HEAVY_GUNNAR_COMPACT_STASIS_GRAPPLER  : 41054,
    HEAVY_HULL_MAINTENANCE_BOT_I  : 33671,
    HEAVY_HULL_MAINTENANCE_BOT_I_BLUEPRINT  : 33672,
    HEAVY_HULL_MAINTENANCE_BOT_II  : 33708,
    HEAVY_INFECTIOUS_SCOPED_ENERGY_NEUTRALIZER  : 16477,
    HEAVY_INITIATED_COMPACT_WARP_SCRAMBLER  : 40752,
    HEAVY_INITIATED_COMPACT_WARP_SCRAMBLER_BLUEPRINT  : 40753,
    HEAVY_INTERDICTION_CRUISERS  : 28609,
    HEAVY_ION_BLASTER_I  : 569,
    HEAVY_ION_BLASTER_I_BLUEPRINT  : 1120,
    HEAVY_ION_BLASTER_II  : 3138,
    HEAVY_J5_ENDURING_WARP_DISRUPTOR  : 40732,
    HEAVY_J5_ENDURING_WARP_DISRUPTOR_BLUEPRINT  : 40742,
    HEAVY_J5B_ENDURING_WARP_SCRAMBLER  : 40754,
    HEAVY_J5B_ENDURING_WARP_SCRAMBLER_BLUEPRINT  : 40755,
    HEAVY_JIGORO_ENDURING_STASIS_GRAPPLER  : 41056,
    HEAVY_KARELIN_SCOPED_STASIS_GRAPPLER  : 41055,
    HEAVY_KNAVE_SCOPED_ENERGY_NOSFERATU  : 16499,
    HEAVY_METALS  : 2272,
    HEAVY_MISSILE_LAUNCHER_I  : 501,
    HEAVY_MISSILE_LAUNCHER_I_BLUEPRINT  : 788,
    HEAVY_MISSILE_LAUNCHER_II  : 2410,
    HEAVY_MISSILE_SPECIALIZATION  : 20211,
    HEAVY_MISSILES  : 3324,
    HEAVY_MOAT_ENERGY_NEUTRALIZER  : 23819,
    HEAVY_MODAL_LASER_I  : 6959,
    HEAVY_MODAL_PULSE_LASER_I  : 6919,
    HEAVY_MODULATED_ENERGY_BEAM_I  : 6963,
    HEAVY_MODULATED_PULSE_ENERGY_BEAM_I  : 6923,
    HEAVY_MUTADAPTIVE_COMPACT_REMOTE_ARMOR_REPAIRER  : 49772,
    HEAVY_MUTADAPTIVE_REMOTE_ARMOR_REPAIRER_I  : 49770,
    HEAVY_MUTADAPTIVE_REMOTE_ARMOR_REPAIRER_II  : 49773,
    HEAVY_MUTADAPTIVE_SCOPED_REMOTE_ARMOR_REPAIRER  : 49771,
    HEAVY_NEUTRON_BLASTER_I  : 568,
    HEAVY_NEUTRON_BLASTER_I_BLUEPRINT  : 1119,
    HEAVY_NEUTRON_BLASTER_II  : 3146,
    HEAVY_PULSE_LASER_I  : 458,
    HEAVY_PULSE_LASER_I_BLUEPRINT  : 842,
    HEAVY_PULSE_LASER_II  : 3520,
    HEAVY_SCOPED_ENTROPIC_DISINTEGRATOR  : 47917,
    HEAVY_SCOPED_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 48472,
    HEAVY_SHIELD_MAINTENANCE_BOT_I  : 22765,
    HEAVY_SHIELD_MAINTENANCE_BOT_I_BLUEPRINT  : 22766,
    HEAVY_SHIELD_MAINTENANCE_BOT_II  : 28199,
    HEAVY_STASIS_GRAPPLER_I  : 41040,
    HEAVY_STASIS_GRAPPLER_I_BLUEPRINT  : 41221,
    HEAVY_STASIS_GRAPPLER_II  : 41057,
    HEAVY_VRYKOLAKAS_ENERGY_NOSFERATU  : 23829,
    HEAVY_WARP_DISRUPTOR_I  : 40730,
    HEAVY_WARP_DISRUPTOR_I_BLUEPRINT  : 40740,
    HEAVY_WARP_DISRUPTOR_II  : 40734,
    HEAVY_WARP_SCRAMBLER_I  : 40750,
    HEAVY_WARP_SCRAMBLER_I_BLUEPRINT  : 40751,
    HEAVY_WARP_SCRAMBLER_II  : 40758,
    HEAVY_WATER  : 16272,
    HECATE  : 35683,
    HECATE_BIOSECURITY_RESPONDERS_SKIN  : 55584,
    HECATE_CAILLE_NEON_SKIN  : 50146,
    HECATE_DOS_ROUVENORS_BEQUEST_SKIN  : 59311,
    HECATE_EXOPLANETS_HUNTER_SKIN  : 45891,
    HECATE_GLACIAL_DRIFT_SKIN  : 46941,
    HECATE_GLITTERING_DREAM_SKIN  : 47528,
    HECATE_HEARTSURGE_SKIN  : 53957,
    HECATE_INNER_ZONE_VANGUARD_SKIN  : 52726,
    HECATE_KOPIS_EDGE_SKIN  : 46403,
    HECATE_MALAGRIN_MARSHLIGHT_SKIN  : 56596,
    HECATE_RUBY_SUNGRAZER_SKIN  : 45564,
    HECATE_SAPPHIRE_SUNGRAZER_SKIN  : 46552,
    HECATE_SCOPE_SYNDICATION_YC122_SKIN  : 61190,
    HECATE_SERPENTIS_SKIN  : 42165,
    HECATE_SPIRIT_SKIN  : 44909,
    HECATE_YOIUL_STAR_SKIN  : 61125,
    HEDBERGITE  : 21,
    HEDBERGITE_PROCESSING  : 12185,
    HEIST_RADAR_ECM  : 20579,
    HEL  : 22852,
    HEL_BIOSECURITY_RESPONDERS_SKIN  : 55665,
    HEL_BLOODY_HANDS_SKIN  : 47179,
    HEL_BLUE_TIGER_SKIN  : 36836,
    HEL_BLUEPRINT  : 22853,
    HEL_CAPSULEER_ELITE_XIX_SKIN  : 63694,
    HEL_CHAINBREAKER_KHUMAAK_SKIN  : 48525,
    HEL_EXOPLANETS_HUNTER_SKIN  : 45982,
    HEL_FIREWALL_BREACH_SKIN  : 46014,
    HEL_FREEDOMS_REVOLUTION_SKIN  : 55716,
    HEL_GLACIAL_DRIFT_SKIN  : 46919,
    HEL_HAZARD_CONTROL_SKIN  : 45556,
    HEL_HEADHUNTER_SKIN  : 46726,
    HEL_HUNTERS_QUIVER_SKIN  : 47508,
    HEL_IN_RUST_WE_TRUST_SKIN  : 52434,
    HEL_INTERSTELLAR_CONVERGENCE_SKIN  : 62229,
    HEL_JUSTICE_SKIN  : 36449,
    HEL_MALAGRIN_MARSHLIGHT_SKIN  : 53018,
    HEL_SEBIESTOR_SKIN  : 34658,
    HEL_SNOWLINE_BLADERACER_SKIN  : 52893,
    HEL_TRONHADAR_INK_SKIN  : 40507,
    HEL_ULTRAVIOLENCE_VORTEX_SKIN  : 58801,
    HEL_VALKLEAR_GLORY_SKIN  : 36789,
    HEL_YOIUL_STAR_SKIN  : 57163,
    HELIOS  : 11172,
    HELIOS_BIOSECURITY_RESPONDERS_SKIN  : 55624,
    HELIOS_BLUEPRINT  : 11173,
    HELIOS_EXOPLANETS_HUNTER_SKIN  : 45879,
    HELIOS_GLACIAL_DRIFT_SKIN  : 46931,
    HELIOS_INNER_ZONE_VANGUARD_SKIN  : 52714,
    HELIOS_INTAKI_SYNDICATE_SKIN  : 36377,
    HELIOS_KOPIS_EDGE_SKIN  : 46391,
    HELIOS_LUMINAIRE_RISING_SKIN  : 64413,
    HELIOS_RUBY_SUNGRAZER_SKIN  : 45561,
    HELIOS_SAPPHIRE_SUNGRAZER_SKIN  : 46553,
    HELIOS_SERPENTIS_SKIN  : 42155,
    HELIOS_SPIRIT_SKIN  : 44897,
    HELIOS_VALIMOR_LEGACY_SKIN  : 37555,
    HELIOS_VERSUS_REDFORCE_SKIN  : 62018,
    HELIUM_FUEL_BLOCK  : 4247,
    HELIUM_FUEL_BLOCK_BLUEPRINT  : 4315,
    HELIUM_ISOTOPES  : 16274,
    HEMATOS  : 33079,
    HEMORPHITE  : 1231,
    HEMORPHITE_PROCESSING  : 12186,
    HERETIC  : 22452,
    HERETIC_AURORA_UNIVERSALIS_SKIN  : 57010,
    HERETIC_BIOSECURITY_RESPONDERS_SKIN  : 55345,
    HERETIC_BLUEPRINT  : 22453,
    HERETIC_COLD_IRON_SKIN  : 42765,
    HERETIC_EOM_SKIN  : 36313,
    HERETIC_EXOPLANETS_HUNTER_SKIN  : 45772,
    HERETIC_GALNET_STREAMCAST_UNIT_SKIN  : 76487,
    HERETIC_GLACIAL_DRIFT_SKIN  : 46785,
    HERETIC_IMPERIAL_JUBILEE_SKIN  : 48846,
    HERETIC_OASIS_OF_FAITH_SKIN  : 53614,
    HERETIC_PURITY_OF_THE_THRONE_SKIN  : 42563,
    HERETIC_RAATA_SUNSET_SKIN  : 37499,
    HERMES_COMPACT_FIGHTER_SUPPORT_UNIT  : 41414,
    HERMES_COMPACT_FIGHTER_SUPPORT_UNIT_BLUEPRINT  : 41413,
    HERMETIC_MEMBRANES  : 2361,
    HERON  : 605,
    HERON_ASPIRANT_EXPLORER_SKIN  : 60229,
    HERON_BIOSECURITY_RESPONDERS_SKIN  : 55528,
    HERON_BLUE_TIGER_SKIN  : 36708,
    HERON_BLUEPRINT  : 952,
    HERON_EXOPLANETS_HUNTER_SKIN  : 45815,
    HERON_FORWARD_4_4_SKIN  : 56862,
    HERON_GHOSTBIRD_SKIN  : 48158,
    HERON_GLACIAL_DRIFT_SKIN  : 44149,
    HERON_MATIGU_SEABEAST_SKIN  : 40582,
    HERON_NAVY_ISSUE  : 72904,
    HERON_NAVY_ISSUE_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 73839,
    HERON_RAATA_SUNSET_SKIN  : 36636,
    HERON_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 63029,
    HERON_STEEL_CARDINAL_SKIN  : 46092,
    HERON_SUKUUVESTAA_SKIN  : 34732,
    HERON_VERSUS_BLUEFORCE_SKIN  : 62013,
    HERON_WARP_CONVERGENCE_SKIN  : 61865,
    HERON_ZENTO_ISIDEKO_COMBINE_SKIN  : 53152,
    HEURISTIC_SELFASSEMBLERS  : 30022,
    HEXA_2500MM_REPEATING_CANNON_I  : 20452,
    HEXA_2500MM_REPEATING_CANNON_I_BLUEPRINT  : 20453,
    HEXA_2500MM_REPEATING_CANNON_II  : 37306,
    HEXITE  : 16665,
    HEXITE_REACTION_FORMULA  : 46174,
    HIEMAL_TRICARBOXYL_VAPOR  : 49787,
    HIGH_ENERGY_PHYSICS  : 11433,
    HIGH_GRADE_AMULET_ALPHA  : 20499,
    HIGH_GRADE_AMULET_BETA  : 20501,
    HIGH_GRADE_AMULET_DELTA  : 20503,
    HIGH_GRADE_AMULET_EPSILON  : 20505,
    HIGH_GRADE_AMULET_GAMMA  : 20507,
    HIGH_GRADE_AMULET_OMEGA  : 20509,
    HIGH_GRADE_ASCENDANCY_ALPHA  : 33516,
    HIGH_GRADE_ASCENDANCY_BETA  : 33525,
    HIGH_GRADE_ASCENDANCY_DELTA  : 33526,
    HIGH_GRADE_ASCENDANCY_EPSILON  : 33527,
    HIGH_GRADE_ASCENDANCY_GAMMA  : 33528,
    HIGH_GRADE_ASCENDANCY_OMEGA  : 33529,
    HIGH_GRADE_ASKLEPIAN_ALPHA  : 42210,
    HIGH_GRADE_ASKLEPIAN_BETA  : 42211,
    HIGH_GRADE_ASKLEPIAN_DELTA  : 42213,
    HIGH_GRADE_ASKLEPIAN_EPSILON  : 42214,
    HIGH_GRADE_ASKLEPIAN_GAMMA  : 42212,
    HIGH_GRADE_ASKLEPIAN_OMEGA  : 42215,
    HIGH_GRADE_CRYSTAL_ALPHA  : 20121,
    HIGH_GRADE_CRYSTAL_BETA  : 20157,
    HIGH_GRADE_CRYSTAL_DELTA  : 20159,
    HIGH_GRADE_CRYSTAL_EPSILON  : 20160,
    HIGH_GRADE_CRYSTAL_GAMMA  : 20158,
    HIGH_GRADE_CRYSTAL_OMEGA  : 20161,
    HIGH_GRADE_GRAIL_ALPHA  : 31954,
    HIGH_GRADE_GRAIL_BETA  : 31955,
    HIGH_GRADE_GRAIL_DELTA  : 31956,
    HIGH_GRADE_GRAIL_EPSILON  : 31957,
    HIGH_GRADE_GRAIL_GAMMA  : 31958,
    HIGH_GRADE_GRAIL_OMEGA  : 31959,
    HIGH_GRADE_HALO_ALPHA  : 20498,
    HIGH_GRADE_HALO_BETA  : 20500,
    HIGH_GRADE_HALO_DELTA  : 20502,
    HIGH_GRADE_HALO_EPSILON  : 20504,
    HIGH_GRADE_HALO_GAMMA  : 20506,
    HIGH_GRADE_HALO_OMEGA  : 20508,
    HIGH_GRADE_HYDRA_ALPHA  : 54392,
    HIGH_GRADE_HYDRA_BETA  : 54393,
    HIGH_GRADE_HYDRA_DELTA  : 54395,
    HIGH_GRADE_HYDRA_EPSILON  : 54396,
    HIGH_GRADE_HYDRA_GAMMA  : 54394,
    HIGH_GRADE_HYDRA_OMEGA  : 54397,
    HIGH_GRADE_JACKAL_ALPHA  : 31974,
    HIGH_GRADE_JACKAL_BETA  : 31975,
    HIGH_GRADE_JACKAL_DELTA  : 31976,
    HIGH_GRADE_JACKAL_EPSILON  : 31977,
    HIGH_GRADE_JACKAL_GAMMA  : 31978,
    HIGH_GRADE_JACKAL_OMEGA  : 31979,
    HIGH_GRADE_MIMESIS_ALPHA  : 52917,
    HIGH_GRADE_MIMESIS_BETA  : 52918,
    HIGH_GRADE_MIMESIS_DELTA  : 52919,
    HIGH_GRADE_MIMESIS_EPSILON  : 52920,
    HIGH_GRADE_MIMESIS_GAMMA  : 52921,
    HIGH_GRADE_MIMESIS_OMEGA  : 52922,
    HIGH_GRADE_NIRVANA_ALPHA  : 53710,
    HIGH_GRADE_NIRVANA_BETA  : 53711,
    HIGH_GRADE_NIRVANA_DELTA  : 53712,
    HIGH_GRADE_NIRVANA_EPSILON  : 53713,
    HIGH_GRADE_NIRVANA_GAMMA  : 53715,
    HIGH_GRADE_NIRVANA_OMEGA  : 53714,
    HIGH_GRADE_RAPTURE_ALPHA  : 57123,
    HIGH_GRADE_RAPTURE_BETA  : 57124,
    HIGH_GRADE_RAPTURE_DELTA  : 57126,
    HIGH_GRADE_RAPTURE_EPSILON  : 57127,
    HIGH_GRADE_RAPTURE_GAMMA  : 57125,
    HIGH_GRADE_RAPTURE_OMEGA  : 57128,
    HIGH_GRADE_SAVIOR_ALPHA  : 53890,
    HIGH_GRADE_SAVIOR_BETA  : 53891,
    HIGH_GRADE_SAVIOR_DELTA  : 53893,
    HIGH_GRADE_SAVIOR_EPSILON  : 53894,
    HIGH_GRADE_SAVIOR_GAMMA  : 53892,
    HIGH_GRADE_SAVIOR_OMEGA  : 53895,
    HIGH_GRADE_SNAKE_ALPHA  : 19540,
    HIGH_GRADE_SNAKE_BETA  : 19551,
    HIGH_GRADE_SNAKE_DELTA  : 19554,
    HIGH_GRADE_SNAKE_EPSILON  : 19555,
    HIGH_GRADE_SNAKE_GAMMA  : 19553,
    HIGH_GRADE_SNAKE_OMEGA  : 19556,
    HIGH_GRADE_SPUR_ALPHA  : 31968,
    HIGH_GRADE_SPUR_BETA  : 31969,
    HIGH_GRADE_SPUR_DELTA  : 31970,
    HIGH_GRADE_SPUR_EPSILON  : 31971,
    HIGH_GRADE_SPUR_GAMMA  : 31972,
    HIGH_GRADE_SPUR_OMEGA  : 31973,
    HIGH_GRADE_TALISMAN_ALPHA  : 19534,
    HIGH_GRADE_TALISMAN_BETA  : 19535,
    HIGH_GRADE_TALISMAN_DELTA  : 19537,
    HIGH_GRADE_TALISMAN_EPSILON  : 19538,
    HIGH_GRADE_TALISMAN_GAMMA  : 19536,
    HIGH_GRADE_TALISMAN_OMEGA  : 19539,
    HIGH_GRADE_TALON_ALPHA  : 31962,
    HIGH_GRADE_TALON_BETA  : 31963,
    HIGH_GRADE_TALON_DELTA  : 31964,
    HIGH_GRADE_TALON_EPSILON  : 31965,
    HIGH_GRADE_TALON_GAMMA  : 31966,
    HIGH_GRADE_TALON_OMEGA  : 31967,
    HIGH_NOON_THERMAL_SHIELD_AMPLIFIER  : 20607,
    HIGH_SPEED_MANEUVERING  : 3454,
    HIGH_TECH_DATA_CHIP  : 17893,
    HIGH_TECH_MANUFACTURING_TOOLS  : 17895,
    HIGH_TECH_SCANNER  : 17894,
    HIGH_TECH_SMALL_ARMS  : 17897,
    HIGH_TECH_TRANSMITTERS  : 17898,
    HIGHSTROKE_SCOPED_GUIDANCE_DISRUPTOR  : 37544,
    HISTORIC_CAPSULEER_DAY_CLOTHING_CRATE  : 77427,
    HOARDER  : 651,
    HOARDER_BIOSECURITY_RESPONDERS_SKIN  : 55662,
    HOARDER_BLUE_TIGER_SKIN  : 36838,
    HOARDER_BLUEPRINT  : 986,
    HOARDER_EXOPLANETS_HUNTER_SKIN  : 45984,
    HOARDER_FLEET_ORDNANCE_SPIKE_SKIN  : 64907,
    HOARDER_GLACIAL_DRIFT_SKIN  : 46921,
    HOARDER_HAZARD_CONTROL_SKIN  : 56929,
    HOARDER_SNOWLINE_BLADERACER_SKIN  : 52895,
    HOARDER_TRONHADAR_INK_SKIN  : 40509,
    HOARDER_VALKLEAR_GLORY_SKIN  : 36791,
    HOBGOBLIN_I  : 2454,
    HOBGOBLIN_I_BLUEPRINT  : 2455,
    HOBGOBLIN_II  : 2456,
    HOBGOBLIN_SD_300  : 23715,
    HOBGOBLIN_SD_300_BLUEPRINT  : 23716,
    HOLISTIC_NEUROSYNAPTIC_TEMPLATE  : 56712,
    HOLOREELS  : 3647,
    HOLY_DESTINY_ELECTROMAGNETIC_LANCE  : 40631,
    HOLY_DESTINY_ELECTROMAGNETIC_LANCE_BLUEPRINT  : 41445,
    HOME_15_POCHVEN_FILAMENT  : 56081,
    HOME_5_POCHVEN_FILAMENT  : 56080,
    HOMELESS  : 12110,
    HOOLIGAN_HEAVY_ION_BLASTER_I  : 22907,
    HORIZON_FORTIZAR  : 47514,
    HORNET_EC_300  : 23707,
    HORNET_EC_300_BLUEPRINT  : 23708,
    HORNET_I  : 2464,
    HORNET_I_BLUEPRINT  : 2465,
    HORNET_II  : 2466,
    HOSTILE_TARGET_ACQUISITION_I  : 6041,
    HOUND  : 12034,
    HOUND_AURORA_UNIVERSALIS_SKIN  : 60918,
    HOUND_BIOSECURITY_RESPONDERS_SKIN  : 55684,
    HOUND_BLUE_TIGER_SKIN  : 36807,
    HOUND_BLUEPRINT  : 12035,
    HOUND_DAWN_OF_LIBERATION_SKIN  : 48494,
    HOUND_EXOPLANETS_HUNTER_SKIN  : 45942,
    HOUND_GLACIAL_DRIFT_SKIN  : 46883,
    HOUND_HAZARD_CONTROL_SKIN  : 56910,
    HOUND_JUSTICE_SKIN  : 36436,
    HOUND_SNOWLINE_BLADERACER_SKIN  : 52853,
    HOUND_TRONHADAR_INK_SKIN  : 40476,
    HOUND_VALKLEAR_GLORY_SKIN  : 36760,
    HOW_TO_RETIRE_YOUR_STARBASE  : 47058,
    HUBRIS  : 77281,
    HUBRIS_LUMINAIRE_RISING_SKIN  : 77887,
    HUGE_FREIGHT_CONTAINER  : 33005,
    HUGE_FREIGHT_CONTAINER_BLUEPRINT  : 33006,
    HUGE_SECURE_CONTAINER  : 11488,
    HUGE_SECURE_CONTAINER_BLUEPRINT  : 32864,
    HUGINN  : 11961,
    HUGINN_BIOSECURITY_RESPONDERS_SKIN  : 55687,
    HUGINN_BLUE_TIGER_SKIN  : 36822,
    HUGINN_BLUEPRINT  : 11962,
    HUGINN_DAWN_OF_LIBERATION_SKIN  : 48509,
    HUGINN_EXOPLANETS_HUNTER_SKIN  : 45962,
    HUGINN_GLACIAL_DRIFT_SKIN  : 46899,
    HUGINN_HAZARD_CONTROL_SKIN  : 56912,
    HUGINN_JUSTICE_SKIN  : 36430,
    HUGINN_SNOWLINE_BLADERACER_SKIN  : 52873,
    HUGINN_TRONHADAR_INK_SKIN  : 40492,
    HUGINN_VALKLEAR_GLORY_SKIN  : 36775,
    HULK  : 22544,
    HULK_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43757,
    HULK_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43810,
    HULK_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43823,
    HULK_BLUEPRINT  : 22545,
    HULK_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43770,
    HULK_CLAIM_FOREMAN_SKIN  : 44070,
    HULK_DIGMASTER_BLAZON_SKIN  : 42649,
    HULK_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43784,
    HULK_GALNET_STREAMCAST_UNIT_SKIN  : 76492,
    HULK_GLACIAL_DRIFT_SKIN  : 46989,
    HULK_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43836,
    HULK_LODESTRIKE_SKIN  : 46740,
    HULK_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43797,
    HULK_MORPHITE_SHINE_SKIN  : 42622,
    HULK_NOCX_RUSH_SKIN  : 34758,
    HULK_PAYDIRT_PROSPECTOR_SKIN  : 42679,
    HULK_RADIOACTIVES_RECLAMATION_SKIN  : 60952,
    HULK_ROCKBREAKER_PIONEERS_SKIN  : 56617,
    HULK_ROSADA_DAWN_SKIN  : 43671,
    HULK_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43849,
    HULL_TANKING_ELITE  : 33575,
    HULL_UPGRADES  : 3394,
    HURRICANE  : 24702,
    HURRICANE_ABYSSAL_FIRESTORM_SKIN  : 53143,
    HURRICANE_AURORA_UNIVERSALIS_SKIN  : 53361,
    HURRICANE_BIOSECURITY_RESPONDERS_SKIN  : 55661,
    HURRICANE_BLAZE_SQUADRON_SKIN  : 47104,
    HURRICANE_BLOODY_HANDS_SKIN  : 47167,
    HURRICANE_BLUE_TIGER_SKIN  : 36825,
    HURRICANE_BLUEPRINT  : 24703,
    HURRICANE_CHAINBREAKER_KHUMAAK_SKIN  : 64591,
    HURRICANE_CORDITE_BLOSSOM_SKIN  : 48839,
    HURRICANE_DEATHGLOW_HUNTERS_SKIN  : 60574,
    HURRICANE_DEATHGLOW_REMNANT_SKIN  : 73368,
    HURRICANE_EXOPLANETS_HUNTER_SKIN  : 45968,
    HURRICANE_FIREWALL_BREACH_SKIN  : 48529,
    HURRICANE_FLEET_ISSUE  : 33157,
    HURRICANE_FLEET_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55673,
    HURRICANE_FLEET_ISSUE_BLOODY_HANDS_SKIN  : 47168,
    HURRICANE_FLEET_ISSUE_BRUTOR_SKIN  : 59873,
    HURRICANE_FLEET_ISSUE_CHAINBREAKER_KHUMAAK_SKIN  : 64592,
    HURRICANE_FLEET_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45972,
    HURRICANE_FLEET_ISSUE_FREEDOMS_REVOLUTION_SKIN  : 55717,
    HURRICANE_FLEET_ISSUE_GLACIAL_DRIFT_SKIN  : 46909,
    HURRICANE_FLEET_ISSUE_IGC_SKIN  : 48561,
    HURRICANE_FLEET_ISSUE_IN_RUST_WE_TRUST_SKIN  : 52430,
    HURRICANE_FLEET_ISSUE_JUSTICE_SKIN  : 36458,
    HURRICANE_FLEET_ISSUE_KRUSUAL_SKIN  : 59833,
    HURRICANE_FLEET_ISSUE_NEFANTAR_SKIN  : 59825,
    HURRICANE_FLEET_ISSUE_SEBIESTOR_SKIN  : 59840,
    HURRICANE_FLEET_ISSUE_SNOWLINE_BLADERACER_SKIN  : 52883,
    HURRICANE_FLEET_ISSUE_SPIRIT_SKIN  : 57395,
    HURRICANE_FLEET_ISSUE_STARKMANIR_SKIN  : 59865,
    HURRICANE_FLEET_ISSUE_THUKKER_TRIBE_SKIN  : 59849,
    HURRICANE_FLEET_ISSUE_VHEROKIOR_SKIN  : 59857,
    HURRICANE_FLEET_ORDNANCE_SPIKE_SKIN  : 59246,
    HURRICANE_FREEDOMS_REVOLUTION_SKIN  : 55713,
    HURRICANE_GLACIAL_DRIFT_SKIN  : 46905,
    HURRICANE_HAZARD_CONTROL_SKIN  : 45553,
    HURRICANE_HEADHUNTER_SKIN  : 46724,
    HURRICANE_IGC_SKIN  : 48560,
    HURRICANE_IN_RUST_WE_TRUST_SKIN  : 52429,
    HURRICANE_OPERATION_PERMAFROST_SKIN  : 49811,
    HURRICANE_SEBIESTOR_SKIN  : 34620,
    HURRICANE_SNOWLINE_BLADERACER_SKIN  : 52879,
    HURRICANE_SPIRIT_SKIN  : 47318,
    HURRICANE_TRIGLAVIAN_TWILIGHT_SKIN  : 52405,
    HURRICANE_TRONHADAR_INK_SKIN  : 40496,
    HURRICANE_VALKLEAR_GLORY_SKIN  : 36778,
    HURRICANE_YOIUL_STAR_SKIN  : 53433,
    HUSTLER_HEAVY_NEUTRON_BLASTER_I  : 22909,
    HYASYODA_RESEARCH_LAB_TECHNICAL_DATA_CHIP  : 45584,
    HYASYODA_RESEARCH_LABORATORY  : 32245,
    HYBRID_POLYMER_SILO  : 30655,
    HYBRID_WEAPON_RIGGING  : 26259,
    HYDRA  : 48636,
    HYDROCARBONS  : 16633,
    HYDROCHLORIC_ACID  : 3773,
    HYDROGEN_BATTERIES  : 3685,
    HYDROGEN_FUEL_BLOCK  : 4246,
    HYDROGEN_FUEL_BLOCK_BLUEPRINT  : 4316,
    HYDROGEN_ISOTOPES  : 17889,
    HYDROMAGNETIC_PHYSICS  : 11443,
    HYENA  : 11387,
    HYENA_BIOSECURITY_RESPONDERS_SKIN  : 55691,
    HYENA_BLUE_TIGER_SKIN  : 36808,
    HYENA_DAWN_OF_LIBERATION_SKIN  : 48495,
    HYENA_EXOPLANETS_HUNTER_SKIN  : 45943,
    HYENA_GLACIAL_DRIFT_SKIN  : 46884,
    HYENA_HAZARD_CONTROL_SKIN  : 56895,
    HYENA_HEARTSURGE_SKIN  : 76283,
    HYENA_JUSTICE_SKIN  : 36428,
    HYENA_REPUBLIC_FLEET_SKIN  : 58866,
    HYENA_SNOWLINE_BLADERACER_SKIN  : 52854,
    HYENA_TRONHADAR_INK_SKIN  : 40477,
    HYENA_VALKLEAR_GLORY_SKIN  : 36761,
    HYPERBOLIC_METATRANSISTOR_ARRAY  : 54971,
    HYPERCORE  : 52568,
    HYPERFLURITE  : 16666,
    HYPERFLURITE_REACTION_FORMULA  : 46175,
    HYPERION  : 24690,
    HYPERION_ALIASTRA_SKIN  : 34636,
    HYPERION_AURORA_UNIVERSALIS_SKIN  : 57022,
    HYPERION_BIOSECURITY_RESPONDERS_SKIN  : 55580,
    HYPERION_BLUEPRINT  : 24691,
    HYPERION_CAPSULEER_DAY_XV_SKIN  : 47634,
    HYPERION_CAPSULEER_ELITE_XIX_SKIN  : 63690,
    HYPERION_EXOPLANETS_HUNTER_SKIN  : 45912,
    HYPERION_FEDERAL_POLICE_SKIN  : 52438,
    HYPERION_FEDERATION_NAVY_SKIN  : 58892,
    HYPERION_GLACIAL_DRIFT_SKIN  : 46958,
    HYPERION_HUNTERS_QUIVER_SKIN  : 47501,
    HYPERION_INNER_ZONE_SHIPPING_SKIN  : 34637,
    HYPERION_INNER_ZONE_VANGUARD_SKIN  : 52747,
    HYPERION_INTAKI_SYNDICATE_SKIN  : 36403,
    HYPERION_KOPIS_EDGE_SKIN  : 46424,
    HYPERION_QUAFE_SKIN  : 37056,
    HYPERION_SERENE_MANG_SKIN  : 49152,
    HYPERION_SERPENTIS_SKIN  : 42183,
    HYPERION_SPIRIT_SKIN  : 44930,
    HYPERION_VALIMOR_LEGACY_SKIN  : 37579,
    HYPERSYNAPTIC_FIBERS  : 16682,
    HYPERSYNAPTIC_FIBERS_REACTION_FORMULA  : 46212,
    HYPNAGOGIC_NEUROLINK_ENHANCER  : 57459,
    HYPNAGOGIC_NEUROLINK_ENHANCER_REACTION_FORMULA  : 57496,
    HYPNOS_COMPACT_SIGNAL_DISTORTION_AMPLIFIER_I  : 25565,
    HYPNOS_SCOPED_MAGNETOMETRIC_ECM  : 19927,
    HZO_REFINERY_STRONG_BOX  : 43684,
    IBIS  : 601,
    IBIS_BIOSECURITY_RESPONDERS_SKIN  : 55533,
    IBIS_EXOPLANETS_HUNTER_SKIN  : 45811,
    IBIS_GLACIAL_DRIFT_SKIN  : 44147,
    ICE_COMMAND_CENTER  : 2533,
    ICE_HARVESTER_I  : 16278,
    ICE_HARVESTER_I_BLUEPRINT  : 16279,
    ICE_HARVESTER_II  : 22229,
    ICE_HARVESTER_UPGRADE_I  : 22576,
    ICE_HARVESTER_UPGRADE_I_BLUEPRINT  : 22577,
    ICE_HARVESTER_UPGRADE_II  : 28578,
    ICE_HARVESTING  : 16281,
    ICE_HARVESTING_DRONE_I  : 43699,
    ICE_HARVESTING_DRONE_I_BLUEPRINT  : 43911,
    ICE_HARVESTING_DRONE_II  : 43700,
    ICE_HARVESTING_DRONE_OPERATION  : 43702,
    ICE_HARVESTING_DRONE_SPECIALIZATION  : 43703,
    ICE_MINING_LASER_I  : 37450,
    ICE_MINING_LASER_I_BLUEPRINT  : 38660,
    ICE_MINING_LASER_II  : 37451,
    ICE_PROCESSING  : 18025,
    IFFA_COMPACT_DAMAGE_CONTROL  : 5839,
    IGC_GARMENT_KEEPER  : 46151,
    IKITURSA  : 52252,
    IKITURSA_ALL_STARS_CASINO_CLASH_SKIN  : 53023,
    IKITURSA_BLACKFIRE_STEEL_SKIN  : 53667,
    IKITURSA_LIMINAL_CROSSINGS_SKIN  : 53314,
    IKITURSA_METAMATERIA_EXOTICA_SKIN  : 54221,
    IKITURSA_NIFLHEL_GILDCLAW_SKIN  : 52699,
    IKITURSA_SINGULARITY_STORM_SKIN  : 52353,
    IMAI_KENONS_TAG  : 17630,
    IMICUS  : 607,
    IMICUS_ASPIRANT_EXPLORER_SKIN  : 60228,
    IMICUS_BIOSECURITY_RESPONDERS_SKIN  : 55595,
    IMICUS_BLUEPRINT  : 954,
    IMICUS_EXOPLANETS_HUNTER_SKIN  : 45872,
    IMICUS_GLACIAL_DRIFT_SKIN  : 44175,
    IMICUS_INNER_ZONE_SHIPPING_SKIN  : 34738,
    IMICUS_INNER_ZONE_VANGUARD_SKIN  : 52707,
    IMICUS_INTAKI_SYNDICATE_SKIN  : 34739,
    IMICUS_KOPIS_EDGE_SKIN  : 46384,
    IMICUS_NAVY_ISSUE  : 72913,
    IMICUS_NAVY_ISSUE_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 73840,
    IMICUS_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 63030,
    IMICUS_SERPENTIS_SKIN  : 42148,
    IMICUS_SPIRIT_SKIN  : 44890,
    IMICUS_VALIMOR_LEGACY_SKIN  : 37548,
    IMICUS_VERSUS_BLUEFORCE_SKIN  : 62016,
    IMICUS_WARP_CONVERGENCE_SKIN  : 61870,
    IMMACULATE_JASPET  : 46682,
    IMMOLATOR  : 615,
    IMP  : 35779,
    IMPAIROR  : 596,
    IMPAIROR_BIOSECURITY_RESPONDERS_SKIN  : 55332,
    IMPAIROR_COLD_IRON_SKIN  : 42909,
    IMPAIROR_EXOPLANETS_HUNTER_SKIN  : 45752,
    IMPAIROR_GLACIAL_DRIFT_SKIN  : 44146,
    IMPAIROR_PURITY_OF_THE_THRONE_SKIN  : 42543,
    IMPEL  : 12753,
    IMPEL_BIOSECURITY_RESPONDERS_SKIN  : 55343,
    IMPEL_BLUEPRINT  : 12754,
    IMPEL_COLD_IRON_SKIN  : 42798,
    IMPEL_EOM_SKIN  : 36301,
    IMPEL_EXOPLANETS_HUNTER_SKIN  : 45807,
    IMPEL_GLACIAL_DRIFT_SKIN  : 46816,
    IMPEL_IMPERIAL_JUBILEE_SKIN  : 48860,
    IMPEL_LODESTRIKE_SKIN  : 46746,
    IMPEL_OASIS_OF_FAITH_SKIN  : 53649,
    IMPEL_PURITY_OF_THE_THRONE_SKIN  : 42598,
    IMPEL_RAATA_SUNSET_SKIN  : 37527,
    IMPEL_STAR_CAPTAIN_SKIN  : 52587,
    IMPEL_VERSUS_REDFORCE_SKIN  : 63856,
    IMPERIAL_DUCINIUM  : 74536,
    IMPERIAL_EXPLOIT_LOG  : 77949,
    IMPERIAL_NAVY_100MM_STEEL_PLATES  : 31896,
    IMPERIAL_NAVY_1600MM_STEEL_PLATES  : 31900,
    IMPERIAL_NAVY_200MM_STEEL_PLATES  : 31904,
    IMPERIAL_NAVY_400MM_STEEL_PLATES  : 31908,
    IMPERIAL_NAVY_800MM_STEEL_PLATES  : 31916,
    IMPERIAL_NAVY_ACOLYTE  : 31864,
    IMPERIAL_NAVY_CAP_RECHARGER  : 17526,
    IMPERIAL_NAVY_CAPACITOR_POWER_RELAY  : 17528,
    IMPERIAL_NAVY_CAPTAIN_INSIGNIA_I  : 15612,
    IMPERIAL_NAVY_COLONEL_INSIGNIA_I  : 15614,
    IMPERIAL_NAVY_COMMAND_MINDLINK  : 33403,
    IMPERIAL_NAVY_CURATOR  : 31868,
    IMPERIAL_NAVY_DRONE_DAMAGE_AMPLIFIER  : 33844,
    IMPERIAL_NAVY_DUAL_HEAVY_BEAM_LASER  : 15874,
    IMPERIAL_NAVY_DUAL_HEAVY_PULSE_LASER  : 15873,
    IMPERIAL_NAVY_DUAL_LIGHT_BEAM_LASER  : 15872,
    IMPERIAL_NAVY_DUAL_LIGHT_PULSE_LASER  : 15871,
    IMPERIAL_NAVY_EM_ARMOR_HARDENER  : 15711,
    IMPERIAL_NAVY_EM_COATING  : 15687,
    IMPERIAL_NAVY_EM_ENERGIZED_MEMBRANE  : 15723,
    IMPERIAL_NAVY_EXPLOSIVE_ARMOR_HARDENER  : 15709,
    IMPERIAL_NAVY_EXPLOSIVE_COATING  : 15689,
    IMPERIAL_NAVY_EXPLOSIVE_ENERGIZED_MEMBRANE  : 15725,
    IMPERIAL_NAVY_FLEET_COMMANDER_INSIGNIA  : 15670,
    IMPERIAL_NAVY_FLEET_REAR_ADMIRAL_INSIGNIA  : 28238,
    IMPERIAL_NAVY_FOCUSED_MEDIUM_BEAM_LASER  : 15870,
    IMPERIAL_NAVY_FOCUSED_MEDIUM_PULSE_LASER  : 15869,
    IMPERIAL_NAVY_GAMMA_L  : 23107,
    IMPERIAL_NAVY_GAMMA_M  : 23091,
    IMPERIAL_NAVY_GAMMA_S  : 23073,
    IMPERIAL_NAVY_GATLING_PULSE_LASER  : 15868,
    IMPERIAL_NAVY_GENERAL_INSIGNIA_I  : 15615,
    IMPERIAL_NAVY_GENERAL_INSIGNIA_II  : 15669,
    IMPERIAL_NAVY_HEAT_SINK  : 15810,
    IMPERIAL_NAVY_HEAVY_BEAM_LASER  : 15867,
    IMPERIAL_NAVY_HEAVY_CAPACITOR_BOOSTER  : 15786,
    IMPERIAL_NAVY_HEAVY_ENERGY_NEUTRALIZER  : 15804,
    IMPERIAL_NAVY_HEAVY_ENERGY_NOSFERATU  : 15885,
    IMPERIAL_NAVY_HEAVY_PULSE_LASER  : 15866,
    IMPERIAL_NAVY_INFILTRATOR  : 31866,
    IMPERIAL_NAVY_INFRARED_L  : 23115,
    IMPERIAL_NAVY_INFRARED_M  : 23099,
    IMPERIAL_NAVY_INFRARED_S  : 23081,
    IMPERIAL_NAVY_KINETIC_ARMOR_HARDENER  : 15707,
    IMPERIAL_NAVY_KINETIC_COATING  : 15691,
    IMPERIAL_NAVY_KINETIC_ENERGIZED_MEMBRANE  : 15727,
    IMPERIAL_NAVY_LARGE_ARMOR_REPAIRER  : 17546,
    IMPERIAL_NAVY_LARGE_EMP_SMARTBOMB  : 15963,
    IMPERIAL_NAVY_LARGE_REMOTE_CAPACITOR_TRANSMITTER  : 31946,
    IMPERIAL_NAVY_MAJOR_INSIGNIA_I  : 15613,
    IMPERIAL_NAVY_MEDIUM_ARMOR_REPAIRER  : 17547,
    IMPERIAL_NAVY_MEDIUM_CAPACITOR_BOOSTER  : 15784,
    IMPERIAL_NAVY_MEDIUM_EMP_SMARTBOMB  : 15961,
    IMPERIAL_NAVY_MEDIUM_ENERGY_NEUTRALIZER  : 15802,
    IMPERIAL_NAVY_MEDIUM_ENERGY_NOSFERATU  : 15883,
    IMPERIAL_NAVY_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 31948,
    IMPERIAL_NAVY_MEGA_BEAM_LASER  : 15863,
    IMPERIAL_NAVY_MEGA_PULSE_LASER  : 15862,
    IMPERIAL_NAVY_MICRO_EMP_SMARTBOMB  : 15959,
    IMPERIAL_NAVY_MICROWAVE_L  : 23117,
    IMPERIAL_NAVY_MICROWAVE_M  : 23101,
    IMPERIAL_NAVY_MICROWAVE_S  : 23083,
    IMPERIAL_NAVY_MIDSHIPMAN_INSIGNIA_I  : 15607,
    IMPERIAL_NAVY_MIDSHIPMAN_INSIGNIA_II  : 15608,
    IMPERIAL_NAVY_MIDSHIPMAN_INSIGNIA_III  : 15609,
    IMPERIAL_NAVY_MJOLNIR_AUTO_TARGETING_CRUISE_MISSILE_I  : 27459,
    IMPERIAL_NAVY_MJOLNIR_AUTO_TARGETING_HEAVY_MISSILE_I  : 27483,
    IMPERIAL_NAVY_MJOLNIR_AUTO_TARGETING_LIGHT_MISSILE_I  : 27507,
    IMPERIAL_NAVY_MODIFIED_NOBLE_IMPLANT  : 32254,
    IMPERIAL_NAVY_MULTIFREQUENCY_L  : 23105,
    IMPERIAL_NAVY_MULTIFREQUENCY_M  : 23089,
    IMPERIAL_NAVY_MULTIFREQUENCY_S  : 23071,
    IMPERIAL_NAVY_MULTISPECTRUM_COATING  : 15693,
    IMPERIAL_NAVY_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 15729,
    IMPERIAL_NAVY_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 33832,
    IMPERIAL_NAVY_PRAETOR  : 31870,
    IMPERIAL_NAVY_QUAD_BEAM_LASER  : 15861,
    IMPERIAL_NAVY_RADIO_L  : 23119,
    IMPERIAL_NAVY_RADIO_M  : 23103,
    IMPERIAL_NAVY_RADIO_S  : 23085,
    IMPERIAL_NAVY_RAID_LEADER_INSIGNIA  : 15635,
    IMPERIAL_NAVY_SERGEANT_ELITE_INSIGNIA  : 15636,
    IMPERIAL_NAVY_SERGEANT_INSIGNIA_I  : 15610,
    IMPERIAL_NAVY_SERGEANT_INSIGNIA_II  : 15992,
    IMPERIAL_NAVY_SERGEANT_INSIGNIA_III  : 16000,
    IMPERIAL_NAVY_SERGEANT_MAJOR_INSIGNIA_I  : 15611,
    IMPERIAL_NAVY_SLICER  : 17703,
    IMPERIAL_NAVY_SLICER_ARDISHAPUR_SKIN  : 60673,
    IMPERIAL_NAVY_SLICER_BIOSECURITY_RESPONDERS_SKIN  : 55337,
    IMPERIAL_NAVY_SLICER_COLD_IRON_SKIN  : 42760,
    IMPERIAL_NAVY_SLICER_CROWN_AND_SWORDS_SKIN  : 52767,
    IMPERIAL_NAVY_SLICER_EOM_SKIN  : 36304,
    IMPERIAL_NAVY_SLICER_EXOPLANETS_HUNTER_SKIN  : 45767,
    IMPERIAL_NAVY_SLICER_GLACIAL_DRIFT_SKIN  : 46782,
    IMPERIAL_NAVY_SLICER_OASIS_OF_FAITH_SKIN  : 53609,
    IMPERIAL_NAVY_SLICER_PURITY_OF_THE_THRONE_SKIN  : 42558,
    IMPERIAL_NAVY_SLICER_SARUM_SKIN  : 56789,
    IMPERIAL_NAVY_SLICER_VERSUS_BLUEFORCE_SKIN  : 73875,
    IMPERIAL_NAVY_SMALL_ARMOR_REPAIRER  : 17548,
    IMPERIAL_NAVY_SMALL_CAPACITOR_BOOSTER  : 15780,
    IMPERIAL_NAVY_SMALL_EMP_SMARTBOMB  : 15957,
    IMPERIAL_NAVY_SMALL_ENERGY_NEUTRALIZER  : 15800,
    IMPERIAL_NAVY_SMALL_ENERGY_NOSFERATU  : 15881,
    IMPERIAL_NAVY_SMALL_FOCUSED_BEAM_LASER  : 15865,
    IMPERIAL_NAVY_SMALL_FOCUSED_PULSE_LASER  : 15864,
    IMPERIAL_NAVY_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 31950,
    IMPERIAL_NAVY_SQUAD_LEADER_INSIGNIA  : 15634,
    IMPERIAL_NAVY_STANDARD_L  : 23113,
    IMPERIAL_NAVY_STANDARD_M  : 23097,
    IMPERIAL_NAVY_STANDARD_S  : 23079,
    IMPERIAL_NAVY_TACHYON_BEAM_LASER  : 15860,
    IMPERIAL_NAVY_THERMAL_ARMOR_HARDENER  : 15705,
    IMPERIAL_NAVY_THERMAL_COATING  : 15685,
    IMPERIAL_NAVY_THERMAL_ENERGIZED_MEMBRANE  : 15721,
    IMPERIAL_NAVY_ULTRAVIOLET_L  : 23111,
    IMPERIAL_NAVY_ULTRAVIOLET_M  : 23095,
    IMPERIAL_NAVY_ULTRAVIOLET_S  : 23077,
    IMPERIAL_NAVY_XRAY_L  : 23109,
    IMPERIAL_NAVY_XRAY_M  : 23093,
    IMPERIAL_NAVY_XRAY_S  : 23075,
    IMPERIAL_SPECIAL_OPS_FIELD_ENHANCER___STANDARD  : 22760,
    IMPETUS_CONSOLE  : 25621,
    IMPETUS_TRADING_CARDS  : 48138,
    IMPROVED_BLUE_PILL_BOOSTER  : 10155,
    IMPROVED_BLUE_PILL_BOOSTER_REACTION_FORMULA  : 46251,
    IMPROVED_CLOAKING_DEVICE_II  : 11577,
    IMPROVED_CRASH_BOOSTER  : 10151,
    IMPROVED_CRASH_BOOSTER_REACTION_FORMULA  : 46236,
    IMPROVED_DROP_BOOSTER  : 15477,
    IMPROVED_DROP_BOOSTER_REACTION_FORMULA  : 46237,
    IMPROVED_EXILE_BOOSTER  : 15480,
    IMPROVED_EXILE_BOOSTER_REACTION_FORMULA  : 46238,
    IMPROVED_FRENTIX_BOOSTER  : 15461,
    IMPROVED_FRENTIX_BOOSTER_REACTION_FORMULA  : 46241,
    IMPROVED_GUISE_CLOAKING_DEVICE_II  : 20565,
    IMPROVED_MINDFLOOD_BOOSTER  : 15464,
    IMPROVED_MINDFLOOD_BOOSTER_REACTION_FORMULA  : 46240,
    IMPROVED_SOOTH_SAYER_BOOSTER  : 10165,
    IMPROVED_SOOTH_SAYER_BOOSTER_REACTION_FORMULA  : 46243,
    IMPROVED_X_INSTINCT_BOOSTER  : 15458,
    IMPROVED_X_INSTINCT_BOOSTER_REACTION_FORMULA  : 46242,
    INACTIVE_SONIC_MULTITOOL  : 62047,
    INCEPTION_TARGET_PAINTER  : 21540,
    INCURSUS  : 594,
    INCURSUS_AIR_LABORATORIES_SKIN  : 60159,
    INCURSUS_ALIASTRA_SKIN  : 34740,
    INCURSUS_BIOSECURITY_RESPONDERS_SKIN  : 55598,
    INCURSUS_BLUEPRINT  : 941,
    INCURSUS_CAPSULEER_DAY_XV_SKIN  : 47631,
    INCURSUS_DEATHGLOW_REMNANT_SKIN  : 60657,
    INCURSUS_EXOPLANETS_HUNTER_SKIN  : 45873,
    INCURSUS_FEDERATION_NAVY_SKIN  : 58884,
    INCURSUS_GLACIAL_DRIFT_SKIN  : 44176,
    INCURSUS_GLITTERING_DREAM_SKIN  : 47522,
    INCURSUS_HARMONIOUS_ASCENSION_SKIN  : 53209,
    INCURSUS_INNER_ZONE_SHIPPING_SKIN  : 34741,
    INCURSUS_INNER_ZONE_VANGUARD_SKIN  : 52708,
    INCURSUS_INTAKI_SYNDICATE_SKIN  : 36368,
    INCURSUS_KOPIS_EDGE_SKIN  : 46385,
    INCURSUS_QUAFE_SKIN  : 37055,
    INCURSUS_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60219,
    INCURSUS_SERPENTIS_SKIN  : 42149,
    INCURSUS_SPIRIT_SKIN  : 44891,
    INCURSUS_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59237,
    INCURSUS_VALIMOR_LEGACY_SKIN  : 37549,
    INCURSUS_ZAKURA_SHUMYU_SKIN  : 57060,
    INDIRECT_TARGET_ACQUISITION_I  : 5865,
    INDUCED_COMPACT_MULTISPECTRAL_ECM  : 19929,
    INDUSTRIAL_COMMAND_SHIPS  : 29637,
    INDUSTRIAL_CYNOSURAL_FIELD_GENERATOR  : 52694,
    INDUSTRIAL_CYNOSURAL_FIELD_GENERATOR_BLUEPRINT  : 52695,
    INDUSTRIAL_EXPLOSIVES  : 2360,
    INDUSTRIAL_FIBERS  : 2397,
    INDUSTRIAL_JUMP_PORTAL_GENERATOR_I  : 63140,
    INDUSTRIAL_JUMP_PORTAL_GENERATOR_I_BLUEPRINT  : 63164,
    INDUSTRIAL_RECONFIGURATION  : 58956,
    INDUSTRIALIST___CRUISER_BLUEPRINT_CRATE  : 63774,
    INDUSTRIALIST___DESTROYER_BLUEPRINT_CRATE  : 63773,
    INDUSTRIALIST___FRIGATE_BLUEPRINT_CRATE  : 63772,
    INDUSTRIALIST___HAULER_BLUEPRINT_CRATE  : 63775,
    INDUSTRIALIST___HAULER_SKILL_CRATE  : 63776,
    INDUSTRIALIST___LIGHT_COMBAT_DRONES_CRATE  : 63777,
    INDUSTRY  : 3380,
    INERTIAL_STABILIZERS_I  : 1403,
    INERTIAL_STABILIZERS_I_BLUEPRINT  : 1404,
    INERTIAL_STABILIZERS_II  : 1405,
    INFERNO_AUTO_TARGETING_CRUISE_MISSILE_I  : 1832,
    INFERNO_AUTO_TARGETING_CRUISE_MISSILE_I_BLUEPRINT  : 1833,
    INFERNO_AUTO_TARGETING_HEAVY_MISSILE_I  : 1824,
    INFERNO_AUTO_TARGETING_HEAVY_MISSILE_I_BLUEPRINT  : 1825,
    INFERNO_AUTO_TARGETING_LIGHT_MISSILE_I  : 1816,
    INFERNO_AUTO_TARGETING_LIGHT_MISSILE_I_BLUEPRINT  : 1817,
    INFERNO_CRUISE_MISSILE  : 204,
    INFERNO_CRUISE_MISSILE_BLUEPRINT  : 805,
    INFERNO_FURY_CRUISE_MISSILE  : 2621,
    INFERNO_FURY_HEAVY_MISSILE  : 24511,
    INFERNO_FURY_LIGHT_MISSILE  : 24499,
    INFERNO_FURY_XL_CRUISE_MISSILE  : 41287,
    INFERNO_HEAVY_ASSAULT_MISSILE  : 20308,
    INFERNO_HEAVY_ASSAULT_MISSILE_BLUEPRINT  : 26761,
    INFERNO_HEAVY_MISSILE  : 208,
    INFERNO_HEAVY_MISSILE_BLUEPRINT  : 809,
    INFERNO_JAVELIN_HEAVY_ASSAULT_MISSILE  : 24494,
    INFERNO_JAVELIN_ROCKET  : 24479,
    INFERNO_JAVELIN_TORPEDO  : 24525,
    INFERNO_JAVELIN_XL_TORPEDO  : 41276,
    INFERNO_LIGHT_MISSILE  : 211,
    INFERNO_LIGHT_MISSILE_BLUEPRINT  : 812,
    INFERNO_PRECISION_CRUISE_MISSILE  : 2637,
    INFERNO_PRECISION_HEAVY_MISSILE  : 24515,
    INFERNO_PRECISION_LIGHT_MISSILE  : 2647,
    INFERNO_PRECISION_XL_CRUISE_MISSILE  : 41286,
    INFERNO_RAGE_HEAVY_ASSAULT_MISSILE  : 24486,
    INFERNO_RAGE_ROCKET  : 24475,
    INFERNO_RAGE_TORPEDO  : 2811,
    INFERNO_RAGE_XL_TORPEDO  : 41277,
    INFERNO_ROCKET  : 2514,
    INFERNO_ROCKET_BLUEPRINT  : 2515,
    INFERNO_TORPEDO  : 2510,
    INFERNO_TORPEDO_BLUEPRINT  : 2511,
    INFERNO_XL_CRUISE_MISSILE  : 32440,
    INFERNO_XL_CRUISE_MISSILE_BLUEPRINT  : 32441,
    INFERNO_XL_TORPEDO  : 17861,
    INFERNO_XL_TORPEDO_BLUEPRINT  : 17862,
    INFILTRATOR_EV_600  : 23702,
    INFILTRATOR_EV_600_BLUEPRINT  : 23703,
    INFILTRATOR_I  : 2173,
    INFILTRATOR_I_BLUEPRINT  : 2174,
    INFILTRATOR_II  : 2175,
    INFILTRATOR_TD_600  : 23725,
    INFILTRATOR_TD_600_BLUEPRINT  : 23726,
    INFINITE_CELEBRATIONS_FIREWORK  : 76963,
    INFOMORPH_PSYCHOLOGY  : 24242,
    INFOMORPH_SYNCHRONIZING  : 33399,
    INFORMATION_COMMAND  : 20495,
    INFORMATION_COMMAND_BURST_I  : 42527,
    INFORMATION_COMMAND_BURST_I_BLUEPRINT  : 43548,
    INFORMATION_COMMAND_BURST_II  : 43554,
    INFORMATION_COMMAND_MINDLINK  : 21889,
    INFORMATION_COMMAND_SPECIALIST  : 3352,
    INFRARED_L  : 257,
    INFRARED_L_BLUEPRINT  : 1172,
    INFRARED_M  : 249,
    INFRARED_M_BLUEPRINT  : 1164,
    INFRARED_S  : 241,
    INFRARED_S_BLUEPRINT  : 1156,
    INFRARED_XL  : 17682,
    INFRARED_XL_BLUEPRINT  : 17683,
    INFRASTRUCTURE_HUB  : 32458,
    INFRASTRUCTURE_HUB_BLUEPRINT  : 2735,
    INGENII_ICE_HARVESTER_UPGRADE  : 22623,
    INHERENT_IMPLANTS_HIGHWALL_MINING_MX_1001  : 27102,
    INHERENT_IMPLANTS_HIGHWALL_MINING_MX_1003  : 22534,
    INHERENT_IMPLANTS_HIGHWALL_MINING_MX_1005  : 22535,
    INHERENT_IMPLANTS_HIGHWALL_MINING_UPGRADES_MU_1001  : 27151,
    INHERENT_IMPLANTS_HIGHWALL_MINING_UPGRADES_MU_1003  : 27149,
    INHERENT_IMPLANTS_HIGHWALL_MINING_UPGRADES_MU_1005  : 27150,
    INHERENT_IMPLANTS_LANCER_CONTROLLED_BURSTS_CB_701  : 27079,
    INHERENT_IMPLANTS_LANCER_CONTROLLED_BURSTS_CB_702  : 3203,
    INHERENT_IMPLANTS_LANCER_CONTROLLED_BURSTS_CB_703  : 13246,
    INHERENT_IMPLANTS_LANCER_CONTROLLED_BURSTS_CB_704  : 3208,
    INHERENT_IMPLANTS_LANCER_CONTROLLED_BURSTS_CB_705  : 19692,
    INHERENT_IMPLANTS_LANCER_CONTROLLED_BURSTS_CB_706  : 3213,
    INHERENT_IMPLANTS_LANCER_GUNNERY_RF_901  : 27077,
    INHERENT_IMPLANTS_LANCER_GUNNERY_RF_902  : 3204,
    INHERENT_IMPLANTS_LANCER_GUNNERY_RF_903  : 13166,
    INHERENT_IMPLANTS_LANCER_GUNNERY_RF_904  : 3209,
    INHERENT_IMPLANTS_LANCER_GUNNERY_RF_905  : 19693,
    INHERENT_IMPLANTS_LANCER_GUNNERY_RF_906  : 3214,
    INHERENT_IMPLANTS_LANCER_LARGE_ENERGY_TURRET_LE_1001  : 27088,
    INHERENT_IMPLANTS_LANCER_LARGE_ENERGY_TURRET_LE_1002  : 3205,
    INHERENT_IMPLANTS_LANCER_LARGE_ENERGY_TURRET_LE_1003  : 13217,
    INHERENT_IMPLANTS_LANCER_LARGE_ENERGY_TURRET_LE_1004  : 3210,
    INHERENT_IMPLANTS_LANCER_LARGE_ENERGY_TURRET_LE_1005  : 19694,
    INHERENT_IMPLANTS_LANCER_LARGE_ENERGY_TURRET_LE_1006  : 3215,
    INHERENT_IMPLANTS_LANCER_MEDIUM_ENERGY_TURRET_ME_801  : 27085,
    INHERENT_IMPLANTS_LANCER_MEDIUM_ENERGY_TURRET_ME_802  : 3206,
    INHERENT_IMPLANTS_LANCER_MEDIUM_ENERGY_TURRET_ME_803  : 13220,
    INHERENT_IMPLANTS_LANCER_MEDIUM_ENERGY_TURRET_ME_804  : 3211,
    INHERENT_IMPLANTS_LANCER_MEDIUM_ENERGY_TURRET_ME_805  : 19695,
    INHERENT_IMPLANTS_LANCER_MEDIUM_ENERGY_TURRET_ME_806  : 3216,
    INHERENT_IMPLANTS_LANCER_SMALL_ENERGY_TURRET_SE_601  : 27082,
    INHERENT_IMPLANTS_LANCER_SMALL_ENERGY_TURRET_SE_602  : 3202,
    INHERENT_IMPLANTS_LANCER_SMALL_ENERGY_TURRET_SE_603  : 13223,
    INHERENT_IMPLANTS_LANCER_SMALL_ENERGY_TURRET_SE_604  : 3207,
    INHERENT_IMPLANTS_LANCER_SMALL_ENERGY_TURRET_SE_605  : 19691,
    INHERENT_IMPLANTS_LANCER_SMALL_ENERGY_TURRET_SE_606  : 3212,
    INHERENT_IMPLANTS_NOBLE_HULL_UPGRADES_HG_1001  : 27074,
    INHERENT_IMPLANTS_NOBLE_HULL_UPGRADES_HG_1002  : 3479,
    INHERENT_IMPLANTS_NOBLE_HULL_UPGRADES_HG_1003  : 13256,
    INHERENT_IMPLANTS_NOBLE_HULL_UPGRADES_HG_1004  : 3481,
    INHERENT_IMPLANTS_NOBLE_HULL_UPGRADES_HG_1005  : 19550,
    INHERENT_IMPLANTS_NOBLE_HULL_UPGRADES_HG_1006  : 3482,
    INHERENT_IMPLANTS_NOBLE_HULL_UPGRADES_HG_1008  : 21606,
    INHERENT_IMPLANTS_NOBLE_MECHANIC_MC_801  : 27072,
    INHERENT_IMPLANTS_NOBLE_MECHANIC_MC_802  : 3471,
    INHERENT_IMPLANTS_NOBLE_MECHANIC_MC_803  : 13257,
    INHERENT_IMPLANTS_NOBLE_MECHANIC_MC_804  : 3474,
    INHERENT_IMPLANTS_NOBLE_MECHANIC_MC_805  : 19549,
    INHERENT_IMPLANTS_NOBLE_MECHANIC_MC_806  : 3475,
    INHERENT_IMPLANTS_NOBLE_REMOTE_ARMOR_REPAIR_SYSTEMS_RA_701  : 27071,
    INHERENT_IMPLANTS_NOBLE_REMOTE_ARMOR_REPAIR_SYSTEMS_RA_702  : 3414,
    INHERENT_IMPLANTS_NOBLE_REMOTE_ARMOR_REPAIR_SYSTEMS_RA_703  : 17871,
    INHERENT_IMPLANTS_NOBLE_REMOTE_ARMOR_REPAIR_SYSTEMS_RA_704  : 3415,
    INHERENT_IMPLANTS_NOBLE_REMOTE_ARMOR_REPAIR_SYSTEMS_RA_705  : 19548,
    INHERENT_IMPLANTS_NOBLE_REMOTE_ARMOR_REPAIR_SYSTEMS_RA_706  : 3470,
    INHERENT_IMPLANTS_NOBLE_REPAIR_PROFICIENCY_RP_901  : 27073,
    INHERENT_IMPLANTS_NOBLE_REPAIR_PROFICIENCY_RP_902  : 3476,
    INHERENT_IMPLANTS_NOBLE_REPAIR_PROFICIENCY_RP_903  : 19684,
    INHERENT_IMPLANTS_NOBLE_REPAIR_PROFICIENCY_RP_904  : 3477,
    INHERENT_IMPLANTS_NOBLE_REPAIR_PROFICIENCY_RP_905  : 19685,
    INHERENT_IMPLANTS_NOBLE_REPAIR_PROFICIENCY_RP_906  : 3478,
    INHERENT_IMPLANTS_NOBLE_REPAIR_SYSTEMS_RS_601  : 27070,
    INHERENT_IMPLANTS_NOBLE_REPAIR_SYSTEMS_RS_602  : 3291,
    INHERENT_IMPLANTS_NOBLE_REPAIR_SYSTEMS_RS_603  : 13258,
    INHERENT_IMPLANTS_NOBLE_REPAIR_SYSTEMS_RS_604  : 3292,
    INHERENT_IMPLANTS_NOBLE_REPAIR_SYSTEMS_RS_605  : 19547,
    INHERENT_IMPLANTS_NOBLE_REPAIR_SYSTEMS_RS_606  : 3299,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_EMISSION_SYSTEMS_ES_701  : 27120,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_EMISSION_SYSTEMS_ES_702  : 3247,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_EMISSION_SYSTEMS_ES_703  : 13265,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_EMISSION_SYSTEMS_ES_704  : 3248,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_EMISSION_SYSTEMS_ES_705  : 27121,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_EMISSION_SYSTEMS_ES_706  : 3249,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_MANAGEMENT_EM_801  : 27117,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_MANAGEMENT_EM_802  : 3237,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_MANAGEMENT_EM_803  : 13259,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_MANAGEMENT_EM_804  : 3238,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_MANAGEMENT_EM_805  : 27116,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_MANAGEMENT_EM_806  : 3239,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_SYSTEMS_OPERATION_EO_601  : 27119,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_SYSTEMS_OPERATION_EO_602  : 3240,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_SYSTEMS_OPERATION_EO_603  : 13260,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_SYSTEMS_OPERATION_EO_604  : 3241,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_SYSTEMS_OPERATION_EO_605  : 27118,
    INHERENT_IMPLANTS_SQUIRE_CAPACITOR_SYSTEMS_OPERATION_EO_606  : 3246,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_GRID_UPGRADES_EU_701  : 27125,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_GRID_UPGRADES_EU_702  : 3253,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_GRID_UPGRADES_EU_703  : 13255,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_GRID_UPGRADES_EU_704  : 3254,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_GRID_UPGRADES_EU_705  : 27124,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_GRID_UPGRADES_EU_706  : 3255,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_PULSE_WEAPONS_EP_701  : 27123,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_PULSE_WEAPONS_EP_702  : 3250,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_PULSE_WEAPONS_EP_703  : 13251,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_PULSE_WEAPONS_EP_704  : 3251,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_PULSE_WEAPONS_EP_705  : 27122,
    INHERENT_IMPLANTS_SQUIRE_ENERGY_PULSE_WEAPONS_EP_706  : 3252,
    INHERENT_IMPLANTS_SQUIRE_POWER_GRID_MANAGEMENT_EG_601  : 27127,
    INHERENT_IMPLANTS_SQUIRE_POWER_GRID_MANAGEMENT_EG_602  : 3256,
    INHERENT_IMPLANTS_SQUIRE_POWER_GRID_MANAGEMENT_EG_603  : 13261,
    INHERENT_IMPLANTS_SQUIRE_POWER_GRID_MANAGEMENT_EG_604  : 3257,
    INHERENT_IMPLANTS_SQUIRE_POWER_GRID_MANAGEMENT_EG_605  : 27126,
    INHERENT_IMPLANTS_SQUIRE_POWER_GRID_MANAGEMENT_EG_606  : 3258,
    INHERENT_IMPLANTS_YETI_ICE_HARVESTING_IH_1001  : 27103,
    INHERENT_IMPLANTS_YETI_ICE_HARVESTING_IH_1003  : 22570,
    INHERENT_IMPLANTS_YETI_ICE_HARVESTING_IH_1005  : 22571,
    INITIATED_COMPACT_WARP_DISRUPTOR  : 5405,
    INITIATED_COMPACT_WARP_SCRAMBLER  : 5445,
    INITIATED_ENDURING_MULTISPECTRAL_ECM  : 9521,
    INQUEST_EROS_STASIS_WEBIFIER_MR_803  : 47261,
    INQUEST_EROS_STASIS_WEBIFIER_MR_805  : 47262,
    INQUEST_EROS_STASIS_WEBIFIER_MR_807  : 47263,
    INQUEST_HEDONE_ENTANGLEMENT_OPTIMIZER_WS_802  : 50053,
    INQUEST_HEDONE_ENTANGLEMENT_OPTIMIZER_WS_803  : 50066,
    INQUEST_HEDONE_ENTANGLEMENT_OPTIMIZER_WS_804  : 50067,
    INQUISITOR  : 590,
    INQUISITOR_BIOSECURITY_RESPONDERS_SKIN  : 55304,
    INQUISITOR_BLUEPRINT  : 937,
    INQUISITOR_COLD_IRON_SKIN  : 42748,
    INQUISITOR_COMBAT_MEDIC_SECTION_SKIN  : 60197,
    INQUISITOR_EXOPLANETS_HUNTER_SKIN  : 45755,
    INQUISITOR_GLACIAL_DRIFT_SKIN  : 44136,
    INQUISITOR_HUNTERS_QUIVER_SKIN  : 47495,
    INQUISITOR_KHANID_SKIN  : 34723,
    INQUISITOR_LUMINAIRE_ZENITH_SKIN  : 48218,
    INQUISITOR_OASIS_OF_FAITH_SKIN  : 53597,
    INQUISITOR_PURITY_OF_THE_THRONE_SKIN  : 42546,
    INQUISITOR_RAATA_SUNSET_SKIN  : 37486,
    INQUISITOR_TASH_MURKON_SKIN  : 34724,
    INSURGENT_TROJAN_FRAGMENTS  : 77946,
    INTACT_ARMOR_NANOBOT  : 30614,
    INTACT_ARMOR_PLATES  : 25624,
    INTACT_HULL_SECTION  : 30752,
    INTACT_NEUROSYNAPTIC_PROFILE  : 56711,
    INTACT_POWER_CORES  : 30582,
    INTACT_SHIELD_EMITTER  : 25608,
    INTACT_THRUSTER_SECTIONS  : 30187,
    INTACT_WEAPON_SUBROUTINES  : 30628,
    INTAKI_CYCLE_OF_IDA_BRACELET  : 48140,
    INTAKI_SPICED_TEA  : 48143,
    INTEGRATED_ACOLYTE  : 28262,
    INTEGRATED_BERSERKER  : 28266,
    INTEGRATED_HAMMERHEAD  : 28270,
    INTEGRATED_HOBGOBLIN  : 28274,
    INTEGRATED_HORNET  : 28278,
    INTEGRATED_INFILTRATOR  : 28282,
    INTEGRATED_OGRE  : 28286,
    INTEGRATED_PRAETOR  : 28290,
    INTEGRATED_VALKYRIE  : 28294,
    INTEGRATED_VESPA  : 28298,
    INTEGRATED_WARRIOR  : 28302,
    INTEGRATED_WASP  : 28306,
    INTEGRITY_RESPONSE_DRONES  : 2868,
    INTENSIVE_REPROCESSING_ARRAY  : 19470,
    INTERBUS_SHUTTLE  : 30842,
    INTERCEPTORS  : 12092,
    INTERDICTION_MANEUVERS_CHARGE  : 42839,
    INTERDICTION_MANEUVERS_CHARGE_BLUEPRINT  : 42887,
    INTERDICTION_NULLIFIER_I  : 58919,
    INTERDICTION_NULLIFIER_I_BLUEPRINT  : 58955,
    INTERDICTION_NULLIFIER_II  : 58972,
    INTERDICTION_NULLIFIER_II_BLUEPRINT  : 58975,
    INTERDICTION_SPHERE_LAUNCHER_I  : 22782,
    INTERDICTION_SPHERE_LAUNCHER_I_BLUEPRINT  : 22783,
    INTERDICTORS  : 12098,
    INTERFACE_CIRCUIT  : 25620,
    INTERMEDIATE_MEDAL  : 16713,
    INTERNAL_15_POCHVEN_FILAMENT  : 56079,
    INTERNAL_5_POCHVEN_FILAMENT  : 56078,
    INTERNAL_BULKHEAD  : 23166,
    INTERNAL_POLICIES  : 48752,
    INTERPLANETARY_CONSOLIDATION  : 2495,
    INTERRUPTIVE_WARP_DISRUPTOR  : 21510,
    INTERSTELLAR_NAVIGATION_LOGS  : 56708,
    INVESTOR_TRACKING_DISRUPTOR_I  : 22933,
    INVULNERABILITY_CORE_OPERATION  : 44067,
    ION_BLASTER_CANNON_I  : 575,
    ION_BLASTER_CANNON_I_BLUEPRINT  : 1126,
    ION_BLASTER_CANNON_II  : 3154,
    ION_FIELD_PROJECTION_BATTERY  : 17174,
    ION_FIELD_PROJECTION_BATTERY_BLUEPRINT  : 2792,
    ION_SIEGE_BLASTER_I  : 20450,
    ION_SIEGE_BLASTER_I_BLUEPRINT  : 20451,
    ION_SIEGE_BLASTER_II  : 37302,
    ION_THRUSTER  : 11531,
    ION_THRUSTER_BLUEPRINT  : 17329,
    IONIC_SOLUTIONS  : 2309,
    IRIDESCENT_GNEISS  : 17865,
    IRIDIUM_CHARGE_L  : 233,
    IRIDIUM_CHARGE_L_BLUEPRINT  : 1148,
    IRIDIUM_CHARGE_M  : 225,
    IRIDIUM_CHARGE_M_BLUEPRINT  : 1140,
    IRIDIUM_CHARGE_S  : 217,
    IRIDIUM_CHARGE_S_BLUEPRINT  : 1132,
    IRIDIUM_CHARGE_XL  : 17650,
    IRIDIUM_CHARGE_XL_BLUEPRINT  : 17651,
    IRON_CHARGE_L  : 231,
    IRON_CHARGE_L_BLUEPRINT  : 1146,
    IRON_CHARGE_M  : 223,
    IRON_CHARGE_M_BLUEPRINT  : 1138,
    IRON_CHARGE_S  : 215,
    IRON_CHARGE_S_BLUEPRINT  : 1130,
    IRON_CHARGE_XL  : 17652,
    IRON_CHARGE_XL_BLUEPRINT  : 17653,
    IRON_PIKE_KINETIC_LANCE  : 41439,
    IRON_PIKE_KINETIC_LANCE_BLUEPRINT  : 41446,
    ISHKUR  : 12042,
    ISHKUR_BIOSECURITY_RESPONDERS_SKIN  : 55623,
    ISHKUR_BLUEPRINT  : 12043,
    ISHKUR_EXOPLANETS_HUNTER_SKIN  : 45878,
    ISHKUR_GALNET_STREAMCAST_UNIT_SKIN  : 57729,
    ISHKUR_GLACIAL_DRIFT_SKIN  : 46930,
    ISHKUR_GLITTERING_DREAM_SKIN  : 47523,
    ISHKUR_INNER_ZONE_VANGUARD_SKIN  : 52713,
    ISHKUR_INTAKI_SYNDICATE_SKIN  : 36388,
    ISHKUR_KOPIS_EDGE_SKIN  : 46390,
    ISHKUR_SERPENTIS_SKIN  : 42154,
    ISHKUR_SPIRIT_SKIN  : 44896,
    ISHKUR_VALIMOR_LEGACY_SKIN  : 37554,
    ISHKUR_ZYDRINE_BURN_SKIN  : 76954,
    ISHTAR  : 12005,
    ISHTAR_ACE_OF_PODHUNTERS_SKIN  : 63208,
    ISHTAR_BIOSECURITY_RESPONDERS_SKIN  : 55626,
    ISHTAR_BLAZE_SQUADRON_SKIN  : 47103,
    ISHTAR_BLUEPRINT  : 12006,
    ISHTAR_CAILLE_NEON_SKIN  : 50148,
    ISHTAR_CAPSULEER_DAY_XV_SKIN  : 47632,
    ISHTAR_DRAGON_PHOENIX_SKIN  : 54429,
    ISHTAR_EXOPLANETS_HUNTER_SKIN  : 45897,
    ISHTAR_FEDERAL_POLICE_SKIN  : 50003,
    ISHTAR_GILDED_PREDATOR_SKIN  : 77175,
    ISHTAR_GLACIAL_DRIFT_SKIN  : 46943,
    ISHTAR_GLITTERING_DREAM_SKIN  : 47532,
    ISHTAR_GREEN_DEMONS_SKIN  : 59340,
    ISHTAR_HEARTSURGE_SKIN  : 53958,
    ISHTAR_IGC_SKIN  : 48557,
    ISHTAR_INNER_ZONE_VANGUARD_SKIN  : 52732,
    ISHTAR_INTAKI_SYNDICATE_SKIN  : 36385,
    ISHTAR_KOPIS_EDGE_SKIN  : 46409,
    ISHTAR_LUMINAIRE_RISING_SKIN  : 64569,
    ISHTAR_RUBY_SUNGRAZER_SKIN  : 45567,
    ISHTAR_SAPPHIRE_SUNGRAZER_SKIN  : 46554,
    ISHTAR_SERPENTIS_SKIN  : 42171,
    ISHTAR_SPIRIT_SKIN  : 44915,
    ISHTAR_VALIMOR_LEGACY_SKIN  : 37568,
    ISHTAR_YOIUL_STAR_SKIN  : 53428,
    ISHTAR_ZYDRINE_BURN_SKIN  : 76951,
    ISOGEN  : 37,
    ISOTROPIC_DEPOSITION_GUIDE  : 57452,
    ISOTROPIC_NEOFULLERENE_ALPHA_3  : 57463,
    ISOTROPIC_NEOFULLERENE_ALPHA_3_REACTION_FORMULA  : 57500,
    ISOTROPIC_NEOFULLERENE_BETA_6  : 57464,
    ISOTROPIC_NEOFULLERENE_BETA_6_REACTION_FORMULA  : 57501,
    ISOTROPIC_NEOFULLERENE_GAMMA_9  : 57465,
    ISOTROPIC_NEOFULLERENE_GAMMA_9_REACTION_FORMULA  : 57502,
    ITERON_MARK_V  : 657,
    ITERON_MARK_V_BIOSECURITY_RESPONDERS_SKIN  : 55588,
    ITERON_MARK_V_BLUEPRINT  : 992,
    ITERON_MARK_V_EXOPLANETS_HUNTER_SKIN  : 45923,
    ITERON_MARK_V_GLACIAL_DRIFT_SKIN  : 46969,
    ITERON_MARK_V_INNER_ZONE_SHIPPING_SKIN  : 34755,
    ITERON_MARK_V_INNER_ZONE_VANGUARD_SKIN  : 52758,
    ITERON_MARK_V_INTERBUS_SKIN  : 34756,
    ITERON_MARK_V_KOPIS_EDGE_SKIN  : 46435,
    ITERON_MARK_V_LODESTRIKE_SKIN  : 46750,
    ITERON_MARK_V_RUBY_SUNGRAZER_SKIN  : 45576,
    ITERON_MARK_V_SERPENTIS_SKIN  : 42192,
    ITERON_MARK_V_SPIRIT_SKIN  : 44941,
    ITERON_MARK_V_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 64394,
    ITERON_MARK_V_VALIMOR_LEGACY_SKIN  : 37588,
    J5_ENDURING_WARP_DISRUPTOR  : 5399,
    J5B_ENDURING_WARP_SCRAMBLER  : 5439,
    JACKDAW  : 34828,
    JACKDAW_BIOSECURITY_RESPONDERS_SKIN  : 55531,
    JACKDAW_EXOPLANETS_HUNTER_SKIN  : 45832,
    JACKDAW_GALNET_STREAMCAST_UNIT_SKIN  : 76485,
    JACKDAW_GHOSTBIRD_SKIN  : 48175,
    JACKDAW_GLACIAL_DRIFT_SKIN  : 46840,
    JACKDAW_HEARTSURGE_SKIN  : 53955,
    JACKDAW_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47611,
    JACKDAW_OCTOPUS_RED_FLIGHT_SKIN  : 46728,
    JACKDAW_QUANTUM_POTENTIAL_SKIN  : 48566,
    JACKDAW_STEEL_CARDINAL_SKIN  : 46109,
    JACKDAW_UNION_DAY_EXECUTIVE_SKIN  : 71723,
    JACKDAW_VITALSHIFT_EGO_SKIN  : 52554,
    JACKDAW_YOIUL_FESTIVAL_YC121_SKIN  : 49952,
    JACKDAW_YOIUL_STAR_SKIN  : 53424,
    JACKDAW_ZENTO_ISIDEKO_COMBINE_SKIN  : 53169,
    JAGUAR  : 11400,
    JAGUAR_BIOSECURITY_RESPONDERS_SKIN  : 55676,
    JAGUAR_BLUE_TIGER_SKIN  : 36804,
    JAGUAR_BLUEPRINT  : 11401,
    JAGUAR_CHAINBREAKER_KHUMAAK_SKIN  : 77962,
    JAGUAR_DAWN_OF_LIBERATION_SKIN  : 48491,
    JAGUAR_EXOPLANETS_HUNTER_SKIN  : 45939,
    JAGUAR_GLACIAL_DRIFT_SKIN  : 46880,
    JAGUAR_HAZARD_CONTROL_SKIN  : 56902,
    JAGUAR_IN_RUST_WE_TRUST_SKIN  : 52423,
    JAGUAR_JUSTICE_SKIN  : 36429,
    JAGUAR_SNOWLINE_BLADERACER_SKIN  : 52850,
    JAGUAR_TRONHADAR_INK_SKIN  : 40473,
    JAGUAR_VALKLEAR_GLORY_SKIN  : 36757,
    JANITOR  : 13267,
    JARKON_PUMANS_TAG  : 19663,
    JASPET  : 1226,
    JASPET_PROCESSING  : 12187,
    JAVELIN_L  : 12803,
    JAVELIN_M  : 12801,
    JAVELIN_S  : 12620,
    JAVELIN_XL  : 41326,
    JENMEIS_TAG  : 17623,
    JEREK_ZUOMIS_INSIGNIA  : 15645,
    JET_OCHRE  : 46675,
    JEZAJAS_GIANT_SECURE_CONTAINER  : 44205,
    JIN_MEI_SILK_CORD  : 48142,
    JITA_PROTEST_YC113  : 52275,
    JOINT_HARVESTING_STRONG_BOX  : 43683,
    JORMUNGANDR_EXPLOSIVE_REAPER  : 41444,
    JORMUNGANDR_EXPLOSIVE_REAPER_BLUEPRINT  : 41451,
    JOUST_HEAVY_PULSE_LASER_I  : 23842,
    JOVIAN_DNA_STRAND  : 40541,
    JUDGMENT_ELECTROMAGNETIC_DOOMSDAY  : 24550,
    JUDGMENT_ELECTROMAGNETIC_DOOMSDAY_BLUEPRINT  : 24551,
    JUMP_BRIDGE  : 27897,
    JUMP_DRIVE_CALIBRATION  : 21611,
    JUMP_DRIVE_CONTROL_NEXUS  : 30268,
    JUMP_DRIVE_OPERATION  : 3456,
    JUMP_FATIGUE_RECOVERY_AGENT  : 34419,
    JUMP_FREIGHTERS  : 29029,
    JUMP_FUEL_CONSERVATION  : 21610,
    JUMP_PORTAL_GENERATION  : 24562,
    JUMP_PORTAL_GENERATION_INSTRUCTION_HOLOREEL  : 34428,
    JUMP_PORTAL_GENERATOR_I  : 23953,
    JUMP_PORTAL_GENERATOR_I_BLUEPRINT  : 23954,
    JURY_RIGGING  : 26252,
    KAALAKIOTA_PEAKS_VODKA  : 44215,
    KAATARAS_CUSTOM_BALLISTIC_CONTROL_SYSTEM  : 46270,
    KAATARAS_CUSTOM_MAGNETIC_FIELD_STABILIZER  : 44113,
    KAIKKA_PEUNATOS_TAG  : 17298,
    KAIKKAS_MODIFIED_350MM_RAILGUN  : 14391,
    KAIKKAS_MODIFIED_425MM_RAILGUN  : 14401,
    KAIKKAS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14684,
    KAIKKAS_MODIFIED_CLOAKING_DEVICE  : 14776,
    KAIKKAS_MODIFIED_CO_PROCESSOR  : 14768,
    KAIKKAS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14672,
    KAIKKAS_MODIFIED_DUAL_250MM_RAILGUN  : 14411,
    KAIKKAS_MODIFIED_EM_SHIELD_AMPLIFIER  : 14740,
    KAIKKAS_MODIFIED_EM_SHIELD_HARDENER  : 14752,
    KAIKKAS_MODIFIED_EXPLOSIVE_SHIELD_AMPLIFIER  : 14716,
    KAIKKAS_MODIFIED_EXPLOSIVE_SHIELD_HARDENER  : 14756,
    KAIKKAS_MODIFIED_KINETIC_SHIELD_AMPLIFIER  : 14732,
    KAIKKAS_MODIFIED_KINETIC_SHIELD_HARDENER  : 14748,
    KAIKKAS_MODIFIED_LARGE_GRAVITON_SMARTBOMB  : 14692,
    KAIKKAS_MODIFIED_LARGE_SHIELD_BOOSTER  : 14700,
    KAIKKAS_MODIFIED_MULTISPECTRAL_ECM  : 20201,
    KAIKKAS_MODIFIED_MULTISPECTRUM_SHIELD_HARDENER  : 14764,
    KAIKKAS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33457,
    KAIKKAS_MODIFIED_SHIELD_BOOST_AMPLIFIER  : 14708,
    KAIKKAS_MODIFIED_THERMAL_SHIELD_AMPLIFIER  : 14724,
    KAIKKAS_MODIFIED_THERMAL_SHIELD_HARDENER  : 14760,
    KAIKKAS_MODIFIED_TORPEDO_LAUNCHER  : 14680,
    KAIKKAS_MODIFIED_X_LARGE_SHIELD_BOOSTER  : 14704,
    KALI_MIDEZS_INSIGNIA  : 15667,
    KAMEIRAS  : 17767,
    KAPLI_BREAD  : 48590,
    KAPTEYN_COMPACT_SENSOR_DAMPENER  : 5301,
    KARURA  : 77284,
    KARZO_SARUMS_INSIGNIA  : 15639,
    KEEPSTAR  : 35834,
    KEEPSTAR_BLUEPRINT  : 36968,
    KEEPSTAR_UPWELL_QUANTUM_CORE  : 56207,
    KERES  : 11174,
    KERES_BIOSECURITY_RESPONDERS_SKIN  : 55614,
    KERES_EXOPLANETS_HUNTER_SKIN  : 45881,
    KERES_GLACIAL_DRIFT_SKIN  : 46933,
    KERES_HEARTSURGE_SKIN  : 76287,
    KERES_INNER_ZONE_VANGUARD_SKIN  : 52716,
    KERES_INTAKI_SYNDICATE_SKIN  : 36378,
    KERES_KOPIS_EDGE_SKIN  : 46393,
    KERES_SERPENTIS_SKIN  : 42157,
    KERES_SPIRIT_SKIN  : 44899,
    KERES_VALIMOR_LEGACY_SKIN  : 37557,
    KERES_VERSUS_REDFORCE_SKIN  : 63859,
    KERNITE  : 20,
    KERNITE_PROCESSING  : 12188,
    KESTREL  : 602,
    KESTREL_AURORA_UNIVERSALIS_SKIN  : 60911,
    KESTREL_BIOSECURITY_RESPONDERS_SKIN  : 55529,
    KESTREL_BLUE_TIGER_SKIN  : 36709,
    KESTREL_BLUEPRINT  : 949,
    KESTREL_CALDARI_NAVY_SKIN  : 58874,
    KESTREL_EXOPLANETS_HUNTER_SKIN  : 45816,
    KESTREL_FORWARD_4_4_SKIN  : 56867,
    KESTREL_GHOSTBIRD_SKIN  : 48159,
    KESTREL_GLACIAL_DRIFT_SKIN  : 44150,
    KESTREL_GURISTAS_SKIN  : 54554,
    KESTREL_MATIGU_SEABEAST_SKIN  : 40583,
    KESTREL_RAATA_SUNSET_SKIN  : 36637,
    KESTREL_SCOPE_SYNDICATION_YC122_SKIN  : 61192,
    KESTREL_STEEL_CARDINAL_SKIN  : 46093,
    KESTREL_WIYRKOMI_SKIN  : 34733,
    KESTREL_ZAKURA_HANSEI_SKIN  : 57106,
    KESTREL_ZENTO_ISIDEKO_COMBINE_SKIN  : 53153,
    KHANID_EAGLE_INSIGNIA  : 16186,
    KHANID_ELITE_FIGHTER_INSIGNIA  : 16181,
    KHANID_FIGHTER_INSIGNIA  : 16180,
    KHANID_HAWK_INSIGNIA  : 16185,
    KHANID_HIGH_COMMANDER_INSIGNIA  : 16188,
    KHANID_KINGDOM_STARBASE_CHARTER  : 24596,
    KHANID_NAVY_BALLISTIC_CONTROL_SYSTEM  : 28563,
    KHANID_NAVY_CAP_RECHARGER  : 28530,
    KHANID_NAVY_CAPACITOR_POWER_RELAY  : 28532,
    KHANID_NAVY_CO_PROCESSOR  : 28561,
    KHANID_NAVY_EM_ARMOR_HARDENER  : 28522,
    KHANID_NAVY_EM_COATING  : 28554,
    KHANID_NAVY_EM_ENERGIZED_MEMBRANE  : 28540,
    KHANID_NAVY_EXPLOSIVE_ARMOR_HARDENER  : 28524,
    KHANID_NAVY_EXPLOSIVE_COATING  : 28552,
    KHANID_NAVY_EXPLOSIVE_ENERGIZED_MEMBRANE  : 28538,
    KHANID_NAVY_HEAVY_ASSAULT_MISSILE_LAUNCHER  : 28565,
    KHANID_NAVY_KINETIC_ARMOR_HARDENER  : 28526,
    KHANID_NAVY_KINETIC_COATING  : 28547,
    KHANID_NAVY_KINETIC_ENERGIZED_MEMBRANE  : 28536,
    KHANID_NAVY_LARGE_ARMOR_REPAIRER  : 28544,
    KHANID_NAVY_LARGE_EMP_SMARTBOMB  : 28545,
    KHANID_NAVY_MEDIUM_ARMOR_REPAIRER  : 28549,
    KHANID_NAVY_MEDIUM_EMP_SMARTBOMB  : 28550,
    KHANID_NAVY_MULTISPECTRUM_COATING  : 28520,
    KHANID_NAVY_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 28534,
    KHANID_NAVY_ROCKET_LAUNCHER  : 28511,
    KHANID_NAVY_SMALL_ARMOR_REPAIRER  : 28556,
    KHANID_NAVY_SMALL_EMP_SMARTBOMB  : 28557,
    KHANID_NAVY_STASIS_WEBIFIER  : 28514,
    KHANID_NAVY_THERMAL_ARMOR_HARDENER  : 28528,
    KHANID_NAVY_THERMAL_COATING  : 28559,
    KHANID_NAVY_THERMAL_ENERGIZED_MEMBRANE  : 28542,
    KHANID_NAVY_WARP_DISRUPTOR  : 28516,
    KHANID_NAVY_WARP_SCRAMBLER  : 28518,
    KHANID_OFFICER_INSIGNIA  : 16184,
    KHANID_ROOKIE_INSIGNIA  : 16179,
    KHANID_ROYAL_INSIGNIA  : 16189,
    KHANID_SCOUT_INSIGNIA  : 16182,
    KHANID_SLAVE_TRADER_INSIGNIA  : 15981,
    KHANID_SPARROW_INSIGNIA  : 16183,
    KHANID_WARBIRD_INSIGNIA  : 16187,
    KHUMAAK  : 12478,
    KIDNAPPED_ORE_RESEARCHER  : 62710,
    KIIRASHA_FINAL_BROADCAST_FRAGMENT  : 44209,
    KIKIMORA  : 49710,
    KIKIMORA_BLACKFIRE_STEEL_SKIN  : 49923,
    KIKIMORA_COPPER_LIGHTNING_SKIN  : 60757,
    KIKIMORA_EDENS_HUNTERS_SKIN  : 54905,
    KIKIMORA_HADEAN_HORROR_SKIN  : 49933,
    KIKIMORA_KYBERNAUT_CLADE_SKIN  : 54914,
    KIKIMORA_METAMATERIA_EXOTICA_SKIN  : 50140,
    KIKIMORA_NETHERWORLD_STRIKER_SKIN  : 50158,
    KIKIMORA_RED_MEATGRINDER_SKIN  : 52321,
    KINDRED_GYROSTABILIZER  : 21486,
    KINETIC_ARMOR_COMPENSATION  : 22808,
    KINETIC_ARMOR_HARDENER_I  : 11305,
    KINETIC_ARMOR_HARDENER_I_BLUEPRINT  : 11306,
    KINETIC_ARMOR_HARDENER_II  : 11644,
    KINETIC_COATING_I  : 1284,
    KINETIC_COATING_I_BLUEPRINT  : 1285,
    KINETIC_COATING_II  : 1286,
    KINETIC_ENERGIZED_MEMBRANE_I  : 11247,
    KINETIC_ENERGIZED_MEMBRANE_I_BLUEPRINT  : 11248,
    KINETIC_ENERGIZED_MEMBRANE_II  : 11249,
    KINETIC_SHIELD_AMPLIFIER_I  : 2545,
    KINETIC_SHIELD_AMPLIFIER_I_BLUEPRINT  : 2546,
    KINETIC_SHIELD_AMPLIFIER_II  : 2547,
    KINETIC_SHIELD_COMPENSATION  : 12366,
    KINETIC_SHIELD_HARDENER_I  : 2291,
    KINETIC_SHIELD_HARDENER_I_BLUEPRINT  : 2292,
    KINETIC_SHIELD_HARDENER_II  : 2299,
    KIRIN  : 37458,
    KIRIN_BIOSECURITY_RESPONDERS_SKIN  : 55557,
    KIRIN_BLUE_TIGER_SKIN  : 40321,
    KIRIN_EMERGENCY_RESPONSE_SQUAD_SKIN  : 60194,
    KIRIN_EXOPLANETS_HUNTER_SKIN  : 45825,
    KIRIN_GHOSTBIRD_SKIN  : 48168,
    KIRIN_GLACIAL_DRIFT_SKIN  : 46835,
    KIRIN_MATIGU_SEABEAST_SKIN  : 40592,
    KIRIN_STEEL_CARDINAL_SKIN  : 46102,
    KIRIN_ZENTO_ISIDEKO_COMBINE_SKIN  : 53162,
    KITSUNE  : 11194,
    KITSUNE_BIOSECURITY_RESPONDERS_SKIN  : 55554,
    KITSUNE_BLUE_TIGER_SKIN  : 36715,
    KITSUNE_EXOPLANETS_HUNTER_SKIN  : 45822,
    KITSUNE_GHOSTBIRD_SKIN  : 48165,
    KITSUNE_GLACIAL_DRIFT_SKIN  : 46832,
    KITSUNE_GURISTAS_SKIN  : 54555,
    KITSUNE_MATIGU_SEABEAST_SKIN  : 40589,
    KITSUNE_RAATA_SUNSET_SKIN  : 36643,
    KITSUNE_STEEL_CARDINAL_SKIN  : 46099,
    KITSUNE_WIYRKOMI_SKIN  : 36338,
    KITSUNE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53159,
    KNIGHT_MEDIUM_REMOTE_CAPACITOR_TRANSMITTER  : 23854,
    KOMODO  : 45649,
    KOMODO_GLACIAL_DRIFT_SKIN  : 46878,
    KOR_AZOR_FAMILY_DECREE  : 44207,
    KORAKOS_MODIFIED_DRONE_CONTROL_UNIT  : 54562,
    KORRANIS_DNA  : 13918,
    KRITAK_KNIFE  : 48591,
    KRONOS  : 28661,
    KRONOS_BIOSECURITY_RESPONDERS_SKIN  : 55617,
    KRONOS_CAILLE_NEON_SKIN  : 50149,
    KRONOS_EXOPLANETS_HUNTER_SKIN  : 45915,
    KRONOS_FEDERAL_POLICE_SKIN  : 34817,
    KRONOS_FEDERATION_NAVY_SKIN  : 58893,
    KRONOS_GLACIAL_DRIFT_SKIN  : 46961,
    KRONOS_HEADHUNTER_SKIN  : 49426,
    KRONOS_HEARTSURGE_SKIN  : 76286,
    KRONOS_INNER_ZONE_VANGUARD_SKIN  : 52750,
    KRONOS_INTAKI_SYNDICATE_SKIN  : 36404,
    KRONOS_KOPIS_EDGE_SKIN  : 46427,
    KRONOS_MALAGRIN_MARSHLIGHT_SKIN  : 60568,
    KRONOS_RUBY_SUNGRAZER_SKIN  : 45571,
    KRONOS_SAPPHIRE_SUNGRAZER_SKIN  : 46555,
    KRONOS_SERPENTIS_SKIN  : 42186,
    KRONOS_SPIRIT_SKIN  : 44933,
    KRONOS_VALIMOR_LEGACY_SKIN  : 37582,
    KRONOS_ZAKURA_SHUMYU_SKIN  : 57071,
    KRUSUAL_ETCHED_STICKS  : 48588,
    KRUULS_DNA  : 14292,
    KRYOS  : 654,
    KRYOS_BIOSECURITY_RESPONDERS_SKIN  : 55587,
    KRYOS_BLUEPRINT  : 989,
    KRYOS_EXOPLANETS_HUNTER_SKIN  : 45924,
    KRYOS_GLACIAL_DRIFT_SKIN  : 46970,
    KRYOS_INNER_ZONE_VANGUARD_SKIN  : 52759,
    KRYOS_KOPIS_EDGE_SKIN  : 46436,
    KRYOS_SERPENTIS_SKIN  : 42193,
    KRYOS_SPIRIT_SKIN  : 44942,
    KRYOS_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 64391,
    KRYOS_VALIMOR_LEGACY_SKIN  : 37589,
    KRYSTALLOS  : 16269,
    LABORATORY_OPERATION  : 3406,
    LACHESIS  : 11971,
    LACHESIS_BIOSECURITY_RESPONDERS_SKIN  : 55635,
    LACHESIS_BLUEPRINT  : 11972,
    LACHESIS_EXOPLANETS_HUNTER_SKIN  : 45901,
    LACHESIS_GLACIAL_DRIFT_SKIN  : 46947,
    LACHESIS_INNER_ZONE_VANGUARD_SKIN  : 52736,
    LACHESIS_INTAKI_SYNDICATE_SKIN  : 36383,
    LACHESIS_KOPIS_EDGE_SKIN  : 46413,
    LACHESIS_SERPENTIS_SKIN  : 42175,
    LACHESIS_SPIRIT_SKIN  : 44919,
    LACHESIS_VALIMOR_LEGACY_SKIN  : 37572,
    LADAR_BACKUP_ARRAY_I_BLUEPRINT  : 11824,
    LADAR_ECM_I  : 1958,
    LADAR_ECM_I_BLUEPRINT  : 11630,
    LADAR_ECM_II  : 2559,
    LADAR_FTL_INTERLINK_COMMUNICATOR  : 57477,
    LADAR_FTL_INTERLINK_COMMUNICATOR_BLUEPRINT  : 57514,
    LADAR_SENSOR_CLUSTER  : 11536,
    LADAR_SENSOR_CLUSTER_BLUEPRINT  : 17333,
    LADAR_SENSOR_COMPENSATION  : 33001,
    LAELAPS  : 60764,
    LANCE_MEDIUM_EMP_SMARTBOMB_I  : 23866,
    LANCER_DREADNOUGHTS  : 77738,
    LANGUID_ENDURING_LADAR_ECM  : 19935,
    LANTHANUM_METALLOFULLERENE  : 30307,
    LANTHANUM_METALLOFULLERENE_REACTION_FORMULA  : 46161,
    LARGE_ACM_COMPACT_ARMOR_REPAIRER  : 4613,
    LARGE_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I  : 25976,
    LARGE_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 25977,
    LARGE_ALGID_ENERGY_ADMINISTRATIONS_UNIT_II  : 26384,
    LARGE_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I  : 26004,
    LARGE_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 26005,
    LARGE_ALGID_HYBRID_ADMINISTRATIONS_UNIT_II  : 26398,
    LARGE_ANCILLARY_ARMOR_REPAIRER  : 33103,
    LARGE_ANCILLARY_CURRENT_ROUTER_I  : 25956,
    LARGE_ANCILLARY_CURRENT_ROUTER_I_BLUEPRINT  : 25957,
    LARGE_ANCILLARY_CURRENT_ROUTER_II  : 26370,
    LARGE_ANCILLARY_REMOTE_ARMOR_REPAIRER  : 41478,
    LARGE_ANCILLARY_REMOTE_SHIELD_BOOSTER  : 41482,
    LARGE_ANCILLARY_SHIELD_BOOSTER  : 4391,
    LARGE_ARMOR_REPAIRER_I  : 3538,
    LARGE_ARMOR_REPAIRER_I_BLUEPRINT  : 3539,
    LARGE_ARMOR_REPAIRER_II  : 3540,
    LARGE_ARTILLERY_BATTERY  : 16689,
    LARGE_ARTILLERY_BATTERY_BLUEPRINT  : 2805,
    LARGE_ARTILLERY_SPECIALIZATION  : 12203,
    LARGE_ASTEROID_ORE_COMPRESSOR_I  : 62625,
    LARGE_ASTEROID_ORE_COMPRESSOR_I_BLUEPRINT  : 62669,
    LARGE_ASYMMETRIC_ENDURING_REMOTE_SHIELD_BOOSTER  : 8639,
    LARGE_AUDIT_LOG_SECURE_CONTAINER  : 17365,
    LARGE_AUDIT_LOG_SECURE_CONTAINER_BLUEPRINT  : 32870,
    LARGE_AUTOCANNON_BATTERY  : 17770,
    LARGE_AUTOCANNON_BATTERY_BLUEPRINT  : 2807,
    LARGE_AUTOCANNON_SPECIALIZATION  : 12209,
    LARGE_AUTOMATED_STRUCTURAL_RESTORATION  : 5757,
    LARGE_AUXILIARY_NANO_PUMP_I  : 25896,
    LARGE_AUXILIARY_NANO_PUMP_I_BLUEPRINT  : 25897,
    LARGE_AUXILIARY_NANO_PUMP_II  : 26294,
    LARGE_AUXILIARY_THRUSTERS_I  : 26060,
    LARGE_AUXILIARY_THRUSTERS_I_BLUEPRINT  : 26061,
    LARGE_AUXILIARY_THRUSTERS_II  : 26318,
    LARGE_AZEOTROPIC_RESTRAINED_SHIELD_EXTENDER  : 8419,
    LARGE_BAY_LOADING_ACCELERATOR_I  : 26026,
    LARGE_BAY_LOADING_ACCELERATOR_I_BLUEPRINT  : 26027,
    LARGE_BAY_LOADING_ACCELERATOR_II  : 26406,
    LARGE_BEAM_LASER_BATTERY  : 16694,
    LARGE_BEAM_LASER_BATTERY_BLUEPRINT  : 2830,
    LARGE_BEAM_LASER_SPECIALIZATION  : 12205,
    LARGE_BLASTER_BATTERY  : 17402,
    LARGE_BLASTER_BATTERY_BLUEPRINT  : 2803,
    LARGE_BLASTER_SPECIALIZATION  : 12212,
    LARGE_C5_L_COMPACT_SHIELD_BOOSTER  : 10880,
    LARGE_CAP_BATTERY_I  : 2020,
    LARGE_CAP_BATTERY_I_BLUEPRINT  : 2021,
    LARGE_CAP_BATTERY_II  : 3504,
    LARGE_CAPACITOR_CONTROL_CIRCUIT_I  : 25948,
    LARGE_CAPACITOR_CONTROL_CIRCUIT_I_BLUEPRINT  : 25949,
    LARGE_CAPACITOR_CONTROL_CIRCUIT_II  : 26374,
    LARGE_CARGOHOLD_OPTIMIZATION_I  : 26072,
    LARGE_CARGOHOLD_OPTIMIZATION_I_BLUEPRINT  : 26073,
    LARGE_CARGOHOLD_OPTIMIZATION_II  : 26304,
    LARGE_CLARITY_WARD_ENDURING_SHIELD_BOOSTER  : 10876,
    LARGE_COAXIAL_COMPACT_REMOTE_ARMOR_REPAIRER  : 16451,
    LARGE_COMMAND_PROCESSOR_I  : 43898,
    LARGE_COMMAND_PROCESSOR_I_BLUEPRINT  : 43906,
    LARGE_COMPACT_PB_ACID_CAP_BATTERY  : 4871,
    LARGE_COMPACT_VORTON_PROJECTOR  : 54751,
    LARGE_CONSORTIUM_VORTON_PROJECTOR  : 54754,
    LARGE_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I  : 25906,
    LARGE_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I_BLUEPRINT  : 25907,
    LARGE_CORE_DEFENSE_CAPACITOR_SAFEGUARD_II  : 26444,
    LARGE_CORE_DEFENSE_CHARGE_ECONOMIZER_I  : 26090,
    LARGE_CORE_DEFENSE_CHARGE_ECONOMIZER_I_BLUEPRINT  : 26091,
    LARGE_CORE_DEFENSE_CHARGE_ECONOMIZER_II  : 26446,
    LARGE_CORE_DEFENSE_FIELD_EXTENDER_I  : 26088,
    LARGE_CORE_DEFENSE_FIELD_EXTENDER_I_BLUEPRINT  : 26089,
    LARGE_CORE_DEFENSE_FIELD_EXTENDER_II  : 26448,
    LARGE_CORE_DEFENSE_FIELD_PURGER_I  : 26084,
    LARGE_CORE_DEFENSE_FIELD_PURGER_I_BLUEPRINT  : 26085,
    LARGE_CORE_DEFENSE_FIELD_PURGER_II  : 26450,
    LARGE_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I  : 26086,
    LARGE_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I_BLUEPRINT  : 26087,
    LARGE_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_II  : 26452,
    LARGE_DEGENERATIVE_CONCUSSION_BOMB_I  : 21538,
    LARGE_DISINTEGRATOR_SPECIALIZATION  : 47875,
    LARGE_DRONE_CONTROL_RANGE_AUGMENTOR_I  : 25908,
    LARGE_DRONE_CONTROL_RANGE_AUGMENTOR_I_BLUEPRINT  : 25909,
    LARGE_DRONE_CONTROL_RANGE_AUGMENTOR_II  : 26324,
    LARGE_DRONE_DURABILITY_ENHANCER_I  : 25916,
    LARGE_DRONE_DURABILITY_ENHANCER_I_BLUEPRINT  : 25917,
    LARGE_DRONE_DURABILITY_ENHANCER_II  : 26326,
    LARGE_DRONE_MINING_AUGMENTOR_I  : 25918,
    LARGE_DRONE_MINING_AUGMENTOR_I_BLUEPRINT  : 25919,
    LARGE_DRONE_MINING_AUGMENTOR_II  : 26328,
    LARGE_DRONE_REPAIR_AUGMENTOR_I  : 25910,
    LARGE_DRONE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 25911,
    LARGE_DRONE_REPAIR_AUGMENTOR_II  : 26330,
    LARGE_DRONE_SCOPE_CHIP_I  : 25912,
    LARGE_DRONE_SCOPE_CHIP_I_BLUEPRINT  : 25913,
    LARGE_DRONE_SCOPE_CHIP_II  : 26332,
    LARGE_DRONE_SPEED_AUGMENTOR_I  : 25914,
    LARGE_DRONE_SPEED_AUGMENTOR_I_BLUEPRINT  : 25915,
    LARGE_DRONE_SPEED_AUGMENTOR_II  : 26334,
    LARGE_DYNAMIC_FUEL_VALVE_I  : 26056,
    LARGE_DYNAMIC_FUEL_VALVE_I_BLUEPRINT  : 26057,
    LARGE_DYNAMIC_FUEL_VALVE_II  : 26306,
    LARGE_EGRESS_PORT_MAXIMIZER_I  : 25950,
    LARGE_EGRESS_PORT_MAXIMIZER_I_BLUEPRINT  : 25951,
    LARGE_EGRESS_PORT_MAXIMIZER_II  : 26368,
    LARGE_EM_ARMOR_REINFORCER_I  : 25736,
    LARGE_EM_ARMOR_REINFORCER_I_BLUEPRINT  : 25737,
    LARGE_EM_ARMOR_REINFORCER_II  : 26286,
    LARGE_EM_SHIELD_REINFORCER_I  : 26076,
    LARGE_EM_SHIELD_REINFORCER_I_BLUEPRINT  : 26077,
    LARGE_EM_SHIELD_REINFORCER_II  : 26436,
    LARGE_EMISSION_SCOPE_SHARPENER_I  : 25930,
    LARGE_EMISSION_SCOPE_SHARPENER_I_BLUEPRINT  : 25931,
    LARGE_EMISSION_SCOPE_SHARPENER_II  : 26342,
    LARGE_EMP_SMARTBOMB_I  : 3993,
    LARGE_EMP_SMARTBOMB_I_BLUEPRINT  : 3994,
    LARGE_EMP_SMARTBOMB_II  : 3995,
    LARGE_ENERGY_AMBIT_EXTENSION_I  : 25970,
    LARGE_ENERGY_AMBIT_EXTENSION_I_BLUEPRINT  : 25971,
    LARGE_ENERGY_AMBIT_EXTENSION_II  : 26386,
    LARGE_ENERGY_BURST_AERATOR_I  : 25978,
    LARGE_ENERGY_BURST_AERATOR_I_BLUEPRINT  : 25979,
    LARGE_ENERGY_BURST_AERATOR_II  : 26380,
    LARGE_ENERGY_COLLISION_ACCELERATOR_I  : 25980,
    LARGE_ENERGY_COLLISION_ACCELERATOR_I_BLUEPRINT  : 25981,
    LARGE_ENERGY_COLLISION_ACCELERATOR_II  : 26382,
    LARGE_ENERGY_DISCHARGE_ELUTRIATION_I  : 25968,
    LARGE_ENERGY_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 25969,
    LARGE_ENERGY_DISCHARGE_ELUTRIATION_II  : 26378,
    LARGE_ENERGY_LOCUS_COORDINATOR_I  : 25972,
    LARGE_ENERGY_LOCUS_COORDINATOR_I_BLUEPRINT  : 25973,
    LARGE_ENERGY_LOCUS_COORDINATOR_II  : 26388,
    LARGE_ENERGY_METASTASIS_ADJUSTER_I  : 25974,
    LARGE_ENERGY_METASTASIS_ADJUSTER_I_BLUEPRINT  : 25975,
    LARGE_ENERGY_METASTASIS_ADJUSTER_II  : 26390,
    LARGE_ENERGY_TURRET  : 3309,
    LARGE_ENGINE_THERMAL_SHIELDING_I  : 26062,
    LARGE_ENGINE_THERMAL_SHIELDING_I_BLUEPRINT  : 26063,
    LARGE_ENGINE_THERMAL_SHIELDING_II  : 26308,
    LARGE_EXPLOSIVE_ARMOR_REINFORCER_I  : 25888,
    LARGE_EXPLOSIVE_ARMOR_REINFORCER_I_BLUEPRINT  : 25889,
    LARGE_EXPLOSIVE_ARMOR_REINFORCER_II  : 26288,
    LARGE_EXPLOSIVE_SHIELD_REINFORCER_I  : 26078,
    LARGE_EXPLOSIVE_SHIELD_REINFORCER_I_BLUEPRINT  : 26079,
    LARGE_EXPLOSIVE_SHIELD_REINFORCER_II  : 26438,
    LARGE_F_S9_REGOLITH_COMPACT_SHIELD_EXTENDER  : 8529,
    LARGE_FACTION_AMMUNITION_CRATE  : 49748,
    LARGE_FORTIZAR_CITADEL_BPC_TOKEN  : 40549,
    LARGE_FREIGHT_CONTAINER  : 33007,
    LARGE_FREIGHT_CONTAINER_BLUEPRINT  : 33008,
    LARGE_GAS_COMPRESSOR_I  : 62626,
    LARGE_GAS_COMPRESSOR_I_BLUEPRINT  : 62674,
    LARGE_GRAVITON_SMARTBOMB_I  : 3981,
    LARGE_GRAVITON_SMARTBOMB_I_BLUEPRINT  : 3982,
    LARGE_GRAVITON_SMARTBOMB_II  : 3983,
    LARGE_GRAVITY_CAPACITOR_UPGRADE_I  : 25936,
    LARGE_GRAVITY_CAPACITOR_UPGRADE_I_BLUEPRINT  : 25937,
    LARGE_GRAVITY_CAPACITOR_UPGRADE_II  : 26350,
    LARGE_HIGGS_ANCHOR_I  : 34306,
    LARGE_HIGGS_ANCHOR_I_BLUEPRINT  : 34307,
    LARGE_HOPE_HULL_RECONSTRUCTOR_I  : 5755,
    LARGE_HULL_REPAIRER_I  : 3663,
    LARGE_HULL_REPAIRER_I_BLUEPRINT  : 3664,
    LARGE_HULL_REPAIRER_II  : 3665,
    LARGE_HYBRID_AMBIT_EXTENSION_I  : 25998,
    LARGE_HYBRID_AMBIT_EXTENSION_I_BLUEPRINT  : 25999,
    LARGE_HYBRID_AMBIT_EXTENSION_II  : 26400,
    LARGE_HYBRID_BURST_AERATOR_I  : 26006,
    LARGE_HYBRID_BURST_AERATOR_I_BLUEPRINT  : 26007,
    LARGE_HYBRID_BURST_AERATOR_II  : 26394,
    LARGE_HYBRID_COLLISION_ACCELERATOR_I  : 26008,
    LARGE_HYBRID_COLLISION_ACCELERATOR_I_BLUEPRINT  : 26009,
    LARGE_HYBRID_COLLISION_ACCELERATOR_II  : 26396,
    LARGE_HYBRID_DISCHARGE_ELUTRIATION_I  : 25996,
    LARGE_HYBRID_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 25997,
    LARGE_HYBRID_DISCHARGE_ELUTRIATION_II  : 26392,
    LARGE_HYBRID_LOCUS_COORDINATOR_I  : 26000,
    LARGE_HYBRID_LOCUS_COORDINATOR_I_BLUEPRINT  : 26001,
    LARGE_HYBRID_LOCUS_COORDINATOR_II  : 26402,
    LARGE_HYBRID_METASTASIS_ADJUSTER_I  : 26002,
    LARGE_HYBRID_METASTASIS_ADJUSTER_I_BLUEPRINT  : 26003,
    LARGE_HYBRID_METASTASIS_ADJUSTER_II  : 26404,
    LARGE_HYBRID_TURRET  : 3307,
    LARGE_HYDRAULIC_BAY_THRUSTERS_I  : 26016,
    LARGE_HYDRAULIC_BAY_THRUSTERS_I_BLUEPRINT  : 26017,
    LARGE_HYDRAULIC_BAY_THRUSTERS_II  : 26416,
    LARGE_HYPERSPATIAL_VELOCITY_OPTIMIZER_I  : 26068,
    LARGE_HYPERSPATIAL_VELOCITY_OPTIMIZER_I_BLUEPRINT  : 26069,
    LARGE_HYPERSPATIAL_VELOCITY_OPTIMIZER_II  : 26322,
    LARGE_I_A_ENDURING_ARMOR_REPAIRER  : 4609,
    LARGE_I_AX_ENDURING_REMOTE_ARMOR_REPAIRER  : 16449,
    LARGE_I_B_POLARIZED_STRUCTURAL_REGENERATOR  : 5759,
    LARGE_ICE_COMPRESSOR_I  : 62628,
    LARGE_ICE_COMPRESSOR_I_BLUEPRINT  : 62671,
    LARGE_INDUCTIVE_COMPACT_REMOTE_CAPACITOR_TRANSMITTER  : 16487,
    LARGE_INDUSTRIAL_CORE_I  : 58945,
    LARGE_INDUSTRIAL_CORE_I_BLUEPRINT  : 59171,
    LARGE_INDUSTRIAL_CORE_II  : 58950,
    LARGE_INEFFICIENT_HULL_REPAIR_UNIT  : 5697,
    LARGE_INTEGRATIVE_HULL_REPAIR_UNIT  : 21508,
    LARGE_INVERTED_SIGNAL_FIELD_PROJECTOR_I  : 26110,
    LARGE_INVERTED_SIGNAL_FIELD_PROJECTOR_I_BLUEPRINT  : 26111,
    LARGE_INVERTED_SIGNAL_FIELD_PROJECTOR_II  : 26354,
    LARGE_IONIC_FIELD_PROJECTOR_I  : 26102,
    LARGE_IONIC_FIELD_PROJECTOR_I_BLUEPRINT  : 26103,
    LARGE_IONIC_FIELD_PROJECTOR_II  : 26358,
    LARGE_KINETIC_ARMOR_REINFORCER_I  : 25890,
    LARGE_KINETIC_ARMOR_REINFORCER_I_BLUEPRINT  : 25891,
    LARGE_KINETIC_ARMOR_REINFORCER_II  : 26290,
    LARGE_KINETIC_SHIELD_REINFORCER_I  : 26080,
    LARGE_KINETIC_SHIELD_REINFORCER_I_BLUEPRINT  : 26081,
    LARGE_KINETIC_SHIELD_REINFORCER_II  : 26440,
    LARGE_LIQUID_COOLED_ELECTRONICS_I  : 25934,
    LARGE_LIQUID_COOLED_ELECTRONICS_I_BLUEPRINT  : 25935,
    LARGE_LIQUID_COOLED_ELECTRONICS_II  : 26348,
    LARGE_LOW_FRICTION_NOZZLE_JOINTS_I  : 26058,
    LARGE_LOW_FRICTION_NOZZLE_JOINTS_I_BLUEPRINT  : 26059,
    LARGE_LOW_FRICTION_NOZZLE_JOINTS_II  : 26310,
    LARGE_MEMETIC_ALGORITHM_BANK_I  : 25932,
    LARGE_MEMETIC_ALGORITHM_BANK_I_BLUEPRINT  : 25933,
    LARGE_MEMETIC_ALGORITHM_BANK_II  : 26346,
    LARGE_MERCOXIT_COMPRESSOR_I  : 62630,
    LARGE_MERCOXIT_COMPRESSOR_I_BLUEPRINT  : 62676,
    LARGE_MICRO_JUMP_DRIVE  : 4383,
    LARGE_MOON_ORE_COMPRESSOR_I  : 62631,
    LARGE_MOON_ORE_COMPRESSOR_I_BLUEPRINT  : 62678,
    LARGE_MURKY_COMPACT_REMOTE_SHIELD_BOOSTER  : 8635,
    LARGE_NANOBOT_ACCELERATOR_I  : 25898,
    LARGE_NANOBOT_ACCELERATOR_I_BLUEPRINT  : 25899,
    LARGE_NANOBOT_ACCELERATOR_II  : 26296,
    LARGE_NOTOS_EXPLOSIVE_CHARGE_I  : 9772,
    LARGE_OUTLAW_SHIELD_BOOSTER  : 20621,
    LARGE_PARTICLE_DISPERSION_AUGMENTOR_I  : 26106,
    LARGE_PARTICLE_DISPERSION_AUGMENTOR_I_BLUEPRINT  : 26107,
    LARGE_PARTICLE_DISPERSION_AUGMENTOR_II  : 26352,
    LARGE_PARTICLE_DISPERSION_PROJECTOR_I  : 26108,
    LARGE_PARTICLE_DISPERSION_PROJECTOR_I_BLUEPRINT  : 26109,
    LARGE_PARTICLE_DISPERSION_PROJECTOR_II  : 26360,
    LARGE_PLASMA_SMARTBOMB_I  : 3987,
    LARGE_PLASMA_SMARTBOMB_I_BLUEPRINT  : 3988,
    LARGE_PLASMA_SMARTBOMB_II  : 3989,
    LARGE_POLYCARBON_ENGINE_HOUSING_I  : 26070,
    LARGE_POLYCARBON_ENGINE_HOUSING_I_BLUEPRINT  : 26071,
    LARGE_POLYCARBON_ENGINE_HOUSING_II  : 26312,
    LARGE_POWERGRID_SUBROUTINE_MAXIMIZER_I  : 25952,
    LARGE_POWERGRID_SUBROUTINE_MAXIMIZER_I_BLUEPRINT  : 25953,
    LARGE_POWERGRID_SUBROUTINE_MAXIMIZER_II  : 26372,
    LARGE_PRECURSOR_WEAPON  : 47872,
    LARGE_PROCESSOR_OVERCLOCKING_UNIT_I  : 4397,
    LARGE_PROCESSOR_OVERCLOCKING_UNIT_I_BLUEPRINT  : 4398,
    LARGE_PROCESSOR_OVERCLOCKING_UNIT_II  : 4401,
    LARGE_PROJECTILE_AMBIT_EXTENSION_I  : 26038,
    LARGE_PROJECTILE_AMBIT_EXTENSION_I_BLUEPRINT  : 26039,
    LARGE_PROJECTILE_AMBIT_EXTENSION_II  : 26428,
    LARGE_PROJECTILE_BURST_AERATOR_I  : 26046,
    LARGE_PROJECTILE_BURST_AERATOR_I_BLUEPRINT  : 26047,
    LARGE_PROJECTILE_BURST_AERATOR_II  : 26430,
    LARGE_PROJECTILE_COLLISION_ACCELERATOR_I  : 26048,
    LARGE_PROJECTILE_COLLISION_ACCELERATOR_I_BLUEPRINT  : 26049,
    LARGE_PROJECTILE_COLLISION_ACCELERATOR_II  : 26424,
    LARGE_PROJECTILE_LOCUS_COORDINATOR_I  : 26040,
    LARGE_PROJECTILE_LOCUS_COORDINATOR_I_BLUEPRINT  : 26041,
    LARGE_PROJECTILE_LOCUS_COORDINATOR_II  : 26432,
    LARGE_PROJECTILE_METASTASIS_ADJUSTER_I  : 26042,
    LARGE_PROJECTILE_METASTASIS_ADJUSTER_I_BLUEPRINT  : 26043,
    LARGE_PROJECTILE_METASTASIS_ADJUSTER_II  : 26434,
    LARGE_PROJECTILE_TURRET  : 3308,
    LARGE_PROTON_SMARTBOMB_I  : 3977,
    LARGE_PROTON_SMARTBOMB_I_BLUEPRINT  : 3978,
    LARGE_PROTON_SMARTBOMB_II  : 3979,
    LARGE_PULSE_LASER_BATTERY  : 17406,
    LARGE_PULSE_LASER_BATTERY_BLUEPRINT  : 2829,
    LARGE_PULSE_LASER_SPECIALIZATION  : 12215,
    LARGE_RADIATIVE_SCOPED_REMOTE_CAPACITOR_TRANSMITTER  : 16481,
    LARGE_RAILGUN_BATTERY  : 16692,
    LARGE_RAILGUN_BATTERY_BLUEPRINT  : 2804,
    LARGE_RAILGUN_SPECIALIZATION  : 12207,
    LARGE_REMOTE_ARMOR_REPAIRER_I  : 11359,
    LARGE_REMOTE_ARMOR_REPAIRER_I_BLUEPRINT  : 11360,
    LARGE_REMOTE_ARMOR_REPAIRER_II  : 26914,
    LARGE_REMOTE_CAPACITOR_TRANSMITTER_I  : 12225,
    LARGE_REMOTE_CAPACITOR_TRANSMITTER_I_BLUEPRINT  : 12226,
    LARGE_REMOTE_CAPACITOR_TRANSMITTER_II  : 12102,
    LARGE_REMOTE_HULL_REPAIRER_I  : 27904,
    LARGE_REMOTE_HULL_REPAIRER_I_BLUEPRINT  : 27905,
    LARGE_REMOTE_HULL_REPAIRER_II  : 3986,
    LARGE_REMOTE_REPAIR_AUGMENTOR_I  : 25900,
    LARGE_REMOTE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 25901,
    LARGE_REMOTE_REPAIR_AUGMENTOR_II  : 26298,
    LARGE_REMOTE_SHIELD_BOOSTER_I  : 3606,
    LARGE_REMOTE_SHIELD_BOOSTER_I_BLUEPRINT  : 3607,
    LARGE_REMOTE_SHIELD_BOOSTER_II  : 3608,
    LARGE_ROCKET_FUEL_CACHE_PARTITION_I  : 26022,
    LARGE_ROCKET_FUEL_CACHE_PARTITION_I_BLUEPRINT  : 26023,
    LARGE_ROCKET_FUEL_CACHE_PARTITION_II  : 26418,
    LARGE_RUDIMENTARY_CONCUSSION_BOMB_I  : 9668,
    LARGE_S95A_SCOPED_REMOTE_SHIELD_BOOSTER  : 8641,
    LARGE_SALVAGE_TACKLE_I  : 25902,
    LARGE_SALVAGE_TACKLE_I_BLUEPRINT  : 25903,
    LARGE_SALVAGE_TACKLE_II  : 26300,
    LARGE_SCOPED_VORTON_PROJECTOR  : 54752,
    LARGE_SECURE_CONTAINER  : 3465,
    LARGE_SECURE_CONTAINER_BLUEPRINT  : 32865,
    LARGE_SEMICONDUCTOR_MEMORY_CELL_I  : 25954,
    LARGE_SEMICONDUCTOR_MEMORY_CELL_I_BLUEPRINT  : 25955,
    LARGE_SEMICONDUCTOR_MEMORY_CELL_II  : 26376,
    LARGE_SENTRY_DAMAGE_AUGMENTOR_I  : 25920,
    LARGE_SENTRY_DAMAGE_AUGMENTOR_I_BLUEPRINT  : 25921,
    LARGE_SENTRY_DAMAGE_AUGMENTOR_II  : 26338,
    LARGE_SHERIFF_SHIELD_EXTENDER  : 20631,
    LARGE_SHIELD_BOOSTER_I  : 10838,
    LARGE_SHIELD_BOOSTER_I_BLUEPRINT  : 10839,
    LARGE_SHIELD_BOOSTER_II  : 10858,
    LARGE_SHIELD_EXTENDER_I  : 3839,
    LARGE_SHIELD_EXTENDER_I_BLUEPRINT  : 3840,
    LARGE_SHIELD_EXTENDER_II  : 3841,
    LARGE_SHIP_ASSEMBLY_ARRAY  : 29613,
    LARGE_SIGNAL_DISRUPTION_AMPLIFIER_I  : 25928,
    LARGE_SIGNAL_DISRUPTION_AMPLIFIER_I_BLUEPRINT  : 25929,
    LARGE_SIGNAL_DISRUPTION_AMPLIFIER_II  : 26344,
    LARGE_SIGNAL_FOCUSING_KIT_I  : 26104,
    LARGE_SIGNAL_FOCUSING_KIT_I_BLUEPRINT  : 26105,
    LARGE_SIGNAL_FOCUSING_KIT_II  : 26362,
    LARGE_SKILL_INJECTOR  : 40520,
    LARGE_SOLACE_SCOPED_REMOTE_ARMOR_REPAIRER  : 16455,
    LARGE_STANDARD_CONTAINER  : 3296,
    LARGE_STANDARD_CONTAINER_BLUEPRINT  : 32861,
    LARGE_STASIS_DRONE_AUGMENTOR_I  : 25924,
    LARGE_STASIS_DRONE_AUGMENTOR_I_BLUEPRINT  : 25925,
    LARGE_STASIS_DRONE_AUGMENTOR_II  : 26340,
    LARGE_TARGETING_SYSTEM_SUBCONTROLLER_I  : 26100,
    LARGE_TARGETING_SYSTEM_SUBCONTROLLER_I_BLUEPRINT  : 26101,
    LARGE_TARGETING_SYSTEM_SUBCONTROLLER_II  : 26364,
    LARGE_TARGETING_SYSTEMS_STABILIZER_I  : 26096,
    LARGE_TARGETING_SYSTEMS_STABILIZER_I_BLUEPRINT  : 26097,
    LARGE_TARGETING_SYSTEMS_STABILIZER_II  : 26366,
    LARGE_THERMAL_ARMOR_REINFORCER_I  : 25892,
    LARGE_THERMAL_ARMOR_REINFORCER_I_BLUEPRINT  : 25893,
    LARGE_THERMAL_ARMOR_REINFORCER_II  : 26292,
    LARGE_THERMAL_SHIELD_REINFORCER_I  : 26082,
    LARGE_THERMAL_SHIELD_REINFORCER_I_BLUEPRINT  : 26083,
    LARGE_THERMAL_SHIELD_REINFORCER_II  : 26442,
    LARGE_TRACKING_DIAGNOSTIC_SUBROUTINES_I  : 26112,
    LARGE_TRACKING_DIAGNOSTIC_SUBROUTINES_I_BLUEPRINT  : 26113,
    LARGE_TRACKING_DIAGNOSTIC_SUBROUTINES_II  : 26356,
    LARGE_TRANSVERSE_BULKHEAD_I  : 33898,
    LARGE_TRANSVERSE_BULKHEAD_I_BLUEPRINT  : 33899,
    LARGE_TRANSVERSE_BULKHEAD_II  : 33900,
    LARGE_TRIMARK_ARMOR_PUMP_I  : 25894,
    LARGE_TRIMARK_ARMOR_PUMP_I_BLUEPRINT  : 25895,
    LARGE_TRIMARK_ARMOR_PUMP_II  : 26302,
    LARGE_VEHEMENCE_SHOCKWAVE_CHARGE  : 9678,
    LARGE_VORTON_PROJECTOR  : 54826,
    LARGE_VORTON_PROJECTOR_I  : 54750,
    LARGE_VORTON_PROJECTOR_II  : 54753,
    LARGE_VORTON_SPECIALIZATION  : 54829,
    LARGE_WARHEAD_CALEFACTION_CATALYST_I  : 26030,
    LARGE_WARHEAD_CALEFACTION_CATALYST_I_BLUEPRINT  : 26031,
    LARGE_WARHEAD_CALEFACTION_CATALYST_II  : 26420,
    LARGE_WARHEAD_FLARE_CATALYST_I  : 26028,
    LARGE_WARHEAD_FLARE_CATALYST_I_BLUEPRINT  : 26029,
    LARGE_WARHEAD_FLARE_CATALYST_II  : 26412,
    LARGE_WARHEAD_RIGOR_CATALYST_I  : 26020,
    LARGE_WARHEAD_RIGOR_CATALYST_I_BLUEPRINT  : 26021,
    LARGE_WARHEAD_RIGOR_CATALYST_II  : 26414,
    LARGE_WARP_CORE_OPTIMIZER_I  : 26066,
    LARGE_WARP_CORE_OPTIMIZER_I_BLUEPRINT  : 26067,
    LARGE_WARP_CORE_OPTIMIZER_II  : 26320,
    LARGE_YF_12A_SMARTBOMB  : 9808,
    LASER_FOCUSING_CRYSTALS  : 11689,
    LASER_FOCUSING_CRYSTALS_BLUEPRINT  : 17348,
    LASER_PHYSICS  : 11447,
    LATTICE_LOCKED_DEKAISOGEN  : 52314,
    LAUNCHER_RIGGING  : 26260,
    LAVA_COMMAND_CENTER  : 2549,
    LAVISH_CHROMITE  : 46302,
    LAVISH_OTAVITE  : 46296,
    LAVISH_SPERRYLITE  : 46298,
    LAVISH_VANADINITE  : 46300,
    LAYERED_COATING_I  : 1274,
    LAYERED_COATING_I_BLUEPRINT  : 1275,
    LAYERED_COATING_II  : 1276,
    LAYERED_ENERGIZED_MEMBRANE_I  : 11237,
    LAYERED_ENERGIZED_MEMBRANE_I_BLUEPRINT  : 11238,
    LAYERED_ENERGIZED_MEMBRANE_II  : 11239,
    LAZARUSS_TAG  : 17847,
    LEAD_CHARGE_L  : 234,
    LEAD_CHARGE_L_BLUEPRINT  : 1149,
    LEAD_CHARGE_M  : 226,
    LEAD_CHARGE_M_BLUEPRINT  : 1141,
    LEAD_CHARGE_S  : 218,
    LEAD_CHARGE_S_BLUEPRINT  : 1133,
    LEAD_CHARGE_XL  : 17654,
    LEAD_CHARGE_XL_BLUEPRINT  : 17655,
    LEADERSHIP  : 3348,
    LEGENDS_MEDAL  : 16714,
    LEGION  : 29986,
    LEGION_BIOSECURITY_RESPONDERS_SKIN  : 55330,
    LEGION_CORE___AUGMENTED_ANTIMATTER_REACTOR  : 45623,
    LEGION_CORE___DISSOLUTION_SEQUENCER  : 45622,
    LEGION_CORE___ENERGY_PARASITIC_COMPLEX  : 45624,
    LEGION_DEFENSIVE___AUGMENTED_PLATING  : 45587,
    LEGION_DEFENSIVE___COVERT_RECONFIGURATION  : 45586,
    LEGION_DEFENSIVE___NANOBOT_INJECTOR  : 45588,
    LEGION_EXOPLANETS_HUNTER_SKIN  : 45784,
    LEGION_GLACIAL_DRIFT_SKIN  : 46793,
    LEGION_GRAVIMETRIC_ECM  : 28735,
    LEGION_LADAR_ECM  : 28733,
    LEGION_MAGNETOMETRIC_ECM  : 28729,
    LEGION_MULTISPECTRAL_ECM  : 28731,
    LEGION_OASIS_OF_FAITH_SKIN  : 53626,
    LEGION_OFFENSIVE___ASSAULT_OPTIMIZATION  : 45599,
    LEGION_OFFENSIVE___LIQUID_CRYSTAL_MAGNIFIERS  : 45598,
    LEGION_OFFENSIVE___SUPPORT_PROCESSOR  : 45600,
    LEGION_PROPULSION___INTERCALATED_NANOFIBERS  : 45611,
    LEGION_PROPULSION___INTERDICTION_NULLIFIER  : 45610,
    LEGION_PROPULSION___WAKE_LIMITER  : 45612,
    LEGION_RADAR_ECM  : 28737,
    LEGION_SUNBURST_SKIN  : 46022,
    LEOPARD  : 33513,
    LEOPARD_BRUTOR_SKIN  : 59867,
    LEOPARD_KRUSUAL_SKIN  : 59827,
    LEOPARD_LIBERATION_GAMES_SKIN  : 59875,
    LEOPARD_NEFANTAR_SKIN  : 59819,
    LEOPARD_REPUBLIC_FLEET_SKIN  : 59818,
    LEOPARD_SEBIESTOR_SKIN  : 59835,
    LEOPARD_STARKMANIR_SKIN  : 59859,
    LEOPARD_THUKKER_TRIBE_SKIN  : 59843,
    LEOPARD_VHEROKIOR_SKIN  : 59852,
    LESHAK  : 47271,
    LESHAK_BLACKFIRE_STEEL_SKIN  : 49928,
    LESHAK_EDENS_HUNTERS_SKIN  : 54907,
    LESHAK_HADEAN_HORROR_SKIN  : 48655,
    LESHAK_KYBERNAUT_CLADE_SKIN  : 54916,
    LESHAK_METAMATERIA_EXOTICA_SKIN  : 50145,
    LESHAK_NETHERWORLD_STRIKER_SKIN  : 50156,
    LESHAK_RED_MEATGRINDER_SKIN  : 52323,
    LESHAK_SCOPE_SYNDICATION_YC122_SKIN  : 61182,
    LESHAK_SINGULARITY_STORM_SKIN  : 48651,
    LESHAK_TRIGLAVIAN_VICTORY_SKIN  : 48565,
    LEVIATHAN  : 3764,
    LEVIATHAN_BIOSECURITY_RESPONDERS_SKIN  : 55520,
    LEVIATHAN_BLUE_TIGER_SKIN  : 36744,
    LEVIATHAN_BLUEPRINT  : 3765,
    LEVIATHAN_CAPSULEER_ELITE_XV_SKIN  : 47641,
    LEVIATHAN_EROS_BLOSSOM_SKIN  : 50076,
    LEVIATHAN_EXOPLANETS_HUNTER_SKIN  : 45862,
    LEVIATHAN_GHOSTBIRD_SKIN  : 48205,
    LEVIATHAN_GLACIAL_DRIFT_SKIN  : 46866,
    LEVIATHAN_HEADHUNTER_SKIN  : 49425,
    LEVIATHAN_INTERSTELLAR_CONVERGENCE_SKIN  : 62225,
    LEVIATHAN_KIMOTORO_ESKEITAN_SKIN  : 52643,
    LEVIATHAN_MALAGRIN_MARSHLIGHT_SKIN  : 53012,
    LEVIATHAN_MATIGU_SEABEAST_SKIN  : 40620,
    LEVIATHAN_RAATA_SUNSET_SKIN  : 36672,
    LEVIATHAN_REDCLAW_SABLE_SKIN  : 56956,
    LEVIATHAN_STARLINE_MAJESTIC_SKIN  : 55726,
    LEVIATHAN_STEEL_CARDINAL_SKIN  : 46139,
    LEVIATHAN_UNION_DAY_EXECUTIVE_SKIN  : 71734,
    LEVIATHAN_WIYRKOMI_SKIN  : 36334,
    LEVIATHAN_YOIUL_STAR_SKIN  : 53427,
    LEVIATHAN_ZENTO_ISIDEKO_COMBINE_SKIN  : 53199,
    LFT_ENDURING_SENSOR_DAMPENER  : 5299,
    LIBERATION_GAMES_REWARD_TOKEN  : 59752,
    LIF  : 37606,
    LIF_BIOSECURITY_RESPONDERS_SKIN  : 55653,
    LIF_BLOODY_HANDS_SKIN  : 47178,
    LIF_BLUE_TIGER_SKIN  : 41686,
    LIF_BLUEPRINT  : 41585,
    LIF_CAPSULEER_ELITE_XV_SKIN  : 47646,
    LIF_EXOPLANETS_HUNTER_SKIN  : 46149,
    LIF_FRONTIER_SAFEGUARDER_SKIN  : 54437,
    LIF_GLACIAL_DRIFT_SKIN  : 47052,
    LIF_HAZARD_CONTROL_SKIN  : 56935,
    LIF_SNOWLINE_BLADERACER_SKIN  : 52902,
    LIF_TRONHADAR_INK_SKIN  : 41687,
    LIF_VALKLEAR_GLORY_SKIN  : 41685,
    LIFE_SUPPORT_BACKUP_UNIT  : 57486,
    LIFE_SUPPORT_BACKUP_UNIT_BLUEPRINT  : 57523,
    LIFEBLOOD_COLLECTORS_EDITION  : 49649,
    LIGATURE_INTEGRATED_ANALYZER  : 41533,
    LIGHT_ARMOR_MAINTENANCE_BOT_I  : 23711,
    LIGHT_ARMOR_MAINTENANCE_BOT_I_BLUEPRINT  : 23712,
    LIGHT_ARMOR_MAINTENANCE_BOT_II  : 28201,
    LIGHT_COMPACT_ENTROPIC_DISINTEGRATOR  : 47912,
    LIGHT_COMPACT_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 48469,
    LIGHT_DRONE_OPERATION  : 24241,
    LIGHT_ELECTRON_BLASTER_I  : 562,
    LIGHT_ELECTRON_BLASTER_I_BLUEPRINT  : 1113,
    LIGHT_ELECTRON_BLASTER_II  : 3162,
    LIGHT_ENTROPIC_DISINTEGRATOR_I  : 47272,
    LIGHT_ENTROPIC_DISINTEGRATOR_I_BLUEPRINT  : 47969,
    LIGHT_ENTROPIC_DISINTEGRATOR_II  : 47914,
    LIGHT_FIGHTERS  : 40572,
    LIGHT_HULL_MAINTENANCE_BOT_I  : 33706,
    LIGHT_HULL_MAINTENANCE_BOT_I_BLUEPRINT  : 33707,
    LIGHT_HULL_MAINTENANCE_BOT_II  : 33712,
    LIGHT_ION_BLASTER_I  : 563,
    LIGHT_ION_BLASTER_I_BLUEPRINT  : 1114,
    LIGHT_ION_BLASTER_II  : 3170,
    LIGHT_MISSILE_LAUNCHER_I  : 499,
    LIGHT_MISSILE_LAUNCHER_I_BLUEPRINT  : 786,
    LIGHT_MISSILE_LAUNCHER_II  : 2404,
    LIGHT_MISSILE_SPECIALIZATION  : 20210,
    LIGHT_MISSILES  : 3321,
    LIGHT_NEUTRON_BLASTER_I  : 564,
    LIGHT_NEUTRON_BLASTER_I_BLUEPRINT  : 1115,
    LIGHT_NEUTRON_BLASTER_II  : 3178,
    LIGHT_SCOPED_ENTROPIC_DISINTEGRATOR  : 47913,
    LIGHT_SCOPED_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 48470,
    LIGHT_SHIELD_MAINTENANCE_BOT_I  : 23719,
    LIGHT_SHIELD_MAINTENANCE_BOT_I_BLUEPRINT  : 23720,
    LIGHT_SHIELD_MAINTENANCE_BOT_II  : 28203,
    LIME_CYTOSEROCIN  : 25277,
    LIME_MYKOSEROCIN  : 28698,
    LIMITED_CYBERNETIC_SUBPROCESSOR  : 13287,
    LIMITED_CYBERNETIC_SUBPROCESSOR___BETA  : 14298,
    LIMITED_ELECTRON_BLASTER_CANNON_I  : 7745,
    LIMITED_ELECTRON_BLASTER_I  : 7621,
    LIMITED_EXPANDED_ARCHIVER_CARGO  : 21493,
    LIMITED_HYPERSPATIAL_ACCELERATOR  : 33981,
    LIMITED_ION_BLASTER_I  : 7665,
    LIMITED_JUMP_DRIVE_ECONOMIZER  : 34122,
    LIMITED_LIGHT_ELECTRON_BLASTER_I  : 7489,
    LIMITED_LIGHT_ION_BLASTER_I  : 7537,
    LIMITED_LIGHT_NEUTRON_BLASTER_I  : 7581,
    LIMITED_LIMOS_HEAVY_ASSAULT_MISSILE_LAUNCHER_I  : 25711,
    LIMITED_LIMOS_RAPID_LIGHT_MISSILE_LAUNCHER  : 8025,
    LIMITED_LIMOS_TORPEDO_LAUNCHER  : 8115,
    LIMITED_MEGA_ION_BLASTER_I  : 7829,
    LIMITED_MEGA_NEUTRON_BLASTER_I  : 7785,
    LIMITED_MEMORY_AUGMENTATION  : 13284,
    LIMITED_MEMORY_AUGMENTATION___BETA  : 14297,
    LIMITED_NEURAL_BOOST  : 13285,
    LIMITED_NEURAL_BOOST___BETA  : 14296,
    LIMITED_NEUTRON_BLASTER_I  : 7705,
    LIMITED_OCULAR_FILTER  : 13283,
    LIMITED_OCULAR_FILTER___BETA  : 14295,
    LIMITED_PRECISE_ION_SIEGE_BLASTER  : 41129,
    LIMITED_PRECISE_ION_SIEGE_BLASTER_BLUEPRINT  : 41135,
    LIMITED_PRECISE_TRIPLE_NEUTRON_BLASTER_CANNON  : 41078,
    LIMITED_PRECISE_TRIPLE_NEUTRON_BLASTER_CANNON_BLUEPRINT  : 41089,
    LIMITED_SOCIAL_ADAPTATION_CHIP  : 13286,
    LIMITED_SOCIAL_ADAPTATION_CHIP___BETA  : 14299,
    LIMOS_CRUISE_LAUNCHER_I  : 16515,
    LIMOS_RAPID_HEAVY_MISSILE_LAUNCHER_I  : 33441,
    LIMOS_ROCKET_LAUNCHER_I  : 16523,
    LINEAR_SHIELD_EMITTER  : 11557,
    LINEAR_SHIELD_EMITTER_BLUEPRINT  : 17359,
    LINKED_ENDURING_REMOTE_SENSOR_BOOSTER  : 5282,
    LIQUID_OZONE  : 16273,
    LITTLE_HELPER_FEMALE  : 33027,
    LITTLE_HELPER_MALE  : 33028,
    LIVESTOCK  : 3725,
    LIVESTOCK_LIBERATOR_DISPLAY  : 56971,
    LM_COMPOSITE_MOLECULAR_CONDENSER  : 57445,
    LOCKBREAKER_BOMB  : 27922,
    LOCKBREAKER_BOMB_BLUEPRINT  : 27923,
    LOCUST_I  : 40359,
    LOCUST_I_BLUEPRINT  : 41379,
    LOCUST_II  : 40554,
    LOG_FRAGMENT_1_GALA_YC121  : 48950,
    LOG_FRAGMENT_2_GALA_YC121  : 48951,
    LOG_FRAGMENT_3_GALA_YC121  : 48952,
    LOG_FRAGMENT_4_GALA_YC121  : 48953,
    LOG_FRAGMENT_5_GALA_YC121  : 48954,
    LOG_FRAGMENT_6_GALA_YC121  : 50040,
    LOG_FRAGMENT_7_GALA_YC121  : 50041,
    LOG_FRAGMENT_8_GALA_YC121  : 50042,
    LOGGERHEAD  : 45645,
    LOGGERHEAD_GLACIAL_DRIFT_SKIN  : 46877,
    LOGIC_CIRCUIT  : 25619,
    LOGISTICS_CRUISERS  : 12096,
    LOGISTICS_FRIGATES  : 40328,
    LOKI  : 29990,
    LOKI_BATTLESCAR_SKIN  : 46026,
    LOKI_BIOSECURITY_RESPONDERS_SKIN  : 55652,
    LOKI_CORE___AUGMENTED_NUCLEAR_REACTOR  : 45632,
    LOKI_CORE___DISSOLUTION_SEQUENCER  : 45631,
    LOKI_CORE___IMMOBILITY_DRIVERS  : 45633,
    LOKI_DAWN_OF_LIBERATION_SKIN  : 48511,
    LOKI_DEFENSIVE___ADAPTIVE_DEFENSE_NODE  : 45597,
    LOKI_DEFENSIVE___AUGMENTED_DURABILITY  : 45596,
    LOKI_DEFENSIVE___COVERT_RECONFIGURATION  : 45595,
    LOKI_EXOPLANETS_HUNTER_SKIN  : 45964,
    LOKI_GLACIAL_DRIFT_SKIN  : 46901,
    LOKI_HAZARD_CONTROL_SKIN  : 56946,
    LOKI_IN_RUST_WE_TRUST_SKIN  : 52428,
    LOKI_OFFENSIVE___LAUNCHER_EFFICIENCY_CONFIGURATION  : 45608,
    LOKI_OFFENSIVE___PROJECTILE_SCOPING_ARRAY  : 45607,
    LOKI_OFFENSIVE___SUPPORT_PROCESSOR  : 45609,
    LOKI_PROPULSION___INTERCALATED_NANOFIBERS  : 45620,
    LOKI_PROPULSION___INTERDICTION_NULLIFIER  : 45619,
    LOKI_PROPULSION___WAKE_LIMITER  : 45621,
    LOKI_SNOWLINE_BLADERACER_SKIN  : 52875,
    LONG_DISTANCE_JAMMING  : 19759,
    LONG_LIMB_ROES  : 3777,
    LONG_RANGE_TARGETING  : 3428,
    LONGBOW_SMALL_FOCUSED_PULSE_LASER_I  : 23836,
    LOOKING_GLASS_MONOCLE_INTERFACE_LEFT_GOLD  : 4050,
    LOOKING_GLASS_MONOCLE_INTERFACE_LEFT_GRAY  : 4052,
    LOOKING_GLASS_MONOCLE_INTERFACE_RIGHT_GOLD  : 4009,
    LOOKING_GLASS_MONOCLE_INTERFACE_RIGHT_SILVER  : 4042,
    LOPARITE  : 45512,
    LORENTZ_FLUID  : 25610,
    LOST_REMINDER_TO_PAY_SOV_BILL  : 33217,
    LOVE_MEDIUM_REMOTE_ARMOR_REPAIRER  : 22949,
    LOW_GRADE_AMULET_ALPHA  : 33953,
    LOW_GRADE_AMULET_BETA  : 33954,
    LOW_GRADE_AMULET_DELTA  : 33955,
    LOW_GRADE_AMULET_EPSILON  : 33956,
    LOW_GRADE_AMULET_GAMMA  : 33957,
    LOW_GRADE_AMULET_OMEGA  : 33958,
    LOW_GRADE_ASKLEPIAN_ALPHA  : 42145,
    LOW_GRADE_ASKLEPIAN_BETA  : 42146,
    LOW_GRADE_ASKLEPIAN_DELTA  : 42200,
    LOW_GRADE_ASKLEPIAN_EPSILON  : 42201,
    LOW_GRADE_ASKLEPIAN_GAMMA  : 42202,
    LOW_GRADE_ASKLEPIAN_OMEGA  : 42203,
    LOW_GRADE_CENTURION_ALPHA  : 33917,
    LOW_GRADE_CENTURION_BETA  : 33918,
    LOW_GRADE_CENTURION_DELTA  : 33919,
    LOW_GRADE_CENTURION_EPSILON  : 33920,
    LOW_GRADE_CENTURION_GAMMA  : 33921,
    LOW_GRADE_CENTURION_OMEGA  : 33922,
    LOW_GRADE_CRYSTAL_ALPHA  : 33923,
    LOW_GRADE_CRYSTAL_BETA  : 33924,
    LOW_GRADE_CRYSTAL_DELTA  : 33925,
    LOW_GRADE_CRYSTAL_EPSILON  : 33926,
    LOW_GRADE_CRYSTAL_GAMMA  : 33927,
    LOW_GRADE_CRYSTAL_OMEGA  : 33928,
    LOW_GRADE_EDGE_ALPHA  : 33929,
    LOW_GRADE_EDGE_BETA  : 33930,
    LOW_GRADE_EDGE_DELTA  : 33931,
    LOW_GRADE_EDGE_EPSILON  : 33932,
    LOW_GRADE_EDGE_GAMMA  : 33933,
    LOW_GRADE_EDGE_OMEGA  : 33934,
    LOW_GRADE_GRAIL_ALPHA  : 32101,
    LOW_GRADE_GRAIL_BETA  : 32102,
    LOW_GRADE_GRAIL_DELTA  : 32103,
    LOW_GRADE_GRAIL_EPSILON  : 32104,
    LOW_GRADE_GRAIL_GAMMA  : 32105,
    LOW_GRADE_GRAIL_OMEGA  : 32122,
    LOW_GRADE_HALO_ALPHA  : 33935,
    LOW_GRADE_HALO_BETA  : 33936,
    LOW_GRADE_HALO_DELTA  : 33937,
    LOW_GRADE_HALO_EPSILON  : 33938,
    LOW_GRADE_HALO_GAMMA  : 33939,
    LOW_GRADE_HALO_OMEGA  : 33940,
    LOW_GRADE_HARVEST_ALPHA  : 33941,
    LOW_GRADE_HARVEST_BETA  : 33942,
    LOW_GRADE_HARVEST_DELTA  : 33943,
    LOW_GRADE_HARVEST_EPSILON  : 33944,
    LOW_GRADE_HARVEST_GAMMA  : 33945,
    LOW_GRADE_HARVEST_OMEGA  : 33946,
    LOW_GRADE_HYDRA_ALPHA  : 54404,
    LOW_GRADE_HYDRA_BETA  : 54405,
    LOW_GRADE_HYDRA_DELTA  : 54407,
    LOW_GRADE_HYDRA_EPSILON  : 54408,
    LOW_GRADE_HYDRA_GAMMA  : 54406,
    LOW_GRADE_HYDRA_OMEGA  : 54409,
    LOW_GRADE_JACKAL_ALPHA  : 32117,
    LOW_GRADE_JACKAL_BETA  : 32118,
    LOW_GRADE_JACKAL_DELTA  : 32119,
    LOW_GRADE_JACKAL_EPSILON  : 32120,
    LOW_GRADE_JACKAL_GAMMA  : 32121,
    LOW_GRADE_JACKAL_OMEGA  : 32123,
    LOW_GRADE_MIMESIS_ALPHA  : 52674,
    LOW_GRADE_MIMESIS_BETA  : 52679,
    LOW_GRADE_MIMESIS_DELTA  : 52681,
    LOW_GRADE_MIMESIS_EPSILON  : 52682,
    LOW_GRADE_MIMESIS_GAMMA  : 52680,
    LOW_GRADE_MIMESIS_OMEGA  : 52683,
    LOW_GRADE_NIRVANA_ALPHA  : 53853,
    LOW_GRADE_NIRVANA_BETA  : 53854,
    LOW_GRADE_NIRVANA_DELTA  : 53856,
    LOW_GRADE_NIRVANA_EPSILON  : 53857,
    LOW_GRADE_NIRVANA_GAMMA  : 53855,
    LOW_GRADE_NIRVANA_OMEGA  : 53839,
    LOW_GRADE_NOMAD_ALPHA  : 33947,
    LOW_GRADE_NOMAD_BETA  : 33948,
    LOW_GRADE_NOMAD_DELTA  : 33949,
    LOW_GRADE_NOMAD_EPSILON  : 33950,
    LOW_GRADE_NOMAD_GAMMA  : 33951,
    LOW_GRADE_NOMAD_OMEGA  : 33952,
    LOW_GRADE_RAPTURE_ALPHA  : 57110,
    LOW_GRADE_RAPTURE_BETA  : 57111,
    LOW_GRADE_RAPTURE_DELTA  : 57113,
    LOW_GRADE_RAPTURE_EPSILON  : 57114,
    LOW_GRADE_RAPTURE_GAMMA  : 57112,
    LOW_GRADE_RAPTURE_OMEGA  : 57116,
    LOW_GRADE_SAVIOR_ALPHA  : 53902,
    LOW_GRADE_SAVIOR_BETA  : 53903,
    LOW_GRADE_SAVIOR_DELTA  : 53905,
    LOW_GRADE_SAVIOR_EPSILON  : 53906,
    LOW_GRADE_SAVIOR_GAMMA  : 53904,
    LOW_GRADE_SAVIOR_OMEGA  : 53907,
    LOW_GRADE_SNAKE_ALPHA  : 33959,
    LOW_GRADE_SNAKE_BETA  : 33960,
    LOW_GRADE_SNAKE_DELTA  : 33961,
    LOW_GRADE_SNAKE_EPSILON  : 33962,
    LOW_GRADE_SNAKE_GAMMA  : 33963,
    LOW_GRADE_SNAKE_OMEGA  : 33964,
    LOW_GRADE_SPUR_ALPHA  : 32107,
    LOW_GRADE_SPUR_BETA  : 32108,
    LOW_GRADE_SPUR_DELTA  : 32109,
    LOW_GRADE_SPUR_EPSILON  : 32110,
    LOW_GRADE_SPUR_GAMMA  : 32111,
    LOW_GRADE_SPUR_OMEGA  : 32124,
    LOW_GRADE_TALISMAN_ALPHA  : 33965,
    LOW_GRADE_TALISMAN_BETA  : 33966,
    LOW_GRADE_TALISMAN_DELTA  : 33967,
    LOW_GRADE_TALISMAN_EPSILON  : 33968,
    LOW_GRADE_TALISMAN_GAMMA  : 33969,
    LOW_GRADE_TALISMAN_OMEGA  : 33970,
    LOW_GRADE_TALON_ALPHA  : 32112,
    LOW_GRADE_TALON_BETA  : 32113,
    LOW_GRADE_TALON_DELTA  : 32114,
    LOW_GRADE_TALON_EPSILON  : 32115,
    LOW_GRADE_TALON_GAMMA  : 32116,
    LOW_GRADE_TALON_OMEGA  : 32125,
    LOW_GRADE_VIRTUE_ALPHA  : 33971,
    LOW_GRADE_VIRTUE_BETA  : 33972,
    LOW_GRADE_VIRTUE_DELTA  : 33973,
    LOW_GRADE_VIRTUE_EPSILON  : 33974,
    LOW_GRADE_VIRTUE_GAMMA  : 33975,
    LOW_GRADE_VIRTUE_OMEGA  : 33976,
    LUCENT_COMPOUND  : 11738,
    LUMINOUS_KERNITE  : 17452,
    LUMP_OF_CARBON  : 47047,
    LUSTERING_ALLOY  : 11736,
    LUSTROUS_HEDBERGITE  : 46680,
    M_36_ENDURING_WARP_DISRUPTION_FIELD_GENERATOR  : 37611,
    M51_BENEFACTOR_COMPACT_SHIELD_RECHARGER  : 6485,
    MACE_DUAL_LIGHT_BEAM_LASER_I  : 23834,
    MACHARIEL  : 17738,
    MACHARIEL_AMARANTHINE_MANTLE_SKIN  : 50035,
    MACHARIEL_ANGELS_HEX_SKIN  : 45668,
    MACHARIEL_DEATHGLOW_HUNTERS_SKIN  : 49434,
    MACHARIEL_EROS_BLOSSOM_SKIN  : 47295,
    MACHARIEL_GLACIAL_DRIFT_SKIN  : 46999,
    MACHARIEL_HEARTSURGE_SKIN  : 53951,
    MACHARIEL_MALAGRIN_MARSHLIGHT_SKIN  : 56603,
    MACHARIEL_RED_STARGAZER_SKIN  : 75907,
    MACHARIEL_SERPENTIS_SKIN  : 57334,
    MACHARIEL_SPIRIT_SKIN  : 44949,
    MACHARIEL_VERSUS_BLUEFORCE_SKIN  : 62647,
    MACHARIEL_VERSUS_REDFORCE_SKIN  : 62641,
    MACKINAW  : 22548,
    MACKINAW_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43756,
    MACKINAW_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43809,
    MACKINAW_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43822,
    MACKINAW_BLUEPRINT  : 22549,
    MACKINAW_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43769,
    MACKINAW_CLAIM_FOREMAN_SKIN  : 44072,
    MACKINAW_DIGMASTER_BLAZON_SKIN  : 42648,
    MACKINAW_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43783,
    MACKINAW_GLACIAL_DRIFT_SKIN  : 46988,
    MACKINAW_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43835,
    MACKINAW_LODESTRIKE_SKIN  : 46739,
    MACKINAW_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43796,
    MACKINAW_MORPHITE_SHINE_SKIN  : 42621,
    MACKINAW_NOCX_RUSH_SKIN  : 34759,
    MACKINAW_PAYDIRT_PROSPECTOR_SKIN  : 42678,
    MACKINAW_RADIOACTIVES_RECLAMATION_SKIN  : 60951,
    MACKINAW_ROCKBREAKER_PIONEERS_SKIN  : 56616,
    MACKINAW_ROSADA_DAWN_SKIN  : 43670,
    MACKINAW_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43848,
    MAELSTROM  : 24694,
    MAELSTROM_AURORA_UNIVERSALIS_SKIN  : 57025,
    MAELSTROM_BIOSECURITY_RESPONDERS_SKIN  : 55654,
    MAELSTROM_BLUE_TIGER_SKIN  : 36829,
    MAELSTROM_BLUEPRINT  : 24695,
    MAELSTROM_CAPSULEER_DAY_XVII_SKIN  : 54800,
    MAELSTROM_CAPSULEER_ELITE_XIX_SKIN  : 63693,
    MAELSTROM_CHAINBREAKER_KHUMAAK_SKIN  : 64598,
    MAELSTROM_DAWN_OF_LIBERATION_SKIN  : 48514,
    MAELSTROM_EXOPLANETS_HUNTER_SKIN  : 45973,
    MAELSTROM_FIREWALL_BREACH_SKIN  : 48530,
    MAELSTROM_GLACIAL_DRIFT_SKIN  : 46910,
    MAELSTROM_HAZARD_CONTROL_SKIN  : 56928,
    MAELSTROM_KRUSUAL_SKIN  : 34641,
    MAELSTROM_NEFANTAR_SKIN  : 34642,
    MAELSTROM_SNOWLINE_BLADERACER_SKIN  : 52884,
    MAELSTROM_SPIRIT_SKIN  : 57343,
    MAELSTROM_TRONHADAR_INK_SKIN  : 40500,
    MAELSTROM_VALKLEAR_GLORY_SKIN  : 36782,
    MAFIA_KINETIC_ENERGIZED_MEMBRANE  : 22883,
    MAGIC_CRYSTAL_BALL  : 28840,
    MAGMA_MERCOXIT  : 17869,
    MAGNATE  : 29248,
    MAGNATE_ARDISHAPUR_SKIN  : 34725,
    MAGNATE_ASPIRANT_EXPLORER_SKIN  : 60230,
    MAGNATE_BIOSECURITY_RESPONDERS_SKIN  : 55303,
    MAGNATE_BLUEPRINT  : 29249,
    MAGNATE_COLD_IRON_SKIN  : 42749,
    MAGNATE_EXOPLANETS_HUNTER_SKIN  : 45756,
    MAGNATE_GLACIAL_DRIFT_SKIN  : 44137,
    MAGNATE_HEADHUNTER_SKIN  : 46707,
    MAGNATE_IRONBLOOD_SKIN  : 43485,
    MAGNATE_NAVY_ISSUE  : 72907,
    MAGNATE_NAVY_ISSUE_IRONBLOOD_SKIN  : 73468,
    MAGNATE_NAVY_ISSUE_ORDER_OF_TETRIMON_SKIN  : 73459,
    MAGNATE_NAVY_ISSUE_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 73838,
    MAGNATE_OASIS_OF_FAITH_SKIN  : 53598,
    MAGNATE_PURITY_OF_THE_THRONE_SKIN  : 42547,
    MAGNATE_RAATA_SUNSET_SKIN  : 37487,
    MAGNATE_SARUM_SKIN  : 34726,
    MAGNATE_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 63028,
    MAGNATE_SERENE_HAO_SKIN  : 49151,
    MAGNATE_TASH_MURKON_SKIN  : 34727,
    MAGNATE_VERSUS_BLUEFORCE_SKIN  : 62010,
    MAGNATE_WARP_CONVERGENCE_SKIN  : 61861,
    MAGNETIC_FIELD_STABILIZER_I  : 9944,
    MAGNETIC_FIELD_STABILIZER_I_BLUEPRINT  : 9945,
    MAGNETIC_FIELD_STABILIZER_II  : 10190,
    MAGNETOMETRIC_BACKUP_ARRAY_I_BLUEPRINT  : 11828,
    MAGNETOMETRIC_ECM_I  : 1948,
    MAGNETOMETRIC_ECM_I_BLUEPRINT  : 11628,
    MAGNETOMETRIC_ECM_II  : 2563,
    MAGNETOMETRIC_FTL_INTERLINK_COMMUNICATOR  : 57476,
    MAGNETOMETRIC_FTL_INTERLINK_COMMUNICATOR_BLUEPRINT  : 57513,
    MAGNETOMETRIC_SENSOR_CLUSTER  : 11535,
    MAGNETOMETRIC_SENSOR_CLUSTER_BLUEPRINT  : 17345,
    MAGNETOMETRIC_SENSOR_COMPENSATION  : 32999,
    MAGPIE_MOBILE_TRACTOR_UNIT  : 33702,
    MAGPULSE_THRUSTER  : 11533,
    MAGPULSE_THRUSTER_BLUEPRINT  : 17356,
    MAGUS  : 37483,
    MAGUS_BIOSECURITY_RESPONDERS_SKIN  : 55620,
    MAGUS_EXOPLANETS_HUNTER_SKIN  : 45889,
    MAGUS_GLACIAL_DRIFT_SKIN  : 46939,
    MAGUS_GLITTERING_DREAM_SKIN  : 47527,
    MAGUS_INNER_ZONE_VANGUARD_SKIN  : 52724,
    MAGUS_KOPIS_EDGE_SKIN  : 46401,
    MAGUS_SERPENTIS_SKIN  : 42163,
    MAGUS_SPIRIT_SKIN  : 44907,
    MAGUS_VALIMOR_LEGACY_SKIN  : 40324,
    MAINFRAME_BIT  : 23167,
    MAKURS_MODIFIED_CAPACITOR_POWER_RELAY  : 15437,
    MAKURS_MODIFIED_WARP_DISRUPTOR  : 15431,
    MAKURS_MODIFIED_WARP_SCRAMBLER  : 15433,
    MALACHITE_CYTOSEROCIN  : 25276,
    MALACHITE_MYKOSEROCIN  : 28699,
    MALEDICTION  : 11186,
    MALEDICTION_BIOSECURITY_RESPONDERS_SKIN  : 55344,
    MALEDICTION_BLUEPRINT  : 11187,
    MALEDICTION_CAPSULEER_DAY_XV_SKIN  : 47621,
    MALEDICTION_COLD_IRON_SKIN  : 42758,
    MALEDICTION_CROWN_AND_SWORDS_SKIN  : 52768,
    MALEDICTION_EOM_SKIN  : 36288,
    MALEDICTION_EXOPLANETS_HUNTER_SKIN  : 45765,
    MALEDICTION_GLACIAL_DRIFT_SKIN  : 46780,
    MALEDICTION_HUNTERS_QUIVER_SKIN  : 57534,
    MALEDICTION_IMPERIAL_JUBILEE_SKIN  : 48843,
    MALEDICTION_LUMINAIRE_RISING_SKIN  : 64405,
    MALEDICTION_LUMINAIRE_ZENITH_SKIN  : 64395,
    MALEDICTION_OASIS_OF_FAITH_SKIN  : 53607,
    MALEDICTION_PURITY_OF_THE_THRONE_SKIN  : 42556,
    MALEDICTION_RAATA_SUNSET_SKIN  : 37496,
    MALEDICTION_STAR_CAPTAIN_SKIN  : 52570,
    MALFUNCTIONING_ARMOR_NANOBOT  : 30615,
    MALFUNCTIONING_HULL_SECTION  : 30753,
    MALFUNCTIONING_POWER_CORES  : 30586,
    MALFUNCTIONING_SHIELD_EMITTER  : 25589,
    MALFUNCTIONING_THRUSTER_SECTIONS  : 30558,
    MALFUNCTIONING_WEAPON_SUBROUTINES  : 30632,
    MALICE  : 3516,
    MALKUTH_CRUISE_LAUNCHER_I  : 16513,
    MALKUTH_HEAVY_MISSILE_LAUNCHER_I  : 8101,
    MALKUTH_RAPID_HEAVY_MISSILE_LAUNCHER_I  : 33442,
    MALKUTH_ROCKET_LAUNCHER_I  : 16521,
    MALLER  : 624,
    MALLER_AURORA_UNIVERSALIS_SKIN  : 53368,
    MALLER_BIOSECURITY_RESPONDERS_SKIN  : 55328,
    MALLER_BLOOD_RAIDERS_SKIN  : 60670,
    MALLER_BLUEPRINT  : 969,
    MALLER_CAPSULEER_DAY_XVII_SKIN  : 54759,
    MALLER_COLD_IRON_SKIN  : 42768,
    MALLER_EXOPLANETS_HUNTER_SKIN  : 45776,
    MALLER_GLACIAL_DRIFT_SKIN  : 44144,
    MALLER_HUNTERS_QUIVER_SKIN  : 47494,
    MALLER_IMPERIAL_NAVY_SKIN  : 58898,
    MALLER_IRONBLOOD_SKIN  : 43502,
    MALLER_KADOR_SKIN  : 34680,
    MALLER_OASIS_OF_FAITH_SKIN  : 53618,
    MALLER_PERMABAND_ROADIES_SKIN  : 54444,
    MALLER_PURITY_OF_THE_THRONE_SKIN  : 42567,
    MALLER_RAATA_SUNSET_SKIN  : 37502,
    MALLER_SARUM_SKIN  : 60679,
    MALLER_SCOPE_SYNDICATION_YC122_SKIN  : 56884,
    MALLER_TASH_MURKON_SKIN  : 34681,
    MALLER_VERSUS_BLUEFORCE_SKIN  : 62011,
    MALLER_WAR_RESERVES_LIEUTENANT_SKIN  : 59211,
    MALLER_ZAKURA_SHUMYU_SKIN  : 57058,
    MALLEUS_I  : 32340,
    MALLEUS_I_BLUEPRINT  : 32341,
    MALLEUS_II  : 40561,
    MAMMOTH  : 652,
    MAMMOTH_BIOSECURITY_RESPONDERS_SKIN  : 55657,
    MAMMOTH_BLUE_TIGER_SKIN  : 36839,
    MAMMOTH_BLUEPRINT  : 987,
    MAMMOTH_EXOPLANETS_HUNTER_SKIN  : 45985,
    MAMMOTH_FLEET_ORDNANCE_SPIKE_SKIN  : 64908,
    MAMMOTH_GLACIAL_DRIFT_SKIN  : 46922,
    MAMMOTH_HAZARD_CONTROL_SKIN  : 56930,
    MAMMOTH_LODESTRIKE_SKIN  : 46747,
    MAMMOTH_NEFANTAR_SKIN  : 34757,
    MAMMOTH_SNOWLINE_BLADERACER_SKIN  : 52896,
    MAMMOTH_TRONHADAR_INK_SKIN  : 40510,
    MAMMOTH_VALKLEAR_GLORY_SKIN  : 36792,
    MANIFEST__u2013_CARGO_118_01_06__u2013_CLEARENCE_1B  : 41026,
    MANNAR_HAIR_GEL  : 48139,
    MANTICORE  : 12032,
    MANTICORE_AURORA_UNIVERSALIS_SKIN  : 60910,
    MANTICORE_BIOSECURITY_RESPONDERS_SKIN  : 55553,
    MANTICORE_BLUE_TIGER_SKIN  : 36714,
    MANTICORE_BLUEPRINT  : 12031,
    MANTICORE_EXOPLANETS_HUNTER_SKIN  : 45821,
    MANTICORE_GHOSTBIRD_SKIN  : 48164,
    MANTICORE_GLACIAL_DRIFT_SKIN  : 46831,
    MANTICORE_MATIGU_SEABEAST_SKIN  : 40588,
    MANTICORE_RAATA_SUNSET_SKIN  : 36642,
    MANTICORE_STATE_POLICE_SKIN  : 60029,
    MANTICORE_STEEL_CARDINAL_SKIN  : 46098,
    MANTICORE_VITALSHIFT_EGO_SKIN  : 52552,
    MANTICORE_WIYRKOMI_SKIN  : 36347,
    MANTICORE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53158,
    MANTIS_I  : 32344,
    MANTIS_I_BLUEPRINT  : 32345,
    MANTIS_II  : 40567,
    MANUFACTURING_TOOLS  : 16686,
    MANUFACTURING_UNIONS_PLACARD  : 34417,
    MARAUDERS  : 28667,
    MARGINIS_FORTIZAR  : 47515,
    MARINES  : 3810,
    MARK_I_COMPACT_CAPACITOR_FLUX_COIL  : 8133,
    MARK_I_COMPACT_CAPACITOR_POWER_RELAY  : 8177,
    MARK_I_COMPACT_POWER_DIAGNOSTIC_SYSTEM  : 8225,
    MARK_I_COMPACT_REACTOR_CONTROL_UNIT  : 8263,
    MARK_I_COMPACT_REINFORCED_BULKHEADS  : 5649,
    MARK_I_COMPACT_SHIELD_FLUX_COIL  : 8297,
    MARK_I_COMPACT_SHIELD_POWER_RELAY  : 8337,
    MARKETEER_TRACKING_COMPUTER  : 22929,
    MARKETING  : 16598,
    MARSHAL  : 44996,
    MARSHAL_BIOSECURITY_RESPONDERS_SKIN  : 55294,
    MARSHAL_BLACKICE_DERAILERS_SKIN  : 57785,
    MARSHAL_CONCORD_AEROSPACE_PROTOTYPE_SKIN  : 47159,
    MARSHAL_DED_ALPHA_PLUS_TASKFORCE_SKIN  : 48581,
    MARSHAL_DED_ARMY_RESERVE_SKIN  : 54218,
    MARSHAL_DED_MASTER_AT_ARMS_RECRUITER_SKIN  : 48578,
    MARSHAL_EXOPLANETS_HUNTER_SKIN  : 47158,
    MARSHAL_FIREBLADE_GUERILLA_SKIN  : 59329,
    MARSHAL_FIREWATCH_SKIN  : 47160,
    MARSHAL_GLACIAL_DRIFT_SKIN  : 47002,
    MARSHAL_ICEBLADE_GUERILLA_SKIN  : 59334,
    MARSHAL_RED_STARGAZER_SKIN  : 75909,
    MARSHAL_SARO_BLACK_TROOP_SKIN  : 47683,
    MARSHALL_MAGNETOMETRIC_ECM  : 20573,
    MASS_PRODUCTION  : 3387,
    MASS_REACTIONS  : 45748,
    MASSIVE_SCORDITE  : 17464,
    MASTER_AT_ARMS_CEREBRAL_ACCELERATOR  : 48582,
    MASTODON  : 12747,
    MASTODON_BIOSECURITY_RESPONDERS_SKIN  : 55681,
    MASTODON_BLUE_TIGER_SKIN  : 36842,
    MASTODON_BLUEPRINT  : 12748,
    MASTODON_EXOPLANETS_HUNTER_SKIN  : 45988,
    MASTODON_GLACIAL_DRIFT_SKIN  : 46925,
    MASTODON_HAZARD_CONTROL_SKIN  : 56906,
    MASTODON_JUSTICE_SKIN  : 36438,
    MASTODON_LODESTRIKE_SKIN  : 46749,
    MASTODON_SNOWLINE_BLADERACER_SKIN  : 52899,
    MASTODON_TRONHADAR_INK_SKIN  : 40513,
    MASTODON_VALKLEAR_GLORY_SKIN  : 36795,
    MATARI_WORD_CALENDAR  : 48589,
    MATERIAL_ACQUISITION_STRONG_BOX  : 43689,
    MAULUS  : 609,
    MAULUS_BIOSECURITY_RESPONDERS_SKIN  : 55597,
    MAULUS_BLUEPRINT  : 956,
    MAULUS_EXOPLANETS_HUNTER_SKIN  : 45874,
    MAULUS_GLACIAL_DRIFT_SKIN  : 44177,
    MAULUS_INNER_ZONE_SHIPPING_SKIN  : 34742,
    MAULUS_INNER_ZONE_VANGUARD_SKIN  : 52709,
    MAULUS_INTAKI_SYNDICATE_SKIN  : 34743,
    MAULUS_KOPIS_EDGE_SKIN  : 46386,
    MAULUS_LUMINAIRE_RISING_SKIN  : 77869,
    MAULUS_NAVY_ISSUE  : 37456,
    MAULUS_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55605,
    MAULUS_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45886,
    MAULUS_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46938,
    MAULUS_NAVY_ISSUE_INNER_ZONE_VANGUARD_SKIN  : 52721,
    MAULUS_NAVY_ISSUE_INTAKI_SYNDICATE_SKIN  : 40332,
    MAULUS_NAVY_ISSUE_KOPIS_EDGE_SKIN  : 46398,
    MAULUS_NAVY_ISSUE_LUMINAIRE_RISING_SKIN  : 77873,
    MAULUS_NAVY_ISSUE_SAPPHIRE_SUNGRAZER_SKIN  : 45022,
    MAULUS_NAVY_ISSUE_SPIRIT_SKIN  : 44904,
    MAULUS_SERPENTIS_SKIN  : 42150,
    MAULUS_SPIRIT_SKIN  : 44892,
    MAULUS_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59424,
    MAULUS_VALIMOR_LEGACY_SKIN  : 37550,
    MAX_AMARRIA  : 37880,
    MEAT_POPSICLE  : 37885,
    MECHANIC_PARTS  : 21593,
    MECHANICAL_ENGINEERING  : 11452,
    MECHANICAL_PARTS  : 3689,
    MECHANICS  : 3392,
    MEDICAL_REPORT_0401A__u2013_117_01_19  : 41028,
    MEDITATION_MEDIUM_ARMOR_REPAIRER_I  : 22889,
    MEDIUM_ACM_COMPACT_ARMOR_REPAIRER  : 4573,
    MEDIUM_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I  : 31420,
    MEDIUM_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 31421,
    MEDIUM_ALGID_ENERGY_ADMINISTRATIONS_UNIT_II  : 31426,
    MEDIUM_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I  : 31504,
    MEDIUM_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 31505,
    MEDIUM_ALGID_HYBRID_ADMINISTRATIONS_UNIT_II  : 31510,
    MEDIUM_ANCILLARY_ARMOR_REPAIRER  : 33101,
    MEDIUM_ANCILLARY_CURRENT_ROUTER_I  : 31360,
    MEDIUM_ANCILLARY_CURRENT_ROUTER_I_BLUEPRINT  : 31361,
    MEDIUM_ANCILLARY_CURRENT_ROUTER_II  : 31366,
    MEDIUM_ANCILLARY_REMOTE_ARMOR_REPAIRER  : 41477,
    MEDIUM_ANCILLARY_REMOTE_SHIELD_BOOSTER  : 41481,
    MEDIUM_ANCILLARY_SHIELD_BOOSTER  : 32772,
    MEDIUM_ARMOR_MAINTENANCE_BOT_I  : 23709,
    MEDIUM_ARMOR_MAINTENANCE_BOT_I_BLUEPRINT  : 23710,
    MEDIUM_ARMOR_MAINTENANCE_BOT_II  : 28205,
    MEDIUM_ARMOR_REPAIRER_I  : 3528,
    MEDIUM_ARMOR_REPAIRER_I_BLUEPRINT  : 3529,
    MEDIUM_ARMOR_REPAIRER_II  : 3530,
    MEDIUM_ARTILLERY_BATTERY  : 16688,
    MEDIUM_ARTILLERY_BATTERY_BLUEPRINT  : 2810,
    MEDIUM_ARTILLERY_SPECIALIZATION  : 12202,
    MEDIUM_ASTEROID_ORE_COMPRESSOR_I  : 62622,
    MEDIUM_ASTEROID_ORE_COMPRESSOR_I_BLUEPRINT  : 62652,
    MEDIUM_ASTRAHUS_CITADEL_BPC_TOKEN  : 40548,
    MEDIUM_ASYMMETRIC_ENDURING_REMOTE_SHIELD_BOOSTER  : 8583,
    MEDIUM_AUDIT_LOG_SECURE_CONTAINER  : 17364,
    MEDIUM_AUDIT_LOG_SECURE_CONTAINER_BLUEPRINT  : 32869,
    MEDIUM_AUTOCANNON_BATTERY  : 17771,
    MEDIUM_AUTOCANNON_BATTERY_BLUEPRINT  : 2814,
    MEDIUM_AUTOCANNON_SPECIALIZATION  : 12208,
    MEDIUM_AUTOMATED_STRUCTURAL_RESTORATION  : 5721,
    MEDIUM_AUXILIARY_NANO_PUMP_I  : 31047,
    MEDIUM_AUXILIARY_NANO_PUMP_I_BLUEPRINT  : 31048,
    MEDIUM_AUXILIARY_NANO_PUMP_II  : 31053,
    MEDIUM_AUXILIARY_THRUSTERS_I  : 31107,
    MEDIUM_AUXILIARY_THRUSTERS_I_BLUEPRINT  : 31108,
    MEDIUM_AUXILIARY_THRUSTERS_II  : 31113,
    MEDIUM_AZEOTROPIC_RESTRAINED_SHIELD_EXTENDER  : 8433,
    MEDIUM_BAY_LOADING_ACCELERATOR_I  : 31588,
    MEDIUM_BAY_LOADING_ACCELERATOR_I_BLUEPRINT  : 31589,
    MEDIUM_BAY_LOADING_ACCELERATOR_II  : 31594,
    MEDIUM_BEAM_LASER_BATTERY  : 17168,
    MEDIUM_BEAM_LASER_BATTERY_BLUEPRINT  : 2828,
    MEDIUM_BEAM_LASER_SPECIALIZATION  : 12204,
    MEDIUM_BIOCHEMICAL_REACTOR_ARRAY  : 22634,
    MEDIUM_BLASTER_BATTERY  : 17403,
    MEDIUM_BLASTER_BATTERY_BLUEPRINT  : 2806,
    MEDIUM_BLASTER_SPECIALIZATION  : 12211,
    MEDIUM_C5_L_COMPACT_SHIELD_BOOSTER  : 10872,
    MEDIUM_CANYON_SHIELD_EXTENDER  : 20629,
    MEDIUM_CAP_BATTERY_I  : 2018,
    MEDIUM_CAP_BATTERY_I_BLUEPRINT  : 2019,
    MEDIUM_CAP_BATTERY_II  : 3496,
    MEDIUM_CAPACITOR_BOOSTER_I  : 577,
    MEDIUM_CAPACITOR_BOOSTER_I_BLUEPRINT  : 1128,
    MEDIUM_CAPACITOR_BOOSTER_II  : 2024,
    MEDIUM_CAPACITOR_CONTROL_CIRCUIT_I  : 31372,
    MEDIUM_CAPACITOR_CONTROL_CIRCUIT_I_BLUEPRINT  : 31373,
    MEDIUM_CAPACITOR_CONTROL_CIRCUIT_II  : 31378,
    MEDIUM_CARGOHOLD_OPTIMIZATION_I  : 31119,
    MEDIUM_CARGOHOLD_OPTIMIZATION_I_BLUEPRINT  : 31120,
    MEDIUM_CARGOHOLD_OPTIMIZATION_II  : 31125,
    MEDIUM_CLARITY_WARD_ENDURING_SHIELD_BOOSTER  : 10868,
    MEDIUM_COAXIAL_COMPACT_REMOTE_ARMOR_REPAIRER  : 16443,
    MEDIUM_COMMAND_PROCESSOR_I  : 43896,
    MEDIUM_COMMAND_PROCESSOR_I_BLUEPRINT  : 43904,
    MEDIUM_COMPACT_PB_ACID_CAP_BATTERY  : 6073,
    MEDIUM_COMPACT_VORTON_PROJECTOR  : 54746,
    MEDIUM_CONSORTIUM_VORTON_PROJECTOR  : 54749,
    MEDIUM_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I  : 31766,
    MEDIUM_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I_BLUEPRINT  : 31767,
    MEDIUM_CORE_DEFENSE_CAPACITOR_SAFEGUARD_II  : 31772,
    MEDIUM_CORE_DEFENSE_CHARGE_ECONOMIZER_I  : 31778,
    MEDIUM_CORE_DEFENSE_CHARGE_ECONOMIZER_I_BLUEPRINT  : 31779,
    MEDIUM_CORE_DEFENSE_CHARGE_ECONOMIZER_II  : 31784,
    MEDIUM_CORE_DEFENSE_FIELD_EXTENDER_I  : 31790,
    MEDIUM_CORE_DEFENSE_FIELD_EXTENDER_I_BLUEPRINT  : 31791,
    MEDIUM_CORE_DEFENSE_FIELD_EXTENDER_II  : 31796,
    MEDIUM_CORE_DEFENSE_FIELD_PURGER_I  : 31802,
    MEDIUM_CORE_DEFENSE_FIELD_PURGER_I_BLUEPRINT  : 31803,
    MEDIUM_CORE_DEFENSE_FIELD_PURGER_II  : 31812,
    MEDIUM_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I  : 31818,
    MEDIUM_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I_BLUEPRINT  : 31819,
    MEDIUM_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_II  : 31824,
    MEDIUM_DEGENERATIVE_CONCUSSION_BOMB_I  : 21536,
    MEDIUM_DISINTEGRATOR_SPECIALIZATION  : 47874,
    MEDIUM_DITCH_ENERGY_NEUTRALIZER  : 23817,
    MEDIUM_DRONE_CONTROL_RANGE_AUGMENTOR_I  : 32027,
    MEDIUM_DRONE_CONTROL_RANGE_AUGMENTOR_I_BLUEPRINT  : 32028,
    MEDIUM_DRONE_CONTROL_RANGE_AUGMENTOR_II  : 32031,
    MEDIUM_DRONE_DURABILITY_ENHANCER_I  : 32035,
    MEDIUM_DRONE_DURABILITY_ENHANCER_I_BLUEPRINT  : 32036,
    MEDIUM_DRONE_DURABILITY_ENHANCER_II  : 32039,
    MEDIUM_DRONE_MINING_AUGMENTOR_I  : 32043,
    MEDIUM_DRONE_MINING_AUGMENTOR_I_BLUEPRINT  : 32044,
    MEDIUM_DRONE_MINING_AUGMENTOR_II  : 32047,
    MEDIUM_DRONE_OPERATION  : 33699,
    MEDIUM_DRONE_REPAIR_AUGMENTOR_I  : 32051,
    MEDIUM_DRONE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 32052,
    MEDIUM_DRONE_REPAIR_AUGMENTOR_II  : 32055,
    MEDIUM_DRONE_SCOPE_CHIP_I  : 32071,
    MEDIUM_DRONE_SCOPE_CHIP_I_BLUEPRINT  : 32072,
    MEDIUM_DRONE_SCOPE_CHIP_II  : 32075,
    MEDIUM_DRONE_SPEED_AUGMENTOR_I  : 32059,
    MEDIUM_DRONE_SPEED_AUGMENTOR_I_BLUEPRINT  : 32060,
    MEDIUM_DRONE_SPEED_AUGMENTOR_II  : 32063,
    MEDIUM_DYNAMIC_FUEL_VALVE_I  : 31131,
    MEDIUM_DYNAMIC_FUEL_VALVE_I_BLUEPRINT  : 31132,
    MEDIUM_DYNAMIC_FUEL_VALVE_II  : 31137,
    MEDIUM_EGRESS_PORT_MAXIMIZER_I  : 31384,
    MEDIUM_EGRESS_PORT_MAXIMIZER_I_BLUEPRINT  : 31385,
    MEDIUM_EGRESS_PORT_MAXIMIZER_II  : 31390,
    MEDIUM_EM_ARMOR_REINFORCER_I  : 30999,
    MEDIUM_EM_ARMOR_REINFORCER_I_BLUEPRINT  : 31000,
    MEDIUM_EM_ARMOR_REINFORCER_II  : 31005,
    MEDIUM_EM_SHIELD_REINFORCER_I  : 31718,
    MEDIUM_EM_SHIELD_REINFORCER_I_BLUEPRINT  : 31719,
    MEDIUM_EM_SHIELD_REINFORCER_II  : 31724,
    MEDIUM_EMISSION_SCOPE_SHARPENER_I  : 31203,
    MEDIUM_EMISSION_SCOPE_SHARPENER_I_BLUEPRINT  : 31204,
    MEDIUM_EMISSION_SCOPE_SHARPENER_II  : 31209,
    MEDIUM_EMP_SMARTBOMB_I  : 3953,
    MEDIUM_EMP_SMARTBOMB_I_BLUEPRINT  : 3954,
    MEDIUM_EMP_SMARTBOMB_II  : 3955,
    MEDIUM_ENERGY_AMBIT_EXTENSION_I  : 31432,
    MEDIUM_ENERGY_AMBIT_EXTENSION_I_BLUEPRINT  : 31433,
    MEDIUM_ENERGY_AMBIT_EXTENSION_II  : 31438,
    MEDIUM_ENERGY_BURST_AERATOR_I  : 31444,
    MEDIUM_ENERGY_BURST_AERATOR_I_BLUEPRINT  : 31445,
    MEDIUM_ENERGY_BURST_AERATOR_II  : 31450,
    MEDIUM_ENERGY_COLLISION_ACCELERATOR_I  : 31456,
    MEDIUM_ENERGY_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31457,
    MEDIUM_ENERGY_COLLISION_ACCELERATOR_II  : 31462,
    MEDIUM_ENERGY_DISCHARGE_ELUTRIATION_I  : 31468,
    MEDIUM_ENERGY_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 31469,
    MEDIUM_ENERGY_DISCHARGE_ELUTRIATION_II  : 31474,
    MEDIUM_ENERGY_LOCUS_COORDINATOR_I  : 31480,
    MEDIUM_ENERGY_LOCUS_COORDINATOR_I_BLUEPRINT  : 31481,
    MEDIUM_ENERGY_LOCUS_COORDINATOR_II  : 31486,
    MEDIUM_ENERGY_METASTASIS_ADJUSTER_I  : 31492,
    MEDIUM_ENERGY_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31493,
    MEDIUM_ENERGY_METASTASIS_ADJUSTER_II  : 31498,
    MEDIUM_ENERGY_NEUTRALIZER_I  : 12265,
    MEDIUM_ENERGY_NEUTRALIZER_I_BLUEPRINT  : 12266,
    MEDIUM_ENERGY_NEUTRALIZER_II  : 12267,
    MEDIUM_ENERGY_NOSFERATU_I  : 12257,
    MEDIUM_ENERGY_NOSFERATU_I_BLUEPRINT  : 12258,
    MEDIUM_ENERGY_NOSFERATU_II  : 12259,
    MEDIUM_ENERGY_TURRET  : 3306,
    MEDIUM_ENGINE_THERMAL_SHIELDING_I  : 31143,
    MEDIUM_ENGINE_THERMAL_SHIELDING_I_BLUEPRINT  : 31144,
    MEDIUM_ENGINE_THERMAL_SHIELDING_II  : 31149,
    MEDIUM_EXPLOSIVE_ARMOR_REINFORCER_I  : 31011,
    MEDIUM_EXPLOSIVE_ARMOR_REINFORCER_I_BLUEPRINT  : 31012,
    MEDIUM_EXPLOSIVE_ARMOR_REINFORCER_II  : 31017,
    MEDIUM_EXPLOSIVE_SHIELD_REINFORCER_I  : 31730,
    MEDIUM_EXPLOSIVE_SHIELD_REINFORCER_I_BLUEPRINT  : 31731,
    MEDIUM_EXPLOSIVE_SHIELD_REINFORCER_II  : 31736,
    MEDIUM_F_RX_COMPACT_CAPACITOR_BOOSTER  : 4833,
    MEDIUM_F_S9_REGOLITH_COMPACT_SHIELD_EXTENDER  : 8517,
    MEDIUM_FACTION_AMMUNITION_CRATE  : 49719,
    MEDIUM_FREIGHT_CONTAINER  : 33009,
    MEDIUM_FREIGHT_CONTAINER_BLUEPRINT  : 33010,
    MEDIUM_GAS_COMPRESSOR_I  : 62624,
    MEDIUM_GAS_COMPRESSOR_I_BLUEPRINT  : 62673,
    MEDIUM_GATTOTTE_CAPACITOR_BOOSTER  : 20557,
    MEDIUM_GHOUL_COMPACT_ENERGY_NOSFERATU  : 16505,
    MEDIUM_GRAVITON_SMARTBOMB_I  : 3941,
    MEDIUM_GRAVITON_SMARTBOMB_I_BLUEPRINT  : 3942,
    MEDIUM_GRAVITON_SMARTBOMB_II  : 3943,
    MEDIUM_GRAVITY_CAPACITOR_UPGRADE_I  : 31215,
    MEDIUM_GRAVITY_CAPACITOR_UPGRADE_I_BLUEPRINT  : 31216,
    MEDIUM_GRAVITY_CAPACITOR_UPGRADE_II  : 31222,
    MEDIUM_GREMLIN_COMPACT_ENERGY_NEUTRALIZER  : 16467,
    MEDIUM_HIGGS_ANCHOR_I  : 34268,
    MEDIUM_HIGGS_ANCHOR_I_BLUEPRINT  : 34269,
    MEDIUM_HOPE_HULL_RECONSTRUCTOR_I  : 5719,
    MEDIUM_HULL_MAINTENANCE_BOT_I  : 33704,
    MEDIUM_HULL_MAINTENANCE_BOT_I_BLUEPRINT  : 33705,
    MEDIUM_HULL_MAINTENANCE_BOT_II  : 33710,
    MEDIUM_HULL_REPAIRER_I  : 3653,
    MEDIUM_HULL_REPAIRER_I_BLUEPRINT  : 3654,
    MEDIUM_HULL_REPAIRER_II  : 3655,
    MEDIUM_HYBRID_AMBIT_EXTENSION_I  : 31516,
    MEDIUM_HYBRID_AMBIT_EXTENSION_I_BLUEPRINT  : 31517,
    MEDIUM_HYBRID_AMBIT_EXTENSION_II  : 31522,
    MEDIUM_HYBRID_BURST_AERATOR_I  : 31528,
    MEDIUM_HYBRID_BURST_AERATOR_I_BLUEPRINT  : 31529,
    MEDIUM_HYBRID_BURST_AERATOR_II  : 31534,
    MEDIUM_HYBRID_COLLISION_ACCELERATOR_I  : 31540,
    MEDIUM_HYBRID_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31541,
    MEDIUM_HYBRID_COLLISION_ACCELERATOR_II  : 31546,
    MEDIUM_HYBRID_DISCHARGE_ELUTRIATION_I  : 31552,
    MEDIUM_HYBRID_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 31553,
    MEDIUM_HYBRID_DISCHARGE_ELUTRIATION_II  : 31558,
    MEDIUM_HYBRID_LOCUS_COORDINATOR_I  : 31564,
    MEDIUM_HYBRID_LOCUS_COORDINATOR_I_BLUEPRINT  : 31565,
    MEDIUM_HYBRID_LOCUS_COORDINATOR_II  : 31570,
    MEDIUM_HYBRID_METASTASIS_ADJUSTER_I  : 31576,
    MEDIUM_HYBRID_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31577,
    MEDIUM_HYBRID_METASTASIS_ADJUSTER_II  : 31582,
    MEDIUM_HYBRID_TURRET  : 3304,
    MEDIUM_HYDRAULIC_BAY_THRUSTERS_I  : 31600,
    MEDIUM_HYDRAULIC_BAY_THRUSTERS_I_BLUEPRINT  : 31601,
    MEDIUM_HYDRAULIC_BAY_THRUSTERS_II  : 31606,
    MEDIUM_HYPERSPATIAL_VELOCITY_OPTIMIZER_I  : 31161,
    MEDIUM_HYPERSPATIAL_VELOCITY_OPTIMIZER_I_BLUEPRINT  : 31162,
    MEDIUM_HYPERSPATIAL_VELOCITY_OPTIMIZER_II  : 31167,
    MEDIUM_I_A_ENDURING_ARMOR_REPAIRER  : 4569,
    MEDIUM_I_AX_ENDURING_REMOTE_ARMOR_REPAIRER  : 16441,
    MEDIUM_I_B_POLARIZED_STRUCTURAL_REGENERATOR  : 5723,
    MEDIUM_ICE_HARVESTER_ACCELERATOR_I  : 32819,
    MEDIUM_ICE_HARVESTER_ACCELERATOR_I_BLUEPRINT  : 32820,
    MEDIUM_INDUCTIVE_COMPACT_REMOTE_CAPACITOR_TRANSMITTER  : 16495,
    MEDIUM_INDUSTRIAL_CORE_I  : 62590,
    MEDIUM_INDUSTRIAL_CORE_I_BLUEPRINT  : 62592,
    MEDIUM_INDUSTRIAL_CORE_II  : 62591,
    MEDIUM_INEFFICIENT_HULL_REPAIR_UNIT  : 5683,
    MEDIUM_INFECTIOUS_SCOPED_ENERGY_NEUTRALIZER  : 16469,
    MEDIUM_INTEGRATIVE_HULL_REPAIR_UNIT  : 21506,
    MEDIUM_INVERTED_SIGNAL_FIELD_PROJECTOR_I  : 31264,
    MEDIUM_INVERTED_SIGNAL_FIELD_PROJECTOR_I_BLUEPRINT  : 31265,
    MEDIUM_INVERTED_SIGNAL_FIELD_PROJECTOR_II  : 31270,
    MEDIUM_IONIC_FIELD_PROJECTOR_I  : 31276,
    MEDIUM_IONIC_FIELD_PROJECTOR_I_BLUEPRINT  : 31277,
    MEDIUM_IONIC_FIELD_PROJECTOR_II  : 31282,
    MEDIUM_KINETIC_ARMOR_REINFORCER_I  : 31023,
    MEDIUM_KINETIC_ARMOR_REINFORCER_I_BLUEPRINT  : 31024,
    MEDIUM_KINETIC_ARMOR_REINFORCER_II  : 31029,
    MEDIUM_KINETIC_SHIELD_REINFORCER_I  : 31742,
    MEDIUM_KINETIC_SHIELD_REINFORCER_I_BLUEPRINT  : 31743,
    MEDIUM_KINETIC_SHIELD_REINFORCER_II  : 31748,
    MEDIUM_KNAVE_SCOPED_ENERGY_NOSFERATU  : 16507,
    MEDIUM_LIQUID_COOLED_ELECTRONICS_I  : 31228,
    MEDIUM_LIQUID_COOLED_ELECTRONICS_I_BLUEPRINT  : 31229,
    MEDIUM_LIQUID_COOLED_ELECTRONICS_II  : 31234,
    MEDIUM_LONE_RANGER_SHIELD_BOOSTER  : 20619,
    MEDIUM_LOW_FRICTION_NOZZLE_JOINTS_I  : 31155,
    MEDIUM_LOW_FRICTION_NOZZLE_JOINTS_I_BLUEPRINT  : 31156,
    MEDIUM_LOW_FRICTION_NOZZLE_JOINTS_II  : 31173,
    MEDIUM_MEMETIC_ALGORITHM_BANK_I  : 31240,
    MEDIUM_MEMETIC_ALGORITHM_BANK_I_BLUEPRINT  : 31241,
    MEDIUM_MEMETIC_ALGORITHM_BANK_II  : 31246,
    MEDIUM_MERCOXIT_MINING_CRYSTAL_OPTIMIZATION_I  : 32817,
    MEDIUM_MERCOXIT_MINING_CRYSTAL_OPTIMIZATION_I_BLUEPRINT  : 32818,
    MEDIUM_MICRO_JUMP_DRIVE  : 33915,
    MEDIUM_MURKY_COMPACT_REMOTE_SHIELD_BOOSTER  : 8579,
    MEDIUM_NANOBOT_ACCELERATOR_I  : 31065,
    MEDIUM_NANOBOT_ACCELERATOR_I_BLUEPRINT  : 31066,
    MEDIUM_NANOBOT_ACCELERATOR_II  : 31071,
    MEDIUM_NOTOS_EXPLOSIVE_CHARGE_I  : 9762,
    MEDIUM_PARTICLE_DISPERSION_AUGMENTOR_I  : 31288,
    MEDIUM_PARTICLE_DISPERSION_AUGMENTOR_I_BLUEPRINT  : 31289,
    MEDIUM_PARTICLE_DISPERSION_AUGMENTOR_II  : 31294,
    MEDIUM_PARTICLE_DISPERSION_PROJECTOR_I  : 31300,
    MEDIUM_PARTICLE_DISPERSION_PROJECTOR_I_BLUEPRINT  : 31301,
    MEDIUM_PARTICLE_DISPERSION_PROJECTOR_II  : 31306,
    MEDIUM_PLASMA_SMARTBOMB_I  : 3947,
    MEDIUM_PLASMA_SMARTBOMB_I_BLUEPRINT  : 3948,
    MEDIUM_PLASMA_SMARTBOMB_II  : 3949,
    MEDIUM_POLYCARBON_ENGINE_HOUSING_I  : 31179,
    MEDIUM_POLYCARBON_ENGINE_HOUSING_I_BLUEPRINT  : 31180,
    MEDIUM_POLYCARBON_ENGINE_HOUSING_II  : 31185,
    MEDIUM_POWERGRID_SUBROUTINE_MAXIMIZER_I  : 31396,
    MEDIUM_POWERGRID_SUBROUTINE_MAXIMIZER_I_BLUEPRINT  : 31397,
    MEDIUM_POWERGRID_SUBROUTINE_MAXIMIZER_II  : 31402,
    MEDIUM_PRECURSOR_WEAPON  : 47871,
    MEDIUM_PROCESSOR_OVERCLOCKING_UNIT_I  : 4395,
    MEDIUM_PROCESSOR_OVERCLOCKING_UNIT_I_BLUEPRINT  : 4396,
    MEDIUM_PROCESSOR_OVERCLOCKING_UNIT_II  : 4399,
    MEDIUM_PROJECTILE_AMBIT_EXTENSION_I  : 31658,
    MEDIUM_PROJECTILE_AMBIT_EXTENSION_I_BLUEPRINT  : 31659,
    MEDIUM_PROJECTILE_AMBIT_EXTENSION_II  : 31664,
    MEDIUM_PROJECTILE_BURST_AERATOR_I  : 31670,
    MEDIUM_PROJECTILE_BURST_AERATOR_I_BLUEPRINT  : 31671,
    MEDIUM_PROJECTILE_BURST_AERATOR_II  : 31676,
    MEDIUM_PROJECTILE_COLLISION_ACCELERATOR_I  : 31682,
    MEDIUM_PROJECTILE_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31683,
    MEDIUM_PROJECTILE_COLLISION_ACCELERATOR_II  : 31688,
    MEDIUM_PROJECTILE_LOCUS_COORDINATOR_I  : 31694,
    MEDIUM_PROJECTILE_LOCUS_COORDINATOR_I_BLUEPRINT  : 31695,
    MEDIUM_PROJECTILE_LOCUS_COORDINATOR_II  : 31700,
    MEDIUM_PROJECTILE_METASTASIS_ADJUSTER_I  : 31706,
    MEDIUM_PROJECTILE_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31707,
    MEDIUM_PROJECTILE_METASTASIS_ADJUSTER_II  : 31712,
    MEDIUM_PROJECTILE_TURRET  : 3305,
    MEDIUM_PROTON_SMARTBOMB_I  : 3937,
    MEDIUM_PROTON_SMARTBOMB_I_BLUEPRINT  : 3938,
    MEDIUM_PROTON_SMARTBOMB_II  : 3939,
    MEDIUM_PULSE_LASER_BATTERY  : 17407,
    MEDIUM_PULSE_LASER_BATTERY_BLUEPRINT  : 2827,
    MEDIUM_PULSE_LASER_SPECIALIZATION  : 12214,
    MEDIUM_RADIATIVE_SCOPED_REMOTE_CAPACITOR_TRANSMITTER  : 16489,
    MEDIUM_RAILGUN_BATTERY  : 16691,
    MEDIUM_RAILGUN_BATTERY_BLUEPRINT  : 2808,
    MEDIUM_RAILGUN_SPECIALIZATION  : 12206,
    MEDIUM_REMOTE_ARMOR_REPAIRER_I  : 11357,
    MEDIUM_REMOTE_ARMOR_REPAIRER_I_BLUEPRINT  : 11358,
    MEDIUM_REMOTE_ARMOR_REPAIRER_II  : 26913,
    MEDIUM_REMOTE_CAPACITOR_TRANSMITTER_I  : 12217,
    MEDIUM_REMOTE_CAPACITOR_TRANSMITTER_I_BLUEPRINT  : 12218,
    MEDIUM_REMOTE_CAPACITOR_TRANSMITTER_II  : 12221,
    MEDIUM_REMOTE_HULL_REPAIRER_I  : 27930,
    MEDIUM_REMOTE_HULL_REPAIRER_I_BLUEPRINT  : 27931,
    MEDIUM_REMOTE_HULL_REPAIRER_II  : 4296,
    MEDIUM_REMOTE_REPAIR_AUGMENTOR_I  : 31073,
    MEDIUM_REMOTE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 31074,
    MEDIUM_REMOTE_REPAIR_AUGMENTOR_II  : 31079,
    MEDIUM_REMOTE_SHIELD_BOOSTER_I  : 3596,
    MEDIUM_REMOTE_SHIELD_BOOSTER_I_BLUEPRINT  : 3597,
    MEDIUM_REMOTE_SHIELD_BOOSTER_II  : 3598,
    MEDIUM_ROCKET_FUEL_CACHE_PARTITION_I  : 31610,
    MEDIUM_ROCKET_FUEL_CACHE_PARTITION_I_BLUEPRINT  : 31611,
    MEDIUM_ROCKET_FUEL_CACHE_PARTITION_II  : 31616,
    MEDIUM_RUDIMENTARY_CONCUSSION_BOMB_I  : 9728,
    MEDIUM_S95A_SCOPED_REMOTE_SHIELD_BOOSTER  : 8585,
    MEDIUM_SALVAGE_TACKLE_I  : 31085,
    MEDIUM_SALVAGE_TACKLE_I_BLUEPRINT  : 31086,
    MEDIUM_SALVAGE_TACKLE_II  : 31091,
    MEDIUM_SCOPED_VORTON_PROJECTOR  : 54747,
    MEDIUM_SECURE_CONTAINER  : 3466,
    MEDIUM_SECURE_CONTAINER_BLUEPRINT  : 32866,
    MEDIUM_SEMICONDUCTOR_MEMORY_CELL_I  : 31408,
    MEDIUM_SEMICONDUCTOR_MEMORY_CELL_I_BLUEPRINT  : 31409,
    MEDIUM_SEMICONDUCTOR_MEMORY_CELL_II  : 31414,
    MEDIUM_SENTRY_DAMAGE_AUGMENTOR_I  : 32083,
    MEDIUM_SENTRY_DAMAGE_AUGMENTOR_I_BLUEPRINT  : 32084,
    MEDIUM_SENTRY_DAMAGE_AUGMENTOR_II  : 32087,
    MEDIUM_SHIELD_BOOSTER_I  : 10836,
    MEDIUM_SHIELD_BOOSTER_I_BLUEPRINT  : 10837,
    MEDIUM_SHIELD_BOOSTER_II  : 10850,
    MEDIUM_SHIELD_EXTENDER_I  : 3829,
    MEDIUM_SHIELD_EXTENDER_I_BLUEPRINT  : 3830,
    MEDIUM_SHIELD_EXTENDER_II  : 3831,
    MEDIUM_SHIELD_MAINTENANCE_BOT_I  : 23717,
    MEDIUM_SHIELD_MAINTENANCE_BOT_I_BLUEPRINT  : 23718,
    MEDIUM_SHIELD_MAINTENANCE_BOT_II  : 28207,
    MEDIUM_SHIP_ASSEMBLY_ARRAY  : 24654,
    MEDIUM_SIGNAL_DISRUPTION_AMPLIFIER_I  : 31252,
    MEDIUM_SIGNAL_DISRUPTION_AMPLIFIER_I_BLUEPRINT  : 31253,
    MEDIUM_SIGNAL_DISRUPTION_AMPLIFIER_II  : 31258,
    MEDIUM_SIGNAL_FOCUSING_KIT_I  : 31312,
    MEDIUM_SIGNAL_FOCUSING_KIT_I_BLUEPRINT  : 31313,
    MEDIUM_SIGNAL_FOCUSING_KIT_II  : 31318,
    MEDIUM_SOLACE_SCOPED_REMOTE_ARMOR_REPAIRER  : 16447,
    MEDIUM_STANDARD_CONTAINER  : 3293,
    MEDIUM_STANDARD_CONTAINER_BLUEPRINT  : 32860,
    MEDIUM_STASIS_DRONE_AUGMENTOR_I  : 32091,
    MEDIUM_STASIS_DRONE_AUGMENTOR_I_BLUEPRINT  : 32092,
    MEDIUM_STASIS_DRONE_AUGMENTOR_II  : 32095,
    MEDIUM_STRIGOI_ENERGY_NOSFERATU  : 23824,
    MEDIUM_TARGETING_SYSTEM_SUBCONTROLLER_I  : 31324,
    MEDIUM_TARGETING_SYSTEM_SUBCONTROLLER_I_BLUEPRINT  : 31325,
    MEDIUM_TARGETING_SYSTEM_SUBCONTROLLER_II  : 31330,
    MEDIUM_TARGETING_SYSTEMS_STABILIZER_I  : 31336,
    MEDIUM_TARGETING_SYSTEMS_STABILIZER_I_BLUEPRINT  : 31337,
    MEDIUM_TARGETING_SYSTEMS_STABILIZER_II  : 31342,
    MEDIUM_THERMAL_ARMOR_REINFORCER_I  : 31035,
    MEDIUM_THERMAL_ARMOR_REINFORCER_I_BLUEPRINT  : 31036,
    MEDIUM_THERMAL_ARMOR_REINFORCER_II  : 31041,
    MEDIUM_THERMAL_SHIELD_REINFORCER_I  : 31754,
    MEDIUM_THERMAL_SHIELD_REINFORCER_I_BLUEPRINT  : 31755,
    MEDIUM_THERMAL_SHIELD_REINFORCER_II  : 31760,
    MEDIUM_TRACKING_DIAGNOSTIC_SUBROUTINES_I  : 31348,
    MEDIUM_TRACKING_DIAGNOSTIC_SUBROUTINES_I_BLUEPRINT  : 31349,
    MEDIUM_TRACKING_DIAGNOSTIC_SUBROUTINES_II  : 31354,
    MEDIUM_TRANSVERSE_BULKHEAD_I  : 33894,
    MEDIUM_TRANSVERSE_BULKHEAD_I_BLUEPRINT  : 33895,
    MEDIUM_TRANSVERSE_BULKHEAD_II  : 33896,
    MEDIUM_TRIMARK_ARMOR_PUMP_I  : 31055,
    MEDIUM_TRIMARK_ARMOR_PUMP_I_BLUEPRINT  : 31056,
    MEDIUM_TRIMARK_ARMOR_PUMP_II  : 31059,
    MEDIUM_VEHEMENCE_SHOCKWAVE_CHARGE  : 9734,
    MEDIUM_VORTON_PROJECTOR  : 55035,
    MEDIUM_VORTON_PROJECTOR_I  : 54745,
    MEDIUM_VORTON_PROJECTOR_II  : 54748,
    MEDIUM_VORTON_PROJECTOR_OLD  : 54825,
    MEDIUM_VORTON_SPECIALIZATION  : 54828,
    MEDIUM_WARHEAD_CALEFACTION_CATALYST_I  : 31622,
    MEDIUM_WARHEAD_CALEFACTION_CATALYST_I_BLUEPRINT  : 31623,
    MEDIUM_WARHEAD_CALEFACTION_CATALYST_II  : 31628,
    MEDIUM_WARHEAD_FLARE_CATALYST_I  : 31634,
    MEDIUM_WARHEAD_FLARE_CATALYST_I_BLUEPRINT  : 31635,
    MEDIUM_WARHEAD_FLARE_CATALYST_II  : 31640,
    MEDIUM_WARHEAD_RIGOR_CATALYST_I  : 31646,
    MEDIUM_WARHEAD_RIGOR_CATALYST_I_BLUEPRINT  : 31647,
    MEDIUM_WARHEAD_RIGOR_CATALYST_II  : 31652,
    MEDIUM_WARP_CORE_OPTIMIZER_I  : 31191,
    MEDIUM_WARP_CORE_OPTIMIZER_I_BLUEPRINT  : 31192,
    MEDIUM_WARP_CORE_OPTIMIZER_II  : 31197,
    MEDIUM_YF_12A_SMARTBOMB  : 9800,
    MEGA_AFOCAL_MASER_I  : 7125,
    MEGA_AFOCAL_PULSE_MASER_I  : 7085,
    MEGA_ANODE_PARTICLE_STREAM_I  : 7131,
    MEGA_ANODE_PULSE_PARTICLE_STREAM_I  : 7089,
    MEGA_BEAM_LASER_I  : 463,
    MEGA_BEAM_LASER_I_BLUEPRINT  : 847,
    MEGA_BEAM_LASER_II  : 3049,
    MEGA_MODAL_LASER_I  : 7123,
    MEGA_MODAL_PULSE_LASER_I  : 7083,
    MEGA_MODULATED_ENERGY_BEAM_I  : 7127,
    MEGA_MODULATED_PULSE_ENERGY_BEAM_I  : 7087,
    MEGA_PULSE_LASER_I  : 462,
    MEGA_PULSE_LASER_I_BLUEPRINT  : 846,
    MEGA_PULSE_LASER_II  : 3057,
    MEGACORP_MANAGEMENT  : 3731,
    MEGACORP_TRANSACTION_THREAD  : 77948,
    MEGACYTE  : 40,
    MEGATHRON  : 641,
    MEGATHRON_ABYSSAL_FIRESTORM_SKIN  : 53138,
    MEGATHRON_AURORA_UNIVERSALIS_SKIN  : 53379,
    MEGATHRON_BIOSECURITY_RESPONDERS_SKIN  : 55578,
    MEGATHRON_BLUEPRINT  : 995,
    MEGATHRON_DEATHGLOW_REMNANT_SKIN  : 60663,
    MEGATHRON_EXOPLANETS_HUNTER_SKIN  : 45913,
    MEGATHRON_FEDERAL_POLICE_SKIN  : 34802,
    MEGATHRON_FEDERATE_ISSUE  : 13202,
    MEGATHRON_GILDED_PREDATOR_SKIN  : 77177,
    MEGATHRON_GLACIAL_DRIFT_SKIN  : 46959,
    MEGATHRON_GREEN_DEMONS_SKIN  : 59336,
    MEGATHRON_INNER_ZONE_SHIPPING_SKIN  : 34638,
    MEGATHRON_INNER_ZONE_VANGUARD_SKIN  : 52748,
    MEGATHRON_INTAKI_SYNDICATE_SKIN  : 34639,
    MEGATHRON_KOPIS_EDGE_SKIN  : 46425,
    MEGATHRON_LUMINAIRE_ZENITH_SKIN  : 64401,
    MEGATHRON_MALAGRIN_MARSHLIGHT_SKIN  : 53014,
    MEGATHRON_NAVY_ISSUE  : 17728,
    MEGATHRON_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55608,
    MEGATHRON_NAVY_ISSUE_CAPSULEER_ELITE_XX_SKIN  : 77171,
    MEGATHRON_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45917,
    MEGATHRON_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46963,
    MEGATHRON_NAVY_ISSUE_INNER_ZONE_VANGUARD_SKIN  : 52752,
    MEGATHRON_NAVY_ISSUE_INTAKI_SYNDICATE_SKIN  : 36392,
    MEGATHRON_NAVY_ISSUE_KOPIS_EDGE_SKIN  : 46429,
    MEGATHRON_NAVY_ISSUE_LUMINAIRE_RISING_SKIN  : 77872,
    MEGATHRON_NAVY_ISSUE_SAPPHIRE_SUNGRAZER_SKIN  : 45027,
    MEGATHRON_NAVY_ISSUE_SPIRIT_SKIN  : 44935,
    MEGATHRON_OPERATION_PERMAFROST_SKIN  : 49814,
    MEGATHRON_QUAFE_SKIN  : 34640,
    MEGATHRON_RUBY_SUNGRAZER_SKIN  : 45570,
    MEGATHRON_SAPPHIRE_SUNGRAZER_SKIN  : 46556,
    MEGATHRON_SCOPE_SYNDICATION_YC122_SKIN  : 56886,
    MEGATHRON_SERPENTIS_SKIN  : 42184,
    MEGATHRON_SPIRIT_SKIN  : 44931,
    MEGATHRON_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59240,
    MEGATHRON_TRIGLAVIAN_TWILIGHT_SKIN  : 52396,
    MEGATHRON_VALIMOR_LEGACY_SKIN  : 37580,
    MEGATHRON_ZAKURA_SHUMYU_SKIN  : 57063,
    MELTED_CAPACITOR_CONSOLE  : 25603,
    MELTED_NANORIBBONS  : 30259,
    MELTED_SCRAPS_OF_CAPSULE_PLATING  : 54227,
    MELTED_SNOWBALL  : 33569,
    MELTED_SNOWBALL_CVII  : 19658,
    MELTED_SNOWBALL_CX  : 30222,
    MELTED_SNOWBALL_CXIV  : 30221,
    MEMORY_AUGMENTATION___BASIC  : 9941,
    MEMORY_AUGMENTATION___IMPROVED  : 10209,
    MEMORY_AUGMENTATION___STANDARD  : 10208,
    MENS__WOMENS_FROSTLINE_EXPLORATION_SUIT  : 49803,
    MENS_ACE_OF_PODHUNTERS_BOOTS  : 63113,
    MENS_ACE_OF_PODHUNTERS_JACKET  : 63112,
    MENS_ACE_OF_PODHUNTERS_PANTS  : 63114,
    MENS_AIR_CLAIM_SURVEYOR_SUIT  : 62750,
    MENS_ALBEDO_AFFLUENCE_JACKET  : 54382,
    MENS_ALBEDO_AFFLUENCE_PANTS  : 54376,
    MENS_AMARANTHINE_DIVIDE_FACIAL_AUGMENTATION  : 50051,
    MENS_AMARR_COURTLY_ROBE  : 64380,
    MENS_AMARR_DEVOTIONAL_ROBE  : 64379,
    MENS_AMARR_EMPIRE_COMMAND_JACKET  : 46662,
    MENS_AMARR_EMPIRE_EXPLORATION_SUIT  : 45526,
    MENS_AMARR_LOYALIST_T_SHIRT  : 47182,
    MENS_ASCEND_BOOTS_BROWN_GOLD  : 33780,
    MENS_ASCEND_BOOTS_GREY_SILVER  : 33778,
    MENS_ASCEND_BOOTS_MAROON_BLACK  : 33779,
    MENS_ASCEND_BOOTS_ROYAL  : 33777,
    MENS_ASCEND_BOOTS_WHITE_GOLD  : 33776,
    MENS_AURORA_UNIVERSALIS_CAP  : 53384,
    MENS_AURORA_UNIVERSALIS_T_SHIRT  : 53383,
    MENS_AZURE_ABUNDANCE_JACKET  : 54378,
    MENS_AZURE_ABUNDANCE_PANTS  : 54372,
    MENS_BARBATUS_GLIMMER_FACIAL_AUGMENTATION  : 49683,
    MENS_BARBATUS_MEDICUS_FACIAL_AUGMENTATION  : 55636,
    MENS_BARBATUS_ROJO_FACIAL_AUGMENTATION  : 73436,
    MENS_BARBATUS_SEMIOTIQUE_FACIAL_AUGMENTATION  : 60163,
    MENS_BERET_BLOOD_RAIDERS  : 42708,
    MENS_BIOADAPTIVE_KYBERNAUT_COMBAT_SUIT  : 56266,
    MENS_BIOCRYSTAL_PROTECTIVE_GLASSES  : 54966,
    MENS_BIOSECURITY_RESPONSE_TEAM_COAT  : 40462,
    MENS_BIOSECURITY_RESPONSE_TEAM_MASK  : 54830,
    MENS_BITWAVE_GLACIER_AUGMENTATION  : 49987,
    MENS_BOARDER_CAP_DIGITAL_CAMO  : 42732,
    MENS_BONEWELD_FACIAL_AUGMENTATION  : 41036,
    MENS_BRIDGESTAT_FACIAL_AUGMENTATION  : 41047,
    MENS_CALDARI_NAVY_CREW_JACKET  : 70838,
    MENS_CALDARI_PATRIOT_T_SHIRT  : 47188,
    MENS_CALDARI_STATE_EXPLORATION_SUIT  : 45524,
    MENS_CALDARI_STATE_OFFICER_JACKET  : 46659,
    MENS_CAP_BLOOD_RAIDERS  : 42726,
    MENS_CAP_GURISTAS  : 42743,
    MENS_CAPSULEER_DAY_XIX_HOODIE  : 63246,
    MENS_CAPSULEER_DAY_XVI_CAP  : 52202,
    MENS_CAPSULEER_DAY_XVI_T_SHIRT  : 52200,
    MENS_CAPSULEER_DAY_XVII_CAP  : 54327,
    MENS_CAPSULEER_DAY_XVII_T_SHIRT  : 54328,
    MENS_CAPSULEER_DAY_XVIII_CAP  : 58767,
    MENS_CAPSULEER_DAY_XVIII_T_SHIRT  : 58757,
    MENS_CAPSULEER_ELITE_XIX_HOODIE  : 63253,
    MENS_CAPSULEER_ELITE_XV_CAP  : 47191,
    MENS_CAPSULEER_ELITE_XV_T_SHIRT  : 47190,
    MENS_CAPSULEER_NETWORKER_XV_CAP  : 47478,
    MENS_CAPSULEER_NETWORKER_XV_SUIT  : 47476,
    MENS_CAPSULEER_NETWORKER_XV_T_SHIRT  : 47480,
    MENS_CBD_CORPORATION_CREW_JACKET  : 70840,
    MENS_CIRCUITRUN_FACIAL_AUGMENTATION  : 41044,
    MENS_COMMANDO_PANTS_BLACK  : 4165,
    MENS_COMMANDO_PANTS_BLACK_WAX  : 3992,
    MENS_COMMANDO_PANTS_BLUE  : 4166,
    MENS_COMMANDO_PANTS_BROWN_CAMO  : 4169,
    MENS_COMMANDO_PANTS_GOLD_BLACK  : 4167,
    MENS_COMMANDO_PANTS_GRAY_BLACK  : 4168,
    MENS_COMMANDO_PANTS_GREEN_CAMO  : 4170,
    MENS_COMMANDO_PANTS_RED_BLACK  : 4171,
    MENS_CONCORD_SOLITAIRE_SURVIVAL_SUIT  : 37818,
    MENS_CONCORD_TEST_PILOT_T_SHIRT  : 37809,
    MENS_CORPORATE_TROUBLESHOOTER_GEAR  : 45527,
    MENS_CRIMSON_HARVEST_CAP  : 56637,
    MENS_CRIMSON_HARVEST_JACKET  : 56638,
    MENS_CRIMSON_HARVEST_T_SHIRT  : 56639,
    MENS_CRUSADER_BERET_AMARR_EMPIRE  : 42741,
    MENS_CRUSHER_CYBERNETIC_ARM_BLACK_AND_ORANGE_LEFT  : 34024,
    MENS_CRUSHER_CYBERNETIC_ARM_BLACK_AND_ORANGE_RIGHT  : 34038,
    MENS_CRUSHER_CYBERNETIC_ARM_BLACK_AND_RED_LEFT  : 34025,
    MENS_CRUSHER_CYBERNETIC_ARM_BLACK_AND_RED_RIGHT  : 34039,
    MENS_CRUSHER_CYBERNETIC_ARM_BLACK_AND_YELLOW_LEFT  : 34026,
    MENS_CRUSHER_CYBERNETIC_ARM_BLACK_AND_YELLOW_RIGHT  : 34040,
    MENS_CRUSHER_CYBERNETIC_ARM_BLUE_AND_WHITE_LEFT  : 34027,
    MENS_CRUSHER_CYBERNETIC_ARM_BLUE_AND_WHITE_RIGHT  : 34041,
    MENS_CRUSHER_CYBERNETIC_ARM_GREEN_AND_YELLOW_LEFT  : 34029,
    MENS_CRUSHER_CYBERNETIC_ARM_GREEN_AND_YELLOW_RIGHT  : 34043,
    MENS_CRUSHER_CYBERNETIC_ARM_GREEN_CAMO_LEFT  : 34028,
    MENS_CRUSHER_CYBERNETIC_ARM_GREEN_CAMO_RIGHT  : 34042,
    MENS_CRUSHER_CYBERNETIC_ARM_GUNMETAL_LEFT  : 34030,
    MENS_CRUSHER_CYBERNETIC_ARM_GUNMETAL_RIGHT  : 34044,
    MENS_CURATE_COAT_BLACK_SILVER  : 33800,
    MENS_CURATE_COAT_DARK_BRONZE  : 33799,
    MENS_CURATE_COAT_FESTIVE  : 37817,
    MENS_CURATE_COAT_RED_GOLD  : 33798,
    MENS_CURATE_COAT_ROYAL_GOLD  : 33797,
    MENS_CURATE_COAT_WHITE_GOLD  : 33796,
    MENS_DED_CRYPTOLOGIC_TECHNICIAN_COAT  : 54177,
    MENS_DEFENDER_BERET_GALLENTE_FEDERATION  : 42739,
    MENS_DESERT_NOMAD_GOGGLES  : 73426,
    MENS_DESERT_NOMAD_MASK  : 73425,
    MENS_DOMINO_TURQUOISE_FACIAL_AUGMENTATION  : 49682,
    MENS_DREAD_BERET_GURISTAS  : 42699,
    MENS_EDENCOM_DEFIANT_COMBAT_SUIT  : 56267,
    MENS_EDENCOM_RELIANT_BERET  : 56145,
    MENS_EDENCOM_RELIANT_T_SHIRT  : 56147,
    MENS_EDENCOM_VALIANT_COMMAND_JACKET  : 56224,
    MENS_EICO_AURORALITE_FACIAL_AUGMENTATION  : 53393,
    MENS_EICO_AZURBRAK_FACIAL_AUGMENTATION  : 49678,
    MENS_EICO_BIOSEC_FACIAL_AUGMENTATION  : 54926,
    MENS_EICO_FLAMJAW_FACIAL_AUGMENTATION  : 49681,
    MENS_EICO_HEXARC_FACIAL_AUGMENTATION  : 49680,
    MENS_EICO_REDARC_XVI_FACIAL_AUGMENTATION  : 52189,
    MENS_EICO_ZYGOMANDIBA_FACIAL_AUGMENTATION  : 49684,
    MENS_EMERGENT_THREATS_T_SHIRT_YC_117  : 34824,
    MENS_EMPYREAN_DRAGONS_JACKET  : 47032,
    MENS_ESQUIRE_COAT  : 4098,
    MENS_ESQUIRE_COAT_BLACK  : 4233,
    MENS_ESQUIRE_COAT_GREEN_GOLD  : 4234,
    MENS_ESQUIRE_COAT_MATTE_GRAPHITE  : 4235,
    MENS_ESQUIRE_COAT_MATTE_GRAY  : 4236,
    MENS_ESQUIRE_COAT_MATTE_GREEN  : 4237,
    MENS_ESQUIRE_COAT_RED_GOLD  : 4238,
    MENS_ESQUIRE_COAT_SILVER  : 4239,
    MENS_EXOPLANETS_DATA_ANALYST_LAB_COAT  : 45744,
    MENS_EXOPLANETS_HUNTER_CAP  : 45740,
    MENS_EXOPLANETS_HUNTER_MISSION_SUIT  : 45741,
    MENS_EXOPLANETS_HUNTER_T_SHIRT  : 45743,
    MENS_EXOPLANETS_PROGRAM_DIRECTOR_COAT  : 45742,
    MENS_FARSIGHT_AUGMENTED_SPECTACLES  : 45745,
    MENS_FATAL_ELITE_BERET  : 54310,
    MENS_FATAL_ELITE_COMBAT_BOOTS  : 54309,
    MENS_FATAL_ELITE_COMBAT_JACKET  : 54307,
    MENS_FATAL_ELITE_COMBAT_PANTS  : 54308,
    MENS_FEDERAL_DEFENDER_BERET  : 55757,
    MENS_FEDERAL_DEFENDER_JACKET  : 59185,
    MENS_FEDERAL_DEFENDER_PANTS  : 59187,
    MENS_FEDERAL_STRATEGIC_MATERIEL_JACKET  : 46667,
    MENS_FEDERATION_COMMANDERS_DRESS_JACKET  : 58935,
    MENS_FEDERATION_COMMANDERS_DRESS_PANTS  : 58953,
    MENS_FEDERATION_GRAND_PRIX_PIT_STOP_CAP  : 42710,
    MENS_FEDERATION_STARGATE_CREW_PANTS  : 58793,
    MENS_FEDERATION_STARGATE_CREW_SHOES  : 58827,
    MENS_FEDERATION_STARGATE_CREW_TOP  : 58815,
    MENS_FIELD_MARSHAL_COAT  : 4097,
    MENS_FIELD_MARSHAL_COAT_GREEN  : 4244,
    MENS_FORM_T_SHIRT_BLACK  : 4180,
    MENS_FORM_T_SHIRT_BLOOD_RAIDERS  : 42704,
    MENS_FORM_T_SHIRT_BLUE  : 4181,
    MENS_FORM_T_SHIRT_BROWN  : 4182,
    MENS_FORM_T_SHIRT_DARK_BLUE  : 4183,
    MENS_FORM_T_SHIRT_DARK_CAMO  : 4188,
    MENS_FORM_T_SHIRT_DARK_RED  : 4184,
    MENS_FORM_T_SHIRT_DESERT_CAMO  : 4189,
    MENS_FORM_T_SHIRT_KHAKI  : 4185,
    MENS_FORM_T_SHIRT_LIGHT_GRAY  : 4186,
    MENS_FORM_T_SHIRT_OLIVE  : 4187,
    MENS_FORM_T_SHIRT_WHITE  : 4190,
    MENS_FRONTIER_SAFEGUARDER_T_SHIRT  : 54194,
    MENS_FROSTLINE_EXPLORATION_SUIT  : 49978,
    MENS_GALLENTE_DEFENDER_T_SHIRT  : 47184,
    MENS_GALLENTE_FEDERATION_COMMAND_JACKET  : 46665,
    MENS_GALLENTE_FEDERATION_EXPLORATION_SUIT  : 45521,
    MENS_GENOLUTION_COMBAT_SUIT  : 37810,
    MENS_GENOLUTION_TACTICAL_GOGGLES  : 42906,
    MENS_GLAMSTREAK_FACIAL_AUGMENTATION  : 49685,
    MENS_GLASS_SEMIOTIQUE_MONOCLE_INTERFACE_RIGHT  : 60165,
    MENS_GRAND_PRIX_RACING_JACKET  : 63789,
    MENS_GRIMGLAM_FACIAL_AUGMENTATION  : 49677,
    MENS_HEPHAESTUS_PANTS_BLUE  : 36493,
    MENS_HEPHAESTUS_PANTS_CYAN  : 36496,
    MENS_HEPHAESTUS_PANTS_DESERT  : 36495,
    MENS_HEPHAESTUS_PANTS_GRAY_ORANGE  : 36498,
    MENS_HEPHAESTUS_PANTS_GREEN  : 36497,
    MENS_HEPHAESTUS_PANTS_WHITE_RED  : 36494,
    MENS_HEPHAESTUS_SHIRT_BLUE  : 36480,
    MENS_HEPHAESTUS_SHIRT_CYAN  : 36483,
    MENS_HEPHAESTUS_SHIRT_DESERT  : 36482,
    MENS_HEPHAESTUS_SHIRT_GRAY_ORANGE  : 36485,
    MENS_HEPHAESTUS_SHIRT_GREEN  : 36484,
    MENS_HEPHAESTUS_SHIRT_WHITE_RED  : 36481,
    MENS_HEPHAESTUS_SHOES_BLUE  : 36505,
    MENS_HEPHAESTUS_SHOES_CYAN  : 36508,
    MENS_HEPHAESTUS_SHOES_DESERT  : 36507,
    MENS_HEPHAESTUS_SHOES_GRAY_ORANGE  : 36510,
    MENS_HEPHAESTUS_SHOES_GREEN  : 36509,
    MENS_HEPHAESTUS_SHOES_WHITE_RED  : 36506,
    MENS_HUMANITARIAN_T_SHIRT_YC_115  : 33578,
    MENS_HUMANITARIAN_T_SHIRT_YC_117  : 35681,
    MENS_HYASYODA_CREW_JACKET  : 70841,
    MENS_HYPER_RACING_JACKET  : 64196,
    MENS_IGC_LOCATION_SCOUT_SUIT  : 46038,
    MENS_IMPACT_JACKET_DARK_RED  : 33794,
    MENS_IMPACT_JACKET_GRAPHITE  : 33793,
    MENS_IMPACT_JACKET_GREEN_CAMO  : 33792,
    MENS_IMPACT_JACKET_MONOCHROME  : 33791,
    MENS_IMPACT_JACKET_REFLECTIVE_BLUE  : 33795,
    MENS_IMPERIAL_COMMANDERS_DRESS_JACKET  : 58934,
    MENS_IMPERIAL_COMMANDERS_DRESS_PANTS  : 58952,
    MENS_IMPERIAL_LOYALIST_BERET  : 55755,
    MENS_IMPERIAL_LOYALIST_JACKET  : 59366,
    MENS_IMPERIAL_LOYALIST_PANTS  : 59368,
    MENS_IMPERIAL_STARGATE_CREW_PANTS  : 58791,
    MENS_IMPERIAL_STARGATE_CREW_SHOES  : 58825,
    MENS_IMPERIAL_STARGATE_CREW_TOP  : 58813,
    MENS_IMPERIAL_WAR_RESERVES_JACKET  : 46666,
    MENS_INTERSTELLAR_CONVERGENCE_T_SHIRT  : 61533,
    MENS_ISHUKONE_CREW_JACKET  : 70839,
    MENS_JITA_4_4_CREW_PANTS  : 56849,
    MENS_JITA_4_4_CREW_SHOES  : 56847,
    MENS_JITA_4_4_CREW_TOP  : 56848,
    MENS_JITA_4_4_EXECUTIVE_JACKET  : 56851,
    MENS_JITA_4_4_EXECUTIVE_PANTS  : 56850,
    MENS_KAALAKIOTA_CREW_JACKET  : 70842,
    MENS_KIMOTORO_ESKEITAN_COMMANDERS_JACKET  : 63747,
    MENS_KIMOTORO_ESKEITAN_COMMANDERS_PANTS  : 63748,
    MENS_KYBERNAUT_CLADE_BERET  : 56210,
    MENS_KYBERNAUT_CLADE_T_SHIRT  : 56212,
    MENS_LAI_DAI_CREW_JACKET  : 70844,
    MENS_LIBERATOR_BERET_MINMATAR_REPUBLIC  : 42712,
    MENS_LIBERATOR_CAP_MINMATAR_REPUBLIC  : 42730,
    MENS_LOCKSTEP_BOOTS  : 4008,
    MENS_LOCKSTEP_BOOTS_TRUE_BLACK  : 4172,
    MENS_LOCKSTEP_BOOTS_WORN_BROWN  : 4173,
    MENS_LUXURY_T_SHIRT  : 33488,
    MENS_MARCH_BOOTS_BLACK  : 33775,
    MENS_MARCH_BOOTS_BLUE  : 33774,
    MENS_MARCH_BOOTS_BROWN  : 33773,
    MENS_MARCH_BOOTS_GRAPHITE  : 33772,
    MENS_MARCH_BOOTS_WHITE  : 33771,
    MENS_MARSHAL_JACKET_AMARR  : 34157,
    MENS_MARSHAL_JACKET_ANGEL_CARTEL  : 34164,
    MENS_MARSHAL_JACKET_BLOOD_RAIDERS  : 34166,
    MENS_MARSHAL_JACKET_BLOOD_RAIDERS_BLACK_RED  : 42702,
    MENS_MARSHAL_JACKET_CALDARI  : 34156,
    MENS_MARSHAL_JACKET_GALLENTE  : 34159,
    MENS_MARSHAL_JACKET_GURISTAS  : 34167,
    MENS_MARSHAL_JACKET_INTERBUS  : 34163,
    MENS_MARSHAL_JACKET_MINMATAR  : 34158,
    MENS_MARSHAL_JACKET_MORDUS_LEGION  : 34162,
    MENS_MARSHAL_JACKET_ORE  : 34160,
    MENS_MARSHAL_JACKET_SANSHAS_NATION  : 34165,
    MENS_MARSHAL_JACKET_SERPENTIS  : 34168,
    MENS_MARSHAL_JACKET_SISTERS_OF_EVE  : 34161,
    MENS_MINMATAR_FREEDOM_FIGHTER_CAP  : 64270,
    MENS_MINMATAR_FREEDOM_FIGHTER_T_SHIRT  : 64268,
    MENS_MINMATAR_LIBERATOR_T_SHIRT  : 47183,
    MENS_MINMATAR_REBELLION_CAP  : 64201,
    MENS_MINMATAR_REBELLION_T_SHIRT  : 64204,
    MENS_MINMATAR_REPUBLIC_EXPLORATION_SUIT  : 45525,
    MENS_MINMATAR_REPUBLIC_OFFICER_JACKET  : 46664,
    MENS_MIRELLE_PANTS_UPWELL  : 40524,
    MENS_MIRELLE_T_SHIRT_UPWELL  : 40526,
    MENS_MIRELLE_UPWELL_EXECUTIVE_COAT  : 40522,
    MENS_MIRELLE_UPWELL_EXECUTIVE_SPECTACLES  : 41576,
    MENS_MISTBORNE_RIME_AUGMENTATION  : 49986,
    MENS_NEW_EDEN_OPEN_I_T_SHIRT_YC_114  : 33769,
    MENS_NEW_EDEN_OPEN_II_T_SHIRT_YC_116  : 33770,
    MENS_NOVA_HEADWEAR_BLACK  : 34056,
    MENS_NOVA_HEADWEAR_BRONZE  : 34061,
    MENS_NOVA_HEADWEAR_GOLD  : 34060,
    MENS_NOVA_HEADWEAR_SILVER  : 34059,
    MENS_NUGOEIHUVI_CREW_JACKET  : 70846,
    MENS_OBSIDIAN_OPULENCE_JACKET  : 54377,
    MENS_OBSIDIAN_OPULENCE_PANTS  : 54371,
    MENS_OCULINA_CARMINA_FACIAL_AUGMENTATION  : 53394,
    MENS_ORB_SEMIOTIQUE_SYNTHETIC_EYE_RIGHT  : 60160,
    MENS_ORE_CLAIM_SURVEYOR_SUIT  : 45522,
    MENS_OUTLAW_BOOTS_BLOOD_RAIDERS  : 34360,
    MENS_OUTLAW_BOOTS_GURISTAS  : 34362,
    MENS_OUTLAW_BOOTS_SANSHAS_NATION  : 34361,
    MENS_OUTLAW_JACKET_BLOOD_RAIDERS  : 34347,
    MENS_OUTLAW_JACKET_BLOOD_RAIDERS_EXTRA_RED  : 42705,
    MENS_OUTLAW_JACKET_BURNT_ORANGE  : 34393,
    MENS_OUTLAW_JACKET_GURISTAS  : 34349,
    MENS_OUTLAW_JACKET_SANSHAS_NATION  : 34348,
    MENS_OUTLAW_PANTS_BLOOD_RAIDERS  : 34354,
    MENS_OUTLAW_PANTS_GURISTAS  : 34356,
    MENS_OUTLAW_PANTS_SANSHAS_NATION  : 34355,
    MENS_PARADOXICAL_SCARF  : 61536,
    MENS_PERMABAND_TOUR_CAP  : 50133,
    MENS_PERMABAND_TOUR_T_SHIRT  : 50131,
    MENS_PHANCA_CYBERNETIC_ARM_LEFT  : 33551,
    MENS_PHANCA_CYBERNETIC_ARM_RIGHT  : 33552,
    MENS_PITHUM_BERET_GURISTAS  : 42721,
    MENS_POLAR_AURORA_EXPLORATION_SUIT  : 53382,
    MENS_PRECISION_BOOTS  : 3966,
    MENS_PRECISION_BOOTS_BROWN  : 4174,
    MENS_PRECISION_BOOTS_GRAY  : 4175,
    MENS_PRECISION_BOOTS_TAN  : 4176,
    MENS_PROJECT_DISCOVERY_CYTOMETRY_CAP  : 54948,
    MENS_PROJECT_DISCOVERY_CYTOMETRY_T_SHIRT  : 54943,
    MENS_PROPER_DAPPER_OUTFIT  : 61949,
    MENS_PROTECTOR_BERET_CALDARI_STATE  : 42736,
    MENS_PROVEN_BIOADAPTIVE_MASK  : 63135,
    MENS_PROVEN_KYBERNAUT_COMBAT_SUIT  : 55857,
    MENS_QUAFE_T_SHIRT_YC_113  : 4067,
    MENS_QUAFE_T_SHIRT_YC_114  : 4365,
    MENS_QUAFE_T_SHIRT_YC_115  : 33107,
    MENS_QUAFE_T_SHIRT_YC_116  : 33812,
    MENS_QUAFETHRON_T_SHIRT  : 34210,
    MENS_RADIOACTIVES_RECLAMATION_GOGGLES  : 60639,
    MENS_RADIOACTIVES_RECLAMATION_SUIT  : 60647,
    MENS_RED_STAR_T_SHIRT  : 33062,
    MENS_REPUBLIC_COMMANDERS_DRESS_JACKET  : 58936,
    MENS_REPUBLIC_COMMANDERS_DRESS_PANTS  : 58954,
    MENS_REPUBLIC_FLEET_ORDNANCE_JACKET  : 46674,
    MENS_REPUBLIC_SERVICE_TATTOO_BRUTOR  : 59221,
    MENS_REPUBLIC_SERVICE_TATTOO_KRUSUAL  : 59228,
    MENS_REPUBLIC_SERVICE_TATTOO_NEFANTAR  : 59232,
    MENS_REPUBLIC_SERVICE_TATTOO_SEBIESTOR  : 59230,
    MENS_REPUBLIC_SERVICE_TATTOO_STARKMANIR  : 59201,
    MENS_REPUBLIC_SERVICE_TATTOO_THUKKER  : 59199,
    MENS_REPUBLIC_SERVICE_TATTOO_VHEROKIOR  : 59226,
    MENS_REPUBLIC_STARGATE_CREW_PANTS  : 58794,
    MENS_REPUBLIC_STARGATE_CREW_SHOES  : 58828,
    MENS_REPUBLIC_STARGATE_CREW_TOP  : 58816,
    MENS_REPUBLIC_VALKLEAR_BERET  : 55758,
    MENS_REPUBLIC_VALKLEAR_JACKET  : 59178,
    MENS_REPUBLIC_VALKLEAR_PANTS  : 59179,
    MENS_RIDER_PANTS_BLACK_SILVER  : 33790,
    MENS_RIDER_PANTS_RED_GOLD  : 33788,
    MENS_RIDER_PANTS_REFLECTIVE_BLUE  : 33789,
    MENS_RIDER_PANTS_ROYAL_GOLD  : 33787,
    MENS_RIDER_PANTS_WHITE_GOLD  : 33786,
    MENS_RIKKADORI_FACIAL_AUGMENTATION  : 49679,
    MENS_RUBEDO_RICHESSE_JACKET  : 54381,
    MENS_RUBEDO_RICHESSE_PANTS  : 54375,
    MENS_RUBYSET_TORSORIG_BODY_AUGMENTATION  : 53041,
    MENS_SABLE_CAP_BLACK  : 42723,
    MENS_SAIKADORI_FACIAL_AUGMENTATION  : 56855,
    MENS_SANGUINE_HARVEST_PANTS  : 60432,
    MENS_SANGUINE_HARVEST_SHOES  : 58829,
    MENS_SANGUINE_HARVEST_T_SHIRT  : 60435,
    MENS_SARO_BLACK_TROOP_COMBAT_SUIT  : 47286,
    MENS_SCOPE_NETWORK_SPECIAL_CORRESPONDENT_SUIT  : 37864,
    MENS_SEMIOTIQUE_SPORTS_PANTS  : 60161,
    MENS_SEMIOTIQUE_SPORTS_SHOES  : 60162,
    MENS_SEMIOTIQUE_SUPERLUMINAL_JACKET  : 63051,
    MENS_SEMIOTIQUE_SUPERLUMINAL_PANTS  : 63054,
    MENS_SEMIOTIQUE_SUPERLUMINAL_SHADES  : 63058,
    MENS_SEMIOTIQUE_TORSORIG_BODY_AUGMENTATION  : 60164,
    MENS_SERPENTIS_T_SHIRT_OLIVE_BLACK  : 37866,
    MENS_SETCOUPLE_FACIAL_AUGMENTATION  : 41046,
    MENS_SILVER_SUPREMACY_JACKET  : 54380,
    MENS_SILVER_SUPREMACY_PANTS  : 54374,
    MENS_SISTERS_OF_EVE_ADVANCED_COMBAT_SUIT  : 37815,
    MENS_SOE_ENHANCED_BIOSECURITY_MASK  : 54719,
    MENS_SOE_EXPEDITIONARY_SUIT  : 45523,
    MENS_SOURCE_COAT_BLACK  : 33803,
    MENS_STATE_COMMANDERS_DRESS_JACKET  : 58933,
    MENS_STATE_COMMANDERS_DRESS_PANTS  : 58951,
    MENS_STATE_MILITARY_STOCKPILE_JACKET  : 46672,
    MENS_STATE_PROTECTOR_BERET  : 55756,
    MENS_STATE_PROTECTOR_JACKET  : 59405,
    MENS_STATE_PROTECTOR_PANTS  : 59406,
    MENS_STATE_STARGATE_CREW_PANTS  : 58792,
    MENS_STATE_STARGATE_CREW_SHOES  : 58826,
    MENS_STATE_STARGATE_CREW_TOP  : 58814,
    MENS_STEELKNIGHT_TORSORIG_BODY_AUGMENTATION  : 53038,
    MENS_STERLING_DRESS_SHIRT_BLACK  : 4057,
    MENS_STERLING_DRESS_SHIRT_DUST  : 4059,
    MENS_STERLING_DRESS_SHIRT_GOLD_LEATHER  : 4240,
    MENS_STERLING_DRESS_SHIRT_GRAY  : 4241,
    MENS_STERLING_DRESS_SHIRT_ISHUKONE_SPECIAL_EDITION  : 4068,
    MENS_STERLING_DRESS_SHIRT_NAVY  : 4058,
    MENS_STERLING_DRESS_SHIRT_OLIVE  : 4060,
    MENS_STERLING_DRESS_SHIRT_RED_BLACK_LEATHER  : 4242,
    MENS_STERLING_DRESS_SHIRT_WHITE  : 4367,
    MENS_STERLING_DRESS_SHIRT_WHITE_BLUE  : 4243,
    MENS_STREET_SHIRT_BLACK  : 4191,
    MENS_STREET_SHIRT_BLUE  : 4192,
    MENS_STREET_SHIRT_BROWN  : 4193,
    MENS_STREET_SHIRT_BROWN_CAMO  : 4197,
    MENS_STREET_SHIRT_GRAY  : 4194,
    MENS_STREET_SHIRT_GRAY_URBAN_CAMO  : 4196,
    MENS_STREET_SHIRT_GREEN  : 4195,
    MENS_STREET_SHIRT_GREEN_CAMO  : 4199,
    MENS_STREET_SHIRT_URBAN_CAMO  : 4198,
    MENS_STREET_SHIRT_WHITE  : 4200,
    MENS_STRIDER_PANTS_BLACK  : 33785,
    MENS_STRIDER_PANTS_DARK_RED  : 33784,
    MENS_STRIDER_PANTS_GRAPHITE  : 33783,
    MENS_STRIDER_PANTS_GREEN_CAMO  : 33782,
    MENS_STRIDER_PANTS_MONOCHROME  : 33781,
    MENS_SUKUUVESTAA_CREW_JACKET  : 70845,
    MENS_TAWNY_TREASURE_JACKET  : 54379,
    MENS_TAWNY_TREASURE_PANTS  : 54373,
    MENS_TECTONIC_HEADWEAR_MATTE_BLACK  : 34057,
    MENS_TECTONIC_HEADWEAR_METAL  : 34062,
    MENS_TECTONIC_HEADWEAR_SHINY_BLACK  : 34063,
    MENS_TECTONIC_HEADWEAR_WHITE  : 34058,
    MENS_TRENCH_BOOTS  : 4001,
    MENS_TRENCH_BOOTS_BROWN  : 4177,
    MENS_TRENCH_BOOTS_GRAY  : 4178,
    MENS_TRENCH_BOOTS_TAN  : 4179,
    MENS_TRIGLAVIAN_SURVIVAL_SUIT_BIOADAPTIVE  : 48082,
    MENS_TRIGLAVIAN_SURVIVAL_SUIT_ENCOUNTER  : 48387,
    MENS_TRIGLAVIAN_SURVIVAL_SUIT_LIMINALITY  : 56226,
    MENS_TRIGLAVIAN_SURVIVAL_SUIT_PERUN  : 48385,
    MENS_TRIGLAVIAN_SURVIVAL_SUIT_PROVING_GROUNDS  : 55858,
    MENS_TRIGLAVIAN_SURVIVAL_SUIT_SVAROG  : 48384,
    MENS_TRIGLAVIAN_SURVIVAL_SUIT_VELES  : 48383,
    MENS_TWINSWITCH_FACIAL_AUGMENTATION  : 41045,
    MENS_VALKYRIE_FLIGHT_JACKET  : 46274,
    MENS_VERMILLION_DRAGON_FACIAL_AUGMENTATION  : 50050,
    MENS_VIRTUALITY_BIOTECHNICIAN_COAT  : 40464,
    MENS_WIREPOISE_FACIAL_AUGMENTATION  : 41043,
    MENS_WIYRKOMI_CREW_JACKET  : 70843,
    MENS_YC119_YOIUL_FESTIVAL_CAP  : 44125,
    MENS_YC119_YOIUL_FESTIVAL_PANTS  : 44124,
    MENS_YC119_YOIUL_FESTIVAL_T_SHIRT  : 44123,
    MENS_YOIUL_STORMCHASERS_CAP  : 57074,
    MENS_YOIUL_STORMCHASERS_PANTS  : 57075,
    MENS_YOIUL_STORMCHASERS_SUIT  : 57076,
    MENS_YOIUL_STORMCHASERS_T_SHIRT  : 57073,
    MENS_ZAKURA_ZAKITSU_EXPLORATION_SUIT  : 56856,
    MERCOXIT  : 11396,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_A_I  : 18054,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 18055,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_A_II  : 18608,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_B_I  : 60309,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 60373,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_B_II  : 60311,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_C_I  : 60310,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 60374,
    MERCOXIT_ASTEROID_MINING_CRYSTAL_TYPE_C_II  : 60312,
    MERCOXIT_ORE_PROCESSING  : 12189,
    MERCURY  : 16646,
    MERLIN  : 603,
    MERLIN_BIOSECURITY_RESPONDERS_SKIN  : 55530,
    MERLIN_BLUE_TIGER_SKIN  : 36710,
    MERLIN_BLUEPRINT  : 950,
    MERLIN_CALDARI_NAVY_SKIN  : 58875,
    MERLIN_EXOPLANETS_HUNTER_SKIN  : 45817,
    MERLIN_GHOSTBIRD_SKIN  : 48160,
    MERLIN_GLACIAL_DRIFT_SKIN  : 44153,
    MERLIN_GURISTAS_SKIN  : 40771,
    MERLIN_HARMONIOUS_ASCENSION_SKIN  : 53208,
    MERLIN_HEADHUNTER_SKIN  : 46712,
    MERLIN_HUNTERS_QUIVER_SKIN  : 47500,
    MERLIN_MATIGU_SEABEAST_SKIN  : 40584,
    MERLIN_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59220,
    MERLIN_NUGOEIHUVI_SKIN  : 34734,
    MERLIN_OPERATION_PERMAFROST_SKIN  : 49812,
    MERLIN_RAATA_SUNSET_SKIN  : 36638,
    MERLIN_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60216,
    MERLIN_STEEL_CARDINAL_SKIN  : 46094,
    MERLIN_SUKUUVESTAA_SKIN  : 44203,
    MERLIN_VALKYRIE_SKIN  : 41420,
    MERLIN_WIYRKOMI_SKIN  : 34735,
    MERLIN_ZAKURA_SHUMYU_SKIN  : 57052,
    MERLIN_ZENTO_ISIDEKO_COMBINE_SKIN  : 53154,
    MESMERFLUX_CONDENSER_PACK_L  : 54778,
    MESMERFLUX_CONDENSER_PACK_M  : 54774,
    MESMERFLUX_CONDENSER_PACK_S  : 54770,
    MESON_EXOTIC_PLASMA_L  : 47933,
    MESON_EXOTIC_PLASMA_M  : 47929,
    MESON_EXOTIC_PLASMA_S  : 47925,
    MESON_EXOTIC_PLASMA_XL  : 53302,
    META_MOLECULAR_COMBINER  : 57443,
    META_OPERANT_NEUROLINK_ENHANCER  : 57458,
    META_OPERANT_NEUROLINK_ENHANCER_REACTION_FORMULA  : 57495,
    METAL_SCRAPS  : 15331,
    METALLOFULLERENE_PLATING  : 30464,
    METALLOFULLERENE_PLATING_BLUEPRINT  : 30465,
    METALLURGY  : 3409,
    METAMORPHOSIS  : 77114,
    METHANOFULLERENE  : 30306,
    METHANOFULLERENE_REACTION_FORMULA  : 46157,
    MEXALLON  : 36,
    MIASMOS  : 656,
    MIASMOS_AMASTRIS_EDITION  : 32811,
    MIASMOS_BIOSECURITY_RESPONDERS_SKIN  : 55599,
    MIASMOS_BLUEPRINT  : 991,
    MIASMOS_EXOPLANETS_HUNTER_SKIN  : 45925,
    MIASMOS_GLACIAL_DRIFT_SKIN  : 46971,
    MIASMOS_INNER_ZONE_VANGUARD_SKIN  : 52760,
    MIASMOS_KOPIS_EDGE_SKIN  : 46437,
    MIASMOS_QUAFE_ULTRA_EDITION  : 4363,
    MIASMOS_QUAFE_ULTRAMARINE_EDITION  : 4388,
    MIASMOS_RUBY_SUNGRAZER_SKIN  : 45577,
    MIASMOS_SERPENTIS_SKIN  : 42194,
    MIASMOS_SPIRIT_SKIN  : 44943,
    MIASMOS_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 64393,
    MIASMOS_VALIMOR_LEGACY_SKIN  : 37590,
    MICHIS_EXCAVATION_AUGMENTOR  : 20700,
    MICRO_AUXILIARY_POWER_CORE_I  : 11563,
    MICRO_AUXILIARY_POWER_CORE_I_BLUEPRINT  : 11564,
    MICRO_AUXILIARY_POWER_CORE_II  : 4254,
    MICRO_CAP_BATTERY  : 522,
    MICRO_CIRCUIT  : 25618,
    MICRO_DEGENERATIVE_CONCUSSION_BOMB_I  : 21532,
    MICRO_EMP_SMARTBOMB_I  : 3913,
    MICRO_EMP_SMARTBOMB_II  : 3915,
    MICRO_GRAVITON_SMARTBOMB_I  : 3901,
    MICRO_GRAVITON_SMARTBOMB_II  : 3903,
    MICRO_JUMP_DRIVE_OPERATION  : 4385,
    MICRO_JUMP_FIELD_GENERATOR  : 37479,
    MICRO_JUMP_FIELD_GENERATOR_BLUEPRINT  : 37621,
    MICRO_NOTOS_EXPLOSIVE_CHARGE_I  : 9750,
    MICRO_PLASMA_SMARTBOMB_I  : 3907,
    MICRO_PLASMA_SMARTBOMB_II  : 3909,
    MICRO_PROTON_SMARTBOMB_I  : 3897,
    MICRO_PROTON_SMARTBOMB_II  : 3899,
    MICRO_REMOTE_SHIELD_BOOSTER  : 405,
    MICRO_RUDIMENTARY_CONCUSSION_BOMB_I  : 9702,
    MICRO_VEHEMENCE_SHOCKWAVE_CHARGE  : 9706,
    MICRO_YF_12A_SMARTBOMB  : 9790,
    MICROFIBER_SHIELDING  : 2327,
    MICROORGANISMS  : 2073,
    MICROWAVE_L  : 256,
    MICROWAVE_L_BLUEPRINT  : 1171,
    MICROWAVE_M  : 248,
    MICROWAVE_M_BLUEPRINT  : 1163,
    MICROWAVE_S  : 240,
    MICROWAVE_S_BLUEPRINT  : 1155,
    MICROWAVE_XL  : 17684,
    MICROWAVE_XL_BLUEPRINT  : 17685,
    MID_GRADE_AMULET_ALPHA  : 22119,
    MID_GRADE_AMULET_BETA  : 22120,
    MID_GRADE_AMULET_DELTA  : 22121,
    MID_GRADE_AMULET_EPSILON  : 22122,
    MID_GRADE_AMULET_GAMMA  : 22123,
    MID_GRADE_AMULET_OMEGA  : 22124,
    MID_GRADE_ASCENDANCY_ALPHA  : 33555,
    MID_GRADE_ASCENDANCY_BETA  : 33557,
    MID_GRADE_ASCENDANCY_DELTA  : 33559,
    MID_GRADE_ASCENDANCY_EPSILON  : 33561,
    MID_GRADE_ASCENDANCY_GAMMA  : 33563,
    MID_GRADE_ASCENDANCY_OMEGA  : 33565,
    MID_GRADE_ASKLEPIAN_ALPHA  : 42204,
    MID_GRADE_ASKLEPIAN_BETA  : 42205,
    MID_GRADE_ASKLEPIAN_DELTA  : 42207,
    MID_GRADE_ASKLEPIAN_EPSILON  : 42208,
    MID_GRADE_ASKLEPIAN_GAMMA  : 42206,
    MID_GRADE_ASKLEPIAN_OMEGA  : 42209,
    MID_GRADE_CENTURION_ALPHA  : 28790,
    MID_GRADE_CENTURION_BETA  : 28791,
    MID_GRADE_CENTURION_DELTA  : 28792,
    MID_GRADE_CENTURION_EPSILON  : 28793,
    MID_GRADE_CENTURION_GAMMA  : 28794,
    MID_GRADE_CENTURION_OMEGA  : 28795,
    MID_GRADE_CRYSTAL_ALPHA  : 22107,
    MID_GRADE_CRYSTAL_BETA  : 22108,
    MID_GRADE_CRYSTAL_DELTA  : 22109,
    MID_GRADE_CRYSTAL_EPSILON  : 22110,
    MID_GRADE_CRYSTAL_GAMMA  : 22111,
    MID_GRADE_CRYSTAL_OMEGA  : 22112,
    MID_GRADE_EDGE_ALPHA  : 28814,
    MID_GRADE_EDGE_BETA  : 28815,
    MID_GRADE_EDGE_DELTA  : 28816,
    MID_GRADE_EDGE_EPSILON  : 28817,
    MID_GRADE_EDGE_GAMMA  : 28818,
    MID_GRADE_EDGE_OMEGA  : 28819,
    MID_GRADE_HALO_ALPHA  : 22113,
    MID_GRADE_HALO_BETA  : 22114,
    MID_GRADE_HALO_DELTA  : 22115,
    MID_GRADE_HALO_EPSILON  : 22116,
    MID_GRADE_HALO_GAMMA  : 22117,
    MID_GRADE_HALO_OMEGA  : 22118,
    MID_GRADE_HARVEST_ALPHA  : 28802,
    MID_GRADE_HARVEST_BETA  : 28803,
    MID_GRADE_HARVEST_DELTA  : 28804,
    MID_GRADE_HARVEST_EPSILON  : 28805,
    MID_GRADE_HARVEST_GAMMA  : 28806,
    MID_GRADE_HARVEST_OMEGA  : 28807,
    MID_GRADE_HYDRA_ALPHA  : 54398,
    MID_GRADE_HYDRA_BETA  : 54399,
    MID_GRADE_HYDRA_DELTA  : 54401,
    MID_GRADE_HYDRA_EPSILON  : 54402,
    MID_GRADE_HYDRA_GAMMA  : 54400,
    MID_GRADE_HYDRA_OMEGA  : 54403,
    MID_GRADE_MIMESIS_ALPHA  : 52785,
    MID_GRADE_MIMESIS_BETA  : 52786,
    MID_GRADE_MIMESIS_DELTA  : 52788,
    MID_GRADE_MIMESIS_EPSILON  : 52789,
    MID_GRADE_MIMESIS_GAMMA  : 52787,
    MID_GRADE_MIMESIS_OMEGA  : 52790,
    MID_GRADE_NIRVANA_ALPHA  : 53704,
    MID_GRADE_NIRVANA_BETA  : 53705,
    MID_GRADE_NIRVANA_DELTA  : 53706,
    MID_GRADE_NIRVANA_EPSILON  : 53707,
    MID_GRADE_NIRVANA_GAMMA  : 53708,
    MID_GRADE_NIRVANA_OMEGA  : 53709,
    MID_GRADE_NOMAD_ALPHA  : 28796,
    MID_GRADE_NOMAD_BETA  : 28797,
    MID_GRADE_NOMAD_DELTA  : 28798,
    MID_GRADE_NOMAD_EPSILON  : 28799,
    MID_GRADE_NOMAD_GAMMA  : 28800,
    MID_GRADE_NOMAD_OMEGA  : 28801,
    MID_GRADE_RAPTURE_ALPHA  : 57117,
    MID_GRADE_RAPTURE_BETA  : 57118,
    MID_GRADE_RAPTURE_DELTA  : 57120,
    MID_GRADE_RAPTURE_EPSILON  : 57121,
    MID_GRADE_RAPTURE_GAMMA  : 57119,
    MID_GRADE_RAPTURE_OMEGA  : 57122,
    MID_GRADE_SAVIOR_ALPHA  : 53896,
    MID_GRADE_SAVIOR_BETA  : 53897,
    MID_GRADE_SAVIOR_DELTA  : 53899,
    MID_GRADE_SAVIOR_EPSILON  : 53900,
    MID_GRADE_SAVIOR_GAMMA  : 53898,
    MID_GRADE_SAVIOR_OMEGA  : 53901,
    MID_GRADE_SNAKE_ALPHA  : 22125,
    MID_GRADE_SNAKE_BETA  : 22126,
    MID_GRADE_SNAKE_DELTA  : 22127,
    MID_GRADE_SNAKE_EPSILON  : 22128,
    MID_GRADE_SNAKE_GAMMA  : 22129,
    MID_GRADE_SNAKE_OMEGA  : 22130,
    MID_GRADE_TALISMAN_ALPHA  : 22131,
    MID_GRADE_TALISMAN_BETA  : 22133,
    MID_GRADE_TALISMAN_DELTA  : 22134,
    MID_GRADE_TALISMAN_EPSILON  : 22135,
    MID_GRADE_TALISMAN_GAMMA  : 22136,
    MID_GRADE_TALISMAN_OMEGA  : 22137,
    MID_GRADE_VIRTUE_ALPHA  : 28808,
    MID_GRADE_VIRTUE_BETA  : 28809,
    MID_GRADE_VIRTUE_DELTA  : 28810,
    MID_GRADE_VIRTUE_EPSILON  : 28811,
    MID_GRADE_VIRTUE_GAMMA  : 28812,
    MID_GRADE_VIRTUE_OMEGA  : 28813,
    MILITARY_EXPERTS_AND_YOU  : 33025,
    MILITARY_NAVIGATION_TELEMETRY  : 74001,
    MIMIR  : 32209,
    MINDFLOOD  : 3826,
    MINEDRILL_STRONG_BOX  : 43687,
    MINER_I  : 483,
    MINER_I_BLUEPRINT  : 785,
    MINER_II  : 482,
    MINIATURE_ELECTRONICS  : 9842,
    MINING  : 3386,
    MINING_BARGE  : 17940,
    MINING_CONNECTIONS  : 3893,
    MINING_DIRECTOR  : 22552,
    MINING_DRONE_I  : 10246,
    MINING_DRONE_I_BLUEPRINT  : 10247,
    MINING_DRONE_II  : 10250,
    MINING_DRONE_OPERATION  : 3438,
    MINING_DRONE_SPECIALIZATION  : 22541,
    MINING_EQUIPMENT_PRESERVATION_CHARGE  : 42831,
    MINING_EQUIPMENT_PRESERVATION_CHARGE_BLUEPRINT  : 42880,
    MINING_FOREMAN  : 22536,
    MINING_FOREMAN_BURST_I  : 42528,
    MINING_FOREMAN_BURST_I_BLUEPRINT  : 43544,
    MINING_FOREMAN_BURST_II  : 43551,
    MINING_FOREMAN_MINDLINK  : 22559,
    MINING_FRIGATE  : 32918,
    MINING_LASER_FIELD_ENHANCEMENT_CHARGE  : 42829,
    MINING_LASER_FIELD_ENHANCEMENT_CHARGE_BLUEPRINT  : 42881,
    MINING_LASER_OPTIMIZATION_CHARGE  : 42830,
    MINING_LASER_OPTIMIZATION_CHARGE_BLUEPRINT  : 42882,
    MINING_LASER_UPGRADE_I  : 22542,
    MINING_LASER_UPGRADE_I_BLUEPRINT  : 22543,
    MINING_LASER_UPGRADE_II  : 28576,
    MINING_UPGRADES  : 22578,
    MINMATAR_BATTLECRUISER  : 33098,
    MINMATAR_BATTLESHIP  : 3337,
    MINMATAR_CARRIER  : 24314,
    MINMATAR_CONTROL_TOWER  : 16214,
    MINMATAR_CONTROL_TOWER_BLUEPRINT  : 2783,
    MINMATAR_CONTROL_TOWER_MEDIUM  : 20065,
    MINMATAR_CONTROL_TOWER_MEDIUM_BLUEPRINT  : 2784,
    MINMATAR_CONTROL_TOWER_SMALL  : 20066,
    MINMATAR_CONTROL_TOWER_SMALL_BLUEPRINT  : 2785,
    MINMATAR_CORE_SYSTEMS  : 30547,
    MINMATAR_CRUISER  : 3333,
    MINMATAR_DECODER_PACKAGE  : 73398,
    MINMATAR_DEFENSIVE_SYSTEMS  : 30545,
    MINMATAR_DESTROYER  : 33094,
    MINMATAR_DREADNOUGHT  : 20532,
    MINMATAR_DRONE_SPECIALIZATION  : 12485,
    MINMATAR_ENCODER_SPLICE  : 73402,
    MINMATAR_ENCRYPTION_METHODS  : 21791,
    MINMATAR_FORCE_AUXILIARY  : 40538,
    MINMATAR_FREEDOM_FIGHTER_INSIGNIA_I  : 15674,
    MINMATAR_FREIGHTER  : 20528,
    MINMATAR_FRIGATE  : 3329,
    MINMATAR_HAULER  : 3341,
    MINMATAR_INCEPTION_PACKAGE  : 43933,
    MINMATAR_LISTENING_OUTPOST  : 73271,
    MINMATAR_LOYALTY_TOKEN  : 73251,
    MINMATAR_MINING_CORPORATION_STRONG_BOX  : 43691,
    MINMATAR_OFFENSIVE_SYSTEMS  : 30551,
    MINMATAR_PHENOMENA_GENERATOR  : 43664,
    MINMATAR_PHENOMENA_GENERATOR_BLUEPRINT  : 43665,
    MINMATAR_PROPAGANDA_BROADCAST_STRUCTURE  : 73226,
    MINMATAR_PROPULSION_SYSTEMS  : 30554,
    MINMATAR_REPUBLIC_STARBASE_CHARTER  : 24595,
    MINMATAR_SHUTTLE  : 11132,
    MINMATAR_SHUTTLE_BIOSECURITY_RESPONDERS_SKIN  : 55641,
    MINMATAR_SHUTTLE_BLUEPRINT  : 11133,
    MINMATAR_SHUTTLE_EXOPLANETS_HUNTER_SKIN  : 45931,
    MINMATAR_SHUTTLE_GLACIAL_DRIFT_SKIN  : 46879,
    MINMATAR_SHUTTLE_LIBERATION_GAMES_SKIN  : 59475,
    MINMATAR_SHUTTLE_NEFANTAR_SKIN  : 55816,
    MINMATAR_SPACESHIP_SKILL_ACCELERATOR  : 55806,
    MINMATAR_STARSHIP_ENGINEERING  : 11445,
    MINMATAR_STRATEGIC_CRUISER  : 30653,
    MINMATAR_TACTICAL_DESTROYER  : 34533,
    MINMATAR_TITAN  : 3345,
    MINMATAR_UUA_NEXUS_CHIP  : 17815,
    MINMATAR_UUB_NEXUS_CHIP  : 17816,
    MINMATAR_UUC_NEXUS_CHIP  : 17814,
    MINMATAR_VICTORY_SKIN  : 75911,
    MINOKAWA  : 37605,
    MINOKAWA_BIOSECURITY_RESPONDERS_SKIN  : 55537,
    MINOKAWA_BLUE_TIGER_SKIN  : 41682,
    MINOKAWA_BLUEPRINT  : 41583,
    MINOKAWA_CAPSULEER_ELITE_XV_SKIN  : 47642,
    MINOKAWA_CAPSULEER_ELITE_XX_SKIN  : 77164,
    MINOKAWA_EXOPLANETS_HUNTER_SKIN  : 46147,
    MINOKAWA_FRONTIER_SAFEGUARDER_SKIN  : 54440,
    MINOKAWA_GHOSTBIRD_SKIN  : 48212,
    MINOKAWA_GLACIAL_DRIFT_SKIN  : 47050,
    MINOKAWA_KIMOTORO_ESKEITAN_SKIN  : 52641,
    MINOKAWA_MATIGU_SEABEAST_SKIN  : 41683,
    MINOKAWA_RAATA_SUNSET_SKIN  : 41681,
    MINOKAWA_STEEL_CARDINAL_SKIN  : 46276,
    MINOKAWA_YOIUL_FESTIVAL_YC121_SKIN  : 49954,
    MINOKAWA_YOIUL_STAR_SKIN  : 57159,
    MINOKAWA_ZENTO_ISIDEKO_COMBINE_SKIN  : 53206,
    MISSILE_BOMBARDMENT  : 12441,
    MISSILE_GUIDANCE_COMPUTER_I  : 35788,
    MISSILE_GUIDANCE_COMPUTER_I_BLUEPRINT  : 35791,
    MISSILE_GUIDANCE_COMPUTER_II  : 35790,
    MISSILE_GUIDANCE_ENHANCER_I  : 35770,
    MISSILE_GUIDANCE_ENHANCER_I_BLUEPRINT  : 35772,
    MISSILE_GUIDANCE_ENHANCER_II  : 35771,
    MISSILE_LAUNCHER_OPERATION  : 3319,
    MISSILE_PRECISION_DISRUPTION_SCRIPT  : 40335,
    MISSILE_PRECISION_DISRUPTION_SCRIPT_BLUEPRINT  : 40337,
    MISSILE_PRECISION_SCRIPT  : 35795,
    MISSILE_PRECISION_SCRIPT_BLUEPRINT  : 35797,
    MISSILE_PROJECTION  : 12442,
    MISSILE_RANGE_DISRUPTION_SCRIPT  : 40334,
    MISSILE_RANGE_DISRUPTION_SCRIPT_BLUEPRINT  : 40336,
    MISSILE_RANGE_SCRIPT  : 35794,
    MISSILE_RANGE_SCRIPT_BLUEPRINT  : 35796,
    MIZUMA_GOMIS_INSIGNIA  : 15659,
    MIZURO_CYBONS_TAG  : 17306,
    MIZUROS_MODIFIED_100MN_AFTERBURNER  : 14484,
    MIZUROS_MODIFIED_500MN_MICROWARPDRIVE  : 14492,
    MIZUROS_MODIFIED_800MM_REPEATING_CANNON  : 14457,
    MIZUROS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14530,
    MIZUROS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14516,
    MIZUROS_MODIFIED_DUAL_425MM_AUTOCANNON  : 14469,
    MIZUROS_MODIFIED_DUAL_650MM_REPEATING_CANNON  : 14473,
    MIZUROS_MODIFIED_EM_COATING  : 14580,
    MIZUROS_MODIFIED_EXPLOSIVE_COATING  : 14572,
    MIZUROS_MODIFIED_GYROSTABILIZER  : 14536,
    MIZUROS_MODIFIED_HEAVY_WARP_DISRUPTOR  : 14656,
    MIZUROS_MODIFIED_HEAVY_WARP_SCRAMBLER  : 14664,
    MIZUROS_MODIFIED_KINETIC_COATING  : 14564,
    MIZUROS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14552,
    MIZUROS_MODIFIED_LARGE_PROTON_SMARTBOMB  : 14544,
    MIZUROS_MODIFIED_MULTISPECTRUM_COATING  : 14556,
    MIZUROS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33458,
    MIZUROS_MODIFIED_STASIS_WEBIFIER  : 14648,
    MIZUROS_MODIFIED_THERMAL_COATING  : 14588,
    MIZUROS_MODIFIED_TORPEDO_LAUNCHER  : 14524,
    MIZUROS_MODIFIED_TRACKING_ENHANCER  : 14640,
    MJOLNIR_AUTO_TARGETING_CRUISE_MISSILE_I  : 1828,
    MJOLNIR_AUTO_TARGETING_CRUISE_MISSILE_I_BLUEPRINT  : 1829,
    MJOLNIR_AUTO_TARGETING_HEAVY_MISSILE_I  : 1820,
    MJOLNIR_AUTO_TARGETING_HEAVY_MISSILE_I_BLUEPRINT  : 1821,
    MJOLNIR_AUTO_TARGETING_LIGHT_MISSILE_I  : 269,
    MJOLNIR_AUTO_TARGETING_LIGHT_MISSILE_I_BLUEPRINT  : 1216,
    MJOLNIR_CRUISE_MISSILE  : 202,
    MJOLNIR_CRUISE_MISSILE_BLUEPRINT  : 803,
    MJOLNIR_FURY_CRUISE_MISSILE  : 24535,
    MJOLNIR_FURY_HEAVY_MISSILE  : 24509,
    MJOLNIR_FURY_LIGHT_MISSILE  : 2613,
    MJOLNIR_FURY_XL_CRUISE_MISSILE  : 41299,
    MJOLNIR_HEAVY_ASSAULT_MISSILE  : 20306,
    MJOLNIR_HEAVY_ASSAULT_MISSILE_BLUEPRINT  : 26760,
    MJOLNIR_HEAVY_MISSILE  : 207,
    MJOLNIR_HEAVY_MISSILE_BLUEPRINT  : 808,
    MJOLNIR_JAVELIN_HEAVY_ASSAULT_MISSILE  : 24493,
    MJOLNIR_JAVELIN_ROCKET  : 13119,
    MJOLNIR_JAVELIN_TORPEDO  : 24527,
    MJOLNIR_JAVELIN_XL_TORPEDO  : 41274,
    MJOLNIR_LIGHT_MISSILE  : 212,
    MJOLNIR_LIGHT_MISSILE_BLUEPRINT  : 813,
    MJOLNIR_PRECISION_CRUISE_MISSILE  : 24539,
    MJOLNIR_PRECISION_HEAVY_MISSILE  : 24517,
    MJOLNIR_PRECISION_LIGHT_MISSILE  : 24505,
    MJOLNIR_PRECISION_XL_CRUISE_MISSILE  : 41298,
    MJOLNIR_RAGE_HEAVY_ASSAULT_MISSILE  : 24490,
    MJOLNIR_RAGE_ROCKET  : 2817,
    MJOLNIR_RAGE_TORPEDO  : 24523,
    MJOLNIR_RAGE_XL_TORPEDO  : 41275,
    MJOLNIR_ROCKET  : 2512,
    MJOLNIR_ROCKET_BLUEPRINT  : 2513,
    MJOLNIR_TORPEDO  : 2506,
    MJOLNIR_TORPEDO_BLUEPRINT  : 2507,
    MJOLNIR_XL_CRUISE_MISSILE  : 32442,
    MJOLNIR_XL_CRUISE_MISSILE_BLUEPRINT  : 32443,
    MJOLNIR_XL_TORPEDO  : 17857,
    MJOLNIR_XL_TORPEDO_BLUEPRINT  : 17858,
    ML_3_SCOPED_SURVEY_SCANNER  : 6569,
    ML_EKP_POLYBOLOS_BALLISTIC_CONTROL_SYSTEM  : 49790,
    ML_EKP_POLYBOLOS_BALLISTIC_CONTROL_SYSTEM_BLUEPRINT  : 49971,
    ML_EKP_POLYBOLOS_BALLISTIC_CONTROL_SYSTEM_BLUEPRINT_CRATE  : 49974,
    MOA  : 623,
    MOA_AURORA_UNIVERSALIS_SKIN  : 53371,
    MOA_BIOSECURITY_RESPONDERS_SKIN  : 55538,
    MOA_BLUE_TIGER_SKIN  : 36723,
    MOA_BLUEPRINT  : 968,
    MOA_CALDARI_NAVY_SKIN  : 58878,
    MOA_CAPSULEER_DAY_XVII_SKIN  : 54764,
    MOA_EXOPLANETS_HUNTER_SKIN  : 45835,
    MOA_FORWARD_4_4_SKIN  : 56863,
    MOA_GHOSTBIRD_SKIN  : 48178,
    MOA_GLACIAL_DRIFT_SKIN  : 44159,
    MOA_GURISTAS_SKIN  : 40772,
    MOA_KIMOTORO_ESKEITAN_SKIN  : 52633,
    MOA_LAI_DAI_SKIN  : 34687,
    MOA_MATIGU_SEABEAST_SKIN  : 40599,
    MOA_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59218,
    MOA_PERMABAND_ROADIES_SKIN  : 54445,
    MOA_RAATA_SUNSET_SKIN  : 36651,
    MOA_STEEL_CARDINAL_SKIN  : 46112,
    MOA_VALKYRIE_SKIN  : 41421,
    MOA_VERSUS_BLUEFORCE_SKIN  : 62014,
    MOA_WARP_CONVERGENCE_SKIN  : 61866,
    MOA_ZENTO_ISIDEKO_COMBINE_SKIN  : 53172,
    MOBILE_CYNOSURAL_BEACON  : 57319,
    MOBILE_CYNOSURAL_BEACON_BLUEPRINT  : 57429,
    MOBILE_CYNOSURAL_INHIBITOR  : 33476,
    MOBILE_CYNOSURAL_INHIBITOR_BLUEPRINT  : 33518,
    MOBILE_DEPOT  : 33474,
    MOBILE_DEPOT_BLUEPRINT  : 33517,
    MOBILE_LARGE_JUMP_DISRUPTOR_I  : 4386,
    MOBILE_LARGE_WARP_DISRUPTOR_I  : 12200,
    MOBILE_LARGE_WARP_DISRUPTOR_I_BLUEPRINT  : 12301,
    MOBILE_LARGE_WARP_DISRUPTOR_II  : 26888,
    MOBILE_MEDIUM_WARP_DISRUPTOR_I  : 12199,
    MOBILE_MEDIUM_WARP_DISRUPTOR_I_BLUEPRINT  : 12300,
    MOBILE_MEDIUM_WARP_DISRUPTOR_II  : 26890,
    MOBILE_MICRO_JUMP_UNIT  : 33591,
    MOBILE_MICRO_JUMP_UNIT_BLUEPRINT  : 33592,
    MOBILE_OBSERVATORY  : 58904,
    MOBILE_OBSERVATORY_BLUEPRINT  : 58905,
    MOBILE_SANGUINE_HARVESTER  : 56701,
    MOBILE_SCAN_INHIBITOR  : 33589,
    MOBILE_SCAN_INHIBITOR_BLUEPRINT  : 33590,
    MOBILE_SMALL_WARP_DISRUPTOR_I  : 12198,
    MOBILE_SMALL_WARP_DISRUPTOR_I_BLUEPRINT  : 12297,
    MOBILE_SMALL_WARP_DISRUPTOR_II  : 26892,
    MOBILE_TRACTOR_UNIT  : 33475,
    MOBILE_TRACTOR_UNIT_BLUEPRINT  : 33519,
    MODAL_ELECTRON_PARTICLE_ACCELERATOR_I  : 7619,
    MODAL_ENDURING_DUAL_GIGA_BEAM_LASER  : 41115,
    MODAL_ENDURING_DUAL_GIGA_BEAM_LASER_BLUEPRINT  : 41121,
    MODAL_ENDURING_DUAL_GIGA_PULSE_LASER  : 41100,
    MODAL_ENDURING_DUAL_GIGA_PULSE_LASER_BLUEPRINT  : 41106,
    MODAL_ENDURING_ION_SIEGE_BLASTER  : 41127,
    MODAL_ENDURING_ION_SIEGE_BLASTER_BLUEPRINT  : 41133,
    MODAL_ENDURING_QUAD_MEGA_PULSE_LASER  : 41063,
    MODAL_ENDURING_QUAD_MEGA_PULSE_LASER_BLUEPRINT  : 41070,
    MODAL_ENDURING_TRIPLE_NEUTRON_BLASTER_CANNON  : 41076,
    MODAL_ENDURING_TRIPLE_NEUTRON_BLASTER_CANNON_BLUEPRINT  : 41087,
    MODAL_ION_PARTICLE_ACCELERATOR_I  : 7663,
    MODAL_LIGHT_ELECTRON_PARTICLE_ACCELERATOR_I  : 7487,
    MODAL_LIGHT_ION_PARTICLE_ACCELERATOR_I  : 7535,
    MODAL_LIGHT_NEUTRON_PARTICLE_ACCELERATOR_I  : 7579,
    MODAL_MEGA_ELECTRON_PARTICLE_ACCELERATOR_I  : 7743,
    MODAL_MEGA_ION_PARTICLE_ACCELERATOR_I  : 7827,
    MODAL_MEGA_NEUTRON_PARTICLE_ACCELERATOR_I  : 7783,
    MODAL_NEUTRON_PARTICLE_ACCELERATOR_I  : 7703,
    MODEL_OF_A_FIGHTER  : 33030,
    MODIFIED_FLUID_ROUTER  : 30021,
    MODULAR_SYSTEMS_HOLOGRAM  : 56714,
    MODULATED_COMPACT_DUAL_GIGA_BEAM_LASER  : 41114,
    MODULATED_COMPACT_DUAL_GIGA_BEAM_LASER_BLUEPRINT  : 41120,
    MODULATED_COMPACT_DUAL_GIGA_PULSE_LASER  : 41099,
    MODULATED_COMPACT_DUAL_GIGA_PULSE_LASER_BLUEPRINT  : 41105,
    MODULATED_COMPACT_QUAD_MEGA_PULSE_LASER  : 37297,
    MODULATED_COMPACT_QUAD_MEGA_PULSE_LASER_BLUEPRINT  : 41069,
    MODULATED_DEEP_CORE_MINER_II  : 18068,
    MODULATED_DEEP_CORE_STRIP_MINER_II  : 24305,
    MODULATED_STRIP_MINER_II  : 17912,
    MOLECULAR_ENGINEERING  : 11529,
    MOLOK  : 42241,
    MOLOK_GLACIAL_DRIFT_SKIN  : 46824,
    MONAZITE  : 45511,
    MONITOR  : 45534,
    MONITOR_FIREWATCH_SKIN  : 47684,
    MONITOR_SARO_BLACK_TROOP_SKIN  : 47682,
    MONOCLINIC_BISTOT  : 17429,
    MONOPOLY_MAGNETIC_FIELD_STABILIZER  : 22919,
    MOON_HARVESTING_ARRAY  : 16221,
    MOONSHINE_THERMAL_ENERGIZED_MEMBRANE  : 22881,
    MOONSHINE_YTIRIUM  : 74528,
    MORACHA  : 33395,
    MORDUNIUM  : 74521,
    MORDURS_DNA  : 17640,
    MOREAU_FORTIZAR  : 47512,
    MOROS  : 19724,
    MOROS_BIOSECURITY_RESPONDERS_SKIN  : 55592,
    MOROS_BLUEPRINT  : 19725,
    MOROS_EXOPLANETS_HUNTER_SKIN  : 45918,
    MOROS_GLACIAL_DRIFT_SKIN  : 46964,
    MOROS_INNER_ZONE_VANGUARD_SKIN  : 52753,
    MOROS_INTAKI_SYNDICATE_SKIN  : 36395,
    MOROS_INTERBUS_SKIN  : 34663,
    MOROS_KOPIS_EDGE_SKIN  : 46430,
    MOROS_NAVY_ISSUE  : 73792,
    MOROS_NAVY_ISSUE_HEARTSURGE_SKIN  : 76290,
    MOROS_RODEN_SKIN  : 34664,
    MOROS_RUBY_SUNGRAZER_SKIN  : 45572,
    MOROS_SAPPHIRE_SUNGRAZER_SKIN  : 46557,
    MOROS_SERPENTIS_SKIN  : 42187,
    MOROS_SPIRIT_SKIN  : 44936,
    MOROS_VALIMOR_LEGACY_SKIN  : 37583,
    MORPHEUS_ENDURING_MAGNETOMETRIC_ECM  : 19923,
    MORPHITE  : 11399,
    MOTION_PREDICTION  : 3312,
    MOTLEY_COMPOUND  : 11733,
    MOTTE_CAPACITOR_POWER_RELAY_I  : 23896,
    MULTI_SENSOR_BACKUP_ARRAY_I_BLUEPRINT  : 11832,
    MULTIFREQUENCY_L  : 262,
    MULTIFREQUENCY_L_BLUEPRINT  : 1177,
    MULTIFREQUENCY_M  : 254,
    MULTIFREQUENCY_M_BLUEPRINT  : 1169,
    MULTIFREQUENCY_S  : 246,
    MULTIFREQUENCY_S_BLUEPRINT  : 1161,
    MULTIFREQUENCY_XL  : 17686,
    MULTIFREQUENCY_XL_BLUEPRINT  : 17687,
    MULTIPLE_PILOT_TRAINING_CERTIFICATE  : 34133,
    MULTISPECTRAL_ECM_I  : 1957,
    MULTISPECTRAL_ECM_I_BLUEPRINT  : 11629,
    MULTISPECTRAL_ECM_II  : 2567,
    MULTISPECTRUM_COATING_I  : 1304,
    MULTISPECTRUM_COATING_I_BLUEPRINT  : 1305,
    MULTISPECTRUM_COATING_II  : 1306,
    MULTISPECTRUM_ENERGIZED_MEMBRANE_I  : 11267,
    MULTISPECTRUM_ENERGIZED_MEMBRANE_I_BLUEPRINT  : 11268,
    MULTISPECTRUM_ENERGIZED_MEMBRANE_II  : 11269,
    MULTISPECTRUM_SHIELD_HARDENER_I  : 578,
    MULTISPECTRUM_SHIELD_HARDENER_I_BLUEPRINT  : 1129,
    MULTISPECTRUM_SHIELD_HARDENER_II  : 2281,
    MUNINN  : 12015,
    MUNINN_ACE_OF_PODHUNTERS_SKIN  : 63210,
    MUNINN_BIOSECURITY_RESPONDERS_SKIN  : 55686,
    MUNINN_BLOODY_HANDS_SKIN  : 47162,
    MUNINN_BLUE_TIGER_SKIN  : 36818,
    MUNINN_BLUEPRINT  : 12016,
    MUNINN_DAWN_OF_LIBERATION_SKIN  : 48505,
    MUNINN_EXOPLANETS_HUNTER_SKIN  : 45958,
    MUNINN_FIREWALL_BREACH_SKIN  : 46019,
    MUNINN_FREEDOMS_REVOLUTION_SKIN  : 59314,
    MUNINN_GALNET_STREAMCAST_UNIT_SKIN  : 76496,
    MUNINN_GLACIAL_DRIFT_SKIN  : 46895,
    MUNINN_HAZARD_CONTROL_SKIN  : 56911,
    MUNINN_IN_RUST_WE_TRUST_SKIN  : 52427,
    MUNINN_JUSTICE_SKIN  : 36435,
    MUNINN_MALAGRIN_MARSHLIGHT_SKIN  : 60570,
    MUNINN_REPUBLIC_FLEET_SKIN  : 58870,
    MUNINN_SNOWLINE_BLADERACER_SKIN  : 52869,
    MUNINN_TRONHADAR_INK_SKIN  : 40488,
    MUNINN_VALKLEAR_GLORY_SKIN  : 36771,
    MUNINN_YOIUL_STAR_SKIN  : 53432,
    MUTADAPTIVE_CONSTRUCTION_COMPONENT  : 76375,
    MUTATED_DRONE_SPECIALIZATION  : 60515,
    MY_GOD_ITS_FULL_OF_HOLES  : 34430,
    MYRMIDON  : 24700,
    MYRMIDON__TORNADO_ARGYROS_SKIN_CRATE  : 52293,
    MYRMIDON_ARGYROS_ELITE_XVI_SKIN  : 52283,
    MYRMIDON_AURORA_UNIVERSALIS_SKIN  : 53378,
    MYRMIDON_BIOSECURITY_RESPONDERS_SKIN  : 55582,
    MYRMIDON_BLUEPRINT  : 24701,
    MYRMIDON_CAPSULEER_DAY_XV_SKIN  : 47633,
    MYRMIDON_EXOPLANETS_HUNTER_SKIN  : 45906,
    MYRMIDON_GALNET_STREAMCAST_UNIT_SKIN  : 76490,
    MYRMIDON_GLACIAL_DRIFT_SKIN  : 46952,
    MYRMIDON_HUNTERS_QUIVER_SKIN  : 57532,
    MYRMIDON_INNER_ZONE_VANGUARD_SKIN  : 52741,
    MYRMIDON_INTAKI_SYNDICATE_SKIN  : 34614,
    MYRMIDON_INTERBUS_SKIN  : 34615,
    MYRMIDON_KOPIS_EDGE_SKIN  : 46418,
    MYRMIDON_NAVY_ISSUE  : 72869,
    MYRMIDON_NAVY_ISSUE_INNER_ZONE_VANGUARD_SKIN  : 73871,
    MYRMIDON_NAVY_ISSUE_VERSUS_REDFORCE_SKIN  : 73878,
    MYRMIDON_SERPENTIS_SKIN  : 42178,
    MYRMIDON_SPIRIT_SKIN  : 44924,
    MYRMIDON_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59426,
    MYRMIDON_VALIMOR_LEGACY_SKIN  : 37574,
    MYSTIC_L  : 47935,
    MYSTIC_M  : 47931,
    MYSTIC_S  : 47927,
    N_1_NEON_TYPE_ROCKET_BAY  : 21542,
    N_JM_COMPACT_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 41033,
    NAGA  : 4306,
    NAGA_AURORA_UNIVERSALIS_SKIN  : 57018,
    NAGA_BIOSECURITY_RESPONDERS_SKIN  : 55524,
    NAGA_BLUE_TIGER_SKIN  : 36733,
    NAGA_BLUEPRINT  : 4307,
    NAGA_CALDARI_NAVY_SKIN  : 58881,
    NAGA_CALDARI_UNION_DAY_SKIN  : 48730,
    NAGA_CAPSULEER_ELITE_XIX_SKIN  : 63686,
    NAGA_EXOPLANETS_HUNTER_SKIN  : 45848,
    NAGA_GHOSTBIRD_SKIN  : 48191,
    NAGA_GLACIAL_DRIFT_SKIN  : 46852,
    NAGA_LUMINAIRE_ZENITH_SKIN  : 48225,
    NAGA_MATIGU_SEABEAST_SKIN  : 40609,
    NAGA_RAATA_SUNSET_SKIN  : 36661,
    NAGA_SERENE_SONG_SKIN  : 49153,
    NAGA_STEEL_CARDINAL_SKIN  : 46125,
    NAGA_VALKYRIE_SKIN  : 46377,
    NAGA_WIYRKOMI_SKIN  : 34610,
    NAGA_ZENTO_ISIDEKO_COMBINE_SKIN  : 53185,
    NAGLFAR  : 19722,
    NAGLFAR_BIOSECURITY_RESPONDERS_SKIN  : 55664,
    NAGLFAR_BLUE_TIGER_SKIN  : 36834,
    NAGLFAR_BLUEPRINT  : 19723,
    NAGLFAR_CAPSULEER_ELITE_XX_SKIN  : 77170,
    NAGLFAR_CHAINBREAKER_KHUMAAK_SKIN  : 48523,
    NAGLFAR_EXOPLANETS_HUNTER_SKIN  : 45980,
    NAGLFAR_FIREWALL_BREACH_SKIN  : 46016,
    NAGLFAR_FLEET_ISSUE  : 73787,
    NAGLFAR_FLEET_ISSUE_HEARTSURGE_SKIN  : 76285,
    NAGLFAR_FREEDOMS_REVOLUTION_SKIN  : 59315,
    NAGLFAR_GLACIAL_DRIFT_SKIN  : 46917,
    NAGLFAR_HAZARD_CONTROL_SKIN  : 45558,
    NAGLFAR_IN_RUST_WE_TRUST_SKIN  : 52433,
    NAGLFAR_JUSTICE_SKIN  : 34665,
    NAGLFAR_MALAGRIN_MARSHLIGHT_SKIN  : 56600,
    NAGLFAR_SNOWLINE_BLADERACER_SKIN  : 52891,
    NAGLFAR_SPIRIT_SKIN  : 47320,
    NAGLFAR_TRONHADAR_INK_SKIN  : 40505,
    NAGLFAR_VALKLEAR_GLORY_SKIN  : 36787,
    NAGLFAR_YOIUL_STAR_SKIN  : 61128,
    NAIYON_TAIS_INSIGNIA  : 15658,
    NAIYONS_MODIFIED_MAGNETIC_FIELD_STABILIZER  : 15416,
    NANITE_COMPOUND  : 25609,
    NANITE_ENGINEERING  : 11442,
    NANITE_INTERFACING  : 28880,
    NANITE_OPERATION  : 28879,
    NANITE_REPAIR_PASTE  : 28668,
    NANITE_REPAIR_PASTE_BLUEPRINT  : 2739,
    NANITES  : 2463,
    NANO_FACTORY  : 2869,
    NANO_REGULATION_GATE  : 57449,
    NANOELECTRICAL_MICROPROCESSOR  : 11539,
    NANOELECTRICAL_MICROPROCESSOR_BLUEPRINT  : 17330,
    NANOFIBER_INTERNAL_STRUCTURE_I  : 2603,
    NANOFIBER_INTERNAL_STRUCTURE_I_BLUEPRINT  : 2604,
    NANOFIBER_INTERNAL_STRUCTURE_II  : 2605,
    NANOHEURISTIC_CLONE_MAPPER  : 56733,
    NANOMECHANICAL_MICROPROCESSOR  : 11538,
    NANOMECHANICAL_MICROPROCESSOR_BLUEPRINT  : 17325,
    NANOSCALE_FILTER_PLATE  : 57444,
    NANOTRANSISTORS  : 16681,
    NANOTRANSISTORS_REACTION_FORMULA  : 46211,
    NANOWIRE_COMPOSITES  : 30474,
    NANOWIRE_COMPOSITES_BLUEPRINT  : 30475,
    NATION_UPLIFTS_FIREWORK  : 44271,
    NATURA_WARP_CORE_STABILIZER_I  : 22877,
    NAUGHTY_PEOPLE_FIREWORK  : 44269,
    NAVIGATION  : 3449,
    NAVITAS  : 592,
    NAVITAS_BIOSECURITY_RESPONDERS_SKIN  : 55585,
    NAVITAS_BLUEPRINT  : 939,
    NAVITAS_COMBAT_MEDIC_SECTION_SKIN  : 60199,
    NAVITAS_EXOPLANETS_HUNTER_SKIN  : 45875,
    NAVITAS_GLACIAL_DRIFT_SKIN  : 44178,
    NAVITAS_INNER_ZONE_VANGUARD_SKIN  : 52710,
    NAVITAS_INTAKI_SYNDICATE_SKIN  : 34744,
    NAVITAS_KOPIS_EDGE_SKIN  : 46387,
    NAVITAS_SERPENTIS_SKIN  : 42151,
    NAVITAS_SPIRIT_SKIN  : 44893,
    NAVITAS_VALIMOR_LEGACY_SKIN  : 37551,
    NAVY_CAP_BOOSTER_100  : 31982,
    NAVY_CAP_BOOSTER_150  : 31990,
    NAVY_CAP_BOOSTER_200  : 31998,
    NAVY_CAP_BOOSTER_25  : 33330,
    NAVY_CAP_BOOSTER_3200  : 41490,
    NAVY_CAP_BOOSTER_400  : 32006,
    NAVY_CAP_BOOSTER_50  : 33332,
    NAVY_CAP_BOOSTER_75  : 33334,
    NAVY_CAP_BOOSTER_800  : 32014,
    NAVY_MICRO_AUXILIARY_POWER_CORE  : 31936,
    NEGOTIATION  : 3356,
    NEMESIS  : 11377,
    NEMESIS_AURORA_UNIVERSALIS_SKIN  : 60914,
    NEMESIS_BIOSECURITY_RESPONDERS_SKIN  : 55615,
    NEMESIS_BLUEPRINT  : 11378,
    NEMESIS_CAILLE_NEON_SKIN  : 50153,
    NEMESIS_EXOPLANETS_HUNTER_SKIN  : 45880,
    NEMESIS_GLACIAL_DRIFT_SKIN  : 46932,
    NEMESIS_INNER_ZONE_VANGUARD_SKIN  : 52715,
    NEMESIS_INTAKI_SYNDICATE_SKIN  : 36381,
    NEMESIS_KOPIS_EDGE_SKIN  : 46392,
    NEMESIS_LUMINAIRE_RISING_SKIN  : 77868,
    NEMESIS_MALAGRIN_MARSHLIGHT_SKIN  : 60567,
    NEMESIS_SERPENTIS_SKIN  : 42156,
    NEMESIS_SPIRIT_SKIN  : 44898,
    NEMESIS_VALIMOR_LEGACY_SKIN  : 37556,
    NEO_JADARITE  : 76374,
    NEO_MERCURITE  : 16667,
    NEO_MERCURITE_REACTION_FORMULA  : 46176,
    NEO_YC_114_8_CAS  : 33057,
    NEO_YC_114_AFRICAS_FINEST  : 33041,
    NEO_YC_114_ASINE_HITAMAS_TEAM  : 33048,
    NEO_YC_114_BAAARAMU  : 33047,
    NEO_YC_114_BLUE_BALLERS  : 33052,
    NEO_YC_114_DEEPWATER  : 33051,
    NEO_YC_114_EFS  : 33049,
    NEO_YC_114_EXPENDABLES  : 33036,
    NEO_YC_114_GOGGLE_WEARING_INTERNET_CRIME_FIGHTERS  : 33045,
    NEO_YC_114_GUIDING_HAND_SOCIAL_CLUB  : 33054,
    NEO_YC_114_ISN___INCURSION_SHINY_NETWORK  : 33046,
    NEO_YC_114_LAST_HUZZAH  : 33033,
    NEO_YC_114_MUCH_CRYING_OLD_EXPERTS  : 33050,
    NEO_YC_114_MY_LITTLE_NULLI  : 33056,
    NEO_YC_114_OXYGEN_ISONOPES  : 33040,
    NEO_YC_114_PERIHELION_BERYLLIUM_DURALUMIN  : 33044,
    NEO_YC_114_RAIDEN_58TH_SQUADRON  : 33032,
    NEO_YC_114_RONIN_AND_PIXIES  : 33035,
    NEO_YC_114_SOMETHING_ELSE  : 33042,
    NEO_YC_114_TEAM_INELUCTABLE  : 33031,
    NEO_YC_114_TENGU_TERROR  : 33039,
    NEO_YC_114_THE_EXILED_GAMING  : 33043,
    NEO_YC_114_THE_GENTLEMEN_RENEGADES  : 33053,
    NEO_YC_114_THE_HUNS  : 33037,
    NEO_YC_114_TINKERHELL_AND_ALTS  : 33038,
    NEO_YC_114_WHY_DASH  : 33034,
    NEO_YC_114_XXXMITY  : 33055,
    NEOCOMS  : 2354,
    NEODYMIUM  : 16651,
    NEOPHITE  : 15410,
    NEPHRITE  : 60771,
    NEREUS  : 650,
    NEREUS_BIOSECURITY_RESPONDERS_SKIN  : 55600,
    NEREUS_BLUEPRINT  : 985,
    NEREUS_EXOPLANETS_HUNTER_SKIN  : 45926,
    NEREUS_GLACIAL_DRIFT_SKIN  : 46972,
    NEREUS_INNER_ZONE_VANGUARD_SKIN  : 52761,
    NEREUS_KOPIS_EDGE_SKIN  : 46438,
    NEREUS_RUBY_SUNGRAZER_SKIN  : 45578,
    NEREUS_SERPENTIS_SKIN  : 42195,
    NEREUS_SPIRIT_SKIN  : 44944,
    NEREUS_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 64390,
    NEREUS_VALIMOR_LEGACY_SKIN  : 37591,
    NERGAL  : 52250,
    NERGAL_ALL_STARS_CASINO_CLASH_SKIN  : 53022,
    NERGAL_BLACKFIRE_STEEL_SKIN  : 53665,
    NERGAL_LIMINAL_CROSSINGS_SKIN  : 53315,
    NERGAL_METAMATERIA_EXOTICA_SKIN  : 54219,
    NERGAL_NIFLHEL_GILDCLAW_SKIN  : 52697,
    NERGAL_SINGULARITY_STORM_SKIN  : 52351,
    NERVE_STICKS  : 3703,
    NESOSILICATE_RAKOVENE  : 76373,
    NESTOR  : 33472,
    NESTOR_AIR_LABORATORIES_SKIN  : 60158,
    NESTOR_CRYSTAL_BLAST_SKIN  : 47251,
    NESTOR_EDENS_HUNTERS_SKIN  : 54904,
    NESTOR_FRIENDSHIP_LEAGUE_SKIN  : 60099,
    NESTOR_GLACIAL_DRIFT_SKIN  : 47015,
    NESTOR_KYBERNAUT_CLADE_SKIN  : 54913,
    NESTOR_ONE_EDEN_SKIN  : 47377,
    NESTOR_SANCTUARY_SKIN  : 35700,
    NESTOR_SOE_BLUE_CELL  : 45020,
    NESTOR_SOE_FIRE_CELL  : 44220,
    NESTOR_SOE_GOLD_CELL  : 45485,
    NESTOR_SPECTRAL_SHIFT_SKIN  : 46522,
    NESTOR_YOIUL_FESTIVAL_YC121_SKIN  : 49963,
    NESTOR_YOIUL_STAR_SKIN  : 53438,
    NETWORKED_SENSOR_ARRAY  : 41411,
    NETWORKED_SENSOR_ARRAY_BLUEPRINT  : 41412,
    NEURAL_BOOST___BASIC  : 9942,
    NEURAL_BOOST___IMPROVED  : 10213,
    NEURAL_BOOST___STANDARD  : 10212,
    NEURAL_LACE_BLACKGLASS_NET_INTRUSION_920_40  : 47028,
    NEURAL_NETWORK_ANALYZER  : 30744,
    NEURAL_SOURCE_BOOST  : 33808,
    NEUROLINK_ENHANCER_RESERVOIR  : 57483,
    NEUROLINK_ENHANCER_RESERVOIR_BLUEPRINT  : 57520,
    NEUROLINK_PROTECTION_CELL  : 57488,
    NEUROLINK_PROTECTION_CELL_BLUEPRINT  : 57525,
    NEUROTOXIN_CONTROL  : 25538,
    NEUROTOXIN_RECOVERY  : 25530,
    NEUROVISUAL_INPUT_MATRIX  : 30251,
    NEUROVISUAL_OUTPUT_ANALYZER  : 30470,
    NEUROVISUAL_OUTPUT_ANALYZER_BLUEPRINT  : 30471,
    NEUTRON_BLASTER_CANNON_I  : 573,
    NEUTRON_BLASTER_CANNON_I_BLUEPRINT  : 1124,
    NEUTRON_BLASTER_CANNON_II  : 3186,
    NEUTRON_FLOW_POLARITY_REVERSER  : 62054,
    NEW_EDEN_SOUNDBOX  : 33066,
    NEW_EDEN_SOURCE  : 33809,
    NIDHOGGUR  : 24483,
    NIDHOGGUR_BIOSECURITY_RESPONDERS_SKIN  : 55666,
    NIDHOGGUR_BLOODY_HANDS_SKIN  : 47177,
    NIDHOGGUR_BLUE_TIGER_SKIN  : 36835,
    NIDHOGGUR_BLUEPRINT  : 24484,
    NIDHOGGUR_CHAINBREAKER_KHUMAAK_SKIN  : 48524,
    NIDHOGGUR_EROS_BLOSSOM_SKIN  : 47292,
    NIDHOGGUR_EXOPLANETS_HUNTER_SKIN  : 45981,
    NIDHOGGUR_FIREWALL_BREACH_SKIN  : 46015,
    NIDHOGGUR_FREEDOMS_REVOLUTION_SKIN  : 55715,
    NIDHOGGUR_GLACIAL_DRIFT_SKIN  : 46918,
    NIDHOGGUR_HAZARD_CONTROL_SKIN  : 45555,
    NIDHOGGUR_HEADHUNTER_SKIN  : 49430,
    NIDHOGGUR_JUSTICE_SKIN  : 36451,
    NIDHOGGUR_KRUSUAL_SKIN  : 34657,
    NIDHOGGUR_MALAGRIN_MARSHLIGHT_SKIN  : 56601,
    NIDHOGGUR_SNOWLINE_BLADERACER_SKIN  : 52892,
    NIDHOGGUR_SPIRIT_SKIN  : 47321,
    NIDHOGGUR_TRONHADAR_INK_SKIN  : 40506,
    NIDHOGGUR_VALKLEAR_GLORY_SKIN  : 36788,
    NIDHOGGUR_YOIUL_STAR_SKIN  : 53435,
    NIGHTHAWK  : 22470,
    NIGHTHAWK_BIOSECURITY_RESPONDERS_SKIN  : 55559,
    NIGHTHAWK_BLUE_TIGER_SKIN  : 36734,
    NIGHTHAWK_BLUEPRINT  : 22471,
    NIGHTHAWK_EXOPLANETS_HUNTER_SKIN  : 45849,
    NIGHTHAWK_GHOSTBIRD_SKIN  : 48192,
    NIGHTHAWK_GLACIAL_DRIFT_SKIN  : 46853,
    NIGHTHAWK_MATIGU_SEABEAST_SKIN  : 40610,
    NIGHTHAWK_RAATA_SUNSET_SKIN  : 36662,
    NIGHTHAWK_STEEL_CARDINAL_SKIN  : 46126,
    NIGHTHAWK_UNION_DAY_EXECUTIVE_SKIN  : 71727,
    NIGHTHAWK_WIYRKOMI_SKIN  : 36358,
    NIGHTHAWK_ZENTO_ISIDEKO_COMBINE_SKIN  : 53186,
    NIGHTMARE  : 17736,
    NIGHTMARE_COPPER_LIGHTNING_SKIN  : 60763,
    NIGHTMARE_GHOSTS_HEX_SKIN  : 57809,
    NIGHTMARE_GLACIAL_DRIFT_SKIN  : 47008,
    NIGHTMARE_LUMINAE_POLARIS_SKIN  : 75267,
    NIGHTMARE_RED_STARGAZER_SKIN  : 75910,
    NIGHTMARE_TRUE_DELIVERANCE_SKIN  : 52596,
    NINAZU  : 37607,
    NINAZU_BIOSECURITY_RESPONDERS_SKIN  : 55602,
    NINAZU_BLUEPRINT  : 41584,
    NINAZU_CAPSULEER_ELITE_XV_SKIN  : 47644,
    NINAZU_EXOPLANETS_HUNTER_SKIN  : 46148,
    NINAZU_FEDERAL_POLICE_SKIN  : 52439,
    NINAZU_FRONTIER_SAFEGUARDER_SKIN  : 54443,
    NINAZU_GLACIAL_DRIFT_SKIN  : 47051,
    NINAZU_GLITTERING_DREAM_SKIN  : 47537,
    NINAZU_INNER_ZONE_VANGUARD_SKIN  : 52766,
    NINAZU_KOPIS_EDGE_SKIN  : 46443,
    NINAZU_VALIMOR_LEGACY_SKIN  : 41684,
    NITROGEN_FUEL_BLOCK  : 4051,
    NITROGEN_FUEL_BLOCK_BLUEPRINT  : 4314,
    NITROGEN_ISOTOPES  : 17888,
    NOBLE_DUCINIUM  : 74534,
    NOBLE_GAS  : 2310,
    NOBLE_METALS  : 2270,
    NOCTIS  : 2998,
    NOCTIS_BLUEPRINT  : 3039,
    NOCTIS_EDENS_HUNTERS_SKIN  : 54911,
    NOCTIS_GLACIAL_DRIFT_SKIN  : 46994,
    NOCTIS_KYBERNAUT_CLADE_SKIN  : 54920,
    NOCTIS_LODESTRIKE_SKIN  : 46743,
    NOCTIS_RADIOACTIVES_RECLAMATION_SKIN  : 60956,
    NOCTIS_ROCKBREAKER_PIONEERS_SKIN  : 56618,
    NOCTIS_ROSADA_DAWN_SKIN  : 43676,
    NOCXIUM  : 38,
    NOIR_MODIFIED_ENTOSIS_LINK  : 40307,
    NOISE_15_NEEDLEJACK_FILAMENT  : 53985,
    NOISE_25_NEEDLEJACK_FILAMENT  : 53987,
    NOISE_5_NEEDLEJACK_FILAMENT  : 53977,
    NOMAD  : 28846,
    NOMAD_BIOSECURITY_RESPONDERS_SKIN  : 55680,
    NOMAD_BLUE_TIGER_SKIN  : 36844,
    NOMAD_EXOPLANETS_HUNTER_SKIN  : 45990,
    NOMAD_GLACIAL_DRIFT_SKIN  : 46927,
    NOMAD_HAZARD_CONTROL_SKIN  : 56937,
    NOMAD_JUSTICE_SKIN  : 36454,
    NOMAD_SNOWLINE_BLADERACER_SKIN  : 52901,
    NOMAD_TRONHADAR_INK_SKIN  : 40515,
    NOMAD_VALKLEAR_GLORY_SKIN  : 36797,
    NON_CS_CRYSTALS  : 2306,
    NONLINEAR_METAMATERIALS  : 33362,
    NONLINEAR_METAMATERIALS_REACTION_FORMULA  : 46218,
    NOOSE_CRUISE_MISSILE_LAUNCHER  : 20601,
    NOVA_AUTO_TARGETING_CRUISE_MISSILE_I  : 1830,
    NOVA_AUTO_TARGETING_CRUISE_MISSILE_I_BLUEPRINT  : 1831,
    NOVA_AUTO_TARGETING_HEAVY_MISSILE_I  : 1822,
    NOVA_AUTO_TARGETING_HEAVY_MISSILE_I_BLUEPRINT  : 1823,
    NOVA_AUTO_TARGETING_LIGHT_MISSILE_I  : 1814,
    NOVA_AUTO_TARGETING_LIGHT_MISSILE_I_BLUEPRINT  : 1815,
    NOVA_CRUISE_MISSILE  : 205,
    NOVA_CRUISE_MISSILE_BLUEPRINT  : 806,
    NOVA_FURY_CRUISE_MISSILE  : 24531,
    NOVA_FURY_HEAVY_MISSILE  : 24507,
    NOVA_FURY_LIGHT_MISSILE  : 24497,
    NOVA_FURY_XL_CRUISE_MISSILE  : 41294,
    NOVA_HEAVY_ASSAULT_MISSILE  : 21867,
    NOVA_HEAVY_ASSAULT_MISSILE_BLUEPRINT  : 21868,
    NOVA_HEAVY_MISSILE  : 206,
    NOVA_HEAVY_MISSILE_BLUEPRINT  : 807,
    NOVA_JAVELIN_HEAVY_ASSAULT_MISSILE  : 13856,
    NOVA_JAVELIN_ROCKET  : 24478,
    NOVA_JAVELIN_TORPEDO  : 2801,
    NOVA_JAVELIN_XL_TORPEDO  : 41272,
    NOVA_LIGHT_MISSILE  : 213,
    NOVA_LIGHT_MISSILE_BLUEPRINT  : 814,
    NOVA_PRECISION_CRUISE_MISSILE  : 24537,
    NOVA_PRECISION_HEAVY_MISSILE  : 2655,
    NOVA_PRECISION_LIGHT_MISSILE  : 24503,
    NOVA_PRECISION_XL_CRUISE_MISSILE  : 41295,
    NOVA_RAGE_HEAVY_ASSAULT_MISSILE  : 24488,
    NOVA_RAGE_ROCKET  : 24473,
    NOVA_RAGE_TORPEDO  : 24519,
    NOVA_RAGE_XL_TORPEDO  : 41273,
    NOVA_ROCKET  : 2516,
    NOVA_ROCKET_BLUEPRINT  : 2517,
    NOVA_TORPEDO  : 2508,
    NOVA_TORPEDO_BLUEPRINT  : 2509,
    NOVA_XL_CRUISE_MISSILE  : 32438,
    NOVA_XL_CRUISE_MISSILE_BLUEPRINT  : 32439,
    NOVA_XL_TORPEDO  : 17863,
    NOVA_XL_TORPEDO_BLUEPRINT  : 17864,
    NOVICE_MEDAL  : 16712,
    NUCLEAR_L  : 195,
    NUCLEAR_L_BLUEPRINT  : 896,
    NUCLEAR_M  : 187,
    NUCLEAR_M_BLUEPRINT  : 888,
    NUCLEAR_PHYSICS  : 11451,
    NUCLEAR_PULSE_GENERATOR  : 11692,
    NUCLEAR_PULSE_GENERATOR_BLUEPRINT  : 17326,
    NUCLEAR_REACTOR_UNIT  : 11548,
    NUCLEAR_REACTOR_UNIT_BLUEPRINT  : 17342,
    NUCLEAR_REACTORS  : 2352,
    NUCLEAR_S  : 179,
    NUCLEAR_S_BLUEPRINT  : 880,
    NUCLEAR_XL  : 17672,
    NUCLEAR_XL_BLUEPRINT  : 17673,
    NUGGET_KINETIC_SHIELD_HARDENER  : 20633,
    NUGOEHUVI_SYNTH_BLUE_PILL_BOOSTER  : 32248,
    NULL_L  : 12787,
    NULL_M  : 12785,
    NULL_S  : 12614,
    NULL_XL  : 41324,
    NUMON_FAMILY_HEIRLOOM  : 20358,
    NYX  : 23913,
    NYX_BIOSECURITY_RESPONDERS_SKIN  : 55590,
    NYX_BLUEPRINT  : 23914,
    NYX_CAILLE_NEON_SKIN  : 50151,
    NYX_CAPSULEER_ELITE_XIX_SKIN  : 63691,
    NYX_DOS_ROUVENORS_BEQUEST_SKIN  : 48213,
    NYX_EROS_BLOSSOM_SKIN  : 50078,
    NYX_EXOPLANETS_HUNTER_SKIN  : 45920,
    NYX_FEDERAL_POLICE_SKIN  : 50004,
    NYX_GLACIAL_DRIFT_SKIN  : 46966,
    NYX_GLITTERING_DREAM_SKIN  : 47539,
    NYX_GREEN_DEMONS_SKIN  : 59338,
    NYX_HEADHUNTER_SKIN  : 46721,
    NYX_INNER_ZONE_VANGUARD_SKIN  : 52755,
    NYX_INTAKI_SYNDICATE_SKIN  : 36402,
    NYX_INTERBUS_SKIN  : 34655,
    NYX_INTERSTELLAR_CONVERGENCE_SKIN  : 62232,
    NYX_KOPIS_EDGE_SKIN  : 46432,
    NYX_LUMINAIRE_RISING_SKIN  : 64573,
    NYX_MALAGRIN_MARSHLIGHT_SKIN  : 53015,
    NYX_QUAFE_SKIN  : 34656,
    NYX_RUBY_SUNGRAZER_SKIN  : 45574,
    NYX_SAPPHIRE_SUNGRAZER_SKIN  : 46558,
    NYX_SERPENTIS_SKIN  : 42189,
    NYX_SPIRIT_SKIN  : 44938,
    NYX_ULTRAVIOLENCE_VORTEX_SKIN  : 58800,
    NYX_UMBRAL_SKIN  : 36522,
    NYX_VALIMOR_LEGACY_SKIN  : 37585,
    NYX_YOIUL_STAR_SKIN  : 53430,
    OBELISK  : 20187,
    OBELISK_BIOSECURITY_RESPONDERS_SKIN  : 55601,
    OBELISK_BLUEPRINT  : 20188,
    OBELISK_EXOPLANETS_HUNTER_SKIN  : 45929,
    OBELISK_GLACIAL_DRIFT_SKIN  : 46974,
    OBELISK_INNER_ZONE_SHIPPING_SKIN  : 34669,
    OBELISK_INNER_ZONE_VANGUARD_SKIN  : 52764,
    OBELISK_INTAKI_SYNDICATE_SKIN  : 36396,
    OBELISK_KOPIS_EDGE_SKIN  : 46441,
    OBELISK_QUAFE_SKIN  : 34670,
    OBELISK_REDCLAW_SABLE_SKIN  : 56955,
    OBELISK_RUBY_SUNGRAZER_SKIN  : 45581,
    OBELISK_SAPPHIRE_SUNGRAZER_SKIN  : 46559,
    OBELISK_SCOPE_SYNDICATION_YC122_SKIN  : 61188,
    OBELISK_SERPENTIS_SKIN  : 42198,
    OBELISK_SPIRIT_SKIN  : 44947,
    OBELISK_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 62857,
    OBELISK_VALIMOR_LEGACY_SKIN  : 37594,
    OBLIVION_KINETIC_DOOMSDAY  : 24552,
    OBLIVION_KINETIC_DOOMSDAY_BLUEPRINT  : 24553,
    OBSIDIAN_OCHRE  : 17437,
    OCCATOR  : 12745,
    OCCATOR_BIOSECURITY_RESPONDERS_SKIN  : 55632,
    OCCATOR_BLUEPRINT  : 12746,
    OCCATOR_EXOPLANETS_HUNTER_SKIN  : 45928,
    OCCATOR_GLACIAL_DRIFT_SKIN  : 46973,
    OCCATOR_INNER_ZONE_VANGUARD_SKIN  : 52763,
    OCCATOR_INTAKI_SYNDICATE_SKIN  : 36391,
    OCCATOR_KOPIS_EDGE_SKIN  : 46440,
    OCCATOR_LODESTRIKE_SKIN  : 46752,
    OCCATOR_RUBY_SUNGRAZER_SKIN  : 45580,
    OCCATOR_SERPENTIS_SKIN  : 42197,
    OCCATOR_SPIRIT_SKIN  : 44946,
    OCCATOR_VALIMOR_LEGACY_SKIN  : 37593,
    OCCATOR_VERSUS_REDFORCE_SKIN  : 63860,
    OCCULT_L  : 47934,
    OCCULT_M  : 47930,
    OCCULT_S  : 47926,
    OCEANIC_COMMAND_CENTER  : 2525,
    OCULAR_FILTER___BASIC  : 9899,
    OCULAR_FILTER___IMPROVED  : 10217,
    OCULAR_FILTER___STANDARD  : 10216,
    ODIN_SYNTHETIC_EYE_LEFT_DARK  : 4043,
    ODIN_SYNTHETIC_EYE_LEFT_GOLD  : 4046,
    ODIN_SYNTHETIC_EYE_LEFT_GRAY  : 4048,
    ODIN_SYNTHETIC_EYE_RIGHT_DARK  : 4037,
    ODIN_SYNTHETIC_EYE_RIGHT_GOLD  : 4039,
    ODIN_SYNTHETIC_EYE_RIGHT_GRAY  : 4038,
    OE_5200_ROCKET_LAUNCHER  : 16525,
    OFFENSIVE_CONTENT_REPORTS_JAN_YC120_VOL_I  : 48759,
    OFFENSIVE_SUBSYSTEM_TECHNOLOGY  : 30327,
    OGDINS_EYE_COORDINATION_ENHANCER  : 20443,
    OGRE_I  : 2444,
    OGRE_I_BLUEPRINT  : 2445,
    OGRE_II  : 2446,
    OGRE_SD_900  : 23506,
    OGRE_SD_900_BLUEPRINT  : 23507,
    OLD_L_SET_ICE_GRADING_PROCESSOR_I  : 41428,
    OLD_L_SET_ICE_GRADING_PROCESSOR_II  : 41429,
    OLD_L_SET_ORE_GRADING_PROCESSOR_I  : 37282,
    OLD_L_SET_ORE_GRADING_PROCESSOR_II  : 37283,
    OLD_M_SET_HS_MATERIALS_RECLAMATION_I  : 37280,
    OLD_M_SET_HS_MATERIALS_RECLAMATION_II  : 37281,
    OLD_M_SET_I1_MATERIALS_RECLAMATION_I  : 41424,
    OLD_M_SET_I1_MATERIALS_RECLAMATION_II  : 41425,
    OLD_M_SET_I2_MATERIALS_RECLAMATION_I  : 41426,
    OLD_M_SET_I2_MATERIALS_RECLAMATION_II  : 41427,
    OLD_M_SET_LNS_MATERIALS_RECLAMATION_I  : 41422,
    OLD_M_SET_LNS_MATERIALS_RECLAMATION_II  : 41423,
    OLD_XL_SET_REPROCESSING_MONITOR_I  : 37284,
    OLD_XL_SET_REPROCESSING_MONITOR_II  : 37285,
    OLFEI_MEDALLION  : 32099,
    OLUFAMIS_DNA  : 18655,
    OMBER  : 1227,
    OMBER_PROCESSING  : 12190,
    OMEN  : 2006,
    OMEN_ASPIRANT_ENFORCER_SKIN  : 60239,
    OMEN_AURORA_UNIVERSALIS_SKIN  : 57014,
    OMEN_BIOSECURITY_RESPONDERS_SKIN  : 55327,
    OMEN_BLOOD_RAIDERS_SKIN  : 36916,
    OMEN_BLUEPRINT  : 2007,
    OMEN_CAPSULEER_DAY_XV_SKIN  : 47623,
    OMEN_COLD_IRON_SKIN  : 42769,
    OMEN_DEATHGLOW_REMNANT_SKIN  : 60660,
    OMEN_EXOPLANETS_HUNTER_SKIN  : 45777,
    OMEN_GLACIAL_DRIFT_SKIN  : 44145,
    OMEN_HEADHUNTER_SKIN  : 46708,
    OMEN_IMPERIAL_ARMAMENTS_SKIN  : 41586,
    OMEN_IRONBLOOD_SKIN  : 43503,
    OMEN_KADOR_SKIN  : 34682,
    OMEN_NAVY_ISSUE  : 17709,
    OMEN_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55339,
    OMEN_NAVY_ISSUE_COLD_IRON_SKIN  : 42777,
    OMEN_NAVY_ISSUE_EOM_SKIN  : 36305,
    OMEN_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45786,
    OMEN_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46795,
    OMEN_NAVY_ISSUE_IMPERIAL_JUBILEE_SKIN  : 48847,
    OMEN_NAVY_ISSUE_OASIS_OF_FAITH_SKIN  : 53628,
    OMEN_NAVY_ISSUE_PURITY_OF_THE_THRONE_SKIN  : 42577,
    OMEN_NAVY_ISSUE_SARUM_SKIN  : 56795,
    OMEN_NAVY_ISSUE_STAR_CAPTAIN_SKIN  : 52577,
    OMEN_NAVY_ISSUE_WARP_CONVERGENCE_SKIN  : 61863,
    OMEN_OASIS_OF_FAITH_SKIN  : 53619,
    OMEN_OPERATION_PERMAFROST_SKIN  : 49813,
    OMEN_PURITY_OF_THE_THRONE_SKIN  : 42568,
    OMEN_RAATA_SUNSET_SKIN  : 37503,
    OMEN_SARUM_SKIN  : 34683,
    OMEN_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60215,
    OMEN_TASH_MURKON_SKIN  : 34684,
    OMEN_VERSUS_REDFORCE_SKIN  : 63241,
    OMEN_WAR_RESERVES_LIEUTENANT_SKIN  : 59214,
    OMEN_WARP_CONVERGENCE_SKIN  : 61862,
    OMNIDIRECTIONAL_TRACKING_ENHANCER_I  : 33822,
    OMNIDIRECTIONAL_TRACKING_ENHANCER_I_BLUEPRINT  : 33823,
    OMNIDIRECTIONAL_TRACKING_ENHANCER_II  : 33824,
    OMNIDIRECTIONAL_TRACKING_LINK_I  : 23533,
    OMNIDIRECTIONAL_TRACKING_LINK_I_BLUEPRINT  : 23534,
    OMNIDIRECTIONAL_TRACKING_LINK_II  : 24438,
    ONEIROS  : 11989,
    ONEIROS_BIOSECURITY_RESPONDERS_SKIN  : 55634,
    ONEIROS_BLUEPRINT  : 11990,
    ONEIROS_EMERGENCY_RESPONSE_SQUAD_SKIN  : 48725,
    ONEIROS_EXOPLANETS_HUNTER_SKIN  : 45899,
    ONEIROS_FEDERATION_NAVY_SKIN  : 58890,
    ONEIROS_FRONTIER_SAFEGUARDER_SKIN  : 54442,
    ONEIROS_GLACIAL_DRIFT_SKIN  : 46945,
    ONEIROS_INNER_ZONE_VANGUARD_SKIN  : 52734,
    ONEIROS_INTAKI_SYNDICATE_SKIN  : 36384,
    ONEIROS_KOPIS_EDGE_SKIN  : 46411,
    ONEIROS_SERPENTIS_SKIN  : 42173,
    ONEIROS_SPIRIT_SKIN  : 44917,
    ONEIROS_VALIMOR_LEGACY_SKIN  : 37570,
    ONYX  : 11995,
    ONYX_BIOSECURITY_RESPONDERS_SKIN  : 55564,
    ONYX_BLUE_TIGER_SKIN  : 36727,
    ONYX_EXOPLANETS_HUNTER_SKIN  : 45839,
    ONYX_GHOSTBIRD_SKIN  : 48182,
    ONYX_GLACIAL_DRIFT_SKIN  : 46843,
    ONYX_KIMOTORO_ESKEITAN_SKIN  : 52635,
    ONYX_MATIGU_SEABEAST_SKIN  : 40603,
    ONYX_OCHRE  : 17436,
    ONYX_RAATA_SUNSET_SKIN  : 36655,
    ONYX_STEEL_CARDINAL_SKIN  : 46116,
    ONYX_WIYRKOMI_SKIN  : 36345,
    ONYX_ZENTO_ISIDEKO_COMBINE_SKIN  : 53176,
    OODA_LOOP_EXPERT_SYSTEM_PACKAGE  : 54811,
    OPERATION_CENTER_REWARD_TOKEN  : 72258,
    OPTICAL_COMPACT_TRACKING_COMPUTER  : 6173,
    OPTIMAL_RANGE_DISRUPTION_SCRIPT  : 29005,
    OPTIMAL_RANGE_DISRUPTION_SCRIPT_BLUEPRINT  : 29006,
    OPTIMAL_RANGE_SCRIPT  : 28999,
    OPTIMAL_RANGE_SCRIPT_BLUEPRINT  : 29000,
    OPTIMIZED_ATTAINMENT_DECRYPTOR  : 34207,
    OPTIMIZED_AUGMENTATION_DECRYPTOR  : 34208,
    OPTIMIZED_NANO_ENGINES  : 29992,
    OPTIMIZED_NANO_ENGINES_BLUEPRINT  : 29993,
    OPULENT_COMPOUND  : 11734,
    OPULENT_PYROXERES  : 46686,
    OPUX_LUXURY_YACHT  : 635,
    ORACLE  : 4302,
    ORACLE_ARGYROS_ELITE_XVI_SKIN  : 52282,
    ORACLE_AURORA_UNIVERSALIS_SKIN  : 57015,
    ORACLE_BIOSECURITY_RESPONDERS_SKIN  : 55308,
    ORACLE_BLAZE_SQUADRON_SKIN  : 47101,
    ORACLE_BLUEPRINT  : 4305,
    ORACLE_CAPSULEER_DAY_XV_SKIN  : 47624,
    ORACLE_CAPSULEER_ELITE_XIX_SKIN  : 63683,
    ORACLE_COLD_IRON_SKIN  : 42779,
    ORACLE_CROWN_AND_SWORDS_SKIN  : 56194,
    ORACLE_DEATHGLOW_HUNTERS_SKIN  : 60571,
    ORACLE_EXOPLANETS_HUNTER_SKIN  : 45788,
    ORACLE_GLACIAL_DRIFT_SKIN  : 46797,
    ORACLE_HEADHUNTER_SKIN  : 46709,
    ORACLE_IMPERIAL_ARMAMENTS_SKIN  : 41587,
    ORACLE_IMPERIAL_JUBILEE_SKIN  : 46533,
    ORACLE_IRONBLOOD_SKIN  : 43511,
    ORACLE_KHANID_SKIN  : 34603,
    ORACLE_OASIS_OF_FAITH_SKIN  : 53630,
    ORACLE_PURITY_OF_THE_THRONE_SKIN  : 42579,
    ORACLE_RAATA_SUNSET_SKIN  : 37511,
    ORACLE_SARUM_SKIN  : 34604,
    ORACLE_STAR_CAPTAIN_SKIN  : 52582,
    ORACLE_YOIUL_FESTIVAL_YC121_SKIN  : 49946,
    ORACLE_YOIUL_STAR_SKIN  : 53420,
    ORCA  : 28606,
    ORCA_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43759,
    ORCA_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43812,
    ORCA_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43825,
    ORCA_BLUEPRINT  : 28607,
    ORCA_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43772,
    ORCA_CAPSULEER_DAY_XVIII_SKIN  : 58823,
    ORCA_CAPSULEER_ELITE_XIX_SKIN  : 63695,
    ORCA_CLAIM_FOREMAN_SKIN  : 44068,
    ORCA_DIGMASTER_BLAZON_SKIN  : 42651,
    ORCA_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43786,
    ORCA_GLACIAL_DRIFT_SKIN  : 46991,
    ORCA_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43838,
    ORCA_LODESTRIKE_SKIN  : 46741,
    ORCA_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43799,
    ORCA_MORPHITE_SHINE_SKIN  : 42624,
    ORCA_NOCX_RUSH_SKIN  : 34645,
    ORCA_PAYDIRT_PROSPECTOR_SKIN  : 42681,
    ORCA_RADIOACTIVES_RECLAMATION_SKIN  : 60953,
    ORCA_ROCKBREAKER_PIONEERS_SKIN  : 56612,
    ORCA_ROSADA_DAWN_SKIN  : 43673,
    ORCA_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43851,
    ORE_DEEP_CORE_MINING_LASER  : 28748,
    ORE_EXPANDED_CARGOHOLD  : 34489,
    ORE_FREIGHTER  : 34327,
    ORE_GAS_CLOUD_HARVESTER  : 60315,
    ORE_HAULER  : 3184,
    ORE_ICE_HARVESTER  : 28752,
    ORE_ICE_MINING_LASER  : 37452,
    ORE_MINER  : 28750,
    ORE_MINING_DIRECTOR_MINDLINK  : 43775,
    ORE_PROSPECTING_ARRAY_1  : 2040,
    ORE_PROSPECTING_ARRAY_1_BLUEPRINT  : 34855,
    ORE_PROSPECTING_ARRAY_2  : 2041,
    ORE_PROSPECTING_ARRAY_2_BLUEPRINT  : 34856,
    ORE_PROSPECTING_ARRAY_3  : 2042,
    ORE_PROSPECTING_ARRAY_3_BLUEPRINT  : 34857,
    ORE_PROSPECTING_ARRAY_4  : 2043,
    ORE_PROSPECTING_ARRAY_4_BLUEPRINT  : 34858,
    ORE_PROSPECTING_ARRAY_5  : 2044,
    ORE_PROSPECTING_ARRAY_5_BLUEPRINT  : 34859,
    ORE_REINFORCED_BULKHEADS  : 34485,
    ORE_RESEARCH_DATA_FRAGMENT  : 62709,
    ORE_STRIP_MINER  : 28754,
    ORGANIC_MORTAR_APPLICATORS  : 2870,
    ORTHRUS  : 33818,
    ORTHRUS_ASPIRANT_SOLDIER_SKIN  : 60241,
    ORTHRUS_AURORA_UNIVERSALIS_SKIN  : 60924,
    ORTHRUS_CONVERGENCE___NOVA_SKIN  : 44254,
    ORTHRUS_GHOSTS_HEX_SKIN  : 57811,
    ORTHRUS_GLACIAL_DRIFT_SKIN  : 47004,
    ORTHRUS_IGC_SKIN  : 60755,
    ORTHRUS_LUMINAE_POLARIS_SKIN  : 75269,
    ORTHRUS_REDCLAW_SABLE_SKIN  : 57786,
    ORTHRUS_TRIUMPHANT_ELITE_SKIN  : 62373,
    ORTHRUS_WASCHI_UPRISING_COMBAT_SKIN  : 37868,
    ORTHRUS_WASCHI_UPRISING_PARADE_SKIN  : 37966,
    OSCILLATOR_CAPACITOR_UNIT  : 11553,
    OSCILLATOR_CAPACITOR_UNIT_BLUEPRINT  : 17337,
    OSPREY  : 620,
    OSPREY_AIR_LABORATORIES_SKIN  : 60266,
    OSPREY_AURORA_UNIVERSALIS_SKIN  : 60912,
    OSPREY_BIOSECURITY_RESPONDERS_SKIN  : 55539,
    OSPREY_BLUE_TIGER_SKIN  : 36724,
    OSPREY_BLUEPRINT  : 686,
    OSPREY_COMBAT_MEDIC_SECTION_SKIN  : 48720,
    OSPREY_EXOPLANETS_HUNTER_SKIN  : 45836,
    OSPREY_GHOSTBIRD_SKIN  : 48179,
    OSPREY_GLACIAL_DRIFT_SKIN  : 44156,
    OSPREY_KIMOTORO_ESKEITAN_SKIN  : 52637,
    OSPREY_MATIGU_SEABEAST_SKIN  : 40600,
    OSPREY_NAVY_ISSUE  : 29340,
    OSPREY_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55546,
    OSPREY_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45845,
    OSPREY_NAVY_ISSUE_GHOSTBIRD_SKIN  : 48188,
    OSPREY_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46849,
    OSPREY_NAVY_ISSUE_KIMOTORO_ESKEITAN_COMMANDER_SKIN  : 64097,
    OSPREY_NAVY_ISSUE_STEEL_CARDINAL_SKIN  : 46122,
    OSPREY_NAVY_ISSUE_UNION_DAY_EXECUTIVE_SKIN  : 71726,
    OSPREY_NAVY_ISSUE_VITALSHIFT_EGO_SKIN  : 52556,
    OSPREY_NAVY_ISSUE_WIYRKOMI_SKIN  : 36363,
    OSPREY_NAVY_ISSUE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53182,
    OSPREY_RAATA_SUNSET_SKIN  : 36652,
    OSPREY_STEEL_CARDINAL_SKIN  : 46113,
    OSPREY_WIYRKOMI_SKIN  : 34688,
    OSPREY_ZENTO_ISIDEKO_COMBINE_SKIN  : 53173,
    OTAVITE  : 45498,
    OUTPOST_CONSTRUCTION  : 3400,
    OVERDRIVE_INJECTOR_SYSTEM_I  : 1244,
    OVERDRIVE_INJECTOR_SYSTEM_I_BLUEPRINT  : 1245,
    OVERDRIVE_INJECTOR_SYSTEM_II  : 1236,
    OVERMIND_GOLIATH_DRONE_TUNER_T25_10S  : 48746,
    OVERMIND_HAWKMOTH_DRONE_TUNER_S10_25T  : 48747,
    OXIDES  : 2317,
    OXIDIZING_COMPOUND  : 2392,
    OXY_ORGANIC_SOLVENTS  : 57454,
    OXY_ORGANIC_SOLVENTS_REACTION_FORMULA  : 57491,
    OXYGEN  : 3683,
    OXYGEN_FUEL_BLOCK  : 4312,
    OXYGEN_FUEL_BLOCK_BLUEPRINT  : 4313,
    OXYGEN_ISOTOPES  : 17887,
    P_S_COMPACT_REMOTE_TRACKING_COMPUTER  : 5340,
    PACIFIER  : 44993,
    PACIFIER_BIOSECURITY_RESPONDERS_SKIN  : 55295,
    PACIFIER_BLACKICE_DERAILERS_SKIN  : 57783,
    PACIFIER_CONCORD_AEROSPACE_PROTOTYPE_SKIN  : 45481,
    PACIFIER_DED_ALPHA_PLUS_TASKFORCE_SKIN  : 48579,
    PACIFIER_DED_ARMY_RESERVE_SKIN  : 54216,
    PACIFIER_DED_MASTER_AT_ARMS_RECRUITER_SKIN  : 48576,
    PACIFIER_EXOPLANETS_HUNTER_SKIN  : 45991,
    PACIFIER_FIREWATCH_SKIN  : 46021,
    PACIFIER_GLACIAL_DRIFT_SKIN  : 47001,
    PACIFIER_LUMINAIRE_RISING_SKIN  : 64409,
    PACIFIER_SARO_BLACK_TROOP_SKIN  : 47680,
    PACKRAT_MOBILE_TRACTOR_UNIT  : 33700,
    PAGE_CAPACITOR_FLUX_COIL_I  : 23894,
    PALADIN  : 28659,
    PALADIN_BIOSECURITY_RESPONDERS_SKIN  : 55350,
    PALADIN_BLOOD_RAIDERS_SKIN  : 34810,
    PALADIN_COLD_IRON_SKIN  : 42788,
    PALADIN_EOM_SKIN  : 36319,
    PALADIN_EXOPLANETS_HUNTER_SKIN  : 45797,
    PALADIN_GLACIAL_DRIFT_SKIN  : 46806,
    PALADIN_IMPERIAL_JUBILEE_SKIN  : 46536,
    PALADIN_IRONBLOOD_SKIN  : 43519,
    PALADIN_LUMINAIRE_RISING_SKIN  : 48214,
    PALADIN_MALAGRIN_MARSHLIGHT_SKIN  : 53008,
    PALADIN_OASIS_OF_FAITH_SKIN  : 53639,
    PALADIN_ORDER_OF_TETRIMON_SKIN  : 73466,
    PALADIN_PURITY_OF_THE_THRONE_SKIN  : 42588,
    PALADIN_RAATA_SUNSET_SKIN  : 37519,
    PALADIN_SANGUINARY_SAVANT_SKIN  : 73454,
    PALADIN_STAR_CAPTAIN_SKIN  : 44127,
    PALADIN_YOIUL_FESTIVAL_YC121_SKIN  : 49947,
    PALADIN_ZAKURA_SHUMYU_SKIN  : 57069,
    PALISADE_CAP_RECHARGER_I  : 23813,
    PANDEMIC_SPHERE_MODIFIED_ENTOSIS_LINK  : 40310,
    PANTHER  : 22440,
    PANTHER_BIOSECURITY_RESPONDERS_SKIN  : 55682,
    PANTHER_BLOODY_HANDS_SKIN  : 47175,
    PANTHER_BLUE_TIGER_SKIN  : 36832,
    PANTHER_CHAINBREAKER_KHUMAAK_SKIN  : 48521,
    PANTHER_DAWN_OF_LIBERATION_SKIN  : 48517,
    PANTHER_EROS_BLOSSOM_SKIN  : 50084,
    PANTHER_EXOPLANETS_HUNTER_SKIN  : 45976,
    PANTHER_FIREBLADE_GUERILLA_SKIN  : 59325,
    PANTHER_GLACIAL_DRIFT_SKIN  : 46913,
    PANTHER_HAZARD_CONTROL_SKIN  : 56905,
    PANTHER_HEADHUNTER_SKIN  : 49429,
    PANTHER_ICEBLADE_GUERILLA_SKIN  : 59330,
    PANTHER_JUSTICE_SKIN  : 36445,
    PANTHER_LUMINAIRE_RISING_SKIN  : 48216,
    PANTHER_MALAGRIN_MARSHLIGHT_SKIN  : 56599,
    PANTHER_REPUBLIC_FLEET_SKIN  : 59817,
    PANTHER_SNOWLINE_BLADERACER_SKIN  : 52887,
    PANTHER_TRONHADAR_INK_SKIN  : 40503,
    PANTHER_VALKLEAR_GLORY_SKIN  : 36785,
    PANTHER_VERSUS_REDFORCE_SKIN  : 63870,
    PANTHER_YOIUL_FESTIVAL_YC121_SKIN  : 49960,
    PANTHER_YOIUL_STAR_SKIN  : 53434,
    PARADOXICAL_NEBULA_FIREWORK  : 60939,
    PARALLEL_ENDURING_TARGET_PAINTER  : 19812,
    PARITY_DECRYPTOR  : 34204,
    PARTIAL_NEUROSYNAPTIC_PROFILE  : 56710,
    PARTICLE_ACCELERATOR_UNIT  : 11688,
    PARTICLE_ACCELERATOR_UNIT_BLUEPRINT  : 17344,
    PARTICLE_BORE_COMPACT_MINING_LASER  : 5245,
    PASHANS_TURRET_CUSTOMIZATION_MINDLINK  : 25868,
    PASHANS_TURRET_HANDLING_MINDLINK  : 25867,
    PASSIVE_TARGETER_I  : 581,
    PASSIVE_TARGETER_I_BLUEPRINT  : 1212,
    PASSIVE_TARGETER_II  : 2341,
    PASSIVE_TARGETING_ARRAY_I  : 5867,
    PAX_AMARRIA  : 11585,
    PAX_E_KILIZHI_DO  : 52783,
    PEACE_LARGE_REMOTE_ARMOR_REPAIRER  : 23416,
    PECULIAR_DATA_COLLECTION  : 62031,
    PELLUCID_CROKITE  : 46677,
    PEREGRINUS_MUTANITE  : 77418,
    PERIPHERAL_COMPACT_TARGET_PAINTER  : 19810,
    PERMABAND_TOUR_CAP_CRATE  : 52292,
    PERMABAND_TOUR_T_SHIRT_CRATE  : 52300,
    PERSONAL_HANGAR_ARRAY  : 33149,
    PERSONAL_HANGAR_ARRAY_BLUEPRINT  : 33150,
    PERUN_HEAVY_MUTADAPTIVE_REMOTE_ARMOR_REPAIRER  : 49774,
    PHALARICA_THERMAL_LANCE  : 41440,
    PHALARICA_THERMAL_LANCE_BLUEPRINT  : 41447,
    PHANTASM  : 17718,
    PHANTASM_ASPIRANT_SOLDIER_SKIN  : 60240,
    PHANTASM_AURORA_UNIVERSALIS_SKIN  : 60921,
    PHANTASM_GHOSTS_HEX_SKIN  : 57808,
    PHANTASM_GLACIAL_DRIFT_SKIN  : 47007,
    PHANTASM_LUMINAE_POLARIS_SKIN  : 75266,
    PHANTASM_REDCLAW_SABLE_SKIN  : 56953,
    PHANTASM_SCOPE_SYNDICATION_YC122_SKIN  : 61185,
    PHANTASM_TRUE_DELIVERANCE_SKIN  : 52597,
    PHAROLUX_CYNO_BEACON  : 35840,
    PHAROLUX_CYNO_BEACON_BLUEPRINT  : 36960,
    PHASE_INVERSION_BATTERY  : 17175,
    PHASE_INVERSION_BATTERY_BLUEPRINT  : 2794,
    PHASED_MUON_SCOPED_SENSOR_DAMPENER  : 5302,
    PHASED_PLASMA_L  : 200,
    PHASED_PLASMA_L_BLUEPRINT  : 901,
    PHASED_PLASMA_M  : 192,
    PHASED_PLASMA_M_BLUEPRINT  : 893,
    PHASED_PLASMA_S  : 184,
    PHASED_PLASMA_S_BLUEPRINT  : 885,
    PHASED_PLASMA_XL  : 17674,
    PHASED_PLASMA_XL_BLUEPRINT  : 17675,
    PHASED_SCOPED_TARGET_PAINTER  : 19814,
    PHENOLIC_COMPOSITES  : 16680,
    PHENOLIC_COMPOSITES_REACTION_FORMULA  : 46210,
    PHOBETOR_WARP_DISRUPT_PROBE  : 41402,
    PHOBOS  : 12021,
    PHOBOS_BIOSECURITY_RESPONDERS_SKIN  : 55633,
    PHOBOS_EXOPLANETS_HUNTER_SKIN  : 45898,
    PHOBOS_GLACIAL_DRIFT_SKIN  : 46944,
    PHOBOS_HEARTSURGE_SKIN  : 76288,
    PHOBOS_INNER_ZONE_VANGUARD_SKIN  : 52733,
    PHOBOS_INTAKI_SYNDICATE_SKIN  : 36386,
    PHOBOS_KOPIS_EDGE_SKIN  : 46410,
    PHOBOS_SERPENTIS_SKIN  : 42172,
    PHOBOS_SPIRIT_SKIN  : 44916,
    PHOBOS_VALIMOR_LEGACY_SKIN  : 37569,
    PHOBOS_VERSUS_BLUEFORCE_SKIN  : 63861,
    PHOENIX  : 19726,
    PHOENIX_BIOSECURITY_RESPONDERS_SKIN  : 55517,
    PHOENIX_BLUE_TIGER_SKIN  : 36741,
    PHOENIX_BLUEPRINT  : 19727,
    PHOENIX_CALDARI_UNION_DAY_SKIN  : 48734,
    PHOENIX_CAPSULEER_ELITE_XIX_SKIN  : 63688,
    PHOENIX_EXOPLANETS_HUNTER_SKIN  : 45859,
    PHOENIX_GHOSTBIRD_SKIN  : 48202,
    PHOENIX_GLACIAL_DRIFT_SKIN  : 46863,
    PHOENIX_HEADHUNTER_SKIN  : 46716,
    PHOENIX_LAI_DAI_SKIN  : 34661,
    PHOENIX_MALAGRIN_MARSHLIGHT_SKIN  : 56595,
    PHOENIX_MATIGU_SEABEAST_SKIN  : 40617,
    PHOENIX_NAVY_ISSUE  : 73793,
    PHOENIX_NAVY_ISSUE_HEARTSURGE_SKIN  : 76292,
    PHOENIX_RAATA_SUNSET_SKIN  : 36669,
    PHOENIX_SPIRIT_SKIN  : 47314,
    PHOENIX_STARLINE_MAJESTIC_SKIN  : 55725,
    PHOENIX_STATE_POLICE_SKIN  : 72557,
    PHOENIX_STEEL_CARDINAL_SKIN  : 46136,
    PHOENIX_UNION_DAY_EXECUTIVE_SKIN  : 71733,
    PHOENIX_VITALSHIFT_EGO_SKIN  : 52563,
    PHOENIX_WIYRKOMI_SKIN  : 34662,
    PHOENIX_YOIUL_STAR_SKIN  : 61124,
    PHOENIX_ZENTO_ISIDEKO_COMBINE_SKIN  : 53196,
    PHOTON_MICROPROCESSOR  : 11541,
    PHOTON_MICROPROCESSOR_BLUEPRINT  : 17357,
    PHOTON_SCATTERING_ARRAY  : 17187,
    PHOTON_SCATTERING_ARRAY_BLUEPRINT  : 2761,
    PHOTONIC_METAMATERIALS  : 33359,
    PHOTONIC_METAMATERIALS_REACTION_FORMULA  : 46217,
    PHOTONIC_UPGRADED_CO_PROCESSOR  : 8748,
    PICKAXE_RAPID_LIGHT_MISSILE_LAUNCHER  : 20597,
    PICKPOCKET_EM_ENERGIZED_MEMBRANE  : 55860,
    PIKE_SMALL_EMP_SMARTBOMB_I  : 23864,
    PILFER_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 22879,
    PILGRIM  : 11965,
    PILGRIM_BIOSECURITY_RESPONDERS_SKIN  : 55356,
    PILGRIM_BLUEPRINT  : 11966,
    PILGRIM_COLD_IRON_SKIN  : 42775,
    PILGRIM_EOM_SKIN  : 36294,
    PILGRIM_EXOPLANETS_HUNTER_SKIN  : 45783,
    PILGRIM_GLACIAL_DRIFT_SKIN  : 46792,
    PILGRIM_IMPERIAL_JUBILEE_SKIN  : 48849,
    PILGRIM_OASIS_OF_FAITH_SKIN  : 53625,
    PILGRIM_PURITY_OF_THE_THRONE_SKIN  : 42574,
    PILGRIM_RAATA_SUNSET_SKIN  : 37509,
    PILGRIM_STAR_CAPTAIN_SKIN  : 52579,
    PILOTS_BODY_RESCULPT_CERTIFICATE  : 34132,
    PIRATE_DETECTION_ARRAY_1  : 2026,
    PIRATE_DETECTION_ARRAY_1_BLUEPRINT  : 34845,
    PIRATE_DETECTION_ARRAY_2  : 2027,
    PIRATE_DETECTION_ARRAY_2_BLUEPRINT  : 34846,
    PIRATE_DETECTION_ARRAY_3  : 2028,
    PIRATE_DETECTION_ARRAY_3_BLUEPRINT  : 34847,
    PIRATE_DETECTION_ARRAY_4  : 2029,
    PIRATE_DETECTION_ARRAY_4_BLUEPRINT  : 34848,
    PIRATE_DETECTION_ARRAY_5  : 2030,
    PIRATE_DETECTION_ARRAY_5_BLUEPRINT  : 34849,
    PITFALL_COMPACT_WARP_DISRUPTION_FIELD_GENERATOR  : 37608,
    PITH_A_TYPE_EM_SHIELD_HARDENER  : 19280,
    PITH_A_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19276,
    PITH_A_TYPE_KINETIC_SHIELD_HARDENER  : 19274,
    PITH_A_TYPE_LARGE_SHIELD_BOOSTER  : 19205,
    PITH_A_TYPE_SHIELD_BOOST_AMPLIFIER  : 19311,
    PITH_A_TYPE_THERMAL_SHIELD_HARDENER  : 19278,
    PITH_A_TYPE_X_LARGE_SHIELD_BOOSTER  : 19206,
    PITH_B_TYPE_EM_SHIELD_HARDENER  : 19266,
    PITH_B_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19270,
    PITH_B_TYPE_KINETIC_SHIELD_HARDENER  : 19272,
    PITH_B_TYPE_LARGE_SHIELD_BOOSTER  : 19203,
    PITH_B_TYPE_SHIELD_BOOST_AMPLIFIER  : 19303,
    PITH_B_TYPE_THERMAL_SHIELD_HARDENER  : 19268,
    PITH_B_TYPE_X_LARGE_SHIELD_BOOSTER  : 19204,
    PITH_C_TYPE_EM_SHIELD_HARDENER  : 19264,
    PITH_C_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19260,
    PITH_C_TYPE_KINETIC_SHIELD_HARDENER  : 19258,
    PITH_C_TYPE_LARGE_SHIELD_BOOSTER  : 19201,
    PITH_C_TYPE_SHIELD_BOOST_AMPLIFIER  : 19289,
    PITH_C_TYPE_THERMAL_SHIELD_HARDENER  : 19262,
    PITH_C_TYPE_X_LARGE_SHIELD_BOOSTER  : 19202,
    PITH_X_TYPE_EM_SHIELD_HARDENER  : 19282,
    PITH_X_TYPE_EXPLOSIVE_SHIELD_HARDENER  : 19286,
    PITH_X_TYPE_KINETIC_SHIELD_HARDENER  : 19288,
    PITH_X_TYPE_LARGE_SHIELD_BOOSTER  : 19207,
    PITH_X_TYPE_SHIELD_BOOST_AMPLIFIER  : 19295,
    PITH_X_TYPE_THERMAL_SHIELD_HARDENER  : 19284,
    PITH_X_TYPE_X_LARGE_SHIELD_BOOSTER  : 19208,
    PITHI_A_TYPE_SMALL_REMOTE_SHIELD_BOOSTER  : 19139,
    PITHI_A_TYPE_SMALL_SHIELD_BOOSTER  : 19179,
    PITHI_B_TYPE_SMALL_REMOTE_SHIELD_BOOSTER  : 19137,
    PITHI_B_TYPE_SMALL_SHIELD_BOOSTER  : 19177,
    PITHI_C_TYPE_SMALL_REMOTE_SHIELD_BOOSTER  : 19135,
    PITHI_C_TYPE_SMALL_SHIELD_BOOSTER  : 19175,
    PITHUM_A_TYPE_EM_SHIELD_AMPLIFIER  : 19231,
    PITHUM_A_TYPE_EXPLOSIVE_SHIELD_AMPLIFIER  : 19225,
    PITHUM_A_TYPE_KINETIC_SHIELD_AMPLIFIER  : 19229,
    PITHUM_A_TYPE_MEDIUM_REMOTE_SHIELD_BOOSTER  : 19151,
    PITHUM_A_TYPE_MEDIUM_SHIELD_BOOSTER  : 19191,
    PITHUM_A_TYPE_MULTISPECTRUM_SHIELD_HARDENER  : 4347,
    PITHUM_A_TYPE_THERMAL_SHIELD_AMPLIFIER  : 19227,
    PITHUM_B_TYPE_EM_SHIELD_AMPLIFIER  : 19223,
    PITHUM_B_TYPE_EXPLOSIVE_SHIELD_AMPLIFIER  : 19217,
    PITHUM_B_TYPE_KINETIC_SHIELD_AMPLIFIER  : 19221,
    PITHUM_B_TYPE_MEDIUM_REMOTE_SHIELD_BOOSTER  : 19149,
    PITHUM_B_TYPE_MEDIUM_SHIELD_BOOSTER  : 19189,
    PITHUM_B_TYPE_MULTISPECTRUM_SHIELD_HARDENER  : 4348,
    PITHUM_B_TYPE_THERMAL_SHIELD_AMPLIFIER  : 19219,
    PITHUM_C_TYPE_EM_SHIELD_AMPLIFIER  : 19215,
    PITHUM_C_TYPE_EXPLOSIVE_SHIELD_AMPLIFIER  : 19209,
    PITHUM_C_TYPE_KINETIC_SHIELD_AMPLIFIER  : 19213,
    PITHUM_C_TYPE_MEDIUM_REMOTE_SHIELD_BOOSTER  : 19147,
    PITHUM_C_TYPE_MEDIUM_SHIELD_BOOSTER  : 19187,
    PITHUM_C_TYPE_MULTISPECTRUM_SHIELD_HARDENER  : 4349,
    PITHUM_C_TYPE_THERMAL_SHIELD_AMPLIFIER  : 19211,
    PL_0_SCOPED_CARGO_SCANNER  : 6135,
    PLAGIOCLASE  : 18,
    PLAGIOCLASE_PROCESSING  : 12191,
    PLANETARY_VEHICLES  : 9846,
    PLANETOLOGY  : 2406,
    PLANKTIC_COLONIES  : 2286,
    PLASMA_COMMAND_CENTER  : 2551,
    PLASMA_PHYSICS  : 11441,
    PLASMA_PULSE_GENERATOR  : 11695,
    PLASMA_PULSE_GENERATOR_BLUEPRINT  : 17339,
    PLASMA_THRUSTER  : 11530,
    PLASMA_THRUSTER_BLUEPRINT  : 17324,
    PLASMOIDS  : 2389,
    PLASMONIC_METAMATERIALS  : 33361,
    PLASMONIC_METAMATERIALS_REACTION_FORMULA  : 46215,
    PLATINOID_OMBER  : 46684,
    PLATINUM  : 16644,
    PLATINUM_TECHNITE  : 16662,
    PLATINUM_TECHNITE_REACTION_FORMULA  : 46177,
    PLEX  : 44992,
    PLOUGH_HEAVY_CAPACITOR_BOOSTER_I  : 23811,
    PLOW_GAS_CLOUD_SCOOP  : 25542,
    PLUM_MORDUNIUM  : 74522,
    PLUNDER_MORDUNIUM  : 74524,
    PLUNDERER_GRAVIMETRIC_ECM  : 20577,
    PLUSH_COMPOUND  : 11725,
    PLUTONIUM  : 3727,
    PLUTONIUM_CHARGE_L  : 237,
    PLUTONIUM_CHARGE_L_BLUEPRINT  : 1152,
    PLUTONIUM_CHARGE_M  : 229,
    PLUTONIUM_CHARGE_M_BLUEPRINT  : 1144,
    PLUTONIUM_CHARGE_S  : 221,
    PLUTONIUM_CHARGE_S_BLUEPRINT  : 1136,
    PLUTONIUM_CHARGE_XL  : 17656,
    PLUTONIUM_CHARGE_XL_BLUEPRINT  : 17657,
    POACHER_EM_SHIELD_HARDENER  : 20639,
    POKSU_MINERAL_GROUP_STRONG_BOX  : 43686,
    POLARIS_EVICTION_NOTICE  : 34427,
    POLARIZED_200MM_AUTOCANNON  : 34284,
    POLARIZED_425MM_AUTOCANNON  : 34286,
    POLARIZED_800MM_REPEATING_CANNON  : 34288,
    POLARIZED_HEAVY_ASSAULT_MISSILE_LAUNCHER  : 34292,
    POLARIZED_HEAVY_NEUTRON_BLASTER  : 34280,
    POLARIZED_HEAVY_PULSE_LASER  : 34274,
    POLARIZED_LIGHT_NEUTRON_BLASTER  : 34278,
    POLARIZED_MEGA_PULSE_LASER  : 34276,
    POLARIZED_NEUTRON_BLASTER_CANNON  : 34282,
    POLARIZED_ROCKET_LAUNCHER  : 34290,
    POLARIZED_SMALL_FOCUSED_PULSE_LASER  : 34272,
    POLARIZED_TORPEDO_LAUNCHER  : 34294,
    POLICE_PURSUIT_COMET  : 33677,
    POLLUCITE  : 45504,
    POLYARAMIDS  : 2321,
    POLYMER_REACTOR_ARRAY  : 30656,
    POLYTEXTILES  : 3695,
    PONTIFEX  : 37481,
    PONTIFEX_BIOSECURITY_RESPONDERS_SKIN  : 55354,
    PONTIFEX_COLD_IRON_SKIN  : 42764,
    PONTIFEX_EXOPLANETS_HUNTER_SKIN  : 45771,
    PONTIFEX_GLACIAL_DRIFT_SKIN  : 46784,
    PONTIFEX_IRONBLOOD_SKIN  : 43498,
    PONTIFEX_OASIS_OF_FAITH_SKIN  : 53613,
    PONTIFEX_ORDER_OF_TETRIMON_SKIN  : 73462,
    PONTIFEX_PURITY_OF_THE_THRONE_SKIN  : 42562,
    PONTIFEX_RAATA_SUNSET_SKIN  : 40319,
    PONTIFEX_STAR_CAPTAIN_SKIN  : 52575,
    PORPOISE  : 42244,
    PORPOISE_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43761,
    PORPOISE_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43814,
    PORPOISE_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43827,
    PORPOISE_BLUEPRINT  : 43910,
    PORPOISE_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43774,
    PORPOISE_CAPSULEER_DAY_XVIII_SKIN  : 58822,
    PORPOISE_CAPSULEER_ELITE_XIX_SKIN  : 63697,
    PORPOISE_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43788,
    PORPOISE_GALNET_STREAMCAST_UNIT_SKIN  : 57740,
    PORPOISE_GLACIAL_DRIFT_SKIN  : 46995,
    PORPOISE_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43840,
    PORPOISE_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43801,
    PORPOISE_RADIOACTIVES_RECLAMATION_SKIN  : 60957,
    PORPOISE_ROCKBREAKER_PIONEERS_SKIN  : 56606,
    PORPOISE_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43853,
    PORTCULLIS_REACTOR_CONTROL_UNIT_I  : 23898,
    POS_FUEL  : 54166,
    POSITRON_CORD  : 23158,
    POSSE_MULTISPECTRUM_SHIELD_HARDENER  : 20637,
    POSTCARD_FROM_POITOT  : 33022,
    POTEQUE_PROSPECTOR_ARCHAEOLOGY_AC_905  : 27196,
    POTEQUE_PROSPECTOR_ASTROMETRIC_ACQUISITION_AQ_702  : 27193,
    POTEQUE_PROSPECTOR_ASTROMETRIC_ACQUISITION_AQ_706  : 27187,
    POTEQUE_PROSPECTOR_ASTROMETRIC_ACQUISITION_AQ_710  : 27192,
    POTEQUE_PROSPECTOR_ASTROMETRIC_PINPOINTING_AP_602  : 27191,
    POTEQUE_PROSPECTOR_ASTROMETRIC_PINPOINTING_AP_606  : 27186,
    POTEQUE_PROSPECTOR_ASTROMETRIC_PINPOINTING_AP_610  : 27190,
    POTEQUE_PROSPECTOR_ASTROMETRIC_RANGEFINDING_AR_802  : 27195,
    POTEQUE_PROSPECTOR_ASTROMETRIC_RANGEFINDING_AR_806  : 27188,
    POTEQUE_PROSPECTOR_ASTROMETRIC_RANGEFINDING_AR_810  : 27194,
    POTEQUE_PROSPECTOR_ENVIRONMENTAL_ANALYSIS_EY_1005  : 27260,
    POTEQUE_PROSPECTOR_HACKING_HC_905  : 27197,
    POTEQUE_PROSPECTOR_SALVAGING_SV_905  : 27198,
    POWDERED_C_540_GRAPHITE  : 30019,
    POWER_CIRCUIT  : 25617,
    POWER_CONDUIT  : 25613,
    POWER_COUPLINGS  : 21589,
    POWER_DIAGNOSTIC_SYSTEM_I  : 1539,
    POWER_DIAGNOSTIC_SYSTEM_I_BLUEPRINT  : 1540,
    POWER_DIAGNOSTIC_SYSTEM_II  : 1541,
    POWER_DRAINER_DISPLAY  : 56983,
    POWER_GRID_MANAGEMENT  : 3413,
    PPD_FULLERENE_FIBERS  : 30304,
    PPD_FULLERENE_FIBERS_REACTION_FORMULA  : 46159,
    PRAETOR_EV_900  : 22572,
    PRAETOR_EV_900_BLUEPRINT  : 22573,
    PRAETOR_I  : 2193,
    PRAETOR_I_BLUEPRINT  : 2194,
    PRAETOR_II  : 2195,
    PRAETOR_TD_900  : 23510,
    PRAETOR_TD_900_BLUEPRINT  : 23511,
    PRAXIS  : 47466,
    PRAXIS_CAPSULEER_DAY_XVI_SKIN  : 52278,
    PRAXIS_CAPSULEER_ELITE_XV_SKIN  : 47650,
    PRAXIS_CAPSULEER_NETWORKER_XV_SKIN  : 47655,
    PRAXIS_ICECLOUD_INVESTIGATORS_SKIN  : 49796,
    PRAXIS_TERCIO_ROJO_SKIN  : 74115,
    PRAXIS_VERSUS_BLUEFORCE_SKIN  : 58860,
    PRAXIS_VERSUS_REDFORCE_SKIN  : 58859,
    PRE_COMPLETED_CSM_10_BALLOT_PAPER  : 34423,
    PRECIOUS_ALLOY  : 11737,
    PRECIOUS_METALS  : 2399,
    PRECISE_COMPOUNDS  : 41305,
    PRECISE_CONDUCTORS  : 41306,
    PRECISE_ELECTRONICS  : 41304,
    PRECURSOR_BATTLECRUISER  : 49743,
    PRECURSOR_BATTLESHIP  : 47869,
    PRECURSOR_CRUISER  : 47868,
    PRECURSOR_DESTROYER  : 49742,
    PRECURSOR_DREADNOUGHT  : 52997,
    PRECURSOR_FRIGATE  : 47867,
    PREDICTIVE_LIGHT_CONE_PLOTTER  : 54808,
    PREMIER_TICKET_FOR_CLEAR_SKIES  : 33220,
    PREMIER_TICKET_FOR_DAY_OF_DARKNESS  : 33221,
    PREMIER_TICKET_FOR_THE_LAST_G_CAMPAIGN  : 33219,
    PRESS_PASS_TO_PROMETHEUS_STATION_OPENING  : 33215,
    PRESSURIZED_OXIDIZERS  : 57456,
    PRESSURIZED_OXIDIZERS_REACTION_FORMULA  : 57492,
    PRIMAE  : 2863,
    PRIMAE_GLACIAL_DRIFT_SKIN  : 46993,
    PRIMAE_RADIOACTIVES_RECLAMATION_SKIN  : 60955,
    PRIMAE_ROCKBREAKER_PIONEERS_SKIN  : 56611,
    PRIMAE_ROSADA_DAWN_SKIN  : 43675,
    PRIME_ARKONOR  : 17426,
    PRISMATIC_GNEISS  : 17866,
    PRISTINE_JASPET  : 17449,
    PRISTINE_WHITE_GLAZE  : 17976,
    PRIZE_MORDUNIUM  : 74523,
    PRNCR_10H_HIGHSEC_ICE_STORM_FILAMENT  : 74429,
    PRO_NAV_COMPACT_MISSILE_GUIDANCE_ENHANCER  : 35774,
    PRO_TRADE_PAMPHLETS  : 17755,
    PROBE  : 586,
    PROBE_ASPIRANT_EXPLORER_SKIN  : 60231,
    PROBE_BIOSECURITY_RESPONDERS_SKIN  : 55644,
    PROBE_BLUE_TIGER_SKIN  : 36800,
    PROBE_BLUEPRINT  : 690,
    PROBE_DAWN_OF_LIBERATION_SKIN  : 48487,
    PROBE_EXOPLANETS_HUNTER_SKIN  : 45935,
    PROBE_FLEET_ISSUE  : 72903,
    PROBE_FLEET_ISSUE_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 73841,
    PROBE_GLACIAL_DRIFT_SKIN  : 44162,
    PROBE_HAZARD_CONTROL_SKIN  : 56916,
    PROBE_LIBERATION_GAMES_SKIN  : 59463,
    PROBE_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 63031,
    PROBE_SNOWLINE_BLADERACER_SKIN  : 52846,
    PROBE_TRONHADAR_INK_SKIN  : 40469,
    PROBE_VALKLEAR_GLORY_SKIN  : 36753,
    PROBE_VERSUS_BLUEFORCE_SKIN  : 62019,
    PROBE_VHEROKIOR_SKIN  : 34747,
    PROBE_WARP_CONVERGENCE_SKIN  : 61875,
    PROCESS_DECRYPTOR  : 34205,
    PROCUREMENT  : 16594,
    PROCURER  : 17480,
    PROCURER_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43752,
    PROCURER_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43805,
    PROCURER_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43818,
    PROCURER_BLUEPRINT  : 17481,
    PROCURER_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43765,
    PROCURER_DIGMASTER_BLAZON_SKIN  : 42644,
    PROCURER_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43779,
    PROCURER_GLACIAL_DRIFT_SKIN  : 46984,
    PROCURER_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43831,
    PROCURER_LODESTRIKE_SKIN  : 46735,
    PROCURER_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43792,
    PROCURER_MORPHITE_SHINE_SKIN  : 42617,
    PROCURER_PAYDIRT_PROSPECTOR_SKIN  : 42674,
    PROCURER_RADIOACTIVES_RECLAMATION_SKIN  : 60947,
    PROCURER_ROCKBREAKER_PIONEERS_SKIN  : 56608,
    PROCURER_ROSADA_DAWN_SKIN  : 43666,
    PROCURER_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43844,
    PROCURER_SCOPE_SYNDICATION_YC122_SKIN  : 56885,
    PROGRAMMABLE_PURIFICATION_MEMBRANE  : 57480,
    PROGRAMMABLE_PURIFICATION_MEMBRANE_BLUEPRINT  : 57517,
    PROGRESS_REPORT_0466B__u2013_117_02_28  : 41029,
    PROJECT_COMPASS_STAR_CHARTS  : 37874,
    PROJECTILE_WEAPON_RIGGING  : 26257,
    PROMETHEUS_FORTIZAR  : 47516,
    PROMETHIUM  : 16652,
    PROMETHIUM_MERCURITE  : 33337,
    PROMETHIUM_MERCURITE_REACTION_FORMULA  : 46186,
    PROMETIUM  : 17960,
    PROMETIUM_REACTION_FORMULA  : 46184,
    PROPHECY  : 16233,
    PROPHECY_ABYSSAL_FIRESTORM_SKIN  : 53131,
    PROPHECY_BIOSECURITY_RESPONDERS_SKIN  : 55309,
    PROPHECY_BLOOD_RAIDERS_SKIN_30_DAYS  : 35434,
    PROPHECY_BLUEPRINT  : 16234,
    PROPHECY_CAPSULEER_DAY_XVII_SKIN  : 54760,
    PROPHECY_COLD_IRON_SKIN  : 42780,
    PROPHECY_CROWN_AND_SWORDS_SKIN  : 52773,
    PROPHECY_DEATHGLOW_REMNANT_SKIN  : 73369,
    PROPHECY_EXOPLANETS_HUNTER_SKIN  : 45789,
    PROPHECY_GLACIAL_DRIFT_SKIN  : 46798,
    PROPHECY_HUNTERS_QUIVER_SKIN  : 57530,
    PROPHECY_IMPERIAL_JUBILEE_SKIN  : 46531,
    PROPHECY_IMPERIAL_NAVY_SKIN  : 58901,
    PROPHECY_IRONBLOOD_SKIN  : 43512,
    PROPHECY_KADOR_SKIN  : 34606,
    PROPHECY_LUMINAIRE_ZENITH_SKIN  : 48221,
    PROPHECY_MALAGRIN_MARSHLIGHT_SKIN  : 56591,
    PROPHECY_NAVY_ISSUE  : 72872,
    PROPHECY_NAVY_ISSUE_CROWN_AND_SWORDS_SKIN  : 73872,
    PROPHECY_NAVY_ISSUE_IRONBLOOD_SKIN  : 73474,
    PROPHECY_NAVY_ISSUE_ORDER_OF_TETRIMON_SKIN  : 73465,
    PROPHECY_NAVY_ISSUE_VERSUS_BLUEFORCE_SKIN  : 73876,
    PROPHECY_OASIS_OF_FAITH_SKIN  : 53631,
    PROPHECY_PURITY_OF_THE_THRONE_SKIN  : 42580,
    PROPHECY_RAATA_SUNSET_SKIN  : 37512,
    PROPHECY_SANGUINARY_SAVANT_SKIN  : 73455,
    PROPHECY_TASH_MURKON_SKIN  : 34607,
    PROPHECY_TRIGLAVIAN_TWILIGHT_SKIN  : 52393,
    PROPHECY_WAR_RESERVES_LIEUTENANT_SKIN  : 59423,
    PROPHECY_WARP_CONVERGENCE_SKIN  : 61864,
    PROPULSION_JAMMING  : 3435,
    PROPULSION_SUBSYSTEM_TECHNOLOGY  : 30788,
    PRORATOR  : 12733,
    PRORATOR_BIOSECURITY_RESPONDERS_SKIN  : 55300,
    PRORATOR_BLUEPRINT  : 12734,
    PRORATOR_COLD_IRON_SKIN  : 42797,
    PRORATOR_EOM_SKIN  : 36300,
    PRORATOR_EXOPLANETS_HUNTER_SKIN  : 45806,
    PRORATOR_GLACIAL_DRIFT_SKIN  : 46815,
    PRORATOR_IMPERIAL_JUBILEE_SKIN  : 48859,
    PRORATOR_LODESTRIKE_SKIN  : 46745,
    PRORATOR_OASIS_OF_FAITH_SKIN  : 53648,
    PRORATOR_PURITY_OF_THE_THRONE_SKIN  : 42597,
    PRORATOR_RAATA_SUNSET_SKIN  : 37526,
    PRORATOR_STAR_CAPTAIN_SKIN  : 52586,
    PROSPECT  : 33697,
    PROSPECT_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43751,
    PROSPECT_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43804,
    PROSPECT_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43817,
    PROSPECT_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43764,
    PROSPECT_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43778,
    PROSPECT_GLACIAL_DRIFT_SKIN  : 46983,
    PROSPECT_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43830,
    PROSPECT_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43791,
    PROSPECT_MORPHITE_SHINE_SKIN  : 42616,
    PROSPECT_PAYDIRT_PROSPECTOR_SKIN  : 42673,
    PROSPECT_RADIOACTIVES_RECLAMATION_SKIN  : 60946,
    PROSPECT_ROCKBREAKER_PIONEERS_SKIN  : 56605,
    PROSPECT_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43843,
    PROSPECTOR_EM_SHIELD_AMPLIFIER  : 20611,
    PROTECTIVE_EQUIPMENT_PATTERNS  : 56716,
    PROTEIN_DELICACIES  : 11855,
    PROTEINS  : 2395,
    PROTEST_LARGE_ARMOR_REPAIRER_I  : 22891,
    PROTEUS  : 29988,
    PROTEUS_BIOSECURITY_RESPONDERS_SKIN  : 55604,
    PROTEUS_CORE___AUGMENTED_FUSION_REACTOR  : 45629,
    PROTEUS_CORE___ELECTRONIC_EFFICIENCY_GATE  : 45628,
    PROTEUS_CORE___FRICTION_EXTENSION_PROCESSOR  : 45630,
    PROTEUS_DEFENSIVE___AUGMENTED_PLATING  : 45593,
    PROTEUS_DEFENSIVE___COVERT_RECONFIGURATION  : 45592,
    PROTEUS_DEFENSIVE___NANOBOT_INJECTOR  : 45594,
    PROTEUS_EXOPLANETS_HUNTER_SKIN  : 45902,
    PROTEUS_GLACIAL_DRIFT_SKIN  : 46948,
    PROTEUS_INNER_ZONE_VANGUARD_SKIN  : 52737,
    PROTEUS_KOPIS_EDGE_SKIN  : 46414,
    PROTEUS_OFFENSIVE___DRONE_SYNTHESIS_PROJECTOR  : 45605,
    PROTEUS_OFFENSIVE___HYBRID_ENCODING_PLATFORM  : 45604,
    PROTEUS_OFFENSIVE___SUPPORT_PROCESSOR  : 45606,
    PROTEUS_PROPULSION___HYPERSPATIAL_OPTIMIZATION  : 45617,
    PROTEUS_PROPULSION___INTERDICTION_NULLIFIER  : 45616,
    PROTEUS_PROPULSION___LOCALIZED_INJECTORS  : 45618,
    PROTEUS_SERPENTIS_SKIN  : 42176,
    PROTEUS_SKYLINER_SKIN  : 46025,
    PROTON_L  : 196,
    PROTON_L_BLUEPRINT  : 897,
    PROTON_M  : 188,
    PROTON_M_BLUEPRINT  : 889,
    PROTON_S  : 180,
    PROTON_S_BLUEPRINT  : 881,
    PROTON_XL  : 17676,
    PROTON_XL_BLUEPRINT  : 17677,
    PROTOTYPE_ARBALEST_HEAVY_ASSAULT_MISSILE_LAUNCHER_I  : 25707,
    PROTOTYPE_ARBALEST_RAPID_LIGHT_MISSILE_LAUNCHER  : 8027,
    PROTOTYPE_ARBALEST_TORPEDO_LAUNCHER  : 8117,
    PROTOTYPE_CLOAKING_DEVICE_I  : 11370,
    PROTOTYPE_CLOAKING_DEVICE_I_BLUEPRINT  : 13000,
    PROTOTYPE_COMPACT_EM_ARMOR_HARDENER_I  : 16359,
    PROTOTYPE_COMPACT_EXPLOSIVE_ARMOR_HARDENER_I  : 16367,
    PROTOTYPE_COMPACT_KINETIC_ARMOR_HARDENER_I  : 16375,
    PROTOTYPE_COMPACT_THERMAL_ARMOR_HARDENER_I  : 16383,
    PROTOTYPE_HYPERSPATIAL_ACCELERATOR  : 33985,
    PROTOTYPE_JUMP_DRIVE_ECONOMIZER  : 34126,
    PROTOTYPE_PONCHO_CLOAKING_DEVICE_I  : 20561,
    PROTOTYPE_PRECISE_DUAL_1000MM_RAILGUN  : 41141,
    PROTOTYPE_PRECISE_DUAL_1000MM_RAILGUN_BLUEPRINT  : 41148,
    PROTOTYPE_PRECISE_HEXA_2500MM_REPEATING_CANNON  : 41153,
    PROTOTYPE_PRECISE_HEXA_2500MM_REPEATING_CANNON_BLUEPRINT  : 41165,
    PROTOTYPE_PRECISE_QUAD_3500MM_SIEGE_ARTILLERY  : 41159,
    PROTOTYPE_PRECISE_QUAD_3500MM_SIEGE_ARTILLERY_BLUEPRINT  : 41171,
    PROTOTYPE_PRECISE_QUAD_800MM_REPEATING_CANNON  : 41082,
    PROTOTYPE_PRECISE_QUAD_800MM_REPEATING_CANNON_BLUEPRINT  : 41096,
    PROVIDENCE  : 20183,
    PROVIDENCE_ARDISHAPUR_SKIN  : 34666,
    PROVIDENCE_BIOSECURITY_RESPONDERS_SKIN  : 55321,
    PROVIDENCE_BLUEPRINT  : 20184,
    PROVIDENCE_COLD_IRON_SKIN  : 42799,
    PROVIDENCE_CROWN_AND_SWORDS_SKIN  : 52782,
    PROVIDENCE_EOM_SKIN  : 36310,
    PROVIDENCE_EXOPLANETS_HUNTER_SKIN  : 45808,
    PROVIDENCE_GLACIAL_DRIFT_SKIN  : 46817,
    PROVIDENCE_IMPERIAL_JUBILEE_SKIN  : 46537,
    PROVIDENCE_KADOR_SKIN  : 34667,
    PROVIDENCE_OASIS_OF_FAITH_SKIN  : 53650,
    PROVIDENCE_PURITY_OF_THE_THRONE_SKIN  : 42599,
    PROVIDENCE_RAATA_SUNSET_SKIN  : 37528,
    PROVIDENCE_STAR_CAPTAIN_SKIN  : 48369,
    PROVIDENCE_WAR_RESERVES_LIEUTENANT_SKIN  : 62855,
    PROVIDENCE_YOIUL_STAR_SKIN  : 57157,
    PROWLER  : 12735,
    PROWLER_BIOSECURITY_RESPONDERS_SKIN  : 55697,
    PROWLER_BLUE_TIGER_SKIN  : 36841,
    PROWLER_BLUEPRINT  : 12736,
    PROWLER_EXOPLANETS_HUNTER_SKIN  : 45987,
    PROWLER_GALNET_STREAMCAST_UNIT_SKIN  : 57739,
    PROWLER_GLACIAL_DRIFT_SKIN  : 46924,
    PROWLER_HAZARD_CONTROL_SKIN  : 56934,
    PROWLER_JUSTICE_SKIN  : 36437,
    PROWLER_LODESTRIKE_SKIN  : 46748,
    PROWLER_SCOPE_SYNDICATION_YC122_SKIN  : 61193,
    PROWLER_SNOWLINE_BLADERACER_SKIN  : 52898,
    PROWLER_TRONHADAR_INK_SKIN  : 40512,
    PROWLER_VALKLEAR_GLORY_SKIN  : 36794,
    PROWLER_VERSUS_BLUEFORCE_SKIN  : 63868,
    PROXIMITY_15_EXTRACTION_FILAMENT  : 56069,
    PROXIMITY_5_EXTRACTION_FILAMENT  : 56068,
    PUBLIC_PORTRAIT_HOW_TO  : 33061,
    PULSAR_FLARE_FIREWORK  : 44264,
    PULSE_ACTIVATED_NEXUS_INVULNERABILITY_CORE  : 42522,
    PULSE_ACTIVATED_NEXUS_INVULNERABILITY_CORE_BLUEPRINT  : 42889,
    PULSE_SHIELD_EMITTER  : 11556,
    PULSE_SHIELD_EMITTER_BLUEPRINT  : 17341,
    PUNISHER  : 597,
    PUNISHER_AURORA_UNIVERSALIS_SKIN  : 60907,
    PUNISHER_BIOSECURITY_RESPONDERS_SKIN  : 55306,
    PUNISHER_BLOOD_RAIDERS_SKIN  : 60668,
    PUNISHER_BLUEPRINT  : 944,
    PUNISHER_CHROMODYNAMIC_CANDY_SKIN  : 49798,
    PUNISHER_COLD_IRON_SKIN  : 42750,
    PUNISHER_CORDITE_BLOSSOM_SKIN  : 48830,
    PUNISHER_EXOPLANETS_HUNTER_SKIN  : 45757,
    PUNISHER_GLACIAL_DRIFT_SKIN  : 44138,
    PUNISHER_HARMONIOUS_ASCENSION_SKIN  : 53207,
    PUNISHER_IMPERIAL_NAVY_SKIN  : 58894,
    PUNISHER_IRONBLOOD_SKIN  : 43486,
    PUNISHER_KADOR_SKIN  : 34728,
    PUNISHER_KHANID_SKIN  : 44200,
    PUNISHER_OASIS_OF_FAITH_SKIN  : 53599,
    PUNISHER_PURITY_OF_THE_THRONE_SKIN  : 42548,
    PUNISHER_RAATA_SUNSET_SKIN  : 37488,
    PUNISHER_SANGUINARY_SAVANT_SKIN  : 73456,
    PUNISHER_SARUM_SKIN  : 60677,
    PUNISHER_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60213,
    PUNISHER_TASH_MURKON_SKIN  : 34729,
    PUNISHER_WAR_RESERVES_LIEUTENANT_SKIN  : 59213,
    PUNISHER_ZAKURA_SHUMYU_SKIN  : 57056,
    PURE_IMPROVED_BLUE_PILL_BOOSTER  : 25241,
    PURE_IMPROVED_CRASH_BOOSTER  : 25335,
    PURE_IMPROVED_DROP_BOOSTER  : 25336,
    PURE_IMPROVED_EXILE_BOOSTER  : 25337,
    PURE_IMPROVED_FRENTIX_BOOSTER  : 25339,
    PURE_IMPROVED_MINDFLOOD_BOOSTER  : 25338,
    PURE_IMPROVED_SOOTH_SAYER_BOOSTER  : 25341,
    PURE_IMPROVED_X_INSTINCT_BOOSTER  : 25340,
    PURE_JASPET  : 17448,
    PURE_STANDARD_BLUE_PILL_BOOSTER  : 25237,
    PURE_STANDARD_CRASH_BOOSTER  : 25242,
    PURE_STANDARD_DROP_BOOSTER  : 25330,
    PURE_STANDARD_EXILE_BOOSTER  : 25331,
    PURE_STANDARD_FRENTIX_BOOSTER  : 25252,
    PURE_STANDARD_MINDFLOOD_BOOSTER  : 25332,
    PURE_STANDARD_SOOTH_SAYER_BOOSTER  : 25334,
    PURE_STANDARD_X_INSTINCT_BOOSTER  : 25333,
    PURE_STRONG_BLUE_PILL_BOOSTER  : 25283,
    PURE_STRONG_CRASH_BOOSTER  : 25342,
    PURE_STRONG_DROP_BOOSTER  : 25343,
    PURE_STRONG_EXILE_BOOSTER  : 25344,
    PURE_STRONG_FRENTIX_BOOSTER  : 25346,
    PURE_STRONG_MINDFLOOD_BOOSTER  : 25345,
    PURE_STRONG_SOOTH_SAYER_BOOSTER  : 25348,
    PURE_STRONG_X_INSTINCT_BOOSTER  : 25347,
    PURE_SYNTH_BLUE_PILL_BOOSTER  : 28686,
    PURE_SYNTH_CRASH_BOOSTER  : 28687,
    PURE_SYNTH_DROP_BOOSTER  : 28688,
    PURE_SYNTH_EXILE_BOOSTER  : 28689,
    PURE_SYNTH_FRENTIX_BOOSTER  : 28690,
    PURE_SYNTH_MINDFLOOD_BOOSTER  : 28691,
    PURE_SYNTH_SOOTH_SAYER_BOOSTER  : 28692,
    PURE_SYNTH_X_INSTINCT_BOOSTER  : 28693,
    PURGATIVE_COFFIN  : 49651,
    PURIFIER  : 12038,
    PURIFIER_AURORA_UNIVERSALIS_SKIN  : 60906,
    PURIFIER_BIOSECURITY_RESPONDERS_SKIN  : 55299,
    PURIFIER_BLOOD_RAIDERS_SKIN  : 60669,
    PURIFIER_BLUEPRINT  : 12041,
    PURIFIER_COLD_IRON_SKIN  : 42755,
    PURIFIER_CROWN_AND_SWORDS_SKIN  : 52769,
    PURIFIER_EOM_SKIN  : 36299,
    PURIFIER_EXOPLANETS_HUNTER_SKIN  : 45762,
    PURIFIER_GLACIAL_DRIFT_SKIN  : 46777,
    PURIFIER_IMPERIAL_JUBILEE_SKIN  : 46525,
    PURIFIER_MALAGRIN_MARSHLIGHT_SKIN  : 60564,
    PURIFIER_OASIS_OF_FAITH_SKIN  : 53604,
    PURIFIER_PURITY_OF_THE_THRONE_SKIN  : 42553,
    PURIFIER_RAATA_SUNSET_SKIN  : 37493,
    PURIFIER_SARUM_SKIN  : 60678,
    PURIFIER_SCOPE_SYNDICATION_YC122_SKIN  : 61194,
    PURIFIER_STAR_CAPTAIN_SKIN  : 52574,
    PURLOINED_SANSHA_DATA_ANALYZER  : 3581,
    PYERITE  : 35,
    PYROXERES  : 1224,
    PYROXERES_PROCESSING  : 12192,
    QA_TACTICAL_CAPSULEER_RECLONER  : 56569,
    QUAD_3500MM_SIEGE_ARTILLERY_I  : 20454,
    QUAD_3500MM_SIEGE_ARTILLERY_I_BLUEPRINT  : 20455,
    QUAD_3500MM_SIEGE_ARTILLERY_II  : 37307,
    QUAD_800MM_REPEATING_CANNON_I  : 37289,
    QUAD_800MM_REPEATING_CANNON_I_BLUEPRINT  : 41092,
    QUAD_800MM_REPEATING_CANNON_II  : 37304,
    QUAD_AFOCAL_LIGHT_MASER_I  : 6759,
    QUAD_ANODE_LIGHT_PARTICLE_STREAM_I  : 6763,
    QUAD_LIGHT_BEAM_LASER_I  : 455,
    QUAD_LIGHT_BEAM_LASER_I_BLUEPRINT  : 839,
    QUAD_LIGHT_BEAM_LASER_II  : 3285,
    QUAD_MEGA_PULSE_LASER_I  : 37290,
    QUAD_MEGA_PULSE_LASER_I_BLUEPRINT  : 41068,
    QUAD_MEGA_PULSE_LASER_II  : 37296,
    QUAD_MODAL_LIGHT_LASER_I  : 6757,
    QUAD_MODULATED_LIGHT_ENERGY_BEAM_I  : 6761,
    QUAFE  : 3699,
    QUAFE_HYPER  : 57422,
    QUAFE_ULTRA  : 12865,
    QUAFE_ULTRA_SPECIAL_EDITION  : 12994,
    QUAFE_ZERO_CLASSIC  : 3898,
    QUAFE_ZERO_GREEN_APPLE  : 60575,
    QUAKE_L  : 12761,
    QUAKE_M  : 12767,
    QUAKE_S  : 12631,
    QUAKE_XL  : 41320,
    QUANTUM_FLUX_GENERATOR_1  : 2058,
    QUANTUM_FLUX_GENERATOR_1_BLUEPRINT  : 34850,
    QUANTUM_FLUX_GENERATOR_2  : 2059,
    QUANTUM_FLUX_GENERATOR_2_BLUEPRINT  : 34851,
    QUANTUM_FLUX_GENERATOR_3  : 2060,
    QUANTUM_FLUX_GENERATOR_3_BLUEPRINT  : 34852,
    QUANTUM_FLUX_GENERATOR_4  : 2061,
    QUANTUM_FLUX_GENERATOR_4_BLUEPRINT  : 34853,
    QUANTUM_FLUX_GENERATOR_5  : 2062,
    QUANTUM_FLUX_GENERATOR_5_BLUEPRINT  : 34854,
    QUANTUM_MICROPROCESSOR  : 11540,
    QUANTUM_MICROPROCESSOR_BLUEPRINT  : 17351,
    QUANTUM_PHYSICS  : 11455,
    QUERY_SPAGHETTI_OR_CHICKEN_SANDWICH  : 48748,
    QUEST_SURVEY_PROBE_I  : 18626,
    QUEST_SURVEY_PROBE_I_BLUEPRINT  : 32839,
    R_O_TRIGGER_NEUROLINK_CONDUIT  : 57471,
    R_O_TRIGGER_NEUROLINK_CONDUIT_BLUEPRINT  : 57508,
    RAATA_WIND_CHIMES  : 48141,
    RABISU  : 42245,
    RACKET_LIGHT_NEUTRON_BLASTER_I  : 22903,
    RADAR_BACKUP_ARRAY_I_BLUEPRINT  : 11836,
    RADAR_ECM_I  : 1956,
    RADAR_ECM_I_BLUEPRINT  : 11632,
    RADAR_ECM_II  : 2575,
    RADAR_FTL_INTERLINK_COMMUNICATOR  : 57474,
    RADAR_FTL_INTERLINK_COMMUNICATOR_BLUEPRINT  : 57511,
    RADAR_SENSOR_CLUSTER  : 11537,
    RADAR_SENSOR_CLUSTER_BLUEPRINT  : 17336,
    RADAR_SENSOR_COMPENSATION  : 33002,
    RADIANT_HEMORPHITE  : 17445,
    RADIATION_ABSORPTION_THRUSTER  : 52310,
    RADIATION_ROGUE_DISPLAY  : 56973,
    RADICAL_DAMAGE_CONTROL  : 23418,
    RADICAL_DRONE_DAMAGE_AMPLIFIER_MUTAPLASMID  : 60476,
    RADICAL_FIGHTER_SUPPORT_UNIT_MUTAPLASMID  : 60477,
    RADIO_L  : 255,
    RADIO_L_BLUEPRINT  : 1170,
    RADIO_M  : 247,
    RADIO_M_BLUEPRINT  : 1162,
    RADIO_S  : 239,
    RADIO_S_BLUEPRINT  : 1154,
    RADIO_XL  : 17688,
    RADIO_XL_BLUEPRINT  : 17689,
    RAELEKS_TAG  : 17642,
    RAGING_DARK_FILAMENT  : 47894,
    RAGING_ELECTRICAL_FILAMENT  : 47906,
    RAGING_EXOTIC_FILAMENT  : 47890,
    RAGING_FIRESTORM_FILAMENT  : 47898,
    RAGING_GAMMA_FILAMENT  : 47902,
    RAGNAROK  : 23773,
    RAGNAROK_BIOSECURITY_RESPONDERS_SKIN  : 55638,
    RAGNAROK_BLUE_TIGER_SKIN  : 36837,
    RAGNAROK_BLUEPRINT  : 23774,
    RAGNAROK_CAPSULEER_ELITE_XV_SKIN  : 47645,
    RAGNAROK_CHAINBREAKER_KHUMAAK_SKIN  : 48526,
    RAGNAROK_EROS_BLOSSOM_SKIN  : 50085,
    RAGNAROK_EXOPLANETS_HUNTER_SKIN  : 45983,
    RAGNAROK_GLACIAL_DRIFT_SKIN  : 46920,
    RAGNAROK_HAZARD_CONTROL_SKIN  : 56938,
    RAGNAROK_HEADHUNTER_SKIN  : 49431,
    RAGNAROK_INTERSTELLAR_CONVERGENCE_SKIN  : 62228,
    RAGNAROK_JUSTICE_SKIN  : 36450,
    RAGNAROK_SHAKIM_WARLORD_SKIN  : 47371,
    RAGNAROK_SNOWLINE_BLADERACER_SKIN  : 52894,
    RAGNAROK_TRONHADAR_INK_SKIN  : 40508,
    RAGNAROK_VALKLEAR_GLORY_SKIN  : 36790,
    RAIJU  : 60765,
    RAITARU  : 35825,
    RAITARU_BLUEPRINT  : 36971,
    RAITARU_UPWELL_QUANTUM_CORE  : 56203,
    RAKOVENE  : 52315,
    RAKOVENE_PROCESSING  : 56633,
    RAM__AMMUNITION_TECH  : 11476,
    RAM__AMMUNITION_TECH_BLUEPRINT  : 11872,
    RAM__ARMOR_HULL_TECH  : 11475,
    RAM__ARMOR_HULL_TECH_BLUEPRINT  : 11873,
    RAM__CYBERNETICS  : 11485,
    RAM__ELECTRONICS  : 11483,
    RAM__ELECTRONICS_BLUEPRINT  : 11870,
    RAM__ENERGY_TECH  : 11482,
    RAM__ENERGY_TECH_BLUEPRINT  : 11859,
    RAM__ROBOTICS  : 11481,
    RAM__ROBOTICS_BLUEPRINT  : 11887,
    RAM__SHIELD_TECH  : 11484,
    RAM__SHIELD_TECH_BLUEPRINT  : 11889,
    RAM__STARSHIP_TECH  : 11478,
    RAM__STARSHIP_TECH_BLUEPRINT  : 11890,
    RAM__WEAPON_TECH  : 11486,
    RAM__WEAPON_TECH_BLUEPRINT  : 11891,
    RAPID_DEPLOYMENT_CHARGE  : 42840,
    RAPID_DEPLOYMENT_CHARGE_BLUEPRINT  : 42888,
    RAPID_EQUIPMENT_ASSEMBLY_ARRAY  : 16220,
    RAPID_FIRING  : 3310,
    RAPID_HEAVY_MISSILE_LAUNCHER_I  : 33448,
    RAPID_HEAVY_MISSILE_LAUNCHER_I_BLUEPRINT  : 33449,
    RAPID_HEAVY_MISSILE_LAUNCHER_II  : 33450,
    RAPID_LAUNCH  : 21071,
    RAPID_LIGHT_MISSILE_LAUNCHER_I  : 1875,
    RAPID_LIGHT_MISSILE_LAUNCHER_I_BLUEPRINT  : 1876,
    RAPID_LIGHT_MISSILE_LAUNCHER_II  : 1877,
    RAPID_REPAIR_CHARGE  : 42833,
    RAPID_REPAIR_CHARGE_BLUEPRINT  : 42876,
    RAPID_TORPEDO_LAUNCHER_I  : 37288,
    RAPID_TORPEDO_LAUNCHER_I_BLUEPRINT  : 41225,
    RAPID_TORPEDO_LAUNCHER_II  : 37292,
    RAPIER  : 11963,
    RAPIER_BIOSECURITY_RESPONDERS_SKIN  : 55696,
    RAPIER_BLUE_TIGER_SKIN  : 36823,
    RAPIER_BLUEPRINT  : 11964,
    RAPIER_DAWN_OF_LIBERATION_SKIN  : 48510,
    RAPIER_EXOPLANETS_HUNTER_SKIN  : 45963,
    RAPIER_GLACIAL_DRIFT_SKIN  : 46900,
    RAPIER_HAZARD_CONTROL_SKIN  : 56897,
    RAPIER_JUSTICE_SKIN  : 36431,
    RAPIER_LIBERATION_GAMES_SKIN  : 59472,
    RAPIER_SNOWLINE_BLADERACER_SKIN  : 52874,
    RAPIER_TRONHADAR_INK_SKIN  : 40493,
    RAPIER_VALKLEAR_GLORY_SKIN  : 36776,
    RAPIER_VERSUS_REDFORCE_SKIN  : 63869,
    RAPTOR  : 11178,
    RAPTOR_AURORA_UNIVERSALIS_SKIN  : 57006,
    RAPTOR_BIOSECURITY_RESPONDERS_SKIN  : 55555,
    RAPTOR_BLUE_TIGER_SKIN  : 36717,
    RAPTOR_BLUEPRINT  : 11179,
    RAPTOR_CAPSULEER_DAY_XV_SKIN  : 47627,
    RAPTOR_EXOPLANETS_HUNTER_SKIN  : 45824,
    RAPTOR_GHOSTBIRD_SKIN  : 48167,
    RAPTOR_GLACIAL_DRIFT_SKIN  : 46834,
    RAPTOR_MATIGU_SEABEAST_SKIN  : 40591,
    RAPTOR_RAATA_SUNSET_SKIN  : 36645,
    RAPTOR_STATE_POLICE_SKIN  : 72555,
    RAPTOR_STEEL_CARDINAL_SKIN  : 46101,
    RAPTOR_WIYRKOMI_SKIN  : 36336,
    RAPTOR_ZENTO_ISIDEKO_COMBINE_SKIN  : 53161,
    RARE_MOON_MINING_CRYSTAL_TYPE_A_I  : 46369,
    RARE_MOON_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 46359,
    RARE_MOON_MINING_CRYSTAL_TYPE_A_II  : 46370,
    RARE_MOON_MINING_CRYSTAL_TYPE_B_I  : 61209,
    RARE_MOON_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 61229,
    RARE_MOON_MINING_CRYSTAL_TYPE_B_II  : 61211,
    RARE_MOON_MINING_CRYSTAL_TYPE_C_I  : 61210,
    RARE_MOON_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 61230,
    RARE_MOON_MINING_CRYSTAL_TYPE_C_II  : 61212,
    RARE_MOON_ORE_PROCESSING  : 46155,
    RASH_COMPACT_BURST_JAMMER  : 5363,
    RATION_GRABBER_DISPLAY  : 56979,
    RATTLESNAKE  : 17918,
    RATTLESNAKE_ACE_OF_PODHUNTERS_SKIN  : 47511,
    RATTLESNAKE_AETHERIC_RAIDERS_SKIN  : 52705,
    RATTLESNAKE_GALNET_STREAMCAST_UNIT_SKIN  : 76486,
    RATTLESNAKE_GLACIAL_DRIFT_SKIN  : 46875,
    RATTLESNAKE_HELMATTS_HARRIERS_SKIN  : 54231,
    RATTLESNAKE_HOSTILE_TAKEOVER_SKIN  : 45509,
    RATTLESNAKE_IGC_SKIN  : 48554,
    RATTLESNAKE_KINGS_RANSOM_DAROUEN_SKIN  : 47030,
    RATTLESNAKE_KINGS_RANSOM_LUECIN_SKIN  : 47029,
    RATTLESNAKE_RED_STARGAZER_SKIN  : 75906,
    RATTLESNAKE_REDCLAW_SABLE_SKIN  : 56957,
    RATTLESNAKE_VAMPIRES_HEX_SKIN  : 57815,
    RATTLESNAKE_VERSUS_REDFORCE_SKIN  : 63644,
    RAVEN  : 638,
    RAVEN_ACE_OF_PODHUNTERS_SKIN  : 63206,
    RAVEN_AURORA_UNIVERSALIS_SKIN  : 53374,
    RAVEN_BIOSECURITY_RESPONDERS_SKIN  : 55523,
    RAVEN_BLUE_TIGER_SKIN  : 36736,
    RAVEN_BLUEPRINT  : 688,
    RAVEN_CALDARI_NAVY_SKIN  : 58882,
    RAVEN_CALDARI_UNION_DAY_SKIN  : 48732,
    RAVEN_CRYPTIC_MIMESIS_SKIN  : 48894,
    RAVEN_DEATHGLOW_REMNANT_SKIN  : 56782,
    RAVEN_EXOPLANETS_HUNTER_SKIN  : 45852,
    RAVEN_FORWARD_4_4_SKIN  : 56864,
    RAVEN_GHOSTBIRD_SKIN  : 48195,
    RAVEN_GILDED_PREDATOR_SKIN  : 77174,
    RAVEN_GLACIAL_DRIFT_SKIN  : 46856,
    RAVEN_GURISTAS_SKIN  : 34797,
    RAVEN_HEADHUNTER_SKIN  : 46715,
    RAVEN_HUNTERS_QUIVER_SKIN  : 47498,
    RAVEN_IGC_VICTORY_SKIN  : 45012,
    RAVEN_INTERSTELLAR_CONVERGENCE_SKIN  : 62224,
    RAVEN_MALAGRIN_MARSHLIGHT_SKIN  : 60566,
    RAVEN_MATIGU_SEABEAST_SKIN  : 40612,
    RAVEN_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59225,
    RAVEN_NAVY_ISSUE  : 17636,
    RAVEN_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55543,
    RAVEN_NAVY_ISSUE_CRYPTIC_MIMESIS_SKIN  : 60453,
    RAVEN_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45857,
    RAVEN_NAVY_ISSUE_FORWARD_4_4_SKIN  : 58862,
    RAVEN_NAVY_ISSUE_GHOSTBIRD_SKIN  : 48200,
    RAVEN_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46861,
    RAVEN_NAVY_ISSUE_HUNTERS_QUIVER_SKIN  : 57780,
    RAVEN_NAVY_ISSUE_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47618,
    RAVEN_NAVY_ISSUE_STEEL_CARDINAL_SKIN  : 46134,
    RAVEN_NAVY_ISSUE_WIYRKOMI_SKIN  : 36354,
    RAVEN_NAVY_ISSUE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53194,
    RAVEN_OCTOPUS_ORANGE_FLIGHT_SKIN  : 47617,
    RAVEN_OCTOPUS_RED_FLIGHT_SKIN  : 46732,
    RAVEN_RAATA_SUNSET_SKIN  : 36664,
    RAVEN_SCOPE_SYNDICATION_YC122_SKIN  : 56887,
    RAVEN_STATE_ISSUE  : 26840,
    RAVEN_STATE_POLICE_SKIN  : 72552,
    RAVEN_STEEL_CARDINAL_SKIN  : 46129,
    RAVEN_UNION_DAY_EXECUTIVE_SKIN  : 71731,
    RAVEN_VALKYRIE_SKIN  : 46376,
    RAVEN_WIYRKOMI_SKIN  : 34629,
    RAVEN_ZAKURA_SHUMYU_SKIN  : 57055,
    RAVEN_ZENTO_ISIDEKO_COMBINE_SKIN  : 53189,
    RAYSERE_GIANTS_TAG  : 17301,
    RAYSERES_MODIFIED_CAP_RECHARGER  : 21817,
    RAYSERES_MODIFIED_CAPACITOR_POWER_RELAY  : 15086,
    RAYSERES_MODIFIED_DUAL_HEAVY_BEAM_LASER  : 14421,
    RAYSERES_MODIFIED_EM_ARMOR_HARDENER  : 15022,
    RAYSERES_MODIFIED_EM_COATING  : 14910,
    RAYSERES_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14974,
    RAYSERES_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15070,
    RAYSERES_MODIFIED_EXPLOSIVE_COATING  : 14894,
    RAYSERES_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14990,
    RAYSERES_MODIFIED_HEAT_SINK  : 14806,
    RAYSERES_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15134,
    RAYSERES_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14838,
    RAYSERES_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14822,
    RAYSERES_MODIFIED_KINETIC_ARMOR_HARDENER  : 15054,
    RAYSERES_MODIFIED_KINETIC_COATING  : 14878,
    RAYSERES_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15006,
    RAYSERES_MODIFIED_LARGE_ARMOR_REPAIRER  : 14851,
    RAYSERES_MODIFIED_LARGE_EMP_SMARTBOMB  : 14790,
    RAYSERES_MODIFIED_MEGA_BEAM_LASER  : 14437,
    RAYSERES_MODIFIED_MULTISPECTRUM_COATING  : 14862,
    RAYSERES_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14942,
    RAYSERES_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15102,
    RAYSERES_MODIFIED_REACTOR_CONTROL_UNIT  : 15118,
    RAYSERES_MODIFIED_TACHYON_BEAM_LASER  : 14453,
    RAYSERES_MODIFIED_THERMAL_ARMOR_HARDENER  : 15038,
    RAYSERES_MODIFIED_THERMAL_COATING  : 14926,
    RAYSERES_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14958,
    RDB___BOUNDLESS_CREATIONS  : 11461,
    RDB___BOUNDLESS_CREATIONS_BLUEPRINT  : 11876,
    RDB___CARTHUM_CONGLOMERATE  : 11459,
    RDB___CARTHUM_CONGLOMERATE_BLUEPRINT  : 11880,
    RDB___CORE_COMPLEXION  : 11462,
    RDB___CORE_COMPLEXION_BLUEPRINT  : 11877,
    RDB___CREODRON  : 11466,
    RDB___CREODRON_BLUEPRINT  : 11878,
    RDB___DUVOLLE_LABS  : 11467,
    RDB___DUVOLLE_LABS_BLUEPRINT  : 11879,
    RDB___ISHUKONE  : 11463,
    RDB___ISHUKONE_BLUEPRINT  : 11885,
    RDB___KAALAKIOTA  : 11464,
    RDB___KAALAKIOTA_BLUEPRINT  : 11881,
    RDB___KHANID_INNOVATION  : 11458,
    RDB___KHANID_INNOVATION_BLUEPRINT  : 11882,
    RDB___LAI_DAI  : 11746,
    RDB___LAI_DAI_BLUEPRINT  : 11886,
    RDB___RODEN_SHIPYARDS  : 11465,
    RDB___RODEN_SHIPYARDS_BLUEPRINT  : 11857,
    RDB___THUKKER_MIX  : 11460,
    RDB___THUKKER_MIX_BLUEPRINT  : 11883,
    RDB___VIZIAM  : 11457,
    RDB___VIZIAM_BLUEPRINT  : 11884,
    RDLF_10L_LOWSEC_ICE_STORM_FILAMENT  : 74430,
    REACTION_ORIENTING_NEUROLINK_STABILIZER  : 57467,
    REACTION_ORIENTING_NEUROLINK_STABILIZER_REACTION_FORMULA  : 57504,
    REACTIONS  : 45746,
    REACTIVE_ARMOR_HARDENER  : 4403,
    REACTIVE_GAS  : 2311,
    REACTIVE_METALS  : 2398,
    REACTOR_CONTROL_UNIT_I  : 1353,
    REACTOR_CONTROL_UNIT_I_BLUEPRINT  : 1354,
    REACTOR_CONTROL_UNIT_II  : 1355,
    REAPER  : 588,
    REAPER_BIOSECURITY_RESPONDERS_SKIN  : 55642,
    REAPER_BRUTOR_SKIN  : 59868,
    REAPER_EXOPLANETS_HUNTER_SKIN  : 45932,
    REAPER_GLACIAL_DRIFT_SKIN  : 44160,
    REAPER_KRUSUAL_SKIN  : 59828,
    REAPER_NEFANTAR_SKIN  : 59820,
    REAPER_SEBIESTOR_SKIN  : 59842,
    REAPER_STARKMANIR_SKIN  : 59860,
    REAPER_THUKKER_TRIBE_SKIN  : 59844,
    REAPER_VHEROKIOR_SKIN  : 59851,
    RECON_SHIPS  : 22761,
    RECONFIGURED_SUBSPACE_CALIBRATOR  : 30478,
    RECONFIGURED_SUBSPACE_CALIBRATOR_BLUEPRINT  : 30479,
    RECURSIVE_COMPUTING_MODULE  : 2871,
    RECUSANT_HOSTILE_TARGETING_ARRAY_I  : 6043,
    RECYCLER_RAIDER_DISPLAY  : 56975,
    REDEEMER  : 22428,
    REDEEMER_BIOSECURITY_RESPONDERS_SKIN  : 55298,
    REDEEMER_COLD_IRON_SKIN  : 42787,
    REDEEMER_CROWN_AND_SWORDS_SKIN  : 52779,
    REDEEMER_EOM_SKIN  : 36311,
    REDEEMER_EROS_BLOSSOM_SKIN  : 50070,
    REDEEMER_EXOPLANETS_HUNTER_SKIN  : 45796,
    REDEEMER_FIREBLADE_GUERILLA_SKIN  : 59327,
    REDEEMER_GLACIAL_DRIFT_SKIN  : 46805,
    REDEEMER_HEADHUNTER_SKIN  : 49420,
    REDEEMER_ICEBLADE_GUERILLA_SKIN  : 59332,
    REDEEMER_IMPERIAL_JUBILEE_SKIN  : 48857,
    REDEEMER_IMPERIAL_NAVY_SKIN  : 58903,
    REDEEMER_LAVACORE_IMPERIAL_SKIN  : 49819,
    REDEEMER_OASIS_OF_FAITH_SKIN  : 53638,
    REDEEMER_PURITY_OF_THE_THRONE_SKIN  : 42587,
    REDEEMER_RAATA_SUNSET_SKIN  : 37518,
    REDEEMER_STAR_CAPTAIN_SKIN  : 52585,
    REDEEMER_VERSUS_BLUEFORCE_SKIN  : 63858,
    REDOUBT_ELEMENT  : 34560,
    REDOUBT_INDEX  : 34555,
    REDOUBT_SEQUENCE_0  : 34548,
    REDOUBT_SEQUENCE_1  : 34549,
    REFUGEES  : 3806,
    REGULATED_COMPACT_ION_SIEGE_BLASTER  : 41126,
    REGULATED_COMPACT_ION_SIEGE_BLASTER_BLUEPRINT  : 41132,
    REGULATED_COMPACT_TRIPLE_NEUTRON_BLASTER_CANNON  : 37301,
    REGULATED_COMPACT_TRIPLE_NEUTRON_BLASTER_CANNON_BLUEPRINT  : 41086,
    REGULATED_ELECTRON_PHASE_CANNON_I  : 7623,
    REGULATED_ION_PHASE_CANNON_I  : 7667,
    REGULATED_LIGHT_ELECTRON_PHASE_CANNON_I  : 7491,
    REGULATED_LIGHT_ION_PHASE_CANNON_I  : 7539,
    REGULATED_LIGHT_NEUTRON_PHASE_CANNON_I  : 7583,
    REGULATED_MEGA_ELECTRON_PHASE_CANNON_I  : 7747,
    REGULATED_MEGA_ION_PHASE_CANNON_I  : 7831,
    REGULATED_MEGA_NEUTRON_PHASE_CANNON_I  : 7787,
    REGULATED_NEUTRON_PHASE_CANNON_I  : 7707,
    REINFORCED_BULKHEADS_I  : 1333,
    REINFORCED_BULKHEADS_I_BLUEPRINT  : 1334,
    REINFORCED_BULKHEADS_II  : 1335,
    REINFORCED_CARBON_FIBER  : 57457,
    REINFORCED_CARBON_FIBER_REACTION_FORMULA  : 57493,
    REINFORCED_METAL_SCRAPS  : 30497,
    REINFORCED_METALLOFULLERENE_ALLOYS  : 30008,
    REINFORCED_METALLOFULLERENE_ALLOYS_BLUEPRINT  : 30009,
    REJECTED_SHIP_REPLACEMENT_PROGRAM_REQUEST  : 44211,
    RELIC_ANALYZER_I  : 22177,
    RELIC_ANALYZER_I_BLUEPRINT  : 22178,
    RELIC_ANALYZER_II  : 30832,
    REMEMBER_MYRSKAA_FLYERS  : 47044,
    REMOTE_ARMOR_REPAIR_SYSTEMS  : 16069,
    REMOTE_HULL_REPAIR_SYSTEMS  : 27902,
    REMOTE_REACTIONS  : 45750,
    REMOTE_SENSING  : 13279,
    REMOTE_SENSOR_BOOSTER_I  : 1963,
    REMOTE_SENSOR_BOOSTER_I_BLUEPRINT  : 11798,
    REMOTE_SENSOR_BOOSTER_II  : 1964,
    REMOTE_SENSOR_DAMPENER_I  : 1968,
    REMOTE_SENSOR_DAMPENER_I_BLUEPRINT  : 11803,
    REMOTE_SENSOR_DAMPENER_II  : 1969,
    REMOTE_TRACKING_COMPUTER_I  : 2103,
    REMOTE_TRACKING_COMPUTER_I_BLUEPRINT  : 11617,
    REMOTE_TRACKING_COMPUTER_II  : 2104,
    REPAIR_DRONE_OPERATION  : 3439,
    REPAIR_SYSTEMS  : 3393,
    REPLETE_CARNOTITE  : 46304,
    REPLETE_CINNABAR  : 46310,
    REPLETE_POLLUCITE  : 46308,
    REPLETE_ZIRCON  : 46306,
    REPLICA_GALLENTE_CRUISER  : 33029,
    REPLICA_GOLD_MAGNATE_DECORATIVE_PLATING  : 45062,
    REPLICA_MISS_FEDERATION_TROPHY  : 48137,
    REPLICA_MISTER_FEDERATION_TROPHY  : 48136,
    REPLICA_OF_THE_ISCSS_VENTURE  : 48144,
    REPORTS  : 3814,
    REPOSE_CORE_COMPENSATION  : 16299,
    REPROCESSING  : 3385,
    REPROCESSING_ARRAY  : 12238,
    REPROCESSING_EFFICIENCY  : 3389,
    REPUBLIC_CIVILIAN_CONTAINER  : 43679,
    REPUBLIC_FLEET_100MN_AFTERBURNER  : 15757,
    REPUBLIC_FLEET_10MN_AFTERBURNER  : 15753,
    REPUBLIC_FLEET_1200MM_ARTILLERY  : 16047,
    REPUBLIC_FLEET_125MM_AUTOCANNON  : 16046,
    REPUBLIC_FLEET_1400MM_HOWITZER_ARTILLERY  : 16048,
    REPUBLIC_FLEET_150MM_AUTOCANNON  : 16049,
    REPUBLIC_FLEET_1MN_AFTERBURNER  : 15749,
    REPUBLIC_FLEET_200MM_AUTOCANNON  : 16050,
    REPUBLIC_FLEET_220MM_AUTOCANNON  : 16051,
    REPUBLIC_FLEET_250MM_ARTILLERY  : 16052,
    REPUBLIC_FLEET_280MM_HOWITZER_ARTILLERY  : 16053,
    REPUBLIC_FLEET_425MM_AUTOCANNON  : 16054,
    REPUBLIC_FLEET_500MN_MICROWARPDRIVE  : 15755,
    REPUBLIC_FLEET_50MN_MICROWARPDRIVE  : 15751,
    REPUBLIC_FLEET_5MN_MICROWARPDRIVE  : 15747,
    REPUBLIC_FLEET_650MM_ARTILLERY  : 16055,
    REPUBLIC_FLEET_720MM_HOWITZER_ARTILLERY  : 16056,
    REPUBLIC_FLEET_800MM_REPEATING_CANNON  : 16057,
    REPUBLIC_FLEET_BALLISTIC_CONTROL_SYSTEM  : 15683,
    REPUBLIC_FLEET_BERSERKER  : 31892,
    REPUBLIC_FLEET_BOUNCER  : 31894,
    REPUBLIC_FLEET_CAPTAIN_INSIGNIA_I  : 15630,
    REPUBLIC_FLEET_CARBONIZED_LEAD_L  : 28324,
    REPUBLIC_FLEET_CARBONIZED_LEAD_M  : 28326,
    REPUBLIC_FLEET_CARBONIZED_LEAD_S  : 28328,
    REPUBLIC_FLEET_CARBONIZED_LEAD_XL  : 28330,
    REPUBLIC_FLEET_COMMAND_MINDLINK  : 33405,
    REPUBLIC_FLEET_COMMANDER_INSIGNIA_I  : 15632,
    REPUBLIC_FLEET_COMMANDER_INSIGNIA_II  : 15661,
    REPUBLIC_FLEET_CRUISE_MISSILE_LAUNCHER  : 17485,
    REPUBLIC_FLEET_DEPLETED_URANIUM_L  : 28332,
    REPUBLIC_FLEET_DEPLETED_URANIUM_M  : 28334,
    REPUBLIC_FLEET_DEPLETED_URANIUM_S  : 28336,
    REPUBLIC_FLEET_DEPLETED_URANIUM_XL  : 28338,
    REPUBLIC_FLEET_DUAL_180MM_AUTOCANNON  : 16058,
    REPUBLIC_FLEET_DUAL_425MM_AUTOCANNON  : 16059,
    REPUBLIC_FLEET_DUAL_650MM_REPEATING_CANNON  : 16060,
    REPUBLIC_FLEET_EM_ARMOR_HARDENER  : 15719,
    REPUBLIC_FLEET_EM_COATING  : 15697,
    REPUBLIC_FLEET_EM_SHIELD_AMPLIFIER  : 15917,
    REPUBLIC_FLEET_EMP_L  : 21894,
    REPUBLIC_FLEET_EMP_M  : 21896,
    REPUBLIC_FLEET_EMP_S  : 21898,
    REPUBLIC_FLEET_EMP_XL  : 21900,
    REPUBLIC_FLEET_EXPLOSIVE_ARMOR_HARDENER  : 15717,
    REPUBLIC_FLEET_EXPLOSIVE_COATING  : 15699,
    REPUBLIC_FLEET_EXPLOSIVE_SHIELD_AMPLIFIER  : 15923,
    REPUBLIC_FLEET_FIRETAIL  : 17812,
    REPUBLIC_FLEET_FIRETAIL_AIR_LABORATORIES_SKIN  : 60265,
    REPUBLIC_FLEET_FIRETAIL_BIOSECURITY_RESPONDERS_SKIN  : 55671,
    REPUBLIC_FLEET_FIRETAIL_BRUTOR_SKIN  : 59870,
    REPUBLIC_FLEET_FIRETAIL_DAWN_OF_LIBERATION_SKIN  : 48499,
    REPUBLIC_FLEET_FIRETAIL_EXOPLANETS_HUNTER_SKIN  : 45947,
    REPUBLIC_FLEET_FIRETAIL_FREEDOMS_REVOLUTION_SKIN  : 59316,
    REPUBLIC_FLEET_FIRETAIL_GLACIAL_DRIFT_SKIN  : 46888,
    REPUBLIC_FLEET_FIRETAIL_JUSTICE_SKIN  : 36443,
    REPUBLIC_FLEET_FIRETAIL_KRUSUAL_SKIN  : 59830,
    REPUBLIC_FLEET_FIRETAIL_LIBERATION_GAMES_SKIN  : 59464,
    REPUBLIC_FLEET_FIRETAIL_NEFANTAR_SKIN  : 59822,
    REPUBLIC_FLEET_FIRETAIL_SEBIESTOR_SKIN  : 59837,
    REPUBLIC_FLEET_FIRETAIL_SNOWLINE_BLADERACER_SKIN  : 52858,
    REPUBLIC_FLEET_FIRETAIL_STARKMANIR_SKIN  : 59862,
    REPUBLIC_FLEET_FIRETAIL_THUKKER_TRIBE_SKIN  : 59846,
    REPUBLIC_FLEET_FIRETAIL_VERSUS_REDFORCE_SKIN  : 73879,
    REPUBLIC_FLEET_FIRETAIL_VHEROKIOR_SKIN  : 59854,
    REPUBLIC_FLEET_FUSION_L  : 21902,
    REPUBLIC_FLEET_FUSION_M  : 21904,
    REPUBLIC_FLEET_FUSION_S  : 21906,
    REPUBLIC_FLEET_FUSION_XL  : 21908,
    REPUBLIC_FLEET_GYROSTABILIZER  : 15806,
    REPUBLIC_FLEET_HEAVY_ASSAULT_MISSILE_LAUNCHER  : 28375,
    REPUBLIC_FLEET_HEAVY_MISSILE_LAUNCHER  : 17487,
    REPUBLIC_FLEET_HIGH_CAPTAIN_INSIGNIA_I  : 15631,
    REPUBLIC_FLEET_KINETIC_ARMOR_HARDENER  : 15715,
    REPUBLIC_FLEET_KINETIC_COATING  : 15701,
    REPUBLIC_FLEET_KINETIC_SHIELD_AMPLIFIER  : 15919,
    REPUBLIC_FLEET_LARGE_ARMOR_REPAIRER  : 17492,
    REPUBLIC_FLEET_LARGE_CAP_BATTERY  : 41218,
    REPUBLIC_FLEET_LARGE_PROTON_SMARTBOMB  : 15939,
    REPUBLIC_FLEET_LARGE_SHIELD_BOOSTER  : 15904,
    REPUBLIC_FLEET_LARGE_SHIELD_EXTENDER  : 31932,
    REPUBLIC_FLEET_LIGHT_MISSILE_LAUNCHER  : 17491,
    REPUBLIC_FLEET_MEDIUM_ARMOR_REPAIRER  : 17493,
    REPUBLIC_FLEET_MEDIUM_CAP_BATTERY  : 41215,
    REPUBLIC_FLEET_MEDIUM_PROTON_SMARTBOMB  : 15937,
    REPUBLIC_FLEET_MEDIUM_SHIELD_BOOSTER  : 15903,
    REPUBLIC_FLEET_MEDIUM_SHIELD_EXTENDER  : 31928,
    REPUBLIC_FLEET_MICRO_PROTON_SMARTBOMB  : 15933,
    REPUBLIC_FLEET_MIDSHIPMAN_INSIGNIA_I  : 15625,
    REPUBLIC_FLEET_MIDSHIPMAN_INSIGNIA_II  : 15626,
    REPUBLIC_FLEET_MIDSHIPMAN_INSIGNIA_III  : 15627,
    REPUBLIC_FLEET_MULTISPECTRUM_COATING  : 15703,
    REPUBLIC_FLEET_NANOFIBER_STRUCTURE  : 15813,
    REPUBLIC_FLEET_NAVY_COMMANDER_INSIGNIA  : 15664,
    REPUBLIC_FLEET_NAVY_REAR_ADMIRAL_INSIGNIA  : 28231,
    REPUBLIC_FLEET_NOVA_AUTO_TARGETING_CRUISE_MISSILE_I  : 27477,
    REPUBLIC_FLEET_NOVA_AUTO_TARGETING_HEAVY_MISSILE_I  : 27501,
    REPUBLIC_FLEET_NOVA_AUTO_TARGETING_LIGHT_MISSILE_I  : 27525,
    REPUBLIC_FLEET_NUCLEAR_L  : 21910,
    REPUBLIC_FLEET_NUCLEAR_M  : 21912,
    REPUBLIC_FLEET_NUCLEAR_S  : 21914,
    REPUBLIC_FLEET_NUCLEAR_XL  : 21916,
    REPUBLIC_FLEET_OVERDRIVE_INJECTOR  : 15812,
    REPUBLIC_FLEET_PHASED_PLASMA_L  : 21918,
    REPUBLIC_FLEET_PHASED_PLASMA_M  : 21922,
    REPUBLIC_FLEET_PHASED_PLASMA_S  : 21924,
    REPUBLIC_FLEET_PHASED_PLASMA_XL  : 21920,
    REPUBLIC_FLEET_PRIVATE_ELITE_INSIGNIA  : 15666,
    REPUBLIC_FLEET_PRIVATE_INSIGNIA_I  : 15628,
    REPUBLIC_FLEET_PRIVATE_INSIGNIA_II  : 15629,
    REPUBLIC_FLEET_PRIVATE_INSIGNIA_III  : 15660,
    REPUBLIC_FLEET_PRIVATE_INSIGNIA_IV  : 15997,
    REPUBLIC_FLEET_PRIVATE_INSIGNIA_V  : 15998,
    REPUBLIC_FLEET_PROTON_L  : 21926,
    REPUBLIC_FLEET_PROTON_M  : 21928,
    REPUBLIC_FLEET_PROTON_S  : 21931,
    REPUBLIC_FLEET_PROTON_XL  : 21933,
    REPUBLIC_FLEET_RAID_LEADER_INSIGNIA  : 15663,
    REPUBLIC_FLEET_RAPID_HEAVY_MISSILE_LAUNCHER  : 33459,
    REPUBLIC_FLEET_RAPID_LIGHT_MISSILE_LAUNCHER  : 17484,
    REPUBLIC_FLEET_ROCKET_LAUNCHER  : 17488,
    REPUBLIC_FLEET_SHIELD_BOOST_AMPLIFIER  : 15907,
    REPUBLIC_FLEET_SHIELD_RECHARGER  : 37805,
    REPUBLIC_FLEET_SMALL_ARMOR_REPAIRER  : 17494,
    REPUBLIC_FLEET_SMALL_CAP_BATTERY  : 41212,
    REPUBLIC_FLEET_SMALL_PROTON_SMARTBOMB  : 15935,
    REPUBLIC_FLEET_SMALL_SHIELD_BOOSTER  : 15902,
    REPUBLIC_FLEET_SMALL_SHIELD_EXTENDER  : 31924,
    REPUBLIC_FLEET_SQUAD_LEADER_INSIGNIA  : 15662,
    REPUBLIC_FLEET_STASIS_WEBIFIER  : 41038,
    REPUBLIC_FLEET_TARGET_PAINTER  : 31944,
    REPUBLIC_FLEET_THERMAL_ARMOR_HARDENER  : 15713,
    REPUBLIC_FLEET_THERMAL_COATING  : 15695,
    REPUBLIC_FLEET_THERMAL_SHIELD_AMPLIFIER  : 15921,
    REPUBLIC_FLEET_TITANIUM_SABOT_L  : 21935,
    REPUBLIC_FLEET_TITANIUM_SABOT_M  : 21937,
    REPUBLIC_FLEET_TITANIUM_SABOT_S  : 21939,
    REPUBLIC_FLEET_TITANIUM_SABOT_XL  : 21941,
    REPUBLIC_FLEET_TORPEDO_LAUNCHER  : 17490,
    REPUBLIC_FLEET_TRACKING_ENHANCER  : 15965,
    REPUBLIC_FLEET_VALKYRIE  : 31890,
    REPUBLIC_FLEET_WARP_DISRUPTOR  : 15891,
    REPUBLIC_FLEET_WARP_SCRAMBLER  : 15893,
    REPUBLIC_FLEET_WARRIOR  : 31888,
    REPUBLIC_FLEET_X_LARGE_SHIELD_BOOSTER  : 15901,
    REPUBLIC_JUSTICE_DEPARTMENT_BRONZE_REWARD  : 48534,
    REPUBLIC_JUSTICE_DEPARTMENT_GOLD_REWARD  : 48536,
    REPUBLIC_JUSTICE_DEPARTMENT_SILVER_REWARD  : 48535,
    REPUBLIC_SPECIAL_OPS_FIELD_ENHANCER___GAMMA  : 22715,
    RESEARCH  : 3403,
    RESEARCH_COMPONENT  : 40456,
    RESEARCH_LABORATORY  : 16216,
    RESEARCH_PROJECT_MANAGEMENT  : 12179,
    RESEARCH_TOOLS  : 15353,
    RESISTANCE_PHASING  : 32797,
    RESONANCE_CALIBRATION_MATRIX  : 30258,
    RESPLENDANT_KERNITE  : 46683,
    RESPONSIVE_AUTO_TARGETING_SYSTEM_I  : 6045,
    RESTRAINED_COMPOUNDS  : 41308,
    RESTRAINED_CONDUCTORS  : 41309,
    RESTRAINED_ELECTRONICS  : 41307,
    RETAIL  : 3444,
    RETIRED_SERVER_HAMSTER  : 48750,
    RETRIBUTION  : 11393,
    RETRIBUTION_BIOSECURITY_RESPONDERS_SKIN  : 55348,
    RETRIBUTION_BLUEPRINT  : 11394,
    RETRIBUTION_COLD_IRON_SKIN  : 42752,
    RETRIBUTION_EOM_SKIN  : 36292,
    RETRIBUTION_EXOPLANETS_HUNTER_SKIN  : 45759,
    RETRIBUTION_GALNET_STREAMCAST_UNIT_SKIN  : 57736,
    RETRIBUTION_GLACIAL_DRIFT_SKIN  : 46774,
    RETRIBUTION_IRONBLOOD_SKIN  : 43488,
    RETRIBUTION_MALAGRIN_MARSHLIGHT_SKIN  : 60563,
    RETRIBUTION_OASIS_OF_FAITH_SKIN  : 53601,
    RETRIBUTION_ORDER_OF_TETRIMON_SKIN  : 73460,
    RETRIBUTION_PURITY_OF_THE_THRONE_SKIN  : 42550,
    RETRIBUTION_RAATA_SUNSET_SKIN  : 37490,
    RETRIBUTION_SANGUINARY_SAVANT_SKIN  : 73457,
    RETRIBUTION_STAR_CAPTAIN_SKIN  : 52571,
    RETRIBUTION_YOIUL_STAR_SKIN  : 57156,
    RETRIEVER  : 17478,
    RETRIEVER_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43753,
    RETRIEVER_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43806,
    RETRIEVER_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43819,
    RETRIEVER_BLUEPRINT  : 17479,
    RETRIEVER_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43766,
    RETRIEVER_DIGMASTER_BLAZON_SKIN  : 42645,
    RETRIEVER_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43780,
    RETRIEVER_GLACIAL_DRIFT_SKIN  : 46985,
    RETRIEVER_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43832,
    RETRIEVER_LODESTRIKE_SKIN  : 46736,
    RETRIEVER_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43793,
    RETRIEVER_MORPHITE_SHINE_SKIN  : 42618,
    RETRIEVER_PAYDIRT_PROSPECTOR_SKIN  : 42675,
    RETRIEVER_RADIOACTIVES_RECLAMATION_SKIN  : 60948,
    RETRIEVER_ROCKBREAKER_PIONEERS_SKIN  : 56609,
    RETRIEVER_ROSADA_DAWN_SKIN  : 43667,
    RETRIEVER_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43845,
    REVELATION  : 19720,
    REVELATION_BIOSECURITY_RESPONDERS_SKIN  : 55314,
    REVELATION_BLUEPRINT  : 19721,
    REVELATION_CAPSULEER_ELITE_XIX_SKIN  : 63685,
    REVELATION_COLD_IRON_SKIN  : 42791,
    REVELATION_CROWN_AND_SWORDS_SKIN  : 52780,
    REVELATION_EOM_SKIN  : 36307,
    REVELATION_EXOPLANETS_HUNTER_SKIN  : 45800,
    REVELATION_GLACIAL_DRIFT_SKIN  : 46809,
    REVELATION_IMPERIAL_JUBILEE_SKIN  : 46538,
    REVELATION_IRONBLOOD_SKIN  : 43520,
    REVELATION_KADOR_SKIN  : 34659,
    REVELATION_LAVACORE_IMPERIAL_SKIN  : 49822,
    REVELATION_NAVY_ISSUE  : 73790,
    REVELATION_NAVY_ISSUE_HEARTSURGE_SKIN  : 76294,
    REVELATION_OASIS_OF_FAITH_SKIN  : 53642,
    REVELATION_PURITY_OF_THE_THRONE_SKIN  : 42591,
    REVELATION_RAATA_SUNSET_SKIN  : 37520,
    REVELATION_SARUM_SKIN  : 34660,
    REVELATION_SPIRIT_SKIN  : 47308,
    REVELATION_STAR_CAPTAIN_SKIN  : 48365,
    REVELATION_STARLINE_MAJESTIC_SKIN  : 55721,
    REVELATION_YOIUL_STAR_SKIN  : 53421,
    REVENANT  : 3514,
    REVENANT_GLACIAL_DRIFT_SKIN  : 47009,
    REVENANT_TRUE_DELIVERANCE_SKIN  : 52598,
    RHEA  : 28844,
    RHEA_BIOSECURITY_RESPONDERS_SKIN  : 55568,
    RHEA_BLUE_TIGER_SKIN  : 36750,
    RHEA_EXOPLANETS_HUNTER_SKIN  : 45868,
    RHEA_GHOSTBIRD_SKIN  : 48211,
    RHEA_GLACIAL_DRIFT_SKIN  : 46872,
    RHEA_MATIGU_SEABEAST_SKIN  : 40626,
    RHEA_RAATA_SUNSET_SKIN  : 36678,
    RHEA_STEEL_CARDINAL_SKIN  : 46145,
    RHEA_WIYRKOMI_SKIN  : 36362,
    RHEA_ZENTO_ISIDEKO_COMBINE_SKIN  : 53205,
    RICH_PLAGIOCLASE  : 17456,
    RIFTER  : 587,
    RIFTER_ABSENT_HEROES_SKIN  : 49156,
    RIFTER_AMARANTHINE_MANTLE_SKIN  : 57342,
    RIFTER_AURORA_UNIVERSALIS_SKIN  : 60919,
    RIFTER_BIOSECURITY_RESPONDERS_SKIN  : 55643,
    RIFTER_BLUE_TIGER_SKIN  : 36801,
    RIFTER_BLUEPRINT  : 691,
    RIFTER_CHAINBREAKER_KHUMAAK_SKIN  : 64587,
    RIFTER_DAWN_OF_LIBERATION_SKIN  : 48488,
    RIFTER_EXOPLANETS_HUNTER_SKIN  : 45936,
    RIFTER_FLEET_ORDNANCE_SPIKE_SKIN  : 59244,
    RIFTER_GLACIAL_DRIFT_SKIN  : 44166,
    RIFTER_HARMONIOUS_ASCENSION_SKIN  : 53210,
    RIFTER_HAZARD_CONTROL_SKIN  : 45554,
    RIFTER_JUSTICE_SKIN  : 36411,
    RIFTER_KRUSUAL_SKIN  : 34748,
    RIFTER_LIBERATION_GAMES_SKIN  : 59462,
    RIFTER_NEFANTAR_SKIN  : 34749,
    RIFTER_REPUBLIC_FLEET_SKIN  : 58864,
    RIFTER_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60222,
    RIFTER_SNOWLINE_BLADERACER_SKIN  : 52847,
    RIFTER_TRONHADAR_INK_SKIN  : 40470,
    RIFTER_VALKLEAR_GLORY_SKIN  : 36754,
    RIFTER_ZAKURA_SHUMYU_SKIN  : 57064,
    RIMEFROST_STARBURST_FIREWORK  : 57150,
    ROBOTICS  : 9848,
    ROCKET_FUEL  : 9830,
    ROCKET_LAUNCHER_I  : 10629,
    ROCKET_LAUNCHER_I_BLUEPRINT  : 10630,
    ROCKET_LAUNCHER_II  : 10631,
    ROCKET_SCIENCE  : 11449,
    ROCKET_SPECIALIZATION  : 20209,
    ROCKETS  : 3320,
    RODIVA  : 49712,
    RODIVA_BLACKFIRE_STEEL_SKIN  : 49925,
    RODIVA_DEADSPACE_DREAMTIME_SKIN  : 52418,
    RODIVA_HADEAN_HORROR_SKIN  : 49934,
    RODIVA_METAMATERIA_EXOTICA_SKIN  : 50142,
    RODIVA_RED_FORESTS_THUNDERBIRD_SKIN  : 52443,
    ROGUE_DRONE_42_X_NEXUS_CHIP  : 33620,
    ROGUE_DRONE_43_X_NEXUS_CHIP  : 33619,
    ROGUE_DRONE_46_X_NEXUS_CHIP  : 33618,
    ROGUE_DRONE_INFESTATION_DATA  : 60459,
    ROKH  : 24688,
    ROKH_ACE_OF_PODHUNTERS_SKIN  : 63205,
    ROKH_AURORA_UNIVERSALIS_SKIN  : 57019,
    ROKH_BIOSECURITY_RESPONDERS_SKIN  : 55522,
    ROKH_BLUE_TIGER_SKIN  : 36737,
    ROKH_BLUEPRINT  : 24689,
    ROKH_CALDARI_UNION_DAY_SKIN  : 48731,
    ROKH_CAPSULEER_DAY_XVII_SKIN  : 54768,
    ROKH_CAPSULEER_ELITE_XIX_SKIN  : 63687,
    ROKH_EXOPLANETS_HUNTER_SKIN  : 45853,
    ROKH_GHOSTBIRD_SKIN  : 48196,
    ROKH_GLACIAL_DRIFT_SKIN  : 46857,
    ROKH_MATIGU_SEABEAST_SKIN  : 40613,
    ROKH_NUGOEIHUVI_SKIN  : 34630,
    ROKH_PERMABAND___WRECKING_MACHINE_SKIN  : 52286,
    ROKH_RAATA_SUNSET_SKIN  : 36665,
    ROKH_STEEL_CARDINAL_SKIN  : 46130,
    ROKH_WIYRKOMI_SKIN  : 34631,
    ROKH_ZENTO_ISIDEKO_COMBINE_SKIN  : 53190,
    ROLLED_TUNGSTEN_ALLOY  : 16657,
    ROLLED_TUNGSTEN_ALLOY_REACTION_FORMULA  : 46178,
    ROOK  : 11959,
    ROOK_BIOSECURITY_RESPONDERS_SKIN  : 55563,
    ROOK_BLUE_TIGER_SKIN  : 36730,
    ROOK_BLUEPRINT  : 11960,
    ROOK_EXOPLANETS_HUNTER_SKIN  : 45842,
    ROOK_GHOSTBIRD_SKIN  : 48185,
    ROOK_GLACIAL_DRIFT_SKIN  : 46846,
    ROOK_GURISTAS_SKIN  : 54560,
    ROOK_IGC_SKIN  : 48549,
    ROOK_MATIGU_SEABEAST_SKIN  : 40606,
    ROOK_RAATA_SUNSET_SKIN  : 36658,
    ROOK_STEEL_CARDINAL_SKIN  : 46119,
    ROOK_WIYRKOMI_SKIN  : 36342,
    ROOK_ZENTO_ISIDEKO_COMBINE_SKIN  : 53179,
    ROOKS__KINGS___THE_CLARION_CALL_COMPILATION  : 34422,
    RORQUAL  : 28352,
    RORQUAL_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43760,
    RORQUAL_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43813,
    RORQUAL_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43826,
    RORQUAL_BLUEPRINT  : 28353,
    RORQUAL_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43773,
    RORQUAL_CAPSULEER_DAY_XVIII_SKIN  : 58824,
    RORQUAL_CAPSULEER_ELITE_XIX_SKIN  : 63696,
    RORQUAL_CLAIM_FOREMAN_SKIN  : 44071,
    RORQUAL_COPPER_LIGHTNING_SKIN  : 60762,
    RORQUAL_DESIGN_CHANGE_LOG  : 47042,
    RORQUAL_DIGMASTER_BLAZON_SKIN  : 42652,
    RORQUAL_FORGEMASTER_SKIN  : 46547,
    RORQUAL_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43787,
    RORQUAL_GLACIAL_DRIFT_SKIN  : 46992,
    RORQUAL_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43839,
    RORQUAL_LODESTRIKE_SKIN  : 46742,
    RORQUAL_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43800,
    RORQUAL_MORPHITE_SHINE_SKIN  : 42625,
    RORQUAL_NOCX_RUSH_SKIN  : 34646,
    RORQUAL_PAYDIRT_PROSPECTOR_SKIN  : 42682,
    RORQUAL_RADIOACTIVES_RECLAMATION_SKIN  : 60954,
    RORQUAL_ROCKBREAKER_PIONEERS_SKIN  : 56613,
    RORQUAL_ROSADA_DAWN_SKIN  : 43674,
    RORQUAL_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43852,
    RORQUAL_SOVEREIGN_CLAIM_SKIN  : 48735,
    ROYAL_DUCINIUM  : 74535,
    RSS_CORE_SCANNER_PROBE  : 32246,
    RULES_OF_ENGAGEMENT  : 33026,
    RUPTURE  : 629,
    RUPTURE_ASPIRANT_ENFORCER_SKIN  : 60238,
    RUPTURE_AURORA_UNIVERSALIS_SKIN  : 53363,
    RUPTURE_BIOSECURITY_RESPONDERS_SKIN  : 55651,
    RUPTURE_BLOODY_HANDS_SKIN  : 47161,
    RUPTURE_BLUE_TIGER_SKIN  : 36815,
    RUPTURE_BLUEPRINT  : 974,
    RUPTURE_BRUTOR_SKIN  : 59871,
    RUPTURE_CAPSULEER_DAY_XVII_SKIN  : 54798,
    RUPTURE_CHAINBREAKER_KHUMAAK_SKIN  : 64589,
    RUPTURE_CHROMODYNAMIC_CANDY_SKIN  : 49801,
    RUPTURE_DAWN_OF_LIBERATION_SKIN  : 48502,
    RUPTURE_EXOPLANETS_HUNTER_SKIN  : 45955,
    RUPTURE_FIREWALL_BREACH_SKIN  : 46017,
    RUPTURE_FLEET_ORDNANCE_SPIKE_SKIN  : 59245,
    RUPTURE_GLACIAL_DRIFT_SKIN  : 44172,
    RUPTURE_HAZARD_CONTROL_SKIN  : 56921,
    RUPTURE_HEADHUNTER_SKIN  : 46723,
    RUPTURE_KRUSUAL_SKIN  : 59831,
    RUPTURE_NEFANTAR_SKIN  : 59823,
    RUPTURE_PERMABAND_ROADIES_SKIN  : 54447,
    RUPTURE_REPUBLIC_FLEET_SKIN  : 58868,
    RUPTURE_SCOPE_SYNDICATION_YC122_SKIN  : 56883,
    RUPTURE_SEBIESTOR_SKIN  : 59838,
    RUPTURE_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60224,
    RUPTURE_SNOWLINE_BLADERACER_SKIN  : 52866,
    RUPTURE_STARKMANIR_SKIN  : 59863,
    RUPTURE_THUKKER_TRIBE_SKIN  : 59847,
    RUPTURE_TRONHADAR_INK_SKIN  : 40485,
    RUPTURE_VALKLEAR_GLORY_SKIN  : 36768,
    RUPTURE_VERSUS_BLUEFORCE_SKIN  : 62020,
    RUPTURE_VHEROKIOR_SKIN  : 59855,
    S_R_TRIGGER_NEUROLINK_CONDUIT  : 57472,
    S_R_TRIGGER_NEUROLINK_CONDUIT_BLUEPRINT  : 57509,
    SABRE  : 22456,
    SABRE_AURORA_UNIVERSALIS_SKIN  : 57013,
    SABRE_BIOSECURITY_RESPONDERS_SKIN  : 55694,
    SABRE_BLUE_TIGER_SKIN  : 36813,
    SABRE_BLUEPRINT  : 22457,
    SABRE_CHAINBREAKER_KHUMAAK_SKIN  : 64588,
    SABRE_CORDITE_BLOSSOM_SKIN  : 48838,
    SABRE_EROS_BLOSSOM_SKIN  : 50083,
    SABRE_EXOPLANETS_HUNTER_SKIN  : 45952,
    SABRE_GALNET_STREAMCAST_UNIT_SKIN  : 57738,
    SABRE_GLACIAL_DRIFT_SKIN  : 46891,
    SABRE_HAZARD_CONTROL_SKIN  : 45557,
    SABRE_IN_RUST_WE_TRUST_SKIN  : 52425,
    SABRE_JUSTICE_SKIN  : 36447,
    SABRE_PERMABAND___WARP_TO_THE_DANCE_FLOOR_SKIN  : 52287,
    SABRE_SNOWLINE_BLADERACER_SKIN  : 52863,
    SABRE_TRONHADAR_INK_SKIN  : 40482,
    SABRE_VALKLEAR_GLORY_SKIN  : 36766,
    SACRILEGE  : 12019,
    SACRILEGE_BIOSECURITY_RESPONDERS_SKIN  : 55346,
    SACRILEGE_BLUEPRINT  : 12020,
    SACRILEGE_COLD_IRON_SKIN  : 42770,
    SACRILEGE_CROWN_AND_SWORDS_SKIN  : 56193,
    SACRILEGE_EOM_SKIN  : 36298,
    SACRILEGE_EXOPLANETS_HUNTER_SKIN  : 45778,
    SACRILEGE_GLACIAL_DRIFT_SKIN  : 46787,
    SACRILEGE_IMPERIAL_JUBILEE_SKIN  : 46528,
    SACRILEGE_OASIS_OF_FAITH_SKIN  : 53620,
    SACRILEGE_PURITY_OF_THE_THRONE_SKIN  : 42569,
    SACRILEGE_RAATA_SUNSET_SKIN  : 37504,
    SACRILEGE_STAR_CAPTAIN_SKIN  : 52581,
    SADDLE_SMALL_CAPACITOR_BOOSTER_I  : 23807,
    SALVAGE_DRONE_I  : 32787,
    SALVAGE_DRONE_I_BLUEPRINT  : 32982,
    SALVAGE_DRONE_II  : 55760,
    SALVAGE_DRONE_OPERATION  : 3440,
    SALVAGE_DRONE_SPECIALIZATION  : 57164,
    SALVAGER_I  : 25861,
    SALVAGER_I_BLUEPRINT  : 25862,
    SALVAGER_II  : 30836,
    SALVAGING  : 25863,
    SALVATION_ANGELS_STRONG_BOX  : 43698,
    SANI_SABIK_MURDER_SECURITY_TAPES  : 49650,
    SANI_SABIK_SERMON  : 49648,
    SANSHA_BRASS_TAG  : 17212,
    SANSHA_BRONZE_TAG  : 12547,
    SANSHA_CLAWS_FAIRYTALE_BOOK  : 37878,
    SANSHA_CONTROL_TOWER  : 27780,
    SANSHA_CONTROL_TOWER_MEDIUM  : 27782,
    SANSHA_CONTROL_TOWER_SMALL  : 27784,
    SANSHA_COPPER_TAG  : 17210,
    SANSHA_CRYSTAL_TAG  : 12551,
    SANSHA_DIAMOND_TAG  : 17211,
    SANSHA_ELECTRUM_TAG  : 17214,
    SANSHA_ENERGY_NEUTRALIZING_BATTERY  : 27855,
    SANSHA_GOLD_TAG  : 12549,
    SANSHA_LARGE_BEAM_LASER_BATTERY  : 27766,
    SANSHA_LARGE_PULSE_LASER_BATTERY  : 27767,
    SANSHA_MEDIUM_BEAM_LASER_BATTERY  : 27768,
    SANSHA_MEDIUM_PULSE_LASER_BATTERY  : 27769,
    SANSHA_MODIFIED_GNOME_IMPLANT  : 32255,
    SANSHA_PALLADIUM_TAG  : 17213,
    SANSHA_PLATINUM_TAG  : 12550,
    SANSHA_SILVER_TAG  : 12548,
    SANSHA_SMALL_BEAM_LASER_BATTERY  : 27770,
    SANSHA_SMALL_PULSE_LASER_BATTERY  : 27771,
    SANSHA_VICTORY_SKIN  : 36854,
    SANSHAS_GAMMA_L  : 20837,
    SANSHAS_GAMMA_M  : 20829,
    SANSHAS_GAMMA_S  : 20821,
    SANSHAS_GAMMA_XL  : 20845,
    SANSHAS_INFRARED_L  : 20022,
    SANSHAS_INFRARED_M  : 20014,
    SANSHAS_INFRARED_S  : 19982,
    SANSHAS_INFRARED_XL  : 20030,
    SANSHAS_MICROWAVE_L  : 20020,
    SANSHAS_MICROWAVE_M  : 20012,
    SANSHAS_MICROWAVE_S  : 19980,
    SANSHAS_MICROWAVE_XL  : 20028,
    SANSHAS_MULTIFREQUENCY_L  : 20839,
    SANSHAS_MULTIFREQUENCY_M  : 20831,
    SANSHAS_MULTIFREQUENCY_S  : 20823,
    SANSHAS_MULTIFREQUENCY_XL  : 20847,
    SANSHAS_RADIO_L  : 20018,
    SANSHAS_RADIO_M  : 20010,
    SANSHAS_RADIO_S  : 19978,
    SANSHAS_RADIO_XL  : 20026,
    SANSHAS_STANDARD_L  : 20024,
    SANSHAS_STANDARD_M  : 20016,
    SANSHAS_STANDARD_S  : 19984,
    SANSHAS_STANDARD_XL  : 20032,
    SANSHAS_ULTRAVIOLET_L  : 20833,
    SANSHAS_ULTRAVIOLET_M  : 20825,
    SANSHAS_ULTRAVIOLET_S  : 20817,
    SANSHAS_ULTRAVIOLET_XL  : 20841,
    SANSHAS_XRAY_L  : 20835,
    SANSHAS_XRAY_M  : 20827,
    SANSHAS_XRAY_S  : 20819,
    SANSHAS_XRAY_XL  : 20843,
    SARISSA_THERMAL_DISRUPTIVE_LANCE  : 77401,
    SARISSA_THERMAL_DISRUPTIVE_LANCE_BLUEPRINT  : 77405,
    SARPATI_RESERVE_BRANDY  : 54164,
    SATYR_I  : 40360,
    SATYR_I_BLUEPRINT  : 41382,
    SATYR_II  : 40555,
    SCALAR_CAPACITOR_UNIT  : 11552,
    SCALAR_CAPACITOR_UNIT_BLUEPRINT  : 17327,
    SCALE_MODEL_OF_A_BLUE_BOX  : 62046,
    SCALE_MODEL_OF_RFS_MAHTU_REA  : 44210,
    SCALPEL  : 37460,
    SCALPEL_BIOSECURITY_RESPONDERS_SKIN  : 55678,
    SCALPEL_DAWN_OF_LIBERATION_SKIN  : 48498,
    SCALPEL_EMERGENCY_RESPONSE_SQUAD_SKIN  : 60196,
    SCALPEL_EXOPLANETS_HUNTER_SKIN  : 45946,
    SCALPEL_GLACIAL_DRIFT_SKIN  : 46887,
    SCALPEL_HAZARD_CONTROL_SKIN  : 56903,
    SCALPEL_SNOWLINE_BLADERACER_SKIN  : 52857,
    SCALPEL_TRONHADAR_INK_SKIN  : 40516,
    SCALPEL_VALKLEAR_GLORY_SKIN  : 40325,
    SCAN_ACQUISITION_ARRAY_I  : 33176,
    SCAN_ACQUISITION_ARRAY_I_BLUEPRINT  : 33177,
    SCAN_ACQUISITION_ARRAY_II  : 33197,
    SCAN_PINPOINTING_ARRAY_I  : 33178,
    SCAN_PINPOINTING_ARRAY_I_BLUEPRINT  : 33179,
    SCAN_PINPOINTING_ARRAY_II  : 33199,
    SCAN_RANGEFINDING_ARRAY_I  : 33180,
    SCAN_RANGEFINDING_ARRAY_I_BLUEPRINT  : 33181,
    SCAN_RANGEFINDING_ARRAY_II  : 33201,
    SCAN_RESOLUTION_DAMPENING_SCRIPT  : 29013,
    SCAN_RESOLUTION_DAMPENING_SCRIPT_BLUEPRINT  : 29014,
    SCAN_RESOLUTION_SCRIPT  : 29011,
    SCAN_RESOLUTION_SCRIPT_BLUEPRINT  : 29012,
    SCANDIUM  : 16639,
    SCANDIUM_METALLOFULLERENE  : 30308,
    SCANDIUM_METALLOFULLERENE_REACTION_FORMULA  : 46162,
    SCANNER_JACKER_DISPLAY  : 56980,
    SCARAB_I  : 40345,
    SCARAB_I_BLUEPRINT  : 41369,
    SCARAB_II  : 40569,
    SCARLET_EMBRACE_FIREWORK  : 73408,
    SCC_GUIDELINES___LOTTERIES_FOR_DUMMIES  : 34425,
    SCHEELITE  : 45497,
    SCIENCE  : 3402,
    SCIENCE_GRADUATES  : 12243,
    SCIENTIFIC_NETWORKING  : 24270,
    SCIMITAR  : 11978,
    SCIMITAR_BIOSECURITY_RESPONDERS_SKIN  : 55695,
    SCIMITAR_BLOODY_HANDS_SKIN  : 47166,
    SCIMITAR_BLUE_TIGER_SKIN  : 36821,
    SCIMITAR_BLUEPRINT  : 11979,
    SCIMITAR_DAWN_OF_LIBERATION_SKIN  : 48508,
    SCIMITAR_EMERGENCY_RESPONSE_SQUAD_SKIN  : 48726,
    SCIMITAR_EXOPLANETS_HUNTER_SKIN  : 45961,
    SCIMITAR_FRONTIER_SAFEGUARDER_SKIN  : 54436,
    SCIMITAR_GLACIAL_DRIFT_SKIN  : 46898,
    SCIMITAR_HAZARD_CONTROL_SKIN  : 56898,
    SCIMITAR_JUSTICE_SKIN  : 36432,
    SCIMITAR_REPUBLIC_FLEET_SKIN  : 59813,
    SCIMITAR_SNOWLINE_BLADERACER_SKIN  : 52872,
    SCIMITAR_TRONHADAR_INK_SKIN  : 40491,
    SCIMITAR_VALKLEAR_GLORY_SKIN  : 36774,
    SCINTILLATING_HEMORPHITE  : 46681,
    SCOPED_COMPOUNDS  : 41311,
    SCOPED_CONDUCTORS  : 41312,
    SCOPED_ELECTRONICS  : 41310,
    SCORCH_BOMB  : 27916,
    SCORCH_BOMB_BLUEPRINT  : 27917,
    SCORCH_L  : 12820,
    SCORCH_M  : 12818,
    SCORCH_S  : 12563,
    SCORCH_XL  : 41330,
    SCORCHED_MICROGRAVIMETER  : 45660,
    SCORCHED_TELEMETRY_PROCESSOR  : 25588,
    SCORDITE  : 1228,
    SCORDITE_PROCESSING  : 12193,
    SCORPION  : 640,
    SCORPION_ABYSSAL_FIRESTORM_SKIN  : 53134,
    SCORPION_BIOSECURITY_RESPONDERS_SKIN  : 55521,
    SCORPION_BLUE_TIGER_SKIN  : 36738,
    SCORPION_BLUEPRINT  : 994,
    SCORPION_EXOPLANETS_HUNTER_SKIN  : 45854,
    SCORPION_FORWARD_4_4_SKIN  : 56865,
    SCORPION_GHOSTBIRD_SKIN  : 48197,
    SCORPION_GLACIAL_DRIFT_SKIN  : 46858,
    SCORPION_GURISTAS_SKIN  : 40773,
    SCORPION_ISHUKONE_WATCH  : 4005,
    SCORPION_ISHUKONE_WATCH_SKIN  : 34632,
    SCORPION_MATIGU_SEABEAST_SKIN  : 40614,
    SCORPION_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 59219,
    SCORPION_NAVY_ISSUE  : 32309,
    SCORPION_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55544,
    SCORPION_NAVY_ISSUE_CAPSULEER_ELITE_XX_SKIN  : 77165,
    SCORPION_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45858,
    SCORPION_NAVY_ISSUE_FORWARD_4_4_SKIN  : 58863,
    SCORPION_NAVY_ISSUE_GHOSTBIRD_SKIN  : 48201,
    SCORPION_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46862,
    SCORPION_NAVY_ISSUE_SPIRIT_SKIN  : 57391,
    SCORPION_NAVY_ISSUE_STEEL_CARDINAL_SKIN  : 46135,
    SCORPION_NAVY_ISSUE_WIYRKOMI_SKIN  : 36364,
    SCORPION_NAVY_ISSUE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53195,
    SCORPION_RAATA_SUNSET_SKIN  : 36666,
    SCORPION_SPIRIT_SKIN  : 47313,
    SCORPION_STEEL_CARDINAL_SKIN  : 46131,
    SCORPION_TRIGLAVIAN_TWILIGHT_SKIN  : 52400,
    SCORPION_VALKYRIE_SKIN  : 41419,
    SCORPION_ZENTO_ISIDEKO_COMBINE_SKIN  : 53191,
    SCOTTY_THE_DOCKING_MANAGERS_CLONE  : 33019,
    SCOURGE_AUTO_TARGETING_CRUISE_MISSILE_I  : 1826,
    SCOURGE_AUTO_TARGETING_CRUISE_MISSILE_I_BLUEPRINT  : 1827,
    SCOURGE_AUTO_TARGETING_HEAVY_MISSILE_I  : 1818,
    SCOURGE_AUTO_TARGETING_HEAVY_MISSILE_I_BLUEPRINT  : 1819,
    SCOURGE_AUTO_TARGETING_LIGHT_MISSILE_I  : 1810,
    SCOURGE_AUTO_TARGETING_LIGHT_MISSILE_I_BLUEPRINT  : 1811,
    SCOURGE_CRUISE_MISSILE  : 203,
    SCOURGE_CRUISE_MISSILE_BLUEPRINT  : 804,
    SCOURGE_FURY_CRUISE_MISSILE  : 24533,
    SCOURGE_FURY_HEAVY_MISSILE  : 2629,
    SCOURGE_FURY_LIGHT_MISSILE  : 24495,
    SCOURGE_FURY_XL_CRUISE_MISSILE  : 41291,
    SCOURGE_HEAVY_ASSAULT_MISSILE  : 20307,
    SCOURGE_HEAVY_ASSAULT_MISSILE_BLUEPRINT  : 26762,
    SCOURGE_HEAVY_MISSILE  : 209,
    SCOURGE_HEAVY_MISSILE_BLUEPRINT  : 810,
    SCOURGE_JAVELIN_HEAVY_ASSAULT_MISSILE  : 24492,
    SCOURGE_JAVELIN_ROCKET  : 24477,
    SCOURGE_JAVELIN_TORPEDO  : 24529,
    SCOURGE_JAVELIN_XL_TORPEDO  : 41270,
    SCOURGE_LIGHT_MISSILE  : 210,
    SCOURGE_LIGHT_MISSILE_BLUEPRINT  : 811,
    SCOURGE_PRECISION_CRUISE_MISSILE  : 24541,
    SCOURGE_PRECISION_HEAVY_MISSILE  : 24513,
    SCOURGE_PRECISION_LIGHT_MISSILE  : 24501,
    SCOURGE_PRECISION_XL_CRUISE_MISSILE  : 41290,
    SCOURGE_RAGE_HEAVY_ASSAULT_MISSILE  : 2679,
    SCOURGE_RAGE_ROCKET  : 24471,
    SCOURGE_RAGE_TORPEDO  : 24521,
    SCOURGE_RAGE_XL_TORPEDO  : 41271,
    SCOURGE_ROCKET  : 266,
    SCOURGE_ROCKET_BLUEPRINT  : 1220,
    SCOURGE_TORPEDO  : 267,
    SCOURGE_TORPEDO_BLUEPRINT  : 1221,
    SCOURGE_XL_CRUISE_MISSILE  : 32436,
    SCOURGE_XL_CRUISE_MISSILE_BLUEPRINT  : 32437,
    SCOURGE_XL_TORPEDO  : 17859,
    SCOURGE_XL_TORPEDO_BLUEPRINT  : 17860,
    SCOUT_SCOPED_DUAL_1000MM_RAILGUN  : 41140,
    SCOUT_SCOPED_DUAL_1000MM_RAILGUN_BLUEPRINT  : 41146,
    SCOUT_SCOPED_HEXA_2500MM_REPEATING_CANNON  : 41152,
    SCOUT_SCOPED_HEXA_2500MM_REPEATING_CANNON_BLUEPRINT  : 41163,
    SCOUT_SCOPED_QUAD_3500MM_SIEGE_ARTILLERY  : 41158,
    SCOUT_SCOPED_QUAD_3500MM_SIEGE_ARTILLERY_BLUEPRINT  : 41170,
    SCOUT_SCOPED_QUAD_800MM_REPEATING_CANNON  : 41081,
    SCOUT_SCOPED_QUAD_800MM_REPEATING_CANNON_BLUEPRINT  : 41095,
    SCRAPMETAL_PROCESSING  : 12196,
    SCYTHE  : 631,
    SCYTHE_AURORA_UNIVERSALIS_SKIN  : 60920,
    SCYTHE_BIOSECURITY_RESPONDERS_SKIN  : 55650,
    SCYTHE_BLOODY_HANDS_SKIN  : 47164,
    SCYTHE_BLUE_TIGER_SKIN  : 36816,
    SCYTHE_BLUEPRINT  : 976,
    SCYTHE_COMBAT_MEDIC_SECTION_SKIN  : 48722,
    SCYTHE_DAWN_OF_LIBERATION_SKIN  : 48503,
    SCYTHE_EXOPLANETS_HUNTER_SKIN  : 45956,
    SCYTHE_FLEET_ISSUE  : 29336,
    SCYTHE_FLEET_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55669,
    SCYTHE_FLEET_ISSUE_BLOODY_HANDS_SKIN  : 47165,
    SCYTHE_FLEET_ISSUE_DAWN_OF_LIBERATION_SKIN  : 48512,
    SCYTHE_FLEET_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45965,
    SCYTHE_FLEET_ISSUE_GLACIAL_DRIFT_SKIN  : 46902,
    SCYTHE_FLEET_ISSUE_JUSTICE_SKIN  : 36455,
    SCYTHE_FLEET_ISSUE_LIBERATION_GAMES_SKIN  : 64910,
    SCYTHE_FLEET_ISSUE_SNOWLINE_BLADERACER_SKIN  : 52876,
    SCYTHE_GLACIAL_DRIFT_SKIN  : 44169,
    SCYTHE_GLACIAL_DRIFT_SKIN2  : 46893,
    SCYTHE_HAZARD_CONTROL_SKIN  : 56923,
    SCYTHE_LIBERATION_GAMES_SKIN  : 59466,
    SCYTHE_SNOWLINE_BLADERACER_SKIN  : 52867,
    SCYTHE_TRONHADAR_INK_SKIN  : 40486,
    SCYTHE_VALKLEAR_GLORY_SKIN  : 36769,
    SECOND_HAND_PARTS  : 23164,
    SECURE_COMMS_UPLINK_ALPHA_6  : 41027,
    SECURITY_CONNECTIONS  : 3895,
    SEED_MICRO_CAPACITOR_BOOSTER_I  : 4959,
    SELF_ASSEMBLING_NANOLATTICE  : 34396,
    SELF_ASSEMBLING_NANOLATTICE_BLUEPRINT  : 34397,
    SELF_HARMONIZING_POWER_CORE  : 2872,
    SELYNNE_MARDAKARS_TAG  : 17289,
    SELYNNES_MODIFIED_CAP_RECHARGER  : 16601,
    SELYNNES_MODIFIED_CAPACITOR_POWER_RELAY  : 15084,
    SELYNNES_MODIFIED_DUAL_HEAVY_BEAM_LASER  : 14417,
    SELYNNES_MODIFIED_EM_ARMOR_HARDENER  : 15020,
    SELYNNES_MODIFIED_EM_COATING  : 14908,
    SELYNNES_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14972,
    SELYNNES_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15068,
    SELYNNES_MODIFIED_EXPLOSIVE_COATING  : 14892,
    SELYNNES_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14988,
    SELYNNES_MODIFIED_HEAT_SINK  : 14804,
    SELYNNES_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15132,
    SELYNNES_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14836,
    SELYNNES_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14820,
    SELYNNES_MODIFIED_KINETIC_ARMOR_HARDENER  : 15052,
    SELYNNES_MODIFIED_KINETIC_COATING  : 14876,
    SELYNNES_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15004,
    SELYNNES_MODIFIED_LARGE_ARMOR_REPAIRER  : 14850,
    SELYNNES_MODIFIED_LARGE_EMP_SMARTBOMB  : 14788,
    SELYNNES_MODIFIED_MEGA_BEAM_LASER  : 14433,
    SELYNNES_MODIFIED_MULTISPECTRUM_COATING  : 14860,
    SELYNNES_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14940,
    SELYNNES_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15100,
    SELYNNES_MODIFIED_REACTOR_CONTROL_UNIT  : 15116,
    SELYNNES_MODIFIED_TACHYON_BEAM_LASER  : 14449,
    SELYNNES_MODIFIED_THERMAL_ARMOR_HARDENER  : 15036,
    SELYNNES_MODIFIED_THERMAL_COATING  : 14924,
    SELYNNES_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14956,
    SENSE_HEURISTIC_NEUROLINK_ENHANCER  : 57462,
    SENSE_HEURISTIC_NEUROLINK_ENHANCER_REACTION_FORMULA  : 57499,
    SENSOR_BOOSTER_I  : 1973,
    SENSOR_BOOSTER_I_BLUEPRINT  : 11620,
    SENSOR_BOOSTER_II  : 1952,
    SENSOR_DAMPENING_BATTERY  : 17180,
    SENSOR_DAMPENING_BATTERY_BLUEPRINT  : 2763,
    SENSOR_DAMPENING_BURST_PROJECTOR  : 40697,
    SENSOR_DAMPENING_BURST_PROJECTOR_BLUEPRINT  : 41388,
    SENSOR_LINKING  : 3433,
    SENSOR_OPTIMIZATION_CHARGE  : 42835,
    SENSOR_OPTIMIZATION_CHARGE_BLUEPRINT  : 42879,
    SENTIENT_BURST_JAMMER  : 40690,
    SENTIENT_DAMAGE_CONTROL  : 41202,
    SENTIENT_DRONE_DAMAGE_AMPLIFIER  : 33848,
    SENTIENT_DRONE_NAVIGATION_COMPUTER  : 33852,
    SENTIENT_FIGHTER_SUPPORT_UNIT  : 41417,
    SENTIENT_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 33828,
    SENTIENT_OMNIDIRECTIONAL_TRACKING_LINK  : 33826,
    SENTIENT_REMOTE_SENSOR_BOOSTER  : 41198,
    SENTIENT_SENSOR_BOOSTER  : 41191,
    SENTIENT_SIGNAL_AMPLIFIER  : 41196,
    SENTINEL  : 11190,
    SENTINEL_BIOSECURITY_RESPONDERS_SKIN  : 55297,
    SENTINEL_COLD_IRON_SKIN  : 42756,
    SENTINEL_ELEMENT  : 34556,
    SENTINEL_EOM_SKIN  : 36290,
    SENTINEL_EXOPLANETS_HUNTER_SKIN  : 45763,
    SENTINEL_GLACIAL_DRIFT_SKIN  : 46778,
    SENTINEL_INDEX  : 34551,
    SENTINEL_OASIS_OF_FAITH_SKIN  : 53605,
    SENTINEL_PURITY_OF_THE_THRONE_SKIN  : 42554,
    SENTINEL_RAATA_SUNSET_SKIN  : 37494,
    SENTINEL_SEQUENCE_0  : 34540,
    SENTINEL_SEQUENCE_1  : 34541,
    SENTINEL_VERSUS_REDFORCE_SKIN  : 63855,
    SENTRY_DRONE_INTERFACING  : 23594,
    SERENITY_BASIC_BONDING_CEREBRAL_ACCELERATOR  : 57433,
    SERENITY_EXTENDED_BONDING_CEREBRAL_ACCELERATOR  : 57435,
    SERENITY_MONTHLY_CEREBRAL_ACCELERATOR___20206  : 54976,
    SERENITY_MONTHLY_CEREBRAL_ACCELERATOR___20207  : 55747,
    SERENITY_POTENT_BONDING_CEREBRAL_ACCELERATOR  : 57434,
    SERENITY_SPECIAL_CEREBRAL_ACCELERATOR  : 55700,
    SERENITY_SPECIAL_SKINS_CRATE  : 55827,
    SERPENTIS_3D_SCANNER_GAMUT  : 23156,
    SERPENTIS_BASIC_TARGET_GUIDER  : 23154,
    SERPENTIS_BRASS_TAG  : 17217,
    SERPENTIS_BRONZE_TAG  : 12537,
    SERPENTIS_COMPLEX_TARGET_GUIDER  : 23155,
    SERPENTIS_CONTROL_TOWER  : 27536,
    SERPENTIS_CONTROL_TOWER_MEDIUM  : 27601,
    SERPENTIS_CONTROL_TOWER_SMALL  : 27604,
    SERPENTIS_COPPER_TAG  : 17215,
    SERPENTIS_CRYSTAL_TAG  : 12541,
    SERPENTIS_DIAMOND_TAG  : 17216,
    SERPENTIS_ELECTRUM_TAG  : 17219,
    SERPENTIS_GOLD_TAG  : 12539,
    SERPENTIS_LARGE_BLASTER_BATTERY  : 27542,
    SERPENTIS_LARGE_RAILGUN_BATTERY  : 27545,
    SERPENTIS_MEDIUM_BLASTER_BATTERY  : 27613,
    SERPENTIS_MEDIUM_RAILGUN_BATTERY  : 27616,
    SERPENTIS_MODIFIED_CAPITAL_MICROPROCESSOR  : 42226,
    SERPENTIS_MULTI_TASKING_PROCESSOR  : 23157,
    SERPENTIS_PALLADIUM_TAG  : 17218,
    SERPENTIS_PLAIN_TARGET_GUIDER  : 23153,
    SERPENTIS_PLATINUM_TAG  : 12540,
    SERPENTIS_SENSOR_DAMPENING_BATTERY  : 27778,
    SERPENTIS_SILVER_TAG  : 12538,
    SERPENTIS_SMALL_BLASTER_BATTERY  : 27619,
    SERPENTIS_SMALL_RAILGUN_BATTERY  : 27622,
    SERPENTIS_VICTORY_SKIN  : 46582,
    SERPENTIS_WARP_DISRUPTION_BATTERY  : 27563,
    SERPENTIS_WARP_SCRAMBLING_BATTERY  : 27567,
    SETELE_SCHELLANS_TAG  : 17292,
    SETELES_MODIFIED_100MN_AFTERBURNER  : 14504,
    SETELES_MODIFIED_350MM_RAILGUN  : 14389,
    SETELES_MODIFIED_425MM_RAILGUN  : 14399,
    SETELES_MODIFIED_500MN_MICROWARPDRIVE  : 14512,
    SETELES_MODIFIED_CO_PROCESSOR  : 15312,
    SETELES_MODIFIED_DAMAGE_CONTROL  : 41207,
    SETELES_MODIFIED_DUAL_250MM_RAILGUN  : 14409,
    SETELES_MODIFIED_EM_ARMOR_HARDENER  : 15248,
    SETELES_MODIFIED_EM_COATING  : 15184,
    SETELES_MODIFIED_EM_ENERGIZED_MEMBRANE  : 15224,
    SETELES_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15272,
    SETELES_MODIFIED_EXPLOSIVE_COATING  : 15192,
    SETELES_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 15232,
    SETELES_MODIFIED_KINETIC_ARMOR_HARDENER  : 15264,
    SETELES_MODIFIED_KINETIC_COATING  : 15200,
    SETELES_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15240,
    SETELES_MODIFIED_LARGE_ARMOR_REPAIRER  : 15162,
    SETELES_MODIFIED_LARGE_PLASMA_SMARTBOMB  : 15156,
    SETELES_MODIFIED_MAGNETIC_FIELD_STABILIZER  : 15148,
    SETELES_MODIFIED_MULTISPECTRUM_COATING  : 15168,
    SETELES_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 15208,
    SETELES_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15296,
    SETELES_MODIFIED_REACTOR_CONTROL_UNIT  : 15304,
    SETELES_MODIFIED_SENSOR_BOOSTER  : 15280,
    SETELES_MODIFIED_THERMAL_ARMOR_HARDENER  : 15256,
    SETELES_MODIFIED_THERMAL_COATING  : 15176,
    SETELES_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 15216,
    SETELES_MODIFIED_TRACKING_COMPUTER  : 15288,
    SHADE_COMPACT_RADAR_ECM  : 19950,
    SHADOW  : 2948,
    SHADOW_ANTIMATTER_CHARGE_L  : 20927,
    SHADOW_ANTIMATTER_CHARGE_M  : 20057,
    SHADOW_ANTIMATTER_CHARGE_S  : 20040,
    SHADOW_ANTIMATTER_CHARGE_XL  : 20929,
    SHADOW_CONTROL_TOWER  : 27538,
    SHADOW_CONTROL_TOWER_MEDIUM  : 27603,
    SHADOW_CONTROL_TOWER_SMALL  : 27606,
    SHADOW_IRIDIUM_CHARGE_L  : 20917,
    SHADOW_IRIDIUM_CHARGE_M  : 20047,
    SHADOW_IRIDIUM_CHARGE_S  : 19966,
    SHADOW_IRIDIUM_CHARGE_XL  : 20931,
    SHADOW_IRON_CHARGE_L  : 20913,
    SHADOW_IRON_CHARGE_M  : 20043,
    SHADOW_IRON_CHARGE_S  : 19962,
    SHADOW_IRON_CHARGE_XL  : 20933,
    SHADOW_LARGE_BLASTER_BATTERY  : 27544,
    SHADOW_LARGE_RAILGUN_BATTERY  : 27547,
    SHADOW_LEAD_CHARGE_L  : 20919,
    SHADOW_LEAD_CHARGE_M  : 20049,
    SHADOW_LEAD_CHARGE_S  : 19968,
    SHADOW_LEAD_CHARGE_XL  : 20935,
    SHADOW_MEDIUM_BLASTER_BATTERY  : 27615,
    SHADOW_MEDIUM_RAILGUN_BATTERY  : 27618,
    SHADOW_PLUTONIUM_CHARGE_L  : 20925,
    SHADOW_PLUTONIUM_CHARGE_M  : 20055,
    SHADOW_PLUTONIUM_CHARGE_S  : 20038,
    SHADOW_PLUTONIUM_CHARGE_XL  : 20937,
    SHADOW_SENSOR_DAMPENING_BATTERY  : 27779,
    SHADOW_SERPENTIS_10000MN_AFTERBURNER  : 41241,
    SHADOW_SERPENTIS_100MN_AFTERBURNER  : 14104,
    SHADOW_SERPENTIS_10MN_AFTERBURNER  : 14108,
    SHADOW_SERPENTIS_125MM_RAILGUN  : 13864,
    SHADOW_SERPENTIS_150MM_RAILGUN  : 13866,
    SHADOW_SERPENTIS_1MN_AFTERBURNER  : 14112,
    SHADOW_SERPENTIS_200MM_RAILGUN  : 13868,
    SHADOW_SERPENTIS_25000MM_STEEL_PLATES  : 41458,
    SHADOW_SERPENTIS_250MM_RAILGUN  : 13872,
    SHADOW_SERPENTIS_350MM_RAILGUN  : 13874,
    SHADOW_SERPENTIS_425MM_RAILGUN  : 13878,
    SHADOW_SERPENTIS_50000MN_MICROWARPDRIVE  : 41255,
    SHADOW_SERPENTIS_500MN_MICROWARPDRIVE  : 14116,
    SHADOW_SERPENTIS_50MN_MICROWARPDRIVE  : 14120,
    SHADOW_SERPENTIS_5MN_MICROWARPDRIVE  : 14124,
    SHADOW_SERPENTIS_75MM_RAILGUN  : 13894,
    SHADOW_SERPENTIS_ASSAULT_DAMAGE_CONTROL  : 47258,
    SHADOW_SERPENTIS_BRASS_TAG  : 17262,
    SHADOW_SERPENTIS_BRONZE_TAG  : 17263,
    SHADOW_SERPENTIS_CAPITAL_ARMOR_REPAIRER  : 41502,
    SHADOW_SERPENTIS_CAPITAL_FLEX_ARMOR_HARDENER  : 41527,
    SHADOW_SERPENTIS_CAPITAL_REMOTE_ARMOR_REPAIRER  : 41468,
    SHADOW_SERPENTIS_CO_PROCESSOR  : 14232,
    SHADOW_SERPENTIS_COPPER_TAG  : 17264,
    SHADOW_SERPENTIS_CRYSTAL_TAG  : 17266,
    SHADOW_SERPENTIS_DAMAGE_CONTROL  : 41200,
    SHADOW_SERPENTIS_DIAMOND_TAG  : 17267,
    SHADOW_SERPENTIS_DUAL_1000MM_RAILGUN  : 41142,
    SHADOW_SERPENTIS_DUAL_150MM_RAILGUN  : 13880,
    SHADOW_SERPENTIS_DUAL_250MM_RAILGUN  : 13882,
    SHADOW_SERPENTIS_ELECTRON_BLASTER_CANNON  : 13889,
    SHADOW_SERPENTIS_ELECTRUM_TAG  : 17268,
    SHADOW_SERPENTIS_EM_ARMOR_HARDENER  : 14059,
    SHADOW_SERPENTIS_EM_COATING  : 14055,
    SHADOW_SERPENTIS_EM_ENERGIZED_MEMBRANE  : 14092,
    SHADOW_SERPENTIS_EXPLOSIVE_ARMOR_HARDENER  : 14061,
    SHADOW_SERPENTIS_EXPLOSIVE_COATING  : 14053,
    SHADOW_SERPENTIS_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14086,
    SHADOW_SERPENTIS_GOLD_TAG  : 17270,
    SHADOW_SERPENTIS_HEAVY_ELECTRON_BLASTER  : 13884,
    SHADOW_SERPENTIS_HEAVY_ION_BLASTER  : 13885,
    SHADOW_SERPENTIS_HEAVY_NEUTRON_BLASTER  : 13892,
    SHADOW_SERPENTIS_HEAVY_STASIS_GRAPPLER  : 41058,
    SHADOW_SERPENTIS_HEAVY_WARP_DISRUPTOR  : 40736,
    SHADOW_SERPENTIS_HEAVY_WARP_SCRAMBLER  : 40762,
    SHADOW_SERPENTIS_INERTIAL_STABILIZERS  : 34483,
    SHADOW_SERPENTIS_ION_BLASTER_CANNON  : 13890,
    SHADOW_SERPENTIS_ION_SIEGE_BLASTER  : 41130,
    SHADOW_SERPENTIS_KINETIC_ARMOR_HARDENER  : 14063,
    SHADOW_SERPENTIS_KINETIC_COATING  : 14051,
    SHADOW_SERPENTIS_KINETIC_ENERGIZED_MEMBRANE  : 14080,
    SHADOW_SERPENTIS_LARGE_ARMOR_REPAIRER  : 14067,
    SHADOW_SERPENTIS_LARGE_PLASMA_SMARTBOMB  : 14206,
    SHADOW_SERPENTIS_LIGHT_ELECTRON_BLASTER  : 13886,
    SHADOW_SERPENTIS_LIGHT_ION_BLASTER  : 13887,
    SHADOW_SERPENTIS_LIGHT_NEUTRON_BLASTER  : 13888,
    SHADOW_SERPENTIS_MAGNETIC_FIELD_STABILIZER  : 13945,
    SHADOW_SERPENTIS_MEDIUM_ARMOR_REPAIRER  : 14068,
    SHADOW_SERPENTIS_MEDIUM_PLASMA_SMARTBOMB  : 14220,
    SHADOW_SERPENTIS_MICRO_PLASMA_SMARTBOMB  : 14218,
    SHADOW_SERPENTIS_MULTISPECTRUM_COATING  : 14049,
    SHADOW_SERPENTIS_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14074,
    SHADOW_SERPENTIS_NEUTRON_BLASTER_CANNON  : 13891,
    SHADOW_SERPENTIS_PALLADIUM_TAG  : 17269,
    SHADOW_SERPENTIS_PLATINUM_TAG  : 17271,
    SHADOW_SERPENTIS_POWER_DIAGNOSTIC_SYSTEM  : 14138,
    SHADOW_SERPENTIS_REACTOR_CONTROL_UNIT  : 14132,
    SHADOW_SERPENTIS_REMOTE_SENSOR_BOOSTER  : 41199,
    SHADOW_SERPENTIS_REMOTE_TRACKING_COMPUTER  : 14240,
    SHADOW_SERPENTIS_SENSOR_BOOSTER  : 14236,
    SHADOW_SERPENTIS_SILVER_TAG  : 17272,
    SHADOW_SERPENTIS_SMALL_ARMOR_REPAIRER  : 14069,
    SHADOW_SERPENTIS_SMALL_PLASMA_SMARTBOMB  : 14228,
    SHADOW_SERPENTIS_STASIS_WEBIFIER  : 14270,
    SHADOW_SERPENTIS_THERMAL_ARMOR_HARDENER  : 14065,
    SHADOW_SERPENTIS_THERMAL_COATING  : 14057,
    SHADOW_SERPENTIS_THERMAL_ENERGIZED_MEMBRANE  : 14098,
    SHADOW_SERPENTIS_TRACKING_COMPUTER  : 14238,
    SHADOW_SERPENTIS_TRIPLE_NEUTRON_BLASTER_CANNON  : 41079,
    SHADOW_SERPENTIS_WARP_DISRUPTOR  : 14250,
    SHADOW_SERPENTIS_WARP_SCRAMBLER  : 14260,
    SHADOW_SMALL_BLASTER_BATTERY  : 27621,
    SHADOW_SMALL_RAILGUN_BATTERY  : 27624,
    SHADOW_THORIUM_CHARGE_L  : 20921,
    SHADOW_THORIUM_CHARGE_M  : 20051,
    SHADOW_THORIUM_CHARGE_S  : 20034,
    SHADOW_THORIUM_CHARGE_XL  : 20939,
    SHADOW_TUNGSTEN_CHARGE_L  : 20915,
    SHADOW_TUNGSTEN_CHARGE_M  : 20045,
    SHADOW_TUNGSTEN_CHARGE_S  : 19964,
    SHADOW_TUNGSTEN_CHARGE_XL  : 20941,
    SHADOW_URANIUM_CHARGE_L  : 20923,
    SHADOW_URANIUM_CHARGE_M  : 20053,
    SHADOW_URANIUM_CHARGE_S  : 20036,
    SHADOW_URANIUM_CHARGE_XL  : 20943,
    SHADOW_WARP_DISRUPTION_BATTERY  : 27565,
    SHADOW_WARP_SCRAMBLING_BATTERY  : 27569,
    SHADY_SENSOR_BOOSTER  : 22895,
    SHAQIL_DRAGATS_INSIGNIA  : 15668,
    SHAQILS_MODIFIED_GYROSTABILIZER  : 15447,
    SHAQILS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33461,
    SHAQILS_SPEED_ENHANCER  : 24669,
    SHARP_CROKITE  : 17432,
    SHARPSHOOTER  : 3311,
    SHATTERED_VILLARD_WHEEL  : 33539,
    SHEEN_COMPOUND  : 11732,
    SHIELD_BOOST_AMPLIFIER_I  : 11561,
    SHIELD_BOOST_AMPLIFIER_I_BLUEPRINT  : 11562,
    SHIELD_BOOST_AMPLIFIER_II  : 24443,
    SHIELD_COMMAND  : 3350,
    SHIELD_COMMAND_BURST_I  : 42529,
    SHIELD_COMMAND_BURST_I_BLUEPRINT  : 43545,
    SHIELD_COMMAND_BURST_II  : 43555,
    SHIELD_COMMAND_MINDLINK  : 21888,
    SHIELD_COMMAND_SPECIALIST  : 3351,
    SHIELD_COMPENSATION  : 21059,
    SHIELD_EM_RESISTANCE_SCRIPT  : 41521,
    SHIELD_EM_RESISTANCE_SCRIPT_BLUEPRINT  : 41594,
    SHIELD_EMISSION_SYSTEMS  : 3422,
    SHIELD_EXPLOSIVE_RESISTANCE_SCRIPT  : 41524,
    SHIELD_EXPLOSIVE_RESISTANCE_SCRIPT_BLUEPRINT  : 41597,
    SHIELD_EXTENSION_CHARGE  : 42696,
    SHIELD_EXTENSION_CHARGE_BLUEPRINT  : 42884,
    SHIELD_FLUX_COIL_I  : 1254,
    SHIELD_FLUX_COIL_I_BLUEPRINT  : 1255,
    SHIELD_FLUX_COIL_II  : 1256,
    SHIELD_HARMONIZING_CHARGE  : 42695,
    SHIELD_HARMONIZING_CHARGE_BLUEPRINT  : 42885,
    SHIELD_KINETIC_RESISTANCE_SCRIPT  : 41523,
    SHIELD_KINETIC_RESISTANCE_SCRIPT_BLUEPRINT  : 41596,
    SHIELD_MANAGEMENT  : 3419,
    SHIELD_OPERATION  : 3416,
    SHIELD_POWER_RELAY_I  : 2331,
    SHIELD_POWER_RELAY_I_BLUEPRINT  : 2332,
    SHIELD_POWER_RELAY_II  : 1422,
    SHIELD_RECHARGER_I  : 393,
    SHIELD_RECHARGER_I_BLUEPRINT  : 1026,
    SHIELD_RECHARGER_II  : 394,
    SHIELD_RIGGING  : 26261,
    SHIELD_THERMAL_RESISTANCE_SCRIPT  : 41522,
    SHIELD_THERMAL_RESISTANCE_SCRIPT_BLUEPRINT  : 41595,
    SHIELD_UPGRADES  : 3425,
    SHIMMERING_CHROMITE  : 46303,
    SHIMMERING_OTAVITE  : 46297,
    SHIMMERING_SPERRYLITE  : 46299,
    SHIMMERING_VANADINITE  : 46301,
    SHIMON_JAENS_INSIGNIA  : 18657,
    SHINING_LOPARITE  : 46317,
    SHINING_MONAZITE  : 46315,
    SHINING_XENOTIME  : 46313,
    SHINING_YTTERBITE  : 46319,
    SHIP_FITTING_GUIDE  : 33018,
    SHIP_MAINTENANCE_ARRAY  : 12237,
    SHIP_MAINTENANCE_ARRAY_BLUEPRINT  : 2752,
    SHIP_SCANNER_I  : 443,
    SHIP_SCANNER_I_BLUEPRINT  : 1075,
    SHIP_SCANNER_II  : 1855,
    SHIPBOARD_COMPRESSION_TECHNOLOGY  : 62450,
    SHRAPNEL_BOMB  : 27918,
    SHRAPNEL_BOMB_BLUEPRINT  : 27919,
    SHUTTLE_PILOTING_FOR_DUMMIES  : 33059,
    SIEGE_MODULE_I  : 20280,
    SIEGE_MODULE_I_BLUEPRINT  : 20281,
    SIEGE_MODULE_II  : 4292,
    SIGIL  : 19744,
    SIGIL_BIOSECURITY_RESPONDERS_SKIN  : 55322,
    SIGIL_BLUEPRINT  : 19745,
    SIGIL_COLD_IRON_SKIN  : 42796,
    SIGIL_EXOPLANETS_HUNTER_SKIN  : 45805,
    SIGIL_GLACIAL_DRIFT_SKIN  : 46814,
    SIGIL_KADOR_SKIN  : 34753,
    SIGIL_OASIS_OF_FAITH_SKIN  : 53647,
    SIGIL_PURITY_OF_THE_THRONE_SKIN  : 42596,
    SIGIL_RAATA_SUNSET_SKIN  : 37525,
    SIGIL_WAR_RESERVES_LIEUTENANT_SKIN  : 70798,
    SIGNAL_15_NEEDLEJACK_FILAMENT  : 53986,
    SIGNAL_25_NEEDLEJACK_FILAMENT  : 53983,
    SIGNAL_5_NEEDLEJACK_FILAMENT  : 53984,
    SIGNAL_AMPLIFIER_I  : 1986,
    SIGNAL_AMPLIFIER_I_BLUEPRINT  : 11634,
    SIGNAL_AMPLIFIER_II  : 1987,
    SIGNAL_DISPERSION  : 19761,
    SIGNAL_DISTORTION_AMPLIFIER_I  : 25561,
    SIGNAL_DISTORTION_AMPLIFIER_I_BLUEPRINT  : 25562,
    SIGNAL_DISTORTION_AMPLIFIER_II  : 25563,
    SIGNAL_SUPPRESSION  : 19766,
    SIGNATURE_ANALYSIS  : 3431,
    SIGNATURE_FOCUSING  : 19922,
    SIGNATURE_MASKING  : 4411,
    SIGNATURE_RADIUS_SUPPRESSOR_I  : 4409,
    SILICATE_GLASS  : 3697,
    SILICATES  : 16636,
    SILICON  : 9828,
    SILICON_DIBORITE  : 16658,
    SILICON_DIBORITE_REACTION_FORMULA  : 46179,
    SILO  : 14343,
    SILVER_MAGNATE  : 11942,
    SILVER_MONOCLE_INTERFACE_CRATE  : 52301,
    SILVER_OUROBOROS  : 42230,
    SILVERSHORE_GREATCOAT  : 4245,
    SILVERY_OMBER  : 17867,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_A_I  : 60276,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 60341,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_A_II  : 60281,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_B_I  : 60279,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 60363,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_B_II  : 60283,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_C_I  : 60280,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 60364,
    SIMPLE_ASTEROID_MINING_CRYSTAL_TYPE_C_II  : 60284,
    SIMPLE_ORE_PROCESSING  : 60377,
    SIMPLE_REACTOR_ARRAY  : 20175,
    SIN  : 22430,
    SIN_BIOSECURITY_RESPONDERS_SKIN  : 55621,
    SIN_EXOPLANETS_HUNTER_SKIN  : 45914,
    SIN_FIREBLADE_GUERILLA_SKIN  : 59328,
    SIN_GLACIAL_DRIFT_SKIN  : 46960,
    SIN_GLITTERING_DREAM_SKIN  : 47536,
    SIN_ICEBLADE_GUERILLA_SKIN  : 59333,
    SIN_INNER_ZONE_VANGUARD_SKIN  : 52749,
    SIN_INTAKI_SYNDICATE_SKIN  : 36397,
    SIN_KOPIS_EDGE_SKIN  : 46426,
    SIN_RUBY_SUNGRAZER_SKIN  : 45664,
    SIN_SAPPHIRE_SUNGRAZER_SKIN  : 46560,
    SIN_SERPENTIS_SKIN  : 42185,
    SIN_SPIRIT_SKIN  : 44932,
    SIN_VALIMOR_LEGACY_SKIN  : 37581,
    SIN_VERSUS_BLUEFORCE_SKIN  : 63862,
    SIN_ZYDRINE_BURN_SKIN  : 76952,
    SINGLE_CRYSTAL_SUPERALLOY_I_BEAM  : 25614,
    SINGLE_DIODE_BASIC_MINING_LASER  : 5233,
    SINGULARITY_RADIATION_CONVERTOR  : 52311,
    SIREN_I  : 40346,
    SIREN_I_BLUEPRINT  : 41371,
    SIREN_II  : 40570,
    SISTERS_CAPITAL_EMERGENCY_HULL_ENERGIZER  : 40718,
    SISTERS_COMBAT_SCANNER_PROBE  : 30486,
    SISTERS_CORE_PROBE_LAUNCHER  : 28758,
    SISTERS_CORE_SCANNER_PROBE  : 30488,
    SISTERS_EXPANDED_PROBE_LAUNCHER  : 28756,
    SISTERS_OF_EVE_CHARITY_STATUE  : 34432,
    SKIFF  : 22546,
    SKIFF_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43755,
    SKIFF_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43808,
    SKIFF_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43821,
    SKIFF_BLUEPRINT  : 22547,
    SKIFF_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43768,
    SKIFF_CLAIM_FOREMAN_SKIN  : 44069,
    SKIFF_DIGMASTER_BLAZON_SKIN  : 42647,
    SKIFF_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43782,
    SKIFF_GLACIAL_DRIFT_SKIN  : 46987,
    SKIFF_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43834,
    SKIFF_LODESTRIKE_SKIN  : 46738,
    SKIFF_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43795,
    SKIFF_MORPHITE_SHINE_SKIN  : 42620,
    SKIFF_NOCX_RUSH_SKIN  : 34760,
    SKIFF_PAYDIRT_PROSPECTOR_SKIN  : 42677,
    SKIFF_RADIOACTIVES_RECLAMATION_SKIN  : 60950,
    SKIFF_ROCKBREAKER_PIONEERS_SKIN  : 56615,
    SKIFF_ROSADA_DAWN_SKIN  : 43669,
    SKIFF_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43847,
    SKILL_EXTRACTOR  : 40519,
    SKIRMISH_COMMAND  : 3349,
    SKIRMISH_COMMAND_BURST_I  : 42530,
    SKIRMISH_COMMAND_BURST_I_BLUEPRINT  : 43546,
    SKIRMISH_COMMAND_BURST_II  : 43556,
    SKIRMISH_COMMAND_MINDLINK  : 21890,
    SKIRMISH_COMMAND_SPECIALIST  : 11572,
    SKYBREAKER  : 54731,
    SKYBREAKER_BLUESTAR_DEFENDERS_SKIN  : 55984,
    SKYBREAKER_EDENCOM_VICTORY_SKIN  : 57706,
    SLAMBOLT_CONDENSER_PACK_L  : 54780,
    SLAMBOLT_CONDENSER_PACK_M  : 54776,
    SLAMBOLT_CONDENSER_PACK_S  : 54772,
    SLASHER  : 585,
    SLASHER_AURORA_UNIVERSALIS_SKIN  : 53364,
    SLASHER_BIOSECURITY_RESPONDERS_SKIN  : 55646,
    SLASHER_BLUE_TIGER_SKIN  : 36802,
    SLASHER_BLUEPRINT  : 689,
    SLASHER_CORDITE_BLOSSOM_SKIN  : 48837,
    SLASHER_DAWN_OF_LIBERATION_SKIN  : 48489,
    SLASHER_DEATHGLOW_REMNANT_SKIN  : 56784,
    SLASHER_EXOPLANETS_HUNTER_SKIN  : 45937,
    SLASHER_GLACIAL_DRIFT_SKIN  : 44163,
    SLASHER_HAZARD_CONTROL_SKIN  : 56915,
    SLASHER_HEADHUNTER_SKIN  : 46722,
    SLASHER_KRUSUAL_SKIN  : 34750,
    SLASHER_LUMINAIRE_ZENITH_SKIN  : 48230,
    SLASHER_REPUBLIC_FLEET_SKIN  : 59808,
    SLASHER_SNOWLINE_BLADERACER_SKIN  : 52848,
    SLASHER_TRONHADAR_INK_SKIN  : 40471,
    SLASHER_VALKLEAR_GLORY_SKIN  : 36755,
    SLASHER_VERSUS_REDFORCE_SKIN  : 63142,
    SLASHER_ZAKURA_HANSEI_SKIN  : 57108,
    SLAVER  : 12049,
    SLAVER_HOUND  : 3723,
    SLAVER_TOOTH_NECKLACE  : 48593,
    SLAVES  : 3721,
    SLEEPER_CRYO_BATTERIES  : 21585,
    SLEEPER_DATA_CRYSTALS  : 21572,
    SLEEPER_DATA_INTERFACE_PROTOCOL  : 21568,
    SLEEPER_DATA_LIBRARY  : 30745,
    SLEEPER_DRONE_AI_NEXUS  : 30747,
    SLEEPER_ENCRYPTION_METHODS  : 3408,
    SLEEPER_FOUNDATION_BLOCK  : 20110,
    SLEEPER_HEAT_NULLIFYING_COIL  : 21721,
    SLEEPER_HYPERBOOSTER  : 21719,
    SLEEPER_MANUSCRIPTS  : 21570,
    SLEEPER_MICRO_CIRCUITS  : 21584,
    SLEEPER_NANITE_CLUSTER  : 21722,
    SLEEPER_PROFOUND_RESEARCH_NOTES  : 21569,
    SLEEPER_REINTEGRATION_CONTROL  : 21723,
    SLEEPER_SPLIT_CABLES  : 21073,
    SLEEPER_TECHNICAL_SCHEMATICS  : 21571,
    SLEEPER_TECHNOLOGY  : 21789,
    SLEEPER_THERMAL_REGULATOR  : 21720,
    SLEEPER_VIRTUAL_ENERGIZER  : 21586,
    SLEEVE___DRONE_LEFT  : 33377,
    SLEEVE___DRONE_RIGHT  : 33381,
    SLEEVE___NATURE_LEFT  : 33380,
    SLEEVE___NATURE_RIGHT  : 33384,
    SLEEVE___PROTOTYPE_LEFT  : 33379,
    SLEEVE___PROTOTYPE_RIGHT  : 33383,
    SLEEVE___WRECKAGE_LEFT  : 33378,
    SLEEVE___WRECKAGE_RIGHT  : 33382,
    SLEIPNIR  : 22444,
    SLEIPNIR_BIOSECURITY_RESPONDERS_SKIN  : 55688,
    SLEIPNIR_BLOODY_HANDS_SKIN  : 47169,
    SLEIPNIR_BLUE_TIGER_SKIN  : 36828,
    SLEIPNIR_BLUEPRINT  : 22445,
    SLEIPNIR_CHAINBREAKER_KHUMAAK_SKIN  : 77959,
    SLEIPNIR_EMPEROR_BEAR_SKIN  : 52304,
    SLEIPNIR_EXOPLANETS_HUNTER_SKIN  : 45971,
    SLEIPNIR_GLACIAL_DRIFT_SKIN  : 46908,
    SLEIPNIR_HAZARD_CONTROL_SKIN  : 56913,
    SLEIPNIR_IN_RUST_WE_TRUST_SKIN  : 52431,
    SLEIPNIR_JUSTICE_SKIN  : 36446,
    SLEIPNIR_REPUBLIC_FLEET_SKIN  : 59815,
    SLEIPNIR_SNOWLINE_BLADERACER_SKIN  : 52882,
    SLEIPNIR_STORM_BEAR_SKIN  : 48819,
    SLEIPNIR_TRONHADAR_INK_SKIN  : 40499,
    SLEIPNIR_VALKLEAR_GLORY_SKIN  : 36781,
    SLEIPNIR_YOIUL_FESTIVAL_YC121_SKIN  : 49958,
    SLITHER_HEAVY_ELECTRON_BLASTER_I  : 22905,
    SMALL_ACM_COMPACT_ARMOR_REPAIRER  : 4533,
    SMALL_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I  : 31418,
    SMALL_ALGID_ENERGY_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 31419,
    SMALL_ALGID_ENERGY_ADMINISTRATIONS_UNIT_II  : 31424,
    SMALL_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I  : 31502,
    SMALL_ALGID_HYBRID_ADMINISTRATIONS_UNIT_I_BLUEPRINT  : 31503,
    SMALL_ALGID_HYBRID_ADMINISTRATIONS_UNIT_II  : 31508,
    SMALL_ANCILLARY_ARMOR_REPAIRER  : 33076,
    SMALL_ANCILLARY_CURRENT_ROUTER_I  : 31358,
    SMALL_ANCILLARY_CURRENT_ROUTER_I_BLUEPRINT  : 31359,
    SMALL_ANCILLARY_CURRENT_ROUTER_II  : 31364,
    SMALL_ANCILLARY_REMOTE_ARMOR_REPAIRER  : 41476,
    SMALL_ANCILLARY_REMOTE_SHIELD_BOOSTER  : 41480,
    SMALL_ANCILLARY_SHIELD_BOOSTER  : 32774,
    SMALL_ARMOR_REPAIRER_I  : 523,
    SMALL_ARMOR_REPAIRER_I_BLUEPRINT  : 1099,
    SMALL_ARMOR_REPAIRER_II  : 1183,
    SMALL_ARMS  : 9844,
    SMALL_ARTILLERY_BATTERY  : 16631,
    SMALL_ARTILLERY_BATTERY_BLUEPRINT  : 2816,
    SMALL_ARTILLERY_SPECIALIZATION  : 12201,
    SMALL_ASYMMETRIC_ENDURING_REMOTE_SHIELD_BOOSTER  : 8535,
    SMALL_AUDIT_LOG_SECURE_CONTAINER  : 17363,
    SMALL_AUDIT_LOG_SECURE_CONTAINER_BLUEPRINT  : 32868,
    SMALL_AUTOCANNON_BATTERY  : 17772,
    SMALL_AUTOCANNON_BATTERY_BLUEPRINT  : 2819,
    SMALL_AUTOCANNON_SPECIALIZATION  : 11084,
    SMALL_AUTOMATED_STRUCTURAL_RESTORATION  : 5745,
    SMALL_AUXILIARY_NANO_PUMP_I  : 31045,
    SMALL_AUXILIARY_NANO_PUMP_I_BLUEPRINT  : 31046,
    SMALL_AUXILIARY_NANO_PUMP_II  : 31051,
    SMALL_AUXILIARY_THRUSTERS_I  : 31105,
    SMALL_AUXILIARY_THRUSTERS_I_BLUEPRINT  : 31106,
    SMALL_AUXILIARY_THRUSTERS_II  : 31111,
    SMALL_AZEOTROPIC_RESTRAINED_SHIELD_EXTENDER  : 8427,
    SMALL_BAY_LOADING_ACCELERATOR_I  : 31586,
    SMALL_BAY_LOADING_ACCELERATOR_I_BLUEPRINT  : 31587,
    SMALL_BAY_LOADING_ACCELERATOR_II  : 31592,
    SMALL_BEAM_LASER_BATTERY  : 17167,
    SMALL_BEAM_LASER_BATTERY_BLUEPRINT  : 2826,
    SMALL_BEAM_LASER_SPECIALIZATION  : 11083,
    SMALL_BLASTER_BATTERY  : 17404,
    SMALL_BLASTER_BATTERY_BLUEPRINT  : 2813,
    SMALL_BLASTER_SPECIALIZATION  : 12210,
    SMALL_C5_L_COMPACT_SHIELD_BOOSTER  : 6437,
    SMALL_CALTROP_ENERGY_NEUTRALIZER  : 23815,
    SMALL_CAP_BATTERY_I  : 1185,
    SMALL_CAP_BATTERY_I_BLUEPRINT  : 1186,
    SMALL_CAP_BATTERY_II  : 3488,
    SMALL_CAPACITOR_BOOSTER_I  : 3566,
    SMALL_CAPACITOR_BOOSTER_I_BLUEPRINT  : 3567,
    SMALL_CAPACITOR_BOOSTER_II  : 3568,
    SMALL_CAPACITOR_CONTROL_CIRCUIT_I  : 31370,
    SMALL_CAPACITOR_CONTROL_CIRCUIT_I_BLUEPRINT  : 31371,
    SMALL_CAPACITOR_CONTROL_CIRCUIT_II  : 31376,
    SMALL_CARGOHOLD_OPTIMIZATION_I  : 31117,
    SMALL_CARGOHOLD_OPTIMIZATION_I_BLUEPRINT  : 31118,
    SMALL_CARGOHOLD_OPTIMIZATION_II  : 31123,
    SMALL_CLARITY_WARD_ENDURING_SHIELD_BOOSTER  : 6441,
    SMALL_COAXIAL_COMPACT_REMOTE_ARMOR_REPAIRER  : 16435,
    SMALL_COMMAND_PROCESSOR_I  : 43894,
    SMALL_COMMAND_PROCESSOR_I_BLUEPRINT  : 43902,
    SMALL_COMPACT_PB_ACID_CAP_BATTERY  : 4787,
    SMALL_COMPACT_VORTON_PROJECTOR  : 54740,
    SMALL_CONSORTIUM_VORTON_PROJECTOR  : 54743,
    SMALL_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I  : 31764,
    SMALL_CORE_DEFENSE_CAPACITOR_SAFEGUARD_I_BLUEPRINT  : 31765,
    SMALL_CORE_DEFENSE_CAPACITOR_SAFEGUARD_II  : 31770,
    SMALL_CORE_DEFENSE_CHARGE_ECONOMIZER_I  : 31776,
    SMALL_CORE_DEFENSE_CHARGE_ECONOMIZER_I_BLUEPRINT  : 31777,
    SMALL_CORE_DEFENSE_CHARGE_ECONOMIZER_II  : 31782,
    SMALL_CORE_DEFENSE_FIELD_EXTENDER_I  : 31788,
    SMALL_CORE_DEFENSE_FIELD_EXTENDER_I_BLUEPRINT  : 31789,
    SMALL_CORE_DEFENSE_FIELD_EXTENDER_II  : 31794,
    SMALL_CORE_DEFENSE_FIELD_PURGER_I  : 31800,
    SMALL_CORE_DEFENSE_FIELD_PURGER_I_BLUEPRINT  : 31801,
    SMALL_CORE_DEFENSE_FIELD_PURGER_II  : 31810,
    SMALL_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I  : 31816,
    SMALL_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_I_BLUEPRINT  : 31817,
    SMALL_CORE_DEFENSE_OPERATIONAL_SOLIDIFIER_II  : 31822,
    SMALL_DEGENERATIVE_CONCUSSION_BOMB_I  : 21534,
    SMALL_DISINTEGRATOR_SPECIALIZATION  : 47873,
    SMALL_DRONE_CONTROL_RANGE_AUGMENTOR_I  : 32025,
    SMALL_DRONE_CONTROL_RANGE_AUGMENTOR_I_BLUEPRINT  : 32026,
    SMALL_DRONE_CONTROL_RANGE_AUGMENTOR_II  : 32029,
    SMALL_DRONE_DURABILITY_ENHANCER_I  : 32033,
    SMALL_DRONE_DURABILITY_ENHANCER_I_BLUEPRINT  : 32034,
    SMALL_DRONE_DURABILITY_ENHANCER_II  : 32037,
    SMALL_DRONE_MINING_AUGMENTOR_I  : 32041,
    SMALL_DRONE_MINING_AUGMENTOR_I_BLUEPRINT  : 32042,
    SMALL_DRONE_MINING_AUGMENTOR_II  : 32045,
    SMALL_DRONE_REPAIR_AUGMENTOR_I  : 32049,
    SMALL_DRONE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 32050,
    SMALL_DRONE_REPAIR_AUGMENTOR_II  : 32053,
    SMALL_DRONE_SCOPE_CHIP_I  : 32069,
    SMALL_DRONE_SCOPE_CHIP_I_BLUEPRINT  : 32070,
    SMALL_DRONE_SCOPE_CHIP_II  : 32073,
    SMALL_DRONE_SPEED_AUGMENTOR_I  : 32057,
    SMALL_DRONE_SPEED_AUGMENTOR_I_BLUEPRINT  : 32058,
    SMALL_DRONE_SPEED_AUGMENTOR_II  : 32061,
    SMALL_DYNAMIC_FUEL_VALVE_I  : 31129,
    SMALL_DYNAMIC_FUEL_VALVE_I_BLUEPRINT  : 31130,
    SMALL_DYNAMIC_FUEL_VALVE_II  : 31135,
    SMALL_EGRESS_PORT_MAXIMIZER_I  : 31382,
    SMALL_EGRESS_PORT_MAXIMIZER_I_BLUEPRINT  : 31383,
    SMALL_EGRESS_PORT_MAXIMIZER_II  : 31388,
    SMALL_EM_ARMOR_REINFORCER_I  : 30997,
    SMALL_EM_ARMOR_REINFORCER_I_BLUEPRINT  : 30998,
    SMALL_EM_ARMOR_REINFORCER_II  : 31003,
    SMALL_EM_SHIELD_REINFORCER_I  : 31716,
    SMALL_EM_SHIELD_REINFORCER_I_BLUEPRINT  : 31717,
    SMALL_EM_SHIELD_REINFORCER_II  : 31722,
    SMALL_EMISSION_SCOPE_SHARPENER_I  : 31201,
    SMALL_EMISSION_SCOPE_SHARPENER_I_BLUEPRINT  : 31202,
    SMALL_EMISSION_SCOPE_SHARPENER_II  : 31207,
    SMALL_EMP_SMARTBOMB_I  : 1563,
    SMALL_EMP_SMARTBOMB_I_BLUEPRINT  : 1564,
    SMALL_EMP_SMARTBOMB_II  : 1565,
    SMALL_ENERGY_AMBIT_EXTENSION_I  : 31430,
    SMALL_ENERGY_AMBIT_EXTENSION_I_BLUEPRINT  : 31431,
    SMALL_ENERGY_AMBIT_EXTENSION_II  : 31436,
    SMALL_ENERGY_BURST_AERATOR_I  : 31442,
    SMALL_ENERGY_BURST_AERATOR_I_BLUEPRINT  : 31443,
    SMALL_ENERGY_BURST_AERATOR_II  : 31448,
    SMALL_ENERGY_COLLISION_ACCELERATOR_I  : 31454,
    SMALL_ENERGY_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31455,
    SMALL_ENERGY_COLLISION_ACCELERATOR_II  : 31460,
    SMALL_ENERGY_DISCHARGE_ELUTRIATION_I  : 31466,
    SMALL_ENERGY_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 31467,
    SMALL_ENERGY_DISCHARGE_ELUTRIATION_II  : 31472,
    SMALL_ENERGY_LOCUS_COORDINATOR_I  : 31478,
    SMALL_ENERGY_LOCUS_COORDINATOR_I_BLUEPRINT  : 31479,
    SMALL_ENERGY_LOCUS_COORDINATOR_II  : 31484,
    SMALL_ENERGY_METASTASIS_ADJUSTER_I  : 31490,
    SMALL_ENERGY_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31491,
    SMALL_ENERGY_METASTASIS_ADJUSTER_II  : 31496,
    SMALL_ENERGY_NEUTRALIZER_I  : 533,
    SMALL_ENERGY_NEUTRALIZER_I_BLUEPRINT  : 1109,
    SMALL_ENERGY_NEUTRALIZER_II  : 13003,
    SMALL_ENERGY_NOSFERATU_I  : 530,
    SMALL_ENERGY_NOSFERATU_I_BLUEPRINT  : 1106,
    SMALL_ENERGY_NOSFERATU_II  : 13001,
    SMALL_ENERGY_TURRET  : 3303,
    SMALL_ENGINE_THERMAL_SHIELDING_I  : 31141,
    SMALL_ENGINE_THERMAL_SHIELDING_I_BLUEPRINT  : 31142,
    SMALL_ENGINE_THERMAL_SHIELDING_II  : 31147,
    SMALL_EXPLOSIVE_ARMOR_REINFORCER_I  : 31009,
    SMALL_EXPLOSIVE_ARMOR_REINFORCER_I_BLUEPRINT  : 31010,
    SMALL_EXPLOSIVE_ARMOR_REINFORCER_II  : 31015,
    SMALL_EXPLOSIVE_SHIELD_REINFORCER_I  : 31728,
    SMALL_EXPLOSIVE_SHIELD_REINFORCER_I_BLUEPRINT  : 31729,
    SMALL_EXPLOSIVE_SHIELD_REINFORCER_II  : 31734,
    SMALL_F_RX_COMPACT_CAPACITOR_BOOSTER  : 5011,
    SMALL_F_S9_REGOLITH_COMPACT_SHIELD_EXTENDER  : 8521,
    SMALL_FOCUSED_AFOCAL_MASER_I  : 6717,
    SMALL_FOCUSED_AFOCAL_PULSE_MASER_I  : 6673,
    SMALL_FOCUSED_ANODE_PARTICLE_STREAM_I  : 6721,
    SMALL_FOCUSED_ANODE_PULSE_PARTICLE_STREAM_I  : 6677,
    SMALL_FOCUSED_BEAM_LASER_I  : 454,
    SMALL_FOCUSED_BEAM_LASER_I_BLUEPRINT  : 838,
    SMALL_FOCUSED_BEAM_LASER_II  : 3033,
    SMALL_FOCUSED_MODAL_LASER_I  : 6715,
    SMALL_FOCUSED_MODAL_PULSE_LASER_I  : 6671,
    SMALL_FOCUSED_MODULATED_ENERGY_BEAM_I  : 6719,
    SMALL_FOCUSED_MODULATED_PULSE_ENERGY_BEAM_I  : 6675,
    SMALL_FOCUSED_PULSE_LASER_I  : 453,
    SMALL_FOCUSED_PULSE_LASER_I_BLUEPRINT  : 837,
    SMALL_FOCUSED_PULSE_LASER_II  : 3041,
    SMALL_FREIGHT_CONTAINER  : 33011,
    SMALL_FREIGHT_CONTAINER_BLUEPRINT  : 33012,
    SMALL_GHOUL_COMPACT_ENERGY_NOSFERATU  : 5141,
    SMALL_GRAVITON_SMARTBOMB_I  : 1551,
    SMALL_GRAVITON_SMARTBOMB_I_BLUEPRINT  : 1552,
    SMALL_GRAVITON_SMARTBOMB_II  : 1553,
    SMALL_GRAVITY_CAPACITOR_UPGRADE_I  : 31213,
    SMALL_GRAVITY_CAPACITOR_UPGRADE_I_BLUEPRINT  : 31214,
    SMALL_GRAVITY_CAPACITOR_UPGRADE_II  : 31220,
    SMALL_GREMLIN_COMPACT_ENERGY_NEUTRALIZER  : 4477,
    SMALL_HIGGS_ANCHOR_I  : 34266,
    SMALL_HIGGS_ANCHOR_I_BLUEPRINT  : 34267,
    SMALL_HOPE_HULL_RECONSTRUCTOR_I  : 5743,
    SMALL_HULL_REPAIRER_I  : 524,
    SMALL_HULL_REPAIRER_I_BLUEPRINT  : 1100,
    SMALL_HULL_REPAIRER_II  : 2355,
    SMALL_HYBRID_AMBIT_EXTENSION_I  : 31514,
    SMALL_HYBRID_AMBIT_EXTENSION_I_BLUEPRINT  : 31515,
    SMALL_HYBRID_AMBIT_EXTENSION_II  : 31520,
    SMALL_HYBRID_BURST_AERATOR_I  : 31526,
    SMALL_HYBRID_BURST_AERATOR_I_BLUEPRINT  : 31527,
    SMALL_HYBRID_BURST_AERATOR_II  : 31532,
    SMALL_HYBRID_COLLISION_ACCELERATOR_I  : 31538,
    SMALL_HYBRID_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31539,
    SMALL_HYBRID_COLLISION_ACCELERATOR_II  : 31544,
    SMALL_HYBRID_DISCHARGE_ELUTRIATION_I  : 31550,
    SMALL_HYBRID_DISCHARGE_ELUTRIATION_I_BLUEPRINT  : 31551,
    SMALL_HYBRID_DISCHARGE_ELUTRIATION_II  : 31556,
    SMALL_HYBRID_LOCUS_COORDINATOR_I  : 31562,
    SMALL_HYBRID_LOCUS_COORDINATOR_I_BLUEPRINT  : 31563,
    SMALL_HYBRID_LOCUS_COORDINATOR_II  : 31568,
    SMALL_HYBRID_METASTASIS_ADJUSTER_I  : 31574,
    SMALL_HYBRID_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31575,
    SMALL_HYBRID_METASTASIS_ADJUSTER_II  : 31580,
    SMALL_HYBRID_TURRET  : 3301,
    SMALL_HYDRAULIC_BAY_THRUSTERS_I  : 31598,
    SMALL_HYDRAULIC_BAY_THRUSTERS_I_BLUEPRINT  : 31599,
    SMALL_HYDRAULIC_BAY_THRUSTERS_II  : 31604,
    SMALL_HYPERSPATIAL_VELOCITY_OPTIMIZER_I  : 31159,
    SMALL_HYPERSPATIAL_VELOCITY_OPTIMIZER_I_BLUEPRINT  : 31160,
    SMALL_HYPERSPATIAL_VELOCITY_OPTIMIZER_II  : 31165,
    SMALL_I_A_ENDURING_ARMOR_REPAIRER  : 4529,
    SMALL_I_AX_ENDURING_REMOTE_ARMOR_REPAIRER  : 16433,
    SMALL_I_B_POLARIZED_STRUCTURAL_REGENERATOR  : 5747,
    SMALL_INDUCTIVE_COMPACT_REMOTE_CAPACITOR_TRANSMITTER  : 5091,
    SMALL_INEFFICIENT_HULL_REPAIR_UNIT  : 5693,
    SMALL_INFECTIOUS_SCOPED_ENERGY_NEUTRALIZER  : 4471,
    SMALL_INTACT_HULL_SECTION  : 34412,
    SMALL_INTEGRATIVE_HULL_REPAIR_UNIT  : 21504,
    SMALL_INVERTED_SIGNAL_FIELD_PROJECTOR_I  : 31262,
    SMALL_INVERTED_SIGNAL_FIELD_PROJECTOR_I_BLUEPRINT  : 31263,
    SMALL_INVERTED_SIGNAL_FIELD_PROJECTOR_II  : 31268,
    SMALL_IONIC_FIELD_PROJECTOR_I  : 31274,
    SMALL_IONIC_FIELD_PROJECTOR_I_BLUEPRINT  : 31275,
    SMALL_IONIC_FIELD_PROJECTOR_II  : 31280,
    SMALL_KINETIC_ARMOR_REINFORCER_I  : 31021,
    SMALL_KINETIC_ARMOR_REINFORCER_I_BLUEPRINT  : 31022,
    SMALL_KINETIC_ARMOR_REINFORCER_II  : 31027,
    SMALL_KINETIC_SHIELD_REINFORCER_I  : 31740,
    SMALL_KINETIC_SHIELD_REINFORCER_I_BLUEPRINT  : 31741,
    SMALL_KINETIC_SHIELD_REINFORCER_II  : 31746,
    SMALL_KNAVE_SCOPED_ENERGY_NOSFERATU  : 5137,
    SMALL_LIQUID_COOLED_ELECTRONICS_I  : 31226,
    SMALL_LIQUID_COOLED_ELECTRONICS_I_BLUEPRINT  : 31227,
    SMALL_LIQUID_COOLED_ELECTRONICS_II  : 31232,
    SMALL_LOW_FRICTION_NOZZLE_JOINTS_I  : 31153,
    SMALL_LOW_FRICTION_NOZZLE_JOINTS_I_BLUEPRINT  : 31154,
    SMALL_LOW_FRICTION_NOZZLE_JOINTS_II  : 31171,
    SMALL_MALFUNCTIONING_HULL_SECTION  : 34414,
    SMALL_MEMETIC_ALGORITHM_BANK_I  : 31238,
    SMALL_MEMETIC_ALGORITHM_BANK_I_BLUEPRINT  : 31239,
    SMALL_MEMETIC_ALGORITHM_BANK_II  : 31244,
    SMALL_MOBILE_HYBRID_SIPHON_UNIT  : 33581,
    SMALL_MOBILE_ROTE_SIPHON_UNIT  : 33583,
    SMALL_MOBILE_SIPHON_UNIT  : 33477,
    SMALL_MURKY_COMPACT_REMOTE_SHIELD_BOOSTER  : 8531,
    SMALL_NANOBOT_ACCELERATOR_I  : 31063,
    SMALL_NANOBOT_ACCELERATOR_I_BLUEPRINT  : 31064,
    SMALL_NANOBOT_ACCELERATOR_II  : 31069,
    SMALL_NAVY_AMMUNITION_CRATE  : 49746,
    SMALL_NOTOS_EXPLOSIVE_CHARGE_I  : 9744,
    SMALL_PARTICLE_DISPERSION_AUGMENTOR_I  : 31286,
    SMALL_PARTICLE_DISPERSION_AUGMENTOR_I_BLUEPRINT  : 31287,
    SMALL_PARTICLE_DISPERSION_AUGMENTOR_II  : 31292,
    SMALL_PARTICLE_DISPERSION_PROJECTOR_I  : 31298,
    SMALL_PARTICLE_DISPERSION_PROJECTOR_I_BLUEPRINT  : 31299,
    SMALL_PARTICLE_DISPERSION_PROJECTOR_II  : 31304,
    SMALL_PLASMA_SMARTBOMB_I  : 1557,
    SMALL_PLASMA_SMARTBOMB_I_BLUEPRINT  : 1558,
    SMALL_PLASMA_SMARTBOMB_II  : 1559,
    SMALL_POLYCARBON_ENGINE_HOUSING_I  : 31177,
    SMALL_POLYCARBON_ENGINE_HOUSING_I_BLUEPRINT  : 31178,
    SMALL_POLYCARBON_ENGINE_HOUSING_II  : 31183,
    SMALL_POWERGRID_SUBROUTINE_MAXIMIZER_I  : 31394,
    SMALL_POWERGRID_SUBROUTINE_MAXIMIZER_I_BLUEPRINT  : 31395,
    SMALL_POWERGRID_SUBROUTINE_MAXIMIZER_II  : 31400,
    SMALL_PRECURSOR_WEAPON  : 47870,
    SMALL_PROCESSOR_OVERCLOCKING_UNIT_I  : 26929,
    SMALL_PROCESSOR_OVERCLOCKING_UNIT_I_BLUEPRINT  : 26930,
    SMALL_PROCESSOR_OVERCLOCKING_UNIT_II  : 26931,
    SMALL_PROJECTILE_AMBIT_EXTENSION_I  : 31656,
    SMALL_PROJECTILE_AMBIT_EXTENSION_I_BLUEPRINT  : 31657,
    SMALL_PROJECTILE_AMBIT_EXTENSION_II  : 31662,
    SMALL_PROJECTILE_BURST_AERATOR_I  : 31668,
    SMALL_PROJECTILE_BURST_AERATOR_I_BLUEPRINT  : 31669,
    SMALL_PROJECTILE_BURST_AERATOR_II  : 31674,
    SMALL_PROJECTILE_COLLISION_ACCELERATOR_I  : 31680,
    SMALL_PROJECTILE_COLLISION_ACCELERATOR_I_BLUEPRINT  : 31681,
    SMALL_PROJECTILE_COLLISION_ACCELERATOR_II  : 31686,
    SMALL_PROJECTILE_LOCUS_COORDINATOR_I  : 31692,
    SMALL_PROJECTILE_LOCUS_COORDINATOR_I_BLUEPRINT  : 31693,
    SMALL_PROJECTILE_LOCUS_COORDINATOR_II  : 31698,
    SMALL_PROJECTILE_METASTASIS_ADJUSTER_I  : 31704,
    SMALL_PROJECTILE_METASTASIS_ADJUSTER_I_BLUEPRINT  : 31705,
    SMALL_PROJECTILE_METASTASIS_ADJUSTER_II  : 31710,
    SMALL_PROJECTILE_TURRET  : 3302,
    SMALL_PROTON_SMARTBOMB_I  : 1547,
    SMALL_PROTON_SMARTBOMB_I_BLUEPRINT  : 1548,
    SMALL_PROTON_SMARTBOMB_II  : 1549,
    SMALL_PULSE_LASER_BATTERY  : 17408,
    SMALL_PULSE_LASER_BATTERY_BLUEPRINT  : 2825,
    SMALL_PULSE_LASER_SPECIALIZATION  : 12213,
    SMALL_RADIATIVE_SCOPED_REMOTE_CAPACITOR_TRANSMITTER  : 5093,
    SMALL_RAILGUN_BATTERY  : 16690,
    SMALL_RAILGUN_BATTERY_BLUEPRINT  : 2815,
    SMALL_RAILGUN_SPECIALIZATION  : 11082,
    SMALL_REMOTE_ARMOR_REPAIRER_I  : 11355,
    SMALL_REMOTE_ARMOR_REPAIRER_I_BLUEPRINT  : 11356,
    SMALL_REMOTE_ARMOR_REPAIRER_II  : 26912,
    SMALL_REMOTE_CAPACITOR_TRANSMITTER_I  : 529,
    SMALL_REMOTE_CAPACITOR_TRANSMITTER_I_BLUEPRINT  : 1105,
    SMALL_REMOTE_CAPACITOR_TRANSMITTER_II  : 1190,
    SMALL_REMOTE_HULL_REPAIRER_I  : 27932,
    SMALL_REMOTE_HULL_REPAIRER_I_BLUEPRINT  : 27933,
    SMALL_REMOTE_HULL_REPAIRER_II  : 4299,
    SMALL_REMOTE_REPAIR_AUGMENTOR_I  : 27068,
    SMALL_REMOTE_REPAIR_AUGMENTOR_I_BLUEPRINT  : 27069,
    SMALL_REMOTE_REPAIR_AUGMENTOR_II  : 31077,
    SMALL_REMOTE_SHIELD_BOOSTER_I  : 3586,
    SMALL_REMOTE_SHIELD_BOOSTER_I_BLUEPRINT  : 3587,
    SMALL_REMOTE_SHIELD_BOOSTER_II  : 3588,
    SMALL_ROCKET_FUEL_CACHE_PARTITION_I  : 31608,
    SMALL_ROCKET_FUEL_CACHE_PARTITION_I_BLUEPRINT  : 31609,
    SMALL_ROCKET_FUEL_CACHE_PARTITION_II  : 31614,
    SMALL_RUDIMENTARY_CONCUSSION_BOMB_I  : 9670,
    SMALL_S95A_SCOPED_REMOTE_SHIELD_BOOSTER  : 8537,
    SMALL_SALVAGE_TACKLE_I  : 31083,
    SMALL_SALVAGE_TACKLE_I_BLUEPRINT  : 31084,
    SMALL_SALVAGE_TACKLE_II  : 31089,
    SMALL_SCOPED_VORTON_PROJECTOR  : 54741,
    SMALL_SECURE_CONTAINER  : 3467,
    SMALL_SECURE_CONTAINER_BLUEPRINT  : 32867,
    SMALL_SEMICONDUCTOR_MEMORY_CELL_I  : 31406,
    SMALL_SEMICONDUCTOR_MEMORY_CELL_I_BLUEPRINT  : 31407,
    SMALL_SEMICONDUCTOR_MEMORY_CELL_II  : 31412,
    SMALL_SENTRY_DAMAGE_AUGMENTOR_I  : 32081,
    SMALL_SENTRY_DAMAGE_AUGMENTOR_I_BLUEPRINT  : 32082,
    SMALL_SENTRY_DAMAGE_AUGMENTOR_II  : 32085,
    SMALL_SETTLER_SHIELD_BOOSTER  : 20617,
    SMALL_SHIELD_BOOSTER_I  : 399,
    SMALL_SHIELD_BOOSTER_I_BLUEPRINT  : 1032,
    SMALL_SHIELD_BOOSTER_II  : 400,
    SMALL_SHIELD_EXTENDER_I  : 377,
    SMALL_SHIELD_EXTENDER_I_BLUEPRINT  : 1010,
    SMALL_SHIELD_EXTENDER_II  : 380,
    SMALL_SHIP_ASSEMBLY_ARRAY  : 24574,
    SMALL_SIESTA_CAPACITOR_BOOSTER  : 20555,
    SMALL_SIGNAL_DISRUPTION_AMPLIFIER_I  : 31250,
    SMALL_SIGNAL_DISRUPTION_AMPLIFIER_I_BLUEPRINT  : 31251,
    SMALL_SIGNAL_DISRUPTION_AMPLIFIER_II  : 31256,
    SMALL_SIGNAL_FOCUSING_KIT_I  : 31310,
    SMALL_SIGNAL_FOCUSING_KIT_I_BLUEPRINT  : 31311,
    SMALL_SIGNAL_FOCUSING_KIT_II  : 31316,
    SMALL_SKILL_INJECTOR  : 45635,
    SMALL_SOLACE_SCOPED_REMOTE_ARMOR_REPAIRER  : 16439,
    SMALL_STANDARD_CONTAINER  : 3297,
    SMALL_STANDARD_CONTAINER_BLUEPRINT  : 32859,
    SMALL_STASIS_DRONE_AUGMENTOR_I  : 32089,
    SMALL_STASIS_DRONE_AUGMENTOR_I_BLUEPRINT  : 32090,
    SMALL_STASIS_DRONE_AUGMENTOR_II  : 32093,
    SMALL_TARGETING_SYSTEM_SUBCONTROLLER_I  : 31322,
    SMALL_TARGETING_SYSTEM_SUBCONTROLLER_I_BLUEPRINT  : 31323,
    SMALL_TARGETING_SYSTEM_SUBCONTROLLER_II  : 31328,
    SMALL_TARGETING_SYSTEMS_STABILIZER_I  : 31334,
    SMALL_TARGETING_SYSTEMS_STABILIZER_I_BLUEPRINT  : 31335,
    SMALL_TARGETING_SYSTEMS_STABILIZER_II  : 31340,
    SMALL_THERMAL_ARMOR_REINFORCER_I  : 31033,
    SMALL_THERMAL_ARMOR_REINFORCER_I_BLUEPRINT  : 31034,
    SMALL_THERMAL_ARMOR_REINFORCER_II  : 31039,
    SMALL_THERMAL_SHIELD_REINFORCER_I  : 31752,
    SMALL_THERMAL_SHIELD_REINFORCER_I_BLUEPRINT  : 31753,
    SMALL_THERMAL_SHIELD_REINFORCER_II  : 31758,
    SMALL_TRACKING_DIAGNOSTIC_SUBROUTINES_I  : 31346,
    SMALL_TRACKING_DIAGNOSTIC_SUBROUTINES_I_BLUEPRINT  : 31347,
    SMALL_TRACKING_DIAGNOSTIC_SUBROUTINES_II  : 31352,
    SMALL_TRACTOR_BEAM_I  : 24348,
    SMALL_TRACTOR_BEAM_I_BLUEPRINT  : 24349,
    SMALL_TRACTOR_BEAM_II  : 4250,
    SMALL_TRANSVERSE_BULKHEAD_I  : 33890,
    SMALL_TRANSVERSE_BULKHEAD_I_BLUEPRINT  : 33891,
    SMALL_TRANSVERSE_BULKHEAD_II  : 33892,
    SMALL_TRAPPER_SHIELD_EXTENDER  : 20627,
    SMALL_TRIMARK_ARMOR_PUMP_I  : 30987,
    SMALL_TRIMARK_ARMOR_PUMP_I_BLUEPRINT  : 30988,
    SMALL_TRIMARK_ARMOR_PUMP_II  : 31057,
    SMALL_UPIR_ENERGY_NOSFERATU  : 23821,
    SMALL_VEHEMENCE_SHOCKWAVE_CHARGE  : 9680,
    SMALL_VORTON_PROJECTOR  : 55034,
    SMALL_VORTON_PROJECTOR_I  : 54739,
    SMALL_VORTON_PROJECTOR_II  : 54742,
    SMALL_VORTON_PROJECTOR_OLD  : 54824,
    SMALL_VORTON_SPECIALIZATION  : 54827,
    SMALL_WARHEAD_CALEFACTION_CATALYST_I  : 31620,
    SMALL_WARHEAD_CALEFACTION_CATALYST_I_BLUEPRINT  : 31621,
    SMALL_WARHEAD_CALEFACTION_CATALYST_II  : 31626,
    SMALL_WARHEAD_FLARE_CATALYST_I  : 31632,
    SMALL_WARHEAD_FLARE_CATALYST_I_BLUEPRINT  : 31633,
    SMALL_WARHEAD_FLARE_CATALYST_II  : 31638,
    SMALL_WARHEAD_RIGOR_CATALYST_I  : 31644,
    SMALL_WARHEAD_RIGOR_CATALYST_I_BLUEPRINT  : 31645,
    SMALL_WARHEAD_RIGOR_CATALYST_II  : 31650,
    SMALL_WARP_CORE_OPTIMIZER_I  : 31189,
    SMALL_WARP_CORE_OPTIMIZER_I_BLUEPRINT  : 31190,
    SMALL_WARP_CORE_OPTIMIZER_II  : 31195,
    SMALL_WOLF_SHIELD_EXTENDER  : 20625,
    SMALL_WRECKED_HULL_SECTION  : 34416,
    SMALL_YF_12A_SMARTBOMB  : 9784,
    SMARTFAB_UNITS  : 2351,
    SMASHED_TRIGGER_UNIT  : 25593,
    SMOKESCREEN_COVERT_OPS_CLOAKING_DEVICE_II  : 20563,
    SMOOTH_GLACIAL_MASS  : 17977,
    SMUGGLER_LOCKBOX  : 64033,
    SNAKE_EYES_EXPLOSIVE_SHIELD_HARDENER  : 20641,
    SNOW_GLOBE_WITH_PECULIAR_ARCHITECTURE  : 62051,
    SNOWSTAR_CRYSTALBURST_FIREWORK  : 57149,
    SNOWSTAR_CRYSTALBURST_FIREWORKS_CRATE  : 57166,
    SOCIAL  : 3355,
    SOCIAL_ADAPTATION_CHIP___BASIC  : 9956,
    SOCIAL_ADAPTATION_CHIP___IMPROVED  : 10226,
    SOCIAL_ADAPTATION_CHIP___STANDARD  : 10225,
    SODIUM_FIREWORK  : 33571,
    SODIUM_FIREWORK_CXIV  : 32993,
    SOIL  : 3643,
    SOLDIER_OF_FORTUNE___COMBAT_FRIGATE_CRATE  : 63784,
    SOLDIER_OF_FORTUNE___FRIGATE_SKILL_CRATE  : 63783,
    SOLDIER_OF_FORTUNE___SUPPORT_FRIGATE_CRATE  : 63785,
    SOLERIUM  : 16664,
    SOLERIUM_REACTION_FORMULA  : 46180,
    SOLID_PYROXERES  : 17459,
    SOLIS_MUTANITE  : 77420,
    SOOTH_SAYER  : 3820,
    SORT_DRAGONS_GUIDE_TO_DIPLOMACY  : 34426,
    SOTIYO  : 35827,
    SOTIYO_BLUEPRINT  : 36973,
    SOTIYO_UPWELL_QUANTUM_CORE  : 56208,
    SOVEREIGNTY  : 12241,
    SOXFOURS_SPACEBOOTS  : 34435,
    SPACESHIP_COMMAND  : 3327,
    SPACETIME_SINGULARITY_FIREWORK  : 44266,
    SPARE_PARTS  : 21588,
    SPARKLING_PLAGIOCLASE  : 46685,
    SPATIAL_ATTUNEMENT_UNIT  : 33195,
    SPATIAL_DESTABILIZATION_BATTERY  : 17176,
    SPATIAL_DESTABILIZATION_BATTERY_BLUEPRINT  : 2796,
    SPATIAL_PHENOMENA_GENERATION  : 43728,
    SPATIOTEMPORAL_BOUNDARY_DETECTOR  : 54809,
    SPECTRE_FLEETS_MODIFIED_ENTOSIS_LINK  : 40308,
    SPERRYLITE  : 45499,
    SPICED_WINE  : 42,
    SPIKE_L  : 12807,
    SPIKE_M  : 12805,
    SPIKE_S  : 12618,
    SPIKE_XL  : 41328,
    SPIKED_QUAFE  : 54165,
    SPIRITS  : 9850,
    SPODUMAIN  : 19,
    SPODUMAIN_PROCESSING  : 12194,
    SPZ_3_TORCH_LASER_SIGHT_COMBAT_OCULAR_ENHANCER_RIGHT_BLACK  : 32853,
    SQUIRE_SMALL_REMOTE_CAPACITOR_TRANSMITTER  : 23852,
    ST_TETRIMONS_FINGER  : 49634,
    STABBER  : 622,
    STABBER_ABYSSAL_FIRESTORM_SKIN  : 53141,
    STABBER_AURORA_UNIVERSALIS_SKIN  : 57023,
    STABBER_BIOSECURITY_RESPONDERS_SKIN  : 55649,
    STABBER_BLUE_TIGER_SKIN  : 36817,
    STABBER_BLUEPRINT  : 692,
    STABBER_DAWN_OF_LIBERATION_SKIN  : 48504,
    STABBER_DEATHGLOW_REMNANT_SKIN  : 60662,
    STABBER_EXOPLANETS_HUNTER_SKIN  : 45957,
    STABBER_FLEET_ISSUE  : 17713,
    STABBER_FLEET_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55670,
    STABBER_FLEET_ISSUE_BRUTOR_SKIN  : 59872,
    STABBER_FLEET_ISSUE_CHAINBREAKER_KHUMAAK_SKIN  : 77963,
    STABBER_FLEET_ISSUE_DAWN_OF_LIBERATION_SKIN  : 48513,
    STABBER_FLEET_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45966,
    STABBER_FLEET_ISSUE_GLACIAL_DRIFT_SKIN  : 46903,
    STABBER_FLEET_ISSUE_JUSTICE_SKIN  : 36441,
    STABBER_FLEET_ISSUE_KRUSUAL_SKIN  : 59832,
    STABBER_FLEET_ISSUE_NEFANTAR_SKIN  : 59824,
    STABBER_FLEET_ISSUE_SEBIESTOR_SKIN  : 59839,
    STABBER_FLEET_ISSUE_SNOWLINE_BLADERACER_SKIN  : 52877,
    STABBER_FLEET_ISSUE_SPIRIT_SKIN  : 57394,
    STABBER_FLEET_ISSUE_STARKMANIR_SKIN  : 59864,
    STABBER_FLEET_ISSUE_THUKKER_TRIBE_SKIN  : 59848,
    STABBER_FLEET_ISSUE_VHEROKIOR_SKIN  : 59856,
    STABBER_FLEET_ISSUE_WARP_CONVERGENCE_SKIN  : 61877,
    STABBER_FLEET_ORDNANCE_SPIKE_SKIN  : 59242,
    STABBER_GLACIAL_DRIFT_SKIN  : 46894,
    STABBER_GLACIAL_DRIFT_SKIN2  : 44171,
    STABBER_HAZARD_CONTROL_SKIN  : 56920,
    STABBER_HUNTERS_QUIVER_SKIN  : 47506,
    STABBER_KRUSUAL_SKIN  : 34698,
    STABBER_LUMINAIRE_ZENITH_SKIN  : 48232,
    STABBER_NEFANTAR_SKIN  : 34699,
    STABBER_REPUBLIC_FLEET_SKIN  : 59811,
    STABBER_SNOWLINE_BLADERACER_SKIN  : 52868,
    STABBER_SPIRIT_SKIN  : 47317,
    STABBER_TRIGLAVIAN_TWILIGHT_SKIN  : 52403,
    STABBER_TRONHADAR_INK_SKIN  : 40487,
    STABBER_VALKLEAR_GLORY_SKIN  : 36770,
    STABBER_VERSUS_BLUEFORCE_SKIN  : 63245,
    STABBER_WARP_CONVERGENCE_SKIN  : 61876,
    STABBER_ZAKURA_SHUMYU_SKIN  : 57066,
    STABLE_VELDSPAR  : 46689,
    STALWART_RESTRAINED_SHIELD_BOOST_AMPLIFIER  : 16533,
    STANDARD_ARDOUR_CEREBRAL_ACCELERATOR_BLUEPRINT_CRATE  : 50030,
    STANDARD_BLUE_PILL_BOOSTER  : 9950,
    STANDARD_BLUE_PILL_BOOSTER_REACTION_FORMULA  : 46230,
    STANDARD_CRASH_BOOSTER  : 9947,
    STANDARD_CRASH_BOOSTER_REACTION_FORMULA  : 46231,
    STANDARD_DROP_BOOSTER  : 15466,
    STANDARD_DROP_BOOSTER_REACTION_FORMULA  : 46223,
    STANDARD_EXILE_BOOSTER  : 15479,
    STANDARD_EXILE_BOOSTER_REACTION_FORMULA  : 46224,
    STANDARD_FRENTIX_BOOSTER  : 15460,
    STANDARD_FRENTIX_BOOSTER_REACTION_FORMULA  : 46222,
    STANDARD_L  : 258,
    STANDARD_L_BLUEPRINT  : 1173,
    STANDARD_M  : 250,
    STANDARD_M_BLUEPRINT  : 1165,
    STANDARD_MINDFLOOD_BOOSTER  : 15463,
    STANDARD_MINDFLOOD_BOOSTER_REACTION_FORMULA  : 46225,
    STANDARD_S  : 242,
    STANDARD_S_BLUEPRINT  : 1157,
    STANDARD_SOOTH_SAYER_BOOSTER  : 10164,
    STANDARD_SOOTH_SAYER_BOOSTER_REACTION_FORMULA  : 46227,
    STANDARD_X_INSTINCT_BOOSTER  : 15457,
    STANDARD_X_INSTINCT_BOOSTER_REACTION_FORMULA  : 46226,
    STANDARD_XL  : 17690,
    STANDARD_XL_BLUEPRINT  : 17691,
    STANDUP_AMETAT_I  : 47124,
    STANDUP_AMETAT_I_BLUEPRINT  : 47241,
    STANDUP_AMETAT_II  : 47127,
    STANDUP_ANTAEUS_I  : 47126,
    STANDUP_ANTAEUS_I_BLUEPRINT  : 47245,
    STANDUP_ANTAEUS_II  : 47129,
    STANDUP_ANTICAPITAL_MISSILE_LAUNCHER_I  : 35921,
    STANDUP_ANTICAPITAL_MISSILE_LAUNCHER_I_BLUEPRINT  : 37047,
    STANDUP_ANTICAPITAL_MISSILE_LAUNCHER_II  : 47323,
    STANDUP_ARCING_VORTON_PROJECTOR_I  : 35928,
    STANDUP_ARCING_VORTON_PROJECTOR_I_BLUEPRINT  : 37030,
    STANDUP_AXL_SR_MISSILE  : 37845,
    STANDUP_AXL_SR_MISSILE_BLUEPRINT  : 37854,
    STANDUP_BALLISTIC_CONTROL_SYSTEM_I  : 35959,
    STANDUP_BALLISTIC_CONTROL_SYSTEM_I_BLUEPRINT  : 37020,
    STANDUP_BALLISTIC_CONTROL_SYSTEM_II  : 47342,
    STANDUP_BIOCHEMICAL_REACTOR_I  : 45539,
    STANDUP_BIOCHEMICAL_REACTOR_I_BLUEPRINT  : 45542,
    STANDUP_CAP_BATTERY_I  : 47352,
    STANDUP_CAP_BATTERY_I_BLUEPRINT  : 47354,
    STANDUP_CAP_BATTERY_II  : 47353,
    STANDUP_CAPACITOR_POWER_RELAY_I  : 47356,
    STANDUP_CAPACITOR_POWER_RELAY_I_BLUEPRINT  : 47357,
    STANDUP_CAPACITOR_POWER_RELAY_II  : 47358,
    STANDUP_CAPITAL_SHIPYARD_I  : 35881,
    STANDUP_CAPITAL_SHIPYARD_I_BLUEPRINT  : 43925,
    STANDUP_CENOBITE_I  : 47131,
    STANDUP_CENOBITE_I_BLUEPRINT  : 47224,
    STANDUP_CENOBITE_II  : 47134,
    STANDUP_CLONING_CENTER_I  : 35894,
    STANDUP_CLONING_CENTER_I_BLUEPRINT  : 37023,
    STANDUP_CO_PROCESSOR_ARRAY_I  : 35963,
    STANDUP_CO_PROCESSOR_ARRAY_I_BLUEPRINT  : 37028,
    STANDUP_CO_PROCESSOR_ARRAY_II  : 47344,
    STANDUP_COMPOSITE_REACTOR_I  : 45537,
    STANDUP_COMPOSITE_REACTOR_I_BLUEPRINT  : 45540,
    STANDUP_CRUISE_MISSILE  : 37846,
    STANDUP_CRUISE_MISSILE_BLUEPRINT  : 37855,
    STANDUP_CYCLOPS_I  : 47117,
    STANDUP_CYCLOPS_I_BLUEPRINT  : 47233,
    STANDUP_CYCLOPS_II  : 47121,
    STANDUP_DRAGONFLY_I  : 47138,
    STANDUP_DRAGONFLY_I_BLUEPRINT  : 47209,
    STANDUP_DRAGONFLY_II  : 47142,
    STANDUP_DROMI_I  : 47133,
    STANDUP_DROMI_I_BLUEPRINT  : 47230,
    STANDUP_DROMI_II  : 47137,
    STANDUP_ECM_JAMMER_BURST_PROJECTOR  : 47069,
    STANDUP_ECM_JAMMER_BURST_PROJECTOR_BLUEPRINT  : 47110,
    STANDUP_EINHERJI_I  : 47140,
    STANDUP_EINHERJI_I_BLUEPRINT  : 47211,
    STANDUP_EINHERJI_II  : 47144,
    STANDUP_ENERGY_NEUTRALIZATION_BURST_PROJECTOR  : 35944,
    STANDUP_ENERGY_NEUTRALIZATION_BURST_PROJECTOR_BLUEPRINT  : 47114,
    STANDUP_EQUITE_I  : 47145,
    STANDUP_EQUITE_I_BLUEPRINT  : 47213,
    STANDUP_EQUITE_II  : 47148,
    STANDUP_FIRBOLG_I  : 47139,
    STANDUP_FIRBOLG_I_BLUEPRINT  : 47210,
    STANDUP_FIRBOLG_II  : 47143,
    STANDUP_FLAK_ROUND_BLUEPRINT  : 63196,
    STANDUP_FLAK_ROUND_I  : 63195,
    STANDUP_FOCUSED_WARP_DISRUPTOR_I  : 35949,
    STANDUP_FOCUSED_WARP_DISRUPTOR_I_BLUEPRINT  : 37087,
    STANDUP_FOCUSED_WARP_DISRUPTOR_II  : 47334,
    STANDUP_FOCUSED_WARP_SCRAMBLING_SCRIPT  : 47336,
    STANDUP_FOCUSED_WARP_SCRAMBLING_SCRIPT_BLUEPRINT  : 47337,
    STANDUP_GRAM_I  : 47036,
    STANDUP_GRAM_I_BLUEPRINT  : 47222,
    STANDUP_GRAM_II  : 47151,
    STANDUP_GRAVIMETRIC_ECM_SCRIPT  : 37821,
    STANDUP_GRAVIMETRIC_ECM_SCRIPT_BLUEPRINT  : 37833,
    STANDUP_GUIDED_BOMB_LAUNCHER_I  : 35923,
    STANDUP_GUIDED_BOMB_LAUNCHER_I_BLUEPRINT  : 37008,
    STANDUP_GUIDED_BOMB_LAUNCHER_II  : 47325,
    STANDUP_GUNGNIR_I  : 47039,
    STANDUP_GUNGNIR_I_BLUEPRINT  : 47247,
    STANDUP_GUNGNIR_II  : 47130,
    STANDUP_HEAVY_ENERGY_NEUTRALIZER_I  : 35925,
    STANDUP_HEAVY_ENERGY_NEUTRALIZER_I_BLUEPRINT  : 37044,
    STANDUP_HEAVY_ENERGY_NEUTRALIZER_II  : 47332,
    STANDUP_HEAVY_GUIDED_BOMB  : 37849,
    STANDUP_HEAVY_GUIDED_BOMB_BLUEPRINT  : 37858,
    STANDUP_HEAVY_MISSILE  : 37847,
    STANDUP_HEAVY_MISSILE_BLUEPRINT  : 37856,
    STANDUP_HYASYODA_RESEARCH_LAB  : 45550,
    STANDUP_HYBRID_REACTOR_I  : 45538,
    STANDUP_HYBRID_REACTOR_I_BLUEPRINT  : 45541,
    STANDUP_INVENTION_LAB_I  : 35886,
    STANDUP_INVENTION_LAB_I_BLUEPRINT  : 43926,
    STANDUP_L_SET_ADVANCED_COMPONENT_MANUFACTURING_EFFICIENCY_I  : 37174,
    STANDUP_L_SET_ADVANCED_COMPONENT_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37338,
    STANDUP_L_SET_ADVANCED_COMPONENT_MANUFACTURING_EFFICIENCY_II  : 37175,
    STANDUP_L_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_EFFICIENCY_I  : 37168,
    STANDUP_L_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37332,
    STANDUP_L_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_EFFICIENCY_II  : 37169,
    STANDUP_L_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_EFFICIENCY_I  : 43709,
    STANDUP_L_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44074,
    STANDUP_L_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_EFFICIENCY_II  : 43711,
    STANDUP_L_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_EFFICIENCY_I  : 43707,
    STANDUP_L_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44076,
    STANDUP_L_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_EFFICIENCY_II  : 43708,
    STANDUP_L_SET_AMMUNITION_MANUFACTURING_EFFICIENCY_I  : 37164,
    STANDUP_L_SET_AMMUNITION_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37309,
    STANDUP_L_SET_AMMUNITION_MANUFACTURING_EFFICIENCY_II  : 37165,
    STANDUP_L_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_EFFICIENCY_I  : 43718,
    STANDUP_L_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44078,
    STANDUP_L_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_EFFICIENCY_II  : 43719,
    STANDUP_L_SET_BASIC_LARGE_SHIP_MANUFACTURING_EFFICIENCY_I  : 37166,
    STANDUP_L_SET_BASIC_LARGE_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37330,
    STANDUP_L_SET_BASIC_LARGE_SHIP_MANUFACTURING_EFFICIENCY_II  : 37167,
    STANDUP_L_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_EFFICIENCY_I  : 43716,
    STANDUP_L_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44080,
    STANDUP_L_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_EFFICIENCY_II  : 43717,
    STANDUP_L_SET_BASIC_SMALL_SHIP_MANUFACTURING_EFFICIENCY_I  : 43714,
    STANDUP_L_SET_BASIC_SMALL_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44082,
    STANDUP_L_SET_BASIC_SMALL_SHIP_MANUFACTURING_EFFICIENCY_II  : 43715,
    STANDUP_L_SET_BLUEPRINT_COPY_OPTIMIZATION_I  : 43729,
    STANDUP_L_SET_BLUEPRINT_COPY_OPTIMIZATION_I_BLUEPRINT  : 44084,
    STANDUP_L_SET_BLUEPRINT_COPY_OPTIMIZATION_II  : 43730,
    STANDUP_L_SET_BOMB_AIMER_I  : 37256,
    STANDUP_L_SET_BOMB_AIMER_I_BLUEPRINT  : 37419,
    STANDUP_L_SET_BOMB_AIMER_II  : 37257,
    STANDUP_L_SET_CAPITAL_SHIP_MANUFACTURING_EFFICIENCY_I  : 37173,
    STANDUP_L_SET_CAPITAL_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44086,
    STANDUP_L_SET_CAPITAL_SHIP_MANUFACTURING_EFFICIENCY_II  : 37172,
    STANDUP_L_SET_DRONE_AND_FIGHTER_MANUFACTURING_EFFICIENCY_I  : 43712,
    STANDUP_L_SET_DRONE_AND_FIGHTER_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44088,
    STANDUP_L_SET_DRONE_AND_FIGHTER_MANUFACTURING_EFFICIENCY_II  : 43713,
    STANDUP_L_SET_ENERGY_NEUTRALIZER_FEEDBACK_CONTROL_I  : 37250,
    STANDUP_L_SET_ENERGY_NEUTRALIZER_FEEDBACK_CONTROL_I_BLUEPRINT  : 37413,
    STANDUP_L_SET_ENERGY_NEUTRALIZER_FEEDBACK_CONTROL_II  : 37251,
    STANDUP_L_SET_EQUIPMENT_MANUFACTURING_EFFICIENCY_I  : 37170,
    STANDUP_L_SET_EQUIPMENT_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37334,
    STANDUP_L_SET_EQUIPMENT_MANUFACTURING_EFFICIENCY_II  : 37171,
    STANDUP_L_SET_EW_COMMAND_SYSTEM_I  : 37254,
    STANDUP_L_SET_EW_COMMAND_SYSTEM_I_BLUEPRINT  : 37417,
    STANDUP_L_SET_EW_COMMAND_SYSTEM_II  : 37255,
    STANDUP_L_SET_FIGHTER_MISSION_CONTROL_I_BLUEPRINT  : 37415,
    STANDUP_L_SET_INVENTION_OPTIMIZATION_I  : 43722,
    STANDUP_L_SET_INVENTION_OPTIMIZATION_I_BLUEPRINT  : 44090,
    STANDUP_L_SET_INVENTION_OPTIMIZATION_II  : 43723,
    STANDUP_L_SET_ME_RESEARCH_OPTIMIZATION_I  : 43724,
    STANDUP_L_SET_ME_RESEARCH_OPTIMIZATION_I_BLUEPRINT  : 44092,
    STANDUP_L_SET_ME_RESEARCH_OPTIMIZATION_II  : 43725,
    STANDUP_L_SET_MISSILE_FLIGHT_PROCESSOR_I  : 37248,
    STANDUP_L_SET_MISSILE_FLIGHT_PROCESSOR_I_BLUEPRINT  : 37410,
    STANDUP_L_SET_MISSILE_FLIGHT_PROCESSOR_II  : 37249,
    STANDUP_L_SET_MOON_DRILLING_PROFICIENCY_I  : 46327,
    STANDUP_L_SET_MOON_DRILLING_PROFICIENCY_I_BLUEPRINT  : 46501,
    STANDUP_L_SET_MOON_DRILLING_PROFICIENCY_II  : 46328,
    STANDUP_L_SET_POINT_DEFENSE_BATTERY_CONTROL_I  : 37258,
    STANDUP_L_SET_POINT_DEFENSE_BATTERY_CONTROL_I_BLUEPRINT  : 37421,
    STANDUP_L_SET_POINT_DEFENSE_BATTERY_CONTROL_II  : 37259,
    STANDUP_L_SET_REACTOR_EFFICIENCY_I  : 46496,
    STANDUP_L_SET_REACTOR_EFFICIENCY_I_BLUEPRINT  : 46518,
    STANDUP_L_SET_REACTOR_EFFICIENCY_II  : 46497,
    STANDUP_L_SET_REPROCESSING_MONITOR_I  : 46639,
    STANDUP_L_SET_REPROCESSING_MONITOR_I_BLUEPRINT  : 46649,
    STANDUP_L_SET_REPROCESSING_MONITOR_II  : 46640,
    STANDUP_L_SET_STRUCTURE_MANUFACTURING_EFFICIENCY_I  : 43720,
    STANDUP_L_SET_STRUCTURE_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 44094,
    STANDUP_L_SET_STRUCTURE_MANUFACTURING_EFFICIENCY_II  : 43721,
    STANDUP_L_SET_TARGET_ACQUISITION_ARRAY_I  : 37260,
    STANDUP_L_SET_TARGET_ACQUISITION_ARRAY_I_BLUEPRINT  : 37423,
    STANDUP_L_SET_TARGET_ACQUISITION_ARRAY_II  : 37261,
    STANDUP_L_SET_TE_RESEARCH_OPTIMIZATION_I  : 43726,
    STANDUP_L_SET_TE_RESEARCH_OPTIMIZATION_I_BLUEPRINT  : 44096,
    STANDUP_L_SET_TE_RESEARCH_OPTIMIZATION_II  : 43727,
    STANDUP_L_SET_THUKKER_ADVANCED_COMPONENT_MANUFACTURING_EFFICIENCY  : 45641,
    STANDUP_L_SET_THUKKER_BASIC_CAPITAL_COMPONENT_MANUFACTURING_EFFICIENCY  : 45546,
    STANDUP_LADAR_ECM_SCRIPT  : 37822,
    STANDUP_LADAR_ECM_SCRIPT_BLUEPRINT  : 37834,
    STANDUP_LARGE_FESTIVAL_LAUNCHER  : 47303,
    STANDUP_LAYERED_ARMOR_PLATING_I  : 47360,
    STANDUP_LAYERED_ARMOR_PLATING_I_BLUEPRINT  : 47361,
    STANDUP_LAYERED_ARMOR_PLATING_II  : 47362,
    STANDUP_LIGHT_GUIDED_BOMB  : 37850,
    STANDUP_LIGHT_GUIDED_BOMB_BLUEPRINT  : 37859,
    STANDUP_LIGHT_MISSILE  : 37848,
    STANDUP_LIGHT_MISSILE_BLUEPRINT  : 37857,
    STANDUP_LOCUST_I  : 47146,
    STANDUP_LOCUST_I_BLUEPRINT  : 47216,
    STANDUP_LOCUST_II  : 47149,
    STANDUP_M_SET_ADVANCED_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43867,
    STANDUP_M_SET_ADVANCED_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44038,
    STANDUP_M_SET_ADVANCED_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 43866,
    STANDUP_M_SET_ADVANCED_COMPONENT_MANUFACTURING_TIME_EFFICIENCY_I  : 43869,
    STANDUP_M_SET_ADVANCED_COMPONENT_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44040,
    STANDUP_M_SET_ADVANCED_COMPONENT_MANUFACTURING_TIME_EFFICIENCY_II  : 43868,
    STANDUP_M_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43862,
    STANDUP_M_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44034,
    STANDUP_M_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 43863,
    STANDUP_M_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_TIME_EFFICIENCY_I  : 43865,
    STANDUP_M_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44036,
    STANDUP_M_SET_ADVANCED_LARGE_SHIP_MANUFACTURING_TIME_EFFICIENCY_II  : 43864,
    STANDUP_M_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43858,
    STANDUP_M_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44030,
    STANDUP_M_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 43859,
    STANDUP_M_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_TIME_EFFICIENCY_I  : 43860,
    STANDUP_M_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44032,
    STANDUP_M_SET_ADVANCED_MEDIUM_SHIP_MANUFACTURING_TIME_EFFICIENCY_II  : 43861,
    STANDUP_M_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43855,
    STANDUP_M_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44026,
    STANDUP_M_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 43854,
    STANDUP_M_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_TIME_EFFICIENCY_I  : 43856,
    STANDUP_M_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44028,
    STANDUP_M_SET_ADVANCED_SMALL_SHIP_MANUFACTURING_TIME_EFFICIENCY_II  : 43857,
    STANDUP_M_SET_AMMUNITION_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 37158,
    STANDUP_M_SET_AMMUNITION_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44006,
    STANDUP_M_SET_AMMUNITION_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 37159,
    STANDUP_M_SET_AMMUNITION_MANUFACTURING_TIME_EFFICIENCY_I  : 37150,
    STANDUP_M_SET_AMMUNITION_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44008,
    STANDUP_M_SET_AMMUNITION_MANUFACTURING_TIME_EFFICIENCY_II  : 37151,
    STANDUP_M_SET_ASTEROID_ORE_GRADING_PROCESSOR_I  : 46633,
    STANDUP_M_SET_ASTEROID_ORE_GRADING_PROCESSOR_I_BLUEPRINT  : 46643,
    STANDUP_M_SET_ASTEROID_ORE_GRADING_PROCESSOR_II  : 46634,
    STANDUP_M_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43870,
    STANDUP_M_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44042,
    STANDUP_M_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 43871,
    STANDUP_M_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_TIME_EFFICIENCY_I  : 43872,
    STANDUP_M_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44044,
    STANDUP_M_SET_BASIC_CAPITAL_COMPONENT_MANUFACTURING_TIME_EFFICIENCY_II  : 43873,
    STANDUP_M_SET_BASIC_LARGE_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43732,
    STANDUP_M_SET_BASIC_LARGE_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44022,
    STANDUP_M_SET_BASIC_LARGE_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 37152,
    STANDUP_M_SET_BASIC_LARGE_SHIP_MANUFACTURING_TIME_EFFICIENCY_I  : 43733,
    STANDUP_M_SET_BASIC_LARGE_SHIP_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44024,
    STANDUP_M_SET_BASIC_LARGE_SHIP_MANUFACTURING_TIME_EFFICIENCY_II  : 43734,
    STANDUP_M_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 37146,
    STANDUP_M_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44018,
    STANDUP_M_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 37147,
    STANDUP_M_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_TIME_EFFICIENCY_I  : 43919,
    STANDUP_M_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44020,
    STANDUP_M_SET_BASIC_MEDIUM_SHIP_MANUFACTURING_TIME_EFFICIENCY_II  : 37153,
    STANDUP_M_SET_BASIC_SMALL_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 37154,
    STANDUP_M_SET_BASIC_SMALL_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44014,
    STANDUP_M_SET_BASIC_SMALL_SHIP_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 37155,
    STANDUP_M_SET_BASIC_SMALL_SHIP_MANUFACTURING_TIME_EFFICIENCY_I  : 37162,
    STANDUP_M_SET_BASIC_SMALL_SHIP_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44016,
    STANDUP_M_SET_BASIC_SMALL_SHIP_MANUFACTURING_TIME_EFFICIENCY_II  : 37163,
    STANDUP_M_SET_BIOCHEMICAL_REACTOR_MATERIAL_EFFICIENCY_I  : 46494,
    STANDUP_M_SET_BIOCHEMICAL_REACTOR_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 46516,
    STANDUP_M_SET_BIOCHEMICAL_REACTOR_MATERIAL_EFFICIENCY_II  : 46495,
    STANDUP_M_SET_BIOCHEMICAL_REACTOR_TIME_EFFICIENCY_I  : 46492,
    STANDUP_M_SET_BIOCHEMICAL_REACTOR_TIME_EFFICIENCY_I_BLUEPRINT  : 46514,
    STANDUP_M_SET_BIOCHEMICAL_REACTOR_TIME_EFFICIENCY_II  : 46493,
    STANDUP_M_SET_BLUEPRINT_COPY_ACCELERATOR_I  : 43893,
    STANDUP_M_SET_BLUEPRINT_COPY_ACCELERATOR_I_BLUEPRINT  : 44064,
    STANDUP_M_SET_BLUEPRINT_COPY_ACCELERATOR_II  : 43892,
    STANDUP_M_SET_BLUEPRINT_COPY_COST_OPTIMIZATION_I  : 43891,
    STANDUP_M_SET_BLUEPRINT_COPY_COST_OPTIMIZATION_I_BLUEPRINT  : 44062,
    STANDUP_M_SET_BLUEPRINT_COPY_COST_OPTIMIZATION_II  : 43890,
    STANDUP_M_SET_COMPOSITE_REACTOR_MATERIAL_EFFICIENCY_I  : 46486,
    STANDUP_M_SET_COMPOSITE_REACTOR_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 46508,
    STANDUP_M_SET_COMPOSITE_REACTOR_MATERIAL_EFFICIENCY_II  : 46487,
    STANDUP_M_SET_COMPOSITE_REACTOR_TIME_EFFICIENCY_I  : 46484,
    STANDUP_M_SET_COMPOSITE_REACTOR_TIME_EFFICIENCY_I_BLUEPRINT  : 46506,
    STANDUP_M_SET_COMPOSITE_REACTOR_TIME_EFFICIENCY_II  : 46485,
    STANDUP_M_SET_DRONE_AND_FIGHTER_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 37156,
    STANDUP_M_SET_DRONE_AND_FIGHTER_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44010,
    STANDUP_M_SET_DRONE_AND_FIGHTER_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 37157,
    STANDUP_M_SET_DRONE_AND_FIGHTER_MANUFACTURING_TIME_EFFICIENCY_I  : 37148,
    STANDUP_M_SET_DRONE_AND_FIGHTER_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44012,
    STANDUP_M_SET_DRONE_AND_FIGHTER_MANUFACTURING_TIME_EFFICIENCY_II  : 37149,
    STANDUP_M_SET_ELECTRONIC_WARFARE_ECONOMY_I  : 37230,
    STANDUP_M_SET_ELECTRONIC_WARFARE_ECONOMY_I_BLUEPRINT  : 37392,
    STANDUP_M_SET_ELECTRONIC_WARFARE_ECONOMY_II  : 37231,
    STANDUP_M_SET_ELECTRONIC_WARFARE_PROJECTION_I  : 37228,
    STANDUP_M_SET_ELECTRONIC_WARFARE_PROJECTION_I_BLUEPRINT  : 37390,
    STANDUP_M_SET_ELECTRONIC_WARFARE_PROJECTION_II  : 37229,
    STANDUP_M_SET_ENERGY_NEUTRALIZER_ECONOMY_I  : 37222,
    STANDUP_M_SET_ENERGY_NEUTRALIZER_ECONOMY_I_BLUEPRINT  : 37384,
    STANDUP_M_SET_ENERGY_NEUTRALIZER_ECONOMY_II  : 37223,
    STANDUP_M_SET_ENERGY_NEUTRALIZER_PROJECTION_I  : 37218,
    STANDUP_M_SET_ENERGY_NEUTRALIZER_PROJECTION_I_BLUEPRINT  : 37382,
    STANDUP_M_SET_ENERGY_NEUTRALIZER_PROJECTION_II  : 37219,
    STANDUP_M_SET_ENHANCED_TARGETING_SYSTEM_I  : 37234,
    STANDUP_M_SET_ENHANCED_TARGETING_SYSTEM_I_BLUEPRINT  : 37396,
    STANDUP_M_SET_ENHANCED_TARGETING_SYSTEM_II  : 37235,
    STANDUP_M_SET_EQUIPMENT_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43920,
    STANDUP_M_SET_EQUIPMENT_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44002,
    STANDUP_M_SET_EQUIPMENT_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 43921,
    STANDUP_M_SET_EQUIPMENT_MANUFACTURING_TIME_EFFICIENCY_I  : 37160,
    STANDUP_M_SET_EQUIPMENT_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44004,
    STANDUP_M_SET_EQUIPMENT_MANUFACTURING_TIME_EFFICIENCY_II  : 37161,
    STANDUP_M_SET_FIGHTER_NAVIGATION_I_BLUEPRINT  : 37388,
    STANDUP_M_SET_FIGHTER_SURVIVAL_I_BLUEPRINT  : 37386,
    STANDUP_M_SET_HYBRID_REACTOR_MATERIAL_EFFICIENCY_I  : 46490,
    STANDUP_M_SET_HYBRID_REACTOR_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 46512,
    STANDUP_M_SET_HYBRID_REACTOR_MATERIAL_EFFICIENCY_II  : 46491,
    STANDUP_M_SET_HYBRID_REACTOR_TIME_EFFICIENCY_I  : 46488,
    STANDUP_M_SET_HYBRID_REACTOR_TIME_EFFICIENCY_I_BLUEPRINT  : 46510,
    STANDUP_M_SET_HYBRID_REACTOR_TIME_EFFICIENCY_II  : 46489,
    STANDUP_M_SET_ICE_GRADING_PROCESSOR_I  : 46635,
    STANDUP_M_SET_ICE_GRADING_PROCESSOR_I_BLUEPRINT  : 46645,
    STANDUP_M_SET_ICE_GRADING_PROCESSOR_II  : 46636,
    STANDUP_M_SET_INVENTION_ACCELERATOR_I  : 43880,
    STANDUP_M_SET_INVENTION_ACCELERATOR_I_BLUEPRINT  : 44052,
    STANDUP_M_SET_INVENTION_ACCELERATOR_II  : 43881,
    STANDUP_M_SET_INVENTION_COST_OPTIMIZATION_I  : 43879,
    STANDUP_M_SET_INVENTION_COST_OPTIMIZATION_I_BLUEPRINT  : 44050,
    STANDUP_M_SET_INVENTION_COST_OPTIMIZATION_II  : 43878,
    STANDUP_M_SET_ME_RESEARCH_ACCELERATOR_I  : 43883,
    STANDUP_M_SET_ME_RESEARCH_ACCELERATOR_I_BLUEPRINT  : 44056,
    STANDUP_M_SET_ME_RESEARCH_ACCELERATOR_II  : 43882,
    STANDUP_M_SET_ME_RESEARCH_COST_OPTIMIZATION_I  : 43885,
    STANDUP_M_SET_ME_RESEARCH_COST_OPTIMIZATION_I_BLUEPRINT  : 44054,
    STANDUP_M_SET_ME_RESEARCH_COST_OPTIMIZATION_II  : 43884,
    STANDUP_M_SET_MISSILE_PRECISION_I  : 37216,
    STANDUP_M_SET_MISSILE_PRECISION_I_BLUEPRINT  : 37378,
    STANDUP_M_SET_MISSILE_PRECISION_II  : 37217,
    STANDUP_M_SET_MISSILE_PROJECTION_I  : 37220,
    STANDUP_M_SET_MISSILE_PROJECTION_I_BLUEPRINT  : 37380,
    STANDUP_M_SET_MISSILE_PROJECTION_II  : 37221,
    STANDUP_M_SET_MOON_DRILLING_EFFICIENCY_I  : 46323,
    STANDUP_M_SET_MOON_DRILLING_EFFICIENCY_I_BLUEPRINT  : 46505,
    STANDUP_M_SET_MOON_DRILLING_EFFICIENCY_II  : 46324,
    STANDUP_M_SET_MOON_DRILLING_STABILITY_I  : 46325,
    STANDUP_M_SET_MOON_DRILLING_STABILITY_I_BLUEPRINT  : 46503,
    STANDUP_M_SET_MOON_DRILLING_STABILITY_II  : 46326,
    STANDUP_M_SET_MOON_ORE_GRADING_PROCESSOR_I  : 46637,
    STANDUP_M_SET_MOON_ORE_GRADING_PROCESSOR_I_BLUEPRINT  : 46647,
    STANDUP_M_SET_MOON_ORE_GRADING_PROCESSOR_II  : 46638,
    STANDUP_M_SET_STRUCTURE_MANUFACTURING_MATERIAL_EFFICIENCY_I  : 43875,
    STANDUP_M_SET_STRUCTURE_MANUFACTURING_MATERIAL_EFFICIENCY_I_BLUEPRINT  : 44046,
    STANDUP_M_SET_STRUCTURE_MANUFACTURING_MATERIAL_EFFICIENCY_II  : 43874,
    STANDUP_M_SET_STRUCTURE_MANUFACTURING_TIME_EFFICIENCY_I  : 43876,
    STANDUP_M_SET_STRUCTURE_MANUFACTURING_TIME_EFFICIENCY_I_BLUEPRINT  : 44048,
    STANDUP_M_SET_STRUCTURE_MANUFACTURING_TIME_EFFICIENCY_II  : 43877,
    STANDUP_M_SET_STRUCTURE_TARGET_MULTIPLEXING_I  : 37232,
    STANDUP_M_SET_STRUCTURE_TARGET_MULTIPLEXING_I_BLUEPRINT  : 37394,
    STANDUP_M_SET_STRUCTURE_TARGET_MULTIPLEXING_II  : 37233,
    STANDUP_M_SET_TE_RESEARCH_ACCELERATOR_I  : 43889,
    STANDUP_M_SET_TE_RESEARCH_ACCELERATOR_I_BLUEPRINT  : 44060,
    STANDUP_M_SET_TE_RESEARCH_ACCELERATOR_II  : 43888,
    STANDUP_M_SET_TE_RESEARCH_COST_OPTIMIZATION_I  : 43887,
    STANDUP_M_SET_TE_RESEARCH_COST_OPTIMIZATION_I_BLUEPRINT  : 44058,
    STANDUP_M_SET_TE_RESEARCH_COST_OPTIMIZATION_II  : 43886,
    STANDUP_M_SET_THUKKER_ADVANCED_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY  : 45640,
    STANDUP_M_SET_THUKKER_BASIC_CAPITAL_COMPONENT_MANUFACTURING_MATERIAL_EFFICIENCY  : 45544,
    STANDUP_MAGNETOMETRIC_ECM_SCRIPT  : 37823,
    STANDUP_MAGNETOMETRIC_ECM_SCRIPT_BLUEPRINT  : 37835,
    STANDUP_MALLEUS_I  : 47116,
    STANDUP_MALLEUS_I_BLUEPRINT  : 47234,
    STANDUP_MALLEUS_II  : 47119,
    STANDUP_MANTIS_I  : 47038,
    STANDUP_MANTIS_I_BLUEPRINT  : 47236,
    STANDUP_MANTIS_II  : 47120,
    STANDUP_MANUFACTURING_PLANT_I  : 35878,
    STANDUP_MANUFACTURING_PLANT_I_BLUEPRINT  : 43927,
    STANDUP_MARKET_HUB_I  : 35892,
    STANDUP_MARKET_HUB_I_BLUEPRINT  : 37022,
    STANDUP_MISSILE_GUIDANCE_ENHANCER_I  : 35962,
    STANDUP_MISSILE_GUIDANCE_ENHANCER_I_BLUEPRINT  : 37045,
    STANDUP_MISSILE_GUIDANCE_ENHANCER_II  : 47340,
    STANDUP_MOON_DRILL_I  : 45009,
    STANDUP_MOON_DRILL_I_BLUEPRINT  : 37034,
    STANDUP_MULTIROLE_MISSILE_LAUNCHER_I  : 35922,
    STANDUP_MULTIROLE_MISSILE_LAUNCHER_I_BLUEPRINT  : 37048,
    STANDUP_MULTIROLE_MISSILE_LAUNCHER_II  : 47298,
    STANDUP_POINT_DEFENSE_BATTERY_I  : 35926,
    STANDUP_POINT_DEFENSE_BATTERY_I_BLUEPRINT  : 37029,
    STANDUP_POINT_DEFENSE_BATTERY_II  : 47327,
    STANDUP_RADAR_ECM_SCRIPT  : 37824,
    STANDUP_RADAR_ECM_SCRIPT_BLUEPRINT  : 37836,
    STANDUP_REACTOR_CONTROL_UNIT_I  : 35965,
    STANDUP_REACTOR_CONTROL_UNIT_I_BLUEPRINT  : 37060,
    STANDUP_REACTOR_CONTROL_UNIT_II  : 47347,
    STANDUP_REMOTE_SENSOR_DAMPENER_I  : 35941,
    STANDUP_REMOTE_SENSOR_DAMPENER_I_BLUEPRINT  : 37066,
    STANDUP_REMOTE_SENSOR_DAMPENER_II  : 47368,
    STANDUP_REPROCESSING_FACILITY_I  : 35899,
    STANDUP_REPROCESSING_FACILITY_I_BLUEPRINT  : 37032,
    STANDUP_RESEARCH_LAB_I  : 35891,
    STANDUP_RESEARCH_LAB_I_BLUEPRINT  : 43928,
    STANDUP_SATYR_I  : 47147,
    STANDUP_SATYR_I_BLUEPRINT  : 47219,
    STANDUP_SATYR_II  : 47150,
    STANDUP_SCARAB_I  : 47132,
    STANDUP_SCARAB_I_BLUEPRINT  : 47226,
    STANDUP_SCARAB_II  : 47135,
    STANDUP_SENSOR_DAMPENING_BURST_PROJECTOR  : 47070,
    STANDUP_SENSOR_DAMPENING_BURST_PROJECTOR_BLUEPRINT  : 47111,
    STANDUP_SHADOW  : 47123,
    STANDUP_SIGNAL_AMPLIFIER_I  : 37532,
    STANDUP_SIGNAL_AMPLIFIER_I_BLUEPRINT  : 37541,
    STANDUP_SIGNAL_AMPLIFIER_II  : 47348,
    STANDUP_SIREN_I  : 47037,
    STANDUP_SIREN_I_BLUEPRINT  : 47228,
    STANDUP_SIREN_II  : 47136,
    STANDUP_STASIS_WEBIFICATION_BURST_PROJECTOR  : 47071,
    STANDUP_STASIS_WEBIFICATION_BURST_PROJECTOR_BLUEPRINT  : 47112,
    STANDUP_STASIS_WEBIFIER_I  : 35943,
    STANDUP_STASIS_WEBIFIER_I_BLUEPRINT  : 37080,
    STANDUP_STASIS_WEBIFIER_II  : 47351,
    STANDUP_SUPER_HEAVY_TORPEDO  : 37843,
    STANDUP_SUPER_HEAVY_TORPEDO_BLUEPRINT  : 37852,
    STANDUP_SUPERCAPITAL_SHIPYARD_I  : 35877,
    STANDUP_SUPERCAPITAL_SHIPYARD_I_BLUEPRINT  : 43929,
    STANDUP_TARGET_ILLUMINATION_BURST_PROJECTOR  : 47072,
    STANDUP_TARGET_ILLUMINATION_BURST_PROJECTOR_BLUEPRINT  : 47107,
    STANDUP_TARGET_PAINTER_I  : 35947,
    STANDUP_TARGET_PAINTER_I_BLUEPRINT  : 37081,
    STANDUP_TARGET_PAINTER_II  : 47366,
    STANDUP_TEMPLAR_I  : 47035,
    STANDUP_TEMPLAR_I_BLUEPRINT  : 47208,
    STANDUP_TEMPLAR_II  : 47141,
    STANDUP_TERMITE_I  : 47125,
    STANDUP_TERMITE_I_BLUEPRINT  : 47243,
    STANDUP_TERMITE_II  : 47128,
    STANDUP_TYRFING_I  : 47118,
    STANDUP_TYRFING_I_BLUEPRINT  : 47235,
    STANDUP_TYRFING_II  : 47122,
    STANDUP_VARIABLE_SPECTRUM_ECM_I  : 35940,
    STANDUP_VARIABLE_SPECTRUM_ECM_I_BLUEPRINT  : 37041,
    STANDUP_VARIABLE_SPECTRUM_ECM_II  : 47338,
    STANDUP_WARP_DISRUPTION_BURST_PROJECTOR  : 47073,
    STANDUP_WARP_DISRUPTION_BURST_PROJECTOR_BLUEPRINT  : 47113,
    STANDUP_WEAPON_DISRUPTION_BURST_PROJECTOR  : 47074,
    STANDUP_WEAPON_DISRUPTION_BURST_PROJECTOR_BLUEPRINT  : 47109,
    STANDUP_WEAPON_DISRUPTOR_I  : 35945,
    STANDUP_WEAPON_DISRUPTOR_I_BLUEPRINT  : 37083,
    STANDUP_WEAPON_DISRUPTOR_II  : 47364,
    STANDUP_XL_CRUISE_MISSILE  : 37844,
    STANDUP_XL_CRUISE_MISSILE_BLUEPRINT  : 37853,
    STANDUP_XL_ENERGY_NEUTRALIZER_I  : 35924,
    STANDUP_XL_ENERGY_NEUTRALIZER_I_BLUEPRINT  : 37043,
    STANDUP_XL_ENERGY_NEUTRALIZER_II  : 47330,
    STANDUP_XL_SET_EQUIPMENT_AND_CONSUMABLE_MANUFACTURING_EFFICIENCY_I  : 37178,
    STANDUP_XL_SET_EQUIPMENT_AND_CONSUMABLE_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37310,
    STANDUP_XL_SET_EQUIPMENT_AND_CONSUMABLE_MANUFACTURING_EFFICIENCY_II  : 37179,
    STANDUP_XL_SET_EW_AND_EMISSIONS_CO_ORDINATOR_I  : 37272,
    STANDUP_XL_SET_EW_AND_EMISSIONS_CO_ORDINATOR_I_BLUEPRINT  : 37435,
    STANDUP_XL_SET_EW_AND_EMISSIONS_CO_ORDINATOR_II  : 37273,
    STANDUP_XL_SET_EXTINCTION_LEVEL_WEAPONS_SUITE_I  : 37274,
    STANDUP_XL_SET_EXTINCTION_LEVEL_WEAPONS_SUITE_I_BLUEPRINT  : 37437,
    STANDUP_XL_SET_EXTINCTION_LEVEL_WEAPONS_SUITE_II  : 37275,
    STANDUP_XL_SET_INTEGRATED_FIGHTER_AND_PD_NETWORK_I_BLUEPRINT  : 37433,
    STANDUP_XL_SET_LABORATORY_OPTIMIZATION_I  : 37183,
    STANDUP_XL_SET_LABORATORY_OPTIMIZATION_I_BLUEPRINT  : 37346,
    STANDUP_XL_SET_LABORATORY_OPTIMIZATION_II  : 37182,
    STANDUP_XL_SET_MISSILE_FIRE_CONTROL_COMPUTER_I  : 37268,
    STANDUP_XL_SET_MISSILE_FIRE_CONTROL_COMPUTER_I_BLUEPRINT  : 37431,
    STANDUP_XL_SET_MISSILE_FIRE_CONTROL_COMPUTER_II  : 37269,
    STANDUP_XL_SET_REPROCESSING_MONITOR_I  : 46641,
    STANDUP_XL_SET_REPROCESSING_MONITOR_I_BLUEPRINT  : 46651,
    STANDUP_XL_SET_REPROCESSING_MONITOR_II  : 46642,
    STANDUP_XL_SET_SHIP_MANUFACTURING_EFFICIENCY_I  : 37180,
    STANDUP_XL_SET_SHIP_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37343,
    STANDUP_XL_SET_SHIP_MANUFACTURING_EFFICIENCY_II  : 37181,
    STANDUP_XL_SET_STRUCTURE_AND_COMPONENT_MANUFACTURING_EFFICIENCY_I  : 43704,
    STANDUP_XL_SET_STRUCTURE_AND_COMPONENT_MANUFACTURING_EFFICIENCY_I_BLUEPRINT  : 37376,
    STANDUP_XL_SET_STRUCTURE_AND_COMPONENT_MANUFACTURING_EFFICIENCY_II  : 43705,
    STANDUP_XL_SET_THUKKER_STRUCTURE_AND_COMPONENT_MANUFACTURING_EFFICIENCY  : 45548,
    STARBASE_DEFENSE_MANAGEMENT  : 3373,
    STARGAZER_LANTERN_FIREWORK  : 49991,
    STARSI  : 37888,
    STASIS_WEBIFICATION_BATTERY  : 17178,
    STASIS_WEBIFICATION_BATTERY_BLUEPRINT  : 2741,
    STASIS_WEBIFICATION_BURST_PROJECTOR  : 40635,
    STASIS_WEBIFICATION_BURST_PROJECTOR_BLUEPRINT  : 41393,
    STASIS_WEBIFICATION_PROBE  : 56748,
    STASIS_WEBIFICATION_PROBE_BLUEPRINT  : 56749,
    STASIS_WEBIFIER_I  : 526,
    STASIS_WEBIFIER_I_BLUEPRINT  : 1102,
    STASIS_WEBIFIER_II  : 527,
    STATION_CONTAINER  : 17366,
    STATION_CONTAINER_BLUEPRINT  : 32858,
    STATION_VAULT_CONTAINER  : 17367,
    STATION_VAULT_CONTAINER_BLUEPRINT  : 32871,
    STATION_WAREHOUSE_CONTAINER  : 17368,
    STATION_WAREHOUSE_CONTAINER_BLUEPRINT  : 27309,
    STEEL_YARI_KINETIC_DISRUPTIVE_LANCE  : 77400,
    STEEL_YARI_KINETIC_DISRUPTIVE_LANCE_BLUEPRINT  : 77404,
    STELLAR_TRANSMUTER_DATACORE  : 76203,
    STELLAR_TRANSMUTER_RESEARCH_DATA_FRAGMENT  : 76208,
    STELLAR_TRANSMUTER_RESEARCH_ENCRYPTION_KEY  : 76209,
    STELLAR_TRANSMUTER_RESEARCH_INDEX_FILE  : 76207,
    STERILE_CONDUITS  : 2875,
    STILETTO  : 11198,
    STILETTO_BIOSECURITY_RESPONDERS_SKIN  : 55692,
    STILETTO_BLUE_TIGER_SKIN  : 36810,
    STILETTO_BLUEPRINT  : 11199,
    STILETTO_DAWN_OF_LIBERATION_SKIN  : 48497,
    STILETTO_EXOPLANETS_HUNTER_SKIN  : 45945,
    STILETTO_GALNET_STREAMCAST_UNIT_SKIN  : 76489,
    STILETTO_GLACIAL_DRIFT_SKIN  : 46886,
    STILETTO_HAZARD_CONTROL_SKIN  : 56894,
    STILETTO_HUNTERS_QUIVER_SKIN  : 57533,
    STILETTO_JUSTICE_SKIN  : 36426,
    STILETTO_LUMINAIRE_ZENITH_SKIN  : 64402,
    STILETTO_SNOWLINE_BLADERACER_SKIN  : 52856,
    STILETTO_TRONHADAR_INK_SKIN  : 40479,
    STILETTO_VALKLEAR_GLORY_SKIN  : 36763,
    STOIC_CORE_EQUALIZER_I  : 16301,
    STORK  : 37482,
    STORK_BIOSECURITY_RESPONDERS_SKIN  : 55565,
    STORK_BLUE_TIGER_SKIN  : 40322,
    STORK_CALDARI_UNION_DAY_SKIN  : 48728,
    STORK_EXOPLANETS_HUNTER_SKIN  : 45830,
    STORK_GHOSTBIRD_SKIN  : 48173,
    STORK_GLACIAL_DRIFT_SKIN  : 46838,
    STORK_GURISTAS_SKIN  : 54557,
    STORK_KIMOTORO_ESKEITAN_COMMANDER_SKIN  : 64096,
    STORK_MATIGU_SEABEAST_SKIN  : 40595,
    STORK_STEEL_CARDINAL_SKIN  : 46107,
    STORK_UNION_DAY_EXECUTIVE_SKIN  : 71722,
    STORK_ZENTO_ISIDEKO_COMBINE_SKIN  : 53167,
    STORM_COMMAND_CENTER  : 2550,
    STORMBRINGER  : 54732,
    STORMBRINGER_AURORA_UNIVERSALIS_SKIN  : 60926,
    STORMBRINGER_BLUESTAR_DEFENDERS_SKIN  : 55985,
    STORMBRINGER_EDENCOM_VICTORY_SKIN  : 57707,
    STRANGE_MATTER_COMPONENT_X_13  : 62033,
    STRANGE_MATTER_COMPONENT_X_17  : 62036,
    STRANGE_MATTER_COMPONENT_X_31  : 62039,
    STRANGE_MATTER_COMPONENT_X_37  : 62042,
    STRANGE_MATTER_COMPONENT_X_71  : 62045,
    STRANGE_MATTER_COMPONENT_Y_107  : 62043,
    STRANGE_MATTER_COMPONENT_Y_73  : 62034,
    STRANGE_MATTER_COMPONENT_Y_79  : 62037,
    STRANGE_MATTER_COMPONENT_Y_97  : 62040,
    STRANGE_MATTER_COMPONENT_Z_113  : 62035,
    STRANGE_MATTER_COMPONENT_Z_149  : 62038,
    STRANGE_MATTER_COMPONENT_Z_157  : 62041,
    STRANGE_MATTER_COMPONENT_Z_167  : 62044,
    STRATIOS  : 33470,
    STRATIOS_AIR_LABORATORIES_SKIN  : 60157,
    STRATIOS_ASPIRANT_SOLDIER_SKIN  : 60242,
    STRATIOS_AURORA_UNIVERSALIS_SKIN  : 60923,
    STRATIOS_CRYSTAL_BLAST_SKIN  : 47250,
    STRATIOS_FRIENDSHIP_LEAGUE_SKIN  : 60098,
    STRATIOS_GALNET_STREAMCAST_UNIT_SKIN  : 76495,
    STRATIOS_GLACIAL_DRIFT_SKIN  : 47014,
    STRATIOS_ONE_EDEN_SKIN  : 47376,
    STRATIOS_REDCLAW_SABLE_SKIN  : 56958,
    STRATIOS_SANCTUARY_SKIN  : 35695,
    STRATIOS_SCOPE_SYNDICATION_YC122_SKIN  : 61186,
    STRATIOS_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60226,
    STRATIOS_SOE_BLUE_CELL  : 45019,
    STRATIOS_SOE_FIRE_CELL  : 44218,
    STRATIOS_SOE_GOLD_CELL  : 45484,
    STRATIOS_SPECTRAL_SHIFT_SKIN  : 46521,
    STRATIOS_YOIUL_FESTIVAL_YC121_SKIN  : 49962,
    STRATIOS_YOIUL_STAR_SKIN  : 53437,
    STRESS_RESPONDING_NEUROLINK_STABILIZER  : 57468,
    STRESS_RESPONDING_NEUROLINK_STABILIZER_REACTION_FORMULA  : 57505,
    STRIKESNIPE_ULTRA_L  : 54782,
    STRIKESNIPE_ULTRA_M  : 54785,
    STRIKESNIPE_ULTRA_S  : 54781,
    STRING_CHARGE_EVALUATION_COUPLE  : 54810,
    STRIP_MINER_I  : 17482,
    STRIP_MINER_I_BLUEPRINT  : 17483,
    STRONG_BLUE_PILL_BOOSTER  : 10156,
    STRONG_BLUE_PILL_BOOSTER_REACTION_FORMULA  : 46235,
    STRONG_CRASH_BOOSTER  : 10152,
    STRONG_CRASH_BOOSTER_REACTION_FORMULA  : 46244,
    STRONG_DROP_BOOSTER  : 15478,
    STRONG_DROP_BOOSTER_REACTION_FORMULA  : 46245,
    STRONG_EXILE_BOOSTER  : 25349,
    STRONG_EXILE_BOOSTER_REACTION_FORMULA  : 46246,
    STRONG_FRENTIX_BOOSTER  : 15462,
    STRONG_FRENTIX_BOOSTER_REACTION_FORMULA  : 46248,
    STRONG_MINDFLOOD_BOOSTER  : 15465,
    STRONG_MINDFLOOD_BOOSTER_REACTION_FORMULA  : 46247,
    STRONG_SOOTH_SAYER_BOOSTER  : 10166,
    STRONG_SOOTH_SAYER_BOOSTER_REACTION_FORMULA  : 46250,
    STRONG_VEILGUARD_BOOSTER  : 59633,
    STRONG_X_INSTINCT_BOOSTER  : 15459,
    STRONG_X_INSTINCT_BOOSTER_REACTION_FORMULA  : 46249,
    STRONTIUM_CLATHRATES  : 16275,
    STRUCTURE_ACCELERATION_COILS  : 36957,
    STRUCTURE_ACCELERATION_COILS_BLUEPRINT  : 36954,
    STRUCTURE_ADVERTISEMENT_NEXUS  : 36958,
    STRUCTURE_ADVERTISEMENT_NEXUS_BLUEPRINT  : 36955,
    STRUCTURE_CONSTRUCTION_PARTS  : 21947,
    STRUCTURE_CONSTRUCTION_PARTS_BLUEPRINT  : 21948,
    STRUCTURE_DOCKING_BAY  : 21961,
    STRUCTURE_DOCKING_BAY_BLUEPRINT  : 21962,
    STRUCTURE_DOOMSDAY_OPERATION  : 37797,
    STRUCTURE_ELECTROMAGNETIC_SENSOR  : 36956,
    STRUCTURE_ELECTROMAGNETIC_SENSOR_BLUEPRINT  : 36953,
    STRUCTURE_ELECTRONIC_SYSTEMS  : 37798,
    STRUCTURE_ENGINEERING_SYSTEMS  : 37799,
    STRUCTURE_EXERT_CONDUIT_COUPLER  : 49720,
    STRUCTURE_EXERT_CONDUIT_COUPLER_BLUEPRINT  : 49721,
    STRUCTURE_FACTORY  : 21955,
    STRUCTURE_FACTORY_BLUEPRINT  : 21956,
    STRUCTURE_HANGAR_ARRAY  : 21949,
    STRUCTURE_HANGAR_ARRAY_BLUEPRINT  : 21950,
    STRUCTURE_LABORATORY  : 21953,
    STRUCTURE_LABORATORY_BLUEPRINT  : 21954,
    STRUCTURE_MARKET_NETWORK  : 21963,
    STRUCTURE_MARKET_NETWORK_BLUEPRINT  : 21964,
    STRUCTURE_MEDICAL_CENTER  : 21965,
    STRUCTURE_MEDICAL_CENTER_BLUEPRINT  : 21966,
    STRUCTURE_MISSILE_SYSTEMS  : 37796,
    STRUCTURE_MISSION_NETWORK  : 21969,
    STRUCTURE_MISSION_NETWORK_BLUEPRINT  : 21970,
    STRUCTURE_OFFICE_CENTER  : 21967,
    STRUCTURE_OFFICE_CENTER_BLUEPRINT  : 21968,
    STRUCTURE_REPAIR_FACILITY  : 21957,
    STRUCTURE_REPAIR_FACILITY_BLUEPRINT  : 21958,
    STRUCTURE_REPROCESSING_PLANT  : 21959,
    STRUCTURE_REPROCESSING_PLANT_BLUEPRINT  : 21960,
    STRUCTURE_STORAGE_BAY  : 21951,
    STRUCTURE_STORAGE_BAY_BLUEPRINT  : 21952,
    SUBCOMPACT_TEBS_INTERFACE  : 40701,
    SUBCRANIAL_NANOCONTROLLERS  : 53476,
    SUBSPACE_ENERGY__YOU  : 45061,
    SUBSYSTEM_ASSEMBLY_ARRAY  : 30389,
    SUBVERTED_JVN_UC49  : 48744,
    SUBVERTED_JVN_UC49_BLUEPRINT  : 48745,
    SUBVERTED_JVN_UC49_BLUEPRINT_CRATE  : 48928,
    SUCCUBUS  : 17924,
    SUCCUBUS_GHOSTS_HEX_SKIN  : 57807,
    SUCCUBUS_GLACIAL_DRIFT_SKIN  : 47006,
    SUCCUBUS_LUMINAE_POLARIS_SKIN  : 75265,
    SUCCUBUS_TRUE_DELIVERANCE_SKIN  : 52595,
    SULFURIC_ACID  : 16661,
    SULFURIC_ACID_REACTION_FORMULA  : 46181,
    SUNESIS  : 42685,
    SUNESIS_CAPSULEER_DAY_XVI_SKIN  : 52279,
    SUNESIS_CAPSULEER_ELITE_XV_SKIN  : 47648,
    SUNESIS_CAPSULEER_NETWORKER_XV_SKIN  : 47653,
    SUNESIS_GLACIAL_DRIFT_SKIN  : 47012,
    SUNESIS_ICECLOUD_INVESTIGATORS_SKIN  : 49797,
    SUNESIS_IRONBLOOD_SKIN  : 43531,
    SUNESIS_MIDNIGHT_SKIN  : 44259,
    SUNESIS_SILVER_RACER_SKIN  : 47551,
    SUNESIS_TERCIO_ROJO_SKIN  : 74116,
    SUNESIS_VERSUS_BLUEFORCE_SKIN  : 47548,
    SUNESIS_VERSUS_REDFORCE_SKIN  : 47545,
    SUNESIS_VIOLET_VELLICATION_SKIN  : 52420,
    SUPERCAPITAL_CONSTRUCTION_FACILITIES  : 2009,
    SUPERCAPITAL_CONSTRUCTION_FACILITIES_BLUEPRINT  : 34868,
    SUPERCAPITAL_SHIP_ASSEMBLY_ARRAY  : 24575,
    SUPERCOMPUTERS  : 2349,
    SUPERCONDUCTING_GRAVIMETRIC_AMPLIFIER  : 45653,
    SUPERCONDUCTING_GRAVIMETRIC_AMPLIFIER_BLUEPRINT  : 45657,
    SUPERCONDUCTING_LADAR_AMPLIFIER  : 45655,
    SUPERCONDUCTING_LADAR_AMPLIFIER_BLUEPRINT  : 45659,
    SUPERCONDUCTING_MAGNETOMETRIC_AMPLIFIER  : 45652,
    SUPERCONDUCTING_MAGNETOMETRIC_AMPLIFIER_BLUEPRINT  : 45656,
    SUPERCONDUCTING_RADAR_AMPLIFIER  : 45654,
    SUPERCONDUCTING_RADAR_AMPLIFIER_BLUEPRINT  : 45658,
    SUPERCONDUCTOR_RAILS  : 11690,
    SUPERCONDUCTOR_RAILS_BLUEPRINT  : 17354,
    SUPERCONDUCTORS  : 9838,
    SUPERIOR_EXOPLANETS_HUNTER_REWARD_CRATE  : 46050,
    SUPERIOR_FLOW_CYTOMETRY_REWARDS_CRATE  : 55359,
    SUPERTENSILE_PLASTICS  : 2312,
    SUPERWEAPON_AOEECM  : 41350,
    SUPERWEAPON_AOEECM_BLUEPRINT  : 41395,
    SUPERWEAPON_AOEGUIDE  : 40700,
    SUPERWEAPON_AOEGUIDE_BLUEPRINT  : 41389,
    SUPPLY_CHAIN_MANAGEMENT  : 24268,
    SUPPORT_FIGHTERS  : 40573,
    SUPPRESSED_TARGETING_SYSTEM_I  : 5869,
    SUPRATIDAL_COMPACT_ENTROPIC_DISINTEGRATOR  : 47920,
    SUPRATIDAL_COMPACT_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 48473,
    SUPRATIDAL_ENTROPIC_DISINTEGRATOR_I  : 47274,
    SUPRATIDAL_ENTROPIC_DISINTEGRATOR_I_BLUEPRINT  : 47973,
    SUPRATIDAL_ENTROPIC_DISINTEGRATOR_II  : 47922,
    SUPRATIDAL_SCOPED_ENTROPIC_DISINTEGRATOR  : 47921,
    SUPRATIDAL_SCOPED_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 48474,
    SURGICAL_STRIKE  : 3315,
    SURGICAL_WARP_DISRUPT_PROBE  : 34260,
    SURVEY  : 3551,
    SURVEY_NETWORKS_1  : 2053,
    SURVEY_NETWORKS_1_BLUEPRINT  : 34860,
    SURVEY_NETWORKS_2  : 2054,
    SURVEY_NETWORKS_2_BLUEPRINT  : 34861,
    SURVEY_NETWORKS_3  : 2055,
    SURVEY_NETWORKS_3_BLUEPRINT  : 34862,
    SURVEY_NETWORKS_4  : 2056,
    SURVEY_NETWORKS_4_BLUEPRINT  : 34863,
    SURVEY_NETWORKS_5  : 2057,
    SURVEY_NETWORKS_5_BLUEPRINT  : 34864,
    SURVEY_PROBE_LAUNCHER_I  : 33270,
    SURVEY_PROBE_LAUNCHER_I_BLUEPRINT  : 33271,
    SURVEY_PROBE_LAUNCHER_II  : 33272,
    SURVEY_SCANNER_I  : 444,
    SURVEY_SCANNER_I_BLUEPRINT  : 1076,
    SURVEY_SCANNER_II  : 2333,
    SUSPENDED_PLASMA  : 2308,
    SUSTAINED_SHIELD_EMITTER  : 11558,
    SUSTAINED_SHIELD_EMITTER_BLUEPRINT  : 17352,
    SVIPUL  : 34562,
    SVIPUL_BIOSECURITY_RESPONDERS_SKIN  : 55647,
    SVIPUL_CHAINBREAKER_KHUMAAK_SKIN  : 77960,
    SVIPUL_EXOPLANETS_HUNTER_SKIN  : 45953,
    SVIPUL_FIREWALL_BREACH_SKIN  : 48528,
    SVIPUL_FREEDOMS_REVOLUTION_SKIN  : 59313,
    SVIPUL_GLACIAL_DRIFT_SKIN  : 46892,
    SVIPUL_HAZARD_CONTROL_SKIN  : 56931,
    SVIPUL_HEARTSURGE_SKIN  : 53961,
    SVIPUL_IN_RUST_WE_TRUST_SKIN  : 52426,
    SVIPUL_MALAGRIN_MARSHLIGHT_SKIN  : 60569,
    SVIPUL_SNOWLINE_BLADERACER_SKIN  : 52864,
    SWINDLER_ELECTRON_BLASTER_CANNON_I  : 22911,
    SYLRAMIC_FIBERS  : 16678,
    SYLRAMIC_FIBERS_REACTION_FORMULA  : 46208,
    SYLVITE  : 45491,
    SYMMETRY_DECRYPTOR  : 34206,
    SYNDICATE_100MM_STEEL_PLATES  : 28778,
    SYNDICATE_1600MM_STEEL_PLATES  : 28780,
    SYNDICATE_200MM_STEEL_PLATES  : 28782,
    SYNDICATE_400MM_STEEL_PLATES  : 28784,
    SYNDICATE_800MM_STEEL_PLATES  : 28786,
    SYNDICATE_CLOAKING_DEVICE  : 32260,
    SYNDICATE_DAMAGE_CONTROL  : 41201,
    SYNDICATE_GAS_CLOUD_SCOOP  : 28788,
    SYNDICATE_MOBILE_LARGE_WARP_DISRUPTOR  : 28770,
    SYNDICATE_MOBILE_MEDIUM_WARP_DISRUPTOR  : 28772,
    SYNDICATE_MOBILE_SMALL_WARP_DISRUPTOR  : 28774,
    SYNDICATE_REACTOR_CONTROL_UNIT  : 28776,
    SYNDICATE_REINFORCED_BULKHEADS  : 34487,
    SYNDICATE_SIGNAL_AMPLIFIER  : 41197,
    SYNTH_BLUE_PILL_BOOSTER  : 28670,
    SYNTH_BLUE_PILL_BOOSTER_REACTION_FORMULA  : 46233,
    SYNTH_CRASH_BOOSTER  : 28672,
    SYNTH_CRASH_BOOSTER_REACTION_FORMULA  : 46234,
    SYNTH_DROP_BOOSTER  : 28674,
    SYNTH_DROP_BOOSTER_REACTION_FORMULA  : 46219,
    SYNTH_EXILE_BOOSTER  : 28676,
    SYNTH_EXILE_BOOSTER_REACTION_FORMULA  : 46220,
    SYNTH_FRENTIX_BOOSTER  : 28678,
    SYNTH_FRENTIX_BOOSTER_REACTION_FORMULA  : 46221,
    SYNTH_MINDFLOOD_BOOSTER  : 28680,
    SYNTH_MINDFLOOD_BOOSTER_REACTION_FORMULA  : 46228,
    SYNTH_SOOTH_SAYER_BOOSTER  : 28684,
    SYNTH_SOOTH_SAYER_BOOSTER_REACTION_FORMULA  : 46229,
    SYNTH_X_INSTINCT_BOOSTER  : 28682,
    SYNTH_X_INSTINCT_BOOSTER_REACTION_FORMULA  : 46232,
    SYNTHETIC_APERTURE_LADAR_RECEIVER  : 45663,
    SYNTHETIC_COFFEE  : 11944,
    SYNTHETIC_HULL_CONVERSION_INERTIAL_STABILIZERS  : 21498,
    SYNTHETIC_HULL_CONVERSION_NANOFIBER_STRUCTURE  : 21500,
    SYNTHETIC_HULL_CONVERSION_OVERDRIVE_INJECTOR  : 21491,
    SYNTHETIC_HULL_CONVERSION_REINFORCED_BULKHEADS  : 21496,
    SYNTHETIC_OIL  : 3691,
    SYNTHETIC_SYNAPSES  : 2346,
    SYSTEM_SCANNING_ARRAY  : 27675,
    TA3_COMPACT_SHIP_SCANNER  : 6527,
    TACHYON_AFOCAL_MASER_I  : 7169,
    TACHYON_ANODE_PARTICLE_STREAM_I  : 7173,
    TACHYON_BEAM_LASER_I  : 464,
    TACHYON_BEAM_LASER_I_BLUEPRINT  : 848,
    TACHYON_BEAM_LASER_II  : 3065,
    TACHYON_MODAL_LASER_I  : 7167,
    TACHYON_MODULATED_ENERGY_BEAM_I  : 7171,
    TACTICAL_CAPSULEER_RECLONER  : 56735,
    TACTICAL_CAPSULEER_RECLONER_BLUEPRINT  : 56734,
    TACTICAL_LOGISTICS_RECONFIGURATION  : 27906,
    TACTICAL_SHIELD_MANIPULATION  : 3420,
    TACTICAL_WEAPON_RECONFIGURATION  : 22043,
    TAHRONS_CUSTOM_HEAT_SINK  : 44111,
    TAIPAN  : 33081,
    TAIPAN_HOSTILE_TAKEOVER_SKIN  : 45505,
    TAIREI_NAMAZOTHS_TAG  : 17302,
    TAIREIS_MODIFIED_CAP_RECHARGER  : 21816,
    TAIREIS_MODIFIED_CAPACITOR_POWER_RELAY  : 15082,
    TAIREIS_MODIFIED_DUAL_HEAVY_PULSE_LASER  : 14425,
    TAIREIS_MODIFIED_EM_ARMOR_HARDENER  : 15018,
    TAIREIS_MODIFIED_EM_COATING  : 14906,
    TAIREIS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14970,
    TAIREIS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15066,
    TAIREIS_MODIFIED_EXPLOSIVE_COATING  : 14890,
    TAIREIS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14986,
    TAIREIS_MODIFIED_HEAT_SINK  : 14802,
    TAIREIS_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15130,
    TAIREIS_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14834,
    TAIREIS_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14818,
    TAIREIS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15050,
    TAIREIS_MODIFIED_KINETIC_COATING  : 14874,
    TAIREIS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15002,
    TAIREIS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14849,
    TAIREIS_MODIFIED_LARGE_EMP_SMARTBOMB  : 14786,
    TAIREIS_MODIFIED_MEGA_PULSE_LASER  : 14441,
    TAIREIS_MODIFIED_MULTISPECTRUM_COATING  : 14858,
    TAIREIS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14938,
    TAIREIS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15098,
    TAIREIS_MODIFIED_REACTOR_CONTROL_UNIT  : 15114,
    TAIREIS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15034,
    TAIREIS_MODIFIED_THERMAL_COATING  : 14922,
    TAIREIS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14954,
    TAKMAHL_ASTRAL_TREATMENT  : 23177,
    TAKMAHL_BINARY_TEXTS  : 23173,
    TAKMAHL_BIODROID_CONTROLLER  : 23136,
    TAKMAHL_CENTRIFUGAL_PRIMER  : 23175,
    TAKMAHL_COHERE_CORD  : 23144,
    TAKMAHL_DIAMOND_ROD  : 23143,
    TAKMAHL_DYNAMIC_GAUGE  : 23134,
    TAKMAHL_FRACTAL_SHEET  : 23174,
    TAKMAHL_GEOMETRIC_DESIGN  : 23176,
    TAKMAHL_GYRO_BALLAST  : 23135,
    TAKMAHL_MAGNETIC_SLAB  : 23146,
    TAKMAHL_PHRENIC_APPENDIX  : 23133,
    TAKMAHL_QUANTUM_SPHERE  : 23137,
    TAKMAHL_SOLID_MOX  : 23145,
    TAKMAHL_TECHNOLOGY  : 23123,
    TAKMAHL_TRI_POLISHED_LENS  : 23147,
    TALASSONITE  : 52306,
    TALASSONITE_PROCESSING  : 56632,
    TALOCAN_AUTOMATION_ACCOUNTS  : 21085,
    TALOCAN_IGNITION_DEVICE  : 21089,
    TALOCAN_INFO_SHARDS  : 21077,
    TALOCAN_INTRICATE_FORMULAS  : 21087,
    TALOCAN_MATHEMATICAL_SCHEMATICS  : 21084,
    TALOCAN_MECHANICAL_GEARS  : 21081,
    TALOCAN_MOLECULE_BINDER  : 21075,
    TALOCAN_PARTITION_PLATES  : 21086,
    TALOCAN_PERPETUAL_CLOCK  : 21079,
    TALOCAN_REFLECTIVE_SHEETS  : 21078,
    TALOCAN_SKETCH_BOOKS  : 21074,
    TALOCAN_SOLID_ATOMIZER  : 21080,
    TALOCAN_STASIS_DEFLECTOR  : 21088,
    TALOCAN_STASIS_INVERTER  : 21076,
    TALOCAN_SYSTEM_INTERFACE_UNIT  : 21082,
    TALOCAN_TECHNOLOGY  : 20433,
    TALOS  : 4308,
    TALOS_AMARANTHINE_MANTLE_SKIN  : 50031,
    TALOS_AURORA_UNIVERSALIS_SKIN  : 57021,
    TALOS_BIOSECURITY_RESPONDERS_SKIN  : 55583,
    TALOS_BLUEPRINT  : 4309,
    TALOS_CAPSULEER_ELITE_XIX_SKIN  : 63689,
    TALOS_DUVOLLE_SKIN  : 34616,
    TALOS_EXOPLANETS_HUNTER_SKIN  : 45907,
    TALOS_FEDERATION_NAVY_SKIN  : 58891,
    TALOS_GLACIAL_DRIFT_SKIN  : 46953,
    TALOS_GLITTERING_DREAM_SKIN  : 47533,
    TALOS_HEADHUNTER_SKIN  : 46719,
    TALOS_INNER_ZONE_VANGUARD_SKIN  : 52742,
    TALOS_INTAKI_SYNDICATE_SKIN  : 36376,
    TALOS_INTERBUS_SKIN  : 34617,
    TALOS_KOPIS_EDGE_SKIN  : 46419,
    TALOS_LUMINAIRE_ZENITH_SKIN  : 48229,
    TALOS_SERPENTIS_SKIN  : 42179,
    TALOS_SPIRIT_SKIN  : 44925,
    TALOS_VALIMOR_LEGACY_SKIN  : 37575,
    TALOS_YOIUL_FESTIVAL_YC121_SKIN  : 49956,
    TALWAR  : 32878,
    TALWAR_ABYSSAL_FIRESTORM_SKIN  : 53144,
    TALWAR_BIOSECURITY_RESPONDERS_SKIN  : 55667,
    TALWAR_BLUE_TIGER_SKIN  : 36811,
    TALWAR_BLUEPRINT  : 32879,
    TALWAR_DEATHGLOW_REMNANT_SKIN  : 73372,
    TALWAR_EXOPLANETS_HUNTER_SKIN  : 45949,
    TALWAR_FLEET_ORDNANCE_SPIKE_SKIN  : 59431,
    TALWAR_GLACIAL_DRIFT_SKIN  : 44168,
    TALWAR_HAZARD_CONTROL_SKIN  : 56927,
    TALWAR_LIBERATION_GAMES_SKIN  : 59465,
    TALWAR_REPUBLIC_FLEET_SKIN  : 58867,
    TALWAR_SEBIESTOR_SKIN  : 34714,
    TALWAR_SEMIOTIQUE_SPORTS_SKIN  : 60210,
    TALWAR_SNOWLINE_BLADERACER_SKIN  : 52860,
    TALWAR_TRIGLAVIAN_TWILIGHT_SKIN  : 52406,
    TALWAR_TRONHADAR_INK_SKIN  : 40480,
    TALWAR_VALKLEAR_GLORY_SKIN  : 36764,
    TANGLED_POWER_CONDUIT  : 25594,
    TARANIS  : 11200,
    TARANIS_AURORA_UNIVERSALIS_SKIN  : 57008,
    TARANIS_BIOSECURITY_RESPONDERS_SKIN  : 55613,
    TARANIS_BLUEPRINT  : 11201,
    TARANIS_EXOPLANETS_HUNTER_SKIN  : 45883,
    TARANIS_FEDERATION_NAVY_SKIN  : 58886,
    TARANIS_GLACIAL_DRIFT_SKIN  : 46935,
    TARANIS_INNER_ZONE_VANGUARD_SKIN  : 52718,
    TARANIS_INTAKI_SYNDICATE_SKIN  : 36379,
    TARANIS_KOPIS_EDGE_SKIN  : 46395,
    TARANIS_SERPENTIS_SKIN  : 42159,
    TARANIS_SPIRIT_SKIN  : 44901,
    TARANIS_VALIMOR_LEGACY_SKIN  : 37559,
    TARGET_ILLUMINATION_BURST_PROJECTOR  : 40698,
    TARGET_ILLUMINATION_BURST_PROJECTOR_BLUEPRINT  : 41391,
    TARGET_MANAGEMENT  : 3429,
    TARGET_NAVIGATION_PREDICTION  : 20314,
    TARGET_PAINTER_I  : 12709,
    TARGET_PAINTER_I_BLUEPRINT  : 12710,
    TARGET_PAINTER_II  : 19806,
    TARGET_PAINTING  : 19921,
    TARGETING_RANGE_DAMPENING_SCRIPT  : 29015,
    TARGETING_RANGE_DAMPENING_SCRIPT_BLUEPRINT  : 29016,
    TARGETING_RANGE_SCRIPT  : 29009,
    TARGETING_RANGE_SCRIPT_BLUEPRINT  : 29010,
    TAROT_OF_ST_JAMYL  : 49645,
    TATARA  : 35836,
    TATARA_BLUEPRINT  : 36978,
    TATARA_UPWELL_QUANTUM_CORE  : 56205,
    TAYRA  : 649,
    TAYRA_BIOSECURITY_RESPONDERS_SKIN  : 55516,
    TAYRA_BLUE_TIGER_SKIN  : 36746,
    TAYRA_BLUEPRINT  : 984,
    TAYRA_EXOPLANETS_HUNTER_SKIN  : 45864,
    TAYRA_FORWARD_4_4_SKIN  : 56866,
    TAYRA_GHOSTBIRD_SKIN  : 48207,
    TAYRA_GLACIAL_DRIFT_SKIN  : 46868,
    TAYRA_KIMOTORO_ESKEITAN_SKIN  : 52638,
    TAYRA_LODESTRIKE_SKIN  : 46753,
    TAYRA_MATIGU_SEABEAST_SKIN  : 40622,
    TAYRA_MILITARY_STOCKPILE_CAPTAIN_SKIN  : 70837,
    TAYRA_RAATA_SUNSET_SKIN  : 36674,
    TAYRA_STEEL_CARDINAL_SKIN  : 46141,
    TAYRA_UNION_DAY_EXECUTIVE_SKIN  : 71729,
    TAYRA_WIYRKOMI_SKIN  : 34754,
    TAYRA_ZENTO_ISIDEKO_COMBINE_SKIN  : 53201,
    TE_2100_AMPLE_LIGHT_MISSILE_LAUNCHER  : 8091,
    TE_2100_AMPLE_RAPID_TORPEDO_LAUNCHER  : 41224,
    TE_2100_AMPLE_RAPID_TORPEDO_LAUNCHER_BLUEPRINT  : 41227,
    TE_2100_AMPLE_XL_CRUISE_MISSILE_LAUNCHER  : 41176,
    TE_2100_AMPLE_XL_CRUISE_MISSILE_LAUNCHER_BLUEPRINT  : 41178,
    TE_2100_AMPLE_XL_TORPEDO_LAUNCHER  : 41181,
    TE_2100_AMPLE_XL_TORPEDO_LAUNCHER_BLUEPRINT  : 41184,
    TECHNETIUM  : 16649,
    TELEMETRY_PROCESSOR  : 25607,
    TEMPERATE_COMMAND_CENTER  : 2254,
    TEMPEST  : 639,
    TEMPEST_AMARANTHINE_MANTLE_SKIN  : 57341,
    TEMPEST_AURORA_UNIVERSALIS_SKIN  : 53360,
    TEMPEST_BIOSECURITY_RESPONDERS_SKIN  : 55655,
    TEMPEST_BLOODY_HANDS_SKIN  : 47173,
    TEMPEST_BLUE_TIGER_SKIN  : 36830,
    TEMPEST_BLUEPRINT  : 693,
    TEMPEST_CAPSULEER_DAY_XV_SKIN  : 47638,
    TEMPEST_CHAINBREAKER_KHUMAAK_SKIN  : 64596,
    TEMPEST_DAWN_OF_LIBERATION_SKIN  : 48515,
    TEMPEST_EXOPLANETS_HUNTER_SKIN  : 45974,
    TEMPEST_FLEET_ISSUE  : 17732,
    TEMPEST_FLEET_ISSUE_ACE_OF_PODHUNTERS_SKIN  : 63211,
    TEMPEST_FLEET_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55674,
    TEMPEST_FLEET_ISSUE_BLOODY_HANDS_SKIN  : 47174,
    TEMPEST_FLEET_ISSUE_BRUTOR_SKIN  : 59874,
    TEMPEST_FLEET_ISSUE_CHAINBREAKER_KHUMAAK_SKIN  : 64597,
    TEMPEST_FLEET_ISSUE_DAWN_OF_LIBERATION_SKIN  : 48519,
    TEMPEST_FLEET_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45978,
    TEMPEST_FLEET_ISSUE_GLACIAL_DRIFT_SKIN  : 46915,
    TEMPEST_FLEET_ISSUE_KRUSUAL_SKIN  : 59834,
    TEMPEST_FLEET_ISSUE_LIBERATION_GAMES_SKIN  : 64911,
    TEMPEST_FLEET_ISSUE_NEFANTAR_SKIN  : 59826,
    TEMPEST_FLEET_ISSUE_SEBIESTOR_SKIN  : 59841,
    TEMPEST_FLEET_ISSUE_SNOWLINE_BLADERACER_SKIN  : 52889,
    TEMPEST_FLEET_ISSUE_SPIRIT_SKIN  : 57396,
    TEMPEST_FLEET_ISSUE_STARKMANIR_SKIN  : 59866,
    TEMPEST_FLEET_ISSUE_THUKKER_TRIBE_SKIN  : 59850,
    TEMPEST_FLEET_ISSUE_VHEROKIOR_SKIN  : 59858,
    TEMPEST_FLEET_ORDNANCE_SPIKE_SKIN  : 59247,
    TEMPEST_GLACIAL_DRIFT_SKIN  : 46911,
    TEMPEST_HAZARD_CONTROL_SKIN  : 56932,
    TEMPEST_KRUSUAL_SKIN  : 34808,
    TEMPEST_LIBERATION_GAMES_SKIN  : 59468,
    TEMPEST_NEFANTAR_SKIN  : 34809,
    TEMPEST_REPUBLIC_FLEET_SKIN  : 59816,
    TEMPEST_SCOPE_SYNDICATION_YC122_SKIN  : 56889,
    TEMPEST_SNOWLINE_BLADERACER_SKIN  : 52885,
    TEMPEST_SPIRIT_SKIN  : 47319,
    TEMPEST_TRIBAL_ISSUE  : 26842,
    TEMPEST_TRONHADAR_INK_SKIN  : 40501,
    TEMPEST_VALKLEAR_GLORY_SKIN  : 36783,
    TEMPEST_ZAKURA_SHUMYU_SKIN  : 57067,
    TEMPLAR_I  : 23055,
    TEMPLAR_I_BLUEPRINT  : 23056,
    TEMPLAR_II  : 40556,
    TEMPLIS_DRAGONAURS_LOG_1220107_VC47  : 53846,
    TEN_MOST_WANTED_SLAVERS  : 48592,
    TENEBRAET_MUTANITE  : 77421,
    TENEBREX_CYNO_JAMMER  : 37534,
    TENEBREX_CYNO_JAMMER_BLUEPRINT  : 37538,
    TENGU  : 29984,
    TENGU_BIOSECURITY_RESPONDERS_SKIN  : 55513,
    TENGU_CORE___AUGMENTED_GRAVITON_REACTOR  : 45626,
    TENGU_CORE___ELECTRONIC_EFFICIENCY_GATE  : 45625,
    TENGU_CORE___OBFUSCATION_MANIFOLD  : 45627,
    TENGU_DEFENSIVE___AMPLIFICATION_NODE  : 45591,
    TENGU_DEFENSIVE___COVERT_RECONFIGURATION  : 45589,
    TENGU_DEFENSIVE___SUPPLEMENTAL_SCREENING  : 45590,
    TENGU_EMPEROR_BEAR_SKIN  : 52303,
    TENGU_EXOPLANETS_HUNTER_SKIN  : 45843,
    TENGU_GHOSTBIRD_SKIN  : 48186,
    TENGU_GLACIAL_DRIFT_SKIN  : 46847,
    TENGU_NIGHTFIRE_SKIN  : 46024,
    TENGU_OFFENSIVE___ACCELERATED_EJECTION_BAY  : 45601,
    TENGU_OFFENSIVE___MAGNETIC_INFUSION_BASIN  : 45602,
    TENGU_OFFENSIVE___SUPPORT_PROCESSOR  : 45603,
    TENGU_PROPULSION___CHASSIS_OPTIMIZATION  : 45614,
    TENGU_PROPULSION___FUEL_CATALYST  : 45615,
    TENGU_PROPULSION___INTERDICTION_NULLIFIER  : 45613,
    TENGU_STEEL_CARDINAL_SKIN  : 46120,
    TENGU_STORM_BEAR_SKIN  : 48816,
    TENGU_SUKUUVESTAA_SKIN  : 45997,
    TENGU_ULTRA_JUNGLE  : 48544,
    TENGU_ULTRA_JUNGLE2  : 48543,
    TENGU_ZENTO_ISIDEKO_COMBINE_SKIN  : 53180,
    TERACHI_TASH_MURKONS_INSIGNIA  : 15638,
    TERAHERTZ_METAMATERIALS  : 33360,
    TERAHERTZ_METAMATERIALS_REACTION_FORMULA  : 46216,
    TERMITE_I  : 40363,
    TERMITE_I_BLUEPRINT  : 41361,
    TERMITE_II  : 40566,
    TERRITORIAL_CLAIM_UNIT  : 32226,
    TERRITORIAL_CLAIM_UNIT_BLUEPRINT  : 2737,
    TESSERACT_CAPACITOR_UNIT  : 11554,
    TESSERACT_CAPACITOR_UNIT_BLUEPRINT  : 17334,
    TEST_CULTURES  : 2319,
    TEST_DUMMIES  : 12302,
    TEST_ITEM  : 41496,
    TEST_SITE_ARMOR_REPAIR  : 52265,
    TEST_SITE_MALLER  : 52267,
    TESTARMORMOD_BLUEPRINT_PLACEHOLDER  : 46013,
    TESTARMORMOD_PLACEHOLDER  : 46012,
    TESTSHIELDMOD_BLUEPRINT_PLACEHOLDER  : 46011,
    TESTSHIELDMOD_PLACEHOLDER  : 46010,
    TETRYON_EXOTIC_PLASMA_L  : 47887,
    TETRYON_EXOTIC_PLASMA_M  : 47886,
    TETRYON_EXOTIC_PLASMA_S  : 47885,
    TETRYON_EXOTIC_PLASMA_XL  : 53301,
    THALIA  : 37459,
    THALIA_BIOSECURITY_RESPONDERS_SKIN  : 55629,
    THALIA_EMERGENCY_RESPONSE_SQUAD_SKIN  : 60195,
    THALIA_EXOPLANETS_HUNTER_SKIN  : 45884,
    THALIA_GLACIAL_DRIFT_SKIN  : 46936,
    THALIA_INNER_ZONE_VANGUARD_SKIN  : 52719,
    THALIA_KOPIS_EDGE_SKIN  : 46396,
    THALIA_SERPENTIS_SKIN  : 42160,
    THALIA_SPIRIT_SKIN  : 44902,
    THALIA_VALIMOR_LEGACY_SKIN  : 40323,
    THANATOS  : 23911,
    THANATOS_BIOSECURITY_RESPONDERS_SKIN  : 55591,
    THANATOS_BLUEPRINT  : 23912,
    THANATOS_CAILLE_NEON_SKIN  : 50150,
    THANATOS_DOS_ROUVENORS_BEQUEST_SKIN  : 59312,
    THANATOS_EROS_BLOSSOM_SKIN  : 47290,
    THANATOS_EXOPLANETS_HUNTER_SKIN  : 45919,
    THANATOS_FEDERAL_POLICE_SKIN  : 54903,
    THANATOS_GLACIAL_DRIFT_SKIN  : 46965,
    THANATOS_GLITTERING_DREAM_SKIN  : 47538,
    THANATOS_GREEN_DEMONS_SKIN  : 59337,
    THANATOS_HEADHUNTER_SKIN  : 49427,
    THANATOS_HUNTERS_QUIVER_SKIN  : 47504,
    THANATOS_INNER_ZONE_VANGUARD_SKIN  : 52754,
    THANATOS_INTAKI_SYNDICATE_SKIN  : 36401,
    THANATOS_KOPIS_EDGE_SKIN  : 46431,
    THANATOS_LUMINAIRE_RISING_SKIN  : 64572,
    THANATOS_MALAGRIN_MARSHLIGHT_SKIN  : 56598,
    THANATOS_QUAFE_SKIN  : 34653,
    THANATOS_RODEN_SKIN  : 34654,
    THANATOS_RUBY_SUNGRAZER_SKIN  : 45573,
    THANATOS_SAPPHIRE_SUNGRAZER_SKIN  : 46561,
    THANATOS_SERPENTIS_SKIN  : 42188,
    THANATOS_SPIRIT_SKIN  : 44937,
    THANATOS_VALIMOR_LEGACY_SKIN  : 37584,
    THE_CHAMPION_SHIP  : 44213,
    THE_DAMSELS_DRUNK_BODYGUARD  : 34433,
    THE_FRIEND_SHIP  : 34436,
    THE_GALACTIC_PARTY_PLANNING_GUIDE  : 34418,
    THE_MINI_MONOLITH  : 33015,
    THE_POLICY_ENFORCER  : 48753,
    THE_YULAI_SIEGE_YC106  : 52273,
    THERMAL_ARMOR_COMPENSATION  : 22809,
    THERMAL_ARMOR_HARDENER_I  : 11277,
    THERMAL_ARMOR_HARDENER_I_BLUEPRINT  : 11278,
    THERMAL_ARMOR_HARDENER_II  : 11648,
    THERMAL_COATING_I  : 1294,
    THERMAL_COATING_I_BLUEPRINT  : 1295,
    THERMAL_COATING_II  : 1296,
    THERMAL_ENERGIZED_MEMBRANE_I  : 11257,
    THERMAL_ENERGIZED_MEMBRANE_I_BLUEPRINT  : 11258,
    THERMAL_ENERGIZED_MEMBRANE_II  : 11259,
    THERMAL_SHIELD_AMPLIFIER_I  : 2537,
    THERMAL_SHIELD_AMPLIFIER_I_BLUEPRINT  : 2538,
    THERMAL_SHIELD_AMPLIFIER_II  : 2539,
    THERMAL_SHIELD_COMPENSATION  : 11566,
    THERMAL_SHIELD_HARDENER_I  : 2295,
    THERMAL_SHIELD_HARDENER_I_BLUEPRINT  : 2296,
    THERMAL_SHIELD_HARDENER_II  : 2303,
    THERMODYNAMICS  : 28164,
    THERMOELECTRIC_CATALYSTS  : 30252,
    THERMONUCLEAR_TRIGGER_UNIT  : 11691,
    THERMONUCLEAR_TRIGGER_UNIT_BLUEPRINT  : 17322,
    THERMOSETTING_POLYMER  : 57455,
    THERMOSETTING_POLYMER_REACTION_FORMULA  : 57494,
    THICK_BLUE_ICE  : 17975,
    THON_ENEYS_TAG  : 17297,
    THONS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14686,
    THONS_MODIFIED_CLOAKING_DEVICE  : 14778,
    THONS_MODIFIED_CO_PROCESSOR  : 14770,
    THONS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14674,
    THONS_MODIFIED_EM_SHIELD_AMPLIFIER  : 14742,
    THONS_MODIFIED_EM_SHIELD_HARDENER  : 14753,
    THONS_MODIFIED_EXPLOSIVE_SHIELD_AMPLIFIER  : 14718,
    THONS_MODIFIED_EXPLOSIVE_SHIELD_HARDENER  : 14757,
    THONS_MODIFIED_KINETIC_SHIELD_AMPLIFIER  : 14734,
    THONS_MODIFIED_KINETIC_SHIELD_HARDENER  : 14749,
    THONS_MODIFIED_LARGE_GRAVITON_SMARTBOMB  : 14694,
    THONS_MODIFIED_LARGE_SHIELD_BOOSTER  : 14701,
    THONS_MODIFIED_MULTISPECTRAL_ECM  : 20203,
    THONS_MODIFIED_MULTISPECTRUM_SHIELD_HARDENER  : 14765,
    THONS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33462,
    THONS_MODIFIED_SHIELD_BOOST_AMPLIFIER  : 14710,
    THONS_MODIFIED_THERMAL_SHIELD_AMPLIFIER  : 14726,
    THONS_MODIFIED_THERMAL_SHIELD_HARDENER  : 14761,
    THONS_MODIFIED_TORPEDO_LAUNCHER  : 14681,
    THONS_MODIFIED_X_LARGE_SHIELD_BOOSTER  : 14705,
    THORAX  : 627,
    THORAX_ABYSSAL_FIRESTORM_SKIN  : 53137,
    THORAX_ALIASTRA_SKIN  : 34692,
    THORAX_ASPIRANT_ENFORCER_SKIN  : 60237,
    THORAX_AURORA_UNIVERSALIS_SKIN  : 57020,
    THORAX_BIOSECURITY_RESPONDERS_SKIN  : 55573,
    THORAX_BLUEPRINT  : 972,
    THORAX_EXOPLANETS_HUNTER_SKIN  : 45894,
    THORAX_FEDERAL_POLICE_SKIN  : 52435,
    THORAX_FEDERATION_NAVY_SKIN  : 58888,
    THORAX_GLACIAL_DRIFT_SKIN  : 44185,
    THORAX_GLITTERING_DREAM_SKIN  : 47529,
    THORAX_INNER_ZONE_SHIPPING_SKIN  : 34693,
    THORAX_INNER_ZONE_VANGUARD_SKIN  : 52729,
    THORAX_INTAKI_SYNDICATE_SKIN  : 36369,
    THORAX_KOPIS_EDGE_SKIN  : 46406,
    THORAX_LUMINAIRE_ZENITH_SKIN  : 48228,
    THORAX_PERMABAND_ROADIES_SKIN  : 54446,
    THORAX_QUAFE_SKIN  : 37057,
    THORAX_RUBY_SUNGRAZER_SKIN  : 45565,
    THORAX_SAPPHIRE_SUNGRAZER_SKIN  : 46562,
    THORAX_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60221,
    THORAX_SERPENTIS_SKIN  : 42168,
    THORAX_SPIRIT_SKIN  : 44912,
    THORAX_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59238,
    THORAX_TRIGLAVIAN_TWILIGHT_SKIN  : 52395,
    THORAX_VALIMOR_LEGACY_SKIN  : 37565,
    THORAX_VERSUS_REDFORCE_SKIN  : 63243,
    THORIUM_CHARGE_L  : 235,
    THORIUM_CHARGE_L_BLUEPRINT  : 1150,
    THORIUM_CHARGE_M  : 227,
    THORIUM_CHARGE_M_BLUEPRINT  : 1142,
    THORIUM_CHARGE_S  : 219,
    THORIUM_CHARGE_S_BLUEPRINT  : 1134,
    THORIUM_CHARGE_XL  : 17658,
    THORIUM_CHARGE_XL_BLUEPRINT  : 17659,
    THRASHER  : 16242,
    THRASHER_ACE_OF_PODHUNTERS_SKIN  : 63209,
    THRASHER_AURORA_UNIVERSALIS_SKIN  : 53362,
    THRASHER_BIOSECURITY_RESPONDERS_SKIN  : 55648,
    THRASHER_BLUE_TIGER_SKIN  : 36812,
    THRASHER_BLUEPRINT  : 16243,
    THRASHER_EXOPLANETS_HUNTER_SKIN  : 45950,
    THRASHER_FIREWALL_BREACH_SKIN  : 48527,
    THRASHER_FLEET_ISSUE  : 73794,
    THRASHER_FLEET_ISSUE_HEARTSURGE_SKIN  : 76284,
    THRASHER_FLEET_ORDNANCE_SPIKE_SKIN  : 59241,
    THRASHER_GLACIAL_DRIFT_SKIN  : 44167,
    THRASHER_HARMONIOUS_ASCENSION_SKIN  : 53310,
    THRASHER_HAZARD_CONTROL_SKIN  : 56926,
    THRASHER_LUMINAIRE_ZENITH_SKIN  : 48231,
    THRASHER_METALBEE_MINMATAR_SKIN_PERMANENT  : 53535,
    THRASHER_NEFANTAR_SKIN  : 34715,
    THRASHER_REPUBLIC_FLEET_SKIN  : 59810,
    THRASHER_SEMIOTIQUE_SUPERLUMINAL_SKIN  : 60223,
    THRASHER_SNOWLINE_BLADERACER_SKIN  : 52861,
    THRASHER_THUKKER_TRIBE_SKIN_30_DAYS  : 35586,
    THRASHER_TRONHADAR_INK_SKIN  : 40481,
    THRASHER_VALKLEAR_GLORY_SKIN  : 36765,
    THRASHER_ZAKURA_SHUMYU_SKIN  : 57065,
    THRUSTER_CONSOLE  : 25602,
    THUKKER_CAPITAL_COMPONENT_MANUFACTURING_TECHNICAL_DATA_CHIP  : 45585,
    THUKKER_COMPONENT_ASSEMBLY_ARRAY  : 33867,
    THUKKER_LARGE_CAP_BATTERY  : 41220,
    THUKKER_LARGE_SHIELD_EXTENDER  : 28744,
    THUKKER_MEDIUM_CAP_BATTERY  : 41217,
    THUKKER_MEDIUM_SHIELD_EXTENDER  : 28746,
    THUKKER_MICRO_AUXILIARY_POWER_CORE  : 28740,
    THUKKER_MIX_STRONG_BOX  : 43693,
    THUKKER_POWER_DIAGNOSTIC_SYSTEM  : 28739,
    THUKKER_SMALL_CAP_BATTERY  : 41214,
    THUKKER_SMALL_SHIELD_EXTENDER  : 28742,
    THULIUM  : 16653,
    THULIUM_HAFNITE  : 33336,
    THULIUM_HAFNITE_REACTION_FORMULA  : 46185,
    THUNDERCHILD  : 54733,
    THUNDERCHILD_BLUESTAR_DEFENDERS_SKIN  : 55986,
    THUNDERCHILD_EDENCOM_VICTORY_SKIN  : 57708,
    THURIFER_LARGE_CAP_BATTERY  : 23805,
    TIAMAT  : 48635,
    TIME_CRYSTAL_OSCILLATOR  : 62032,
    TITANITE  : 45496,
    TITANIUM  : 16638,
    TITANIUM_CARBIDE  : 16671,
    TITANIUM_CARBIDE_REACTION_FORMULA  : 46204,
    TITANIUM_CHROMIDE  : 16654,
    TITANIUM_CHROMIDE_REACTION_FORMULA  : 46182,
    TITANIUM_DIBORITE_ARMOR_PLATE  : 11544,
    TITANIUM_DIBORITE_ARMOR_PLATE_BLUEPRINT  : 17328,
    TITANIUM_PLATED_CRANIAL_SHIELDING  : 34424,
    TITANIUM_SABOT_L  : 198,
    TITANIUM_SABOT_L_BLUEPRINT  : 899,
    TITANIUM_SABOT_M  : 190,
    TITANIUM_SABOT_M_BLUEPRINT  : 891,
    TITANIUM_SABOT_S  : 182,
    TITANIUM_SABOT_S_BLUEPRINT  : 883,
    TITANIUM_SABOT_XL  : 17678,
    TITANIUM_SABOT_XL_BLUEPRINT  : 17679,
    TITANOMACHY_YC116  : 52276,
    TLF_RECRUITMENT_POSTER  : 48584,
    TO_KILL_THE_RISEN_DEAD  : 47059,
    TOBACCO  : 9852,
    TOBIAS_KRUZHORS_TAG  : 17303,
    TOBIAS_MODIFIED_100MN_AFTERBURNER  : 14490,
    TOBIAS_MODIFIED_1200MM_ARTILLERY_CANNON  : 14463,
    TOBIAS_MODIFIED_1400MM_HOWITZER_ARTILLERY  : 14467,
    TOBIAS_MODIFIED_500MN_MICROWARPDRIVE  : 14498,
    TOBIAS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14534,
    TOBIAS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14522,
    TOBIAS_MODIFIED_EM_SHIELD_AMPLIFIER  : 14634,
    TOBIAS_MODIFIED_EXPLOSIVE_SHIELD_AMPLIFIER  : 14610,
    TOBIAS_MODIFIED_GYROSTABILIZER  : 14542,
    TOBIAS_MODIFIED_HEAVY_WARP_DISRUPTOR  : 14662,
    TOBIAS_MODIFIED_HEAVY_WARP_SCRAMBLER  : 14670,
    TOBIAS_MODIFIED_KINETIC_SHIELD_AMPLIFIER  : 14626,
    TOBIAS_MODIFIED_LARGE_PROTON_SMARTBOMB  : 14550,
    TOBIAS_MODIFIED_LARGE_SHIELD_BOOSTER  : 14599,
    TOBIAS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33463,
    TOBIAS_MODIFIED_SHIELD_BOOST_AMPLIFIER  : 14638,
    TOBIAS_MODIFIED_STASIS_WEBIFIER  : 14654,
    TOBIAS_MODIFIED_THERMAL_SHIELD_AMPLIFIER  : 14618,
    TOBIAS_MODIFIED_TORPEDO_LAUNCHER  : 14527,
    TOBIAS_MODIFIED_TRACKING_ENHANCER  : 14646,
    TOBIAS_MODIFIED_X_LARGE_SHIELD_BOOSTER  : 14603,
    TOOL_SWIPER_DISPLAY  : 56974,
    TOP_GRADE_DEATHGLOW  : 49621,
    TORELLES_CUSTOM_MAGNETIC_FIELD_STABILIZER  : 44114,
    TORMENTOR  : 591,
    TORMENTOR_ARDISHAPUR_SKIN  : 34730,
    TORMENTOR_BIOSECURITY_RESPONDERS_SKIN  : 55305,
    TORMENTOR_BLOOD_RAIDERS_SKIN  : 56796,
    TORMENTOR_BLUEPRINT  : 938,
    TORMENTOR_COLD_IRON_SKIN  : 42751,
    TORMENTOR_DEATHGLOW_REMNANT_SKIN  : 60658,
    TORMENTOR_EXOPLANETS_HUNTER_SKIN  : 45758,
    TORMENTOR_GLACIAL_DRIFT_SKIN  : 44139,
    TORMENTOR_IMPERIAL_NAVY_SKIN  : 58895,
    TORMENTOR_IRONBLOOD_SKIN  : 43487,
    TORMENTOR_OASIS_OF_FAITH_SKIN  : 53600,
    TORMENTOR_ORDER_OF_TETRIMON_SKIN  : 73458,
    TORMENTOR_PURITY_OF_THE_THRONE_SKIN  : 42549,
    TORMENTOR_RAATA_SUNSET_SKIN  : 37489,
    TORMENTOR_SARUM_SKIN  : 34731,
    TORMENTOR_ZAKURA_HANSEI_SKIN  : 57109,
    TORNADO  : 4310,
    TORNADO_AMARANTHINE_MANTLE_SKIN  : 50033,
    TORNADO_ARGYROS_ELITE_XVI_SKIN  : 52284,
    TORNADO_AURORA_UNIVERSALIS_SKIN  : 57024,
    TORNADO_BIOSECURITY_RESPONDERS_SKIN  : 55659,
    TORNADO_BLOODY_HANDS_SKIN  : 47170,
    TORNADO_BLUE_TIGER_SKIN  : 36826,
    TORNADO_BLUEPRINT  : 4311,
    TORNADO_CAPSULEER_DAY_XV_SKIN  : 47637,
    TORNADO_CAPSULEER_ELITE_XIX_SKIN  : 63692,
    TORNADO_CHAINBREAKER_KHUMAAK_SKIN  : 64593,
    TORNADO_EXOPLANETS_HUNTER_SKIN  : 45969,
    TORNADO_GLACIAL_DRIFT_SKIN  : 46906,
    TORNADO_HAZARD_CONTROL_SKIN  : 56925,
    TORNADO_MALAGRIN_MARSHLIGHT_SKIN  : 53016,
    TORNADO_NEFANTAR_SKIN  : 34621,
    TORNADO_REPUBLIC_FLEET_SKIN  : 58871,
    TORNADO_SNOWLINE_BLADERACER_SKIN  : 52880,
    TORNADO_TRONHADAR_INK_SKIN  : 40497,
    TORNADO_VALKLEAR_GLORY_SKIN  : 36779,
    TORPEDO_BATTERY  : 16697,
    TORPEDO_BATTERY_BLUEPRINT  : 2824,
    TORPEDO_LAUNCHER_I  : 503,
    TORPEDO_LAUNCHER_I_BLUEPRINT  : 790,
    TORPEDO_LAUNCHER_II  : 2420,
    TORPEDO_SPECIALIZATION  : 20213,
    TORPEDOES  : 3325,
    TOURISTS  : 3719,
    TOXIC_METALS  : 2400,
    TOXIC_WASTE  : 3729,
    TRACKING_COMPUTER_I  : 1977,
    TRACKING_COMPUTER_I_BLUEPRINT  : 11621,
    TRACKING_COMPUTER_II  : 1978,
    TRACKING_DISRUPTOR_I  : 2108,
    TRACKING_DISRUPTOR_I_BLUEPRINT  : 11614,
    TRACKING_DISRUPTOR_II  : 2109,
    TRACKING_ENHANCER_I  : 1998,
    TRACKING_ENHANCER_I_BLUEPRINT  : 11616,
    TRACKING_ENHANCER_II  : 1999,
    TRACKING_SPEED_DISRUPTION_SCRIPT  : 29007,
    TRACKING_SPEED_DISRUPTION_SCRIPT_BLUEPRINT  : 29008,
    TRACKING_SPEED_SCRIPT  : 29001,
    TRACKING_SPEED_SCRIPT_BLUEPRINT  : 29002,
    TRADE  : 3443,
    TRAJECTORY_ANALYSIS  : 3317,
    TRANQUIL_ABYSSAL_FILAMENTS_CRATE  : 56365,
    TRANQUIL_DARK_FILAMENT  : 56132,
    TRANQUIL_ELECTRICAL_FILAMENT  : 56131,
    TRANQUIL_EXOTIC_FILAMENT  : 56133,
    TRANQUIL_FIRESTORM_FILAMENT  : 56134,
    TRANQUIL_GAMMA_FILAMENT  : 56136,
    TRANSCRANIAL_MICROCONTROLLERS  : 12836,
    TRANSMITTER  : 9840,
    TRANSPORT_RELAY_DATACORE  : 76204,
    TRANSPORT_RELAY_RESEARCH_DATA_FRAGMENT  : 76211,
    TRANSPORT_RELAY_RESEARCH_ENCRYPTION_KEY  : 76212,
    TRANSPORT_RELAY_RESEARCH_INDEX_FILE  : 76210,
    TRANSPORT_SHIPS  : 19719,
    TREBUCHET_HEAT_SINK_I  : 23902,
    TREMOR_L  : 12765,
    TREMOR_M  : 12771,
    TREMOR_S  : 12633,
    TREMOR_XL  : 41318,
    TRIAGE_MODULE_I  : 27951,
    TRIAGE_MODULE_I_BLUEPRINT  : 27952,
    TRIAGE_MODULE_II  : 4294,
    TRICLINIC_BISTOT  : 17428,
    TRIGGER_UNIT  : 25612,
    TRIGLAVIAN_ENCRYPTION_METHODS  : 52308,
    TRIGLAVIAN_HOLOGRAPHIC_TRANSCRIBER  : 55832,
    TRIGLAVIAN_QUANTUM_ENGINEERING  : 52307,
    TRIGLAVIAN_SUBLIGHT_TELEMETER  : 55830,
    TRIGLAVIAN_SURVEY_DATABASE  : 48121,
    TRIGLAVIAN_TRANSCONDUIT_DATACASTER  : 55831,
    TRIGLAVIAN_TRINARY_DATASTREAM_AEA1  : 48764,
    TRIGLAVIAN_TRINARY_DATASTREAM_AEA2  : 48765,
    TRIGLAVIAN_TRINARY_DATASTREAM_AEA3  : 48777,
    TRIGLAVIAN_TRINARY_DATASTREAM_AEA4  : 52190,
    TRIGLAVIAN_TRINARY_DATASTREAM_AEA5  : 52195,
    TRIGLAVIAN_TRINARY_DATASTREAM_AEA6  : 52408,
    TRIGLAVIAN_TRINARY_DATASTREAM_AFN1  : 48778,
    TRIGLAVIAN_TRINARY_DATASTREAM_AFN2  : 48774,
    TRIGLAVIAN_TRINARY_DATASTREAM_AFN3  : 48775,
    TRIGLAVIAN_TRINARY_DATASTREAM_AFN4  : 52191,
    TRIGLAVIAN_TRINARY_DATASTREAM_AFN5  : 52196,
    TRIGLAVIAN_TRINARY_DATASTREAM_AFN6  : 52409,
    TRIGLAVIAN_TRINARY_DATASTREAM_DAV1  : 48767,
    TRIGLAVIAN_TRINARY_DATASTREAM_DAV2  : 48768,
    TRIGLAVIAN_TRINARY_DATASTREAM_DAV3  : 48769,
    TRIGLAVIAN_TRINARY_DATASTREAM_DAV4  : 52192,
    TRIGLAVIAN_TRINARY_DATASTREAM_DAV5  : 52197,
    TRIGLAVIAN_TRINARY_DATASTREAM_DAV6  : 52410,
    TRIGLAVIAN_TRINARY_DATASTREAM_HFN1  : 48770,
    TRIGLAVIAN_TRINARY_DATASTREAM_HFN2  : 48771,
    TRIGLAVIAN_TRINARY_DATASTREAM_HFN3  : 48772,
    TRIGLAVIAN_TRINARY_DATASTREAM_HFN4  : 52411,
    TRIGLAVIAN_TRINARY_DATASTREAM_HFN5  : 52412,
    TRIGLAVIAN_TRINARY_DATASTREAM_HFN6  : 52413,
    TRIGLAVIAN_TRINARY_DATASTREAM_XCZ1  : 54804,
    TRIGLAVIAN_TRINARY_DATASTREAM_XCZ2  : 54805,
    TRIGLAVIAN_TRINARY_DATASTREAM_XCZ3  : 54806,
    TRINARY_STATE_PROCESSOR  : 52313,
    TRIPLE_NEUTRON_BLASTER_CANNON_I  : 37291,
    TRIPLE_NEUTRON_BLASTER_CANNON_I_BLUEPRINT  : 41085,
    TRIPLE_NEUTRON_BLASTER_CANNON_II  : 37300,
    TRIPPED_POWER_CIRCUIT  : 25598,
    TRISTAN  : 593,
    TRISTAN_AURORA_UNIVERSALIS_SKIN  : 60915,
    TRISTAN_BIOSECURITY_RESPONDERS_SKIN  : 55586,
    TRISTAN_BLUEPRINT  : 940,
    TRISTAN_CHROMODYNAMIC_CANDY_SKIN  : 49799,
    TRISTAN_EXOPLANETS_HUNTER_SKIN  : 45876,
    TRISTAN_FEDERATION_NAVY_SKIN  : 58885,
    TRISTAN_GLACIAL_DRIFT_SKIN  : 44179,
    TRISTAN_HEADHUNTER_SKIN  : 46717,
    TRISTAN_INNER_ZONE_SHIPPING_SKIN  : 34745,
    TRISTAN_INNER_ZONE_VANGUARD_SKIN  : 52711,
    TRISTAN_INTAKI_SYNDICATE_SKIN  : 36367,
    TRISTAN_KOPIS_EDGE_SKIN  : 46388,
    TRISTAN_LUMINAIRE_ZENITH_SKIN  : 64398,
    TRISTAN_QUAFE_SKIN  : 34746,
    TRISTAN_RUBY_SUNGRAZER_SKIN  : 45560,
    TRISTAN_SAPPHIRE_SUNGRAZER_SKIN  : 46563,
    TRISTAN_SERPENTIS_SKIN  : 42152,
    TRISTAN_SPIRIT_SKIN  : 44894,
    TRISTAN_VALIMOR_LEGACY_SKIN  : 37552,
    TRISTAN_ZAKURA_HANSEI_SKIN  : 57107,
    TRITANIUM  : 34,
    TRUE_CREATIONS_STRONG_BOX  : 43695,
    TRUE_SANSHA_BRASS_TAG  : 17252,
    TRUE_SANSHA_BRONZE_TAG  : 17253,
    TRUE_SANSHA_CAP_RECHARGER  : 14140,
    TRUE_SANSHA_CAPACITOR_POWER_RELAY  : 14146,
    TRUE_SANSHA_CAPITAL_CAPACITOR_BOOSTER  : 41495,
    TRUE_SANSHA_CAPITAL_ENERGY_NEUTRALIZER  : 40663,
    TRUE_SANSHA_CAPITAL_ENERGY_NOSFERATU  : 40669,
    TRUE_SANSHA_CAPITAL_FLEX_SHIELD_HARDENER  : 41529,
    TRUE_SANSHA_CAPITAL_REMOTE_SHIELD_BOOSTER  : 41472,
    TRUE_SANSHA_CAPITAL_SHIELD_BOOSTER  : 41508,
    TRUE_SANSHA_CAPITAL_SHIELD_EXTENDER  : 41460,
    TRUE_SANSHA_CONTROL_TOWER  : 27786,
    TRUE_SANSHA_CONTROL_TOWER_MEDIUM  : 27788,
    TRUE_SANSHA_CONTROL_TOWER_SMALL  : 27790,
    TRUE_SANSHA_COPPER_TAG  : 17254,
    TRUE_SANSHA_CRUISE_MISSILE_LAUNCHER  : 22568,
    TRUE_SANSHA_CRYSTAL_TAG  : 17255,
    TRUE_SANSHA_DIAMOND_TAG  : 17256,
    TRUE_SANSHA_DUAL_GIGA_BEAM_LASER  : 41119,
    TRUE_SANSHA_DUAL_GIGA_PULSE_LASER  : 41104,
    TRUE_SANSHA_DUAL_HEAVY_BEAM_LASER  : 13820,
    TRUE_SANSHA_DUAL_HEAVY_PULSE_LASER  : 13821,
    TRUE_SANSHA_DUAL_LIGHT_BEAM_LASER  : 13822,
    TRUE_SANSHA_DUAL_LIGHT_PULSE_LASER  : 13823,
    TRUE_SANSHA_ELECTRUM_TAG  : 17257,
    TRUE_SANSHA_EM_ARMOR_HARDENER  : 13970,
    TRUE_SANSHA_EM_COATING  : 14019,
    TRUE_SANSHA_EM_ENERGIZED_MEMBRANE  : 14090,
    TRUE_SANSHA_ENERGY_NEUTRALIZING_BATTERY  : 27856,
    TRUE_SANSHA_EXPLOSIVE_ARMOR_HARDENER  : 13974,
    TRUE_SANSHA_EXPLOSIVE_COATING  : 14013,
    TRUE_SANSHA_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14084,
    TRUE_SANSHA_FOCUSED_MEDIUM_BEAM_LASER  : 13824,
    TRUE_SANSHA_FOCUSED_MEDIUM_PULSE_LASER  : 13825,
    TRUE_SANSHA_GATLING_PULSE_LASER  : 13826,
    TRUE_SANSHA_GOLD_TAG  : 17258,
    TRUE_SANSHA_HEAT_SINK  : 13943,
    TRUE_SANSHA_HEAVY_ASSAULT_MISSILE_LAUNCHER  : 28383,
    TRUE_SANSHA_HEAVY_BEAM_LASER  : 13827,
    TRUE_SANSHA_HEAVY_CAPACITOR_BOOSTER  : 14174,
    TRUE_SANSHA_HEAVY_ENERGY_NEUTRALIZER  : 14170,
    TRUE_SANSHA_HEAVY_ENERGY_NOSFERATU  : 14154,
    TRUE_SANSHA_HEAVY_MISSILE_LAUNCHER  : 22567,
    TRUE_SANSHA_HEAVY_PULSE_LASER  : 13828,
    TRUE_SANSHA_KINETIC_ARMOR_HARDENER  : 13978,
    TRUE_SANSHA_KINETIC_COATING  : 14007,
    TRUE_SANSHA_KINETIC_ENERGIZED_MEMBRANE  : 14078,
    TRUE_SANSHA_LARGE_ARMOR_REPAIRER  : 13956,
    TRUE_SANSHA_LARGE_BEAM_LASER_BATTERY  : 27772,
    TRUE_SANSHA_LARGE_EMP_SMARTBOMB  : 14190,
    TRUE_SANSHA_LARGE_PULSE_LASER_BATTERY  : 27773,
    TRUE_SANSHA_LIGHT_MISSILE_LAUNCHER  : 22565,
    TRUE_SANSHA_MEDIUM_ARMOR_REPAIRER  : 13959,
    TRUE_SANSHA_MEDIUM_BEAM_LASER_BATTERY  : 27774,
    TRUE_SANSHA_MEDIUM_CAPACITOR_BOOSTER  : 14178,
    TRUE_SANSHA_MEDIUM_EMP_SMARTBOMB  : 14194,
    TRUE_SANSHA_MEDIUM_ENERGY_NEUTRALIZER  : 14166,
    TRUE_SANSHA_MEDIUM_ENERGY_NOSFERATU  : 14158,
    TRUE_SANSHA_MEDIUM_PULSE_LASER_BATTERY  : 27775,
    TRUE_SANSHA_MEGA_BEAM_LASER  : 13831,
    TRUE_SANSHA_MEGA_PULSE_LASER  : 13832,
    TRUE_SANSHA_MICRO_EMP_SMARTBOMB  : 14198,
    TRUE_SANSHA_MULTISPECTRUM_COATING  : 14001,
    TRUE_SANSHA_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14072,
    TRUE_SANSHA_PALLADIUM_TAG  : 17259,
    TRUE_SANSHA_PLATINUM_TAG  : 17260,
    TRUE_SANSHA_POWER_DIAGNOSTIC_SYSTEM  : 14136,
    TRUE_SANSHA_QUAD_BEAM_LASER  : 13833,
    TRUE_SANSHA_QUAD_MEGA_PULSE_LASER  : 41067,
    TRUE_SANSHA_RAPID_HEAVY_MISSILE_LAUNCHER  : 33464,
    TRUE_SANSHA_RAPID_LIGHT_MISSILE_LAUNCHER  : 22566,
    TRUE_SANSHA_REACTOR_CONTROL_UNIT  : 14130,
    TRUE_SANSHA_ROCKET_LAUNCHER  : 22564,
    TRUE_SANSHA_SILVER_TAG  : 17261,
    TRUE_SANSHA_SMALL_ARMOR_REPAIRER  : 13963,
    TRUE_SANSHA_SMALL_BEAM_LASER_BATTERY  : 27776,
    TRUE_SANSHA_SMALL_CAPACITOR_BOOSTER  : 14186,
    TRUE_SANSHA_SMALL_EMP_SMARTBOMB  : 14202,
    TRUE_SANSHA_SMALL_ENERGY_NEUTRALIZER  : 14162,
    TRUE_SANSHA_SMALL_ENERGY_NOSFERATU  : 14150,
    TRUE_SANSHA_SMALL_FOCUSED_BEAM_LASER  : 13829,
    TRUE_SANSHA_SMALL_FOCUSED_PULSE_LASER  : 13830,
    TRUE_SANSHA_SMALL_PULSE_LASER_BATTERY  : 27777,
    TRUE_SANSHA_STASIS_WEBIFIER  : 14268,
    TRUE_SANSHA_TACHYON_BEAM_LASER  : 13834,
    TRUE_SANSHA_THERMAL_ARMOR_HARDENER  : 13982,
    TRUE_SANSHA_THERMAL_COATING  : 14025,
    TRUE_SANSHA_THERMAL_ENERGIZED_MEMBRANE  : 14096,
    TRUE_SANSHA_TORPEDO_LAUNCHER  : 22569,
    TRUE_SANSHA_WARP_DISRUPTION_FIELD_GENERATOR  : 37612,
    TRUE_SANSHA_WARP_DISRUPTOR  : 14248,
    TRUE_SANSHA_WARP_SCRAMBLER  : 14258,
    TRUE_SANSHAS_GAMMA_L  : 20893,
    TRUE_SANSHAS_GAMMA_M  : 20877,
    TRUE_SANSHAS_GAMMA_S  : 20861,
    TRUE_SANSHAS_GAMMA_XL  : 20909,
    TRUE_SANSHAS_INFRARED_L  : 20885,
    TRUE_SANSHAS_INFRARED_M  : 20869,
    TRUE_SANSHAS_INFRARED_S  : 20853,
    TRUE_SANSHAS_INFRARED_XL  : 20901,
    TRUE_SANSHAS_MICROWAVE_L  : 20883,
    TRUE_SANSHAS_MICROWAVE_M  : 20867,
    TRUE_SANSHAS_MICROWAVE_S  : 20851,
    TRUE_SANSHAS_MICROWAVE_XL  : 20899,
    TRUE_SANSHAS_MULTIFREQUENCY_L  : 20895,
    TRUE_SANSHAS_MULTIFREQUENCY_M  : 20879,
    TRUE_SANSHAS_MULTIFREQUENCY_S  : 20863,
    TRUE_SANSHAS_MULTIFREQUENCY_XL  : 20911,
    TRUE_SANSHAS_RADIO_L  : 20881,
    TRUE_SANSHAS_RADIO_M  : 20865,
    TRUE_SANSHAS_RADIO_S  : 20849,
    TRUE_SANSHAS_RADIO_XL  : 20897,
    TRUE_SANSHAS_STANDARD_L  : 20887,
    TRUE_SANSHAS_STANDARD_M  : 20871,
    TRUE_SANSHAS_STANDARD_S  : 20855,
    TRUE_SANSHAS_STANDARD_XL  : 20903,
    TRUE_SANSHAS_ULTRAVIOLET_L  : 20889,
    TRUE_SANSHAS_ULTRAVIOLET_M  : 20873,
    TRUE_SANSHAS_ULTRAVIOLET_S  : 20857,
    TRUE_SANSHAS_ULTRAVIOLET_XL  : 20905,
    TRUE_SANSHAS_XRAY_L  : 20891,
    TRUE_SANSHAS_XRAY_M  : 20875,
    TRUE_SANSHAS_XRAY_S  : 20859,
    TRUE_SANSHAS_XRAY_XL  : 20907,
    TUNGSTEN  : 16637,
    TUNGSTEN_CARBIDE  : 16672,
    TUNGSTEN_CARBIDE_ARMOR_PLATE  : 11543,
    TUNGSTEN_CARBIDE_ARMOR_PLATE_BLUEPRINT  : 17350,
    TUNGSTEN_CARBIDE_REACTION_FORMULA  : 46207,
    TUNGSTEN_CHARGE_L  : 232,
    TUNGSTEN_CHARGE_L_BLUEPRINT  : 1147,
    TUNGSTEN_CHARGE_M  : 224,
    TUNGSTEN_CHARGE_M_BLUEPRINT  : 1139,
    TUNGSTEN_CHARGE_S  : 216,
    TUNGSTEN_CHARGE_S_BLUEPRINT  : 1131,
    TUNGSTEN_CHARGE_XL  : 17660,
    TUNGSTEN_CHARGE_XL_BLUEPRINT  : 17661,
    TUVAN_ORTHS_TAG  : 17293,
    TUVANS_MODIFIED_100MN_AFTERBURNER  : 14502,
    TUVANS_MODIFIED_500MN_MICROWARPDRIVE  : 14510,
    TUVANS_MODIFIED_CO_PROCESSOR  : 15310,
    TUVANS_MODIFIED_DAMAGE_CONTROL  : 41205,
    TUVANS_MODIFIED_ELECTRON_BLASTER_CANNON  : 14375,
    TUVANS_MODIFIED_EM_ARMOR_HARDENER  : 15246,
    TUVANS_MODIFIED_EM_COATING  : 15182,
    TUVANS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 15222,
    TUVANS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15270,
    TUVANS_MODIFIED_EXPLOSIVE_COATING  : 15190,
    TUVANS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 15230,
    TUVANS_MODIFIED_ION_BLASTER_CANNON  : 14381,
    TUVANS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15262,
    TUVANS_MODIFIED_KINETIC_COATING  : 15198,
    TUVANS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15238,
    TUVANS_MODIFIED_LARGE_ARMOR_REPAIRER  : 15161,
    TUVANS_MODIFIED_LARGE_PLASMA_SMARTBOMB  : 15154,
    TUVANS_MODIFIED_MAGNETIC_FIELD_STABILIZER  : 15146,
    TUVANS_MODIFIED_MULTISPECTRUM_COATING  : 15166,
    TUVANS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 15206,
    TUVANS_MODIFIED_NEUTRON_BLASTER_CANNON  : 14383,
    TUVANS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15294,
    TUVANS_MODIFIED_REACTOR_CONTROL_UNIT  : 15302,
    TUVANS_MODIFIED_SENSOR_BOOSTER  : 15278,
    TUVANS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15254,
    TUVANS_MODIFIED_THERMAL_COATING  : 15174,
    TUVANS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 15214,
    TUVANS_MODIFIED_TRACKING_COMPUTER  : 15286,
    TWINKLING_COBALTITE  : 46289,
    TWINKLING_EUXENITE  : 46291,
    TWINKLING_SCHEELITE  : 46295,
    TWINKLING_TITANITE  : 46293,
    TYCOON  : 18580,
    TYPE_D_RESTRAINED_CAPACITOR_FLUX_COIL  : 8135,
    TYPE_D_RESTRAINED_CAPACITOR_POWER_RELAY  : 8175,
    TYPE_D_RESTRAINED_EXPANDED_CARGO  : 5493,
    TYPE_D_RESTRAINED_INERTIAL_STABILIZERS  : 5533,
    TYPE_D_RESTRAINED_NANOFIBER_STRUCTURE  : 5599,
    TYPE_D_RESTRAINED_OVERDRIVE_INJECTOR  : 5631,
    TYPE_D_RESTRAINED_REINFORCED_BULKHEADS  : 5647,
    TYPE_D_RESTRAINED_SHIELD_FLUX_COIL  : 8295,
    TYPE_D_RESTRAINED_SHIELD_POWER_RELAY  : 8335,
    TYPE_E_ENDURING_CARGO_SCANNER  : 6131,
    TYPHOON  : 644,
    TYPHOON_ABSENT_HEROES_SKIN  : 49157,
    TYPHOON_ABYSSAL_FIRESTORM_SKIN  : 53142,
    TYPHOON_BIOSECURITY_RESPONDERS_SKIN  : 55663,
    TYPHOON_BLOODY_HANDS_SKIN  : 47171,
    TYPHOON_BLUE_TIGER_SKIN  : 36831,
    TYPHOON_BLUEPRINT  : 998,
    TYPHOON_CHAINBREAKER_KHUMAAK_SKIN  : 64594,
    TYPHOON_CRYPTIC_MIMESIS_SKIN  : 48896,
    TYPHOON_DAWN_OF_LIBERATION_SKIN  : 48516,
    TYPHOON_DEATHGLOW_REMNANT_SKIN  : 56783,
    TYPHOON_EXOPLANETS_HUNTER_SKIN  : 45975,
    TYPHOON_FLEET_ISSUE  : 32311,
    TYPHOON_FLEET_ISSUE_ACE_OF_PODHUNTERS_SKIN  : 63212,
    TYPHOON_FLEET_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55675,
    TYPHOON_FLEET_ISSUE_BLOODY_HANDS_SKIN  : 47172,
    TYPHOON_FLEET_ISSUE_CAPSULEER_ELITE_XX_SKIN  : 77169,
    TYPHOON_FLEET_ISSUE_CHAINBREAKER_KHUMAAK_SKIN  : 64595,
    TYPHOON_FLEET_ISSUE_CRYPTIC_MIMESIS_SKIN  : 60451,
    TYPHOON_FLEET_ISSUE_DAWN_OF_LIBERATION_SKIN  : 48520,
    TYPHOON_FLEET_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45979,
    TYPHOON_FLEET_ISSUE_FREEDOMS_REVOLUTION_SKIN  : 55718,
    TYPHOON_FLEET_ISSUE_GLACIAL_DRIFT_SKIN  : 46916,
    TYPHOON_FLEET_ISSUE_HUNTERS_QUIVER_SKIN  : 57777,
    TYPHOON_FLEET_ISSUE_IGC_SKIN  : 48562,
    TYPHOON_FLEET_ISSUE_JUSTICE_SKIN  : 36456,
    TYPHOON_FLEET_ISSUE_SNOWLINE_BLADERACER_SKIN  : 52890,
    TYPHOON_FLEET_ORDNANCE_SPIKE_SKIN  : 59243,
    TYPHOON_FREEDOMS_REVOLUTION_SKIN  : 55714,
    TYPHOON_GLACIAL_DRIFT_SKIN  : 46912,
    TYPHOON_HAZARD_CONTROL_SKIN  : 45559,
    TYPHOON_HEADHUNTER_SKIN  : 46725,
    TYPHOON_HUNTERS_QUIVER_SKIN  : 47505,
    TYPHOON_IGC_VICTORY_SKIN  : 40695,
    TYPHOON_INTERSTELLAR_CONVERGENCE_SKIN  : 62227,
    TYPHOON_KRUSUAL_SKIN  : 34643,
    TYPHOON_MALAGRIN_MARSHLIGHT_SKIN  : 53017,
    TYPHOON_REPUBLIC_FLEET_SKIN  : 58872,
    TYPHOON_SNOWLINE_BLADERACER_SKIN  : 52886,
    TYPHOON_SPIRIT_SKIN  : 57344,
    TYPHOON_TRIGLAVIAN_TWILIGHT_SKIN  : 52404,
    TYPHOON_TRONHADAR_INK_SKIN  : 40502,
    TYPHOON_VALKLEAR_GLORY_SKIN  : 36784,
    TYPHOON_VALKYRIE_SKIN  : 46380,
    TYPHOON_YOIUL_FESTIVAL_YC121_SKIN  : 49959,
    TYPHOON_YOIUL_STAR_SKIN  : 57162,
    TYRFING_I  : 32342,
    TYRFING_I_BLUEPRINT  : 32343,
    TYRFING_II  : 40565,
    U_C_TRIGGER_NEUROLINK_CONDUIT  : 57470,
    U_C_TRIGGER_NEUROLINK_CONDUIT_BLUEPRINT  : 57507,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_A_I  : 46355,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 46350,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_A_II  : 46356,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_B_I  : 61197,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 61217,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_B_II  : 61199,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_C_I  : 61198,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 61218,
    UBIQUITOUS_MOON_MINING_CRYSTAL_TYPE_C_II  : 61200,
    UBIQUITOUS_MOON_ORE_PROCESSING  : 46152,
    UKOMI_SUPERCONDUCTORS  : 17136,
    ULEEN_BLOODSWORNS_TAG  : 17743,
    ULTRA_PROMOTIONAL_HOLOREEL  : 12995,
    ULTRACONDUCTING_ABLATIVE_NANOCABLE  : 54972,
    ULTRADIAN_CYCLING_NEUROLINK_STABILIZER  : 57466,
    ULTRADIAN_CYCLING_NEUROLINK_STABILIZER_REACTION_FORMULA  : 57503,
    ULTRATIDAL_ENTROPIC_DISINTEGRATOR_I  : 52915,
    ULTRAVIOLET_L  : 259,
    ULTRAVIOLET_L_BLUEPRINT  : 1174,
    ULTRAVIOLET_M  : 251,
    ULTRAVIOLET_M_BLUEPRINT  : 1166,
    ULTRAVIOLET_S  : 243,
    ULTRAVIOLET_S_BLUEPRINT  : 1158,
    ULTRAVIOLET_XL  : 17692,
    ULTRAVIOLET_XL_BLUEPRINT  : 17693,
    UMBRA_SCOPED_RADAR_ECM  : 19952,
    UNALIGNED_DEKAISOGEN  : 77707,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_A_I  : 46367,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 46357,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_A_II  : 46368,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_B_I  : 61205,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 61225,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_B_II  : 61207,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_C_I  : 61206,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 61226,
    UNCOMMON_MOON_MINING_CRYSTAL_TYPE_C_II  : 61208,
    UNCOMMON_MOON_ORE_PROCESSING  : 46154,
    UNDERHAND_NEUTRON_BLASTER_CANNON_I  : 22915,
    UNDERTAKER_HEAVY_MISSILE_LAUNCHER  : 20599,
    UNION_OF_EIGHT_PATHS_FIREWORK  : 71755,
    UNIT_D_34343S_MODIFIED_BURST_JAMMER  : 40691,
    UNIT_D_34343S_MODIFIED_DAMAGE_CONTROL  : 41204,
    UNIT_D_34343S_MODIFIED_DRONE_DAMAGE_AMPLIFIER  : 32919,
    UNIT_D_34343S_MODIFIED_DRONE_LINK_AUGMENTOR  : 32927,
    UNIT_D_34343S_MODIFIED_DRONE_NAVIGATION_COMPUTER  : 32943,
    UNIT_D_34343S_MODIFIED_FIGHTER_SUPPORT_UNIT  : 32951,
    UNIT_D_34343S_MODIFIED_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 33834,
    UNIT_D_34343S_MODIFIED_OMNIDIRECTIONAL_TRACKING_LINK  : 32935,
    UNIT_D_34343S_MODIFIED_SENSOR_BOOSTER  : 41192,
    UNIT_F_435454S_MODIFIED_BURST_JAMMER  : 40692,
    UNIT_F_435454S_MODIFIED_DAMAGE_CONTROL  : 41206,
    UNIT_F_435454S_MODIFIED_DRONE_DAMAGE_AMPLIFIER  : 32921,
    UNIT_F_435454S_MODIFIED_DRONE_LINK_AUGMENTOR  : 32929,
    UNIT_F_435454S_MODIFIED_DRONE_NAVIGATION_COMPUTER  : 32945,
    UNIT_F_435454S_MODIFIED_FIGHTER_SUPPORT_UNIT  : 32953,
    UNIT_F_435454S_MODIFIED_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 33836,
    UNIT_F_435454S_MODIFIED_OMNIDIRECTIONAL_TRACKING_LINK  : 32937,
    UNIT_F_435454S_MODIFIED_SENSOR_BOOSTER  : 41193,
    UNIT_OF_LAG  : 33021,
    UNIT_P_343554S_MODIFIED_BURST_JAMMER  : 40693,
    UNIT_P_343554S_MODIFIED_DAMAGE_CONTROL  : 41208,
    UNIT_P_343554S_MODIFIED_DRONE_DAMAGE_AMPLIFIER  : 32923,
    UNIT_P_343554S_MODIFIED_DRONE_LINK_AUGMENTOR  : 32931,
    UNIT_P_343554S_MODIFIED_DRONE_NAVIGATION_COMPUTER  : 32947,
    UNIT_P_343554S_MODIFIED_FIGHTER_SUPPORT_UNIT  : 32955,
    UNIT_P_343554S_MODIFIED_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 33838,
    UNIT_P_343554S_MODIFIED_OMNIDIRECTIONAL_TRACKING_LINK  : 32939,
    UNIT_P_343554S_MODIFIED_SENSOR_BOOSTER  : 41194,
    UNIT_W_634S_MODIFIED_BURST_JAMMER  : 40694,
    UNIT_W_634S_MODIFIED_DAMAGE_CONTROL  : 41211,
    UNIT_W_634S_MODIFIED_DRONE_DAMAGE_AMPLIFIER  : 32925,
    UNIT_W_634S_MODIFIED_DRONE_LINK_AUGMENTOR  : 32933,
    UNIT_W_634S_MODIFIED_DRONE_NAVIGATION_COMPUTER  : 32949,
    UNIT_W_634S_MODIFIED_FIGHTER_SUPPORT_UNIT  : 32957,
    UNIT_W_634S_MODIFIED_OMNIDIRECTIONAL_TRACKING_ENHANCER  : 33840,
    UNIT_W_634S_MODIFIED_OMNIDIRECTIONAL_TRACKING_LINK  : 32941,
    UNIT_W_634S_MODIFIED_SENSOR_BOOSTER  : 41195,
    UNREFINED_CAESARIUM_CADMIDE  : 32824,
    UNREFINED_CAESARIUM_CADMIDE_REACTION_FORMULA  : 46190,
    UNREFINED_CRYSTALLITE_ALLOY  : 32829,
    UNREFINED_CRYSTALLITE_ALLOY_REACTION_FORMULA  : 46195,
    UNREFINED_DYSPORITE  : 29660,
    UNREFINED_DYSPORITE_REACTION_FORMULA  : 46200,
    UNREFINED_FERNITE_ALLOY  : 32828,
    UNREFINED_FERNITE_ALLOY_REACTION_FORMULA  : 46194,
    UNREFINED_FERROFLUID  : 29663,
    UNREFINED_FERROFLUID_REACTION_FORMULA  : 46197,
    UNREFINED_FLUXED_CONDENSATES  : 29659,
    UNREFINED_FLUXED_CONDENSATES_REACTION_FORMULA  : 46201,
    UNREFINED_HEXITE  : 32825,
    UNREFINED_HEXITE_REACTION_FORMULA  : 46191,
    UNREFINED_HYPERFLURITE  : 29664,
    UNREFINED_HYPERFLURITE_REACTION_FORMULA  : 46196,
    UNREFINED_NEO_MERCURITE  : 29661,
    UNREFINED_NEO_MERCURITE_REACTION_FORMULA  : 46199,
    UNREFINED_PLATINUM_TECHNITE  : 32822,
    UNREFINED_PLATINUM_TECHNITE_REACTION_FORMULA  : 46188,
    UNREFINED_PROMETHIUM_MERCURITE  : 33338,
    UNREFINED_PROMETHIUM_MERCURITE_REACTION_FORMULA  : 46203,
    UNREFINED_PROMETIUM  : 29662,
    UNREFINED_PROMETIUM_REACTION_FORMULA  : 46198,
    UNREFINED_ROLLED_TUNGSTEN_ALLOY  : 32826,
    UNREFINED_ROLLED_TUNGSTEN_ALLOY_REACTION_FORMULA  : 46192,
    UNREFINED_SOLERIUM  : 32823,
    UNREFINED_SOLERIUM_REACTION_FORMULA  : 46189,
    UNREFINED_THULIUM_HAFNITE  : 33339,
    UNREFINED_THULIUM_HAFNITE_REACTION_FORMULA  : 46202,
    UNREFINED_TITANIUM_CHROMIDE  : 32827,
    UNREFINED_TITANIUM_CHROMIDE_REACTION_FORMULA  : 46193,
    UNREFINED_VANADIUM_HAFNITE  : 32821,
    UNREFINED_VANADIUM_HAFNITE_REACTION_FORMULA  : 46187,
    UNSTABLE_10000MN_AFTERBURNER_MUTAPLASMID  : 56276,
    UNSTABLE_100MN_AFTERBURNER_MUTAPLASMID  : 47755,
    UNSTABLE_10MN_AFTERBURNER_MUTAPLASMID  : 47751,
    UNSTABLE_1MN_AFTERBURNER_MUTAPLASMID  : 47747,
    UNSTABLE_50000MN_MICROWARPDRIVE_MUTAPLASMID  : 56279,
    UNSTABLE_500MN_MICROWARPDRIVE_MUTAPLASMID  : 47743,
    UNSTABLE_50MN_MICROWARPDRIVE_MUTAPLASMID  : 47297,
    UNSTABLE_5MN_MICROWARPDRIVE_MUTAPLASMID  : 47738,
    UNSTABLE_ASSAULT_DAMAGE_CONTROL_MUTAPLASMID  : 52231,
    UNSTABLE_BALLISTIC_CONTROL_SYSTEM_MUTAPLASMID  : 49741,
    UNSTABLE_CAPITAL_ANCILLARY_ARMOR_REPAIRER_MUTAPLASMID  : 56284,
    UNSTABLE_CAPITAL_ANCILLARY_SHIELD_BOOSTER_MUTAPLASMID  : 56288,
    UNSTABLE_CAPITAL_ARMOR_REPAIRER_MUTAPLASMID  : 56282,
    UNSTABLE_CAPITAL_ENERGY_NEUTRALIZER_MUTAPLASMID  : 56293,
    UNSTABLE_CAPITAL_ENERGY_NOSFERATU_MUTAPLASMID  : 56290,
    UNSTABLE_CAPITAL_SHIELD_BOOSTER_MUTAPLASMID  : 56286,
    UNSTABLE_DAMAGE_CONTROL_MUTAPLASMID  : 52226,
    UNSTABLE_ENTROPIC_RADIATION_SINK_MUTAPLASMID  : 49737,
    UNSTABLE_GYROSTABILIZER_MUTAPLASMID  : 49733,
    UNSTABLE_HEAT_SINK_MUTAPLASMID  : 49729,
    UNSTABLE_HEAVY_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47830,
    UNSTABLE_HEAVY_ENERGY_NOSFERATU_MUTAPLASMID  : 48426,
    UNSTABLE_HEAVY_WARP_DISRUPTOR_MUTAPLASMID  : 56272,
    UNSTABLE_HEAVY_WARP_SCRAMBLER_MUTAPLASMID  : 56270,
    UNSTABLE_LARGE_ANCILLARY_ARMOR_REPAIRER_MUTAPLASMID  : 47845,
    UNSTABLE_LARGE_ANCILLARY_SHIELD_BOOSTER_MUTAPLASMID  : 47837,
    UNSTABLE_LARGE_ARMOR_PLATE_MUTAPLASMID  : 47818,
    UNSTABLE_LARGE_ARMOR_REPAIRER_MUTAPLASMID  : 47775,
    UNSTABLE_LARGE_CAP_BATTERY_MUTAPLASMID  : 48438,
    UNSTABLE_LARGE_SHIELD_BOOSTER_MUTAPLASMID  : 47787,
    UNSTABLE_LARGE_SHIELD_EXTENDER_MUTAPLASMID  : 47806,
    UNSTABLE_MAGNETIC_FIELD_STABILIZER_MUTAPLASMID  : 49725,
    UNSTABLE_MEDIUM_ANCILLARY_ARMOR_REPAIRER_MUTAPLASMID  : 47843,
    UNSTABLE_MEDIUM_ANCILLARY_SHIELD_BOOSTER_MUTAPLASMID  : 47835,
    UNSTABLE_MEDIUM_ARMOR_PLATE_MUTAPLASMID  : 47814,
    UNSTABLE_MEDIUM_ARMOR_REPAIRER_MUTAPLASMID  : 47771,
    UNSTABLE_MEDIUM_CAP_BATTERY_MUTAPLASMID  : 48434,
    UNSTABLE_MEDIUM_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47826,
    UNSTABLE_MEDIUM_ENERGY_NOSFERATU_MUTAPLASMID  : 48422,
    UNSTABLE_MEDIUM_SHIELD_BOOSTER_MUTAPLASMID  : 47783,
    UNSTABLE_MEDIUM_SHIELD_EXTENDER_MUTAPLASMID  : 47802,
    UNSTABLE_SIEGE_MODULE_MUTAPLASMID  : 56300,
    UNSTABLE_SMALL_ANCILLARY_ARMOR_REPAIRER_MUTAPLASMID  : 47841,
    UNSTABLE_SMALL_ARMOR_PLATE_MUTAPLASMID  : 47810,
    UNSTABLE_SMALL_ARMOR_REPAIRER_MUTAPLASMID  : 47767,
    UNSTABLE_SMALL_CAP_BATTERY_MUTAPLASMID  : 48430,
    UNSTABLE_SMALL_ENERGY_NEUTRALIZER_MUTAPLASMID  : 47822,
    UNSTABLE_SMALL_ENERGY_NOSFERATU_MUTAPLASMID  : 48418,
    UNSTABLE_SMALL_SHIELD_BOOSTER_MUTAPLASMID  : 47779,
    UNSTABLE_SMALL_SHIELD_EXTENDER_MUTAPLASMID  : 47798,
    UNSTABLE_STASIS_WEBIFIER_MUTAPLASMID  : 47700,
    UNSTABLE_WARP_DISRUPTOR_MUTAPLASMID  : 47734,
    UNSTABLE_WARP_SCRAMBLER_MUTAPLASMID  : 47730,
    UNSTABLE_X_LARGE_ANCILLARY_SHIELD_BOOSTER_MUTAPLASMID  : 47839,
    UNSTABLE_X_LARGE_SHIELD_BOOSTER_MUTAPLASMID  : 47791,
    UPGRADED_EM_COATING_I  : 16329,
    UPGRADED_EXPLOSIVE_COATING_I  : 16321,
    UPGRADED_KINETIC_COATING_I  : 16313,
    UPGRADED_LAYERED_COATING_I  : 16345,
    UPGRADED_MALKUTH_HEAVY_ASSAULT_MISSILE_LAUNCHER_I  : 25709,
    UPGRADED_MALKUTH_RAPID_LIGHT_MISSILE_LAUNCHER  : 8023,
    UPGRADED_MALKUTH_TORPEDO_LAUNCHER  : 8113,
    UPGRADED_MULTISPECTRUM_COATING_I  : 16305,
    UPGRADED_THERMAL_COATING_I  : 16337,
    UPWELL_CONSORTIUM_PROPAGANDA_REEL  : 44212,
    UPWELL_ENCRYPTION_METHODS  : 55025,
    UPWELL_PALATINE_KEEPSTAR  : 40340,
    URANIUM_CHARGE_L  : 236,
    URANIUM_CHARGE_L_BLUEPRINT  : 1151,
    URANIUM_CHARGE_M  : 228,
    URANIUM_CHARGE_M_BLUEPRINT  : 1143,
    URANIUM_CHARGE_S  : 220,
    URANIUM_CHARGE_S_BLUEPRINT  : 1135,
    URANIUM_CHARGE_XL  : 17662,
    URANIUM_CHARGE_XL_BLUEPRINT  : 17663,
    USED_ECTOPLASMIC_FLUID  : 54225,
    UTU  : 2834,
    VACCINES  : 28974,
    VADARIS_CUSTOM_GYROSTABILIZER  : 44112,
    VAGABOND  : 11999,
    VAGABOND_BIOSECURITY_RESPONDERS_SKIN  : 55679,
    VAGABOND_BLUE_TIGER_SKIN  : 36819,
    VAGABOND_BLUEPRINT  : 12000,
    VAGABOND_CAPSULEER_DAY_XV_SKIN  : 47636,
    VAGABOND_CHAINBREAKER_KHUMAAK_SKIN  : 64590,
    VAGABOND_DAWN_OF_LIBERATION_SKIN  : 48506,
    VAGABOND_EXOPLANETS_HUNTER_SKIN  : 45959,
    VAGABOND_GLACIAL_DRIFT_SKIN  : 46896,
    VAGABOND_HAZARD_CONTROL_SKIN  : 56904,
    VAGABOND_HEARTSURGE_SKIN  : 53962,
    VAGABOND_JUSTICE_SKIN  : 36433,
    VAGABOND_LIBERATION_GAMES_SKIN  : 59471,
    VAGABOND_REPUBLIC_FLEET_SKIN  : 59812,
    VAGABOND_SNOWLINE_BLADERACER_SKIN  : 52870,
    VAGABOND_TRONHADAR_INK_SKIN  : 40489,
    VAGABOND_VALKLEAR_GLORY_SKIN  : 36772,
    VALKYRIE_I  : 15510,
    VALKYRIE_I_BLUEPRINT  : 15511,
    VALKYRIE_II  : 21640,
    VALKYRIE_SW_600  : 23729,
    VALKYRIE_SW_600_BLUEPRINT  : 23730,
    VALKYRIE_TP_600  : 23721,
    VALKYRIE_TP_600_BLUEPRINT  : 23722,
    VALRAVN  : 77288,
    VANADINITE  : 45500,
    VANADIUM  : 16642,
    VANADIUM_HAFNITE  : 17959,
    VANADIUM_HAFNITE_REACTION_FORMULA  : 46183,
    VANGEL  : 3518,
    VANGUARD  : 42132,
    VANQUISHER  : 42126,
    VANQUISHER_GLACIAL_DRIFT_SKIN  : 46981,
    VANQUISHER_IGC_SKIN  : 46045,
    VARGUR  : 28665,
    VARGUR_BIOSECURITY_RESPONDERS_SKIN  : 55689,
    VARGUR_BLOODY_HANDS_SKIN  : 47176,
    VARGUR_BLUE_TIGER_SKIN  : 36833,
    VARGUR_CHAINBREAKER_KHUMAAK_SKIN  : 48522,
    VARGUR_DAWN_OF_LIBERATION_SKIN  : 48518,
    VARGUR_EXOPLANETS_HUNTER_SKIN  : 45977,
    VARGUR_GALNET_STREAMCAST_UNIT_SKIN  : 76488,
    VARGUR_GLACIAL_DRIFT_SKIN  : 46914,
    VARGUR_HAZARD_CONTROL_SKIN  : 56914,
    VARGUR_HEARTSURGE_SKIN  : 76281,
    VARGUR_IN_RUST_WE_TRUST_SKIN  : 52432,
    VARGUR_LIBERATION_GAMES_SKIN  : 59474,
    VARGUR_REPUBLIC_FLEET_SKIN  : 58873,
    VARGUR_SCOPE_SYNDICATION_YC122_SKIN  : 61183,
    VARGUR_SNOWLINE_BLADERACER_SKIN  : 52888,
    VARGUR_THUKKER_TRIBE_SKIN  : 35705,
    VARGUR_TRONHADAR_INK_SKIN  : 40504,
    VARGUR_VALKLEAR_GLORY_SKIN  : 36786,
    VARGUR_YOIUL_STAR_SKIN  : 61127,
    VARGUR_ZAKURA_SHUMYU_SKIN  : 57072,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_A_I  : 60291,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_A_I_BLUEPRINT  : 60345,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_A_II  : 60294,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_B_I  : 60292,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_B_I_BLUEPRINT  : 60367,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_B_II  : 60295,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_C_I  : 60293,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_C_I_BLUEPRINT  : 60368,
    VARIEGATED_ASTEROID_MINING_CRYSTAL_TYPE_C_II  : 60296,
    VARIEGATED_ORE_PROCESSING  : 60379,
    VEDMAK  : 47270,
    VEDMAK_AURORA_UNIVERSALIS_SKIN  : 60925,
    VEDMAK_BLACKFIRE_STEEL_SKIN  : 49924,
    VEDMAK_COPPER_LIGHTNING_SKIN  : 60758,
    VEDMAK_HADEAN_HORROR_SKIN  : 48654,
    VEDMAK_METAMATERIA_EXOTICA_SKIN  : 50141,
    VEDMAK_NETHERWORLD_STRIKER_SKIN  : 50157,
    VEDMAK_RED_MEATGRINDER_SKIN  : 52322,
    VEDMAK_SCOPE_SYNDICATION_YC122_SKIN  : 56890,
    VEDMAK_SINGULARITY_STORM_SKIN  : 48650,
    VEDMAK_TRIGLAVIAN_VICTORY_SKIN  : 48564,
    VEHEMENT  : 42124,
    VEHEMENT_GLACIAL_DRIFT_SKIN  : 46979,
    VEHEMENT_IGC_SKIN  : 46043,
    VELATOR  : 606,
    VELATOR_BIOSECURITY_RESPONDERS_SKIN  : 55593,
    VELATOR_EXOPLANETS_HUNTER_SKIN  : 45870,
    VELATOR_GLACIAL_DRIFT_SKIN  : 44173,
    VELATOR_SPIRIT_SKIN  : 44888,
    VELDSPAR  : 1230,
    VELES_ENTROPIC_RADIATION_SINK  : 52244,
    VELES_ENTROPIC_RADIATION_SINK_BLUEPRINT  : 52245,
    VELES_HEAVY_ENTROPIC_DISINTEGRATOR  : 47919,
    VELES_HEAVY_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 52349,
    VELES_LIGHT_ENTROPIC_DISINTEGRATOR  : 47915,
    VELES_LIGHT_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 52348,
    VELES_SUPRATIDAL_ENTROPIC_DISINTEGRATOR  : 47923,
    VELES_SUPRATIDAL_ENTROPIC_DISINTEGRATOR_BLUEPRINT  : 52350,
    VENDETTA  : 42125,
    VENDETTA_GLACIAL_DRIFT_SKIN  : 46980,
    VENDETTA_IGC_SKIN  : 46044,
    VENGEANCE  : 11365,
    VENGEANCE_BIOSECURITY_RESPONDERS_SKIN  : 55340,
    VENGEANCE_BLUEPRINT  : 11366,
    VENGEANCE_COLD_IRON_SKIN  : 42753,
    VENGEANCE_CROWN_AND_SWORDS_SKIN  : 56190,
    VENGEANCE_EOM_SKIN  : 36291,
    VENGEANCE_EXOPLANETS_HUNTER_SKIN  : 45760,
    VENGEANCE_GLACIAL_DRIFT_SKIN  : 46775,
    VENGEANCE_OASIS_OF_FAITH_SKIN  : 53602,
    VENGEANCE_PURITY_OF_THE_THRONE_SKIN  : 42551,
    VENGEANCE_RAATA_SUNSET_SKIN  : 37491,
    VENGEANCE_STAR_CAPTAIN_SKIN  : 52572,
    VENTURE  : 32880,
    VENTURE_AMARR_INDUSTRIAL_LIVERY_SKIN  : 43749,
    VENTURE_ANGEL_INDUSTRIAL_LIVERY_SKIN  : 43802,
    VENTURE_BLOOD_RAIDER_INDUSTRIAL_LIVERY_SKIN  : 43815,
    VENTURE_BLUEPRINT  : 32881,
    VENTURE_CALDARI_INDUSTRIAL_LIVERY_SKIN  : 43762,
    VENTURE_GALLENTE_INDUSTRIAL_LIVERY_SKIN  : 43776,
    VENTURE_GALNET_STREAMCAST_UNIT_SKIN  : 76493,
    VENTURE_GLACIAL_DRIFT_SKIN  : 44186,
    VENTURE_GURISTAS_INDUSTRIAL_LIVERY_SKIN  : 43828,
    VENTURE_LODESTRIKE_SKIN  : 46734,
    VENTURE_MINMATAR_INDUSTRIAL_LIVERY_SKIN  : 43789,
    VENTURE_MORPHITE_SHINE_SKIN  : 42614,
    VENTURE_PAYDIRT_PROSPECTOR_SKIN  : 42671,
    VENTURE_RADIOACTIVES_RECLAMATION_SKIN  : 60944,
    VENTURE_ROCKBREAKER_PIONEERS_SKIN  : 56604,
    VENTURE_SANSHA_INDUSTRIAL_LIVERY_SKIN  : 43841,
    VENTURE_SCOPE_SYNDICATION_YC122_SKIN  : 61184,
    VEPAS_MINIMALAS_TAG  : 17296,
    VEPAS_MODIFIED_350MM_RAILGUN  : 14393,
    VEPAS_MODIFIED_425MM_RAILGUN  : 14403,
    VEPAS_MODIFIED_BALLISTIC_CONTROL_SYSTEM  : 14688,
    VEPAS_MODIFIED_CLOAKING_DEVICE  : 14780,
    VEPAS_MODIFIED_CO_PROCESSOR  : 14772,
    VEPAS_MODIFIED_CRUISE_MISSILE_LAUNCHER  : 14676,
    VEPAS_MODIFIED_DUAL_250MM_RAILGUN  : 14413,
    VEPAS_MODIFIED_EM_SHIELD_AMPLIFIER  : 14744,
    VEPAS_MODIFIED_EM_SHIELD_HARDENER  : 14754,
    VEPAS_MODIFIED_EXPLOSIVE_SHIELD_AMPLIFIER  : 14720,
    VEPAS_MODIFIED_EXPLOSIVE_SHIELD_HARDENER  : 14758,
    VEPAS_MODIFIED_KINETIC_SHIELD_AMPLIFIER  : 14736,
    VEPAS_MODIFIED_KINETIC_SHIELD_HARDENER  : 14750,
    VEPAS_MODIFIED_LARGE_GRAVITON_SMARTBOMB  : 14696,
    VEPAS_MODIFIED_LARGE_SHIELD_BOOSTER  : 14702,
    VEPAS_MODIFIED_MULTISPECTRAL_ECM  : 20205,
    VEPAS_MODIFIED_MULTISPECTRUM_SHIELD_HARDENER  : 14766,
    VEPAS_MODIFIED_RAPID_HEAVY_MISSILE_LAUNCHER  : 33465,
    VEPAS_MODIFIED_SHIELD_BOOST_AMPLIFIER  : 14712,
    VEPAS_MODIFIED_THERMAL_SHIELD_AMPLIFIER  : 14728,
    VEPAS_MODIFIED_THERMAL_SHIELD_HARDENER  : 14762,
    VEPAS_MODIFIED_TORPEDO_LAUNCHER  : 14682,
    VEPAS_MODIFIED_X_LARGE_SHIELD_BOOSTER  : 14706,
    VERMILLION_CYTOSEROCIN  : 25278,
    VERMILLION_MYKOSEROCIN  : 28700,
    VESPA_EC_600  : 23705,
    VESPA_EC_600_BLUEPRINT  : 23706,
    VESPA_I  : 15508,
    VESPA_I_BLUEPRINT  : 15509,
    VESPA_II  : 21638,
    VESSEL_CORE_HOLOGRAM  : 56713,
    VEXOR  : 626,
    VEXOR_AURORA_UNIVERSALIS_SKIN  : 53376,
    VEXOR_BIOSECURITY_RESPONDERS_SKIN  : 55603,
    VEXOR_BLUEPRINT  : 971,
    VEXOR_CAPSULEER_DAY_XVII_SKIN  : 54787,
    VEXOR_DEATHGLOW_HUNTERS_SKIN  : 60573,
    VEXOR_DEATHGLOW_REMNANT_SKIN  : 60659,
    VEXOR_EXOPLANETS_HUNTER_SKIN  : 45895,
    VEXOR_FEDERAL_POLICE_SKIN  : 50002,
    VEXOR_GLACIAL_DRIFT_SKIN  : 44184,
    VEXOR_GLITTERING_DREAM_SKIN  : 47530,
    VEXOR_HEADHUNTER_SKIN  : 46718,
    VEXOR_IGC_SKIN  : 48555,
    VEXOR_INNER_ZONE_VANGUARD_SKIN  : 52730,
    VEXOR_INTAKI_SYNDICATE_SKIN  : 34694,
    VEXOR_INTERBUS_SKIN  : 34695,
    VEXOR_KOPIS_EDGE_SKIN  : 46407,
    VEXOR_LUMINAIRE_RISING_SKIN  : 64568,
    VEXOR_NAVY_ISSUE  : 17843,
    VEXOR_NAVY_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55611,
    VEXOR_NAVY_ISSUE_EMPYREAN_DRAGONS_SKIN  : 47372,
    VEXOR_NAVY_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45904,
    VEXOR_NAVY_ISSUE_FEDERAL_POLICE_SKIN  : 54900,
    VEXOR_NAVY_ISSUE_GLACIAL_DRIFT_SKIN  : 46950,
    VEXOR_NAVY_ISSUE_GLITTERING_DREAM_SKIN  : 47531,
    VEXOR_NAVY_ISSUE_IGC_SKIN  : 48556,
    VEXOR_NAVY_ISSUE_INNER_ZONE_VANGUARD_SKIN  : 52739,
    VEXOR_NAVY_ISSUE_INTAKI_SYNDICATE_SKIN  : 36394,
    VEXOR_NAVY_ISSUE_KOPIS_EDGE_SKIN  : 46416,
    VEXOR_NAVY_ISSUE_LUMINAIRE_RISING_SKIN  : 48215,
    VEXOR_NAVY_ISSUE_RUBY_SUNGRAZER_SKIN  : 45568,
    VEXOR_NAVY_ISSUE_SAPPHIRE_SUNGRAZER_SKIN  : 45024,
    VEXOR_NAVY_ISSUE_SPIRIT_SKIN  : 44922,
    VEXOR_NAVY_ISSUE_WARP_CONVERGENCE_SKIN  : 61872,
    VEXOR_NAVY_ISSUE_YOIUL_FESTIVAL_YC121_SKIN  : 49955,
    VEXOR_QUAFE_SKIN  : 34696,
    VEXOR_RUBY_SUNGRAZER_SKIN  : 45566,
    VEXOR_SAPPHIRE_SUNGRAZER_SKIN  : 46564,
    VEXOR_SCOPE_SYNDICATION_YC122_SKIN  : 56882,
    VEXOR_SERPENTIS_SKIN  : 42169,
    VEXOR_SPIRIT_SKIN  : 44913,
    VEXOR_STRATEGIC_MATERIEL_DEFENDER_SKIN  : 59235,
    VEXOR_VALIMOR_LEGACY_SKIN  : 37566,
    VEXOR_VERSUS_BLUEFORCE_SKIN  : 62017,
    VEXOR_WARP_CONVERGENCE_SKIN  : 61871,
    VEXOR_ZAKURA_SHUMYU_SKIN  : 57062,
    VHEROKIOR_MEDICINAL_HERBS  : 48585,
    VHEROKIOR_TRIBE_STRONG_BOX  : 43692,
    VIAL_OF_KYONOKE_PLAGUE_CURE  : 47046,
    VIAL_OF_MIKRAMURKA  : 48586,
    VIATOR  : 12743,
    VIATOR_BIOSECURITY_RESPONDERS_SKIN  : 55616,
    VIATOR_BLUEPRINT  : 12744,
    VIATOR_EXOPLANETS_HUNTER_SKIN  : 45927,
    VIATOR_GLACIAL_DRIFT_SKIN  : 46978,
    VIATOR_INNER_ZONE_VANGUARD_SKIN  : 52762,
    VIATOR_INTAKI_SYNDICATE_SKIN  : 36390,
    VIATOR_KOPIS_EDGE_SKIN  : 46439,
    VIATOR_LODESTRIKE_SKIN  : 46751,
    VIATOR_RUBY_SUNGRAZER_SKIN  : 45579,
    VIATOR_SERPENTIS_SKIN  : 42196,
    VIATOR_SPIRIT_SKIN  : 44945,
    VIATOR_VALIMOR_LEGACY_SKIN  : 37592,
    VICTOR  : 45531,
    VICTORIEUX_LUXURY_YACHT  : 34590,
    VIDETTE_ELEMENT  : 34558,
    VIDETTE_INDEX  : 34553,
    VIDETTE_SEQUENCE_0  : 34544,
    VIDETTE_SEQUENCE_1  : 34545,
    VIGIL  : 3766,
    VIGIL_BIOSECURITY_RESPONDERS_SKIN  : 55645,
    VIGIL_BLUE_TIGER_SKIN  : 36803,
    VIGIL_BLUEPRINT  : 3767,
    VIGIL_DAWN_OF_LIBERATION_SKIN  : 48490,
    VIGIL_EXOPLANETS_HUNTER_SKIN  : 45938,
    VIGIL_FLEET_ISSUE  : 37454,
    VIGIL_FLEET_ISSUE_BIOSECURITY_RESPONDERS_SKIN  : 55672,
    VIGIL_FLEET_ISSUE_DAWN_OF_LIBERATION_SKIN  : 48500,
    VIGIL_FLEET_ISSUE_EXOPLANETS_HUNTER_SKIN  : 45948,
    VIGIL_FLEET_ISSUE_GLACIAL_DRIFT_SKIN  : 46889,
    VIGIL_FLEET_ISSUE_JUSTICE_SKIN  : 40333,
    VIGIL_FLEET_ISSUE_SNOWLINE_BLADERACER_SKIN  : 52859,
    VIGIL_FLEET_ISSUE_SPIRIT_SKIN  : 57393,
    VIGIL_FLEET_ORDNANCE_SPIKE_SKIN  : 59430,
    VIGIL_GLACIAL_DRIFT_SKIN  : 44165,
    VIGIL_HAZARD_CONTROL_SKIN  : 56919,
    VIGIL_KRUSUAL_SKIN  : 34751,
    VIGIL_SNOWLINE_BLADERACER_SKIN  : 52849,
    VIGIL_SPIRIT_SKIN  : 47316,
    VIGIL_TRONHADAR_INK_SKIN  : 40472,
    VIGIL_VALKLEAR_GLORY_SKIN  : 36756,
    VIGILANT  : 17722,
    VIGILANT_ANGELS_HEX_SKIN  : 49149,
    VIGILANT_AURORA_UNIVERSALIS_SKIN  : 60917,
    VIGILANT_GLACIAL_DRIFT_SKIN  : 46976,
    VIGILANT_HEARTSURGE_SKIN  : 53959,
    VIGILANT_IGC_SKIN  : 46040,
    VIGILANT_SPIRIT_SKIN  : 57307,
    VIGILANT_VERSUS_BLUEFORCE_SKIN  : 62648,
    VIGILANT_VERSUS_REDFORCE_SKIN  : 62642,
    VIGOR_COMPACT_MICRO_AUXILIARY_POWER_CORE  : 16537,
    VIMOKSHA_CHORUS_LOG_1220108_TD53  : 53851,
    VINCENT_PRYCES_WARP_DISRUPTION_FIELD_GENERATOR  : 34429,
    VINDICATOR  : 17740,
    VINDICATOR_AMARANTHINE_MANTLE_SKIN  : 50034,
    VINDICATOR_ANGELS_HEX_SKIN  : 49150,
    VINDICATOR_EDENS_HUNTERS_SKIN  : 54912,
    VINDICATOR_GLACIAL_DRIFT_SKIN  : 46977,
    VINDICATOR_HEARTSURGE_SKIN  : 53960,
    VINDICATOR_IGC_SKIN  : 46042,
    VINDICATOR_KYBERNAUT_CLADE_SKIN  : 54921,
    VINDICATOR_RED_STARGAZER_SKIN  : 75898,
    VINDICATOR_SARPATIS_REVENGE___IGIL_SKIN  : 48146,
    VINDICATOR_SARPATIS_REVENGE___VIRGE_SKIN  : 48147,
    VINDICATOR_SPIRIT_SKIN  : 57308,
    VINDICATOR_VERSUS_BLUEFORCE_SKIN  : 62649,
    VINDICATOR_VERSUS_REDFORCE_SKIN  : 62643,
    VINTAGE_FRIGATE_BLUEPRINTS  : 45060,
    VINTAGE_WINE  : 49652,
    VIOLATOR  : 33083,
    VIOLATOR_IGC_SKIN  : 46041,
    VIRAL_AGENT  : 3775,
    VIRGIN_FOREST_PULP  : 17756,
    VIRIDIAN_CYTOSEROCIN  : 25274,
    VIRIDIAN_MYKOSEROCIN  : 28701,
    VIRTUOSO  : 45530,
    VISCOUS_PYROXERES  : 17460,
    VISIBILITY  : 3447,
    VITOC  : 3713,
    VITREOUS_MERCOXIT  : 17870,
    VITRIC_HEDBERGITE  : 17440,
    VIVID_HEMORPHITE  : 17444,
    VIZAN_ANKONINS_TAG  : 17288,
    VIZANS_MODIFIED_CAP_RECHARGER  : 16603,
    VIZANS_MODIFIED_CAPACITOR_POWER_RELAY  : 15088,
    VIZANS_MODIFIED_DUAL_HEAVY_PULSE_LASER  : 14431,
    VIZANS_MODIFIED_EM_ARMOR_HARDENER  : 15024,
    VIZANS_MODIFIED_EM_COATING  : 14912,
    VIZANS_MODIFIED_EM_ENERGIZED_MEMBRANE  : 14976,
    VIZANS_MODIFIED_EXPLOSIVE_ARMOR_HARDENER  : 15072,
    VIZANS_MODIFIED_EXPLOSIVE_COATING  : 14896,
    VIZANS_MODIFIED_EXPLOSIVE_ENERGIZED_MEMBRANE  : 14992,
    VIZANS_MODIFIED_HEAT_SINK  : 14808,
    VIZANS_MODIFIED_HEAVY_CAPACITOR_BOOSTER  : 15136,
    VIZANS_MODIFIED_HEAVY_ENERGY_NEUTRALIZER  : 14840,
    VIZANS_MODIFIED_HEAVY_ENERGY_NOSFERATU  : 14824,
    VIZANS_MODIFIED_KINETIC_ARMOR_HARDENER  : 15056,
    VIZANS_MODIFIED_KINETIC_COATING  : 14880,
    VIZANS_MODIFIED_KINETIC_ENERGIZED_MEMBRANE  : 15008,
    VIZANS_MODIFIED_LARGE_ARMOR_REPAIRER  : 14852,
    VIZANS_MODIFIED_LARGE_EMP_SMARTBOMB  : 14792,
    VIZANS_MODIFIED_MEGA_PULSE_LASER  : 14447,
    VIZANS_MODIFIED_MULTISPECTRUM_COATING  : 14864,
    VIZANS_MODIFIED_MULTISPECTRUM_ENERGIZED_MEMBRANE  : 14944,
    VIZANS_MODIFIED_POWER_DIAGNOSTIC_SYSTEM  : 15104,
    VIZANS_MODIFIED_REACTOR_CONTROL_UNIT  : 15120,
    VIZANS_MODIFIED_THERMAL_ARMOR_HARDENER  : 15040,
    VIZANS_MODIFIED_THERMAL_COATING  : 14928,
    VIZANS_MODIFIED_THERMAL_ENERGIZED_MEMBRANE  : 14960,
    VOID_BOMB  : 27924,
    VOID_BOMB_BLUEPRINT  : 27925,
    VOID_L  : 12791,
    VOID_M  : 12789,
    VOID_S  : 12612,
    VOID_XL  : 41322,
    VORTEX_COMPACT_MAGNETIC_FIELD_STABILIZER  : 11105,
    VORTON_ARC_EXTENSION  : 55511,
    VORTON_ARC_GUIDANCE  : 54841,
    VORTON_POWER_AMPLIFICATION  : 54840,
    VORTON_PROJECTOR_BOOSTER_GIFT_BOX  : 55029,
    VORTON_PROJECTOR_OPERATION  : 55033,
    VORTON_TUNING_SYSTEM_I  : 54973,
    VORTON_TUNING_SYSTEM_II  : 54975,
    VULTURE  : 22446,
    VULTURE_BIOSECURITY_RESPONDERS_SKIN  : 55567,
    VULTURE_BLUE_TIGER_SKIN  : 36735,
    VULTURE_BLUEPRINT  : 22447,
    VULTURE_EXOPLANETS_HUNTER_SKIN  : 45850,
    VULTURE_GHOSTBIRD_SKIN  : 48193,
    VULTURE_GLACIAL_DRIFT_SKIN  : 46854,
    VULTURE_MATIGU_SEABEAST_SKIN  : 40611,
    VULTURE_RAATA_SUNSET_SKIN  : 36663,
    VULTURE_STEEL_CARDINAL_SKIN  : 46127,
    VULTURE_WIYRKOMI_SKIN  : 36356,
    VULTURE_ZENTO_ISIDEKO_COMBINE_SKIN  : 53187,
    VULTURE_ZYDRINE_BURN_SKIN  : 76958,
    VXN_01N_NULLSEC_ICE_STORM_FILAMENT  : 74431,
    WARCLONE_BLANKS  : 56788,
    WARD_CONSOLE  : 25606,
    WARDEN_I  : 23559,
    WARDEN_I_BLUEPRINT  : 23560,
    WARDEN_II  : 28209,
    WARFARE_COMPUTATION_CORE  : 29994,
    WARFARE_COMPUTATION_CORE_BLUEPRINT  : 29995,
    WARHAMMER_LARGE_EMP_SMARTBOMB_I  : 23868,
    WARHEAD_UPGRADES  : 20315,
    WARP_CORE_STABILIZER_I  : 10998,
    WARP_CORE_STABILIZER_I_BLUEPRINT  : 11613,
    WARP_CORE_STABILIZER_II  : 11640,
    WARP_DISRUPT_PROBE  : 22778,
    WARP_DISRUPT_PROBE_BLUEPRINT  : 22779,
    WARP_DISRUPTION_BATTERY  : 17181,
    WARP_DISRUPTION_BATTERY_BLUEPRINT  : 2736,
    WARP_DISRUPTION_BURST_PROJECTOR  : 40696,
    WARP_DISRUPTION_BURST_PROJECTOR_BLUEPRINT  : 41394,
    WARP_DISRUPTION_FIELD_GENERATOR_I  : 28654,
    WARP_DISRUPTION_FIELD_GENERATOR_I_BLUEPRINT  : 28655,
    WARP_DISRUPTION_FIELD_GENERATOR_II  : 4248,
    WARP_DISRUPTOR_I  : 3242,
    WARP_DISRUPTOR_I_BLUEPRINT  : 3243,
    WARP_DISRUPTOR_II  : 3244,
    WARP_DRIVE_OPERATION  : 3455,
    WARP_SCRAMBLER_I  : 447,
    WARP_SCRAMBLER_I_BLUEPRINT  : 1079,
    WARP_SCRAMBLER_II  : 448,
    WARP_SCRAMBLING_BATTERY  : 17182,
    WARP_SCRAMBLING_BATTERY_BLUEPRINT  : 2740,
    WARRIOR_I  : 2486,
    WARRIOR_I_BLUEPRINT  : 2487,
    WARRIOR_II  : 2488,
    WARRIOR_SW_300  : 23731,
    WARRIOR_SW_300_BLUEPRINT  : 23732,
    WARRIOR_TP_300  : 23723,
    WARRIOR_TP_300_BLUEPRINT  : 23724,
    WASP_EC_900  : 23473,
    WASP_EC_900_BLUEPRINT  : 23474,
    WASP_I  : 1201,
    WASP_I_BLUEPRINT  : 1214,
    WASP_II  : 2436,
    WATER  : 3645,
    WATER_COOLED_CPU  : 2328,
    WATER_SUCKER_DISPLAY  : 56976,
    WEAPON_DESTABILIZATION  : 19767,
    WEAPON_DISRUPTION  : 3434,
    WEAPON_DISRUPTION_BURST_PROJECTOR  : 40699,
    WEAPON_DISRUPTION_BURST_PROJECTOR_BLUEPRINT  : 41392,
    WEAPON_UPGRADES  : 3318,
    WELL_WORN_FEDORA  : 48751,
    WETU_MOBILE_DEPOT  : 33520,
    WETWARE_MAINFRAME  : 2876,
    WHEAT  : 3673,
    WHEEL_OF_PROSPERITY_FIREWORK  : 44263,
    WHELAN_MACHORINS_BALLISTIC_SMARTLINK  : 20371,
    WHIPTAIL  : 33673,
    WHISKEY_EXPLOSIVE_SHIELD_AMPLIFIER  : 20605,
    WHITE_GLAZE  : 16265,
    WHITE_NOISE_GENERATION_BATTERY  : 17177,
    WHITE_NOISE_GENERATION_BATTERY_BLUEPRINT  : 2798,
    WHOLESALE  : 16596,
    WIDOW  : 22436,
    WIDOW_BIOSECURITY_RESPONDERS_SKIN  : 55558,
    WIDOW_BLUE_TIGER_SKIN  : 36739,
    WIDOW_CALDARI_NAVY_SKIN  : 58883,
    WIDOW_EXOPLANETS_HUNTER_SKIN  : 45855,
    WIDOW_FIREBLADE_GUERILLA_SKIN  : 59326,
    WIDOW_GHOSTBIRD_SKIN  : 48198,
    WIDOW_GLACIAL_DRIFT_SKIN  : 46859,
    WIDOW_ICEBLADE_GUERILLA_SKIN  : 59331,
    WIDOW_MATIGU_SEABEAST_SKIN  : 40615,
    WIDOW_RAATA_SUNSET_SKIN  : 36667,
    WIDOW_STEEL_CARDINAL_SKIN  : 46132,
    WIDOW_VERSUS_REDFORCE_SKIN  : 63866,
    WIDOW_WIYRKOMI_SKIN  : 36355,
    WIDOW_ZENTO_ISIDEKO_COMBINE_SKIN  : 53192,
    WILD_MINER_I  : 22923,
    WING_COMMAND  : 11574,
    WINGS_OF_VICTORY_FIREWORK  : 44265,
    WOLF  : 11371,
    WOLF_BIOSECURITY_RESPONDERS_SKIN  : 55683,
    WOLF_BLUE_TIGER_SKIN  : 36805,
    WOLF_BLUEPRINT  : 11372,
    WOLF_CHAINBREAKER_KHUMAAK_SKIN  : 77961,
    WOLF_DAWN_OF_LIBERATION_SKIN  : 48492,
    WOLF_EXOPLANETS_HUNTER_SKIN  : 45940,
    WOLF_GLACIAL_DRIFT_SKIN  : 46881,
    WOLF_HAZARD_CONTROL_SKIN  : 56909,
    WOLF_IN_RUST_WE_TRUST_SKIN  : 52424,
    WOLF_JUSTICE_SKIN  : 36427,
    WOLF_REPUBLIC_FLEET_SKIN  : 59809,
    WOLF_SNOWLINE_BLADERACER_SKIN  : 52851,
    WOLF_TRONHADAR_INK_SKIN  : 40474,
    WOLF_VALKLEAR_GLORY_SKIN  : 36758,
    WOMENS_ACE_OF_PODHUNTERS_BOOTS  : 63123,
    WOMENS_ACE_OF_PODHUNTERS_JACKET  : 63115,
    WOMENS_ACE_OF_PODHUNTERS_PANTS  : 63124,
    WOMENS_AERIFORM_HEADWEAR_BLACK  : 34078,
    WOMENS_AERIFORM_HEADWEAR_BLUE  : 34066,
    WOMENS_AERIFORM_HEADWEAR_CLEAR  : 34076,
    WOMENS_AERIFORM_HEADWEAR_CYAN  : 34064,
    WOMENS_AERIFORM_HEADWEAR_ORANGE  : 34070,
    WOMENS_AIR_CLAIM_SURVEYOR_SUIT  : 63056,
    WOMENS_ALBEDO_AFFLUENCE_JACKET  : 54364,
    WOMENS_ALBEDO_AFFLUENCE_PANTS  : 54370,
    WOMENS_AMARANTHINE_DIVIDE_FACIAL_AUGMENTATION  : 50052,
    WOMENS_AMARR_COURTLY_ROBE  : 64378,
    WOMENS_AMARR_DEVOTIONAL_ROBE  : 64377,
    WOMENS_AMARR_EMPIRE_COMMAND_JACKET  : 46663,
    WOMENS_AMARR_EMPIRE_EXPLORATION_SUIT  : 45518,
    WOMENS_AMARR_LOYALIST_T_SHIRT  : 47186,
    WOMENS_AURORA_UNIVERSALIS_CAP  : 53381,
    WOMENS_AURORA_UNIVERSALIS_T_SHIRT  : 53380,
    WOMENS_AVENUE_SHIRT_BLACK  : 4216,
    WOMENS_AVENUE_SHIRT_BLACK_AND_DARK_RED  : 4229,
    WOMENS_AVENUE_SHIRT_BLACK_LEATHER  : 4217,
    WOMENS_AVENUE_SHIRT_BLUE  : 4218,
    WOMENS_AVENUE_SHIRT_BROWN  : 4219,
    WOMENS_AVENUE_SHIRT_CAMO  : 4226,
    WOMENS_AVENUE_SHIRT_GRAY  : 4220,
    WOMENS_AVENUE_SHIRT_GRAY_CAMO  : 4223,
    WOMENS_AVENUE_SHIRT_GREEN  : 4221,
    WOMENS_AVENUE_SHIRT_LINED_BROWN  : 4227,
    WOMENS_AVENUE_SHIRT_ORANGE  : 4222,
    WOMENS_AVENUE_SHIRT_ORANGE_CAMO  : 4224,
    WOMENS_AVENUE_SHIRT_PINK_CAMO  : 4230,
    WOMENS_AVENUE_SHIRT_PURPLE_MESH  : 4228,
    WOMENS_AVENUE_SHIRT_RED  : 4231,
    WOMENS_AVENUE_SHIRT_RED_PATTERNED  : 4225,
    WOMENS_AVENUE_SHIRT_WHITE  : 4232,
    WOMENS_AZURE_ABUNDANCE_JACKET  : 54360,
    WOMENS_AZURE_ABUNDANCE_PANTS  : 54366,
    WOMENS_BERET_BLOOD_RAIDERS  : 42733,
    WOMENS_BIOADAPTIVE_KYBERNAUT_COMBAT_SUIT  : 56264,
    WOMENS_BIOCRYSTAL_PROTECTIVE_GLASSES  : 54965,
    WOMENS_BIOSECURITY_RESPONSE_TEAM_COAT  : 40458,
    WOMENS_BIOSECURITY_RESPONSE_TEAM_MASK  : 54714,
    WOMENS_BITWAVE_GLACIER_AUGMENTATION  : 49984,
    WOMENS_BLADES_HEADWEAR_BLACK  : 34071,
    WOMENS_BLADES_HEADWEAR_GOLD  : 34069,
    WOMENS_BLADES_HEADWEAR_GUNMETAL  : 34065,
    WOMENS_BLADES_HEADWEAR_JADE  : 34074,
    WOMENS_BLADES_HEADWEAR_PLATINUM  : 34067,
    WOMENS_BOARDER_CAP_DIGITAL_CAMO  : 42729,
    WOMENS_CALDARI_NAVY_CREW_JACKET  : 70847,
    WOMENS_CALDARI_PATRIOT_T_SHIRT  : 47189,
    WOMENS_CALDARI_STATE_EXPLORATION_SUIT  : 45515,
    WOMENS_CALDARI_STATE_OFFICER_JACKET  : 46669,
    WOMENS_CAP_BLOOD_RAIDERS  : 42718,
    WOMENS_CAP_GURISTAS  : 42722,
    WOMENS_CAPSULEER_DAY_XIX_HOODIE  : 63257,
    WOMENS_CAPSULEER_DAY_XVI_CAP  : 52201,
    WOMENS_CAPSULEER_DAY_XVI_T_SHIRT  : 52198,
    WOMENS_CAPSULEER_DAY_XVII_CAP  : 54322,
    WOMENS_CAPSULEER_DAY_XVII_T_SHIRT  : 54320,
    WOMENS_CAPSULEER_DAY_XVIII_CAP  : 58769,
    WOMENS_CAPSULEER_DAY_XVIII_T_SHIRT  : 58768,
    WOMENS_CAPSULEER_ELITE_XIX_HOODIE  : 63260,
    WOMENS_CAPSULEER_ELITE_XV_CAP  : 47193,
    WOMENS_CAPSULEER_ELITE_XV_T_SHIRT  : 47192,
    WOMENS_CAPSULEER_NETWORKER_XV_CAP  : 47477,
    WOMENS_CAPSULEER_NETWORKER_XV_SUIT  : 47475,
    WOMENS_CAPSULEER_NETWORKER_XV_T_SHIRT  : 47479,
    WOMENS_CBD_CORPORATION_CREW_JACKET  : 70849,
    WOMENS_CONCORD_SOLITAIRE_SURVIVAL_SUIT  : 37601,
    WOMENS_CONCORD_TEST_PILOT_T_SHIRT  : 37808,
    WOMENS_CORPORATE_TROUBLESHOOTER_GEAR  : 45519,
    WOMENS_CORSAIR_HEELS_BEIGE  : 33723,
    WOMENS_CORSAIR_HEELS_BLACK  : 33720,
    WOMENS_CORSAIR_HEELS_BLUE  : 33721,
    WOMENS_CORSAIR_HEELS_BROWN  : 33722,
    WOMENS_CORSAIR_HEELS_GRAY  : 33724,
    WOMENS_CORSAIR_HEELS_RED  : 33725,
    WOMENS_CRIMSON_HARVEST_CAP  : 56634,
    WOMENS_CRIMSON_HARVEST_JACKET  : 56635,
    WOMENS_CRIMSON_HARVEST_T_SHIRT  : 56636,
    WOMENS_CRIMSON_PHOENIX_FACIAL_AUGMENTATION  : 50043,
    WOMENS_CROSSGRID_FACIAL_AUGMENTATION  : 41049,
    WOMENS_CRUSADER_BERET_AMARR_EMPIRE  : 42744,
    WOMENS_DED_CRYPTOLOGIC_TECHNICIAN_COAT  : 54158,
    WOMENS_DEFENDER_BERET_GALLENTE_FEDERATION  : 42709,
    WOMENS_DESERT_NOMAD_GOGGLES  : 73424,
    WOMENS_DESERT_NOMAD_MASK  : 73423,
    WOMENS_DOMINO_AURIC_FACIAL_AUGMENTATION  : 49671,
    WOMENS_DOMINO_BRONZA_FACIAL_AUGMENTATION  : 49676,
    WOMENS_DOMINO_ROJO_FACIAL_AUGMENTATION  : 73437,
    WOMENS_DOMINO_TURQUOISE_FACIAL_AUGMENTATION  : 49673,
    WOMENS_DREAD_BERET_GURISTAS  : 42698,
    WOMENS_EDENCOM_DEFIANT_COMBAT_SUIT  : 56265,
    WOMENS_EDENCOM_RELIANT_BERET  : 56144,
    WOMENS_EDENCOM_RELIANT_T_SHIRT  : 56146,
    WOMENS_EDENCOM_VALIANT_COMMAND_JACKET  : 56223,
    WOMENS_EICO_AURORALITE_FACIAL_AUGMENTATION  : 53386,
    WOMENS_EICO_AZURCHEV_FACIAL_AUGMENTATION  : 49669,
    WOMENS_EICO_BIOSEC_FACIAL_AUGMENTATION  : 54927,
    WOMENS_EICO_FLAMJAW_FACIAL_AUGMENTATION  : 49672,
    WOMENS_EICO_REDWIRE_XVI_FACIAL_AUGMENTATION  : 52188,
    WOMENS_EICO_SUNSTRIP_FACIAL_AUGMENTATION  : 49675,
    WOMENS_EMERGENT_THREATS_T_SHIRT_YC_117  : 34825,
    WOMENS_EMPYREAN_DRAGONS_JACKET  : 47031,
    WOMENS_ETERNITY_BOOTS_BLACK_GOLD  : 34080,
    WOMENS_ETERNITY_BOOTS_BLACK_RED  : 34086,
    WOMENS_ETERNITY_BOOTS_OLIVE  : 34083,
    WOMENS_ETERNITY_BOOTS_WHITE  : 34088,
    WOMENS_ETERNITY_BOOTS_YELLOW  : 34089,
    WOMENS_ETERNITY_SUIT_BLACK_GOLD  : 34102,
    WOMENS_ETERNITY_SUIT_BLACK_RED  : 34108,
    WOMENS_ETERNITY_SUIT_OLIVE  : 34105,
    WOMENS_ETERNITY_SUIT_TOP_BLACK_GOLD  : 34091,
    WOMENS_ETERNITY_SUIT_TOP_BLACK_RED  : 34097,
    WOMENS_ETERNITY_SUIT_TOP_OLIVE  : 34094,
    WOMENS_ETERNITY_SUIT_TOP_WHITE  : 34099,
    WOMENS_ETERNITY_SUIT_TOP_YELLOW  : 34100,
    WOMENS_ETERNITY_SUIT_WHITE  : 34110,
    WOMENS_ETERNITY_SUIT_YELLOW  : 34111,
    WOMENS_EXCURSION_PANTS_BLACK  : 4016,
    WOMENS_EXCURSION_PANTS_BLACK_BLUE_GOLD  : 4017,
    WOMENS_EXCURSION_PANTS_BLACK_GOLD  : 4018,
    WOMENS_EXCURSION_PANTS_BLACK_GOLD_LINE  : 4019,
    WOMENS_EXCURSION_PANTS_BLACK_GRAY  : 3997,
    WOMENS_EXCURSION_PANTS_BLACK_RED_GOLD  : 4020,
    WOMENS_EXCURSION_PANTS_BLACK_SILVER  : 4021,
    WOMENS_EXCURSION_PANTS_BLOOD_RAIDER  : 42701,
    WOMENS_EXCURSION_PANTS_GOLD  : 4022,
    WOMENS_EXCURSION_PANTS_MATTE_BLUE  : 4026,
    WOMENS_EXCURSION_PANTS_MATTE_GREEN  : 4028,
    WOMENS_EXCURSION_PANTS_MATTE_RED  : 4030,
    WOMENS_EXCURSION_PANTS_SILVER  : 4032,
    WOMENS_EXECUTOR_COAT  : 4054,
    WOMENS_EXECUTOR_COAT_BLACK  : 4136,
    WOMENS_EXECUTOR_COAT_GRAPHITE  : 4137,
    WOMENS_EXECUTOR_COAT_GREEN_GOLD  : 4138,
    WOMENS_EXECUTOR_COAT_MATTE_BLUE  : 4139,
    WOMENS_EXECUTOR_COAT_MATTE_RED  : 4140,
    WOMENS_EXECUTOR_COAT_RED_GOLD  : 4141,
    WOMENS_EXECUTOR_COAT_SILVER  : 4142,
    WOMENS_EXOPLANETS_DATA_ANALYST_LAB_COAT  : 45737,
    WOMENS_EXOPLANETS_HUNTER_CAP  : 45739,
    WOMENS_EXOPLANETS_HUNTER_MISSION_SUIT  : 45735,
    WOMENS_EXOPLANETS_HUNTER_T_SHIRT  : 45734,
    WOMENS_EXOPLANETS_PROGRAM_DIRECTOR_COAT  : 45738,
    WOMENS_FARSIGHT_AUGMENTED_SPECTACLES  : 45736,
    WOMENS_FATAL_ELITE_BERET  : 54304,
    WOMENS_FATAL_ELITE_COMBAT_BOOTS  : 54303,
    WOMENS_FATAL_ELITE_COMBAT_JACKET  : 54299,
    WOMENS_FATAL_ELITE_COMBAT_PANTS  : 54306,
    WOMENS_FEDERAL_DEFENDER_BERET  : 55753,
    WOMENS_FEDERAL_DEFENDER_JACKET  : 59196,
    WOMENS_FEDERAL_DEFENDER_PANTS  : 59198,
    WOMENS_FEDERAL_STRATEGIC_MATERIEL_JACKET  : 46658,
    WOMENS_FEDERATION_COMMANDERS_DRESS_JACKET  : 58943,
    WOMENS_FEDERATION_COMMANDERS_DRESS_PANTS  : 58948,
    WOMENS_FEDERATION_GRAND_PRIX_PIT_STOP_CAP  : 42745,
    WOMENS_FEDERATION_STARGATE_CREW_PANTS  : 58787,
    WOMENS_FEDERATION_STARGATE_CREW_SHOES  : 58819,
    WOMENS_FEDERATION_STARGATE_CREW_TOP  : 58811,
    WOMENS_FRONTIER_SAFEGUARDER_T_SHIRT  : 54193,
    WOMENS_FROSTLINE_EXPLORATION_SUIT  : 49980,
    WOMENS_FUNCTION_T_SHIRT_BLACK  : 4201,
    WOMENS_FUNCTION_T_SHIRT_BLOOD_RAIDERS  : 42700,
    WOMENS_FUNCTION_T_SHIRT_BLUE  : 4202,
    WOMENS_FUNCTION_T_SHIRT_BROWN  : 4203,
    WOMENS_FUNCTION_T_SHIRT_CREAM  : 4204,
    WOMENS_FUNCTION_T_SHIRT_DARK_BLUE  : 4205,
    WOMENS_FUNCTION_T_SHIRT_DARK_CAMO  : 4212,
    WOMENS_FUNCTION_T_SHIRT_DARK_RED  : 4206,
    WOMENS_FUNCTION_T_SHIRT_DESERT_CAMO  : 4213,
    WOMENS_FUNCTION_T_SHIRT_GRAY  : 4207,
    WOMENS_FUNCTION_T_SHIRT_GREEN  : 4208,
    WOMENS_FUNCTION_T_SHIRT_KHAKI  : 4209,
    WOMENS_FUNCTION_T_SHIRT_OLIVE  : 4210,
    WOMENS_FUNCTION_T_SHIRT_ORANGE  : 4211,
    WOMENS_FUNCTION_T_SHIRT_RED  : 4214,
    WOMENS_FUNCTION_T_SHIRT_WHITE  : 4215,
    WOMENS_GALLENTE_DEFENDER_T_SHIRT  : 47185,
    WOMENS_GALLENTE_FEDERATION_COMMAND_JACKET  : 46660,
    WOMENS_GALLENTE_FEDERATION_EXPLORATION_SUIT  : 45517,
    WOMENS_GENOLUTION_COMBAT_SUIT  : 37600,
    WOMENS_GENOLUTION_TACTICAL_GOGGLES  : 42905,
    WOMENS_GLASS_SEMIOTIQUE_MONOCLE_INTERFACE_RIGHT  : 60171,
    WOMENS_GRAND_PRIX_RACING_JACKET  : 63790,
    WOMENS_GREAVE_BOOTS_BLACK_GOLD  : 4127,
    WOMENS_GREAVE_BOOTS_BROWN  : 4128,
    WOMENS_GREAVE_BOOTS_MATTE_BROWN  : 4129,
    WOMENS_GREAVE_BOOTS_MATTE_GRAY  : 4130,
    WOMENS_GREAVE_BOOTS_RED  : 4131,
    WOMENS_GREAVE_KNEE_BOOTS  : 4003,
    WOMENS_GUIDEMASK_FACIAL_AUGMENTATION  : 41050,
    WOMENS_GUNNER_JACKET_AMARR  : 34170,
    WOMENS_GUNNER_JACKET_ANGEL_CARTEL  : 34177,
    WOMENS_GUNNER_JACKET_BLOOD_RAIDERS  : 34179,
    WOMENS_GUNNER_JACKET_BLOOD_RAIDERS_BLACK  : 42703,
    WOMENS_GUNNER_JACKET_CALDARI  : 34169,
    WOMENS_GUNNER_JACKET_GALLENTE  : 34172,
    WOMENS_GUNNER_JACKET_GURISTAS  : 34180,
    WOMENS_GUNNER_JACKET_INTERBUS  : 34176,
    WOMENS_GUNNER_JACKET_MINMATAR  : 34171,
    WOMENS_GUNNER_JACKET_MORDUS_LEGION  : 34175,
    WOMENS_GUNNER_JACKET_ORE  : 34173,
    WOMENS_GUNNER_JACKET_SANSHAS_NATION  : 34178,
    WOMENS_GUNNER_JACKET_SERPENTIS  : 34181,
    WOMENS_GUNNER_JACKET_SISTERS_OF_EVE  : 34174,
    WOMENS_HEPHAESTUS_PANTS_BLUE  : 36499,
    WOMENS_HEPHAESTUS_PANTS_CYAN  : 36502,
    WOMENS_HEPHAESTUS_PANTS_DESERT  : 36501,
    WOMENS_HEPHAESTUS_PANTS_GRAY_ORANGE  : 36504,
    WOMENS_HEPHAESTUS_PANTS_GREEN  : 36503,
    WOMENS_HEPHAESTUS_PANTS_WHITE_RED  : 36500,
    WOMENS_HEPHAESTUS_SHIRT_BLUE  : 36486,
    WOMENS_HEPHAESTUS_SHIRT_CYAN  : 36489,
    WOMENS_HEPHAESTUS_SHIRT_DESERT  : 36488,
    WOMENS_HEPHAESTUS_SHIRT_GRAY_ORANGE  : 36491,
    WOMENS_HEPHAESTUS_SHIRT_GREEN  : 36490,
    WOMENS_HEPHAESTUS_SHIRT_WHITE_RED  : 36487,
    WOMENS_HEPHAESTUS_SHOES_BLUE  : 36511,
    WOMENS_HEPHAESTUS_SHOES_CYAN  : 36514,
    WOMENS_HEPHAESTUS_SHOES_DESERT  : 36513,
    WOMENS_HEPHAESTUS_SHOES_GRAY_ORANGE  : 36516,
    WOMENS_HEPHAESTUS_SHOES_GREEN  : 36515,
    WOMENS_HEPHAESTUS_SHOES_WHITE_RED  : 36512,
    WOMENS_HOVER_TIGHTS_BLACK  : 33989,
    WOMENS_HOVER_TIGHTS_LIGHT  : 34045,
    WOMENS_HOVER_TIGHTS_MATTE_BLACK  : 34052,
    WOMENS_HOVER_TIGHTS_OPAQUE_BLACK  : 34049,
    WOMENS_HOVER_TIGHTS_OPAQUE_BLUE  : 34050,
    WOMENS_HOVER_TIGHTS_OPAQUE_GRAY  : 34051,
    WOMENS_HOVER_TIGHTS_OPAQUE_PURPLE  : 34053,
    WOMENS_HOVER_TIGHTS_ORANGE  : 34046,
    WOMENS_HOVER_TIGHTS_PINK  : 34047,
    WOMENS_HOVER_TIGHTS_RED  : 34048,
    WOMENS_HOVER_TIGHTS_WHITE  : 34054,
    WOMENS_HOVER_TIGHTS_YELLOW  : 34055,
    WOMENS_HUMANITARIAN_T_SHIRT_YC_115  : 33579,
    WOMENS_HUMANITARIAN_T_SHIRT_YC_117  : 35682,
    WOMENS_HYASYODA_CREW_JACKET  : 70850,
    WOMENS_HYPER_RACING_JACKET  : 64195,
    WOMENS_IGC_LOCATION_SCOUT_SUIT  : 46039,
    WOMENS_IMKURADORI_FACIAL_AUGMENTATION  : 49670,
    WOMENS_IMPERIAL_COMMANDERS_DRESS_JACKET  : 58942,
    WOMENS_IMPERIAL_COMMANDERS_DRESS_PANTS  : 58947,
    WOMENS_IMPERIAL_LOYALIST_BERET  : 55751,
    WOMENS_IMPERIAL_LOYALIST_JACKET  : 59367,
    WOMENS_IMPERIAL_LOYALIST_PANTS  : 59370,
    WOMENS_IMPERIAL_STARGATE_CREW_PANTS  : 58785,
    WOMENS_IMPERIAL_STARGATE_CREW_SHOES  : 58817,
    WOMENS_IMPERIAL_STARGATE_CREW_TOP  : 58809,
    WOMENS_IMPERIAL_WAR_RESERVES_JACKET  : 46668,
    WOMENS_IMPRESS_SKIRT_BLACK_LEATHER  : 4033,
    WOMENS_IMPRESS_SKIRT_BLACK_WAX  : 3999,
    WOMENS_IMPRESS_SKIRT_BROWN_LEATHER  : 4034,
    WOMENS_IMPRESS_SKIRT_GRAPHITE  : 4035,
    WOMENS_IMPRESS_SKIRT_GRAY  : 3998,
    WOMENS_IMPRESS_SKIRT_GREEN_GOLD  : 4036,
    WOMENS_IMPRESS_SKIRT_MARINE  : 4069,
    WOMENS_IMPRESS_SKIRT_MATTE_BLACK  : 4070,
    WOMENS_IMPRESS_SKIRT_MATTE_BLUE  : 4071,
    WOMENS_IMPRESS_SKIRT_MATTE_RED  : 4072,
    WOMENS_IMPRESS_SKIRT_RED_GOLD  : 4073,
    WOMENS_IMPRESS_SKIRT_SILVER  : 4074,
    WOMENS_IMPRESS_SKIRT_WHITE  : 4075,
    WOMENS_INTERSTELLAR_CONVERGENCE_T_SHIRT  : 61532,
    WOMENS_ISHUKONE_CREW_JACKET  : 70848,
    WOMENS_JITA_4_4_CREW_PANTS  : 56846,
    WOMENS_JITA_4_4_CREW_SHOES  : 56844,
    WOMENS_JITA_4_4_CREW_TOP  : 56845,
    WOMENS_JITA_4_4_EXECUTIVE_JACKET  : 56852,
    WOMENS_JITA_4_4_EXECUTIVE_PANTS  : 56853,
    WOMENS_KAALAKIOTA_CREW_JACKET  : 70851,
    WOMENS_KHALIKOS_VECTOR_FACIAL_AUGMENTATION  : 49674,
    WOMENS_KIMOTORO_ESKEITAN_COMMANDERS_JACKET  : 63749,
    WOMENS_KIMOTORO_ESKEITAN_COMMANDERS_PANTS  : 63750,
    WOMENS_KYBERNAUT_CLADE_BERET  : 56209,
    WOMENS_KYBERNAUT_CLADE_T_SHIRT  : 56211,
    WOMENS_LAI_DAI_CREW_JACKET  : 70853,
    WOMENS_LIBERATOR_BERET_MINMATAR_REPUBLIC  : 42738,
    WOMENS_LIBERATOR_CAP_MINMATAR_REPUBLIC  : 42715,
    WOMENS_LUXURY_T_SHIRT  : 33487,
    WOMENS_MINIMA_HEELS  : 4002,
    WOMENS_MINIMA_HEELS_BLACK_GOLD  : 4116,
    WOMENS_MINIMA_HEELS_BLACK_RED  : 4117,
    WOMENS_MINIMA_HEELS_BLUE  : 4118,
    WOMENS_MINIMA_HEELS_GOLD  : 4119,
    WOMENS_MINIMA_HEELS_GRAPHITE_WHITE  : 4120,
    WOMENS_MINIMA_HEELS_GREEN_BLACK  : 4121,
    WOMENS_MINIMA_HEELS_MATTE_BLACK  : 4122,
    WOMENS_MINIMA_HEELS_MATTE_RED  : 4123,
    WOMENS_MINIMA_HEELS_RED  : 4124,
    WOMENS_MINIMA_HEELS_SILVER  : 4125,
    WOMENS_MINIMA_HEELS_TURQUOISE  : 4126,
    WOMENS_MINMATAR_FREEDOM_FIGHTER_CAP  : 64271,
    WOMENS_MINMATAR_FREEDOM_FIGHTER_T_SHIRT  : 64269,
    WOMENS_MINMATAR_LIBERATOR_T_SHIRT  : 47187,
    WOMENS_MINMATAR_REBELLION_CAP  : 64202,
    WOMENS_MINMATAR_REBELLION_T_SHIRT  : 64203,
    WOMENS_MINMATAR_REPUBLIC_EXPLORATION_SUIT  : 45516,
    WOMENS_MINMATAR_REPUBLIC_OFFICER_JACKET  : 46673,
    WOMENS_MIRELLE_PANTS_UPWELL  : 40525,
    WOMENS_MIRELLE_T_SHIRT_UPWELL  : 40527,
    WOMENS_MIRELLE_UPWELL_EXECUTIVE_COAT  : 40523,
    WOMENS_MIRELLE_UPWELL_EXECUTIVE_SPECTACLES  : 41575,
    WOMENS_MISTBORNE_RIME_AUGMENTATION  : 49985,
    WOMENS_MITRAL_BOOTS_BLACK  : 33714,
    WOMENS_MITRAL_BOOTS_BRONZE  : 33716,
    WOMENS_MITRAL_BOOTS_CREAM  : 33717,
    WOMENS_MITRAL_BOOTS_DARK_BLUE  : 33718,
    WOMENS_MITRAL_BOOTS_DARK_RED  : 33719,
    WOMENS_MYSTRIOSO_BOOTS  : 4004,
    WOMENS_MYSTRIOSO_BOOTS_BLACK_WHITE  : 4132,
    WOMENS_MYSTRIOSO_BOOTS_BROWN_BLACK  : 4133,
    WOMENS_MYSTRIOSO_BOOTS_RED  : 4134,
    WOMENS_MYSTRIOSO_BOOTS_WHITE_BLACK  : 4135,
    WOMENS_NEW_EDEN_OPEN_I_T_SHIRT_YC_114  : 33767,
    WOMENS_NEW_EDEN_OPEN_II_T_SHIRT_YC_116  : 33768,
    WOMENS_NUGOEIHUVI_CREW_JACKET  : 70855,
    WOMENS_OBSIDIAN_OPULENCE_JACKET  : 54359,
    WOMENS_OBSIDIAN_OPULENCE_PANTS  : 54365,
    WOMENS_OCULINA_CARMINA_FACIAL_AUGMENTATION  : 53385,
    WOMENS_OCULINA_SEMIOTIQUE_FACIAL_AUGMENTATION  : 60169,
    WOMENS_OPENYAGI_FACIAL_AUGMENTATION  : 41053,
    WOMENS_ORB_SEMIOTIQUE_SYNTHETIC_EYE_RIGHT  : 60166,
    WOMENS_ORE_CLAIM_SURVEYOR_SUIT  : 45520,
    WOMENS_OUTLAW_BOOTS_BLOOD_RAIDERS  : 34363,
    WOMENS_OUTLAW_BOOTS_GURISTAS  : 34365,
    WOMENS_OUTLAW_BOOTS_SANSHAS_NATION  : 34364,
    WOMENS_OUTLAW_COAT_BLOOD_RAIDERS  : 34353,
    WOMENS_OUTLAW_COAT_BLOOD_RAIDERS_EXTRA_RED  : 42706,
    WOMENS_OUTLAW_COAT_BURNT_ORANGE  : 34394,
    WOMENS_OUTLAW_COAT_GURISTAS  : 34351,
    WOMENS_OUTLAW_COAT_SANSHAS_NATION  : 34350,
    WOMENS_OUTLAW_PANTS_BLOOD_RAIDERS  : 34357,
    WOMENS_OUTLAW_PANTS_GURISTAS  : 34359,
    WOMENS_OUTLAW_PANTS_SANSHAS_NATION  : 34358,
    WOMENS_PARADOXICAL_SCARF  : 61535,
    WOMENS_PERMABAND_TOUR_CAP  : 50132,
    WOMENS_PERMABAND_TOUR_T_SHIRT  : 50130,
    WOMENS_PHANCA_CYBERNETIC_ARM_LEFT  : 33549,
    WOMENS_PHANCA_CYBERNETIC_ARM_RIGHT  : 33550,
    WOMENS_PITHUM_BERET_GURISTAS  : 42717,
    WOMENS_POISE_PANTS_BEIGE_SWIRL  : 33746,
    WOMENS_POISE_PANTS_BLACK  : 33745,
    WOMENS_POISE_PANTS_BLUE  : 33751,
    WOMENS_POISE_PANTS_CREAM  : 33747,
    WOMENS_POISE_PANTS_DARK_GREEN  : 33748,
    WOMENS_POISE_PANTS_DARK_SWIRL  : 33749,
    WOMENS_POISE_PANTS_GLOSSY_BLACK  : 33744,
    WOMENS_POISE_PANTS_GLOSSY_BRONZE  : 33750,
    WOMENS_POISE_PANTS_GLOSSY_RED  : 33753,
    WOMENS_POISE_PANTS_RED  : 33752,
    WOMENS_POLAR_AURORA_EXPLORATION_SUIT  : 53357,
    WOMENS_PROJECT_DISCOVERY_CYTOMETRY_CAP  : 54949,
    WOMENS_PROJECT_DISCOVERY_CYTOMETRY_T_SHIRT  : 54944,
    WOMENS_PROPER_DAPPER_OUTFIT  : 61848,
    WOMENS_PROTECTOR_BERET_CALDARI_STATE  : 42740,
    WOMENS_PROVEN_BIOADAPTIVE_MASK  : 63138,
    WOMENS_PROVEN_KYBERNAUT_COMBAT_SUIT  : 55856,
    WOMENS_PURITY_VECTOR_FACIAL_AUGMENTATION  : 55627,
    WOMENS_QUAFE_T_SHIRT_YC_113  : 4066,
    WOMENS_QUAFE_T_SHIRT_YC_114  : 4366,
    WOMENS_QUAFE_T_SHIRT_YC_115  : 33109,
    WOMENS_QUAFE_T_SHIRT_YC_116  : 33813,
    WOMENS_QUAFETHRON_T_SHIRT  : 34211,
    WOMENS_RADIOACTIVES_RECLAMATION_GOGGLES  : 60645,
    WOMENS_RADIOACTIVES_RECLAMATION_SUIT  : 60655,
    WOMENS_RED_STAR_T_SHIRT  : 33063,
    WOMENS_REPUBLIC_COMMANDERS_DRESS_JACKET  : 58944,
    WOMENS_REPUBLIC_COMMANDERS_DRESS_PANTS  : 58949,
    WOMENS_REPUBLIC_FLEET_ORDNANCE_JACKET  : 46661,
    WOMENS_REPUBLIC_SERVICE_TATTOO_BRUTOR  : 59223,
    WOMENS_REPUBLIC_SERVICE_TATTOO_KRUSUAL  : 59229,
    WOMENS_REPUBLIC_SERVICE_TATTOO_NEFANTAR  : 59233,
    WOMENS_REPUBLIC_SERVICE_TATTOO_SEBIESTOR  : 59231,
    WOMENS_REPUBLIC_SERVICE_TATTOO_STARKMANIR  : 59202,
    WOMENS_REPUBLIC_SERVICE_TATTOO_THUKKER  : 59200,
    WOMENS_REPUBLIC_SERVICE_TATTOO_VHEROKIOR  : 59227,
    WOMENS_REPUBLIC_STARGATE_CREW_PANTS  : 58788,
    WOMENS_REPUBLIC_STARGATE_CREW_SHOES  : 58820,
    WOMENS_REPUBLIC_STARGATE_CREW_TOP  : 58812,
    WOMENS_REPUBLIC_VALKLEAR_BERET  : 55754,
    WOMENS_REPUBLIC_VALKLEAR_JACKET  : 59180,
    WOMENS_REPUBLIC_VALKLEAR_PANTS  : 59181,
    WOMENS_ROCKET_DRESS_BLACK  : 33734,
    WOMENS_ROCKET_DRESS_BLUE  : 33735,
    WOMENS_ROCKET_DRESS_COPPER  : 33736,
    WOMENS_ROCKET_DRESS_DARK_GREY  : 33737,
    WOMENS_ROCKET_DRESS_DARK_TEAL  : 33743,
    WOMENS_ROCKET_DRESS_FESTIVE  : 37816,
    WOMENS_ROCKET_DRESS_GLOSSY_BLACK  : 33733,
    WOMENS_ROCKET_DRESS_GREEN  : 33739,
    WOMENS_ROCKET_DRESS_METAL  : 33738,
    WOMENS_ROCKET_DRESS_PURPLE  : 33740,
    WOMENS_ROCKET_DRESS_RED  : 33741,
    WOMENS_ROCKET_DRESS_WHITE  : 33742,
    WOMENS_RUBEDO_RICHESSE_JACKET  : 54363,
    WOMENS_RUBEDO_RICHESSE_PANTS  : 54369,
    WOMENS_RUBYSET_TORSORIG_BODY_AUGMENTATION  : 53229,
    WOMENS_SABLE_CAP_BLACK  : 42734,
    WOMENS_SAIKADORI_FACIAL_AUGMENTATION  : 56854,
    WOMENS_SANCTITY_DRESS_BLACK_GOLD  : 33726,
    WOMENS_SANCTITY_DRESS_BLUE_SILVER  : 33728,
    WOMENS_SANCTITY_DRESS_BROWN_GOLD  : 33727,
    WOMENS_SANCTITY_DRESS_CREAM_GOLD  : 33715,
    WOMENS_SANCTITY_DRESS_GREEN_GOLD  : 33729,
    WOMENS_SANCTITY_DRESS_OLIVE_GOLD  : 33731,
    WOMENS_SANCTITY_DRESS_RED_GOLD  : 33730,
    WOMENS_SANCTITY_DRESS_RED_SILVER  : 33732,
    WOMENS_SANGUINE_HARVEST_PANTS  : 60434,
    WOMENS_SANGUINE_HARVEST_SHOES  : 58821,
    WOMENS_SANGUINE_HARVEST_T_SHIRT  : 60436,
    WOMENS_SARO_BLACK_TROOP_COMBAT_SUIT  : 47287,
    WOMENS_SCOPE_NETWORK_SPECIAL_CORRESPONDENT_SUIT  : 37863,
    WOMENS_SEMIOTIQUE_SPORTS_PANTS  : 60167,
    WOMENS_SEMIOTIQUE_SPORTS_SHOES  : 60168,
    WOMENS_SEMIOTIQUE_SUPERLUMINAL_JACKET  : 63052,
    WOMENS_SEMIOTIQUE_SUPERLUMINAL_PANTS  : 63055,
    WOMENS_SEMIOTIQUE_SUPERLUMINAL_SHADES  : 63061,
    WOMENS_SEMIOTIQUE_TORSORIG_BODY_AUGMENTATION  : 60170,
    WOMENS_SERPENTIS_T_SHIRT_OLIVE_BLACK  : 37865,
    WOMENS_SHADOWGLAM_FACIAL_AUGMENTATION  : 49668,
    WOMENS_SILVER_SUPREMACY_JACKET  : 54362,
    WOMENS_SILVER_SUPREMACY_PANTS  : 54368,
    WOMENS_SISTERS_OF_EVE_ADVANCED_COMBAT_SUIT  : 37603,
    WOMENS_SOE_ENHANCED_BIOSECURITY_MASK  : 54715,
    WOMENS_SOE_EXPEDITIONARY_SUIT  : 45514,
    WOMENS_SOURCE_COAT_SILVER  : 33804,
    WOMENS_SPARKGAP_FACIAL_AUGMENTATION  : 41052,
    WOMENS_SPIDERWEB_HEADWEAR_BLACK  : 34068,
    WOMENS_SPIDERWEB_HEADWEAR_COPPER  : 34072,
    WOMENS_SPIDERWEB_HEADWEAR_GOLDEN  : 34077,
    WOMENS_SPIDERWEB_HEADWEAR_METALLIC  : 34073,
    WOMENS_STATE_COMMANDERS_DRESS_JACKET  : 58941,
    WOMENS_STATE_COMMANDERS_DRESS_PANTS  : 58946,
    WOMENS_STATE_MILITARY_STOCKPILE_JACKET  : 46671,
    WOMENS_STATE_PROTECTOR_BERET  : 55752,
    WOMENS_STATE_PROTECTOR_JACKET  : 59407,
    WOMENS_STATE_PROTECTOR_PANTS  : 59408,
    WOMENS_STATE_STARGATE_CREW_PANTS  : 58786,
    WOMENS_STATE_STARGATE_CREW_SHOES  : 58818,
    WOMENS_STATE_STARGATE_CREW_TOP  : 58810,
    WOMENS_STEELKNIGHT_TORSORIG_BODY_AUGMENTATION  : 53226,
    WOMENS_STERLING_DRESS_BLOUSE_BLACK  : 4061,
    WOMENS_STERLING_DRESS_BLOUSE_BLACK_LEATHER  : 4155,
    WOMENS_STERLING_DRESS_BLOUSE_BLACK_WHITE  : 4156,
    WOMENS_STERLING_DRESS_BLOUSE_BLOOD_RAIDER  : 42707,
    WOMENS_STERLING_DRESS_BLOUSE_DUST  : 4063,
    WOMENS_STERLING_DRESS_BLOUSE_GOLD  : 4157,
    WOMENS_STERLING_DRESS_BLOUSE_GRAPHITE  : 4158,
    WOMENS_STERLING_DRESS_BLOUSE_GREEN_SATIN  : 4159,
    WOMENS_STERLING_DRESS_BLOUSE_MATTE_BLACK  : 4160,
    WOMENS_STERLING_DRESS_BLOUSE_MATTE_BLUE  : 4161,
    WOMENS_STERLING_DRESS_BLOUSE_MATTE_OLIVE  : 4162,
    WOMENS_STERLING_DRESS_BLOUSE_NAVY  : 4062,
    WOMENS_STERLING_DRESS_BLOUSE_OLIVE  : 4064,
    WOMENS_STERLING_DRESS_BLOUSE_ORANGE_SATIN  : 4163,
    WOMENS_STERLING_DRESS_BLOUSE_PLATINUM  : 4065,
    WOMENS_STERLING_DRESS_BLOUSE_RED_SATIN  : 4164,
    WOMENS_STERLING_DRESS_BLOUSE_WHITE  : 4368,
    WOMENS_STRUCTURE_DRESS_BLACK  : 4143,
    WOMENS_STRUCTURE_DRESS_BLACK_WHITE  : 4144,
    WOMENS_STRUCTURE_DRESS_BROWN  : 4145,
    WOMENS_STRUCTURE_DRESS_GOLD_BLACK  : 4146,
    WOMENS_STRUCTURE_DRESS_GRAPHITE  : 4149,
    WOMENS_STRUCTURE_DRESS_GREEN  : 4150,
    WOMENS_STRUCTURE_DRESS_MATTE_BLUE  : 4151,
    WOMENS_STRUCTURE_DRESS_MATTE_RED  : 4152,
    WOMENS_STRUCTURE_DRESS_NAVY  : 3975,
    WOMENS_STRUCTURE_DRESS_RED  : 4153,
    WOMENS_STRUCTURE_DRESS_TURQUOISE  : 4154,
    WOMENS_STRUCTURE_SKIRT_BLACK  : 4085,
    WOMENS_STRUCTURE_SKIRT_BLACK_RED  : 4076,
    WOMENS_STRUCTURE_SKIRT_BLACK_WHITE  : 4101,
    WOMENS_STRUCTURE_SKIRT_BLUE  : 4102,
    WOMENS_STRUCTURE_SKIRT_CAMOUFLAGE  : 4078,
    WOMENS_STRUCTURE_SKIRT_GRAPHITE  : 4103,
    WOMENS_STRUCTURE_SKIRT_GRAY  : 4104,
    WOMENS_STRUCTURE_SKIRT_GRAY_STRIPES  : 4105,
    WOMENS_STRUCTURE_SKIRT_GREEN  : 4106,
    WOMENS_STRUCTURE_SKIRT_GREEN_BLACK  : 4107,
    WOMENS_STRUCTURE_SKIRT_GREEN_STRIPES  : 4108,
    WOMENS_STRUCTURE_SKIRT_KHAKI  : 4109,
    WOMENS_STRUCTURE_SKIRT_MARINE  : 4110,
    WOMENS_STRUCTURE_SKIRT_MATTE_BLACK  : 4111,
    WOMENS_STRUCTURE_SKIRT_RED  : 4112,
    WOMENS_STRUCTURE_SKIRT_RED_LEATHER  : 4113,
    WOMENS_STRUCTURE_SKIRT_RED_STRIPES  : 4114,
    WOMENS_STRUCTURE_SKIRT_WHITE_STRIPES  : 4115,
    WOMENS_STRUT_PANTS_BLACK  : 33754,
    WOMENS_STRUT_PANTS_BLUE  : 33755,
    WOMENS_STRUT_PANTS_CAMO  : 33756,
    WOMENS_STRUT_PANTS_MATTE_GRAY  : 33757,
    WOMENS_STRUT_PANTS_ORANGE  : 33758,
    WOMENS_STRUT_PANTS_RED  : 33759,
    WOMENS_STRUT_PANTS_RED_GLOSS  : 33760,
    WOMENS_STRUT_PANTS_YELLOW_GLOSS  : 33761,
    WOMENS_SUKUUVESTAA_CREW_JACKET  : 70854,
    WOMENS_TRIGLAVIAN_SURVIVAL_SUIT_BIOADAPTIVE  : 48081,
    WOMENS_TRIGLAVIAN_SURVIVAL_SUIT_ENCOUNTER  : 48386,
    WOMENS_TRIGLAVIAN_SURVIVAL_SUIT_LIMINALITY  : 56225,
    WOMENS_TRIGLAVIAN_SURVIVAL_SUIT_PERUN  : 48382,
    WOMENS_TRIGLAVIAN_SURVIVAL_SUIT_PROVING_GROUNDS  : 55855,
    WOMENS_TRIGLAVIAN_SURVIVAL_SUIT_SVAROG  : 48381,
    WOMENS_TRIGLAVIAN_SURVIVAL_SUIT_VELES  : 48380,
    WOMENS_TRIPOINT_FACIAL_AUGMENTATION  : 41048,
    WOMENS_VALKYRIE_FLIGHT_JACKET  : 46273,
    WOMENS_VIRTUALITY_BIOTECHNICIAN_COAT  : 40460,
    WOMENS_VISE_CYBERNETIC_ARM_BLACK_AND_ORANGE_RINGED_LEFT  : 34018,
    WOMENS_VISE_CYBERNETIC_ARM_BLACK_AND_ORANGE_RINGED_RIGHT  : 34032,
    WOMENS_VISE_CYBERNETIC_ARM_BLACK_AND_YELLOW_LEFT  : 34020,
    WOMENS_VISE_CYBERNETIC_ARM_BLACK_AND_YELLOW_RIGHT  : 34034,
    WOMENS_VISE_CYBERNETIC_ARM_BLACK_RINGED_LEFT  : 34019,
    WOMENS_VISE_CYBERNETIC_ARM_BLACK_RINGED_RIGHT  : 34033,
    WOMENS_VISE_CYBERNETIC_ARM_BLUE_AND_BLACK_RINGED_LEFT  : 34021,
    WOMENS_VISE_CYBERNETIC_ARM_BLUE_AND_BLACK_RINGED_RIGHT  : 34035,
    WOMENS_VISE_CYBERNETIC_ARM_BLUE_AND_WHITE_LEFT  : 34022,
    WOMENS_VISE_CYBERNETIC_ARM_BLUE_AND_WHITE_RIGHT  : 34036,
    WOMENS_VISE_CYBERNETIC_ARM_MATTE_BLACK_LEFT  : 34017,
    WOMENS_VISE_CYBERNETIC_ARM_MATTE_BLACK_RIGHT  : 34031,
    WOMENS_VISE_CYBERNETIC_ARM_WHITE_AND_GRAY_RINGED_LEFT  : 34023,
    WOMENS_VISE_CYBERNETIC_ARM_WHITE_AND_GRAY_RINGED_RIGHT  : 34037,
    WOMENS_WAVEFORM_FACIAL_AUGMENTATION  : 41051,
    WOMENS_WIYRKOMI_CREW_JACKET  : 70852,
    WOMENS_YC119_YOIUL_FESTIVAL_CAP  : 44122,
    WOMENS_YC119_YOIUL_FESTIVAL_PANTS  : 44121,
    WOMENS_YC119_YOIUL_FESTIVAL_T_SHIRT  : 44120,
    WOMENS_YOIUL_STORMCHASERS_CAP  : 57079,
    WOMENS_YOIUL_STORMCHASERS_PANTS  : 57078,
    WOMENS_YOIUL_STORMCHASERS_SUIT  : 57080,
    WOMENS_YOIUL_STORMCHASERS_T_SHIRT  : 57077,
    WOMENS_ZAKURA_ZAKITSU_EXPLORATION_SUIT  : 56857,
    WORM  : 17930,
    WORM_ACE_OF_PODHUNTERS_SKIN  : 47509,
    WORM_AETHERIC_RAIDERS_SKIN  : 52703,
    WORM_GLACIAL_DRIFT_SKIN  : 46873,
    WORM_HELMATTS_HARRIERS_SKIN  : 54229,
    WORM_HOSTILE_TAKEOVER_SKIN  : 45507,
    WORM_HUNTERS_QUIVER_SKIN  : 57781,
    WORM_IGC_SKIN  : 48552,
    WORM_VAMPIRES_HEX_SKIN  : 57813,
    WORM_VERSUS_REDFORCE_SKIN  : 63643,
    WREATHE  : 653,
    WREATHE_BIOSECURITY_RESPONDERS_SKIN  : 55658,
    WREATHE_BLUE_TIGER_SKIN  : 36840,
    WREATHE_BLUEPRINT  : 988,
    WREATHE_EXOPLANETS_HUNTER_SKIN  : 45986,
    WREATHE_FLEET_ORDNANCE_SPIKE_SKIN  : 64909,
    WREATHE_GLACIAL_DRIFT_SKIN  : 46923,
    WREATHE_HAZARD_CONTROL_SKIN  : 56933,
    WREATHE_NEFANTAR_SKIN  : 55817,
    WREATHE_SNOWLINE_BLADERACER_SKIN  : 52897,
    WREATHE_TRONHADAR_INK_SKIN  : 40511,
    WREATHE_VALKLEAR_GLORY_SKIN  : 36793,
    WRECKED_ARMOR_NANOBOT  : 30618,
    WRECKED_HULL_SECTION  : 30754,
    WRECKED_POWER_CORES  : 30588,
    WRECKED_THRUSTER_SECTIONS  : 30562,
    WRECKED_WEAPON_SUBROUTINES  : 30633,
    WYVERN  : 23917,
    WYVERN_BIOSECURITY_RESPONDERS_SKIN  : 55519,
    WYVERN_BLUE_TIGER_SKIN  : 36743,
    WYVERN_BLUEPRINT  : 23918,
    WYVERN_CAPSULEER_DAY_XV_SKIN  : 47630,
    WYVERN_EROS_BLOSSOM_SKIN  : 47291,
    WYVERN_EXOPLANETS_HUNTER_SKIN  : 45861,
    WYVERN_GHOSTBIRD_SKIN  : 48204,
    WYVERN_GLACIAL_DRIFT_SKIN  : 46865,
    WYVERN_HEADHUNTER_SKIN  : 49424,
    WYVERN_INTERSTELLAR_CONVERGENCE_SKIN  : 62226,
    WYVERN_KIMOTORO_ESKEITAN_SKIN  : 52642,
    WYVERN_MATIGU_SEABEAST_SKIN  : 40619,
    WYVERN_RAATA_SUNSET_SKIN  : 36671,
    WYVERN_STEEL_CARDINAL_SKIN  : 46138,
    WYVERN_ULTRAVIOLENCE_VORTEX_SKIN  : 58799,
    WYVERN_WIYRKOMI_SKIN  : 34652,
    WYVERN_ZENTO_ISIDEKO_COMBINE_SKIN  : 53198,
    X_INSTINCT  : 3711,
    X_LARGE_ANCILLARY_SHIELD_BOOSTER  : 32780,
    X_LARGE_C5_L_COMPACT_SHIELD_BOOSTER  : 10888,
    X_LARGE_CLARITY_WARD_ENDURING_SHIELD_BOOSTER  : 10884,
    X_LARGE_LOCOMOTIVE_SHIELD_BOOSTER  : 20623,
    X_LARGE_SHIELD_BOOSTER_I  : 10840,
    X_LARGE_SHIELD_BOOSTER_I_BLUEPRINT  : 10841,
    X_LARGE_SHIELD_BOOSTER_II  : 10842,
    X_LARGE_SHIP_MAINTENANCE_ARRAY  : 24646,
    X_LARGE_SHIP_MAINTENANCE_ARRAY_BLUEPRINT  : 2750,
    X5_ENDURING_STASIS_WEBIFIER  : 4025,
    XENOTIME  : 45510,
    XL_CRUISE_MISSILE_LAUNCHER_I  : 32444,
    XL_CRUISE_MISSILE_LAUNCHER_I_BLUEPRINT  : 32445,
    XL_CRUISE_MISSILE_LAUNCHER_II  : 37295,
    XL_CRUISE_MISSILE_SPECIALIZATION  : 41410,
    XL_CRUISE_MISSILES  : 32435,
    XL_TORPEDO_BATTERY  : 17773,
    XL_TORPEDO_BATTERY_BLUEPRINT  : 2822,
    XL_TORPEDO_LAUNCHER_I  : 20539,
    XL_TORPEDO_LAUNCHER_I_BLUEPRINT  : 20540,
    XL_TORPEDO_LAUNCHER_II  : 37294,
    XL_TORPEDO_SPECIALIZATION  : 41409,
    XL_TORPEDOES  : 21668,
    XR_3200_HEAVY_MISSILE_BAY  : 7997,
    XRAY_L  : 260,
    XRAY_L_BLUEPRINT  : 1175,
    XRAY_M  : 252,
    XRAY_M_BLUEPRINT  : 1167,
    XRAY_S  : 244,
    XRAY_S_BLUEPRINT  : 1159,
    XRAY_XL  : 17694,
    XRAY_XL_BLUEPRINT  : 17695,
    XT_9000_CRUISE_LAUNCHER  : 16517,
    XV_ANNIVERSARY_GIFT_BOX_ALPHA  : 47962,
    XV_ANNIVERSARY_GIFT_BOX_OMEGA  : 47963,
    YAN_JUNG_CRYSTAL_CYLINDER  : 23128,
    YAN_JUNG_GLASS_SCALE  : 23139,
    YAN_JUNG_INFO_MATRIX  : 23168,
    YAN_JUNG_NANO_FABRIC  : 23142,
    YAN_JUNG_NULL_SHELL  : 23138,
    YAN_JUNG_PARADOX_BOX  : 23129,
    YAN_JUNG_PLENARY_WIRE  : 23140,
    YAN_JUNG_SEMIOTIC_THEORY  : 23171,
    YAN_JUNG_SILK_ARMOR  : 23141,
    YAN_JUNG_SINGULARITY_FACT_SHEET  : 23172,
    YAN_JUNG_TACHYON_STETOSCOPE  : 23132,
    YAN_JUNG_TECHNOLOGY  : 23124,
    YAN_JUNG_THUNDER_KITE  : 23130,
    YAN_JUNG_TRIGONOMETRIC_LAWS  : 23170,
    YAN_JUNG_VELLUM_ETCH  : 23169,
    YAN_JUNG_VOID_MACHINE  : 23131,
    YC117_SYMPOSIUM_ON_EMERGENT_THREATS_ACCESS_PASS  : 37882,
    YC118_SUCCESSION_TRIAL_CHAMPIONS_DECREE___HOUSE_ARDISHAPUR  : 37892,
    YC118_SUCCESSION_TRIAL_CHAMPIONS_DECREE___HOUSE_KADOR  : 37893,
    YC118_SUCCESSION_TRIAL_CHAMPIONS_DECREE___HOUSE_KHANID  : 37894,
    YC118_SUCCESSION_TRIAL_CHAMPIONS_DECREE___HOUSE_KOR_AZOR  : 37895,
    YC118_SUCCESSION_TRIAL_CHAMPIONS_DECREE___HOUSE_SARUM  : 37896,
    YC118_SUCCESSION_TRIAL_CHAMPIONS_DECREE___HOUSE_TASH_MURKON  : 37889,
    YC121_GUARDIANS_GALA_TICKET  : 50039,
    YENI_SARUMS_INSIGNIA  : 15637,
    YO_5000_RAPID_HEAVY_MISSILE_LAUNCHER  : 33466,
    YOIUL_BLIZZARD_FIREWORK  : 47302,
    YOIUL_FESTIVAL_FIREWORK  : 44110,
    YOIUL_FESTIVAL_FIREWORKS_PACKAGE  : 46825,
    YOIUL_FESTIVAL_SNOWBALL  : 53343,
    YOIUL_FESTIVAL_STARBURST_FIREWORK  : 53461,
    YOIUL_FESTIVAL_YC120_COIN_AMARR  : 47060,
    YOIUL_FESTIVAL_YC120_COIN_CALDARI  : 47063,
    YOIUL_FESTIVAL_YC120_COIN_GALLENTE  : 47064,
    YOIUL_FESTIVAL_YC120_COIN_MINMATAR  : 47062,
    YOIUL_SPIRAL_FIREWORK  : 60905,
    YOIUL_STORMCHASERS_SUIT_AND_CAP_CRATE  : 57170,
    YOIUL_STORMCHASERS_T_SHIRT_AND_PANTS_CRATE  : 57168,
    YOIULS_ASCENT_FIREWORK  : 74386,
    YOIULS_ASCENT_FIREWORK_CRATE  : 74387,
    YTIRIUM  : 74525,
    YTTERBITE  : 45513,
    YURT_MOBILE_DEPOT  : 33522,
    ZAINOU_BEANCOUNTER_INDUSTRY_BX_801  : 27170,
    ZAINOU_BEANCOUNTER_INDUSTRY_BX_802  : 27167,
    ZAINOU_BEANCOUNTER_INDUSTRY_BX_804  : 27171,
    ZAINOU_BEANCOUNTER_METALLURGY_MY_701  : 27182,
    ZAINOU_BEANCOUNTER_METALLURGY_MY_703  : 27176,
    ZAINOU_BEANCOUNTER_METALLURGY_MY_705  : 27181,
    ZAINOU_BEANCOUNTER_REPROCESSING_RX_801  : 27175,
    ZAINOU_BEANCOUNTER_REPROCESSING_RX_802  : 27169,
    ZAINOU_BEANCOUNTER_REPROCESSING_RX_804  : 27174,
    ZAINOU_BEANCOUNTER_RESEARCH_RR_601  : 27180,
    ZAINOU_BEANCOUNTER_RESEARCH_RR_603  : 27177,
    ZAINOU_BEANCOUNTER_RESEARCH_RR_605  : 27179,
    ZAINOU_BEANCOUNTER_SCIENCE_SC_801  : 27185,
    ZAINOU_BEANCOUNTER_SCIENCE_SC_803  : 27178,
    ZAINOU_BEANCOUNTER_SCIENCE_SC_805  : 27184,
    ZAINOU_DEADEYE_GUIDED_MISSILE_PRECISION_GP_801  : 27094,
    ZAINOU_DEADEYE_GUIDED_MISSILE_PRECISION_GP_802  : 3125,
    ZAINOU_DEADEYE_GUIDED_MISSILE_PRECISION_GP_803  : 24632,
    ZAINOU_DEADEYE_GUIDED_MISSILE_PRECISION_GP_804  : 3126,
    ZAINOU_DEADEYE_GUIDED_MISSILE_PRECISION_GP_805  : 24640,
    ZAINOU_DEADEYE_GUIDED_MISSILE_PRECISION_GP_806  : 3127,
    ZAINOU_DEADEYE_LARGE_HYBRID_TURRET_LH_1001  : 27089,
    ZAINOU_DEADEYE_LARGE_HYBRID_TURRET_LH_1002  : 3222,
    ZAINOU_DEADEYE_LARGE_HYBRID_TURRET_LH_1003  : 13218,
    ZAINOU_DEADEYE_LARGE_HYBRID_TURRET_LH_1004  : 3223,
    ZAINOU_DEADEYE_LARGE_HYBRID_TURRET_LH_1005  : 19698,
    ZAINOU_DEADEYE_LARGE_HYBRID_TURRET_LH_1006  : 3224,
    ZAINOU_DEADEYE_MEDIUM_HYBRID_TURRET_MH_801  : 27086,
    ZAINOU_DEADEYE_MEDIUM_HYBRID_TURRET_MH_802  : 3231,
    ZAINOU_DEADEYE_MEDIUM_HYBRID_TURRET_MH_803  : 13221,
    ZAINOU_DEADEYE_MEDIUM_HYBRID_TURRET_MH_804  : 3232,
    ZAINOU_DEADEYE_MEDIUM_HYBRID_TURRET_MH_805  : 19699,
    ZAINOU_DEADEYE_MEDIUM_HYBRID_TURRET_MH_806  : 3233,
    ZAINOU_DEADEYE_MISSILE_BOMBARDMENT_MB_701  : 27092,
    ZAINOU_DEADEYE_MISSILE_BOMBARDMENT_MB_702  : 3128,
    ZAINOU_DEADEYE_MISSILE_BOMBARDMENT_MB_703  : 13247,
    ZAINOU_DEADEYE_MISSILE_BOMBARDMENT_MB_704  : 3129,
    ZAINOU_DEADEYE_MISSILE_BOMBARDMENT_MB_705  : 24636,
    ZAINOU_DEADEYE_MISSILE_BOMBARDMENT_MB_706  : 3132,
    ZAINOU_DEADEYE_MISSILE_PROJECTION_MP_701  : 27093,
    ZAINOU_DEADEYE_MISSILE_PROJECTION_MP_702  : 3133,
    ZAINOU_DEADEYE_MISSILE_PROJECTION_MP_703  : 13248,
    ZAINOU_DEADEYE_MISSILE_PROJECTION_MP_704  : 3134,
    ZAINOU_DEADEYE_MISSILE_PROJECTION_MP_705  : 24637,
    ZAINOU_DEADEYE_MISSILE_PROJECTION_MP_706  : 3135,
    ZAINOU_DEADEYE_RAPID_LAUNCH_RL_1001  : 27096,
    ZAINOU_DEADEYE_RAPID_LAUNCH_RL_1002  : 3136,
    ZAINOU_DEADEYE_RAPID_LAUNCH_RL_1003  : 13249,
    ZAINOU_DEADEYE_RAPID_LAUNCH_RL_1004  : 3137,
    ZAINOU_DEADEYE_RAPID_LAUNCH_RL_1005  : 24638,
    ZAINOU_DEADEYE_RAPID_LAUNCH_RL_1006  : 3140,
    ZAINOU_DEADEYE_SHARPSHOOTER_ST_901  : 27076,
    ZAINOU_DEADEYE_SHARPSHOOTER_ST_902  : 3217,
    ZAINOU_DEADEYE_SHARPSHOOTER_ST_903  : 10204,
    ZAINOU_DEADEYE_SHARPSHOOTER_ST_904  : 3234,
    ZAINOU_DEADEYE_SHARPSHOOTER_ST_905  : 19696,
    ZAINOU_DEADEYE_SHARPSHOOTER_ST_906  : 3235,
    ZAINOU_DEADEYE_SMALL_HYBRID_TURRET_SH_601  : 27083,
    ZAINOU_DEADEYE_SMALL_HYBRID_TURRET_SH_602  : 3225,
    ZAINOU_DEADEYE_SMALL_HYBRID_TURRET_SH_603  : 13224,
    ZAINOU_DEADEYE_SMALL_HYBRID_TURRET_SH_604  : 3226,
    ZAINOU_DEADEYE_SMALL_HYBRID_TURRET_SH_605  : 19700,
    ZAINOU_DEADEYE_SMALL_HYBRID_TURRET_SH_606  : 3227,
    ZAINOU_DEADEYE_TARGET_NAVIGATION_PREDICTION_TN_901  : 27095,
    ZAINOU_DEADEYE_TARGET_NAVIGATION_PREDICTION_TN_902  : 3141,
    ZAINOU_DEADEYE_TARGET_NAVIGATION_PREDICTION_TN_903  : 13250,
    ZAINOU_DEADEYE_TARGET_NAVIGATION_PREDICTION_TN_904  : 3142,
    ZAINOU_DEADEYE_TARGET_NAVIGATION_PREDICTION_TN_905  : 24639,
    ZAINOU_DEADEYE_TARGET_NAVIGATION_PREDICTION_TN_906  : 3143,
    ZAINOU_DEADEYE_TRAJECTORY_ANALYSIS_TA_701  : 27078,
    ZAINOU_DEADEYE_TRAJECTORY_ANALYSIS_TA_702  : 3236,
    ZAINOU_DEADEYE_TRAJECTORY_ANALYSIS_TA_703  : 13245,
    ZAINOU_DEADEYE_TRAJECTORY_ANALYSIS_TA_704  : 3220,
    ZAINOU_DEADEYE_TRAJECTORY_ANALYSIS_TA_705  : 19697,
    ZAINOU_DEADEYE_TRAJECTORY_ANALYSIS_TA_706  : 3221,
    ZAINOU_GNOME_LAUNCHER_CPU_EFFICIENCY_LE_601  : 27091,
    ZAINOU_GNOME_LAUNCHER_CPU_EFFICIENCY_LE_602  : 3144,
    ZAINOU_GNOME_LAUNCHER_CPU_EFFICIENCY_LE_603  : 24641,
    ZAINOU_GNOME_LAUNCHER_CPU_EFFICIENCY_LE_604  : 3145,
    ZAINOU_GNOME_LAUNCHER_CPU_EFFICIENCY_LE_605  : 24642,
    ZAINOU_GNOME_LAUNCHER_CPU_EFFICIENCY_LE_606  : 3148,
    ZAINOU_GNOME_SHIELD_EMISSION_SYSTEMS_SE_801  : 27106,
    ZAINOU_GNOME_SHIELD_EMISSION_SYSTEMS_SE_802  : 3085,
    ZAINOU_GNOME_SHIELD_EMISSION_SYSTEMS_SE_803  : 13262,
    ZAINOU_GNOME_SHIELD_EMISSION_SYSTEMS_SE_804  : 3086,
    ZAINOU_GNOME_SHIELD_EMISSION_SYSTEMS_SE_805  : 16247,
    ZAINOU_GNOME_SHIELD_EMISSION_SYSTEMS_SE_806  : 3087,
    ZAINOU_GNOME_SHIELD_MANAGEMENT_SM_701  : 27105,
    ZAINOU_GNOME_SHIELD_MANAGEMENT_SM_702  : 3080,
    ZAINOU_GNOME_SHIELD_MANAGEMENT_SM_703  : 10228,
    ZAINOU_GNOME_SHIELD_MANAGEMENT_SM_704  : 3081,
    ZAINOU_GNOME_SHIELD_MANAGEMENT_SM_705  : 16246,
    ZAINOU_GNOME_SHIELD_MANAGEMENT_SM_706  : 3084,
    ZAINOU_GNOME_SHIELD_OPERATION_SP_901  : 27107,
    ZAINOU_GNOME_SHIELD_OPERATION_SP_902  : 3088,
    ZAINOU_GNOME_SHIELD_OPERATION_SP_903  : 13263,
    ZAINOU_GNOME_SHIELD_OPERATION_SP_904  : 3089,
    ZAINOU_GNOME_SHIELD_OPERATION_SP_905  : 16248,
    ZAINOU_GNOME_SHIELD_OPERATION_SP_906  : 3092,
    ZAINOU_GNOME_SHIELD_UPGRADES_SU_601  : 27104,
    ZAINOU_GNOME_SHIELD_UPGRADES_SU_602  : 3077,
    ZAINOU_GNOME_SHIELD_UPGRADES_SU_603  : 13253,
    ZAINOU_GNOME_SHIELD_UPGRADES_SU_604  : 3078,
    ZAINOU_GNOME_SHIELD_UPGRADES_SU_605  : 16245,
    ZAINOU_GNOME_SHIELD_UPGRADES_SU_606  : 3079,
    ZAINOU_GNOME_WEAPON_UPGRADES_WU_1001  : 27080,
    ZAINOU_GNOME_WEAPON_UPGRADES_WU_1002  : 3228,
    ZAINOU_GNOME_WEAPON_UPGRADES_WU_1003  : 13252,
    ZAINOU_GNOME_WEAPON_UPGRADES_WU_1004  : 3229,
    ZAINOU_GNOME_WEAPON_UPGRADES_WU_1005  : 16249,
    ZAINOU_GNOME_WEAPON_UPGRADES_WU_1006  : 3230,
    ZAINOU_GYPSY_CPU_MANAGEMENT_EE_601  : 27143,
    ZAINOU_GYPSY_CPU_MANAGEMENT_EE_602  : 3265,
    ZAINOU_GYPSY_CPU_MANAGEMENT_EE_603  : 13216,
    ZAINOU_GYPSY_CPU_MANAGEMENT_EE_604  : 3266,
    ZAINOU_GYPSY_CPU_MANAGEMENT_EE_605  : 27142,
    ZAINOU_GYPSY_CPU_MANAGEMENT_EE_606  : 3267,
    ZAINOU_GYPSY_ELECTRONIC_WARFARE_EW_901  : 27226,
    ZAINOU_GYPSY_ELECTRONIC_WARFARE_EW_902  : 3271,
    ZAINOU_GYPSY_ELECTRONIC_WARFARE_EW_903  : 13232,
    ZAINOU_GYPSY_ELECTRONIC_WARFARE_EW_904  : 3272,
    ZAINOU_GYPSY_ELECTRONIC_WARFARE_EW_905  : 27225,
    ZAINOU_GYPSY_ELECTRONIC_WARFARE_EW_906  : 3273,
    ZAINOU_GYPSY_ELECTRONICS_UPGRADES_EU_601  : 27129,
    ZAINOU_GYPSY_ELECTRONICS_UPGRADES_EU_602  : 3262,
    ZAINOU_GYPSY_ELECTRONICS_UPGRADES_EU_603  : 13254,
    ZAINOU_GYPSY_ELECTRONICS_UPGRADES_EU_604  : 3263,
    ZAINOU_GYPSY_ELECTRONICS_UPGRADES_EU_605  : 27128,
    ZAINOU_GYPSY_ELECTRONICS_UPGRADES_EU_606  : 3264,
    ZAINOU_GYPSY_LONG_RANGE_TARGETING_LT_801  : 27229,
    ZAINOU_GYPSY_LONG_RANGE_TARGETING_LT_802  : 3274,
    ZAINOU_GYPSY_LONG_RANGE_TARGETING_LT_803  : 13233,
    ZAINOU_GYPSY_LONG_RANGE_TARGETING_LT_804  : 3275,
    ZAINOU_GYPSY_LONG_RANGE_TARGETING_LT_805  : 27227,
    ZAINOU_GYPSY_LONG_RANGE_TARGETING_LT_806  : 3276,
    ZAINOU_GYPSY_PROPULSION_JAMMING_PJ_801  : 27231,
    ZAINOU_GYPSY_PROPULSION_JAMMING_PJ_802  : 3277,
    ZAINOU_GYPSY_PROPULSION_JAMMING_PJ_803  : 13234,
    ZAINOU_GYPSY_PROPULSION_JAMMING_PJ_804  : 3278,
    ZAINOU_GYPSY_PROPULSION_JAMMING_PJ_805  : 27230,
    ZAINOU_GYPSY_PROPULSION_JAMMING_PJ_806  : 3279,
    ZAINOU_GYPSY_SENSOR_LINKING_SL_901  : 27233,
    ZAINOU_GYPSY_SENSOR_LINKING_SL_902  : 3280,
    ZAINOU_GYPSY_SENSOR_LINKING_SL_903  : 13235,
    ZAINOU_GYPSY_SENSOR_LINKING_SL_904  : 3281,
    ZAINOU_GYPSY_SENSOR_LINKING_SL_905  : 27232,
    ZAINOU_GYPSY_SENSOR_LINKING_SL_906  : 3282,
    ZAINOU_GYPSY_SIGNATURE_ANALYSIS_SA_701  : 27131,
    ZAINOU_GYPSY_SIGNATURE_ANALYSIS_SA_702  : 3268,
    ZAINOU_GYPSY_SIGNATURE_ANALYSIS_SA_703  : 10244,
    ZAINOU_GYPSY_SIGNATURE_ANALYSIS_SA_704  : 3269,
    ZAINOU_GYPSY_SIGNATURE_ANALYSIS_SA_705  : 27130,
    ZAINOU_GYPSY_SIGNATURE_ANALYSIS_SA_706  : 3270,
    ZAINOU_GYPSY_TARGET_PAINTING_TG_901  : 27237,
    ZAINOU_GYPSY_TARGET_PAINTING_TG_902  : 3288,
    ZAINOU_GYPSY_TARGET_PAINTING_TG_903  : 27224,
    ZAINOU_GYPSY_TARGET_PAINTING_TG_904  : 3289,
    ZAINOU_GYPSY_TARGET_PAINTING_TG_905  : 27236,
    ZAINOU_GYPSY_TARGET_PAINTING_TG_906  : 3290,
    ZAINOU_GYPSY_WEAPON_DISRUPTION_WD_901  : 27235,
    ZAINOU_GYPSY_WEAPON_DISRUPTION_WD_902  : 3283,
    ZAINOU_GYPSY_WEAPON_DISRUPTION_WD_903  : 13236,
    ZAINOU_GYPSY_WEAPON_DISRUPTION_WD_904  : 3284,
    ZAINOU_GYPSY_WEAPON_DISRUPTION_WD_905  : 27234,
    ZAINOU_GYPSY_WEAPON_DISRUPTION_WD_906  : 3287,
    ZAINOU_SNAPSHOT_AUTO_TARGETING_EXPLOSION_RADIUS_FR_1001  : 27249,
    ZAINOU_SNAPSHOT_AUTO_TARGETING_EXPLOSION_RADIUS_FR_1002  : 3160,
    ZAINOU_SNAPSHOT_AUTO_TARGETING_EXPLOSION_RADIUS_FR_1003  : 13228,
    ZAINOU_SNAPSHOT_AUTO_TARGETING_EXPLOSION_RADIUS_FR_1004  : 3161,
    ZAINOU_SNAPSHOT_AUTO_TARGETING_EXPLOSION_RADIUS_FR_1005  : 27247,
    ZAINOU_SNAPSHOT_AUTO_TARGETING_EXPLOSION_RADIUS_FR_1006  : 3164,
    ZAINOU_SNAPSHOT_CRUISE_MISSILES_CM_601  : 27259,
    ZAINOU_SNAPSHOT_CRUISE_MISSILES_CM_602  : 3181,
    ZAINOU_SNAPSHOT_CRUISE_MISSILES_CM_603  : 13226,
    ZAINOU_SNAPSHOT_CRUISE_MISSILES_CM_604  : 3182,
    ZAINOU_SNAPSHOT_CRUISE_MISSILES_CM_605  : 27258,
    ZAINOU_SNAPSHOT_CRUISE_MISSILES_CM_606  : 3183,
    ZAINOU_SNAPSHOT_DEFENDER_MISSILES_DM_801  : 27244,
    ZAINOU_SNAPSHOT_DEFENDER_MISSILES_DM_802  : 3152,
    ZAINOU_SNAPSHOT_DEFENDER_MISSILES_DM_803  : 13227,
    ZAINOU_SNAPSHOT_DEFENDER_MISSILES_DM_804  : 3153,
    ZAINOU_SNAPSHOT_DEFENDER_MISSILES_DM_805  : 27243,
    ZAINOU_SNAPSHOT_DEFENDER_MISSILES_DM_806  : 3156,
    ZAINOU_SNAPSHOT_HEAVY_ASSAULT_MISSILES_AM_701  : 27246,
    ZAINOU_SNAPSHOT_HEAVY_ASSAULT_MISSILES_AM_702  : 3157,
    ZAINOU_SNAPSHOT_HEAVY_ASSAULT_MISSILES_AM_703  : 27109,
    ZAINOU_SNAPSHOT_HEAVY_ASSAULT_MISSILES_AM_704  : 3158,
    ZAINOU_SNAPSHOT_HEAVY_ASSAULT_MISSILES_AM_705  : 27245,
    ZAINOU_SNAPSHOT_HEAVY_ASSAULT_MISSILES_AM_706  : 3159,
    ZAINOU_SNAPSHOT_HEAVY_MISSILES_HM_701  : 27251,
    ZAINOU_SNAPSHOT_HEAVY_MISSILES_HM_702  : 3165,
    ZAINOU_SNAPSHOT_HEAVY_MISSILES_HM_703  : 13229,
    ZAINOU_SNAPSHOT_HEAVY_MISSILES_HM_704  : 3166,
    ZAINOU_SNAPSHOT_HEAVY_MISSILES_HM_705  : 27250,
    ZAINOU_SNAPSHOT_HEAVY_MISSILES_HM_706  : 3167,
    ZAINOU_SNAPSHOT_LIGHT_MISSILES_LM_901  : 27253,
    ZAINOU_SNAPSHOT_LIGHT_MISSILES_LM_902  : 3168,
    ZAINOU_SNAPSHOT_LIGHT_MISSILES_LM_903  : 27108,
    ZAINOU_SNAPSHOT_LIGHT_MISSILES_LM_904  : 3169,
    ZAINOU_SNAPSHOT_LIGHT_MISSILES_LM_905  : 27252,
    ZAINOU_SNAPSHOT_LIGHT_MISSILES_LM_906  : 3172,
    ZAINOU_SNAPSHOT_ROCKETS_RD_901  : 27255,
    ZAINOU_SNAPSHOT_ROCKETS_RD_902  : 3173,
    ZAINOU_SNAPSHOT_ROCKETS_RD_903  : 13230,
    ZAINOU_SNAPSHOT_ROCKETS_RD_904  : 3174,
    ZAINOU_SNAPSHOT_ROCKETS_RD_905  : 27254,
    ZAINOU_SNAPSHOT_ROCKETS_RD_906  : 3175,
    ZAINOU_SNAPSHOT_TORPEDOES_TD_601  : 27257,
    ZAINOU_SNAPSHOT_TORPEDOES_TD_602  : 3176,
    ZAINOU_SNAPSHOT_TORPEDOES_TD_603  : 13231,
    ZAINOU_SNAPSHOT_TORPEDOES_TD_604  : 3177,
    ZAINOU_SNAPSHOT_TORPEDOES_TD_605  : 27256,
    ZAINOU_SNAPSHOT_TORPEDOES_TD_606  : 3180,
    ZAKURA_BAZEI_FIREWORK  : 56944,
    ZARMAZD  : 49713,
    ZARMAZD_BLACKFIRE_STEEL_SKIN  : 49926,
    ZARMAZD_HADEAN_HORROR_SKIN  : 49935,
    ZARMAZD_METAMATERIA_EXOTICA_SKIN  : 50143,
    ZEALOT  : 12003,
    ZEALOT_ACE_OF_PODHUNTERS_SKIN  : 63202,
    ZEALOT_BIOSECURITY_RESPONDERS_SKIN  : 55302,
    ZEALOT_BLOOD_RAIDERS_SKIN  : 60671,
    ZEALOT_BLUEPRINT  : 12004,
    ZEALOT_CAPSULEER_DAY_XV_SKIN  : 47622,
    ZEALOT_COLD_IRON_SKIN  : 42771,
    ZEALOT_CORDITE_BLOSSOM_SKIN  : 48831,
    ZEALOT_CROWN_AND_SWORDS_SKIN  : 56192,
    ZEALOT_EOM_SKIN  : 36296,
    ZEALOT_EXOPLANETS_HUNTER_SKIN  : 45779,
    ZEALOT_GLACIAL_DRIFT_SKIN  : 46788,
    ZEALOT_HEARTSURGE_SKIN  : 53954,
    ZEALOT_IMPERIAL_JUBILEE_SKIN  : 48850,
    ZEALOT_OASIS_OF_FAITH_SKIN  : 53621,
    ZEALOT_PURITY_OF_THE_THRONE_SKIN  : 42570,
    ZEALOT_RAATA_SUNSET_SKIN  : 37505,
    ZEALOT_SARUM_SKIN  : 60680,
    ZEALOT_STAR_CAPTAIN_SKIN  : 52580,
    ZELFARIOS_KASHNOSTRAMUSS_TAG  : 18679,
    ZEMNAR  : 14358,
    ZEOLITES  : 45490,
    ZEPHYR  : 2078,
    ZERO_POINT_CONDENSATE  : 48112,
    ZERO_POINT_FIELD_MANIPULATOR  : 52312,
    ZERO_POINT_MASS_ENTANGLER  : 49099,
    ZERO_POINT_MASS_ENTANGLER_BLUEPRINT  : 49100,
    ZEUGMA_INTEGRATED_ANALYZER  : 41534,
    ZIRCON  : 45503,
    ZIRNITRA  : 52907,
    ZIRNITRA_BLACKFIRE_STEEL_SKIN  : 53668,
    ZIRNITRA_BLUEPRINT_DATA_VAULT  : 53299,
    ZIRNITRA_METAMATERIA_EXOTICA_SKIN  : 54222,
    ZORS_CUSTOM_NAVIGATION_HYPER_LINK  : 24663,
    ZORS_CUSTOM_NAVIGATION_LINK  : 19500,
    ZORS_DNA  : 17840,
    ZORYAS_ENTROPIC_RADIATION_SINK  : 52242,
    ZORYAS_HEAVY_ENTROPIC_DISINTEGRATOR  : 52238,
    ZORYAS_LIGHT_ENTROPIC_DISINTEGRATOR  : 52236,
    ZORYAS_SUPRATIDAL_ENTROPIC_DISINTEGRATOR  : 52240,
    ZYDRINE  : 39

};